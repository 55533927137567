import { useQuery } from '@apollo/client';
import { SOLUTIONS, SOLUTIONS_NL } from '@energiebespaarders/constants';
import {
  Accordion,
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Modal,
  RadioGroup,
  Select,
  Separator,
  SpinnerOverlay,
  Switch,
} from '@energiebespaarders/symbols';
import { Center, Right, Small } from '@energiebespaarders/symbols/helpers';
import {
  At,
  BadgeId,
  BagEuro,
  Bank,
  Browser,
  Calendar,
  CalendarDates,
  CancelCircle,
  ClockRetro,
  FloppyDisk,
  Information,
  LocationTarget,
  MapAlt,
  Pencil,
  Phone,
  Pin,
  Rotate,
  Shipping,
  TagMoney,
} from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { installerAccountHost } from '../../config';
import PC3RegionEditor from '../../domains/InstallerCoverage/PC3RegionEditor';
import { ZipRegion } from '../../domains/InstallerCoverage/mapUtils';
import PipedriveIcon from '../../icons/custom/Pipedrive';
import { GET_OPERATORS } from '../../queries/operatorDirectory';
import { getOperators, getOperatorsVariables } from '../../types/generated/getOperators';
import { installerOverview_installerById } from '../../types/generated/installerOverview';
import {
  InstallerInput,
  InstallerStatus,
  PTCRequirementsInput,
  Solution,
} from '../../types/graphql-global-types';
import DateWithTooltip from '../DateWithTooltip';
import { COMPANY_SIZES } from '../dashboard/AddInstallerForm';
import { OperatorTeam } from '../operatorDirectory/OperatorProfile';
import DataFieldWithUpdate from './DataFieldWithUpdate';
import EditInternalContacts from './EditContacts';
import EditPC4Regions from './EditPC4Regions';
import InstallationCapacityEditor from './InstallationCapacity';
import InstallerChangelog from './InstallerChangelog';
import InstallerStatusConfirmationModal, {
  installerStatusReasonNL,
} from './InstallerStatusConfirmationModal';
import InstallerStatusIndicators from './InstallerStatusIndicator';
import InstallerStatusInfo from './InstallerStatusInfo';

const StatusIcon = styled(Icon)``;
const UpdateIcon = styled(Icon)``;

const StatusChanger = styled(Box)`
  cursor: pointer;

  ${StatusIcon},
  &:hover ${UpdateIcon} {
    display: unset;
  }

  &:hover ${StatusIcon}, ${UpdateIcon} {
    display: none;
  }
`;

export const ClickableBox = styled(Box)`
  cursor: pointer;
`;

const PC3RegionModal: React.FC<{
  onClose: () => void;
  installer: installerOverview_installerById;
  updateInstaller: (update: Partial<InstallerInput>) => void;
}> = ({ onClose, installer, updateInstaller }) => {
  const [regions, setRegions] = useState<ZipRegion[]>([...installer.installationRegions]);

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      title="Kaartweergave van werkgebieden"
      size="xl"
      buttons={[
        {
          label: 'Reset',
          bgColor: 'gold',
          iconStart: Rotate,
          onClick: () => {
            setRegions([...installer.installationRegions]);
          },
        },
        {
          label: 'Opslaan',
          iconStart: FloppyDisk,
          onClick: () => {
            regions.sort((a, b) => a.from - b.from);
            updateInstaller({ installationRegions: regions });
            onClose();
          },
        },
      ]}
    >
      <PC3RegionEditor regions={regions} setRegions={setRegions} />

      <Link to="/installer-coverage">Werkgebieden van alle installateurs bekijken →</Link>
    </Modal>
  );
};

interface InstallerInfoProps {
  installer: installerOverview_installerById;
  updating: boolean;
  updateInstaller: (update: Partial<InstallerInput>) => void;
}

const InstallerInfo: React.FC<InstallerInfoProps> = ({ updating, updateInstaller, installer }) => {
  const {
    name,
    address,
    size,
    website,
    email,
    phone,
    coc,
    vat,
    workRadius,
    minimumRate,
    installationRegions,
    supplier,
    inspectionEmail,
    installationEmail,
    planningPeriod,
    customerNotificationPeriod,
    pipedriveId,
    legalName,
    hasInstallerAccount,
    accountManager,
    slotsPerWeek,
    contacts,
  } = installer;

  const [isMapModalOpen, setMapModalOpen] = useState(false);
  const [isShowingStatusInfo, setShowingStatusInfo] = useState(false);

  const sizeOptions = [{ label: 'Onbekend', value: 0 }, ...COMPANY_SIZES];

  const { data: operatorsData } = useQuery<getOperators, getOperatorsVariables>(GET_OPERATORS, {
    variables: { includeInactive: false },
  });

  const operatorOptions = (operatorsData?.operators || [])
    .filter(o => o.team === OperatorTeam.Planning || o.team === OperatorTeam.MT)
    .map(o => {
      return {
        label: `${o.firstName} ${o.lastName}`,
        value: o.id,
      };
    });

  const dataFieldInputWidth = [1, 1 / 2, 1 / 4, 1 / 5];

  const [isChangingStatus, setChangingStatus] = useState(false);

  const statusIndicator = InstallerStatusIndicators[installer.status.value];

  const isPtcRequired = (solution: Solution) => {
    return installer.ptcRequirements?.find(e => e.solution === solution)?.required ?? false;
  };
  const updatePTCRequirements = (solution: Solution, required: boolean) => {
    const index = installer.ptcRequirements.findIndex(e => e.solution === solution) ?? -1;
    const newPTCRequirements: PTCRequirementsInput[] = [...installer.ptcRequirements];
    if (index !== -1) {
      newPTCRequirements[index] = { solution, required };
      updateInstaller({ ptcRequirements: newPTCRequirements });
      return;
    }
    newPTCRequirements.push({ solution, required });
    updateInstaller({ ptcRequirements: newPTCRequirements });
  };

  return (
    <Accordion title="Gegevens" contentPadding={4} baseBgColor="white" mb={2} defaultOpen>
      {updating && <SpinnerOverlay />}
      <Flex justifyContent="space-between" flexWrap="wrap">
        <Box width={1} px={2}>
          <Flex flexWrap="wrap">
            <Box width={0.1}>Status:</Box>

            <StatusChanger
              ml={0}
              onClick={() => setChangingStatus(!isChangingStatus)}
              maxWidth="80%"
              minWidth="30%"
            >
              <span>
                <StatusIcon icon={statusIndicator.icon} solid fill={statusIndicator.color} />
                <UpdateIcon icon={Pencil} solid fill="blue" hoverColor="blueDark" />{' '}
                <strong>{statusIndicator.label}</strong>{' '}
                {statusIndicator.description ? `(${statusIndicator.description})` : ''}
                <br />
                {installer.status.value !== InstallerStatus.active && (
                  <Small>
                    <Flex>
                      <Box width="80px">Sinds: </Box>
                      <DateWithTooltip date={installer.status.date} />
                    </Flex>
                    <Flex>
                      <Box width="80px">Reden: </Box>
                      <i>
                        {installer.status.reason
                          ? installerStatusReasonNL[installer.status.reason]
                          : 'Onbekend'}
                      </i>
                    </Flex>
                    <Flex>
                      <Box width="80px">Toelichting: </Box>
                      <i>{installer.status.comment || '-'}</i>
                    </Flex>
                  </Small>
                )}
              </span>
            </StatusChanger>

            <ClickableBox
              width={0.1}
              pl={2}
              onClick={() => setShowingStatusInfo(!isShowingStatusInfo)}
            >
              <Small>
                <Icon icon={Information} solid fill="blue" hoverColor="blueDark" mr={1} />
                Info
              </Small>
            </ClickableBox>

            {isShowingStatusInfo && <InstallerStatusInfo />}
          </Flex>

          {isChangingStatus && (
            <InstallerStatusConfirmationModal
              isOpen={isChangingStatus}
              setIsOpen={setChangingStatus}
              initialStatus={installer.status.value}
              installerName={installer.name}
              installerId={installer.id}
              onClose={() => setChangingStatus(false)}
            />
          )}
        </Box>
        <Box width={1} mt={4} px={2}>
          <Box>
            Toegang tot het{' '}
            <a
              href={installerAccountHost}
              target="_blank"
              title="De online omgeving voor IPs voor het inplannen en afronden van opdrachten"
              rel="noreferrer"
            >
              Installer Account
            </a>
            :
          </Box>

          <Switch
            onLabel="Installer Account"
            offLabel="Geen Installer Account"
            bgColorOff="gray"
            bgColorOn="green"
            isOn={!!hasInstallerAccount}
            toggleSwitch={() => updateInstaller({ hasInstallerAccount: !hasInstallerAccount })}
          />
          {hasInstallerAccount && (
            <Center block>
              <Small>
                Je kunt <Link to="/users/installer">hier</Link> accounts voor het IA registreren
              </Small>
            </Center>
          )}
        </Box>
      </Flex>

      <Separator my={3} text="Contactgegevens" />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Wettelijke naam"
        note="Wettelijke naam"
        icon={BadgeId}
        field={legalName || name}
        update={(val: string) => {
          if (val === name || val === '') {
            updateInstaller({ legalName: null });
          } else {
            updateInstaller({ legalName: val });
          }
        }}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Adres"
        icon={Pin}
        field={`${address.street} ${address.number}${address.suffix ? ` ${address.suffix}` : ''}, ${
          address.zip
        } ${address.city}`}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Tel"
        icon={Phone}
        field={phone}
        update={(val: string) => updateInstaller({ phone: val })}
      />

      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Contact e-mail"
        note="Contact e-mail"
        field={email}
        icon={At}
        type="email"
        update={(val: string) => updateInstaller({ email: val })}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Installatie e-mail"
        note="Installatie e-mail"
        field={installationEmail || '-'}
        icon={At}
        type="email"
        update={(val: string) => updateInstaller({ installationEmail: val })}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Schouw e-mail"
        note="Schouw e-mail"
        field={inspectionEmail || '-'}
        icon={At}
        type="email"
        update={(val: string) => updateInstaller({ inspectionEmail: val })}
      />

      <Separator my={3} text="Interne contacten" />
      <EditInternalContacts
        contacts={contacts ?? []}
        onUpdate={newContacts => updateInstaller({ contacts: newContacts })}
      />

      <Separator my={3} text="Werkgebieden" />
      <EditPC4Regions
        regions={installationRegions}
        onUpdate={newRegions => updateInstaller({ installationRegions: newRegions })}
      />

      <Right block>
        <Button
          iconStart={CancelCircle}
          bgColor="red"
          inverse
          onClick={() =>
            window.confirm(
              `Weet je zeker dat je de werkgebieden van ${installer.name} wilt legen?`,
            ) && updateInstaller({ installationRegions: [] })
          }
        >
          Leeg maken
        </Button>
        <Button inverse iconStart={MapAlt} onClick={() => setMapModalOpen(true)}>
          Open kaartweergave
        </Button>
      </Right>

      <Separator my={3} text="Werkafspraken" />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Planning periode (WERKDAGEN)"
        prenote="Planning periode (WERKDAGEN):"
        icon={Calendar}
        field={planningPeriod}
        type="number"
        update={(val: string) => updateInstaller({ planningPeriod: Number(val) })}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Klant notificatie periode (WEKEN)"
        prenote="Klant notificatie periode (WEKEN):"
        icon={Calendar}
        field={customerNotificationPeriod || '-'}
        type="number"
        update={(val: string) => updateInstaller({ customerNotificationPeriod: Number(val) })}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Installatieslots per week"
        prenote="Installatieslots per Week:"
        icon={Calendar}
        field={slotsPerWeek || '-'}
        type="number"
        update={(val: string) => updateInstaller({ slotsPerWeek: Number(val) })}
      />

      {isMapModalOpen && (
        <PC3RegionModal
          installer={installer}
          updateInstaller={updateInstaller}
          onClose={() => setMapModalOpen(false)}
        />
      )}

      <Separator my={3} />

      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Pipedrive ID"
        note="Pipedrive ID"
        field={pipedriveId || '-'}
        icon={PipedriveIcon}
        type="number"
        update={(val: string) => updateInstaller({ pipedriveId: Number(val) })}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Werkgebied"
        note="Werkgebied km"
        field={workRadius || '-'}
        icon={LocationTarget}
        type="number"
        update={val => updateInstaller({ workRadius: Number(val) })}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Minimumtarief"
        note="Minimumtarief"
        field={minimumRate || '-'}
        icon={BagEuro}
        type="number"
        update={(val: string) => updateInstaller({ minimumRate: val === '' ? null : Number(val) })}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Website"
        note="Website"
        icon={Browser}
        field={website || 'website onbekend'}
        update={(val: string) => updateInstaller({ website: val })}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="KvK-nummer"
        note="KvK-nummer"
        icon={Bank}
        field={coc || 'KvK-nummer onbekend'}
        type="number"
        update={(val: string) => updateInstaller({ coc: Number(val) })}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="BTW-nummer"
        note="BTW-nummer"
        icon={TagMoney}
        field={vat || 'BTW-nummer onbekend'}
        update={(val: string) => updateInstaller({ vat: val })}
      />
      <DataFieldWithUpdate
        inputWidth={dataFieldInputWidth}
        loading={updating}
        label="Leverancier partner"
        note="Leverancier partner"
        icon={Shipping}
        field={supplier.name}
        link={`/supplier/${supplier.id}`}
      />
      <Box width={0.5}>
        <Select<string>
          label="Account manager"
          onChange={e => {
            updateInstaller({ accountManagerId: e?.value || null });
          }}
          value={accountManager ? operatorOptions.find(o => o.value === accountManager.id) : ''}
          options={operatorOptions}
          clearable
        />
      </Box>
      <Box width={0.5}>
        <Select<number>
          label="Aantal werknemers"
          options={sizeOptions}
          value={sizeOptions.find(opt => opt.value === size)}
          onChange={e => {
            updateInstaller({ size: Number(e.value) || null });
          }}
          disabled={updating}
        />
      </Box>
      <Separator text="Oplossingen" my={2} />
      <RadioGroup
        id="solutions"
        label="Oplossingen die het bedrijf installeert"
        labelSize={4}
        labelColor="grayDarker"
        name="solutions"
        onChange={(sol: Solution) => {
          updateInstaller({
            solutions: installer.solutions.includes(sol)
              ? installer.solutions.filter(s => s !== sol)
              : [...installer.solutions, sol],
          });
        }}
        disabled={updating}
      >
        {(SOLUTIONS as Solution[]).map(solution => (
          <Checkbox
            key={`${solution}Checkbox`}
            checked={installer.solutions.includes(solution)}
            id={solution}
            label={SOLUTIONS_NL[solution]}
            name="solutions"
            value={solution}
          />
        ))}
      </RadioGroup>

      {installer.solutions.length > 0 && (
        <RadioGroup
          id="ptcRequirements"
          label="Schouw verplicht?"
          labelSize={4}
          labelColor="grayDarker"
          onChange={(solution: Solution) =>
            updatePTCRequirements(solution, !isPtcRequired(solution))
          }
        >
          {installer.solutions.map(solution => {
            return (
              <Checkbox
                key={`ptc-${solution}`}
                id={`ptc-${solution}`}
                checked={isPtcRequired(solution)}
                label={SOLUTIONS_NL[solution]}
                name="ptcSolutions"
                value={solution}
              />
            );
          })}
        </RadioGroup>
      )}

      <Accordion
        title="Installatiecapaciteit"
        icon={CalendarDates}
        unmountCollapsedContent
        baseBgColor="white"
        contentBgColor="white"
        mb={2}
      >
        <InstallationCapacityEditor installerId={installer.id} />
      </Accordion>

      <Accordion
        title="Wijzigingslogboek"
        icon={ClockRetro}
        unmountCollapsedContent
        contentBgColor="white"
        baseBgColor="white"
      >
        <InstallerChangelog installerId={installer.id} />
      </Accordion>
    </Accordion>
  );
};

export default InstallerInfo;
