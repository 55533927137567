import { useMutation } from '@apollo/client';
import { Uploader } from '@energiebespaarders/symbols';
import React from 'react';
import { useMe } from '../hooks/useMe';
import useToaster from '../hooks/useToaster';
import { GET_PRODUCT_FILES, UPLOAD_PRODUCT_FILES } from '../queries/files';
import { getProductFiles, getProductFilesVariables } from '../types/generated/getProductFiles';
import {
  uploadProductFiles,
  uploadProductFilesVariables,
  uploadProductFiles_uploadProductFiles,
} from '../types/generated/uploadProductFiles';

interface ProductUploaderProps {
  accept?: string;
  bgColor?: string;
  borderColor?: string;
  getExtraTags?: () => string[];
  height?: string;
  multiple?: boolean;
  onUploadFinished?: (uploads: readonly uploadProductFiles_uploadProductFiles[]) => void;
  productId: string;
  tags?: string[];
  text?: string;
}

const ProductUploader: React.FC<ProductUploaderProps> = ({
  accept,
  bgColor,
  borderColor,
  getExtraTags,
  height,
  multiple,
  onUploadFinished,
  productId,
  tags,
  text,
}) => {
  const toast = useToaster();
  const { me } = useMe();

  const [performUpload, { loading, error }] = useMutation<
    uploadProductFiles,
    uploadProductFilesVariables
  >(UPLOAD_PRODUCT_FILES, {
    update: (store, { data }) => {
      const queryData = store.readQuery<getProductFiles, getProductFilesVariables>({
        query: GET_PRODUCT_FILES,
        variables: { productId },
      });
      if (!queryData?.productById || !data?.uploadProductFiles) return;
      const newFiles = queryData.productById.files
        ? queryData.productById.files.concat(data.uploadProductFiles)
        : [...data.uploadProductFiles];
      store.writeQuery({
        query: GET_PRODUCT_FILES,
        variables: { productId },
        data: {
          productById: {
            ...queryData.productById,
            files: newFiles,
          },
        },
      });
    },
    onCompleted: data => {
      if (onUploadFinished) {
        onUploadFinished(data?.uploadProductFiles || []);
      }
      toast({
        type: 'success',
        message: 'Bestanden zijn succesvol geüpload',
      });
    },
    onError: () =>
      toast({
        type: 'error',
        message: 'Er is een fout opgetreden bij het uploaden van bestanden',
      }),
  });

  return (
    <Uploader
      accept={accept}
      bgColor={bgColor}
      height={height}
      borderColor={borderColor}
      loading={loading}
      multiple={multiple}
      error={error}
      text={text}
      upload={({ variables }: any) => {
        performUpload({
          variables: {
            id: productId,
            files: variables.files,
            tags: [...(tags || []), ...(getExtraTags?.() || [])],
            uploaderId: me.id,
            uploaderUserType: 'operator',
          },
        });
      }}
      mb={1}
    />
  );
};

export default ProductUploader;
