import { gql, useQuery } from '@apollo/client';
import { ComfortDeterminants, constants, Solution } from '@energiebespaarders/constants';
import {
  Box,
  Button,
  Counter,
  EnergyLabel,
  Flex,
  Icon,
  Spinner,
  Switch,
} from '@energiebespaarders/symbols';
import { Color, Medium, Smaller } from '@energiebespaarders/symbols/helpers';
import React, { useCallback, useMemo, useState } from 'react';
import {
  ConsumptionFragment,
  EstimatesFragment,
  HouseEnergyFragment,
  SituationEnergyPricesFragment,
} from '../fragments';
import { useActiveHouseId } from '../hooks/useActiveHouseId';
import { renderComfortStars } from '../lib/renderComfortStars';
import { delimit, fixUnit, getCalculations } from '../lib/utils';
import { completeEnergyPrices } from '../lib/utils/completeEnergyPrices';
import {
  getCalculations as getCalculationsQuery,
  getCalculationsVariables,
} from '../types/generated/getCalculations';
import { installationByHouseSolution_installationByHouseSolution_items } from '../types/generated/installationByHouseSolution';
import { Quote_energyDelta, Quote_items } from '../types/generated/Quote';

const CALCULATIONS_HELPER = gql`
  ${ConsumptionFragment}
  ${HouseEnergyFragment}
  ${EstimatesFragment}
  ${SituationEnergyPricesFragment}
  query getCalculations($houseId: ID!) {
    house(id: $houseId) {
      id
      energy {
        ...HouseEnergy
      }
      type
      comfortScore
      walls {
        rc
      }
      floor {
        rc
      }
      roof {
        rc
      }
      windowsZTAU {
        livingU
        sleepingU
      }
      heating {
        heatEmissionSystems
        installations
      }
      averageEstimates {
        ...Estimates
      }
      situation {
        id
        consumption {
          ...Consumption
        }
        energyPrices {
          ...SituationEnergyPrices
        }
      }
      intake {
        id
        type
        walls {
          rc
        }
        floor {
          rc
        }
        roof {
          rc
        }
        windows {
          livingU
          sleepingU
        }
        heating {
          heatEmissionSystems
          installations
        }
      }
    }
  }
`;

interface Installable {
  solution: Solution;
  subsidy: number;
  investment: number;
  investmentExTax: number;
  energyDelta: Quote_energyDelta;
  paybackTime: number;
  comfortScore: number;
  items:
    | readonly Quote_items[]
    | readonly installationByHouseSolution_installationByHouseSolution_items[];
}

interface CalculationsProps {
  /** Estimate, Quote or Installation */
  installable: Installable;
  isNarrow?: boolean;
  useEstimate?: boolean;
}

const Calculations: React.FC<CalculationsProps> = ({ isNarrow, useEstimate, installable }) => {
  const solution = installable.solution;

  const { activeHouseId } = useActiveHouseId();
  const { data: calculationsData, loading, error, refetch } = useQuery<
    getCalculationsQuery,
    getCalculationsVariables
  >(CALCULATIONS_HELPER, {
    variables: { houseId: activeHouseId },
  });
  const [includeSubsidyInInvestment, setIncludeSubsidyInInvestment] = useState(false);
  const [includeTaxInInvestment, setIncludeTaxInInvestment] = useState(true);

  const calcs = useMemo(() => {
    if (!calculationsData?.house) return [];

    const {
      house: {
        averageEstimates,
        energy: { gasConsumption, electricityConsumption, electricityProduction, energyIndex },
        situation: { consumption, energyPrices: personalPrices },
      },
    } = calculationsData;

    const energyPrices = completeEnergyPrices(personalPrices);

    const definedConsumption = {
      gas: consumption.gas || gasConsumption,
      electricity: consumption.electricity || electricityConsumption - electricityProduction,
    };

    const investmentToUse = useEstimate
      ? averageEstimates[solution].investment
      : includeTaxInInvestment
      ? installable.investment
      : installable.investmentExTax;

    return getCalculations(
      useEstimate ? averageEstimates[solution].energyDelta : installable.energyDelta,
      definedConsumption,
      investmentToUse,
      energyIndex,
      energyPrices,
      constants.emissionFactors,
      installable.subsidy,
      includeSubsidyInInvestment,
    );
  }, [
    calculationsData,
    useEstimate,
    solution,
    installable,
    includeTaxInInvestment,
    includeSubsidyInInvestment,
  ]);

  const hasSubsidy = !!calcs.find(c => c.key === 'subsidy')?.value;
  const toggleSubsidy = useCallback(() => {
    setIncludeSubsidyInInvestment(!includeSubsidyInInvestment);
  }, [includeSubsidyInInvestment]);

  const toggleTax = useCallback(() => {
    setIncludeTaxInInvestment(!includeTaxInInvestment);
  }, [includeTaxInInvestment]);

  if (loading || !calculationsData?.house) return <Spinner />;
  if (error) return <div>{error.message}</div>;

  const { house } = calculationsData;
  const { intake } = house;
  const comfortProps: ComfortDeterminants = {
    houseType: intake.type || house.type,
    wallsRc: intake.walls.rc || house.walls.rc || 0,
    floorRc: intake.floor.rc || house.floor.rc || 0,
    roofRc: intake.roof.rc || house.roof.rc || 0,
    heatingInstallations: [...(intake.heating.installations || house.heating.installations)],
    heatEmissionSystems: [
      ...(intake.heating.heatEmissionSystems || house.heating.heatEmissionSystems),
    ],
    livingU: intake.windows.livingU || house.windowsZTAU?.livingU || 0,
    sleepingU: intake.windows.sleepingU || house.windowsZTAU?.sleepingU || 0,
  };

  const ComfortStars = renderComfortStars(
    solution,
    comfortProps,
    installable.comfortScore - house.comfortScore,
  );

  return (
    <Flex flexWrap="wrap">
      {calcs.map(calc => (
        <Box
          key={`${solution}-${calc.key}`}
          width={isNarrow ? 1 : 1 / 2}
          mb={isNarrow ? 1 : 0}
          style={{ textAlign: isNarrow ? 'left' : undefined }}
        >
          <Color c={useEstimate ? 'grayDark' : 'grayBlack'}>
            <Flex flexWrap="wrap" alignItems="center" justifyContent="center" mb={isNarrow ? 0 : 1}>
              <Box width={isNarrow ? 1 / 5 : 1 / 10}>
                <Icon
                  icon={calc.icon}
                  solid
                  inline
                  fill={calc.value! === 0 ? 'gray' : calc.color}
                  width="1.25em"
                />
              </Box>
              <Box width={isNarrow ? 2 / 5 : 5 / 10}>
                <Color c={calc.value! === 0 ? 'gray' : 'grayBlack'}>
                  {calc.key === 'subsidy' ? (
                    <Flex>
                      <Medium onClick={toggleSubsidy}>{calc.label}</Medium>
                      {hasSubsidy && (
                        <Switch
                          isOn={includeSubsidyInInvestment}
                          toggleSwitch={toggleSubsidy}
                          width="24px"
                          ml={2}
                        />
                      )}
                    </Flex>
                  ) : calc.key === 'investment' ? (
                    <Flex>
                      <Medium onClick={toggleSubsidy}>{calc.label}</Medium>
                      <Box ml={2}>
                        <Switch
                          isOn={includeTaxInInvestment}
                          toggleSwitch={toggleTax}
                          width="24px"
                        />
                        <div style={{ lineHeight: 1 }}>
                          <Smaller>BTW</Smaller>
                        </div>
                      </Box>
                    </Flex>
                  ) : (
                    <Medium>{calc.label}</Medium>
                  )}
                </Color>
              </Box>
              <Box width={isNarrow ? 2 / 5 : 4 / 10}>
                {useEstimate && '± '}
                {calc.key === 'comfort' ? (
                  ComfortStars
                ) : calc.key === 'energyLabel' ? (
                  <EnergyLabel fontSize={6} label={calc.value as any} />
                ) : (
                  <Color
                    c={
                      calc.value === 0
                        ? 'gray'
                        : calc.value! < 0
                        ? 'red'
                        : calc.key === 'subsidy' && calc.value! > 0 && hasSubsidy
                        ? includeSubsidyInInvestment
                          ? 'green'
                          : 'gray'
                        : 'grayBlack'
                    }
                  >
                    {fixUnit(calc.prefix)}
                    <Counter
                      value={calc.value as number}
                      formatValue={n => delimit(n, calc.decimals)}
                    />
                    {fixUnit(calc.suffix)}
                  </Color>
                )}
              </Box>
            </Flex>
          </Color>
        </Box>
      ))}
      <Box width={1} textAlign="center">
        <Button
          onClick={() => refetch()}
          label="Opnieuw berekenen"
          minimal
          bgColor="blue"
          mb={0}
          fontSize={6}
        />
      </Box>
    </Flex>
  );
};

export default Calculations;
