import { PriceAvailability } from './../../types/graphql-global-types';
import {
  Alert,
  CancelCircle,
  CheckCircle,
  Information,
  MinusCircle,
} from '@energiebespaarders/symbols/icons/solid';

export const PRICE_AVAILABILITY_NL: Record<PriceAvailability, string> = {
  [PriceAvailability.inStock]: 'Op voorraad',
  [PriceAvailability.preOrder]: 'Pre-order',
  [PriceAvailability.outOfStock]: 'Uitverkocht',
  [PriceAvailability.discontinued]: 'Niet leverbaar',
};

export default function (availability: PriceAvailability | null) {
  switch (availability) {
    case 'inStock':
      return {
        icon: CheckCircle,
        fill: 'green',
        toolTip: PRICE_AVAILABILITY_NL['inStock'],
        status: 1,
      };

    case 'preOrder':
      return {
        icon: Alert,
        fill: 'blue',
        toolTip: PRICE_AVAILABILITY_NL['preOrder'],
        status: 3,
      };

    case 'outOfStock':
      return {
        icon: MinusCircle,
        fill: 'orange',
        toolTip: PRICE_AVAILABILITY_NL['outOfStock'],
        status: 4,
      };

    case 'discontinued':
      return {
        icon: CancelCircle,
        fill: 'red',
        toolTip: PRICE_AVAILABILITY_NL['discontinued'],
        status: 5,
      };

    default:
      return {
        icon: Information,
        fill: 'gray',
        toolTip: 'Beschikbaarheid onbekend',
        status: 2,
      };
  }
}
