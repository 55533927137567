import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Logomark } from '@energiebespaarders/symbols/icons/custom';
import {
  BadgeId,
  Box,
  Calendar,
  Dashboard,
  Database,
  FolderUser,
  Frown,
  Hand,
  Hardhat,
  LifeRing,
  Magazine,
  Map,
  MapLocation,
  Settings,
  Smile,
  Star,
  Ticket,
  Toolbox,
  User,
} from '@energiebespaarders/symbols/icons/solid';
import localforage from 'localforage';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { version } from '../../../package.json';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import { useIsAuthenticated } from '../../hooks/useIsAuthenticated';
import { useMe } from '../../hooks/useMe';
import { useNavHelpers } from '../../hooks/useNavHelpers';
import theme from '../../styles/theme';
import { SideNav, SideNavItem, SideNavTabs } from './index';
import { ISideNavItem } from './Navigation';
import { PositionedSideNavItem } from './SideNavItem';

export const LOGOUT_OPERATOR = gql`
  mutation logout {
    logoutOperator
  }
`;

const LogOut = styled(SideNavItem)<PositionedSideNavItem>`
  position: absolute;
  bottom: ${x => (x.$narrowScreen ? 'auto' : '0')};
  width: 100%;
  color: white;
`;

const Version = styled.span`
  position: absolute;
  bottom: 0;
  width: 100%;
  color: ${x => x.theme.colors.grayDark};
  padding-left: 1em;
  padding-bottom: 0.5em;
  font-size: ${x => x.theme.type.scale[5]};
`;

interface IRightNavProps {
  narrowScreen: boolean;
}

const RightNav: React.FC<IRightNavProps> = ({ narrowScreen }) => {
  const client = useApolloClient();
  const location = useLocation();
  const { me, setMe } = useMe();
  const { setIsAuthenticated } = useIsAuthenticated();
  const { activeHouseId, setActiveHouseId } = useActiveHouseId();
  const {
    setHelpMenuState,
    rightNavState,
    setRightNavState,
    setSearchActive,
    toggleTicketPopup,
  } = useNavHelpers();

  const [logout] = useMutation(LOGOUT_OPERATOR, {
    onCompleted: async () => {
      localforage.clear();
      setIsAuthenticated(false);
      setActiveHouseId('');
      await client.resetStore();
      setMe(undefined);
      window.location.reload();
    },
  });

  const handleRouteChange = useCallback(() => {
    if (narrowScreen) setRightNavState?.({ isOpen: false });
    else {
      setRightNavState?.({ isOpen: false });
      setSearchActive?.(false);
    }
  }, [narrowScreen, setRightNavState, setSearchActive]);

  const handleHelpMenu = useCallback(() => {
    const path = location.pathname;
    let helpTab = 1;
    if (path.includes('/house')) helpTab = 1;
    if (path.includes('/installatron')) helpTab = 2;
    if (path.includes('/intake')) helpTab = 3;
    if (path.includes('/advice')) helpTab = 4;
    if (path.includes('/obama')) helpTab = 5;
    setHelpMenuState?.({ activeTab: helpTab, isOpen: true });
  }, [location.pathname, setHelpMenuState]);

  const commonNavItemProps = useMemo(
    () => ({
      showLabel: rightNavState.isOpen,
      side: 'right',
      onRouteChange: handleRouteChange,
    }),
    [handleRouteChange, rightNavState.isOpen],
  );

  const rightSideNavItemsGeneral: ISideNavItem[] = useMemo(() => {
    return [
      {
        label: 'Startscherm',
        to: '/',
        icon: Logomark,
        exact: true,
        ...commonNavItemProps,
      },
      { to: '/products', icon: Database, label: 'Producten', ...commonNavItemProps },
      {
        label: 'Planning',
        to: '/planning',
        icon: Calendar,
        ...commonNavItemProps,
      },
      { to: '/coupons', icon: Ticket, label: 'Kortingscodes', ...commonNavItemProps },
      { to: '/review', icon: Star, label: 'Uitvoer beoordelen', ...commonNavItemProps },
      { to: '/partners', icon: Toolbox, label: 'Uitvoerpartners', ...commonNavItemProps },
      {
        to: '/installer-coverage',
        icon: Map,
        label: 'Dekkingskaart IPs',
        ...commonNavItemProps,
      },
      {
        exact: true,
        to: '/operator-coverage',
        icon: MapLocation,
        label: 'Dekkingskaart adviseurs',
        ...commonNavItemProps,
      },
      {
        to: `/data-control/${activeHouseId}/dates`,
        icon: Settings,
        label: 'Operationele gegevens',
        disabled: !activeHouseId,
        ...commonNavItemProps,
      },
      { to: '/brands', icon: Magazine, label: 'Merken', ...commonNavItemProps },
      {
        disabled: false,
        to: '/partner-management',
        icon: FolderUser,
        label: 'Partner management',
        ...commonNavItemProps,
      },
    ];
  }, [activeHouseId, commonNavItemProps]);

  const rightSideNavItemsUser: ISideNavItem[] = useMemo(
    () => [
      {
        to: `/users/operators/${me?.id}`,
        icon: Smile,
        label: 'Mijn profiel',
        ...commonNavItemProps,
      },
    ],
    [me?.id, commonNavItemProps],
  );

  const rightSideNavItemsSettings: ISideNavItem[] = useMemo(
    () => [
      {
        exact: true,
        strict: true,
        to: '#',
        icon: LifeRing,
        label: 'Help',
        onClick: handleHelpMenu,
        ...commonNavItemProps,
      },
      { to: '/pakketten', icon: Box, label: 'Dienstenpakketten', ...commonNavItemProps },
      {
        exact: true,
        to: '/users/operators',
        icon: BadgeId,
        label: 'Operators',
        ...commonNavItemProps,
      },
      {
        exact: true,
        to: '/users/installer',
        icon: Hardhat,
        label: 'Installateur accounts',
        ...commonNavItemProps,
      },
      {
        disabled: false,
        to: '/config',
        icon: Settings,
        label: 'Instellingen',
        ...commonNavItemProps,
      },
      {
        exact: true,
        strict: true,
        disabled: false,
        to: '#',
        onClick: toggleTicketPopup,
        icon: Hand,
        label: 'Ticket of supportverzoek',
        ...commonNavItemProps,
      },
    ],
    [toggleTicketPopup, handleHelpMenu, commonNavItemProps],
  );

  return (
    <SideNav
      offsetTop="0px"
      side="right"
      translateX={rightNavState.isOpen ? '0' : theme.fixedSizes.rightNavOpened}
      width={theme.fixedSizes.rightNavOpened}
      data-tour="rightNav"
    >
      <SideNavTabs
        activeTab={rightNavState.activeTab}
        setActiveTab={(tab: number) => setRightNavState?.({ activeTab: tab })}
        tabIcons={[Dashboard, User, Settings]}
        data-tour="navTabs"
      />
      {rightNavState.activeTab === 1 && (
        <>
          {rightSideNavItemsGeneral.map(item => (
            <SideNavItem key={item.label} {...item} />
          ))}
        </>
      )}
      {rightNavState.activeTab === 2 && (
        <>
          {rightSideNavItemsUser.map(item => (
            <SideNavItem key={item.label} {...item} />
          ))}
          <LogOut
            $narrowScreen={narrowScreen}
            bgColor="red"
            side="right"
            to="/login"
            icon={Frown}
            label="Uitloggen"
            onClick={logout}
            showLabel
          />
        </>
      )}
      {rightNavState.activeTab === 3 && (
        <>
          {rightSideNavItemsSettings.map(item => (
            <SideNavItem key={item.label} {...item} />
          ))}
          <Version>Energiebeheerders v{version}</Version>
        </>
      )}
    </SideNav>
  );
};

export default RightNav;
