import { Tooltip } from '@energiebespaarders/symbols';
import dayjs from 'dayjs';
import React from 'react';

export const DefaultDateTimeTooltipFormat = 'dddd D MMMM YYYY [om] HH:mm';

type DateWithTooltipProps = {
  date: Date;
  /** Dayjs format, default to `DD/MM/YY` */
  format?: string;
  /** Dayjs format for tooltip, defaults to `dddd D MMMM YYYY HH:mm` */
  tooltipFormat?: string;
  tooltipBGColor?: string;
  tooltipSuffix?: string;
};

const DateWithTooltip: React.FC<DateWithTooltipProps> = ({
  date,
  format = 'DD/MM/YY',
  tooltipFormat = DefaultDateTimeTooltipFormat,
  tooltipBGColor,
  tooltipSuffix,
}) => (
  <Tooltip
    content={dayjs(date).format(tooltipFormat) + (tooltipSuffix || '')}
    bgColor={tooltipBGColor}
  >
    <>{dayjs(date).format(format)}</>
  </Tooltip>
);

export default DateWithTooltip;
