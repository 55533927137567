import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';

export interface AppliedRouteProps<P = any> extends RouteProps {
  component: React.ComponentType<P>;
  exact?: boolean;
  path?: string;
  props?: Record<string, any>;
}

const AppliedRoute: React.FC<AppliedRouteProps> = ({
  component: Component,
  exact,
  path,
  props,
}) => {
  return (
    <ErrorBoundary>
      <Route
        exact={exact}
        path={path}
        render={renderProps => <Component {...renderProps} {...props} />}
      />
    </ErrorBoundary>
  );
};

export default AppliedRoute;
