import React from 'react';
import { useMutation } from '@apollo/client';
import { CHANGE_PRODUCT_ORDER_IN_INSTALLATION } from '../../../queries/installatron';
import { Icon, Flex, Box } from '@energiebespaarders/symbols';
import {
  installationByHouseSolution_installationByHouseSolution_items as t_item,
  installationByHouseSolution_installationByHouseSolution as t_installation,
} from '../../../types/generated/installationByHouseSolution';
import { ArrowUp, ArrowDown } from '@energiebespaarders/symbols/icons/solid';
import {
  changeProductOrderInInstallation,
  changeProductOrderInInstallationVariables,
} from '../../../types/generated/changeProductOrderInInstallation';

export default function ({
  installation,
  index,
  loading,
  setLoading,
}: {
  installation: t_installation;
  index: number;
  houseId: string;
  loading: boolean;
  setLoading: (_: boolean) => void;
}) {
  const [changeProductOrderInInstallation] = useMutation<
    changeProductOrderInInstallation,
    changeProductOrderInInstallationVariables
  >(CHANGE_PRODUCT_ORDER_IN_INSTALLATION, {
    onCompleted: () => {
      setLoading(false);
    },
  });

  function getNewProductOrder(items: t_item[], direction: string, index: number) {
    switch (direction) {
      case 'up': {
        if (index === 0) {
          throw new Error('Can not move first product up');
        }
        const item = items[index];
        const itemAbove = items[index - 1];
        items[index - 1] = item;
        items[index] = itemAbove;
        return items.map(i => i.product!.id);
      }
      case 'down': {
        if (index === items.length - 1) {
          throw new Error('Can not move last product down');
        }
        const item = items[index];
        const itemBelow = items[index + 1];
        items[index + 1] = item;
        items[index] = itemBelow;
        return items.map(i => i.product!.id);
      }
      default:
        throw new Error('Invalid direction specified');
    }
  }

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      {index !== 0 && (
        <Box width={1}>
          <Icon
            m={0}
            p={0}
            icon={ArrowUp}
            solid
            fill={loading ? 'grayLight' : 'gray'}
            hoverColor={loading ? 'grayLight' : 'blue'}
            width="1em"
            draggable="true"
            onClick={() => {
              setLoading(true);
              const order = getNewProductOrder([...installation.items], 'up', index);
              changeProductOrderInInstallation({
                variables: {
                  installationId: installation.id,
                  productOrder: order,
                },
              });
            }}
          />
        </Box>
      )}
      {index !== installation.items.length - 1 && (
        <Box width={1}>
          <Icon
            m={0}
            p={0}
            icon={ArrowDown}
            solid
            fill={loading ? 'grayLight' : 'gray'}
            hoverColor={loading ? 'grayLight' : 'blue'}
            width="1em"
            draggable="true"
            onClick={() => {
              setLoading(true);
              const order = getNewProductOrder([...installation.items], 'down', index);
              changeProductOrderInInstallation({
                variables: {
                  installationId: installation.id,
                  productOrder: order,
                },
              });
            }}
          />
        </Box>
      )}
    </Flex>
  );
}
