import React from 'react';
import { Modal } from '@energiebespaarders/symbols';

type Props = {
  closeModal: () => void;
  mobile: boolean;
  isOpen: boolean;
  submit: (update: boolean) => void;
};

const UpdateCalculationsModal: React.FC<Props> = ({ isOpen, closeModal, mobile, submit }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      mobile={mobile}
      minHeight="20vh"
      size="sm"
      title="Herberekenen voor verzending"
      buttons={[
        {
          label: 'Herberekening overslaan',
          bgColor: 'green',
          onClick: () => submit(Boolean(false)),
          inverse: true,
        },
        {
          label: 'Herberekenen',
          bgColor: 'green',
          onClick: () => submit(Boolean(true)),
          inverse: true,
        },
      ]}
    >
      <p>
        De berekeningen voor deze offerte zullen worden geüpdatet met de meest recente opname
        informatie. Als je de besparing handmatig hebt ingevuld, druk dan op 'Herberekening
        overslaan'.
      </p>
    </Modal>
  );
};

export default UpdateCalculationsModal;
