import { useMutation } from '@apollo/client';
import { Box, Flex, Icon, Tooltip } from '@energiebespaarders/symbols';
import { Medium, Red, Small } from '@energiebespaarders/symbols/helpers';
import { Hourglass, MinusCircle, SendUp } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { isProduction } from '../../../config';
import useToaster from '../../../hooks/useToaster';
import PipedriveIcon from '../../../icons/custom/Pipedrive';
import { dealsByHouseSolutionDomain_dealsByHouseSolutionDomain } from '../../../types/generated/dealsByHouseSolutionDomain';
import {
  setDealNoLongerActiveStatus,
  setDealNoLongerActiveStatusVariables,
} from '../../../types/generated/setDealNoLongerActiveStatus';
import { SET_DEAL_LOST } from '../../modals/MarkDealLostModal';
import { useMe } from '../../../hooks/useMe';

const IconContainer = styled.div`
  position: relative;
`;

const IconChild = styled.div<{ slide: boolean }>`
  position: absolute;
  top: 0;
  left: ${p => (p.slide ? '24' : 0)}px;
  opacity: ${p => (p.slide ? '1' : 0)};
  transition: all 300ms;
`;

type Props = {
  deal: dealsByHouseSolutionDomain_dealsByHouseSolutionDomain;
};

const PipedriveIcons: React.FC<
  Props & { slide: boolean; callback: () => any; loading: boolean }
> = ({ deal, slide, callback, loading }) => {
  return (
    <IconContainer>
      <IconChild slide={slide} onClick={callback}>
        <Tooltip
          bgColor={deal.noLongerActive ? 'green' : 'red'}
          content={`${deal.noLongerActive ? 'Opnieuw openen' : 'Deal verloren'}`}
        >
          <Icon
            solid
            fill={deal.noLongerActive ? 'green' : 'red'}
            hoverColor={deal.noLongerActive ? 'green' : 'red'}
            icon={loading ? Hourglass : deal.noLongerActive ? SendUp : MinusCircle}
            mb={0.5}
          />
        </Tooltip>
      </IconChild>
      <a
        href={`https://${
          isProduction ? 'deenergiebespaarders' : 'energiebespaarders-sandbox'
        }.pipedrive.com/deal/${deal.pipedriveDealId}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Tooltip
          bgColor={deal.noLongerActive ? 'red' : 'grayDarker'}
          content={`${deal.noLongerActive ? 'Deal verloren! ' : ''}Ga naar Pipedrive`}
        >
          <Icon
            solid
            fill={deal.noLongerActive ? 'red' : 'grayDarker'}
            hoverColor={deal.noLongerActive ? 'red' : 'grayDarker'}
            icon={PipedriveIcon}
            mb={0.5}
          />
        </Tooltip>{' '}
      </a>
    </IconContainer>
  );
};

const DealTitle: React.FC<Props & { openLoseDealModal: () => void }> = ({
  deal,
  openLoseDealModal,
}) => {
  const [slide, setSlide] = useState(false);
  const toast = useToaster();
  const { me } = useMe();

  const [setDealAsLost, { loading }] = useMutation<
    setDealNoLongerActiveStatus,
    setDealNoLongerActiveStatusVariables
  >(SET_DEAL_LOST, {
    onCompleted: () => {
      toast({
        type: 'success',
        message: 'Deal succesvol aangepast',
      });
    },
    onError: e => {
      toast({
        type: 'error',
        message: `Er is iets misgegaan! ${e.message}`,
      });
    },
  });

  return (
    <Medium onMouseOver={() => setSlide(true)} onMouseLeave={() => setSlide(false)}>
      <Flex justifyContent="space-between">
        <Box>
          <Flex justifyContent="flex-start">
            <Box>
              {deal.reference} ({deal.quotes.length} offerte{`${deal.quotes.length > 1 ? 's' : ''}`}
              ){' '}
            </Box>
            {deal.pipedriveDealId ? (
              <Box width={'60px'} ml={1}>
                <PipedriveIcons
                  deal={deal}
                  slide={slide}
                  loading={loading}
                  callback={
                    deal.noLongerActive
                      ? () =>
                          setDealAsLost({
                            variables: {
                              dealId: deal.id,
                              details: {
                                comment: 'Lost via EBH',
                                reason: 'Waiting for form',
                                lostDate: new Date(),
                                responsible: me.id,
                              },
                            },
                          })
                      : openLoseDealModal
                  }
                />
              </Box>
            ) : (
              ''
            )}
          </Flex>
        </Box>

        {deal.onHold?.since ? (
          <>
            &nbsp;&nbsp;[
            <Small>
              ON HOLD sinds {dayjs(deal.onHold.since).format('DD/MM/YY')} vanwege{' '}
              {deal.onHold.reason}
            </Small>
            ]
          </>
        ) : (
          <></>
        )}

        {deal.noLongerActive ? (
          <Box>
            <Red>
              <Small>{deal.noLongerActive.reason}</Small>
            </Red>
          </Box>
        ) : (
          <></>
        )}
      </Flex>
    </Medium>
  );
};

export default DealTitle;
