import { gql } from '@apollo/client';
import { RatingFragment } from '../fragments';

export const ADD_RATING = gql`
  ${RatingFragment}

  mutation addRating(
    $sent: Date
    $plannedOn: Date
    $plannedFor: Date
    $jobId: ID!
    $rating: RatingInput!
    $installationDoneOn: Date!
    $addFollowUp: Boolean!
  ) {
    addRating(
      jobId: $jobId
      rating: $rating
      installationDoneOn: $installationDoneOn
      addFollowUp: $addFollowUp
      sent: $sent
      plannedOn: $plannedOn
      plannedFor: $plannedFor
    ) {
      id
      installer {
        id
        name
      }
      completed
      quote {
        id
        reference
        house {
          id
          address {
            street
            zip
            number
            suffix
          }
          customer {
            id
            firstName
            lastName
          }
        }
      }
      rating {
        ...Rating
      }
    }
  }
`;
