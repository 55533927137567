import { gql, useMutation } from '@apollo/client';
import { useForm } from '@energiebespaarders/hooks';
import {
  Box,
  Button,
  Card,
  Flex,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Separator,
  Spacer,
  TextLink,
  Textarea,
  Uploader,
} from '@energiebespaarders/symbols';
import { Center, Right, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import { Cancel, MagicWand, SpecialCheck } from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useMe } from '../../hooks/useMe';
import useToaster from '../../hooks/useToaster';
import {
  createLinearIssue,
  createLinearIssueVariables,
} from '../../types/generated/createLinearIssue';

const CREATE_ISSUE = gql`
  mutation createLinearIssue(
    $username: String!
    $team: String!
    $title: String!
    $description: String
    $relevantURLs: [String!]
    $attachments: [Upload!]
    $dueDate: Date
    $priority: Int
  ) {
    createLinearIssue(
      username: $username
      team: $team
      title: $title
      description: $description
      relevantURLs: $relevantURLs
      attachments: $attachments
      dueDate: $dueDate
      priority: $priority
    )
  }
`;

type TicketType = '' | 'bug' | 'change' | 'feature' | 'support';

interface FormValues {
  ticketType: TicketType;
  team: string;
  title: string;
  description: string;
  attachments: any[]; // should be File but useForm sucks
  relevantURL1: string;
  relevantURL2: string;
  dueDate: string;
  priority: number;
  size: string;
  otherSolutions: string;
}

const initialValues: FormValues = {
  ticketType: '',
  title: '',
  description: '',
  team: '',
  relevantURL1: '',
  relevantURL2: '',
  attachments: [],
  dueDate: '',
  priority: 0,
  size: '',
  otherSolutions: '',
};

export const LinearIssueForm: React.FC<{ onSuccess?: () => void; closeModal?: () => void }> = ({
  onSuccess,
  closeModal,
}) => {
  const { me } = useMe();
  const toast = useToaster();

  const [createIssue, { loading, error, data: result }] = useMutation<
    createLinearIssue,
    createLinearIssueVariables
  >(CREATE_ISSUE, {
    onCompleted: res => {
      toast({ type: 'success', message: 'Ticket aangemaakt! ' + res.createLinearIssue });
      onSuccess?.();
    },
    onError: error => toast({ type: 'error', message: 'Ticket kon niet worden aangemaakt', error }),
  });

  const { formState, submitForm, handleChange, formHasErrors } = useForm<FormValues>({
    initialValues,
    blockEnterToSubmit: true,
    handleSubmit(values) {
      const username = `${me.firstName} ${me.lastName} (${me.team})`;

      const title = `${values.title} [P${values.priority}${values.size && `, ${values.size}`}]`;
      let description = values.description;
      if (values.otherSolutions) {
        description += `\n\nAndere oplossingen: ${values.otherSolutions}`;
      }
      let team = values.team;
      if (values.team === 'other') {
        // If team is not known, just mention that in the description, and add to PM triage
        team = 'PM';
        description = 'Bedoeld voor team: Anders / weet ik niet\n\n' + description;
      }

      createIssue({
        variables: {
          ...values,
          title,
          username,
          team,
          description,
          relevantURLs: [values.relevantURL1, values.relevantURL2].filter(v => !!v),
          dueDate: values.dueDate ? new Date(values.dueDate) : undefined,
        },
      }).then(() => {
        // Reset form on completion
        for (const [key, value] of Object.entries(initialValues)) {
          handleChange({ [key]: value });
        }
      });
    },
    validate(values, errors) {
      if (!values.title) errors.title = 'Ticketnaam ontbreekt';
      if (values.title.length < 20) errors.title = 'Min. 20 karakters';
      if (!values.description) errors.description = 'Voeg wat extra informatie toe';
      if (!values.team) errors.team = 'Team ontbreekt';
      if (typeof values.priority !== 'number') errors.priority = 'Prioriteit ontbreekt';
      return errors;
    },
  });

  const buttonColor = useMemo(() => {
    switch (formState.team.value) {
      case 'DEV':
        return 'green';
      case 'UX':
        return 'orange';
      case 'DATA':
        return 'blue';
      case 'PM':
        return 'purple';
      default:
        return 'grayLight';
    }
  }, [formState.team.value]);

  const handlePasteCurrentUrl = useCallback(() => {
    if (!formState.relevantURL1.value) handleChange({ relevantURL1: window.location.href });
    else if (!formState.relevantURL2.value) handleChange({ relevantURL2: window.location.href });
  }, [formState.relevantURL1.value, formState.relevantURL2.value, handleChange]);

  useEffect(() => {
    // Listen to paste events, so you can quickly share a screengrab by pasting anywhere on the page!
    const onPaste = (evt: ClipboardEvent) => {
      if (!evt.clipboardData) return;
      const clipboardItems = evt.clipboardData.items;

      // Filter the image items only
      const items = Array.from(clipboardItems).filter(item => item.type.startsWith('image'));
      if (items.length === 0) return;

      const item = items[0];
      handleChange({
        attachments: [...(formState.attachments as any).value, item.getAsFile()!] as File[],
      });
    };

    document.addEventListener('paste', onPaste);
    return () => document.removeEventListener('paste', onPaste);
  }, [formState.attachments, handleChange]);

  return result?.createLinearIssue ? (
    <div>
      <Center block>
        <Icon icon={SpecialCheck} fill="green" width="60px" />
      </Center>

      <p>
        Gelukt, dank voor het invullen! Er wordt contact met je opgenomen als deze in behandeling is
        genomen. Voortgang kun je evt. ophalen bij Leo of Floor.
      </p>
      <p>
        Je kunt ook zelf de voortgang volgen van je ticket(s) op{' '}
        <Link to="/tickets" onClick={closeModal}>
          deze pagina
        </Link>
        .
      </p>
    </div>
  ) : (
    <form onSubmit={submitForm}>
      <Card shadow="none" animation="none" borderColor="blue" bgColor="blueLighter" p={1}>
        <Center block>🇬🇧 We prefer tickets in English! 🇬🇧</Center>
      </Card>

      <RadioGroup
        onChange={(e: string) => handleChange({ ticketType: e })}
        label="Wat voor ticket betreft het?"
        name="ticketType"
        id="ticketType"
        divider={4}
        labelSize={5}
        fontSize={6}
        {...formState.ticketType}
      >
        <Radio label="Bug" value="bug" id="bug" bgColor="red" />
        <Radio label="Aanpassing" value="change" id="change" bgColor="orange" />
        <Radio label="Nieuwe functie/idee" value="feature" id="feature" bgColor="blue" />
        <Radio label="Hulp/Ondersteuning" value="support" id="support" bgColor="purple" />
      </RadioGroup>

      <RadioGroup
        onChange={(e: string) => handleChange({ team: e })}
        label="Voor welk team is je verzoek?"
        name="team"
        id="team"
        divider={4}
        labelSize={5}
        fontSize={6}
        {...formState.team}
      >
        <Radio label="DEV" value="DEV" id="DEV" bgColor="green" />
        <Radio label="UX" value="UX" id="UX" bgColor="orange" />
        <Radio label="DATA" value="DATA" id="DATA" bgColor="blue" />
        <Radio label="PM / Onbekend" value="PM" id="PM" bgColor="purple" />
      </RadioGroup>

      <Input
        label="Ticketnaam"
        fontSize={6}
        labelSize={5}
        placeholder="Beschrijf in één zin je probleem, feedback of idee"
        {...formState.title}
        onChange={e => handleChange({ title: e.target.value })}
        maxLength={150}
        minLength={20}
      />

      <Textarea
        label="Omschrijving"
        fontSize={6}
        labelSize={5}
        placeholder="Voeg meer informatie toe over het probleem of idee"
        {...formState.description}
        onChange={e => handleChange({ description: e.target.value })}
        maxLength={3000}
      />

      {['change', 'feature'].includes(formState.ticketType.value) && (
        <Textarea
          label="Alternatieve oplossingen"
          fontSize={6}
          labelSize={5}
          placeholder="Is het handmatig op te lossen? Of automatiseerbaar met Workato?"
          {...formState.otherSolutions}
          onChange={e => handleChange({ otherSolutions: e.target.value })}
          maxLength={3000}
        />
      )}

      {formState.ticketType.value === 'bug' && (
        <RadioGroup
          onChange={(e: number) => handleChange({ size: e })}
          divider={5}
          label="Hoe groot is/acht je het probleem?"
          labelSize={5}
          fontSize={6}
          name="size"
          id="size"
          {...formState.size}
        >
          <Radio label="XS" value="XS" id="XS" bgColor="greenDark" />
          <Radio label="S" value="S" id="S" bgColor="green" />
          <Radio label="M" value="M" id="M" bgColor="gold" />
          <Radio label="L" value="L" id="L" bgColor="red" />
          <Radio label="XL" value="XL" id="XL" bgColor="redDark" />
        </RadioGroup>
      )}

      <RadioGroup
        onChange={(e: number) => handleChange({ priority: e })}
        divider={3}
        label="Wat is volgens jou de prioriteit?"
        labelSize={5}
        fontSize={6}
        name="priority"
        id="priority"
        {...formState.priority}
      >
        <Radio label="Noodgeval" value={1} id="1" bgColor="redDark" />
        <Radio label="Nodig voor huidige doelstelling" value={2} id="2" bgColor="orangeDark" />
        <Radio label="Gewenst voor huidige doelstelling" value={3} id="3" bgColor="goldDark" />
        <Radio label="Niet tijdgevoelig" value={4} id="4" bgColor="blueDark" />
        <Radio label="Weet ik niet" value={0} id="0" bgColor="grayDark" />
      </RadioGroup>

      <Separator text="Optioneel" fontSize={7} my={1} />

      <Flex flexWrap="wrap" mx="-3px">
        <Box width={1 / 3} px="3px">
          <Input
            {...formState.dueDate}
            value={formState.dueDate.value.toString()}
            type="date"
            label="Is er een deadline? (optioneel)"
            fontSize={6}
            labelSize={5}
            onChange={e => handleChange({ dueDate: e.target.value })}
            min={new Date().toISOString().split('T')[0]}
          />
        </Box>
        <Box width={1 / 3} px="3px">
          <Input
            {...formState.relevantURL1}
            type="url"
            label="Relevante link 1"
            fontSize={6}
            labelSize={5}
            placeholder="https://energiebeheerders/house/..."
            onChange={e => handleChange({ relevantURL1: e.target.value })}
            mb={0}
          />
          <Smaller>
            <TextLink onClick={handlePasteCurrentUrl}>→ Plak huidige EBH-pagina</TextLink>
          </Smaller>
        </Box>
        <Box width={1 / 3} px="3px">
          <Input
            {...formState.relevantURL2}
            type="url"
            label="Relevante link 2"
            fontSize={6}
            labelSize={5}
            placeholder="https://deenergiebespaarders.pipedrive.com/..."
            onChange={e => handleChange({ relevantURL2: e.target.value })}
          />
        </Box>
      </Flex>

      <Spacer vertical amount={3} />

      <Uploader
        loading={loading}
        multiple
        text={
          'Voeg hier bijlagen toe om de ticket te verduidelijken. \n\n Je kunt ook afbeeldingen uit het klembord plakken.'
        }
        upload={({ variables }: { variables: { files: FileList } }) => {
          const newAttachments = [...(formState.attachments as any).value];
          for (const file of variables.files) {
            newAttachments.push(file);
          }
          handleChange({ attachments: newAttachments });
        }}
        mb={1}
      />
      {formState.attachments.value.length === 0 && (
        <Center block>
          <i>Nog geen bijlages toegevoegd</i>
        </Center>
      )}
      <Flex flexWrap="wrap">
        {formState.attachments.value.map((at, i) => (
          <Box width={[1, 1, 1 / 2]} key={`${at.name}-${i}`}>
            <Card m={1}>
              <Flex>
                <Box flexGrow={1}>{at.name}</Box>
                <Box>
                  <Icon
                    icon={Cancel}
                    fill="gray"
                    solid
                    hoverColor="red"
                    onClick={() =>
                      handleChange({
                        attachments: formState.attachments.value.filter(otherAt => otherAt !== at),
                      })
                    }
                  />
                </Box>
              </Flex>
            </Card>
          </Box>
        ))}
      </Flex>
      {/* TODO: preview files to upload */}
      {/* TODO: built-in screen recorder, or click to point at the problem */}

      <Button
        type="submit"
        fluid
        loading={loading}
        error={error?.toString()}
        label={`Maak een ticket voor ${formState.team.value || 'Product'}`}
        iconStart={MagicWand}
        mt={2}
        bgColor={buttonColor}
        // TODO: this formHasErrors keeps sticking around after solving the error, doesn't get revalidated after updating the input
        disabled={formHasErrors}
      />

      <Right block>
        <Small>
          <Link to="/tickets" onClick={closeModal}>
            Volg hier de voortgang van je ticket(s) →
          </Link>
        </Small>
      </Right>
    </form>
  );
};
