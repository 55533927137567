import { Solution } from '@energiebespaarders/constants';
import { intakeOverview } from '../../../types/generated/intakeOverview';

/**
 *     WALLS
 */
const wallMissingAnswers = (intakeData: intakeOverview): string[] => {
  const missing = [] as string[];

  intakeData.house.situation.walls.platformRequired ?? missing.push('Steiger/hoogwerker nodig');
  intakeData.house.situation.walls.solutionAdvised ?? missing.push('Oplossing gevel besproken');
  intakeData.house.situation.walls.cavityWidth ?? missing.push('Spouwbreedte');
  intakeData.house.intake.walls.insulatableArea ?? missing.push('Isoleerbaar geveloppervlak');
  // intakeData.house.situation.walls.rubbish ?? missing.push('Spouwvervuiling');
  intakeData.house.situation.walls.ventilationGrillesRequired ??
    missing.push('Ventilatieroosters nodig');

  if (
    intakeData.house.situation.walls.solutionAdvised !== 'foam' &&
    !intakeData.house.situation.walls.amountCavityBrush
  ) {
    // Cavity brushes only required for non-foam products
    intakeData.house.situation.walls.amountCavityBrush ?? missing.push('Spouwborstels');
  }
  // intakeData.house.situation.walls.drillDiameter ?? missing.push('Muur boordiameter');

  return missing;
};

const wallBlockingAnswers = (intakeData: intakeOverview): string[] => {
  let blocking = [] as string[];

  if (
    !(
      intakeData.house.situation.walls.paint === 'breathable' ||
      intakeData.house.situation.walls.paint === 'no'
    ) &&
    intakeData.house.situation.walls.rubbish
  )
    blocking = blocking.concat(['Muren geverfd', 'Spouwvervuiling']);

  return blocking;
};

/**
 *     FLOOR
 */

const floorGenericMissingAnswers = (intakeData: intakeOverview): string[] => {
  const missing = [] as string[];

  intakeData.house.situation.crawlspace.hatchAccessible ?? missing.push('Kruipluik toegankelijk');
  intakeData.house.situation.crawlspace.compartmentsAccessible ??
    missing.push('Compartimenten bereikbaar');
  (intakeData.house.situation.crawlspace.compartmentsAccessible !== 'yes' &&
    intakeData.house.situation.crawlspace.passagesRequired) ??
    missing.push('Doorgangen nodig');
  (!intakeData.house.intake.floor.types || intakeData.house.intake.floor.types.length === 0) &&
    missing.push('Aanwezige vloertypes');
  intakeData.house.intake.crawlspace.ventilation ?? missing.push('Kruipruimte ventilatie');
  intakeData.house.situation.floor.ventilationGrillesRequired ??
    missing.push('Ventilatieroosters nodig');

  return missing;
};

const floorMissingAnswers = (intakeData: intakeOverview): string[] => {
  const missing = floorGenericMissingAnswers(intakeData);

  intakeData.house.intake.floor.insulatableArea ?? missing.push('Isoleerbaar vloeroppervlak');
  (['ecofoam', 'pur'].includes(intakeData.house.situation.floor.solutionAdvised || '') &&
    intakeData.house.situation.crawlspace.insulationPerimeter) ??
    missing.push('Kruipruimte isolatie omtrek');
  intakeData.house.intake.crawlspace.height ?? missing.push('Hoogte kruipruimte');
  intakeData.house.situation.floor.solutionAdvised ?? missing.push('Aanbevolen oplossing');

  return missing;
};

const floorBlockingAnswers = (intakeData: intakeOverview): string[] => {
  const blocking = [] as string[];

  const { height } = intakeData.house.intake.crawlspace;
  if (height !== null && height < 50) {
    blocking.push('Hoogte kruipruimte');
  }

  return blocking;
};

const crawlspaceMissingAnswers = (intakeData: intakeOverview): string[] => {
  const missing = floorGenericMissingAnswers(intakeData);

  intakeData.house.intake.crawlspace.insulatableArea ?? missing.push('Isoleerbaar bodemoppervlak');
  intakeData.house.situation.crawlspace.advisedInsulationThickness ??
    missing.push('Geadviseerde bodemisolatiedikte');

  return missing;
};

const crawlspaceBlockingAnswers = (intakeData: intakeOverview): string[] => {
  const blocking = [] as string[];

  const { height } = intakeData.house.intake.crawlspace;
  if (height !== null && height < 30) {
    blocking.push('Hoogte kruipruimte');
  }

  return blocking;
};

/**
 *     ROOF
 */
const atticFloorMissingAnswers = (intakeData: intakeOverview): string[] => {
  const missing = [] as string[];
  intakeData.house.intake.roof.insulatableAtticFloorArea ??
    missing.push('Isoleerbaar zoldervloeroppervlak');

  intakeData.house.situation.roof.advisedAtticFloorInsulationThickness ??
    missing.push('Geadviseerde zoldervloerisolatieproduct');
  return missing;
};

const innerRoofMissingAnswers = (intakeData: intakeOverview): string[] => {
  const missing = [] as string[];
  intakeData.house.situation.roof.advisedInnerRoofInsulationProduct ??
    missing.push('Geadviseerde dakisolatieproduct');
  intakeData.house.intake.roof.insulatableInnerRoofArea ?? missing.push('Isoleerbaar dakoppervlak');
  intakeData.house.intake.roof.type ?? missing.push('Daktype');
  return missing;
};

/**
 *    GLAZING
 */
const windowGlazingMissingAnswers = (intakeData: intakeOverview): string[] => {
  const missing = [] as string[];
  intakeData.house.situation.windows.areaRequiringReplacementHr ??
    missing.push('Oppervlak voor te vervangende ramen');
  intakeData.house.situation.windows.areaRequiringReplacementSafety ??
    missing.push('Oppervlak voor vervangend veiligheidsglas');
  (!intakeData.house.situation.windows.floorsRequiringReplacement ||
    intakeData.house.situation.windows.floorsRequiringReplacement.length === 0) &&
    missing.push('Verdiepingen met te vervangen ramen');
  intakeData.house.situation.windows.largestRequiringReplacement ??
    missing.push('Grootte van grootste te vervangen raam');

  return missing;
};

const missingAnswers = (solution: Solution, intakeData: intakeOverview): string[] => {
  switch (solution) {
    case Solution.WallInsulation:
      return wallMissingAnswers(intakeData);
    case Solution.FloorInsulation:
      return floorMissingAnswers(intakeData);
    case Solution.CrawlspaceInsulation:
      return crawlspaceMissingAnswers(intakeData);
    case Solution.InnerRoofInsulation:
      return innerRoofMissingAnswers(intakeData);
    case Solution.AtticFloorInsulation:
      return atticFloorMissingAnswers(intakeData);
    case Solution.WindowGlazing:
      return windowGlazingMissingAnswers(intakeData);
    case Solution.WindowFrames:
      return [];
    case Solution.PvSystem:
      return [];
    case Solution.CentralHeatingBoiler:
      return [];
    case Solution.HybridHeatPump:
      return [];
    case Solution.ElectricHeatPump:
      return [];
    default:
      return ['Oplossing onbekend'];
  }
};

const blockingAnswers = (solution: Solution, intakeData: intakeOverview): string[] => {
  switch (solution) {
    case Solution.WallInsulation:
      return wallBlockingAnswers(intakeData);
    case Solution.FloorInsulation:
      return floorBlockingAnswers(intakeData);
    case Solution.CrawlspaceInsulation:
      return crawlspaceBlockingAnswers(intakeData);
    case Solution.InnerRoofInsulation:
      return []; // none are blocking
    case Solution.AtticFloorInsulation:
      return []; // none are blocking
    case Solution.WindowGlazing:
      return [];
    case Solution.WindowFrames:
      return [];
    case Solution.PvSystem:
      return [];
    case Solution.CentralHeatingBoiler:
      return [];
    case Solution.HybridHeatPump:
      return [];
    case Solution.ElectricHeatPump:
      return [];
    default:
      return ['Oplossing onbekend'];
  }
};

export default {
  missingAnswers,
  blockingAnswers,
};
