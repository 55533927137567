import { gql, useQuery } from '@apollo/client';
import { Select } from '@energiebespaarders/symbols';
import { Red } from '@energiebespaarders/symbols/helpers';
import React, { useMemo } from 'react';
import { GET_OPERATORS } from '../../../queries/operatorDirectory';
import { getOperators, getOperatorsVariables } from '../../../types/generated/getOperators';
import { useMe } from '../../../hooks/useMe';

export const UPDATE_SALES_MANAGER = gql`
  mutation updateDealSalesManager($dealId: ID!, $deal: DealInput!) {
    updateDeal(dealId: $dealId, deal: $deal) {
      id
      salesManagerId
      salesManager {
        id
        firstName
        lastName
      }
    }
  }
`;

interface SalesManagerPickerProps {
  salesManagerId: string;
  setSalesManagerId: (salesManagerId: string) => void;
  disabled?: boolean;
}

const SalesManagerPicker: React.FC<SalesManagerPickerProps> = ({
  salesManagerId,
  setSalesManagerId,
  disabled,
}) => {
  const { me } = useMe();

  const { data: operatorsData, loading: operatorsLoading, error: operatorsError } = useQuery<
    getOperators,
    getOperatorsVariables
  >(GET_OPERATORS);

  const salesManagerOptions = useMemo(() => {
    if (operatorsLoading) return [{ value: 'Laden...', label: 'Laden...' }];
    if (operatorsData)
      return operatorsData?.operators
        .filter(o => o.id === me.id || o.team?.toLowerCase().includes('sales'))
        .map(operator => ({
          value: operator.id,
          label: `${operator.firstName} ${operator.lastName}`,
        }));
    return [];
  }, [operatorsLoading, operatorsData, me.id]);

  const selectedSalesManagerOption = salesManagerOptions.find(o => o.value === salesManagerId);

  return (
    <>
      {operatorsError ? (
        <Red>{operatorsError.message}</Red>
      ) : (
        <Select<string>
          clearable={false}
          label="Sales specialist:"
          onChange={e => setSalesManagerId(e.value)}
          options={salesManagerOptions}
          value={selectedSalesManagerOption}
          loading={operatorsLoading}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default SalesManagerPicker;
