import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import HouseUploader from '../HouseUploader';
import HouseImagePicker from '../HouseImagePicker';
import { Modal, Input, Tabs, Tab } from '@energiebespaarders/symbols';
import QuotesImagePicker from '../QuotesImagePicker';

const UploadPickerModal = ({
  closeModal,
  houseId,
  isOpen,
  mobile,
  onPick,
  topic,
  adviceText,
  allowUrl = true,
  allowReset = false,
  // default selected img id
  initialState,
}) => {
  const [selectedFile, setSelectedFile] = useState(initialState ? { id: initialState } : undefined);
  const handlePick = useCallback(() => onPick(selectedFile), [selectedFile, onPick]);
  return (
    <Modal
      key="modal-upload-picker"
      isOpen={isOpen}
      onRequestClose={closeModal}
      title="Foto kiezen"
      mobile={mobile}
      timeout={mobile ? 250 : undefined}
      buttons={[
        {
          bgColor: 'red',
          inverse: true,
          label: 'Sluiten',
          onClick: closeModal,
          m: 0,
        },
        ...(allowReset
          ? [
              {
                bgColor: 'green',
                inverse: true,
                label: 'Reset',
                onClick: () => onPick(undefined),
                disabled: !selectedFile,
              },
            ]
          : []),
        {
          bgColor: 'green',
          inverse: true,
          label: 'Invoegen',
          onClick: handlePick,
          disabled: !selectedFile,
        },
      ]}
    >
      <HouseUploader
        houseId={houseId}
        tags={['intake', topic, ...(adviceText ? ['adviceText'] : [])]}
        onUploadFinished={uploads => uploads.length > 0 && setSelectedFile(uploads[0])}
      />
      {/* Switch between which images are shown: This solution, intake or from all quotes */}
      <Tabs>
        <Tab name="current-solution" label="Dit onderwerp">
          <HouseImagePicker
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            houseId={houseId}
            tags={[topic]}
            exclusiveMatch
          />
        </Tab>
        <Tab name="all-solutions" label="Opname foto's">
          <HouseImagePicker
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            houseId={houseId}
            tags={[]}
            exclusiveMatch
          />
        </Tab>
        <Tab name="all-solutions" label="Offerte foto's">
          <QuotesImagePicker
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            houseId={houseId}
            tags={[]}
            exclusiveMatch
          />
        </Tab>
      </Tabs>

      {allowUrl && (
        <>
          <br />
          <br />
          Of voeg een URL in:
          <br />
          <Input placeholder="https://..." onChange={e => setSelectedFile(e.target.value)} />
        </>
      )}
    </Modal>
  );
};

UploadPickerModal.propTypes = {
  closeModal: PropTypes.func,
  houseId: PropTypes.string,
  topic: PropTypes.string,
  /** Returns the file object or URL */
  onPick: PropTypes.func,
  allowUrl: PropTypes.bool,
  /** Image ID or URL */
  initialState: PropTypes.string,
};

export default UploadPickerModal;
