import { gql, useQuery } from '@apollo/client';
import { Box, Button, Container, Flex, Wrapper } from '@energiebespaarders/symbols';
import { Add, Sync } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BrandCard from '../../components/brands/BrandCard';
import AddBrandModal from '../../components/modals/AddBrandModal';
import UpdateBrandModal from '../../components/modals/UpdateBrandModal';
import { getBrands, getBrands_brands } from '../../types/generated/getBrands';

export const GET_BRANDS = gql`
  query getBrands {
    brands {
      id
      name
      logo {
        id
        extension
      }
    }
  }
`;

const BrandsOverview: React.FC = () => {
  const [showAddBrandModal, setShowAddBrandModal] = useState(false);
  const [editBrandId, setEditBrandId] = useState<getBrands_brands | null>(null);

  const { data, refetch } = useQuery<getBrands>(GET_BRANDS);
  const brands = [...(data?.brands || ([] as getBrands_brands[]))];

  const handleCloseAddBrandModal = () => {
    setShowAddBrandModal(false);
    refetch();
  };

  return (
    <>
      <Helmet title="Productmerken" />
      <Wrapper p={4}>
        <Container>
          {showAddBrandModal && <AddBrandModal open handleClose={handleCloseAddBrandModal} />}
          {editBrandId && (
            <UpdateBrandModal
              refetchBrands={() => refetch()}
              open={!!editBrandId}
              handleClose={() => setEditBrandId(null)}
              brand={editBrandId}
            />
          )}
          <Flex flexDirection="column">
            <h2>
              Productmerken{' '}
              <Button
                bgColor="green"
                label="Verversen"
                onClick={() => refetch()}
                ml={3}
                minimal
                iconStart={Sync}
              />
            </h2>
            <Box mb={4} pr={1} width={[1, 1, 1 / 2]}>
              <Button
                fluid
                iconStart={Add}
                label="Merk toevoegen"
                minIconSize="1em"
                onClick={() => setShowAddBrandModal(true)}
                mb={0}
              />
            </Box>

            <Flex flexWrap="wrap" mx={-1}>
              {brands
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(b => (
                  <BrandCard key={b.id} brand={b} showUpdateBrandModal={() => setEditBrandId(b)} />
                ))}
            </Flex>
          </Flex>
        </Container>
      </Wrapper>
    </>
  );
};
export default BrandsOverview;
