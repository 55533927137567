import { gql } from '@apollo/client';

export const SUPPLIERS_BY_PRODUCT = gql`
  query suppliersByProduct($productId: ID!) {
    suppliersByProduct(productId: $productId) {
      id
      name
    }
  }
`;
