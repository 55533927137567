import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { SOLUTIONS_NL } from '@energiebespaarders/constants';
import { Box, Button, Flex, Modal, Select, Switch } from '@energiebespaarders/symbols';
import { Color, Red, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import { Pencil, Trash } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import { useMe } from '../../../hooks/useMe';
import useToaster from '../../../hooks/useToaster';
import { checkTeamMember } from '../../../lib/permissions';
import { INSTALLATION_BY_HOUSE_SOLUTION, UPDATE_INSTALLATION } from '../../../queries/installatron';
import {
  availableQuoteTemplatesBySolution,
  availableQuoteTemplatesBySolutionVariables,
} from '../../../types/generated/availableQuoteTemplatesBySolution';
import { BasicAddress_address } from '../../../types/generated/BasicAddress';
import {
  createQuoteTemplate,
  createQuoteTemplateVariables,
} from '../../../types/generated/createQuoteTemplate';
import {
  deleteQuoteTemplate,
  deleteQuoteTemplateVariables,
} from '../../../types/generated/deleteQuoteTemplate';
import {
  installationByHouseSolution,
  installationByHouseSolutionVariables,
} from '../../../types/generated/installationByHouseSolution';
import {
  installersAndSuppliersBySolution,
  installersAndSuppliersBySolutionVariables,
  installersAndSuppliersBySolution_installersBySolution,
} from '../../../types/generated/installersAndSuppliersBySolution';
import {
  updateInstallation,
  updateInstallationVariables,
} from '../../../types/generated/updateInstallation';
import {
  updateQuoteTemplate,
  updateQuoteTemplateVariables,
} from '../../../types/generated/updateQuoteTemplate';
import { InstallerStatus, PriceAvailability, Solution } from '../../../types/graphql-global-types';
import InstallerDropdownLabel from '../../companies/InstallerDropdownLabel';
import DateWithTooltip from '../../DateWithTooltip';
import { OperatorTeam } from '../../operatorDirectory/OperatorProfile';
import { useCreateInstallation } from '../InstallatronCore/CreateInstallation';
import { SelectFilterOption } from '../InstallatronCore/DropdownOptions';
import { createTemplateInput, quoteTemplateActionBar } from './actionBar';
import {
  updateCacheOnTemplateCreation,
  updateCacheOnTemplateDeletion,
  updateCacheOnTemplateUpdate,
} from './cacheUpdates';
import {
  AVAILABLE_QUOTE_TEMPLATES,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  INSTALLERS_AND_SUPPLIERS_BY_SOLUTION,
  UPDATE_TEMPLATE,
} from './queries';
import TemplateBuilder from './TemplateBuilder';

export type TemplateItem = {
  supplierId: string;
  amount: number;

  product: {
    id: string;
    title: string;
    archived?: Date;
  };

  price: {
    retailPrice: number;
    purchasePrice: number;
    availability: PriceAvailability | null;
  };
};

type TemplatePickerProps = {
  solution: Solution;
  address: BasicAddress_address;
  isOpen: boolean;
  closeModal: () => void;
  initialItems?: TemplateItem[];
};

const StatusOrder: Record<InstallerStatus, number> = {
  [InstallerStatus.active]: 0,
  [InstallerStatus.setup]: 1,
  [InstallerStatus.paused]: 2,
  [InstallerStatus.inactive]: 3,
};

const formatInstallerSortKey = (inst: installersAndSuppliersBySolution_installersBySolution) =>
  `${inst.capableOfInstallingAtHouse ? 0 : 1}${StatusOrder[inst.status.value]}${inst.name}`;

const TemplatePicker: React.FC<TemplatePickerProps> = ({
  closeModal,
  address,
  solution,
  isOpen,
  initialItems,
}) => {
  const toast = useToaster();
  const { activeHouseId } = useActiveHouseId();

  // eslint-disable-next-line prettier/prettier
  const [selectedSupplier, setSelectedSupplier] = useState<string | undefined>(
    initialItems?.[0]?.supplierId,
  );
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [items, setItems] = useState<TemplateItem[]>(initialItems || []);

  const { me } = useMe();
  const isDev =
    checkTeamMember(me, OperatorTeam.Development) ||
    checkTeamMember(me, OperatorTeam.UserExperience);

  const [hasManagementPermission, setHasManagementPermission] = useState(
    (me.role === 'manager' && me.team?.includes(OperatorTeam.Sales)) ||
      me.role === 'admin' ||
      checkTeamMember(me, OperatorTeam.NetworkSuccess) ||
      checkTeamMember(me, OperatorTeam.MT) ||
      checkTeamMember(me, OperatorTeam.UserExperience) ||
      checkTeamMember(me, OperatorTeam.Development),
  );

  const basicAddress = useMemo(
    () => ({ zip: address.zip, number: address.number, suffix: address.suffix }),
    [address],
  );

  const {
    data: installersData,
    loading: installersLoading,
    error: installersError,
    refetch: refetchInstallers,
  } = useQuery<installersAndSuppliersBySolution, installersAndSuppliersBySolutionVariables>(
    INSTALLERS_AND_SUPPLIERS_BY_SOLUTION,
    { variables: { solution, houseId: activeHouseId } },
  );

  const supplierOptions = useMemo(
    () =>
      [...(installersData?.installersBySolution || [])]
        ?.sort((a, b) =>
          formatInstallerSortKey(a).localeCompare(formatInstallerSortKey(b), 'nl', {
            numeric: true,
          }),
        )
        .map(inst => {
          return {
            value: inst.pairedSupplierId!,
            label: (
              <InstallerDropdownLabel
                installerStatus={inst.status.value}
                name={inst.name}
                capableOfInstallingAtHouse={inst.capableOfInstallingAtHouse}
              />
            ),
            searchValue: inst.name,
          };
        }),
    [installersData?.installersBySolution],
  );

  const { data: templatesData, loading: templatesLoading, error: templatesError } = useQuery<
    availableQuoteTemplatesBySolution,
    availableQuoteTemplatesBySolutionVariables
  >(AVAILABLE_QUOTE_TEMPLATES, {
    variables: {
      solution,
      address: basicAddress,
    },
    fetchPolicy: 'cache-and-network',
  });

  const activeTemplate = templatesData?.quoteTemplates.find(t => t.id === selectedTemplate);

  // When a template is selected, its supplier will be the "active" supplier; whose products will be shown as available
  const activeSupplierId = activeTemplate?.supplier.id || selectedSupplier;

  // TODO: Filter out (or indicate) templates for installers that aren't working in this region
  const templateOptions = useMemo(
    () =>
      templatesData?.quoteTemplates
        ?.filter(template => (hasManagementPermission ? true : template.isActive))
        .filter(template => (selectedSupplier ? template.supplier.id === selectedSupplier : true))
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(template => ({
          label: (
            <>
              {template.isActive ? (
                template.title
              ) : (
                <Color c="darkGray">{template.title} (Inactief)</Color>
              )}{' '}
              <Small>
                <Color c="darkGray">
                  {dayjs(template.modifiedOn || template.createdOn).format('DD/MM/YY')}
                </Color>
              </Small>
            </>
          ),
          value: template.id,
          searchValue: template.title,
        })) || [],
    [hasManagementPermission, selectedSupplier, templatesData?.quoteTemplates],
  );

  const handleSupplierChange = useCallback(
    (supplierId?: string) => {
      setSelectedSupplier(supplierId);

      // If no template was selected, clear the items, so that a fresh new template can be created
      // (otherwise the items of the previously selected supplier will remain on the template intended
      // for a different supplier)
      if (!activeTemplate) {
        setItems([]);
      }
    },
    [activeTemplate],
  );

  const handleTemplateChange = useCallback(
    async (templateId: string | null) => {
      setSelectedTemplate(templateId);

      const template = templatesData?.quoteTemplates.find(t => t.id === templateId);
      setItems(
        !template
          ? []
          : template.items.map(item => {
              // Use the item specific supplierId if available, otherwise fall back to the template's supplierId
              const supplierId = item.supplier.id || template.supplier.id;
              return {
                productId: item.product.id,
                supplierId,
                amount: item.amount,
                product: item.product,
                price: item.price!,
              };
            }),
      );
    },
    [templatesData?.quoteTemplates],
  );

  const [createInstallation] = useCreateInstallation(activeHouseId, solution);
  const [updateInstallationMut] = useMutation<updateInstallation, updateInstallationVariables>(
    UPDATE_INSTALLATION,
  );

  const { data: installationData } = useQuery<
    installationByHouseSolution,
    installationByHouseSolutionVariables
  >(INSTALLATION_BY_HOUSE_SOLUTION, {
    variables: {
      houseId: activeHouseId,
      solution,
    },
    fetchPolicy: 'cache-first',
  });

  /** Upserts an Installation with the items of a QuoteTemplate */
  const handleCreateFromTemplate = useCallback(
    async (items: TemplateItem[]) => {
      let installation = installationData?.installationByHouseSolution;
      if (!installationData?.installationByHouseSolution) {
        installation = (await createInstallation()).data?.createInstallation;
      }
      if (!installation) throw new Error('No installation!');

      try {
        await updateInstallationMut({
          variables: {
            id: installation.id,
            installation: {
              id: installation.id,
              solution: installation.solution,
              items: items.map(item => ({
                productId: item.product.id,
                amount: item.amount || 1,
                supplierId: item.supplierId,
                retailPrice: item.price!.retailPrice,
                purchasePrice: item.price!.purchasePrice,
              })),
            },
          },
        });
        closeModal();
      } catch (e) {
        toast({ message: 'Er is iets mis gegaan. ' + (e as ApolloError)?.message, type: 'error' });
      }
    },
    [
      installationData?.installationByHouseSolution,
      createInstallation,
      updateInstallationMut,
      closeModal,
      toast,
    ],
  );

  const createTuple = useMutation<createQuoteTemplate, createQuoteTemplateVariables>(
    CREATE_TEMPLATE,
    {
      update: (cache, res) =>
        updateCacheOnTemplateCreation(cache, res, { solution, address: basicAddress }),
      onCompleted: data => {
        handleTemplateChange(data.createQuoteTemplate.id);
        toast({ type: 'success', message: 'Template aangemaakt!' });
      },
      onError: () => toast({ type: 'error', message: 'Template aanmaken mislukt' }),
    },
  );

  const updateTuple = useMutation<updateQuoteTemplate, updateQuoteTemplateVariables>(
    UPDATE_TEMPLATE,
    {
      update: (cache, res) =>
        updateCacheOnTemplateUpdate(cache, res, { solution, address: basicAddress }),
      onCompleted: () => toast({ type: 'success', message: 'Template opgeslagen!' }),
      onError: () => toast({ type: 'error', message: 'Template opslaan mislukt' }),
    },
  );
  const [updateTemplate] = updateTuple;

  const [deleteTemplate, deletionResult] = useMutation<
    deleteQuoteTemplate,
    deleteQuoteTemplateVariables
  >(DELETE_TEMPLATE, {
    update: (cache, res, options) =>
      updateCacheOnTemplateDeletion(cache, res, options, { solution, address: basicAddress }),
    onCompleted: () => {
      handleTemplateChange(null);
      toast({ type: 'success', message: 'Template verwijderd!' });
    },
    onError: () => toast({ type: 'error', message: 'Template verwijderen mislukt' }),
  });

  const handleRename = useCallback(() => {
    // TODO: Shouldn't rely on prompt, nicer to make the Select an Input temporarily
    const title = prompt('Hoe wil je de template noemen?', activeTemplate?.title || '');
    if (!title || !activeTemplate) return;
    return updateTemplate({
      variables: {
        templateId: activeTemplate?.id,
        operatorId: me.id,
        template: createTemplateInput({
          title,
          solution,
          supplierId: activeSupplierId!,
          items,
        }),
      },
    });
  }, [activeSupplierId, activeTemplate, items, me.id, solution, updateTemplate]);

  const managementBar = activeTemplate && (
    <Flex flexWrap="wrap" flexDirection="row-reverse" style={{ overflow: 'hidden' }} px={1} mb={1}>
      {/* Fixed width and negative margin to hide the empty right label */}
      <Box width="9.5em" style={{ marginRight: '-4em' }} mt="4px" px={-1}>
        <Switch
          offLabel="Actief"
          scale={0.8}
          isOn={activeTemplate.isActive}
          toggleSwitch={() =>
            updateTemplate({
              variables: {
                operatorId: me.id,
                templateId: activeTemplate.id,
                template: createTemplateInput({
                  title: activeTemplate.title,
                  solution,
                  items: activeTemplate.items.map(i => ({
                    ...i,
                    supplierId: i.supplier.id,
                  })),
                  supplierId: activeTemplate.supplier.id,
                  isActive: !activeTemplate.isActive,
                }),
              },
            })
          }
        />
      </Box>
      <Button
        m={-1}
        mb={-2}
        pb={3}
        onClick={handleRename}
        bgColor="grayDark"
        minimal
        inverse
        iconStart={Pencil}
        fontSize={0.8}
      >
        Hernoemen
      </Button>
      <Button
        m={-1}
        mb={-2}
        pb={3}
        bgColor="red"
        minimal
        inverse
        iconStart={Trash}
        fontSize={0.8}
        disabled={!selectedTemplate}
        loading={deletionResult.loading}
        error={deletionResult.error?.message}
        onClick={() =>
          window.confirm(`Weet je zeker dat je "${activeTemplate.title}" wilt verwijderen?`) &&
          deleteTemplate({ variables: { templateId: selectedTemplate! } })
        }
      >
        Verwijderen
      </Button>
    </Flex>
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        title="Templates"
        buttons={quoteTemplateActionBar(
          hasManagementPermission,
          activeTemplate,
          items,
          selectedSupplier,
          solution,
          me.id,
          installersData?.installersBySolution || [],
          createTuple,
          updateTuple,
          handleCreateFromTemplate,
        )}
      >
        <Select<string>
          label="Installateur"
          placeholder="Kies een installateur..."
          onChange={e => handleSupplierChange(e?.value)}
          options={supplierOptions}
          value={
            supplierOptions.find(
              opt =>
                installersData?.installersBySolution.find(
                  inst => inst.pairedSupplierId === opt.value,
                )?.pairedSupplierId === selectedSupplier,
            ) || null
          }
          loading={installersLoading}
          error={installersError?.message}
          clearable
          refetchOptions={refetchInstallers}
          filterOption={(option: SelectFilterOption, searchString: string) =>
            option?.data?.searchValue?.match(new RegExp(searchString, 'gi'))
          }
        />

        {/* TODO: Option for sorting alphabetically or chronologically */}
        <Select<string>
          options={templateOptions}
          onChange={o => handleTemplateChange(o?.value)}
          value={templateOptions.find(template => template.value === selectedTemplate) || null}
          label={
            selectedSupplier
              ? `${SOLUTIONS_NL[solution]} templates bij ${
                  supplierOptions.find(s => s.value === selectedSupplier)?.searchValue
                }`
              : `Alle ${SOLUTIONS_NL[solution].toLowerCase()} templates`
          }
          placeholder="Kies een template..."
          loading={templatesLoading}
          error={templatesError?.message}
          filterOption={(option: SelectFilterOption, searchString: string) =>
            option?.data?.searchValue?.match(new RegExp(searchString, 'gi'))
          }
        />
        {templatesError && (
          <Red>
            <Small>
              Er is iets mis gegaan: {templatesError?.message}
              <br />
            </Small>
          </Red>
        )}

        {!activeTemplate && items === initialItems && hasManagementPermission ? (
          <Small>Huidige opzet Installatron</Small>
        ) : hasManagementPermission ? (
          managementBar
        ) : undefined}

        {(hasManagementPermission || activeTemplate) && (
          <TemplateBuilder
            supplierId={activeSupplierId || ''}
            solution={solution}
            items={items}
            // TODO: type it. Mismatch between TemplateItem and MutationItem
            setItems={setItems as any}
            isUnsaved={!selectedTemplate}
            isReadOnly={!hasManagementPermission}
            installerName={supplierOptions.find(s => s.value === activeSupplierId)?.searchValue}
          />
        )}

        {activeTemplate && (
          <Box mt={1}>
            <Smaller>
              Laatst bewerkt door{' '}
              {(activeTemplate.modifiedBy || activeTemplate.createdBy).firstName}{' '}
              {(activeTemplate.modifiedBy || activeTemplate.createdBy).lastName} op{' '}
              <DateWithTooltip date={activeTemplate.modifiedOn || activeTemplate.createdOn} />
            </Smaller>
          </Box>
        )}

        {isDev && (
          <Flex flexWrap="wrap" flexDirection="row-reverse" mt={1}>
            <Box width="13em" mt="3px">
              <Switch
                isOn={hasManagementPermission}
                bgColorOff="purple"
                bgColorOn="blue"
                offLabel="Read-only"
                onLabel="Managen"
                toggleSwitch={() => setHasManagementPermission(!hasManagementPermission)}
                scale={0.8}
              />
            </Box>

            <Box>
              <Small>DEV optie:</Small>
            </Box>
          </Flex>
        )}
      </Modal>
    </>
  );
};

export default TemplatePicker;
