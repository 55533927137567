import React from 'react';
import styled, { css } from 'styled-components';

const width = '24';
const height = '24';
const viewBox = '0 0 24 24';

const getDimensions = () => ({
  height,
  width,
});

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`;

const Image = styled.svg`
  ${({ noStyles }) => (!noStyles ? getDimensionsCss() : null)};
`;

const defaultProps = {
  children: [
    <polygon key="key-0" points="5.36 0 0 9.65 0 20.87 12.78 24 21.87 20.84 21.87 8.13 5.36 0" />,
  ],
  viewBox,
};

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'MuntSymbol',
});
