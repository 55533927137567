import { IntakeFragment, SituationFragment, NeedsFragment, FileFragment } from '../../fragments';
import { gql } from '@apollo/client';

export const INTAKE_OVERVIEW = gql`
  ${IntakeFragment}
  ${SituationFragment}
  ${NeedsFragment}
  ${FileFragment}
  query intakeOverview($houseId: ID!) {
    house(id: $houseId) {
      id
      userId
      userType
      pvSystemPower
      type
      constructionYear
      area
      address {
        zip
        street
        number
        suffix
        city
      }
      maxHeatPumpPower
      vve
      feasibleSolutions
      labelRegistration {
        registered
      }
      requestedDuties {
        name
      }
      advice {
        id
        isRequested
      }
      intake {
        ...Intake
        forQuote
        appointment {
          plannedFor
        }
      }
      situation {
        ...Situation
      }
      customer {
        id
        needs {
          ...Needs
        }
      }
      files {
        ...File
      }
    }
  }
`;

export const UPDATE_INTAKE_APPOINTMENT = gql`
  mutation updateIntakeAppointment($houseId: ID!, $date: Date!) {
    updateIntakeAppointment(houseId: $houseId, date: $date) {
      id
      isPlanned
      plannedFor
    }
  }
`;
