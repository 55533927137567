import { gql, useMutation } from '@apollo/client';
import { Modal } from '@energiebespaarders/symbols';
import { Check } from '@energiebespaarders/symbols/icons/solid';
import { containers } from '@energiebespaarders/symbols/styles/theme';
import React from 'react';
import useToaster from '../../../hooks/useToaster';
import { dealsByHouseSolutionDomain_dealsByHouseSolutionDomain } from '../../../types/generated/dealsByHouseSolutionDomain';
import { reopenDeal, reopenDealVariables } from '../../../types/generated/reopenDeal';

export const REOPEN_DEAL = gql`
  mutation reopenDeal($dealId: ID!) {
    reopenDeal(dealId: $dealId) {
      id
      onHold {
        since
        time
      }
      noLongerActive {
        lostDate
        reason
      }
      # For the cache:
      status
    }
  }
`;

interface ReopenDealModalProps {
  closeModal: () => void;
  deal: dealsByHouseSolutionDomain_dealsByHouseSolutionDomain;
}

const ReopenDealModal: React.FC<ReopenDealModalProps> = ({ closeModal, deal }) => {
  const toast = useToaster();
  const [reopenDeal, { loading }] = useMutation<reopenDeal, reopenDealVariables>(REOPEN_DEAL, {
    onCompleted: () => {
      toast({ message: 'Deal op geopend!', type: 'success' });
      closeModal();
    },
    onError: e => {
      toast({ message: 'Er is iets mis gegaan: ' + e.name, type: 'error' });
    },
  });

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      title={'Deal her-openen'}
      buttons={[
        {
          label: 'Annuleren',
          bgColor: 'red',
          inverse: true,
          onClick: closeModal,
          disabled: loading,
        },
        {
          label: 'Bevestigen',
          bgColor: 'green',
          iconStart: Check,
          disabled: loading,
          onClick: () =>
            reopenDeal({
              variables: {
                dealId: deal.id,
              },
            }),
        },
      ]}
      size={'sm' as keyof typeof containers}
    >
      <h4>Wat gaat gebeuren?</h4>
      <ul>
        {[
          'Als er een deal in Pipedrive bestaat, wordt deze verplaatst naar "Klaar voor schouw/installatie"',
          'Als de installatie/schouw klaar stond om ingepland te worden, zal deze teruggeplaatst worden naar de lijst van in-te-plannen opdrachten in het Installer Account',
        ].map(consequence => (
          <li key={'consequence' + consequence}>{consequence}</li>
        ))}
      </ul>
      <p>Weet je zeker dat je dit wil doen?</p>
    </Modal>
  );
};

export default ReopenDealModal;
