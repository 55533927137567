import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

export const TableWrapper = styled.div<{ $height?: string }>`
  .table {
    border: 1px solid #ddd;
    font-size: small;

    min-height: 400px;
    resize: vertical;
    height: ${props => props.$height || 'auto'};

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      :nth-of-type(2n + 1) {
        .td {
          /* can't use alpha colors cuz of sticky columns */
          background-color: ${lighten(0.43, themify('green'))};
        }
      }
      :nth-of-type(2n) {
        .td {
          background-color: ${lighten(0.48, themify('green'))};
        }
      }
    }

    .th {
      background-color: ${themify('green')};
      color: white;
      font-weight: bold;
      &.sorting {
        background-color: ${darken(0.05, themify('green'))};
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        background: ${darken(0.05, themify('green'))};
        width: 8px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &:hover {
          background: ${darken(0.1, themify('green'))};
        }

        &.isResizing {
          background: ${themify('blue')};
        }
      }
    }

    .td.group-start {
      border-left: 1px solid #ddd;
    }

    .footer {
      .tr:first-child {
        .td {
          border-top: 2px solid #ddd;
        }
      }
      font-weight: bolder;
    }

    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.2);
      }

      .body {
        position: relative;
        z-index: 0;
      }

      .status-message {
        font-style: italic;
        padding: 8px;
        width: 100%;
        text-align: center;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.2);
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px rgba(0, 0, 0, 0.2);
      }
    }

    pre {
      margin: 0;
      margin-top: 2px;
      font-size: smaller;
      color: ${themify('darkerGray')};
      text-align: right;
      user-select: none;
    }
  }
`;

interface HeaderTextProps {
  $sortOrder: string;
  $active: boolean;
}

export const HeaderText = styled.span<HeaderTextProps>`
  position: relative;

  &::after {
    position: absolute;
    top: calc(50% - 2px);
    right: -12px;
    content: '';
    width: 0px;
    height: 0px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
    transform: rotate(${x => (x.$sortOrder === 'asc' ? '180deg' : 0)});
    display: ${x => (x.$active ? 'block' : 'none')};
  }
`;

export const ProductTitle = styled.div<{ $isClickable?: boolean }>`
  &:not(:hover) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  transition: background 0.2s ${x => x.theme.curves.standard};

  ${x =>
    x.$isClickable &&
    css`
      cursor: pointer;
      &:hover {
        color: ${darken(0.4, themify('green'))};
      }
    `}
`;

export const ProductIDCell = styled.pre`
  font-size: ${x => x.theme.type.scale[8]};
  margin: 0;
`;
