import React from 'react';
import useToaster from '../../hooks/useToaster';
import { gql, useMutation } from '@apollo/client';
import { useForm } from '@energiebespaarders/hooks';
import { isMissing } from '@energiebespaarders/hooks/useForm';
import { Input, Modal, Select, Textarea } from '@energiebespaarders/symbols';
import { Check } from '@energiebespaarders/symbols/icons/solid';
import { containers } from '@energiebespaarders/symbols/styles/theme';
import {
  updateDealMetaData,
  updateDealMetaDataVariables,
} from '../../types/generated/updateDealMetaData';

type EditDealMetadataModelProps = {
  closeModal: () => void;
  dealId: string;
  title: string | null;
  description: string | null;
  icon: string | null;
  priority: number | null;
};

type DealMetaDataForm = {
  title: string;
  description: string;
  icon: '' | 'Diamond' | 'Star' | 'Heart' | 'Circle' | 'Square' | 'Triangle' | 'Hexagon';
  priority: number;
};

export const UPDATE_DEAL_META_DATA = gql`
  mutation updateDealMetaData(
    $dealId: ID!
    $title: String
    $description: String
    $icon: String
    $priority: Int
  ) {
    updateDeal(
      deal: { title: $title, icon: $icon, description: $description, priority: $priority }
      dealId: $dealId
    ) {
      id
      title
      description
      icon
      priority
    }
  }
`;

export const EditDealMetadataModal: React.FC<EditDealMetadataModelProps> = ({
  closeModal,
  dealId,
  title,
  description,
  icon,
  priority,
}) => {
  const toast = useToaster();
  const [setDealOnHoldStatus, { loading }] = useMutation<
    updateDealMetaData,
    updateDealMetaDataVariables
  >(UPDATE_DEAL_META_DATA, {
    onCompleted: () => {
      toast({ message: 'Deal aangepasst', type: 'success' });
      closeModal();
    },
    onError: e => {
      toast({ message: 'Er is iets mis gegaan: ' + e.name, type: 'error' });
      closeModal();
    },
  });

  const { formState, handleChange, submitForm } = useForm<DealMetaDataForm>({
    initialValues: {
      title: title ?? '',
      description: description ?? '',
      icon: icon ?? '',
      priority: priority ?? 0,
    },
    validate: (values, errors) => {
      if (isMissing(values.priority) || values.priority < 0)
        errors.priority = 'Moet meer dan 0 zijn';
      return errors;
    },
    handleSubmit: values => {
      setDealOnHoldStatus({
        variables: {
          dealId: dealId,
          title: values.title,
          description: values.description,
          icon: values.icon,
        },
      });
    },
  });

  // TODO: make this viable options based on the icons we want to use
  const iconOptions = [
    { value: '', label: 'Geen' },
    { value: 'Diamond', label: 'Diamond' },
    { value: 'Star', label: 'Star' },
    { value: 'Heart', label: 'Heart' },
    { value: 'Circle', label: 'Circle' },
    { value: 'Square', label: 'Square' },
    { value: 'Triangle', label: 'Triangle' },
    { value: 'Hexagon', label: 'Hexagon' },
  ];

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      title={'Deal Metadata aanpassen'}
      buttons={[
        {
          label: 'Annuleren',
          bgColor: 'red',
          inverse: true,
          onClick: closeModal,
          disabled: loading,
        },
        {
          label: 'Bevestigen',
          bgColor: 'green',
          iconStart: Check,
          onClick: () => submitForm(),
          disabled: loading,
        },
      ]}
      size={'sm' as keyof typeof containers}
    >
      <Input
        type="string"
        value={formState.title.value}
        onChange={e => handleChange({ title: e.target.value })}
        label="Titel"
      />
      <Select
        clearable={false}
        label="Deal Icoon"
        placeholder="Reden selecteren"
        onChange={e => handleChange({ icon: e.value })}
        options={iconOptions}
        touched={formState.icon.touched}
        value={iconOptions.find(o => o.value === formState.icon.value)}
        disabled={loading}
      />

      <Textarea
        label="Omschrijving"
        value={formState.description.value}
        onChange={e => handleChange({ description: e.target.value })}
        touched={formState.description.touched}
      />
      <Input
        type="number"
        value={formState.priority.value}
        onChange={e => handleChange({ priority: Number(e.target.value) })}
        error={formState.priority.error}
        touched={formState.priority.touched}
        label="Prioriteit"
      />
      <p></p>
    </Modal>
  );
};
