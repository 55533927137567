import dayjs from 'dayjs';
import { operatorById_operatorById } from '../../types/generated/operatorById';

// THIS METHOD IS ALSO USED IN GraphQL. IF UPDATING HERE, MAKE SURE TO UPDATE THERE TOO
// NOTE: OperatorById isnt the same type thats used in the GQL variant
export function createPersonalCode(operator: operatorById_operatorById): string {
  const lastNames = operator.lastName.split(' ');

  return [operator.firstName, ...lastNames.map(n => n[0])].join('').toUpperCase();
}

// THIS METHOD IS ALSO USED IN GraphQL. IF UPDATING HERE, MAKE SURE TO UPDATE THERE TOO
// NOTE: OperatorById isnt the same type thats used in the GQL variant
export function generateOperatorVriendenkortingCode(operator: operatorById_operatorById): string {
  return dayjs(operator.created).isBefore(dayjs('7-1-2022', 'M-D-YYYY'))
    ? `${operator.firstName.toUpperCase()}-VRIENDENKORTING`
    : `${createPersonalCode(operator)}-VRIENDENKORTING`;
}
