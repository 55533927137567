import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const floatIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const slideRight = keyframes`
  0% {
    transform: translateX(110vw);
  }

  100% {
    transform: translateX(0);
  }
`;

export const slideLeft = keyframes`
  0% {
    transform: translateX(-110vw);
  }

  100% {
    transform: translateX(0);
  }
`;

export const bounce = keyframes`
  0%, 100%, 20%, 53%, 80% {
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }
  70% {
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
`;

export const grow = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

export const pulse = keyframes`
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0);
  }
`;

export const wobble = keyframes`
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(0px, 1px);
  }
  50% {
    transform: translate(0px, 1px);
  }
  75% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(1px, 0px);
  }
`;

export const scaleFade = keyframes`
  0% {
    transform: scale(1);
  }
  50%,
  75% {
    transform: scale(2.5);
  }
  78%, 100% {
    opacity: 0;
  }
`;

export const shake = keyframes`
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-5%, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(5%, 0, 0);
  }
`;

export const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const shimmer = keyframes`
  0%{
      background-position: 200% 0;
  }
  100%{
      background-position: -200% 0;
  }
`;

export const fauxProgress = keyframes`
  0% {
    width: 0;
  }
  20% {
    width: 40%;
  }
  40% {
    width: 45%;
  }
  50% {
    width: 50%;
  }
  70% {
    width: 75%;
  }
  80% {
    width: 85%;
  }
  100% {
    width: 96%;
  }
`;

export const finishProgress = keyframes`
  0% {
    width: 0;
  }
  90% {
    opacity: 1;
  }
  99% {
    width: 100%;
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
`;

export const bgFlash = keyframes`
  0%, 25% {
    background: inherit;
  }
  50% {
    background: white;
  }
  75%, 100% {
    background: inherit;
  }
`;

const animations = {
  fadeIn,
  floatIn,
  slideRight,
  slideLeft,
  bounce,
  grow,
  pulse,
  scaleFade,
  shake,
  spin,
  fauxProgress,
  bgFlash,
  wobble,
};

export default animations;
