import React from 'react';
import { Icon, Tooltip, Flex, Box } from '@energiebespaarders/symbols';
import { Calculator, AwardRibbon, Wrench, List } from '@energiebespaarders/symbols/icons/solid';
import { Energiepaspoort } from '@energiebespaarders/symbols/icons/custom';

interface QuestionIconsProps {
  question: {
    forCalculations?: boolean;
    forAdvice?: boolean;
    forEnergyLabel?: boolean;
    autoInstallationSolutions?: string[];
    jobSolutions?: string[];
  };
  stack?: boolean;
}

const QuestionIcons: React.FC<QuestionIconsProps> = ({ question, stack }: QuestionIconsProps) => {
  return (
    <Flex flexDirection={stack ? 'column' : 'row'} mx={1}>
      {question.autoInstallationSolutions && question.autoInstallationSolutions.length > 0 && (
        <Box>
          <Tooltip
            bgColor="grayDarker"
            content="Deze vraag is belangrijk voor het genereren van offertes"
          >
            <Icon
              fill="gray"
              hoverColor="grayDarker"
              height="16px"
              width="16px"
              icon={List}
              solid
              inline={!stack}
              mx={stack ? 'auto' : 0}
              mr={stack ? 'auto' : 1}
              my={0}
            />
          </Tooltip>
        </Box>
      )}
      {question.forCalculations && (
        <Box>
          <Tooltip bgColor="grayDarker" content="Deze vraag is belangrijk voor onze berekeningen">
            <Icon
              fill="gray"
              hoverColor="grayDarker"
              height="16px"
              width="16px"
              icon={Calculator}
              solid
              inline={!stack}
              mx={stack ? 'auto' : 0}
              mr={stack ? 'auto' : 1}
              my={0}
            />
          </Tooltip>
        </Box>
      )}
      {question.forAdvice && (
        <Box>
          <Tooltip
            bgColor="grayDarker"
            content="Deze vraag is belangrijk voor adviesteksten genereren"
          >
            <Icon
              fill="gray"
              hoverColor="grayDarker"
              height="16px"
              width="16px"
              icon={Energiepaspoort}
              solid
              inline={!stack}
              mx={stack ? 'auto' : 0}
              mr={stack ? 'auto' : 1}
              my={0}
            />
          </Tooltip>
        </Box>
      )}
      {question.forEnergyLabel && (
        <Box>
          <Tooltip
            bgColor="grayDarker"
            content="Deze vraag is belangrijk voor het energielabel bepalen"
          >
            <Icon
              fill="gray"
              hoverColor="grayDarker"
              height="16px"
              width="16px"
              icon={AwardRibbon}
              solid
              inline={!stack}
              mx={stack ? 'auto' : 0}
              mr={stack ? 'auto' : 1}
              my={0}
            />
          </Tooltip>
        </Box>
      )}
      {question.jobSolutions && question.jobSolutions.length > 0 && (
        <Box>
          <Tooltip
            bgColor="grayDarker"
            content="Deze vraag is belangrijk voor voor de installatieopdracht"
            padding={2}
          >
            <Icon
              fill="gray"
              hoverColor="grayDarker"
              height="16px"
              width="16px"
              icon={Wrench}
              solid
              inline={!stack}
              mx={stack ? 'auto' : 0}
              mr={stack ? 'auto' : 1}
              my={0}
            />
          </Tooltip>
        </Box>
      )}
    </Flex>
  );
};

export default QuestionIcons;
