import { gql, useMutation } from '@apollo/client';
import { Button, Flex, Input, Modal, Uploader } from '@energiebespaarders/symbols';
import React, { useState } from 'react';
import useToaster from '../../hooks/useToaster';
import { addBrand, addBrandVariables } from '../../types/generated/addBrand';
import { updateBrandLogo, updateBrandLogoVariables } from '../../types/generated/updateBrandLogo';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const ADD_BRAND = gql`
  mutation addBrand($name: String!) {
    addBrand(name: $name) {
      id
      name
    }
  }
`;

export const UPDATE_BRAND_LOGO = gql`
  mutation updateBrandLogo($id: ID!, $logo: Upload!) {
    updateBrandLogo(id: $id, file: $logo) {
      id
      extension
    }
  }
`;

const AddBrandModal: React.FC<Props> = ({ open, handleClose }) => {
  const [brandName, setBrandName] = useState('');

  const toast = useToaster();

  const [addBrand, { data: brandData }] = useMutation<addBrand, addBrandVariables>(ADD_BRAND);
  const [
    updateBrandLogo,
    { data: brandLogo, loading: uploading, error: uploadError },
  ] = useMutation<updateBrandLogo, updateBrandLogoVariables>(UPDATE_BRAND_LOGO, {
    onCompleted: data =>
      toast({
        type: data.updateBrandLogo?.id ? 'success' : 'error',
        message: data.updateBrandLogo?.id ? 'Logo aangepast!' : 'Logo kon niet worden aangepast :(',
      }),
  });

  const handleSave = () => addBrand({ variables: { name: brandName } });

  return (
    <Modal isOpen={open} onRequestClose={handleClose} title="Merk toevoegen">
      <Flex flexDirection="column">
        {!brandData ? (
          <Input
            onChange={e => setBrandName(e.target.value)}
            label="Merknaam:"
            value={brandName}
            type="text"
            name="name"
            mb={4}
          />
        ) : (
          <Flex flexDirection="column">
            <Uploader
              multiple={false}
              loading={uploading}
              accept=".jpg, .png, .svg, .webp"
              error={uploadError}
              text={brandLogo?.updateBrandLogo?.id ? 'Logo geüpload!' : 'Upload een logo'}
              upload={({ variables }: { variables: { files: File[] } }) => {
                updateBrandLogo({
                  variables: { id: brandData.addBrand.id, logo: variables.files[0] },
                });
              }}
              mb={2}
              bgColor={brandLogo?.updateBrandLogo?.id ? 'green' : 'purple'}
            />
            <p>
              Upload hier het logo van het merk, het liefst in SVG formaat, zodat het er scherp uit
              ziet ook wanneer het groot wordt weergegeven. Deze kun je vinden door te Googlen naar
              "[merknaam] logo" in Google Afbeeldingen, en dan bij Tools de "Type" op "Lijntekening"
              te zetten. Kom je er niet uit? Vraag het even aan Dev!
            </p>
          </Flex>
        )}

        <Button
          disabled={!!brandData && !brandLogo}
          onClick={!brandData ? handleSave : handleClose}
        >
          {!brandData ? 'Volgende' : 'Opslaan'}
        </Button>
      </Flex>
    </Modal>
  );
};

export default AddBrandModal;
