import { gql, useMutation, useQuery } from '@apollo/client';
import { getDomainFromSolution, SOLUTIONS_NL } from '@energiebespaarders/constants';
import { Box, Modal, Placeholder, Select, Textarea } from '@energiebespaarders/symbols';
import { Transfer } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import { QuoteFragment } from '../../../fragments';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { useMe } from '../../../hooks/useMe';
import useToaster from '../../../hooks/useToaster';
import { DEALS_BY_SOLUTION_DOMAIN } from '../../../queries/installatron';
import { dealsByHouseSolutionDomainVariables } from '../../../types/generated/dealsByHouseSolutionDomain';
import { quoteModalQuote_quoteById } from '../../../types/generated/quoteModalQuote';
import {
  quoteWithAlternativePrices,
  quoteWithAlternativePricesVariables,
} from '../../../types/generated/quoteWithAlternativePrices';
import {
  recreateQuoteForInstaller,
  recreateQuoteForInstallerVariables,
} from '../../../types/generated/recreateQuoteForInstaller';
import InstallerComparisonTable from '../InstallerComparison/InstallerComparisonTable';
import { cancellationBlames, cancellationReasons } from './CancelQuoteModal';

const QUOTE_WITH_ALTERNATIVE_PRICES = gql`
  query quoteWithAlternativePrices($quoteId: ID!) {
    quoteById(quoteId: $quoteId) {
      id
      solution
      items {
        supplierId
        retailPrice
        purchasePrice
        amount

        product {
          id
          title
          priceUnit
          prices {
            id
            supplierId
            retailPrice
            purchasePrice
            availability
            minimumInstallerRate
          }
        }
      }
    }
  }
`;

interface QuoteInstallerComparisonTableProps {
  quoteId: string;
  onPickInstaller: (installer: { id: string; pairedSupplierId: string }) => void;
  selectedSupplierId: string;
}

const QuoteInstallerComparisonTable: React.FC<QuoteInstallerComparisonTableProps> = ({
  quoteId,
  onPickInstaller,
  selectedSupplierId,
}) => {
  const { data, loading, error } = useQuery<
    quoteWithAlternativePrices,
    quoteWithAlternativePricesVariables
  >(QUOTE_WITH_ALTERNATIVE_PRICES, {
    variables: { quoteId },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      // When the quote is loaded, pre-fill current supplier
      const firstItem = data.quoteById?.items[0];
      if (firstItem) {
        onPickInstaller({ id: '', pairedSupplierId: firstItem.supplierId });
      }
    },
  });

  if (!data?.quoteById || loading || error) {
    return <Placeholder error={error} />;
  }

  return (
    <InstallerComparisonTable
      solution={data.quoteById.solution}
      items={data.quoteById.items || []}
      selectedSupplierId={selectedSupplierId}
      onPickInstaller={inst => onPickInstaller(inst as { id: string; pairedSupplierId: string })}
      priceType="purchasePrice"
      // Transferring the quote is only possible for installers that over all products on the quote: don't show installers that only have some
      onlyShowAlternativesWithAllProducts
    />
  );
};

const RECREATE_QUOTE_FOR_INSTALLER = gql`
  ${QuoteFragment}

  mutation recreateQuoteForInstaller(
    $quoteId: ID!
    $operatorId: ID!
    $newInstallerId: ID!
    $cancellationInformation: CancellationInformationInput!
  ) {
    recreateQuoteForInstaller(
      quoteId: $quoteId
      operatorId: $operatorId
      newInstallerId: $newInstallerId
      cancellationInformation: $cancellationInformation
    ) {
      ...Quote
    }
  }
`;

interface TransferQuoteModalProps {
  quote: quoteModalQuote_quoteById;
  isOpen: boolean;
  onClose: () => void;
}

const TransferQuoteModal: React.FC<TransferQuoteModalProps> = ({ quote, isOpen, onClose }) => {
  const isMobile = useIsMobile();
  const { me } = useMe();
  const { solution } = quote;
  const { activeHouseId } = useActiveHouseId();
  const toast = useToaster();

  // TODO: duplicate in CancelQuoteModal. Should do this with useForm
  const [cancellationReason, setCancellationReason] = useState('planningPeriodExceeded');
  const [cancellationText, setCancellationText] = useState('');
  const [cancellationBlame, setCancellationBlame] = useState('installer');

  const [newInstaller, setNewInstaller] = useState({ id: '', pairedSupplierId: '' });

  const [transferQuote, { loading, error }] = useMutation<
    recreateQuoteForInstaller,
    recreateQuoteForInstallerVariables
  >(RECREATE_QUOTE_FOR_INSTALLER, {
    onCompleted: () => {
      onClose();
      toast({
        type: 'success',
        message: 'Offerte is succesvol opnieuw opgesteld',
      });
    },
    onError: () =>
      toast({
        type: 'error',
        message: 'Er is een fout opgetreden bij het opnieuw opstellen',
      }),
    // A lot of cache needs to be updated. Might as well re-query
    refetchQueries: [
      {
        query: DEALS_BY_SOLUTION_DOMAIN,
        variables: {
          houseId: activeHouseId,
          solutionDomain: getDomainFromSolution(quote.solution),
        } as dealsByHouseSolutionDomainVariables,
      },
    ],
  });

  const onSubmit = () =>
    transferQuote({
      variables: {
        operatorId: me.id,
        quoteId: quote.id,
        newInstallerId: newInstaller.id,
        cancellationInformation: {
          reason: cancellationReason,
          note: cancellationText,
          party: cancellationBlame,
        },
      },
    });

  const initialSupplierId = quote.items[0].supplier.id;

  return (
    <Modal
      isOpen={isOpen}
      mobile={isMobile}
      onRequestClose={onClose}
      title={`${quote.reference ? `Offerte ${quote.reference}` : 'Concept offerte'} voor ${
        solution ? SOLUTIONS_NL[solution] : '...'
      } opstellen voor andere installateur`}
      size="xl"
      buttons={[
        {
          iconStart: Transfer,
          label: 'Opnieuw opstellen voor andere installateur',
          bgColor: 'green',
          onClick: onSubmit,
          disabled:
            !newInstaller.id ||
            newInstaller.pairedSupplierId === initialSupplierId ||
            loading ||
            !cancellationReason ||
            !cancellationBlame,
          loading,
          error: error?.message,
        },
        {
          label: 'Sluiten',
          bgColor: 'red',
          onClick: onClose,
        },
      ]}
    >
      {/* Cancellation information */}
      {!quote.isCancelled && (
        <>
          <h5>Waarom moet de huidige offerte geannuleerd worden?</h5>
          <Box width={1} mb={3}>
            <Select
              label="Betrokken partij"
              value={cancellationBlames.find(opt => opt.value === cancellationBlame)}
              options={cancellationBlames}
              onChange={e => setCancellationBlame(e.value)}
              required
            />
          </Box>
          <Box width={1} mb={3}>
            <Select
              label="Reden van annulering"
              value={cancellationReasons.find(opt => opt.value === cancellationReason)}
              options={cancellationReasons}
              onChange={e => setCancellationReason(e.value)}
              required
            />
          </Box>
          <Box width={1}>
            <Textarea
              label="Toelichting"
              value={cancellationText}
              onChange={e => setCancellationText(e.target.value)}
            />
          </Box>
        </>
      )}

      {/* Pick new installer */}
      {/* TODO: add total margin comparison */}
      <h5>Kies een alternative installateur:</h5>
      <QuoteInstallerComparisonTable
        quoteId={quote.id}
        onPickInstaller={setNewInstaller}
        selectedSupplierId={newInstaller.pairedSupplierId}
      />

      <p>Wat er gaat gebeuren:</p>

      <ul>
        {!quote.isCancelled && <li>De huidige offerte wordt geannuleerd</li>}
        <li>Een nieuwe offerte wordt gemaakt op dezelfde deal</li>
        <ul>
          <li>De verkoopprijzen worden behouden van de huidige offerte</li>
          <li>De inkoopprijzen worden overgenomen van de nieuwe installateur (hierboven)</li>
          <li>
            De besparingsberekeningen en andere eigenschappen van de offerte worden overgenomen{' '}
          </li>
        </ul>
        <li>
          De nieuwe offerte wordt <b>niet</b> direct op akkoord gezet
        </li>
        <li>
          Een nieuwe {quote.final ? 'installatie' : 'schouw'}-opdracht wordt aangemaakt (maar niet
          verstuurd)
        </li>
      </ul>
      {/* TODO: didn't get around to this yet */}
      {/* <Checkbox label="Opdracht meteen versturen naar X" checked value={true} /> */}
    </Modal>
  );
};

export default TransferQuoteModal;
