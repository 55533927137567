import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppliedRouteProps } from './AppliedRoute';
import { useIsInitializing } from '../../hooks/useIsInitializing';
import PageLoading from '../PageLoading';
import { useMe } from '../../hooks/useMe';

export const MeRoute: React.FC<AppliedRouteProps> = ({ exact, path }) => {
  const { isInitializing } = useIsInitializing();
  const { me } = useMe();
  return (
    <Route
      exact={exact}
      path={path}
      render={renderProps => {
        return isInitializing ? (
          <PageLoading />
        ) : me ? (
          <Redirect
            to={{
              pathname: `/users/operators/${me?.id}`,
              state: { from: renderProps.location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: renderProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default MeRoute;
