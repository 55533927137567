import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useIsInitializing } from '../../hooks/useIsInitializing';
import { useMe } from '../../hooks/useMe';
import Navigation from '../navigation/Navigation';
import TicketPopup from '../navigation/TicketPopup';
import PageLoading from '../PageLoading';
import AppliedRoute, { AppliedRouteProps } from './AppliedRoute';

export interface DefaultRouteProps {
  containerWidth: number;
  leftNavOpen: boolean;
  narrowScreen: boolean;
  narrowContainer: boolean;
}

export const AuthenticatedRoute: React.FC<AppliedRouteProps> = ({
  component: Component,
  exact,
  path,
}) => {
  const { isInitializing } = useIsInitializing();
  const { me } = useMe();

  return (
    <Route
      exact={exact}
      path={path}
      render={renderProps => {
        return isInitializing ? (
          <PageLoading />
        ) : me ? (
          <Navigation>
            <TicketPopup />
            <AppliedRoute
              component={Component}
              {...renderProps}
              location={{ ...renderProps.location, state: { from: renderProps.location } }}
            />
          </Navigation>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: renderProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default AuthenticatedRoute;
