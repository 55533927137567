import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import animations from '../../styles/animations';

export function animated(ComposedComponent, defaults) {
  const componentName = ComposedComponent.displayName || ComposedComponent.name || 'Component';
  const componentPropTypes = ComposedComponent.propTypes; // eslint-disable-line

  const AnimatedComponent = styled(ComposedComponent)`
    ${css`
      ${x => x.enterStyles};
    `};

    ${x =>
      x.animation.indexOf('In') > -1 &&
      css`
        opacity: 0;
      `};

    animation: ${x => (x.animation === 'none' ? 'none' : animations[x.animation])};
    animation-delay: ${x => x.animationDelay}ms;
    animation-direction: ${x => x.animationDirection};
    animation-duration: ${x => x.animationDuration}ms;
    animation-fill-mode: ${x => x.animationFillMode};
    animation-iteration-count: ${x => x.animationIterations};
    animation-timing-function: ${x => x.theme.curves[x.animationEasing]};
  `;

  return class ComponentWithAnimation extends React.Component {
    static displayName = `${componentName}_withAnimation`;
    static propTypes = {
      ...componentPropTypes,
      animation: PropTypes.oneOf(Object.keys(animations).concat('none')),
      /** In milliseconds */
      animationDelay: PropTypes.number,
      animationDirection: PropTypes.oneOf(['normal', 'reverse', 'alternate', 'alternate-reverse']),
      /** In milliseconds */
      animationDuration: PropTypes.number,
      animationEasing: PropTypes.oneOf(['standard', 'sharp', 'accelerate', 'decelerate']),
      animationFillMode: PropTypes.oneOf(['none', 'forwards', 'backwards', 'both']),
      animationIterations: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      enterStyles: PropTypes.string,
    };
    static defaultProps = {
      animation: 'none',
      animationDelay: 0,
      animationDirection: 'normal',
      animationDuration: 500,
      animationEasing: 'standard',
      animationFillMode: 'forwards',
      animationIterations: 1,
      ...defaults,
    };
    render() {
      return <AnimatedComponent {...this.props} />;
    }
  };
}

export default animated;
