import { createGlobalStyle } from 'styled-components';
import html from './html';
import print from './print';

export const GlobalStyle = createGlobalStyle`
  ${html};
  ${print};

  body {
    &.with-scrollbar {
      overflow-y:scroll;
    }

    & > div {
      &:first-of-type {
        height: 100%;
        /* TODO: Find a way of fixing this without fucking up position:fixed everywhere
        This is used to calculate the height of the address bar on mobile browsers */
        /* perspective: 100vh; */
      }
    }
  }

  div[data-slate-editor]:not(.readonly) {
    -webkit-user-modify: read-write !important;
  }
`;
