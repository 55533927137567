import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { Modal, Placeholder, Switch } from '@energiebespaarders/symbols';
import { Right } from '@energiebespaarders/symbols/helpers';
import { Hardhat } from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useState } from 'react';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import useToaster from '../../../hooks/useToaster';
import { UPDATE_INSTALLATION } from '../../../queries/installatron';
import { alternativeInstallers_installersBySolution } from '../../../types/generated/alternativeInstallers';
import { installationByHouseSolution_installationByHouseSolution as t_installation } from '../../../types/generated/installationByHouseSolution';
import {
  installationWithAlternativePrices,
  installationWithAlternativePricesVariables,
} from '../../../types/generated/installationWithAlternativePrices';
import {
  updateInstallation,
  updateInstallationVariables,
} from '../../../types/generated/updateInstallation';
import { InstallationInput, Solution } from '../../../types/graphql-global-types';
import InstallerComparisonTable from './InstallerComparisonTable';

const INSTALLATION_WITH_ALTERNATIVE_PRICES = gql`
  query installationWithAlternativePrices($houseId: ID!, $solution: Solution!) {
    installationByHouseSolution(houseId: $houseId, solution: $solution) {
      id
      items {
        supplierId
        retailPrice
        purchasePrice
        amount

        product {
          id
          title
          priceUnit
          prices {
            id
            supplierId
            retailPrice
            purchasePrice
            availability
            minimumInstallerRate
          }
        }
      }
    }
  }
`;

interface InstallationInstallerComparisonTableProps {
  solution: Solution;
  selectedSupplierId?: string;
  onPickSupplier: (supplierId: string, updatedInstallation: InstallationInput) => void;
}

const InstallationInstallerComparisonTable: React.FC<InstallationInstallerComparisonTableProps> = ({
  solution,
  selectedSupplierId,
  onPickSupplier,
}) => {
  const { activeHouseId } = useActiveHouseId();

  const {
    data: installationData,
    loading: installationLoading,
    error: installationError,
  } = useQuery<installationWithAlternativePrices, installationWithAlternativePricesVariables>(
    INSTALLATION_WITH_ALTERNATIVE_PRICES,
    {
      variables: {
        houseId: activeHouseId,
        solution,
      },
      fetchPolicy: 'cache-first',
    },
  );

  const handleConfirm = useCallback(
    async ({ pairedSupplierId: pickedSupplierId }: alternativeInstallers_installersBySolution) => {
      const installation = installationData?.installationByHouseSolution;
      if (!installation) throw new Error('No installation!');

      // Change the supplierId on all items on the installation
      const newItems = installation.items.map(item => {
        const newPrice = item.product.prices.find(price => price.supplierId === pickedSupplierId);
        // Would it be better to remove items not suppliable by the chosen supplier?
        // Currently the old items remain on there with the old supplier
        // For PV, there often is the BTW teruggave product, on a different supplier than the rest. So, better to just leave them
        return {
          ...item,
          productId: item.product.id,
          product: undefined,
          ...(newPrice
            ? {
                retailPrice: newPrice.retailPrice,
                purchasePrice: newPrice.purchasePrice,
                supplierId: pickedSupplierId!,
              }
            : {}),
        };
      });

      onPickSupplier(pickedSupplierId!, {
        id: installation.id,
        solution: solution as Solution,
        items: newItems,
      });
    },
    [installationData?.installationByHouseSolution, onPickSupplier, solution],
  );

  if (installationLoading || installationError) {
    return <Placeholder error={installationError} />;
  }

  return (
    <InstallerComparisonTable
      solution={solution}
      items={installationData?.installationByHouseSolution?.items || []}
      selectedSupplierId={selectedSupplierId}
      onPickInstaller={handleConfirm}
      priceType="retailPrice"
    />
  );
};

interface InstallerComparisonProps {
  installation: t_installation;
  isOpen: boolean;
  closeModal: () => void;
  initialSupplierId?: string;
}

const InstallerComparisonModal: React.FC<InstallerComparisonProps> = ({
  installation,
  closeModal,
  isOpen,
  initialSupplierId,
}) => {
  const toast = useToaster();

  const [selectedSupplierId, setSelectedSupplierId] = useState(initialSupplierId || '');
  const [isRetainingRetailPrices, setRetainingRetailPrices] = useState(false);

  const [newInstallation, setNewInstallation] = useState<InstallationInput>();

  const handlePickSupplier = useCallback(
    (supplierId: string, updatedInstallation: InstallationInput) => {
      setSelectedSupplierId(supplierId);
      setNewInstallation(updatedInstallation);
    },
    [],
  );

  const [updateInstallationMut] = useMutation<updateInstallation, updateInstallationVariables>(
    UPDATE_INSTALLATION,
    {
      onError: e =>
        toast({ message: 'Er is iets mis gegaan. ' + (e as ApolloError)?.message, type: 'error' }),
    },
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      title="Installateurs vergelijken"
      size="xl"
      buttons={[
        {
          label: 'Sluiten',
          onClick: closeModal,
          bgColor: 'red',
        },
        {
          label: 'Installateur kiezen',
          iconStart: Hardhat,
          onClick: async () => {
            if (!newInstallation) return;

            const newInstallationFinal = { ...newInstallation };
            if (isRetainingRetailPrices) {
              newInstallationFinal.items = newInstallationFinal.items.map((item, index) => ({
                ...item,
                retailPrice: installation.items[index].retailPrice,
              }));
            }

            await updateInstallationMut({
              variables: {
                id: newInstallation.id,
                installation: newInstallationFinal,
              },
            });
            closeModal();
          },
        },
      ]}
    >
      <InstallationInstallerComparisonTable
        solution={installation.solution}
        selectedSupplierId={selectedSupplierId}
        onPickSupplier={handlePickSupplier}
      />
      <Right block>
        <Switch
          p={2}
          isOn={isRetainingRetailPrices}
          toggleSwitch={() => setRetainingRetailPrices(v => !v)}
          onLabel="Verkoopprijzen behouden"
          title="Bedoeld voor NS voor bij het wisselen van installateur nadat de installateur de opdracht afwijst, waarbij de klant al wel akkoord heeft gegeven op de huidige prijzen"
        />
      </Right>
    </Modal>
  );
};

export default InstallerComparisonModal;
