import { gql, useMutation } from '@apollo/client';
import {
  INTAKE_TOPICS_NL,
  IntakeTopic,
  SOLUTIONS_NL,
  Solution,
} from '@energiebespaarders/constants';
import { useForm } from '@energiebespaarders/hooks';
import { Box, Button, Icon, Input, Select, Textarea, Tooltip } from '@energiebespaarders/symbols';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { Medium, Red } from '@energiebespaarders/symbols/helpers';
import {
  Alert,
  FlagCheckered,
  FloppyDisk,
  SkipForward,
} from '@energiebespaarders/symbols/icons/solid';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useState } from 'react';
import { IntakeProgressFragment } from '../../fragments/Intake';
import { useMe } from '../../hooks/useMe';
import { checkTeamMember } from '../../lib/permissions';
import { isInPast } from '../../lib/utils/dateValidation';
import { INSTALLATION_BY_HOUSE_SOLUTION } from '../../queries/installatron';
import { intakeOverview_house_intake_progress_general_reasonNotCompleted } from '../../types/generated/intakeOverview';
import {
  setIntakeIncompletionReason,
  setIntakeIncompletionReasonVariables,
} from '../../types/generated/setIntakeIncompletionReason';
import { IntakeIncompletionReasonCategory } from '../../types/graphql-global-types';
import { OperatorTeam } from '../operatorDirectory/OperatorProfile';
import { FormattedNotes } from './IntakeNotes';
import { QuestionContent, QuestionNumber, StyledQuestion } from './Question';

const SET_INTAKE_INCOMPLETION_REASON = gql`
  ${IntakeProgressFragment}
  mutation setIntakeIncompletionReason(
    $houseId: ID!
    $topic: String!
    $reason: IntakeIncompletionInput!
  ) {
    setIntakeIncompletionReason(houseId: $houseId, topic: $topic, reason: $reason) {
      id
      ...Progress
    }
  }
`;

export const incompletionReasons: Record<IntakeIncompletionReasonCategory, string> = {
  noTime: 'Geen tijd, ik vul de rest later in',
  insideSales: 'Binnendienstopname',
  insufficientPTCResults: 'Onvolledige schouwresultaten na binnendienst opname',
};

const incompletionOptions: DropdownOption<IntakeIncompletionReasonCategory>[] = Object.entries(
  incompletionReasons,
).map(([value, label]) => ({
  label,
  value: value as IntakeIncompletionReasonCategory,
}));

type DueDateOption = 'tonight' | 'tomorrowMorning' | 'inTwoDays' | 'custom' | 'afterPtc' | 'never';

export const dueDatePresets: Record<DueDateOption, string> = {
  afterPtc: 'Kan nu niet, wacht op schouw',
  never: '-',
  tonight: 'Vanavond (18:00)',
  tomorrowMorning: 'Morgen (09:00)',
  inTwoDays: 'Overmorgen (09:00)',
  custom: 'Zelf tijdstip kiezen',
};

const dueDateOptions: DropdownOption<DueDateOption>[] = Object.entries(dueDatePresets).map(
  ([value, label]) => ({
    label,
    value: value as DueDateOption,
  }),
);

interface IncompletionFormState {
  reason: IntakeIncompletionReasonCategory;
  description: string;
  plannedCompletionDate: DueDateOption;
  customDueDate: string;
}

interface IntakeIncompletionFormProps {
  houseId: string;
  topic: IntakeTopic;
  onCompleted?: () => void;
  intakeOperatorId?: string;
  reasonNotCompleted?: intakeOverview_house_intake_progress_general_reasonNotCompleted;
  isUpdating?: boolean;
}

export const IntakeIncompletionForm: React.FC<IntakeIncompletionFormProps> = ({
  houseId,
  topic,
  onCompleted,
  intakeOperatorId,
  isUpdating,
  reasonNotCompleted,
}) => {
  const { me } = useMe();
  const [setIncompletionReasonMutation, { loading, error }] = useMutation<
    setIntakeIncompletionReason,
    setIntakeIncompletionReasonVariables
  >(SET_INTAKE_INCOMPLETION_REASON, {
    onCompleted,
    refetchQueries: [{ query: INSTALLATION_BY_HOUSE_SOLUTION }],
  });

  const { formState, handleChange, submitForm, errors } = useForm<IncompletionFormState>({
    initialValues: {
      reason:
        reasonNotCompleted?.category ||
        (checkTeamMember(me, OperatorTeam.InsideSales)
          ? IntakeIncompletionReasonCategory.insideSales
          : incompletionOptions[0].value),
      description: reasonNotCompleted?.description || '',
      plannedCompletionDate: 'tonight', // TODO: pre-select option here instead of a custom date when editing, otherwise you get validation errors (datetime in the past for Tonight)
      customDueDate: reasonNotCompleted
        ? dayjs(reasonNotCompleted?.plannedCompletionDate).format('YYYY-MM-DD')
        : '',
    },
    handleSubmit: values => {
      if (!intakeOperatorId) return;

      const parseDueDate = (optionValue: DueDateOption) => {
        const cleanHours = (dayjsObj: Dayjs) =>
          dayjsObj.set('minutes', 0).set('seconds', 0).set('milliseconds', 0);
        switch (optionValue) {
          case 'custom':
            if (!values.customDueDate) return dayjs().toDate();
            return cleanHours(dayjs(values.customDueDate)).set('hour', 12).toDate();
          case 'tonight':
            return cleanHours(dayjs()).set('hour', 18).toDate();
          case 'inTwoDays':
            return cleanHours(dayjs()).set('hour', 9).add(2, 'day').toDate();
          case 'tomorrowMorning':
          default:
            return cleanHours(dayjs()).set('hour', 9).add(1, 'day').toDate();
        }
      };
      // TODO: requires that a visit order was made to find a pipedrive deal
      setIncompletionReasonMutation({
        variables: {
          houseId,
          topic,
          reason: {
            category: values.reason,
            description: values.description,
            plannedCompletionDate: parseDueDate(values.plannedCompletionDate),
          },
        },
      });
    },
    validate: (values, errors) => {
      if (!intakeOperatorId) errors.general = 'Er is nog geen adviseur ingesteld.';
      if (!values.reason) {
        errors.reason = 'Verplicht';
      }
      if (!values.plannedCompletionDate) {
        errors.plannedCompletionDate = 'Verplicht';
      }
      if (values.plannedCompletionDate === 'custom' && !values.customDueDate) {
        errors.customDueDate = 'Verplicht';
      }
      if (values.customDueDate && isInPast(values.customDueDate)) {
        errors.customDueDate = 'In het verleden';
      }
      return errors;
    },
  });

  const handleChangeReason = useCallback(
    (option: DropdownOption<IntakeIncompletionReasonCategory>) => {
      if (!formState.plannedCompletionDate.touched) {
        if (option.value === IntakeIncompletionReasonCategory.insideSales) {
          handleChange({ plannedCompletionDate: 'afterPtc' });
          handleChange({
            description: 'Resterende benodigde informatie moet bij schouw opgehaald worden',
          });
        }
        if (option.value === IntakeIncompletionReasonCategory.insufficientPTCResults) {
          handleChange({ plannedCompletionDate: 'never' });
          handleChange({
            description: 'Er ontbreekt informatie uit de schouw om de opname volledig in te vullen',
          });
        }
      }
      handleChange({ reason: option.value });
    },
    [formState.plannedCompletionDate.touched, handleChange],
  );

  const handleChangeDueDateOption = useCallback(
    (option: DropdownOption<DueDateOption>) => {
      if (option.value === 'custom' && !formState.customDueDate.touched) {
        handleChange({ customDueDate: dayjs().format('YYYY-MM-DD') });
      } else if (option.value !== 'custom') {
        handleChange({ customDueDate: '' });
      }
      handleChange({ plannedCompletionDate: option.value });
    },
    [formState.customDueDate.touched, handleChange],
  );

  const formHasErrors = Object.values(formState).some(field => field.error);

  // Only allow the incomplete PTC option when the previously provided reason was inside-sales
  const filteredIncompletionOptions =
    reasonNotCompleted?.category &&
    [
      IntakeIncompletionReasonCategory.insideSales,
      IntakeIncompletionReasonCategory.insufficientPTCResults,
    ].includes(reasonNotCompleted.category)
      ? incompletionOptions
      : incompletionOptions.filter(option => option.value !== 'insufficientPTCResults');

  // Only allow the after-ptc option when the reason is inside sales,
  // and the never option when the reason is insufficient PTC results
  let filteredDueDateOptions = dueDateOptions;
  if (formState.reason.value !== IntakeIncompletionReasonCategory.insideSales) {
    filteredDueDateOptions = filteredDueDateOptions.filter(o => o.value !== 'afterPtc');
  }
  if (formState.reason.value !== IntakeIncompletionReasonCategory.insufficientPTCResults) {
    filteredDueDateOptions = filteredDueDateOptions.filter(o => o.value !== 'never');
  }

  return (
    <form onSubmit={submitForm}>
      <Select<IntakeIncompletionReasonCategory>
        {...formState.reason}
        label="Reden"
        labelSize={6}
        options={filteredIncompletionOptions}
        value={incompletionOptions.find(o => o.value === formState.reason.value)}
        onChange={handleChangeReason}
      />

      <Textarea
        {...formState.description}
        label="Toelichting"
        labelSize={6}
        onChange={e => handleChange({ description: e.target.value })}
      />

      <Select<DueDateOption>
        {...formState.plannedCompletionDate}
        label="Wanneer kun je dit voltooien? Er wordt een activiteit in Pipedrive voor je aangemaakt."
        labelSize={6}
        placeholder="Kies een moment"
        options={filteredDueDateOptions}
        value={dueDateOptions.find(o => o.value === formState.plannedCompletionDate.value)}
        onChange={handleChangeDueDateOption}
      />

      {(formState.plannedCompletionDate.value === 'custom' || !!formState.customDueDate.error) && (
        <Input
          {...formState.customDueDate}
          type="date"
          min={dayjs().format('YYYY-MM-DD')}
          label="Aangepaste datum (12:00)"
          labelSize={6}
          onChange={e => handleChange({ customDueDate: e.target.value })}
        />
      )}

      <Box textAlign="right">
        {isUpdating && (
          <Button onClick={onCompleted} inverse bgColor="red" label="Annuleren" mb={0} />
        )}
        <Button
          type="submit"
          disabled={formHasErrors || loading}
          iconStart={isUpdating ? FloppyDisk : SkipForward}
          loading={loading}
          error={error?.toString() || errors.general}
          mr={0}
          mb={0}
          label={isUpdating ? 'Opslaan' : 'Laat me offertes maken'}
        />
      </Box>
    </form>
  );
};

type IntakeIncompletionCardProps = IntakeIncompletionFormProps & {
  number: number;
  completedDate?: Date;
  isCompletable: boolean | null;
  handleFinishTopic: () => void;
  solutions: Solution[];
};

const IntakeIncompletionQuestionCard: React.FC<IntakeIncompletionCardProps> = props => {
  const {
    number,
    completedDate,
    reasonNotCompleted,
    topic,
    isCompletable,
    handleFinishTopic,
    solutions,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const isIncompletionConfirmed = !!reasonNotCompleted;
  return (
    <StyledQuestion>
      <QuestionNumber $bgColor={themify('blue')}>{number}</QuestionNumber>
      <QuestionContent>
        {isCompletable ? (
          <h5>Dit onderdeel kan nu afgerond worden</h5>
        ) : (
          <h5>
            Waarom kun je de opname voor {INTAKE_TOPICS_NL[topic].toLowerCase()} niet volledig
            invullen?
          </h5>
        )}

        {!isIncompletionConfirmed || !!completedDate || isEditing ? (
          <IntakeIncompletionForm
            {...props}
            isUpdating={isEditing}
            onCompleted={() => setIsEditing(false)}
          />
        ) : (
          <>
            {isCompletable && (
              <Button bgColor="blue" iconStart={FlagCheckered} onClick={handleFinishTopic} fluid>
                Onderdeel afronden
              </Button>
            )}

            <p>
              {dayjs(reasonNotCompleted.plannedCompletionDate).isBefore(dayjs()) ? (
                <span>
                  Afronding van opname-onderdeel stond uitgesteld tot{' '}
                  <strong>{dayjs(reasonNotCompleted.plannedCompletionDate).calendar()}</strong>
                </span>
              ) : (
                <span>
                  Afronding van opname-onderdeel is uitgesteld tot{' '}
                  <strong>{dayjs(reasonNotCompleted.plannedCompletionDate).calendar()}</strong>
                </span>
              )}
            </p>
            <FormattedNotes>
              {'>'}{' '}
              {incompletionOptions.find(o => o.value === reasonNotCompleted.category)?.label ||
                reasonNotCompleted.category}
              <p>{reasonNotCompleted.description}</p>
            </FormattedNotes>
            <Button
              label="Reden of activiteit aanpassen"
              onClick={() => setIsEditing(true)}
              inverse
              bgColor="blue"
            />
          </>
        )}
        <p>
          <Icon icon={Alert} fill="red" mr={1} />{' '}
          <Red>
            Let op: alle offertes{' '}
            {topic === IntakeTopic.General ? (
              ''
            ) : (
              <>
                voor{' '}
                <Medium>
                  {solutions.length
                    ? solutions.map(s => SOLUTIONS_NL[s].toLowerCase()).join(' en ')
                    : INTAKE_TOPICS_NL[topic].toLowerCase()}
                </Medium>
              </>
            )}{' '}
            blijven{' '}
            <Tooltip content="Er komt bij de klant een melding op de offerte dat er nog een voorbehoud op technische haalbaarheid zit.">
              <span>
                <u>voorlopig</u>
              </span>
            </Tooltip>{' '}
            tot dit onderdeel is afgerond. Er kunnen ook geen opdrachten voor verstuurd worden.
          </Red>
        </p>
      </QuestionContent>
    </StyledQuestion>
  );
};

export default IntakeIncompletionQuestionCard;
