// Converts dots to empty string: into the NL number format, and comma to dot
const localeToJs = (s = '') => s.split('.').join('_').split(',').join('.').split('_').join('');

export const currencyFilter = (s: string) => {
  const f = s.match(/[\d.]+(,\d\d|,\d|,|)/);
  return f ? f[0] : '';
};

export const delimit = (
  amount?: string | number,
  decimals = 2,
  isCurrency = false,
  locales = 'nl-NL',
) => {
  if (amount === undefined || amount === null) return '';
  if (typeof amount === 'number') {
    return Number(amount).toLocaleString(locales, {
      currency: 'EUR',
      style: isCurrency ? 'currency' : 'decimal',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  } else if (typeof amount === 'string') {
    const clean = localeToJs(amount || '0');
    return parseFloat(clean).toLocaleString(locales, {
      currency: 'EUR',
      style: isCurrency ? 'currency' : 'decimal',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }
  return amount;
};

export const parseCurrencyToFloat = (amount: number | string) => {
  let val: number;
  if (typeof amount === 'number') {
    val = amount;
  } else if (typeof amount === 'string') {
    val = parseFloat(localeToJs(!amount ? '0' : amount));
  } else {
    console.warn('INCORRECT VARIABLE PASSED TO parseCurrencyToFloat', amount, typeof amount);
    val = amount;
  }
  return Math.round(100 * val) / 100;
};
