import { gql, useQuery } from '@apollo/client';
import { PRODUCT_ENUMS_NL, Solution } from '@energiebespaarders/constants';
import { Icon, Placeholder, Tooltip } from '@energiebespaarders/symbols';
import { Center, Red, Right, Small } from '@energiebespaarders/symbols/helpers';
import { Home, Warning } from '@energiebespaarders/symbols/icons/line';
import React, { useMemo } from 'react';
import { Cell, Column, defaultColumn, useBlockLayout, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import styled from 'styled-components';
import {
  HeaderText,
  ProductTitle,
  TableWrapper,
} from '../../../domains/Products/productTableHelpers';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import { useHasPricingPermission } from '../../../hooks/useHasPricingPermission';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { delimit } from '../../../lib/utils';
import { notEmpty } from '../../../typeHelpers';
import {
  alternativeInstallers,
  alternativeInstallersVariables,
  alternativeInstallers_installersBySolution,
} from '../../../types/generated/alternativeInstallers';
import {
  installationWithAlternativePrices_installationByHouseSolution_items,
  installationWithAlternativePrices_installationByHouseSolution_items_product_prices,
} from '../../../types/generated/installationWithAlternativePrices';
import { InstallerStatus, PriceAvailability } from '../../../types/graphql-global-types';
import InstallerStatusIndicators from '../../companies/InstallerStatusIndicator';

const CustomTableWrapper = styled(TableWrapper)`
  .clickable {
    cursor: pointer;
  }
  .clickable[aria-selected='false'] {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  .clickable[aria-selected='true'] {
    /* When selected: Green -> blue */
    filter: hue-rotate(60deg);

    border-left: 1px dotted gray;
    border-right: 1px dotted gray;
  }

  .sub-footer {
    font-weight: normal;
  }
`;

type t_item = installationWithAlternativePrices_installationByHouseSolution_items;
type t_price = installationWithAlternativePrices_installationByHouseSolution_items_product_prices;

type CustomColumn = Column<t_item> & { sticky?: string; Footer?: any };
type CustomCell = Cell<t_item> & {
  selectedSupplierId?: string;
  setSelectedSupplierId: React.Dispatch<React.SetStateAction<string>>;
};

const ALTERNATIVE_INSTALLERS = gql`
  query alternativeInstallers($houseId: ID!, $solution: Solution!) {
    installersBySolution(solution: $solution) {
      id
      name
      status {
        value
      }
      pairedSupplierId
      distanceToHouse(houseId: $houseId)
      capableOfInstallingAtHouse(houseId: $houseId)
      minimumRate
      installationRegions {
        from
        to
      }
    }
  }
`;

const HEX_ID_LENGTH = 24;

interface InstallerComparisonTableProps {
  solution: Solution;
  items: ReadonlyArray<installationWithAlternativePrices_installationByHouseSolution_items>;
  selectedSupplierId?: string;
  onPickInstaller: (installer: alternativeInstallers_installersBySolution) => void;
  priceType: 'retailPrice' | 'purchasePrice';
  onlyShowAlternativesWithAllProducts?: boolean;
}

/**
 * Show a table similar to the ProductDB with all installers that have prices
 * for the products on the installation.
 * Additional indicators to show:
 * - distance / work radius/ work regions
 * - total cost + comparison to currently chosen installer
 * - a button to swap installers (modify supplierId on all items)
 *
 * ```md
 * Product      | current installer   | alternative installer 1 | alt installer 2 | ...
 *              | 21km                | 63km
 * 32x item 1   | 12345               | ...
 * 32x item 2   | ...                 | ...
 *  1x item 3   | ...                 | _niet leverbaar_
 * Total        | 23456               | 22222 (1234 goedkoper)
 *
 *                                    | [Kies Installateur X]
 * ```
 *
 * Maybe: Click an installer column to highlight it and show more details
 * Then click the modal button to confirm
 */
const InstallerComparisonTable: React.FC<InstallerComparisonTableProps> = ({
  solution,
  items,
  selectedSupplierId,
  onPickInstaller,
  priceType,
  onlyShowAlternativesWithAllProducts,
}) => {
  const { activeHouseId } = useActiveHouseId();
  const isMobile = useIsMobile();

  const data = useMemo(
    () => [
      // Only show inStock prices
      ...(items.map(item => ({
        ...item,
        product: {
          ...item.product,
          prices: item.product.prices.filter(
            price => price.availability === PriceAvailability.inStock,
          ),
        },
      })) || []),
    ],
    [items],
  );

  const { data: suppliersData, loading: suppliersLoading, error: suppliersError } = useQuery<
    alternativeInstallers,
    alternativeInstallersVariables
  >(ALTERNATIVE_INSTALLERS, {
    variables: { houseId: activeHouseId, solution },
  });

  const supplierToInstallerDict = useMemo(
    () =>
      (suppliersData?.installersBySolution || []).reduce((prev, cur) => {
        if (cur.pairedSupplierId) {
          prev[cur.pairedSupplierId] = cur;
        }
        return prev;
      }, {} as Record<string, alternativeInstallers_installersBySolution>),
    [suppliersData],
  );

  const sortedInstallers = useMemo(() => {
    const currentSupplierId = items[0].supplierId;

    // 1. Build a list of suppliers that have prices for any of the items
    const suppliersWithPrices = new Set<string>();
    items.forEach(item =>
      item.product.prices
        .filter(p => p.availability === PriceAvailability.inStock)
        .forEach(price => suppliersWithPrices.add(price.supplierId)),
    );

    // If needed, filter out installers that don't have all the same products available as the current installer
    // (which may not be the same as _all_ products: e.g. "BTW teruggave" is for a different supplier)
    if (onlyShowAlternativesWithAllProducts) {
      const productIdsSuppliedByInstaller = items
        .filter(item => item.supplierId === currentSupplierId)
        .map(item => item.product.id);
      for (const alternativeSupplierId of suppliersWithPrices) {
        const suppliesAllProductsToo = productIdsSuppliedByInstaller.every(
          productId =>
            items
              .find(i => i.product.id === productId)
              ?.product.prices.find(price => price.supplierId === alternativeSupplierId)
              ?.availability === PriceAvailability.inStock,
        );
        if (!suppliesAllProductsToo) {
          suppliersWithPrices.delete(alternativeSupplierId);
        }
      }
    }

    const sortedIds = [];
    // Start with current installer
    if (suppliersWithPrices.has(currentSupplierId)) {
      suppliersWithPrices.delete(currentSupplierId);
      sortedIds.push(currentSupplierId);
    }

    // 2. Then the installers that have prices for all products, sorted by total retail price
    const suppliersWithAllProducts = [];
    for (const supplierId of suppliersWithPrices) {
      if (items.every(item => item.product.prices.some(price => price.supplierId === supplierId))) {
        // Yes, it is perfectly fine to add/remove elements to a set while iterating it
        suppliersWithPrices.delete(supplierId);
        suppliersWithAllProducts.push(supplierId);
      }
    }
    const getTotalPrice = (supplierId: string) =>
      items.reduce(
        (prev, curr) =>
          prev +
          curr.amount *
            (curr.product.prices.find(price => price.supplierId === supplierId)?.[priceType] || 0),
        0,
      );
    suppliersWithAllProducts.sort((a, b) => getTotalPrice(a) - getTotalPrice(b));
    sortedIds.push(...suppliersWithAllProducts);

    // sort remaining installers (with only some of the products available)
    {
      const amountOfProducts = [];
      for (const supplierId of suppliersWithPrices) {
        amountOfProducts.push({
          id: supplierId,
          count: items.filter(item =>
            item.product.prices.some(price => price.supplierId === supplierId),
          ).length,
        });
      }
      // Sort each group that has the same amount of items per retail price too
      amountOfProducts.sort((a, b) => {
        const countDiff = b.count - a.count;
        return countDiff === 0 ? getTotalPrice(a.id) - getTotalPrice(b.id) : countDiff;
      });

      sortedIds.push(...amountOfProducts.map(entry => entry.id));
    }

    // TODO how can there be installers undefined here? They have a price, but not marked as capable for that solution?
    return sortedIds
      .map(id => supplierToInstallerDict[id])
      .filter(
        (inst, i) =>
          inst !== undefined && (i === 0 || inst.status.value !== InstallerStatus.inactive),
      );
  }, [items, onlyShowAlternativesWithAllProducts, priceType, supplierToInstallerDict]);

  // alternative: pre-create groups for each supplier, change hiddenColumns when products are updated
  const columns = useMemo((): CustomColumn[] => {
    return [
      {
        id: 'product',
        // if your table contain at least one header group, place yours sticky columns into a group too (even with an empty Header name)
        Header: priceType === 'retailPrice' ? ' ' : 'Inkoopprijzen bij alternatieve installateurs', // only explicitly mention if it's concerning purchase prices: retail prices are default
        Footer: <span className="sub-footer">Minimumtarief (inkoopprijs)</span>,
        sticky: isMobile ? undefined : 'left',
        columns: [
          {
            id: 'product',
            Header: 'Product',
            accessor: item => item,
            width: 350,
            Cell: function Title({ value }: CustomCell) {
              const unitLabel =
                value.product.priceUnit === 'unit'
                  ? ''
                  : PRODUCT_ENUMS_NL[value.product.priceUnit!] || '';
              return (
                <ProductTitle>
                  {value.amount} {unitLabel} {value.product.title}
                </ProductTitle>
              );
            },
            Footer: 'Totaal',
          },
        ],
      },
      ...sortedInstallers.map(
        (installer): CustomColumn => {
          const minRate = Math.max(
            ...[
              ...data.map(
                item =>
                  item.product.prices.find(p => p.supplierId === installer.pairedSupplierId)
                    ?.minimumInstallerRate,
              ),
              installer.minimumRate,
            ].filter(notEmpty),
          );
          const statusIndicator = InstallerStatusIndicators[installer.status.value];
          return {
            id: installer.pairedSupplierId!,
            Header: <Right block>{installer.name}</Right>,
            Footer: (
              <Right block className="sub-footer">
                {minRate <= 0 ? '-' : `€ ${delimit(minRate, 2)}`}
              </Right>
            ),
            columns: [
              {
                id: installer.pairedSupplierId!,
                accessor: item =>
                  item.product.prices?.find(p => p.supplierId === installer.pairedSupplierId),
                width: 130,
                Header: (
                  <Right block>
                    {installer.status.value !== InstallerStatus.active && (
                      <Tooltip
                        content={[statusIndicator.label, statusIndicator.description].join(': ')}
                        bgColor={statusIndicator.color}
                      >
                        <Icon
                          icon={statusIndicator.icon}
                          fill="orange"
                          strokeWidth={1}
                          stroke="gray"
                          mr={1}
                          hoverColor="red"
                          solid
                        />
                      </Tooltip>
                    )}{' '}
                    {!installer.capableOfInstallingAtHouse && (
                      <Tooltip
                        content="Deze installatiepartner kan niet op dit adres installeren"
                        bgColor="red"
                      >
                        <Icon stroke="orange" strokeWidth={4} icon={Home} mr={1} hoverColor="red" />
                      </Tooltip>
                    )}{' '}
                    {installer.installationRegions.length === 0 && (
                      <Tooltip
                        content="Werkgebieden van deze installateur zijn onbekend"
                        bgColor="red"
                      >
                        <Icon
                          stroke="orange"
                          strokeWidth={4}
                          icon={Warning}
                          mr={1}
                          hoverColor="red"
                        />
                      </Tooltip>
                    )}
                    {`${
                      !installer.distanceToHouse
                        ? '?'
                        : (installer.distanceToHouse / 1000).toFixed(1)
                    }km`}
                  </Right>
                ),
                Cell: function PriceCell({ value: price }: { value: t_price }) {
                  const hasPricingPermission = useHasPricingPermission();

                  if (price !== undefined) {
                    const content = <Right block>{`€ ${delimit(price[priceType], 2)}`}</Right>;

                    // Only show margins and purchase prices for those with the right to deal with them
                    if (!hasPricingPermission) return content;

                    // margin can be undefined to prevent NaN numbers from dividing by 0.
                    const margin =
                      price.retailPrice !== 0
                        ? 1 - price.purchasePrice / price.retailPrice
                        : undefined;

                    return (
                      <Tooltip
                        content={`${
                          priceType === 'retailPrice'
                            ? `Inkoopprijs: € ${delimit(price.purchasePrice, 2)}`
                            : `Verkoopprijs: € ${delimit(price.retailPrice, 2)}`
                        }, marge: ${margin ? delimit(100 * margin, 1) : ' -'}%`}
                        bgColor="blue"
                        block
                      >
                        {content}
                      </Tooltip>
                    );
                  } else {
                    return <Right block>-</Right>;
                  }
                },
                Footer: function Total() {
                  const hasPricingPermission = useHasPricingPermission();

                  let totalRetail = 0;
                  let totalPurchase = 0;
                  for (const item of data) {
                    const price = item.product.prices.find(
                      price => price.supplierId === installer.pairedSupplierId,
                    );
                    if (price) {
                      totalRetail += item.amount * price.retailPrice;
                      totalPurchase += item.amount * price.purchasePrice;
                    }
                  }

                  const content = <Right block>{`€ ${delimit(totalRetail, 2)}`}</Right>;

                  // Only show margins and purchase prices for those with the right to deal with them
                  if (!hasPricingPermission) return content;

                  // margin can be undefined to prevent NaN numbers from dividing by 0.
                  const margin = totalRetail !== 0 ? 1 - totalPurchase / totalRetail : undefined;

                  return (
                    <Tooltip
                      content={`${
                        priceType === 'retailPrice'
                          ? `Inkoopprijs: € ${delimit(totalPurchase, 2)}`
                          : `Verkoopprijs: € ${delimit(totalRetail, 2)}`
                      }, marge: ${margin ? delimit(100 * margin, 1) : ' -'}%`}
                      bgColor="blue"
                      block
                    >
                      {content}
                    </Tooltip>
                  );
                },
              },
            ],
          };
        },
      ),
    ];
  }, [data, isMobile, priceType, sortedInstallers]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: columns as any, // weird TS error /shrug/
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetHiddenColumns: false,
      autoResetExpanded: false,
      defaultColumn,
      // You can also pass in custom data that is available in cells!
      // See Product DB table for example (used for price modification etc.)
      selectedSupplierId,
    },
    useBlockLayout,
    useSticky,
  );

  if (suppliersLoading || suppliersError) {
    return <Placeholder error={suppliersError} />;
  }

  const handleSubmit = (id: string) => onPickInstaller(supplierToInstallerDict[id]);

  const cellProps = (column: { id?: string }, className: string) =>
    column.id?.startsWith('product')
      ? { className }
      : !!selectedSupplierId && column.id?.startsWith(selectedSupplierId)
      ? {
          'aria-selected': true,
          onClick: () => handleSubmit(column.id!.substring(0, HEX_ID_LENGTH)),
          className: `${className} clickable`,
        }
      : {
          'aria-selected': false,
          onClick: () => handleSubmit(column.id!.substring(0, HEX_ID_LENGTH)),
          className: `${className} clickable`,
        };

  if (sortedInstallers.length < 1) {
    return (
      <Red>
        <Center block>
          Helaas, er zijn geen installateurs die als alternatief beschikbaar zijn.
          {onlyShowAlternativesWithAllProducts && (
            <>
              <br />
              <Small>Er ontbreken installateurs die alle zelfde producten aanbieden.</Small>
            </>
          )}
        </Center>
      </Red>
    );
  }

  return (
    <CustomTableWrapper>
      <div {...getTableProps()} className="table sticky">
        <div className="header">
          {headerGroups.map(headerGroup => (
            // react-table already adds keys, no need to do it ourselves
            // eslint-disable-next-line react/jsx-key
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map(column => (
                // eslint-disable-next-line react/jsx-key
                <div {...cellProps(column, 'th')} {...column.getHeaderProps()}>
                  <HeaderText
                    $sortOrder={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}
                    $active={false}
                  >
                    {column.render('Header')}
                  </HeaderText>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {rows.map(row => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <div {...row.getRowProps()} className="tr">
                {row.cells.map(cell => (
                  // eslint-disable-next-line react/jsx-key
                  <div {...cellProps(cell.column, 'td')} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <div className="footer">
          {footerGroups.map(footerGroup => (
            // eslint-disable-next-line react/jsx-key
            <div {...footerGroup.getHeaderGroupProps()} className="tr">
              {footerGroup.headers.map(column => (
                // eslint-disable-next-line react/jsx-key
                <div {...cellProps(column, 'td')} {...column.getHeaderProps()}>
                  <Right block>{column.render('Footer')}</Right>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </CustomTableWrapper>
  );
};

export default InstallerComparisonTable;
