import { useLazyQuery, useQuery } from '@apollo/client';
import { Solution } from '@energiebespaarders/constants';
import { Icon, Tooltip } from '@energiebespaarders/symbols';
import { Red } from '@energiebespaarders/symbols/helpers';
import { Alert, Shipping, Warning } from '@energiebespaarders/symbols/icons/solid';
import React, { useEffect, useState } from 'react';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import useToaster from '../../../hooks/useToaster';
import { GET_PRICE_AVAILABILITY, SUPPLIER_BY_ID } from '../../../queries/installatron';
import {
  checkPriceAvailability,
  checkPriceAvailabilityVariables,
} from '../../../types/generated/checkPriceAvailability';
import { installationByHouseSolution_installationByHouseSolution_items } from '../../../types/generated/installationByHouseSolution';
import { supplierById, supplierByIdVariables } from '../../../types/generated/supplierById';
import { HiddenIcon, SlideSpan } from '../ProductList';
import UpdateSupplierModal from '../Quote/UpdateSupplierModal';

interface InstallationItemLineProps {
  installationId: string;
  item: Omit<installationByHouseSolution_installationByHouseSolution_items, 'tieredRetailPrice'>;
  isForQuote: boolean;
  solution?: Solution;
  readOnly: boolean;
}

const InstallationItemLine: React.FC<InstallationItemLineProps> = ({
  item,
  installationId,
  isForQuote,
  solution,
  readOnly,
}) => {
  const [isUpdatingSupplier, setIsUpdatingSupplier] = useState(false);
  const toast = useToaster();
  const isMobile = useIsMobile();
  const { data } = useQuery<checkPriceAvailability, checkPriceAvailabilityVariables>(
    GET_PRICE_AVAILABILITY,
    {
      variables: { productId: item.product.id, supplierId: item.supplier.id },
      fetchPolicy: 'network-only',
      onError: () =>
        toast({
          type: 'error',
          message: 'Error finding the availability of this price',
        }),
    },
  );

  const [
    getSupplier,
    { data: supplierData, loading: supplierLoading, error: supplierError },
  ] = useLazyQuery<supplierById, supplierByIdVariables>(SUPPLIER_BY_ID, {
    variables: { id: item.supplier.id },
  });

  useEffect(() => {
    if (data?.checkPriceAvailability && !supplierData && !supplierLoading && !supplierError)
      getSupplier();
  }, [data?.checkPriceAvailability, getSupplier, supplierData, supplierError, supplierLoading]);

  if (!data) return <></>;
  const { checkPriceAvailability: available } = data;

  return (
    <>
      <Tooltip
        bgColor={available && supplierData ? (isMobile ? 'black' : 'blue') : 'red'}
        content={
          supplierData && supplierData.supplierById
            ? `${supplierData.supplierById.name}${
                !available
                  ? ' - Dit product is niet meer leverbaar door deze leverancier'
                  : `${!isForQuote ? ' - Klik om leverancier aan te passen' : ''}`
              }`
            : 'Handmatig aangepast product'
        }
        placement="right"
      >
        <HiddenIcon
          isForQuote={isForQuote}
          onClick={() => !readOnly && setIsUpdatingSupplier(true)}
          icon={available && supplierData ? Shipping : Alert}
          fill={supplierData ? (isMobile ? 'white' : 'gray') : 'red'}
          hoverColor={available && supplierData ? (isMobile ? 'white' : 'blue') : 'red'}
          isMobile={isMobile}
          solid
        />
      </Tooltip>

      {/* Updating suppliers should only be used in Installation, not in Quote ProductList */}
      {!isForQuote && supplierData && solution && (
        <UpdateSupplierModal
          available={!!available}
          isOpen={isUpdatingSupplier}
          item={item}
          installationId={installationId}
          supplier={supplierData?.supplierById}
          solution={solution}
          handleClose={() => setIsUpdatingSupplier(false)}
        />
      )}

      <SlideSpan isMobile={isMobile}>
        {available ? item.product.title : <Red>{item.product.title}</Red>}
        {!available && (
          <Tooltip bgColor="red" content="Dit product is niet meer leverbaar door deze leverancier">
            <Icon icon={Warning} solid fill="red" mx={1} />
          </Tooltip>
        )}
      </SlideSpan>
    </>
  );
};

export default InstallationItemLine;
