import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Solution } from '@energiebespaarders/constants';
import { Modal, Placeholder, Select, Separator, Spinner } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import React, { useMemo, useState } from 'react';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import useToaster from '../../../hooks/useToaster';
import { INSTALLATION_BY_HOUSE_SOLUTION } from '../../../queries/installatron';
import { installationByHouseSolution_installationByHouseSolution_items } from '../../../types/generated/installationByHouseSolution';
import {
  suppliersByProduct,
  suppliersByProductVariables,
} from '../../../types/generated/suppliersByProduct';
import {
  updateSupplierInInstallation,
  updateSupplierInInstallationVariables,
} from '../../../types/generated/updateSupplierInInstallation';
import { UPDATE_SUPPLIER_IN_INSTALLATION } from './mutations';
import { SUPPLIERS_BY_PRODUCT } from './queries';

type UpdateSuppliedModalProps = {
  isOpen: boolean;
  available: boolean;
  item: Omit<installationByHouseSolution_installationByHouseSolution_items, 'tieredRetailPrice'>;
  installationId: string;
  supplier: any;
  solution: Solution;
  handleClose: () => void;
};

const UpdateSupplierModal: React.FC<UpdateSuppliedModalProps> = ({
  available,
  isOpen,
  item,
  installationId,
  supplier,
  solution,
  handleClose,
}) => {
  const isMobile = useIsMobile();
  const { activeHouseId } = useActiveHouseId();
  const { data: eligableSupplierData, loading: suppliersLoading, error } = useQuery<
    suppliersByProduct,
    suppliersByProductVariables
  >(SUPPLIERS_BY_PRODUCT, {
    variables: { productId: item.product.id },
    fetchPolicy: 'network-only',
  });
  const toast = useToaster();

  const [selectedSupplierId, setSelectedSupplierId] = useState('');
  const { product, ...rest } = item;
  const [updateInstallation, { loading }] = useMutation<
    updateSupplierInInstallation,
    updateSupplierInInstallationVariables
  >(UPDATE_SUPPLIER_IN_INSTALLATION, {
    variables: {
      installationId,
      oldItem: {
        ...rest,
        supplierId: rest.supplier.id,
        productId: product.id,
        ...({ supplier: undefined } as any),
      },
      newSupplierId: selectedSupplierId,
    },
    onError: (e: ApolloError) => {
      toast({
        type: 'error',
        message: e.message,
      });
    },
    update: (cache, { data }) => {
      const queryVars = { houseId: activeHouseId, solution };

      cache.writeQuery({
        query: INSTALLATION_BY_HOUSE_SOLUTION,
        variables: queryVars,
        data,
      });
    },
    onCompleted: () => {
      toast({
        type: 'success',
        message: 'Installatie successvol aangepast',
      });
      handleClose();
    },
  });

  const supplierOptions = useMemo(
    () =>
      eligableSupplierData?.suppliersByProduct.map(s => ({
        label: s.name,
        value: s.id,
      })) || [],
    [eligableSupplierData],
  );

  if (error) return <Placeholder error={error} />;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      title="Leverancier aanpassen"
      size="sm"
      mobile={isMobile}
      buttons={[
        {
          bgColor: 'green',
          label: 'Leverancier aanpassen',
          onClick: () => updateInstallation(),
          disabled: loading || !selectedSupplierId,
          title: !selectedSupplierId ? 'Kies een leverancier' : '',
        },
      ]}
    >
      {!available && (
        <Medium>
          Dit product is niet meer leverbaar door {supplier.name}. Kies een andere leverancier.
        </Medium>
      )}
      <div>Product:</div>
      <Medium>
        <p>{item.product.title}</p>
      </Medium>
      <Separator my={3} />
      {suppliersLoading || !eligableSupplierData ? (
        <Spinner />
      ) : (
        <Select<string>
          label="Beschikbare leveranciers"
          placeholder="Kies leverancier"
          options={supplierOptions}
          onChange={e => setSelectedSupplierId(e.value)}
          value={supplierOptions.find(option => option.value === selectedSupplierId)}
        />
      )}
      <p>
        <Medium>LET OP:</Medium> Door de leverancier te veranderen, kan de prijs op de offerte ook
        meeveranderen!
      </p>
    </Modal>
  );
};

export default UpdateSupplierModal;
