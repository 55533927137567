import {
  IInstallationCalculationItem,
  delimit,
  getQuoteTotals,
} from '@energiebespaarders/constants';
import { Box, Card, Flex, Icon } from '@energiebespaarders/symbols';
import { Color, Large, Medium } from '@energiebespaarders/symbols/helpers';
import { AddCircle, MinusCircle, NavigationCircle } from '@energiebespaarders/symbols/icons/solid';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import _ from 'lodash';
import { rgba } from 'polished';
import React from 'react';
import {
  jobForMutationModal_jobById_mutation_items,
  jobForMutationModal_jobById_mutation_items_product,
} from '../../types/generated/jobForMutationModal';

type RequiredItemProps = {
  amount: number;
  retailPrice: number;
  purchasePrice?: number;
  product: Pick<
    jobForMutationModal_jobById_mutation_items_product,
    'id' | 'title' | 'solution' | 'category' | 'priceUnit' | 'tax'
  > | null;
};

interface JobMutationDiffsProps {
  originalItems: RequiredItemProps[];
  mutationItems: RequiredItemProps[];
}

type ItemProp = keyof Omit<
  jobForMutationModal_jobById_mutation_items,
  'product' | 'price' | 'supplier' | 'purchasePrice' | '__typename' | 'tieredRetailPrice'
>;

const MUTABLE_ITEM_PROPS_NL: Record<ItemProp, string> = {
  amount: 'Aantal',
  retailPrice: 'Verkoopprijs',
};

const JobMutationDiffs: React.FC<JobMutationDiffsProps> = ({ originalItems, mutationItems }) => {
  const additions = mutationItems.filter(
    item => !originalItems.some(ogItem => ogItem.product?.id === item.product?.id),
  );
  const deletions = originalItems.filter(
    item => !mutationItems.some(ogItem => ogItem.product?.id === item.product?.id),
  );
  const updates = mutationItems.filter(item =>
    originalItems.some(
      ogItem =>
        ogItem.product?.id === item.product?.id &&
        (ogItem.amount !== item.amount || ogItem.retailPrice !== item.retailPrice),
    ),
  );

  const originalTotals = getQuoteTotals([...originalItems] as IInstallationCalculationItem[]);
  const newTotals = getQuoteTotals([...mutationItems] as IInstallationCalculationItem[]);

  const totalRetailPriceDiff = newTotals.totalIncludingTax - originalTotals.totalIncludingTax;

  return (
    <>
      {[...additions, ...deletions, ...updates].length > 0 ? (
        <>
          <Card animation="none" shadow="none" borderColor="grayLight" bgColor="grayLighter" mb={1}>
            <div>
              Originele opdrachtprijs voor de klant:{' '}
              <Medium>€{delimit(originalTotals.totalIncludingTax, 2)}</Medium> (zonder evt.
              kortingen)
            </div>
            <div>
              Nieuwe opdrachtprijs voor de klant:{' '}
              <Medium>€{delimit(newTotals.totalIncludingTax, 2)}</Medium> (zonder evt. kortingen)
            </div>
            <div>
              <Medium>
                Totale {totalRetailPriceDiff > 0 ? 'meerprijs' : 'minderprijs'} voor de klant: €
                {delimit(totalRetailPriceDiff)} ({totalRetailPriceDiff > 0 ? '+' : ''}
                {delimit((totalRetailPriceDiff / originalTotals.totalIncludingTax) * 100, 1)}
                %)
              </Medium>
            </div>
          </Card>

          {additions.length > 0 && (
            <Card
              animation="none"
              shadow="none"
              borderColor="greenLighter"
              style={{ borderWidth: '0.5px' }}
              bgColor={rgba(themify('green'), 0.1)}
              mb={1}
            >
              <Color c="greenDark">
                <Large>
                  <Icon icon={AddCircle} fill="green" verticalAlign="sub" />{' '}
                  <Medium>Toegevoegd aan de opdracht</Medium>
                </Large>
              </Color>

              <Flex mx="-3px" style={{ fontWeight: 600 }}>
                <Box width={1 / 12} p="3px" textAlign="center">
                  Aantal
                </Box>
                <Box width={6 / 12} p="3px">
                  Product
                </Box>
                <Box width={2 / 12} p="3px" textAlign="right">
                  Verkoopprijs
                </Box>
                <Box width={3 / 12} p="3px" textAlign="right">
                  Subtotaal
                </Box>
              </Flex>

              {additions.map(
                ({ product, amount, retailPrice }) =>
                  product && (
                    <Flex
                      key={`addition-${product.id}`}
                      mx="-3px"
                      style={{ borderTop: '0.5px solid', borderColor: themify('greenLighter') }}
                    >
                      <Box width={1 / 12} p="3px" textAlign="center">
                        {amount}
                      </Box>
                      <Box width={6 / 12} p="3px">
                        {product.title}
                      </Box>
                      <Box width={2 / 12} p="3px" textAlign="right">
                        €{delimit(retailPrice, 2)}{' '}
                      </Box>
                      <Box width={3 / 12} p="3px" textAlign="right">
                        €{delimit(amount * retailPrice, 2)}
                      </Box>
                    </Flex>
                  ),
              )}
            </Card>
          )}

          {deletions.length > 0 && (
            <Card
              animation="none"
              shadow="none"
              borderColor="redLighter"
              style={{ borderWidth: '0.5px' }}
              bgColor={rgba(themify('red'), 0.1)}
              mb={1}
            >
              <Color c="redDark">
                <Large>
                  <Icon icon={MinusCircle} fill="red" verticalAlign="sub" />{' '}
                  <Medium>Verwijderd van de opdracht</Medium>
                </Large>
              </Color>

              <Flex mx="-3px" style={{ fontWeight: 600 }}>
                <Box width={1 / 12} p="3px" textAlign="center">
                  Aantal
                </Box>
                <Box width={6 / 12} p="3px">
                  Product
                </Box>
                <Box width={2 / 12} p="3px" textAlign="right">
                  Verkoopprijs
                </Box>
                <Box width={3 / 12} p="3px" textAlign="right">
                  Subtotaal
                </Box>
              </Flex>

              {deletions.map(
                ({ amount, product, retailPrice }) =>
                  product && (
                    <Flex
                      key={`deletion-${product.id}`}
                      mx="-3px"
                      alignItems="flex-start"
                      style={{ borderTop: '0.5px solid', borderColor: themify('redLighter') }}
                    >
                      <Box width={1 / 12} p="3px" textAlign="center">
                        {amount}
                      </Box>
                      <Box width={6 / 12} p="3px">
                        {product.title}
                      </Box>
                      <Box width={2 / 12} p="3px" textAlign="right">
                        €{delimit(retailPrice, 2)}{' '}
                      </Box>
                      <Box width={3 / 12} p="3px" textAlign="right">
                        €{delimit(amount * retailPrice, 2)}
                      </Box>
                    </Flex>
                  ),
              )}
            </Card>
          )}

          {updates.length > 0 && (
            <Card
              animation="none"
              shadow="none"
              borderColor="orangeLighter"
              style={{ borderWidth: '0.5px' }}
              bgColor={rgba(themify('orange'), 0.1)}
              mb={1}
            >
              <Color c="orangeDark">
                <Large>
                  <Icon icon={NavigationCircle} fill="orange" verticalAlign="sub" />{' '}
                  <Medium>Aangepast</Medium>
                </Large>
              </Color>

              <Flex mx="-3px" style={{ fontWeight: 600 }}>
                <Box width={1 / 12} p="3px"></Box>
                <Box width={6 / 12} p="3px">
                  Product
                </Box>
                <Box width={5 / 12} p="3px">
                  Aanpassingen
                </Box>
              </Flex>

              {updates.map((updatedItem, index) => {
                const product = updatedItem.product;
                const ogItem = originalItems.find(item => item.product!.id === product!.id)!;
                const updatedFields = _.reduce(
                  updatedItem,
                  (prev, curr, key) =>
                    // Only include properties in the diff that we want to display (price, amount), and only if they've changed (not equal)
                    !(key in MUTABLE_ITEM_PROPS_NL) || _.isEqual(curr, (ogItem as any)[key])
                      ? prev
                      : prev.concat(key as ItemProp),
                  [] as ItemProp[],
                );
                return product ? (
                  <Flex
                    key={`update-${product.id}-${index}`}
                    mx="-3px"
                    alignItems="flex-start"
                    style={{ borderTop: '0.5px solid', borderColor: themify('orangeLighter') }}
                  >
                    <Box width={1 / 12} p="3px"></Box>
                    <Box width={6 / 12} p="3px">
                      {product.title}
                    </Box>
                    <Box width={5 / 12} p="3px">
                      {updatedFields.map((field, index) => (
                        <div key={`change-${field}-${index}`}>
                          {MUTABLE_ITEM_PROPS_NL[field]}:{' '}
                          {field !== 'amount'
                            ? `€${delimit(ogItem[field], 2)} → €${delimit(updatedItem[field], 2)}`
                            : `${ogItem[field]} → ${updatedItem[field]}`}
                        </div>
                      ))}
                    </Box>
                  </Flex>
                ) : (
                  <></>
                );
              })}
            </Card>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default JobMutationDiffs;
