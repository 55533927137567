import { gql } from '@apollo/client';

export const NeedsFragment = gql`
  fragment Needs on CustomerNeeds {
    budget
    anticipatedSlurpers
    extraComfort
    motivations
    floor {
      underfloorHeating
    }
    heating {
      multiHotWater
      newThermostat
      boilerBrandPreference
      extraWarmWaterCapacity
    }
    solar {
      pvPanelPreferences
      pvRoofPreferences
    }
    windows {
      soundproofing
      newFrames
    }
    solar {
      pvPanelPreferences
      pvRoofPreferences
    }
    desiredLabel
    expectedStay
    motivations
    cameFrom
  }
`;

export default NeedsFragment;
