import { useMutation } from '@apollo/client';
import { Box, Checkbox, Flex, Input, Switch } from '@energiebespaarders/symbols';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useToaster from '../../../hooks/useToaster';
import { UPDATE_INSTALLATION } from '../../../queries/installatron';
import { installationByHouseSolution_installationByHouseSolution_items as t_item } from '../../../types/generated/installationByHouseSolution';
import type {
  updateInstallation,
  updateInstallationVariables,
} from '../../../types/generated/updateInstallation';
import { InstallationItemInput, Solution } from '../../../types/graphql-global-types';
import PriceModificationTable from './PriceModificationTable';
import { ModifiableItem, recalculateInstallationPrices } from './recalculateInstallationPrices';

type InputType = 'totalRetailPrice' | 'totalMargin';
const DefaultMargin = 0.06; // By default, pre-fill with 6% margin

const calculateTotalMargin = (items: ModifiableItem[]) => {
  const totalRetailPrice = items.reduce((acc, item) => acc + item.newRetailPrice * item.amount, 0);
  const totalPurchasePrice = items.reduce(
    (acc, item) => acc + item.newPurchasePrice * item.amount,
    0,
  );
  return (totalRetailPrice - totalPurchasePrice) / totalRetailPrice;
};

const calculateTotalPurchasePrice = (items: ModifiableItem[]) => {
  return items.reduce((acc, item) => acc + item.newPurchasePrice * item.amount, 0);
};

type D2CQuoteConfiguratorProps = {
  isD2CSmokeScreen: boolean;
  setIsD2CSmokeScreen: (value: boolean) => void;
  installationId: string;
  solution: Solution;
  items: ReadonlyArray<t_item>;
  /** Is set to true when the quote is not D2C OR when it's D2C with updated prices */
  setIsValid: (value: boolean) => void;
  setSubmissionHandler: (handler: () => () => Promise<void>) => void;
  hasBeenConvertedToD2CSmokeScreen: boolean;
  setHasBeenConvertedToD2CSmokeScreen: (value: boolean) => void;
};

const D2CQuoteConfigurator: React.FC<D2CQuoteConfiguratorProps> = ({
  isD2CSmokeScreen,
  setIsD2CSmokeScreen,
  installationId,
  solution,
  items,
  setIsValid,
  setSubmissionHandler,
  hasBeenConvertedToD2CSmokeScreen,
  setHasBeenConvertedToD2CSmokeScreen,
}) => {
  const [inputType, setInputType] = useState<InputType>('totalMargin');
  const [targetMargin, setTargetMargin] = useState(DefaultMargin);
  const [targetPrice, setTargetPrice] = useState(0);

  const [modifiableItems, setModifiableItems] = useState<ModifiableItem[]>(
    items.map(item => ({
      ...item,
      newRetailPrice: item.retailPrice,
      newPurchasePrice: item.purchasePrice,
      newPriceType: 'retail',
      include: true,
    })),
  );

  const currentTotalPurchasePrice = useMemo(
    () => items.reduce((acc, item) => acc + item.purchasePrice, 0),
    [items],
  );

  const handleSwitchInputType = () => {
    setInputType(inputType === 'totalRetailPrice' ? 'totalMargin' : 'totalRetailPrice');
    if (inputType === 'totalRetailPrice') {
      setTargetMargin(_.round(calculateTotalMargin(modifiableItems), 6));
    } else {
      setTargetPrice(_.round(calculateTotalPurchasePrice(modifiableItems) / (1 - targetMargin), 2));
    }
  };

  const handleTargetPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTargetPrice = Number(e.target.value);
    setTargetPrice(newTargetPrice);
    const newItems = recalculateInstallationPrices(
      modifiableItems,
      newTargetPrice,
      'retail',
      false,
    );
    setTargetMargin(_.round(calculateTotalMargin(newItems), 6));
    setModifiableItems(newItems);
  };

  const handleTargetMarginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTargetMargin = Number(e.target.value) / 100;
    const totalPurchasePrice = calculateTotalPurchasePrice(modifiableItems);
    const newTargetPrice = totalPurchasePrice / (1 - newTargetMargin);
    setTargetMargin(newTargetMargin);
    setTargetPrice(_.round(newTargetPrice, 2));
    setModifiableItems(
      recalculateInstallationPrices(modifiableItems, newTargetPrice, 'retail', false),
    );
  };

  // Initialize with default margin
  useEffect(
    () =>
      handleTargetMarginChange({
        target: { value: (DefaultMargin * 100).toString() },
      } as React.ChangeEvent<HTMLInputElement>),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const toast = useToaster();
  const [updateInstallation] = useMutation<updateInstallation, updateInstallationVariables>(
    UPDATE_INSTALLATION,
    {
      onCompleted() {
        setIsValid(true);
      },
      onError(error) {
        console.error('Could not save D2CSmokeScreen quote prices', error);
        toast({ type: 'error', message: 'Er is iets misgegaan' });
      },
    },
  );

  const handleSubmit = useCallback(async () => {
    if (hasBeenConvertedToD2CSmokeScreen) return;

    const installationItems: InstallationItemInput[] = modifiableItems.map(i => ({
      retailPrice: Math.round(i.newRetailPrice * 100) / 100,
      purchasePrice: i.purchasePrice,
      productId: i.product.id,
      supplierId: i.supplier.id,
      amount: i.amount,
    }));
    await updateInstallation({
      variables: {
        id: installationId,
        installation: {
          id: installationId,
          solution: solution,
          items: installationItems,
        },
      },
    });
    setIsValid(false); // Returned to true on completion
    setHasBeenConvertedToD2CSmokeScreen(true);
  }, [
    hasBeenConvertedToD2CSmokeScreen,
    installationId,
    modifiableItems,
    setHasBeenConvertedToD2CSmokeScreen,
    setIsValid,
    solution,
    updateInstallation,
  ]);

  // Yes this is ugly. Tried other methods but they all failed
  // This sets the handleSubmit to be used on the parent Modal's submit button
  useEffect(() => setSubmissionHandler(() => handleSubmit), [handleSubmit, setSubmissionHandler]);

  // Validation
  useEffect(() => {
    // No negative prices and margin must remain > 0
    const margin = calculateTotalMargin(modifiableItems);
    const isInvalid = modifiableItems.some(i => i.newRetailPrice < 0) || margin < 0;
    setIsValid(!isInvalid);
  }, [modifiableItems, setIsValid]);

  return (
    <Box mt={2}>
      <p>Wil je deze offerte aanbieden als D2C offerte?</p>
      <Checkbox
        id="d2c-smokescreen-experiment"
        label="D2C offerte (experiment)"
        value={isD2CSmokeScreen}
        checked={isD2CSmokeScreen}
        onChange={() => setIsD2CSmokeScreen(!isD2CSmokeScreen)}
        disabled={hasBeenConvertedToD2CSmokeScreen}
      />

      {isD2CSmokeScreen && (
        <Flex flexWrap="wrap">
          <Box width={[1 / 2, 1 / 2, 1 / 4]} m={1}>
            <Input
              label="Totale marge"
              type="number"
              value={_.round(targetMargin * 100, 6)}
              onChange={handleTargetMarginChange}
              addonContent="%"
              addonSide="end"
              step="any"
              min={0}
              max={50}
              disabled={inputType !== 'totalMargin' || hasBeenConvertedToD2CSmokeScreen}
            />
          </Box>
          <Box width={[1 / 2, 1 / 2, 1 / 4]} m={1}>
            <Input
              label="Totale verkoopprijs"
              type="number"
              value={targetPrice}
              onChange={handleTargetPriceChange}
              addonContent="€"
              addonSide="start"
              step="any"
              // Retail price may be no less than the purchase price -> no negative margins
              min={currentTotalPurchasePrice}
              disabled={inputType !== 'totalRetailPrice' || hasBeenConvertedToD2CSmokeScreen}
            />
          </Box>

          <Box width={[1 / 2, 1 / 2, 1 / 4]} alignSelf="flex-end" mb={4}>
            <Switch
              isOn={inputType === 'totalRetailPrice'}
              toggleSwitch={handleSwitchInputType}
              offLabel="Marge"
              onLabel="Verkoopprijs"
              bgColorOff="blue"
              bgColorOn="purple"
            />
          </Box>

          <PriceModificationTable
            targetPriceType="retail"
            // Only lowering retail prices, not purchase
            isApplyingToBoth={false}
            // Only applicable when lowering purchase prices; not the case here
            hasMinimumRate={false}
            targetPrice={targetPrice}
            modifiableItems={modifiableItems}
            setModifiableItems={setModifiableItems}
          />
        </Flex>
      )}
    </Box>
  );
};

export default D2CQuoteConfigurator;
