import { isUndefined } from '@energiebespaarders/constants';
import dayjs from 'dayjs';
import { getValueFromKey } from '../../lib/utils';
import {
  intakeOverview_house,
  intakeOverview_house_intake,
} from '../../types/generated/intakeOverview';
import { IntakeMethod } from '../../types/graphql-global-types';

export const getIntakeDataFields = (intake: intakeOverview_house_intake) => [
  {
    label: 'Startdatum',
    value: intake.isStarted ? dayjs(intake.startedOn).format('dddd D MMMM YYYY') : '-',
    color: intake.isStarted ? 'green' : 'red',
  },
  {
    label: 'Opnamespecialist',
    value: intake.operator ? `${intake.operator.firstName} ${intake.operator.lastName}` : '-',
    color: intake.operator ? 'green' : 'red',
  },
  {
    label: 'Methode',
    value: !intake.method ? '-' : intake.method === IntakeMethod.physical ? 'Fysiek' : 'Op afstand',
    color: intake.method ? 'green' : 'red',
  },
];

export function calculateProgress(questions: any[], house: intakeOverview_house) {
  let progress = 0;
  const relevantQuestions = questions.filter(
    q => q.key && q.question && !q.irrelevant && !q.excludeFromProgress,
  );
  const { files } = house;
  let filteredImages = files;
  relevantQuestions.map(q => {
    // For value-based questions
    if (q.query) {
      const value =
        q.key.indexOf('customer.') > -1
          ? getValueFromKey(q.key.replace('house', 'customer.needs'), house)
          : q.key.indexOf('situation.') > -1
          ? getValueFromKey(q.key.replace('house', 'situation'), house)
          : getValueFromKey(q.key.replace('house', 'intake'), house);
      if (!isUndefined(value, true)) {
        return (progress += 1);
      }
    } else {
      // For photo-based questions
      const tags = q.key === 'photo.profile' ? ['profile'] : ['intake', q.key, ...q.topics];
      if (files && tags.length > 0) {
        filteredImages = files.filter(file =>
          tags.every(tag => file.metadata.tags.indexOf(tag) > -1),
        );
      }
      if (filteredImages.length > 0) {
        return (progress += 1);
      }
    }
    return null;
  });

  return relevantQuestions.length === 0 ? -1 : progress / relevantQuestions.length;
}

export const submitOnEnter = (e: React.KeyboardEvent<HTMLElement>) =>
  e.keyCode === 13 && (e.target as HTMLInputElement)?.blur?.();
