import { gql, useQuery } from '@apollo/client';
import { Placeholder } from '@energiebespaarders/symbols';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import React, { ReactNode, useState } from 'react';
import { FileFragment } from '../../fragments';
import { ProductHistoryFragment } from '../../fragments/Product';
import productSpecs from '../../lib/dataMaps/productData';
import {
  productRevisions,
  productRevisionsVariables,
  productRevisions_productById_revisions_diff,
} from '../../types/generated/productRevisions';
import Changelog from '../Changelog';

const PRODUCT_REVISIONS = gql`
  ${FileFragment}
  ${ProductHistoryFragment}

  query productRevisions($productId: ID!, $offset: Int, $limit: Int) {
    productById(id: $productId) {
      id
      revisions(offset: $offset, limit: $limit) {
        id
        modifiedBy {
          ... on AuthenticatedUser {
            id
            firstName
            lastName
          }
        }
        timestamp
        revision
        diff {
          ...ProductHistory
        }
      }
    }
  }
`;

const specs = productSpecs();
const ProductFieldLabels = (specs.reduce((dict, spec) => ({
  ...dict,
  [spec.key]: spec.label,
})) as unknown) as Record<keyof productRevisions_productById_revisions_diff, string>;
ProductFieldLabels.files = 'Bestanden';
ProductFieldLabels.archived = 'Gearchiveerd';

const ProductFieldFormatter: Partial<
  Record<
    keyof productRevisions_productById_revisions_diff,
    (value: productRevisions_productById_revisions_diff) => ReactNode
  >
> = {
  __typename: () => '?',
  id: () => '?',
  files: ({ files }) => files?.map(f => f.metadata.title || <i>Verwijderd bestand</i>).join(', '),
  archived: ({ archived }) => dayjs(archived).format('DD/MM/YYYY HH:mm'),
};

interface ProductChangelogProps {
  productId: string;
}

const defaultLimit = 10;

const ProductChangelog: React.FC<ProductChangelogProps> = ({ productId }) => {
  const { data, loading, error, refetch } = useQuery<productRevisions, productRevisionsVariables>(
    PRODUCT_REVISIONS,
    {
      variables: { productId, limit: defaultLimit },
      fetchPolicy: 'cache-and-network',
    },
  );

  // Temporary pagination alternative, a single "load more" button
  const [hasLoadedMore, setHasLoadedMore] = useState(false);

  if (error) return <Placeholder error={error} />;

  const revisions = data?.productById?.revisions || [];

  return (
    <Changelog
      fetchMore={
        revisions.length === defaultLimit && !hasLoadedMore
          ? () => {
              setHasLoadedMore(true);
              refetch({ productId, limit: 50 });
            }
          : undefined
      }
      loading={loading}
      error={error}
      changes={
        revisions.flatMap(({ modifiedBy, timestamp, diff, revision }, index) => {
          const user =
            modifiedBy && 'firstName' in modifiedBy
              ? {
                  name: `${modifiedBy.firstName} ${modifiedBy.lastName}`,
                  link: `/users/operators/${modifiedBy.id}`,
                }
              : { name: 'Onbekend' };

          // TODO: this is mostly duplicate of InstallerChangelog.tsx, it has the same TODOs as in there
          const changedKeys = Object.keys(diff)
            .filter(k => !['id', '__typename'].includes(k) && diff[k as keyof typeof diff])
            .filter(k => {
              // "hacky" fix for Product diff checking:
              // I didn't add a custom ProductDiff type to the schema because every Product category would have needed its own variant of it...
              // So we're using the normal Product type, which has some non-nullable fields. Those are filtered out here so they don't appear in the diff
              // These are array fields (advantages, disadvantages, files)
              const val = diff[k as keyof typeof diff];
              if (!Array.isArray(val)) return true;
              const prevVal = revisions[index + 1]?.diff[k as keyof typeof diff];
              return val?.length > 0 && !isEqual(val, prevVal);
            });

          const isMultiChange = changedKeys.length > 1;

          return changedKeys.map((key, index) => {
            const typedKey = key as keyof typeof diff;
            return {
              revision: isMultiChange
                ? `${revision}-${String.fromCharCode(index + 'A'.charCodeAt(0))}`
                : revision.toString(),
              modifiedBy: user,
              timestamp,
              field: ProductFieldLabels[typedKey] || key,
              change: ProductFieldFormatter[typedKey]?.(diff) || JSON.stringify(diff[typedKey]),
            };
          });
        }) || []
      }
    />
  );
};

export default ProductChangelog;
