import React from 'react';
import { Button } from '@energiebespaarders/symbols';
import { humulusUrl, isProduction, isStaging } from '../../../config';
import { BagEuro, Toolbox } from '@energiebespaarders/symbols/icons/solid';
import { JOB_TYPES_NL } from '@energiebespaarders/constants';
import { Heading } from '@energiebespaarders/symbols/helpers';
import { jobDomainQuote_quoteById_jobs } from '../../../types/generated/jobDomainQuote';

interface JobFilesProps {
  job: jobDomainQuote_quoteById_jobs;
}

const JobFiles: React.FC<JobFilesProps> = ({ job }) => {
  return (
    <>
      <Heading>{JOB_TYPES_NL[job.type]}opdracht bestanden:</Heading>
      <Button
        bgColor="purple"
        fontSize={6}
        fluid
        inverse
        iconStart={Toolbox}
        minIconSize="1rem"
        label={`${JOB_TYPES_NL[job.type]}opdracht PDF`}
        href={
          isProduction || isStaging
            ? `${humulusUrl}/job/${job.id}`
            : `${humulusUrl}/job?jobId=${job.id}`
        }
      />
      <Button
        fontSize={6}
        fluid
        inverse
        iconStart={BagEuro}
        minIconSize="1rem"
        label={`${JOB_TYPES_NL[job.type]}opdrachtprijs PDF`}
        href={
          isProduction || isStaging
            ? `${humulusUrl}/job-pricing/${job.id}`
            : `${humulusUrl}/job-pricing?jobId=${job.id}`
        }
      />
    </>
  );
};

export default JobFiles;
