import { Solution } from '@energiebespaarders/constants';
import { Input, Select } from '@energiebespaarders/symbols';
import { CombinedSelectProps, DropdownOption } from '@energiebespaarders/symbols/components/Select';
import React, { useMemo } from 'react';
import { isdeDataDate, isdeProductOptions } from './isdeData';

type ISDECodeDropdownProps = {
  value: string;
  onChange: (value: string) => void;
  solution?: Solution;
  creatable?: boolean;
};

const formatCreateOption = (value: string) => `"${value}" invullen (geen officiële ISDE code)`;

const ISDECodeDropdown: React.FC<
  ISDECodeDropdownProps & Omit<CombinedSelectProps<string>, 'options'>
> = ({ solution, creatable, value, onChange, clearable = true, ...props }) => {
  const options = useMemo(() => {
    const opts = isdeProductOptions(solution);

    // For cases where no ISDE code options are available, leave no options so a normal Input element gets rendered
    if (opts.length === 0) return [];

    // Add an option for custom values that aren't in the official ISDE code list
    if (value && !opts.find(o => o.value === value)) {
      opts.unshift({ value, label: value, searchValue: value });
    }
    return opts;
  }, [solution, value]);

  // For some solutions, we don't show ISDE codes yet. Just show a normal input then
  if (options.length === 0) {
    return (
      <Input {...props} label="ISDE code" value={value} onChange={e => onChange(e.target.value)} />
    );
  }

  return (
    <Select
      // showing date indicator with in case people wonder if it's outdated
      {...props}
      label={`ISDE code (${isdeDataDate})`}
      options={options}
      value={options.find(o => o?.value === value)}
      onChange={o => onChange(o?.value)}
      clearable={clearable}
      filterOption={(
        option: DropdownOption & { data: { searchValue: string } },
        searchString: string,
      ) => {
        // The CreateOption has no search value, so always show those
        if (!option?.data?.searchValue) {
          return true;
        }
        return option.data.searchValue.match(new RegExp(searchString, 'gi'));
      }}
      creatable
      onCreateOption={onChange}
      formatCreateLabel={formatCreateOption}
    />
  );
};

export default ISDECodeDropdown;
