import React, { ReactNode, useState, ChangeEvent } from 'react';
import { Icon, Input, Flex, Box, Button } from '@energiebespaarders/symbols';
import { Small } from '@energiebespaarders/symbols/helpers';
import { Edit, Check } from '@energiebespaarders/symbols/icons/solid';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

type Props = {
  loading: boolean;
  icon: ReactNode;
  label: string;
  note?: string;
  prenote?: string;
  field: string | number;
  type?: 'text' | 'email' | 'number';
  link?: string;
  update?: (v: string) => any;
  inputWidth?: number[] | number;
};

type ClickableProps = {
  disabled: boolean;
};
const Clickable = styled.div<ClickableProps>`
  cursor: pointer;
  ${x =>
    x.disabled &&
    css<ClickableProps>`
      cursor: not-allowed;
      color: ${x => x.theme.colors.gray};
    `}
`;

type ContainerProps = {
  disabled: boolean;
};
const Container = styled(Box)<ContainerProps>`
  ${x =>
    x.disabled &&
    css<ContainerProps>`
      cursor: not-allowed;
      color: ${x => x.theme.colors.gray};
    `}
`;

const DataFieldWithUpdate: React.FC<Props> = ({
  icon,
  loading,
  field,
  label,
  note,
  prenote,
  type,
  link,
  update,
  inputWidth,
}: Props) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [fieldUpdate, setFieldUpdate] = useState<string>('');
  const [isHovering, setIsHovering] = useState(false);

  if (!update)
    return (
      <>
        <Icon
          icon={isHovering ? Edit : icon}
          solid
          mr={1}
          fill={isHovering ? 'blue' : 'grayBlack'}
        />{' '}
        {link ? <Link to={link}>{field}</Link> : field}
        {note && <Small> ({note})</Small>}
      </>
    );

  return (
    <Container disabled={loading} width={1}>
      {isUpdating ? (
        <form
          onSubmit={() => {
            if (loading) return;
            update(fieldUpdate);
            setIsUpdating(false);
          }}
        >
          <Flex alignItems="center">
            <Box width={inputWidth || 1}>
              <Input
                label={label}
                type={type || 'text'}
                value={fieldUpdate}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldUpdate(e.target.value)}
                m={0}
                autoFocus
              />
            </Box>
            <Box>
              <Button minimal bgColor="green" type="submit" mx={1} mb={0}>
                <Icon solid fill="green" icon={Check} />
              </Button>
            </Box>
          </Flex>
        </form>
      ) : (
        <Clickable
          disabled={loading}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={() => {
            if (loading) return;
            setFieldUpdate(field.toString());
            setIsUpdating(true);
          }}
        >
          <Icon
            icon={isHovering ? Edit : icon}
            solid
            mr={1}
            fill={isHovering ? 'blue' : 'grayBlack'}
          />{' '}
          {prenote && `${prenote} `}
          {link ? <Link to={link}>{field}</Link> : field}
          {note && <Small> ({note})</Small>}
        </Clickable>
      )}
    </Container>
  );
};

export default DataFieldWithUpdate;
