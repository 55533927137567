import { ApolloCache, DefaultContext, MutationTuple } from '@apollo/client';
import { BaseButtonProps } from '@energiebespaarders/symbols/components/Button';
import { FloppyDisk, Play, Star } from '@energiebespaarders/symbols/icons/solid';
import { AnimatedProps } from '@energiebespaarders/symbols/lib/hoc/withAnimation';
import { SpacingProps } from '@energiebespaarders/symbols/lib/hoc/withSpacing';
import { availableQuoteTemplatesBySolution_quoteTemplates } from '../../../types/generated/availableQuoteTemplatesBySolution';
import {
  createQuoteTemplate,
  createQuoteTemplateVariables,
} from '../../../types/generated/createQuoteTemplate';
import { installersAndSuppliersBySolution_installersBySolution } from '../../../types/generated/installersAndSuppliersBySolution';
import {
  updateQuoteTemplate,
  updateQuoteTemplateVariables,
} from '../../../types/generated/updateQuoteTemplate';
import { QuoteTemplateInput } from '../../../types/graphql-global-types';
import { TemplateItem } from './TemplatePicker';
import { Solution } from '@energiebespaarders/constants';

type ButtonProps = BaseButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  SpacingProps &
  Partial<AnimatedProps>;

export function createTemplateInput({
  title,
  solution,
  items,
  supplierId,
  isActive,
}: {
  title: string;
  solution: Solution;
  items: readonly { amount: number; supplierId: string; product: { id: string } }[];
  supplierId: string;
  isActive?: boolean;
}): QuoteTemplateInput {
  return {
    title,
    supplierId,
    isActive: isActive ?? true,
    solution: solution,
    items: items.map(item => ({
      productId: item.product.id,
      amount: item.amount || 1,
      supplierId: item.supplierId,
    })),
  };
}

/** Sets up the buttons shown at the bottom of the QuoteTemplate modal */
export const quoteTemplateActionBar = (
  hasManagementPermission: boolean,
  activeTemplate: availableQuoteTemplatesBySolution_quoteTemplates | undefined,
  items: TemplateItem[],
  selectedSupplierId: string | undefined,
  solution: Solution,
  meId: string,
  installers: readonly installersAndSuppliersBySolution_installersBySolution[],
  [createTemplate, creationResult]: MutationTuple<
    createQuoteTemplate,
    createQuoteTemplateVariables,
    DefaultContext,
    ApolloCache<any>
  >,
  [updateTemplate, updateResult]: MutationTuple<
    updateQuoteTemplate,
    updateQuoteTemplateVariables,
    DefaultContext,
    ApolloCache<any>
  >,
  handleCreateFromTemplate: (items: TemplateItem[]) => Promise<void>,
): ButtonProps[] => {
  const actionBar: ButtonProps[] = [
    {
      label: 'Gebruiken',
      disabled: !activeTemplate,
      iconStart: Play,
      onClick: async () => {
        if (!items?.length) return;
        handleCreateFromTemplate(items);
      },
    },
  ];
  if (!hasManagementPermission) {
    return actionBar;
  }

  if (activeTemplate) {
    // When a template is selected, show a "Save" and "Save as" action
    actionBar.unshift(
      ...[
        {
          label: 'Opslaan als nieuwe template',
          disabled: !items?.length,
          iconStart: Star,
          inverse: true,
          bgColor: 'blue',
          loading: creationResult.loading,
          error: creationResult.error?.message,
          onClick: () => {
            if (!items?.length) return;

            const title = prompt(
              'Hoe wil je deze template noemen?',
              `${activeTemplate?.title} (kopie)`,
            );
            if (!title) return;

            createTemplate({
              variables: {
                operatorId: meId,
                template: {
                  title,
                  solution,
                  isActive: true,
                  supplierId: selectedSupplierId!,
                  items: items.map(item => ({
                    productId: item.product.id,
                    amount: item.amount,
                    supplierId: item.supplierId,
                  })),
                },
              },
            });
          },
        },
        {
          label: 'Huidige template opslaan',
          disabled: !items?.length,
          bgColor: 'blue',
          iconStart: FloppyDisk,
          loading: updateResult.loading,
          error: updateResult.error?.message,
          onClick: async () => {
            if (!items?.length || !activeTemplate) return;
            updateTemplate({
              variables: {
                operatorId: meId,
                templateId: activeTemplate.id,
                template: createTemplateInput({
                  title: activeTemplate.title,
                  solution,
                  items,
                  supplierId: activeTemplate.supplier.id,
                  isActive: activeTemplate.isActive,
                }),
              },
            });
          },
        },
      ],
    );
  } else {
    // When no template is selected, prompt the current Installation to be saved as a template
    actionBar.unshift({
      label: 'Opslaan als template',
      disabled: !items?.length,
      iconStart: FloppyDisk,
      bgColor: 'blue',
      loading: creationResult.loading,
      error: creationResult.error?.message,
      onClick: () => {
        if (!items?.length) return;

        const title = `${items[0].product.title} bij ${
          installers.find(inst => inst.pairedSupplierId === selectedSupplierId)?.name || '?'
        }`;

        createTemplate({
          variables: {
            operatorId: meId,
            template: {
              title,
              solution,
              isActive: true,
              supplierId: selectedSupplierId!,
              items: items.map(item => ({
                productId: item.product.id,
                amount: item.amount,
                supplierId: item.supplierId,
              })),
            },
          },
        });
      },
    });
  }

  return actionBar;
};
