import { Box, Card, Flex, Image } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import React from 'react';
import { fileUrl } from '../../config';
import { getBrands_brands } from '../../types/generated/getBrands';

interface Props {
  brand: getBrands_brands;
  showUpdateBrandModal: () => void;
}

const BrandCard: React.FC<Props> = ({ brand, showUpdateBrandModal }) => {
  const { name, logo } = brand;
  const logoSrc = brand.logo?.id ? `${fileUrl}/${logo?.id}.${logo?.extension}` : '';

  return (
    <Box
      width={[1 / 2, 1 / 3, 1 / 4, 1 / 5]}
      px={1}
      onClick={showUpdateBrandModal}
      style={{ cursor: 'pointer' }}
    >
      <Card mb={2}>
        <Flex flexWrap="wrap" mx={-1} alignItems="center">
          <Box width={1} px={1} textAlign="center">
            <Image src={logoSrc} alt={name} height="50px" style={{ objectFit: 'contain' }} />
          </Box>
          <Box
            width={1}
            px={1}
            textAlign="center"
            style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            <Medium>{name}</Medium>
          </Box>
        </Flex>
      </Card>
    </Box>
  );
};

export default BrandCard;
