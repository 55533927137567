import _dayjs from 'dayjs';
import 'dayjs/locale/nl';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';

_dayjs.locale('nl');

_dayjs.extend(utc);

_dayjs.extend(updateLocale);
_dayjs.extend(calendar);
_dayjs.extend(duration);
_dayjs.extend(isoWeeksInYear);
_dayjs.extend(weekOfYear);
_dayjs.extend(isLeapYear);
_dayjs.updateLocale('nl', {
  calendar: {
    lastDay: '[gisteren om] H:mm',
    sameDay: '[vandaag om] H:mm',
    nextDay: '[morgen om] H:mm',
    lastWeek: '[vorige] dddd [om] H:mm',
    nextWeek: 'dddd [om] H:mm',
    sameElse: 'DD-MM-YYYY',
  },
});

export const relativeDayjs = _dayjs.extend(relativeTime);

const extendedDayJs = _dayjs;
export default extendedDayJs;
