import { useMutation } from '@apollo/client';
import { Box, Button, Flex, Input } from '@energiebespaarders/symbols';
import { Red } from '@energiebespaarders/symbols/helpers';
import { Calendar } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import useToaster from '../../../hooks/useToaster';
import { COMPLETE_JOB } from '../../../queries/job';
import { completeJob, completeJobVariables } from '../../../types/generated/completeJob';
import InvoiceExpectationsEmailCheckbox, {
  useInvoiceExpectationsEmailData,
} from './InvoiceExpectationsEmailCheckbox';

interface CompleteJobFormProps {
  jobId: string;
  quoteId: string;
  onClose?: () => void;
}

const CompleteJobForm: React.FC<CompleteJobFormProps> = ({ jobId, quoteId, onClose }) => {
  const [date, setDate] = useState('');
  const toast = useToaster();

  const [isSendEmailEnabled, setSendEmail] = useState(true);

  const { sendInvoiceEmailMutation, invoiceEmailType } = useInvoiceExpectationsEmailData(quoteId);

  const [completeJob, { loading }] = useMutation<completeJob, completeJobVariables>(COMPLETE_JOB, {
    onCompleted: () => {
      toast({
        type: 'success',
        message: 'Installatiedatum ingevuld',
      });
      onClose?.();
    },
    onError: e => {
      console.error(e);
      toast({
        type: 'error',
        message: e.message,
      });
    },
  });

  const handleSubmit = () => {
    completeJob({
      variables: {
        id: jobId,
        completionDate: new Date(date),
      },
    });

    if (isSendEmailEnabled) {
      sendInvoiceEmailMutation({ variables: { jobId, jobFollowUpType: invoiceEmailType } });
    }
  };

  return (
    <>
      <p>
        Als de klant onbereikbaar is, maar de installatie is wel uitgevoerd, kun je hier zonder
        beoordeling een installatiedatum invullen voor ons systeem.
      </p>

      <Input
        type="date"
        addonSide="start"
        icon={Calendar}
        label="Installatiedatum"
        value={date}
        onChange={e => setDate(e.target.value)}
        max={dayjs().format('YYYY-MM-DD')}
      />
      {new Date(date) > new Date() && (
        <Red>
          Let op: De installatie moet al uitgevoerd zijn, dus deze datum moet in het verleden zijn.
        </Red>
      )}

      <Flex flexWrap="wrap" mb={2}>
        <Box width={1}>
          <InvoiceExpectationsEmailCheckbox
            invoiceEmailType={invoiceEmailType}
            handleChange={e => setSendEmail(!!e.sendInvoiceEmail)}
            value={isSendEmailEnabled}
          />
        </Box>
      </Flex>

      <Flex mt={1} mx={-1}>
        {onClose && (
          <Box width="50%" px={1}>
            <Button bgColor="red" label="Sluiten" fluid onClick={onClose} />
          </Box>
        )}
        <Box width="100%" px={1}>
          <Button
            bgColor="green"
            label="Opdracht voltooien"
            disabled={loading}
            fluid
            onClick={handleSubmit}
          />
        </Box>
      </Flex>
    </>
  );
};

export default CompleteJobForm;
