import { gql, useMutation, useQuery } from '@apollo/client';
import { SOLUTIONS, SOLUTIONS_NL } from '@energiebespaarders/constants';
import { useForm } from '@energiebespaarders/hooks';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Select,
  Separator,
} from '@energiebespaarders/symbols';
import { Gray, Small } from '@energiebespaarders/symbols/helpers';
import {
  At,
  BadgeId,
  Bank,
  Calendar,
  CreditCard,
  Hardhat,
  PercentageChat,
  Phone,
  StocksUp,
  User,
  World,
} from '@energiebespaarders/symbols/icons/solid';
import React from 'react';
import { GET_COMPANIES } from '../../domains/Companies';
import useToaster from '../../hooks/useToaster';
import { GET_OPERATORS } from '../../queries/operatorDirectory';
import { getOperators, getOperatorsVariables } from '../../types/generated/getOperators';
import { OperatorTeam } from '../operatorDirectory/OperatorProfile';

export const COMPANY_SIZES = [
  { label: '1-5', value: 1 },
  { label: '5-15', value: 5 },
  { label: '15-50', value: 15 },
  { label: '50-250', value: 50 },
  { label: '250+', value: 250 },
];

const ADD_INSTALLER = gql`
  mutation addInstallerWithPairedSupplier($installer: InstallerInput!) {
    addInstallerWithPairedSupplier(installer: $installer) {
      id
    }
  }
`;

type Props = {
  onSubmitCompleted: () => void;
};

type Installer = {
  name: string;
  workRadius: number | undefined;
  address: {
    street: string;
    number: number | undefined;
    suffix: string;
    zip: string;
    city: string;
  };
  solutions: string[];
  coc: string;
  iban: string;
  vat: string;
  website: string;
  email: string;
  phone: string;
  inspectionEmail: string;
  installationEmail: string;
  contactPerson: string;
  installationRegions: { from: number; to: number }[];
  planningPeriod: number;
  customerNotificationPeriod: number | undefined;
  size: number | undefined;
  legalName?: string;
  rating: number;
  accountManagerId: string;
};

const installer: Installer = {
  name: '',
  workRadius: 0,
  address: {
    street: '',
    number: undefined,
    suffix: '',
    zip: '',
    city: '',
  },
  solutions: [],
  coc: '',
  iban: '',
  vat: '',
  website: '',
  email: '',
  phone: '',
  inspectionEmail: '',
  installationEmail: '',
  contactPerson: '',
  installationRegions: [],
  planningPeriod: 5,
  customerNotificationPeriod: undefined,
  size: 1,
  legalName: '',
  rating: 0,
  accountManagerId: '',
};

const AddInstallerForm: React.FC<Props> = ({ onSubmitCompleted }) => {
  const toast = useToaster();

  const [addInstallerWithPairedSupplier, { loading }] = useMutation(ADD_INSTALLER, {
    onCompleted: () => {
      toast({
        type: 'success',
        message: 'Installatiepartner toegevoegd',
      });
      onSubmitCompleted();
    },
    refetchQueries: [{ query: GET_COMPANIES }],
    onError: e => {
      toast({
        type: 'error',
        message: e.message,
      });
    },
  });

  const { formState, submitForm, handleChange } = useForm<Installer>({
    initialValues: installer,
    validate: (values, errors) => {
      // TODO: validation doesn't seem to trigger?
      if (!values.solutions || values.solutions.length === 0) {
        errors.solutions = 'Kies ten minste één oplossing';
      }
      return errors;
    },
    handleSubmit: values => {
      addInstallerWithPairedSupplier({
        variables: {
          installer: {
            ...values,
            coc: parseInt(values.coc!, 10),
            address: { ...values.address, number: Number(values.address.number?.toFixed(0)) },
          },
        },
      });
    },
  });

  const handleChangeSolution = (e: string) => {
    const updatedSolutions = formState.solutions.value.includes(e)
      ? formState.solutions.value.filter(s => s !== e)
      : [...formState.solutions.value, e];

    handleChange({ solutions: updatedSolutions });
  };

  const { data: operatorsData } = useQuery<getOperators, getOperatorsVariables>(GET_OPERATORS, {
    variables: { includeInactive: false },
  });
  const operatorOptions = (operatorsData?.operators || [])
    .filter(o => o.team === OperatorTeam.Planning || o.team === OperatorTeam.MT)
    .map(o => {
      return {
        label: `${o.firstName} ${o.lastName}`,
        value: o.id,
      };
    });

  return (
    <form onSubmit={submitForm}>
      <Flex flexWrap="wrap" mx={-1} mt={2}>
        <Box width={1}>
          <Separator text="Algemene informatie" my={2} />
        </Box>

        <Box width={1} px={1}>
          <Input
            {...formState.name}
            type="text"
            name="name"
            onChange={e => handleChange({ name: e.target.value })}
            addonSide="start"
            icon={Hardhat}
            label="Bedrijfsnaam"
            required
          />
          <Input
            {...formState.legalName}
            type="text"
            name="legalName"
            value={formState.legalName?.value || ''}
            onChange={e => handleChange({ legalName: e.target.value })}
            addonSide="start"
            icon={BadgeId}
            label="Wettelijke naam"
          />
        </Box>
        <Box width={3 / 5} px={1}>
          <Input
            {...formState.address.street}
            required
            type="text"
            name="street"
            label="Straat"
            onChange={e =>
              handleChange({
                address: {
                  street: e.target.value,
                },
              })
            }
          />
        </Box>
        <Box width={1 / 5} px={1}>
          <Input
            {...formState.address.number}
            required
            type="number"
            name="number"
            label="Huisnummer"
            onChange={(e: any) => {
              handleChange({
                address: {
                  number: Number(e.target.value) || 0,
                },
              });
            }}
            maxLength={5}
          />
        </Box>
        <Box width={1 / 5} px={1}>
          <Input
            {...formState.address.suffix}
            type="text"
            name="suffix"
            onChange={(e: any) => {
              handleChange({
                address: {
                  suffix: e.target.value,
                },
              });
            }}
            label="Toevoeging"
            placeholder="a"
          />
        </Box>
        <Box width={1 / 4} px={1}>
          <Input
            {...formState.address.zip}
            required
            type="text"
            name="zip"
            onChange={(e: any) => {
              handleChange({
                address: {
                  zip: e.target.value,
                },
              });
            }}
            label="Postcode"
            placeholder="1234AB"
            maxLength={6}
          />
        </Box>
        <Box width={2 / 4} px={1}>
          <Input
            {...formState.address.city}
            required
            type="text"
            name="city"
            label="Stad"
            placeholder=""
            onChange={(e: any) => {
              handleChange({
                address: {
                  city: e.target.value,
                },
              });
            }}
          />
        </Box>
        <Box width={1 / 4} px={1}>
          <Input
            {...formState.workRadius}
            type="number"
            name="workRadius"
            label="Straal werkgebied"
            placeholder="0"
            addonContent="km"
            addonSide="end"
            maxLength={3}
            step="0.5"
            onChange={(e: any) => {
              handleChange({ workRadius: Number(e.target.value) || 0 });
            }}
          />
        </Box>
        <Box width={1} px={1}>
          <RadioGroup
            {...formState.size}
            id="size"
            name="size"
            label="Aantal medewerkers"
            onChange={e => handleChange({ size: e })}
            divider={5}
          >
            {COMPANY_SIZES.map((size, index) => {
              return (
                <Radio
                  key={`size-${index}`}
                  checked={formState.size.value === size.value}
                  id={size.label}
                  label={size.label}
                  name="size"
                  value={size.value}
                  width="100%"
                />
              );
            })}
          </RadioGroup>
        </Box>

        <Box width={1}>
          <Separator text="Contact" my={2} />
        </Box>

        <Box width={1 / 2} px={1}>
          <Input
            {...formState.phone}
            onChange={e => handleChange({ phone: e.target.value })}
            addonSide="start"
            name="phone"
            icon={Phone}
            label="Telefoonnummer"
            type="tel"
          />
        </Box>
        <Box width={1 / 2} px={1}>
          <Input
            {...formState.email}
            required
            onChange={e => handleChange({ email: e.target.value })}
            addonSide="start"
            name="email"
            icon={At}
            label="E-mailadres"
            type="email"
          />
        </Box>
        <Box width={1 / 2} px={1}>
          <Input
            {...formState.installationEmail}
            onChange={e => handleChange({ installationEmail: e.target.value })}
            addonSide="start"
            name="installationEmail"
            icon={At}
            label="E-mail: Installaties"
            type="email"
          />
        </Box>
        <Box width={1 / 2} px={1}>
          <Input
            {...formState.inspectionEmail}
            onChange={e => handleChange({ inspectionEmail: e.target.value })}
            addonSide="start"
            name="inspectionEmail"
            icon={At}
            label="E-mail: Schouwen"
            type="email"
          />
        </Box>
        <Box width={1 / 2} px={1}>
          <Input
            {...formState.contactPerson}
            onChange={e => handleChange({ contactPerson: e.target.value })}
            addonSide="start"
            name="contactPerson"
            icon={User}
            label="Contactpersoon (alleen voornaam)"
            type="string"
          />
        </Box>

        <Box width={1}>
          <Separator text="Planning" my={2} />
        </Box>

        <Box width={1} p={1}>
          <Small>
            Deze informatie is nu niet verplicht, maar zonder deze informatie zul je geen opdrachten
            kunnen maken voor deze installateur.
          </Small>
        </Box>

        <Box width={1} p={1}>
          <Small>
            Je kunt deze informatie aanpassen zodra het bekend is via de installateurspagina, via de
            zoekbalk te bereiken.
          </Small>
        </Box>
        <Box width={1 / 2} px={1}>
          <Input
            {...formState.planningPeriod}
            name="planningPeriod"
            type="number"
            addonSide="start"
            icon={Calendar}
            label="Planning period (WERKDAGEN)"
            onChange={(e: any) => {
              handleChange({ planningPeriod: Number(e.target.value) || 0 });
            }}
          />
        </Box>
        <Box width={1 / 2} px={1}>
          <Input
            {...formState.customerNotificationPeriod}
            name="customerNotificationPeriod"
            type="number"
            addonSide="start"
            icon={Calendar}
            label="Klant notificatie period (WEKEN)"
            onChange={(e: any) => {
              handleChange({ customerNotificationPeriod: Number(e.target.value) || 0 });
            }}
          />
        </Box>
        <Box width={1} px={1}>
          <Small>
            Om{' '}
            <a
              href="https://docs.google.com/spreadsheets/d/1OwxNbfZ520Iu4I1iTs6OcRn63AEZvyGSM1G9hRR-GR4"
              rel="noopener noreferrer"
              target="_blank"
            >
              deze installatieregio's
            </a>{' '}
            toe te voegen moet je eerst een nieuwe installateur aanmaken en deze via de zoekbalk
            bekijken en bewerken.
          </Small>
        </Box>

        <Box width={1}>
          <Separator text="Oplossingen" my={2} />
        </Box>

        <Box width={1} px={1}>
          <RadioGroup
            {...formState.solutions}
            id="solutions"
            label="Oplossingen die het bedrijf installeert"
            name="solutions"
            onChange={(e: string) => handleChangeSolution(e)}
          >
            {SOLUTIONS.map(solution1 => (
              <Checkbox
                checked={formState.solutions!.value.indexOf(solution1) > -1}
                id={solution1}
                key={`${solution1}Checkbox`}
                label={SOLUTIONS_NL[solution1]}
                name="solutions"
                value={solution1}
              />
            ))}
          </RadioGroup>
        </Box>

        <Box width={1}>
          <Separator text="Overig" my={2} />
        </Box>

        <Box width={1 / 2} px={1}>
          <Input
            {...formState.website}
            onChange={e => handleChange({ website: e.target.value })}
            addonSide="start"
            icon={World}
            type="url"
            label="Website"
            name="website"
          />
        </Box>
        <Box width={1 / 2} px={1}>
          <Input
            {...formState.coc}
            type="number"
            name="coc"
            icon={Bank}
            addonSide="start"
            label="KvK-nummer"
            onChange={(e: any) => {
              handleChange({ coc: Number(e.target.value) || 0 });
            }}
          />
        </Box>
        <Box width={1 / 2} px={1}>
          <Input
            {...formState.iban}
            onChange={e => handleChange({ iban: e.target.value })}
            addonSide="start"
            icon={CreditCard}
            type="text"
            label="IBAN"
            name="iban"
          />
        </Box>
        <Box width={1 / 2} px={1}>
          <Input
            {...formState.vat}
            onChange={e => handleChange({ vat: e.target.value })}
            addonSide="start"
            icon={PercentageChat}
            type="text"
            label="BTW-nummer"
            name="vat"
          />
        </Box>

        <Box width={1 / 2} px={1}>
          <Input
            {...formState.rating}
            onChange={e => handleChange({ rating: Number(e.target.value) })}
            addonSide="start"
            icon={StocksUp}
            type="number"
            min={1}
            max={10}
            label="Interne beoordeling [1-10]"
            name="rating"
            title="Een score tussen de 1 en 10, die invloed heeft of deze installateur gekozen wordt bij het maken van automatische installaties. Een prijsverschil van 5% staat gelijk aan 1 punt."
          />
        </Box>

        <Box width={0.5} px={1}>
          <Select<string>
            {...formState.accountManagerId}
            label="Account manager"
            onChange={opt => handleChange({ accountManagerId: opt?.value })}
            value={operatorOptions.find(o => o.value === formState.accountManagerId.value)}
            options={operatorOptions}
            clearable
          />
        </Box>

        <Box width={1} px={1} mt={5}>
          <Button
            type="submit"
            disabled={loading}
            fluid
            iconStart={Hardhat}
            label={'Installatiebedrijf aanmaken *'}
          />
          <Gray>
            <Small>
              * Er wordt automatisch een leverancier aangemaakt met dezelfde eigenschappen
            </Small>
          </Gray>
        </Box>
      </Flex>
    </form>
  );
};

export default AddInstallerForm;
