import { JOB_TYPES_NL } from '@energiebespaarders/constants';
import { Button } from '@energiebespaarders/symbols';
import { Heading, Red, Small } from '@energiebespaarders/symbols/helpers';
import {
  CalendarAlt,
  Clipboard,
  PaperAirplane,
  Warning,
} from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useState } from 'react';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import {
  jobDomainQuote_quoteById,
  jobDomainQuote_quoteById_jobs,
} from '../../../../types/generated/jobDomainQuote';
import { InstallerStatus, JobType } from '../../../../types/graphql-global-types';
import PlanningControlsModal from '../../../planning/PlanningControlsModal';
import SuspendModal from '../../../planning/SuspendModal';
import RequestPlanningModal from './RequestPlanningModal';
import SignoffModal from './SignoffModal';

interface JobActionsProps {
  job: jobDomainQuote_quoteById_jobs;
  quote: jobDomainQuote_quoteById;
}

export const JobActions: React.FC<JobActionsProps> = ({ job, quote }) => {
  const mobile = useIsMobile();
  const jobType = JOB_TYPES_NL[job.type];
  const incorrectJobType =
    (quote.final && job.type === JobType.PTC) ||
    (!quote.final && job.type === JobType.INSTALLATION);

  const [isShowingRequestPlanningModal, setIsShowingRequestPlanningModal] = useState(false);
  const [isShowingSuspendPlanningModal, setIsShowingSuspendPlanningModal] = useState(false);
  const [isShowingSignoffModal, setIsShowingPtcResultsModal] = useState(false);
  const [isShowingPlanningControlsModal, setIsShowingPlanningControlsModal] = useState(false);

  const openRequestPlanningModal = useCallback(() => {
    if (job.installer.name.includes('Energiebespaarders')) {
      window.alert('Je mag geen opdrachten versturen die nog toegewezen staan aan onszelf.');
    }
    if (
      !incorrectJobType ||
      window.confirm(
        `De offerte is ${
          !quote.final ? 'niet ' : 'al '
        }definitief, weet je zeker dat je dit wilt doen voor een ${jobType.toLowerCase()}?`,
      )
    ) {
      setIsShowingRequestPlanningModal(true);
    }
  }, [incorrectJobType, job.installer.name, jobType, quote.final]);

  const openPlanningControlsModal = useCallback(() => setIsShowingPlanningControlsModal(true), []);
  const closePlanningControlsModal = useCallback(
    () => setIsShowingPlanningControlsModal(false),
    [],
  );

  const openSuspendPlanningModal = useCallback(() => setIsShowingSuspendPlanningModal(true), []);
  const closeSuspendPlanningModal = useCallback(() => setIsShowingSuspendPlanningModal(false), []);

  const closeRequestPlanningModal = useCallback(() => setIsShowingRequestPlanningModal(false), []);

  const openPtcResultsModal = useCallback(() => setIsShowingPtcResultsModal(true), []);
  const closeSignoffModal = useCallback(() => setIsShowingPtcResultsModal(false), []);

  const jobPreviouslySent = job.plannings.some(planning => !!planning.sent);
  const requestPlanningText = jobPreviouslySent
    ? 'Planning opnieuw aanvragen'
    : `${jobType}opdracht verzenden...`;

  // Are we waiting for PTC results?
  const signoffExpected =
    !job.installerSignoff?.markedAsComplete && job.plannings.some(p => !!p.sent);

  // Is there a PTC that has been filled in but not confirmed? (I.e. there are PTC results
  // but we haven't marked the job as completed)
  const signoffUnconfirmed = job.installerSignoff?.markedAsComplete && !job.completed;

  const signoffDocumentName = job.type === 'INSTALLATION' ? 'Opleverdocument' : 'Schouwresultaten';

  return (
    <>
      <Heading>Volgende stappen:</Heading>
      {job.completed && (
        <>
          <h3>Opdracht voltooid</h3>
        </>
      )}

      {signoffExpected && (
        <Button
          bgColor={job.type === 'INSTALLATION' ? 'purple' : 'goldDark'}
          fluid
          fontSize={6}
          iconStart={Clipboard}
          label={`${signoffDocumentName} invullen`}
          minIconSize="1rem"
          mr={0}
          onClick={openPtcResultsModal}
          disabled={quote.installer?.status.value === InstallerStatus.inactive}
        />
      )}
      {signoffUnconfirmed && (
        <Button
          to={`/${job.type === JobType.INSTALLATION ? 'opleverdocument' : 'schouw-resultaten'}/${
            job.id
          }`}
          bgColor={job.type === 'INSTALLATION' ? 'purple' : 'goldDark'}
          fluid
          fontSize={6}
          iconStart={Clipboard}
          label={`${signoffDocumentName} bevestigen`}
          minIconSize="1rem"
          mr={0}
        />
      )}
      {!job.completed && (
        <>
          <Button
            bgColor={jobPreviouslySent && !job.currentPlanning?.suspended ? 'gray' : 'orange'}
            fluid
            fontSize={6}
            iconStart={PaperAirplane}
            label={requestPlanningText}
            minIconSize="1rem"
            mr={0}
            onClick={openRequestPlanningModal}
            disabled={quote.installer?.status.value === InstallerStatus.inactive}
          />
          <Button
            bgColor="red"
            disabled={
              !jobPreviouslySent || quote.installer?.status.value === InstallerStatus.inactive
            }
            fluid
            inverse
            fontSize={6}
            iconStart={Warning}
            label="Planning uitstellen"
            minIconSize="1rem"
            mr={0}
            onClick={openSuspendPlanningModal}
          />
          <Button
            bgColor="orange"
            fluid
            inverse
            fontSize={6}
            iconStart={CalendarAlt}
            label="Planning aanpassen"
            minIconSize="1rem"
            mr={0}
            onClick={openPlanningControlsModal}
          />
        </>
      )}

      {isShowingRequestPlanningModal && (
        <RequestPlanningModal
          job={job}
          close={closeRequestPlanningModal}
          jobPreviouslySent={jobPreviouslySent}
          mobile={mobile}
        />
      )}

      {isShowingSuspendPlanningModal && (
        <SuspendModal
          job={{ ...job, quoteId: quote.id }}
          close={closeSuspendPlanningModal}
          mobile={mobile}
        />
      )}

      {isShowingPlanningControlsModal && (
        <PlanningControlsModal quoteId={quote.id} close={closePlanningControlsModal} />
      )}

      {isShowingSignoffModal && <SignoffModal job={job} close={closeSignoffModal} />}

      {!job.installer.installationEmail && (
        <Small>
          <Red>
            Er is geen e-mailadres bekend voor deze installatiepartner. Je zult de opdracht
            handmatig moeten versturen.
          </Red>
        </Small>
      )}
    </>
  );
};

export default JobActions;
