import { useMutation } from '@apollo/client';
import { useIsMobile } from '@energiebespaarders/hooks';
import { Box, Flex, Uploader } from '@energiebespaarders/symbols';
import React, { useCallback, useState } from 'react';
import {
  IMAGE_UPLOAD_SCALE_PREFERENCE_KEY,
  ImageUploadScalePreset,
  ImageUploadScaleSizes,
} from '../domains/Config';
import { scaleImage } from '../domains/Intake/imageScaling';
import { useMe } from '../hooks/useMe';
import useToaster from '../hooks/useToaster';
import { GET_HOUSE_FILES, UPLOAD_HOUSE_FILES } from '../queries/files';
import { getHouseFiles, getHouseFilesVariables } from '../types/generated/getHouseFiles';
import {
  uploadHouseFiles,
  uploadHouseFilesVariables,
  uploadHouseFiles_uploadHouseFiles,
} from '../types/generated/uploadHouseFiles';
import useIsMobileUserAgent from '../hooks/useIsMobileUserAgent';

interface HouseUploaderProps {
  accept?: string;
  bgColor?: string;
  borderColor?: string;
  getExtraTags?: () => string[];
  height?: string;
  houseId: string;
  multiple?: boolean;
  onUploadFinished?: (uploads: readonly uploadHouseFiles_uploadHouseFiles[]) => void;
  showCameraOption?: boolean;
  tags?: string[];
  text?: string;
}

const HouseUploader: React.FC<HouseUploaderProps> = ({
  accept,
  bgColor,
  borderColor,
  getExtraTags,
  height,
  houseId,
  multiple,
  onUploadFinished,
  showCameraOption,
  tags,
  text,
}) => {
  const toast = useToaster();
  const { me } = useMe();
  const isMobile = useIsMobile(); // Is based on screen resolution
  const isMobileUserAgent = useIsMobileUserAgent(); // Also includes tablets

  const [performUpload, { loading, error }] = useMutation<
    uploadHouseFiles,
    uploadHouseFilesVariables
  >(UPLOAD_HOUSE_FILES, {
    update: (store, { data }) => {
      const queryData = store.readQuery<getHouseFiles, getHouseFilesVariables>({
        query: GET_HOUSE_FILES,
        variables: { houseId },
      });
      if (!queryData || !data?.uploadHouseFiles) return;
      const newFiles = queryData.house.files
        ? queryData.house.files.concat(data.uploadHouseFiles)
        : [...data.uploadHouseFiles];

      store.writeQuery<getHouseFiles, getHouseFilesVariables>({
        query: GET_HOUSE_FILES,
        data: {
          house: {
            ...queryData.house,
            files: newFiles,
          },
        },
        variables: { houseId },
      });
    },
    onCompleted: data => {
      if (onUploadFinished) {
        onUploadFinished(data?.uploadHouseFiles || []);
      }
      toast({
        type: 'success',
        message: 'Bestanden zijn succesvol geüpload',
      });
    },
    onError: () =>
      toast({
        type: 'error',
        message: 'Er is een fout opgetreden bij het uploaden van bestanden',
      }),
  });

  // show loading spinner/bar while resizing too
  const [isResizing, setResizing] = useState(false);

  const handleUpload = useCallback(
    async ({ variables }: any) => {
      const rescalePref = (localStorage.getItem(IMAGE_UPLOAD_SCALE_PREFERENCE_KEY) ||
        'large') as ImageUploadScalePreset;

      const fileList: FileList = variables.files as FileList;
      let files: Blob[] = [];
      for (let i = 0; i < fileList.length; i++) {
        const f = fileList.item(i);
        if (f) {
          files.push(f);
        }
      }

      if (rescalePref !== 'original') {
        try {
          setResizing(true);
          const newFiles: Blob[] = [];
          let includesNonImage = false;
          for (let i = 0; i < files.length; i++) {
            // Skip non-image files
            const item = files[i];
            if (!item.type.includes('image')) {
              includesNonImage = true;
              break;
            }
            // TODO: do this in a worker?
            // todo: all images are converted to jpg, so no transparency. Highly unlikely those types of image will be uploaded in the intake though
            newFiles.push(await scaleImage(item, ImageUploadScaleSizes[rescalePref]));
          }
          if (!includesNonImage) {
            files = newFiles;
          }
        } catch (e) {
          console.error(
            'Could not rescale uploaded images, falling back to original resolution',
            e,
          );
        } finally {
          setResizing(false);
        }
      }

      // upload in batches (e.g. max 5 images at once) so request doesn't time out
      let batch: Blob[] = [];
      while (((batch = files.splice(0, 5)), batch.length > 0)) {
        await performUpload({
          variables: {
            id: houseId,
            files: batch,
            tags: [...(tags || []), ...(getExtraTags?.() || [])],
            uploaderId: me.id,
            uploaderUserType: 'operator',
          },
        });
      }
    },
    [getExtraTags, houseId, me.id, performUpload, tags],
  );

  const uploaderProps = {
    accept,
    bgColor,
    borderColor,
    height,
    loading: loading || isResizing,
    error,
    multiple,
    text: text || 'Upload bestanden',
    upload: handleUpload,
    mb: 1,
  };

  return (isMobile || isMobileUserAgent) && showCameraOption ? (
    <Flex mx="-3px">
      <Box width={1 / 2} px="3px">
        <Uploader {...uploaderProps} />
      </Box>
      <Box width={1 / 2} px="3px">
        <Uploader
          {...uploaderProps}
          capture="environment"
          text="Maak een foto"
          bgColor="orangeLighter"
          borderColor="orange"
        />
      </Box>
    </Flex>
  ) : (
    <Uploader {...uploaderProps} />
  );
};

export default HouseUploader;
