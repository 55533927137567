import { Box, Button, Card, Flex, Icon, Input } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import {
  AddCircle,
  ContactCard,
  Mail,
  MinusCircle,
  Phone,
  User,
} from '@energiebespaarders/symbols/icons/solid';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { installerOverview_installerById_contacts as Contact } from '../../types/generated/installerOverview';
import { ContactInput } from '../../types/graphql-global-types';

const Clickable = styled(Icon)`
  cursor: pointer;
`;

type EditProps = {
  contacts: readonly Contact[];
  onUpdate: (contacts: ContactInput[]) => void;
};

const EditInternalContacts: React.FC<EditProps> = ({ contacts, onUpdate }) => {
  const [adding, setAdding] = useState(false);
  const [newContact, setNewContact] = useState<ContactInput>({
    email: '',
    phone: '',
    name: '',
    role: '',
  });

  const addContact = () => {
    setAdding(false);
    onUpdate([...contacts, newContact]);
    setNewContact({ email: '', phone: '', name: '', role: '' });
  };

  const removeContact = (i: number) => {
    onUpdate([...contacts.slice(0, i), ...contacts.slice(i + 1)]);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewContact({ ...newContact, email: e.target.value });
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewContact({ ...newContact, phone: e.target.value });
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewContact({ ...newContact, name: e.target.value });
  };

  const handleRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewContact({ ...newContact, role: e.target.value });
  };

  const AddNewContact = (
    <form
      onSubmit={e => {
        e.preventDefault();
        addContact();
      }}
    >
      <Flex flexWrap="wrap" alignItems="flex-end" mx="-3px">
        <Box width={[1, 1 / 2, 1 / 4]} px="3px" mt={2}>
          <Input
            type="email"
            label="Email"
            placeholder=""
            onChange={handleEmailChange}
            value={newContact.email ?? ''}
          />
        </Box>
        <Box width={[1, 1 / 2, 1 / 4]} px="3px" mt={2}>
          <Input
            type="tel"
            label="Telefoonnummer"
            placeholder=""
            onChange={handlePhoneChange}
            value={newContact.phone ?? ''}
          />
        </Box>
        <Box width={[1, 1 / 2, 1 / 4]} px="3px" mt={2}>
          <Input
            label="Naam"
            placeholder=""
            onChange={handleNameChange}
            value={newContact.name ?? ''}
          />
        </Box>
        <Box width={[1, 1 / 2, 1 / 4]} px="3px" mt={2}>
          <Input
            label="Functie"
            placeholder=""
            onChange={handleRoleChange}
            value={newContact.role ?? ''}
          />
        </Box>
        <Box width={[1, 1 / 2, 1 / 4]} px="3px" mt={2}>
          <Button type="submit" label="Toevoegen" />
        </Box>
      </Flex>
    </form>
  );

  return (
    <div>
      <Flex flexWrap="wrap" mx={-1} mt={1} alignItems="center">
        {[...contacts].map((contact, i) => (
          <Box
            width={[1 / 2, 1 / 3]}
            px={1}
            key={'contact ' + i}
            style={{ display: 'flex', flexGrow: 1 }}
          >
            <Card
              animation="none"
              borderColor="grayLight"
              mb={1}
              shadow="none"
              style={{ width: '100%' }}
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Box width={1}>
                  {contact.name && (
                    <>
                      <Icon icon={User} solid fill="grayDark" mr={1} />
                      <Medium>{contact.name}</Medium>
                      <br />
                    </>
                  )}
                  {contact.role && (
                    <>
                      <Icon icon={ContactCard} solid fill="grayDark" mr={1} />
                      <i>{contact.role}</i>
                      <br />
                    </>
                  )}
                  {contact.email && (
                    <>
                      <Icon icon={Mail} solid fill="grayDark" mr={1} />
                      {contact.email}
                      <br />
                    </>
                  )}
                  {contact.phone && (
                    <>
                      <Icon icon={Phone} solid fill="grayDark" mr={1} />
                      {contact.phone}
                    </>
                  )}
                </Box>
                <Box width="30px">
                  <Clickable
                    onClick={() => removeContact(i)}
                    icon={MinusCircle}
                    solid
                    fill="gray"
                    hoverColor="red"
                    width="18px"
                  />
                </Box>
              </Flex>
            </Card>
          </Box>
        ))}
        {contacts.length === 0 && !adding && (
          <Box width={1} px={4}>
            <i>Nog geen contacten toegevoegd</i>
          </Box>
        )}
        <Box width={adding ? 1 : 1 / 3} px={1}>
          {adding ? (
            AddNewContact
          ) : (
            <Clickable
              onClick={() => {
                setAdding(true);
              }}
              icon={AddCircle}
              solid
              fill="green"
              m={1}
              width="18px"
            />
          )}
        </Box>
      </Flex>
    </div>
  );
};

export default EditInternalContacts;
