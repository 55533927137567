import { DropdownOption } from '@energiebespaarders/hooks/useForm';
import { Box, Modal, Select, Textarea, Toast } from '@energiebespaarders/symbols';
import React, { useState } from 'react';
import { CancellationInformation } from './QuoteStatusButtons';

export const cancellationReasons: DropdownOption<string>[] = [
  { label: 'Kies een reden', value: '', disabled: true },
  { label: 'Klant wil ander product', value: 'customerWantsOtherProduct' },
  { label: 'Adviseur raadt ander product aan', value: 'advisorAdvisesOtherProduct' },
  { label: 'Scherpere offerte bij andere partner', value: 'betterOfferFromOtherPartner' },
  { label: 'Korting noodzakelijk om deal te winnen', value: 'discountNeededToWinDeal' },
  {
    label: 'Overschrijding planningstermijn installateur',
    value: 'planningPeriodExceeded',
  },
  {
    label: 'Installatieopdracht foutief / incompleet',
    value: 'jobIncomplete',
  },
  {
    label: 'Klant wacht op financiering en wilt later installeren',
    value: 'customerWaitingOnFinance',
  },
  {
    label: 'Klant wilt later installeren met overige reden',
    value: 'customerWantsLaterInstallation',
  },
  {
    label: 'Installatie technisch niet mogelijk',
    value: 'technicallyNotPossible',
  },
  {
    label: 'Bij verkeerde installateur uitgezet',
    value: 'wrongInstaller',
  },
  {
    label: 'Geselecteerde installatiebedrijf komt niet overeen met werkgebieden kaart',
    value: 'installerOutsideWorkArea',
  },
  {
    label: 'Herpland door de installateur (op de dag zelf)',
    value: 'installerReplannedOnTheDay',
  },
  {
    label: 'Herpland door de installateur (op tijd)',
    value: 'installerReplannedInTime',
  },
  {
    label: 'Installateur niet komen opdagen zonder bericht',
    value: 'installerNoShow',
  },
  {
    label: 'Verloren opdracht na voorlopig akkoord',
    value: 'lostJobAfterAcceptedQuote',
  },
  {
    label: 'Schade bij installatie of schouw',
    value: 'damageDuringJob',
  },
  {
    label: 'Extra werkzaamheden uitgevoerd zonder overleg',
    value: 'extraWorkWithoutConsultation',
  },
  {
    label: 'Installatie niet afgemaakt',
    value: 'workNotFinished',
  },
  {
    label: 'Offerte gemaakt voor de verkeerde installateur',
    value: 'offerForWrongInstaller',
  },
  {
    label: 'Post vergeten op definitieve offerte',
    value: 'itemForgottenInFinalQuote',
  },
  {
    label: 'Opdracht niet geannuleerd bij installateur',
    value: 'jobNotCancelledByInstaller',
  },
  {
    label: 'Happy Call te laat/nog niet uitgevoerd',
    value: 'happyCallTooLateOrNotDone',
  },
  {
    label: 'Anders: geef aan in toelichting en laat weten aan je manager',
    value: 'other',
  },
  {
    label: 'Installatie geweigerd door installateur',
    value: 'installerRefusedJob',
  },
];

export const cancellationBlames: DropdownOption<string>[] = [
  {
    label: 'Kies een partij',
    value: '',
    disabled: true,
  },
  {
    label: 'Installateur',
    value: 'installer',
  },
  {
    label: 'Adviseur',
    value: 'advisor',
  },
  {
    label: 'Planning',
    value: 'planning',
  },
  {
    label: 'Anders, zie notitie',
    value: 'other',
  },
];

type CancelQuoteModalProps = {
  closeModal: () => void;
  mobile: boolean;
  loading: boolean;
  isOpen: boolean;
  submit: (cancellationInfo: CancellationInformation) => void;
  isOnlyVisibleQuoteOnDeal?: boolean;
};

const CancelQuoteModal: React.FC<CancelQuoteModalProps> = ({
  isOpen,
  closeModal,
  mobile,
  loading,
  submit,
  isOnlyVisibleQuoteOnDeal,
}) => {
  // TODO: duplicate in TransferQuoteModal. Should do this with useForm
  const [cancellationReason, setCancellationReason] = useState(cancellationReasons[0].value);
  const [cancellationText, setCancellationText] = useState('');
  const [cancellationBlame, setCancellationBlame] = useState(cancellationBlames[0].value);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      mobile={mobile}
      title={'Reden van annulering'}
      buttons={[
        {
          label: loading ? 'Laden...' : 'Offerte annuleren',
          bgColor: 'red',
          onClick: () =>
            submit({
              reason: cancellationReason,
              ...(cancellationText ? { note: cancellationText } : {}),
              party: cancellationBlame,
            }),
          disabled: loading || !cancellationReason || !cancellationBlame,
        },
      ]}
    >
      {isOnlyVisibleQuoteOnDeal && (
        <Toast
          id={'69'}
          type="alert"
          width="100%"
          message="Let op! Dit is de enige zichtbare offerte in deze deal. Als de deal verloren is, vergeet deze dan niet op 'Verloren' te zetten."
          mb={2}
        />
      )}
      <Box width={1} mb={3}>
        <Select
          label="Betrokken partij"
          value={cancellationBlames.find(opt => opt.value === cancellationBlame)}
          options={cancellationBlames}
          onChange={e => setCancellationBlame(e.value)}
          required
        />
      </Box>
      <Box width={1} mb={3}>
        <Select
          label="Reden van annulering"
          value={cancellationReasons.find(opt => opt.value === cancellationReason)}
          options={cancellationReasons}
          onChange={e => setCancellationReason(e.value)}
          required
        />
      </Box>
      <Box width={1}>
        <Textarea
          label="Toelichting"
          value={cancellationText}
          onChange={e => setCancellationText(e.target.value)}
        />
      </Box>
    </Modal>
  );
};

export default CancelQuoteModal;
