import { gql, useMutation } from '@apollo/client';
import { enumValues, JobType, JOB_TYPES_NL } from '@energiebespaarders/constants';
import { useForm } from '@energiebespaarders/hooks';
import { Box, Checkbox, Flex, Modal, Select, Textarea } from '@energiebespaarders/symbols';
import React from 'react';
import { useMe } from '../../hooks/useMe';
import useToaster from '../../hooks/useToaster';
import { suspendJob, suspendJobVariables } from '../../types/generated/suspendJob';
import { JobSuspensionReason, WaitingFor } from '../../types/graphql-global-types';

export const SUSPEND_JOB = gql`
  mutation suspendJob(
    $jobId: ID!
    $userId: ID!
    $reason: JobSuspensionReason!
    $comment: String
    $waitingFor: WaitingFor!
    $sendToCustomer: Boolean!
    $sendToInstaller: Boolean!
  ) {
    suspendJob(
      jobId: $jobId
      userId: $userId
      userType: operator
      reason: $reason
      comment: $comment
      waitingFor: $waitingFor
      sendToCustomer: $sendToCustomer
      sendToInstaller: $sendToInstaller
    ) {
      id
      currentPlanning {
        created
        suspended
        sent
        plannedOn
        plannedFor
        arrivalWindow
        metadata {
          comment
          additionalInformation
        }
      }
      plannings {
        created
        sent
        suspended
        plannedOn
        plannedFor
        arrivalWindow
        metadata {
          comment
          additionalInformation
        }
      }
    }
  }
`;

export const JOB_SUSPENSION_REASONS_NL: Record<JobSuspensionReason, string> = {
  [JobSuspensionReason.materialsNotAvailable]: 'Materialen momenteel niet beschikbaar',
  [JobSuspensionReason.wantToPtcFirst]: 'Er moet eerst geschouwd worden',
  [JobSuspensionReason.notInExpertise]: 'Niet binnen expertise',
  [JobSuspensionReason.notInWorkArea]: 'Niet binnen werkgebied',
  [JobSuspensionReason.quoteNotApplicable]: 'Werkbon klopt niet',
  [JobSuspensionReason.noInstallerInArea]: 'Er is geen installateur in de regio beschikbaar',
  [JobSuspensionReason.weather]: 'Weersomstandigheden',
  [JobSuspensionReason.other]: 'Anders',
};

// NOTE: Keep these in sync with IA
const waitingForInstaller: JobSuspensionReason[] = [JobSuspensionReason.materialsNotAvailable];
export const reasonsToSuspend: Array<{
  waitingFor: WaitingFor;
  value: JobSuspensionReason;
  label: string;
}> = enumValues(JobSuspensionReason)
  .sort(a => (a === JobSuspensionReason.other ? 0 : -1))
  .map(reason => ({
    waitingFor: waitingForInstaller.includes(reason) ? WaitingFor.installer : WaitingFor.deb,
    value: JobSuspensionReason[reason],
    label: JOB_SUSPENSION_REASONS_NL[reason],
  }));

type Planning = {
  suspended: Date | null;
  metadata: {
    comment: string | null;
    additionalInformation: string | null;
  } | null;
};
interface SuspendModalProps {
  close: () => void;
  mobile: boolean;
  job: {
    id: string;
    quoteId: string;
    type: JobType;
    plannings: Planning[] | readonly Planning[];
  };
}

const SuspendModal: React.FC<SuspendModalProps> = ({ job, close, mobile }) => {
  const toast = useToaster();
  const { me } = useMe();

  const alreadySuspended = !!job.plannings.slice(-1)[0]?.suspended;

  const [suspendJob, { error, loading }] = useMutation<suspendJob, suspendJobVariables>(
    SUSPEND_JOB,
    {
      onCompleted: () => {
        toast({ type: 'success', message: 'Opdracht opgeslagen' });
        close();
      },
      onError: e =>
        toast({
          type: 'error',
          message: e.message,
        }),
    },
  );

  const { formState, handleChange, submitForm } = useForm<{
    reason: JobSuspensionReason;
    comment: string;
    sendToCustomer: boolean;
    sendToInstaller: boolean;
  }>({
    initialValues: alreadySuspended
      ? {
          reason: job.plannings[0].metadata!.additionalInformation || '',
          comment: job.plannings[0].metadata!.comment || '',
          sendToCustomer: true,
          sendToInstaller: true,
        }
      : {
          reason: '',
          comment: '',
          sendToCustomer: true,
          sendToInstaller: true,
        },
    handleSubmit: values =>
      suspendJob({
        variables: {
          jobId: job.id,
          userId: me.id,
          reason: values.reason,
          comment: values.comment,
          waitingFor:
            reasonsToSuspend.find(reason => reason.value === values.comment)?.waitingFor ||
            WaitingFor.deb, // Fallback
          sendToCustomer: values.sendToCustomer,
          sendToInstaller: values.sendToInstaller,
        },
      }),
    validate: (values, e) => {
      if (!values.comment) e.comment === 'Verplicht';
      return e;
    },
  });

  const reasonOptions = reasonsToSuspend.map(reason => ({
    value: reason.value,
    label: reason.label,
  }));
  return (
    <Modal
      isOpen={true}
      onRequestClose={close}
      mobile={mobile}
      size="md"
      title={'Opdracht in afwachting zetten'}
      buttons={[
        {
          onClick: () => submitForm(),
          label: 'Bevestigen',
          loading,
          error: error?.toString(),
        },
      ]}
    >
      {job.plannings.slice(-1)[0]?.suspended ? (
        <>
          Om de opdracht weer voort te zetten, moet je hem opnieuw naar de installateur verzenden.
          Je kunt dat{' '}
          <a
            href={`/opdracht/${job.quoteId}/${JOB_TYPES_NL[job.type].toLowerCase()}`}
            target="_blank"
            rel="noreferrer"
          >
            hier
          </a>{' '}
          doen. Of je kunt een planningsdatumbevestiging in deze pagina invoeren.
          <hr />
        </>
      ) : (
        <></>
      )}
      <Select<JobSuspensionReason>
        clearable={false}
        label="Reden om de opdracht uit te stellen"
        value={reasonOptions.find(option => option.value === formState.comment.value)}
        error={formState.reason.error}
        touched={formState.reason.touched}
        options={reasonOptions}
        onChange={e => handleChange({ reason: e.value })}
      />
      <Textarea
        label="Extra informatie"
        onChange={e => handleChange({ comment: e.target.value })}
        value={formState.comment.value}
        error={formState.comment.error}
        touched={formState.comment.touched}
      />
      <Flex flexWrap="wrap" mb={2}>
        <Box width={[1, 1, 1 / 2]} px={1}>
          <Checkbox
            bgColor="orange"
            id={'Stuur inst. e-mail'}
            label="Stuur installateur e-mail"
            checked={formState.sendToInstaller.value}
            onChange={() => handleChange({ sendToInstaller: !formState.sendToInstaller.value })}
            value={true}
            width="100%"
          />
        </Box>
        <Box width={[1, 1, 1 / 2]} px={1}>
          <Checkbox
            bgColor="blue"
            id={'Stuur ook de klant e-mail'}
            label="Stuur klant e-mail"
            checked={formState.sendToCustomer.value}
            onChange={() => handleChange({ sendToCustomer: !formState.sendToCustomer.value })}
            value={true}
            width="100%"
          />
        </Box>
      </Flex>
      <p>Wat er gaat gebeuren:</p>
      <ul>
        {formState.sendToInstaller.value && (
          <li>
            De installateur ontvangt een mail dat we momenteel geen planningsdatum nodig hebben
          </li>
        )}
        {formState.sendToCustomer.value && (
          <li>De klant ontvangt een mail dat de opdracht momenteel niet ingepland kan worden</li>
        )}
        <li>Er wordt een herinnering in Pipedrive gezet, die over 4 weken afgevinkt moet worden</li>
        <li>De Deal in Pipedrive wordt naar de 'in afwachting' pijplijn verplaatst</li>
        <li>
          De opdracht is verplaatst van de planningslijst naar een 'uitstel' lijst in het Installer
          Account
        </li>
      </ul>
    </Modal>
  );
};

export default SuspendModal;
