import { Icon } from '@energiebespaarders/symbols';
import { darken } from 'polished';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { themify } from '../../styles/mixins';
import theme from '../../styles/theme';

interface StyledSideNavIconsProps {
  $bgColor: string;
  $height: string;
}

const StyledSideNavIcons = styled.div<StyledSideNavIconsProps>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  background: ${x => x.$bgColor};
  height: ${x => x.$height};
  width: 100%;
`;

interface StyledTabIconWrapperProps {
  $bgColor: string;
  $height: string;
  $hoverColor: string;
  $zIndex: number;
}

const StyledTabIconWrapper = styled.div<StyledTabIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${x => x.$height};
  z-index: ${x => x.$zIndex};
  cursor: pointer;
  transition: all 0.2s ${x => x.theme.curves.standard};
  pointer-events: auto;

  &:hover {
    background: ${x => darken(0.05, x.$bgColor)};

    & path {
      fill: ${x => x.$hoverColor} !important;
    }
  }
`;

interface SideNavTabsProps {
  activeTab: number;
  setActiveTab: (tab: number) => void;
  bgColor?: string;
  hoverColor?: string;
  iconColor?: string;
  tabIcons: any[];
  zIndex?: number;
  'data-tour'?: string;
  height?: string;
}

const SideNavTabs: React.FC<SideNavTabsProps> = ({
  activeTab,
  setActiveTab,
  bgColor = 'grayLighter',
  iconColor = 'grayBlack',
  hoverColor = 'green',
  'data-tour': tourStepName,
  height = theme.fixedSizes.navHeight,
  tabIcons,
  zIndex = theme.z.nav,
}) => {
  return (
    <StyledSideNavIcons
      $bgColor={themify(bgColor)}
      $height={height}
      key="sideNavTabs"
      data-tour={tourStepName}
    >
      {tabIcons.map((icon: ReactNode, index: number) => (
        <StyledTabIconWrapper
          key={`icon-${index}`}
          $bgColor={themify(bgColor)}
          $height={height}
          $hoverColor={themify(hoverColor)}
          onClick={() => setActiveTab(index + 1)}
          $zIndex={zIndex}
        >
          <Icon
            icon={icon}
            fill={themify(activeTab === index + 1 ? hoverColor : iconColor)}
            height="24px"
            width="24px"
            solid
            inline
          />
        </StyledTabIconWrapper>
      ))}
    </StyledSideNavIcons>
  );
};

export default SideNavTabs;
