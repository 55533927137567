import { gql } from '@apollo/client';

export const SolutionAdviceFragment = gql`
  fragment SolutionAdvice on SolutionAdvice {
    advisedQuoteIds
    text
  }
`;

export const AdviceTextsFragment = gql`
  fragment AdviceTexts on Advice {
    wallInsulation {
      text
    }
    floorInsulation {
      text
    }
    roofInsulation {
      text
    }
    insulationGlazing {
      text
    }
    pvSystem {
      text
    }
    heating {
      text
    }
    miscellaneous {
      text
    }
    summary
  }
`;

export const AdvisedQuotesFragment = gql`
  fragment AdvisedQuotes on Advice {
    wallInsulation {
      advisedQuoteIds
    }
    floorInsulation {
      advisedQuoteIds
    }
    roofInsulation {
      advisedQuoteIds
    }
    insulationGlazing {
      advisedQuoteIds
    }
    pvSystem {
      advisedQuoteIds
    }
    heating {
      advisedQuoteIds
    }
    miscellaneous {
      advisedQuoteIds
    }
  }
`;
