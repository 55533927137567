import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, Select, Textarea } from '@energiebespaarders/symbols';
import { cancellationReasons } from '../../domains/Rating/utils';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { useMe } from '../../hooks/useMe';

const AddCancellationModal = ({ loading, isOpen, onClose, onSubmit }) => {
  const { me } = useMe();
  const [comment, setComment] = useState('');
  const [reason, setReason] = useState('');
  const mobile = useIsMobile();
  const validate = () => {
    return Boolean(reason && !loading);
  };
  const handleSubmit = () => {
    if (validate()) {
      onSubmit({ comment, reason, operatorId: me.id });
    } else {
      throw new Error("Shouldn't be possible");
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      mobile={mobile}
      onRequestClose={onClose}
      title="Opdracht annuleren"
      size="sm"
      shouldCloseOnOverlayClick={false}
      buttons={[
        {
          label: 'Sluiten',
          bgColor: 'red',
          inverse: true,
          onClick: onClose,
        },
        {
          label: 'Opdracht annuleren',
          bgColor: 'red',
          disabled: !validate(),
          onClick: handleSubmit,
        },
      ]}
    >
      <Box width={1} mb={2}>
        <Select
          label="Reden"
          options={cancellationReasons}
          onChange={e => setReason(e.value)}
          value={cancellationReasons.find(opt => opt.value === reason)}
        />
      </Box>

      <Box>
        <Textarea
          label="Commentaar"
          value={comment}
          rows={8}
          onChange={e => setComment(e.target.value)}
        />
      </Box>
    </Modal>
  );
};

AddCancellationModal.propTypes = {
  mobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AddCancellationModal.defaultProps = {
  isOpen: true,
};

export default AddCancellationModal;
