import { Box, Button, Card, Flex, Icon, Input } from '@energiebespaarders/symbols';
import { AddCircle, MinusCircle } from '@energiebespaarders/symbols/icons/solid';
import _ from 'lodash';
import React, { ChangeEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useMe } from '../../hooks/useMe';
import useToaster from '../../hooks/useToaster';
import { checkTeamMember } from '../../lib/permissions';
import { getInstallationPartners_installersBySolution_installationRegions as PC4Region } from '../../types/generated/getInstallationPartners';
import { InstallationRegionInput as PC4RegionInput } from '../../types/graphql-global-types';
import { OperatorTeam } from '../operatorDirectory/OperatorProfile';

const Clickable = styled(Icon)`
  cursor: pointer;
`;

type Props = {
  regions: readonly PC4Region[];
  onUpdate: (installationRegions: PC4RegionInput[]) => void;
  regionUpdatePermittedOperatorEmails?: string[]; // array of emails of operators that may edit the regions
};

const EditPC4Regions: React.FC<Props> = ({
  regions: installationRegions,
  onUpdate,
  regionUpdatePermittedOperatorEmails = [],
}: Props) => {
  const [adding, setAdding] = useState(false);
  const [newRegion, setNewRegion] = useState<PC4RegionInput>({
    from: 0,
    to: 0,
  });

  const { me } = useMe();
  const toast = useToaster();
  const hasPermission =
    checkTeamMember(me, OperatorTeam.NetworkSuccess) ||
    checkTeamMember(me, OperatorTeam.Planning) ||
    checkTeamMember(me, OperatorTeam.Development) ||
    me.email === 'laurens@energiebespaarders.nl' ||
    regionUpdatePermittedOperatorEmails.includes(me.email); // TODO: PERMISSION HACK
  const blockEdit = () =>
    toast({ type: 'error', message: 'Alleen NS en DEV kunnen werkgebieden bewerken.' });

  const removeRegion = (i: number) => {
    if (!hasPermission) return blockEdit();
    onUpdate([...installationRegions.slice(0, i), ...installationRegions.slice(i + 1)]);
  };
  const addRegion = () => {
    if (!hasPermission) return blockEdit();
    if (newRegion.to < newRegion.from) {
      return window.alert(
        'De ondergrens PC4 van het gebied moet lager zijn dan de bovengrens PC4.',
      );
    }
    const existingRegions = installationRegions.map(r => ({ from: r.from, to: r.to }));
    if (existingRegions.some(region => _.isEqual(region, newRegion))) {
      return window.alert('Regio is al toegevoegd!');
    }
    setAdding(false);
    onUpdate([...installationRegions, newRegion]);
    setNewRegion({ from: 0, to: 0 });
  };

  const handleChangeRegionStart = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const pc4 = Number(e.target.value);
      if (!pc4 || (pc4 > 0 && pc4 < 9999)) {
        setNewRegion({ from: pc4, to: newRegion.to });
      }
    },
    [newRegion.to],
  );

  const handleChangeRegionEnd = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const pc4 = Number(e.target.value);
      if (!pc4 || (pc4 >= 0 && pc4 <= 9999)) {
        setNewRegion({ from: newRegion.from, to: pc4 });
      }
    },
    [newRegion.from],
  );

  const AddNewRegion = (
    <form
      onSubmit={e => {
        e.preventDefault();
        addRegion();
      }}
    >
      <Flex flexWrap="wrap" alignItems="flex-end" mx="-3px">
        <Box width={[2 / 5, 1 / 3]} px="3px" mt={2}>
          <Input
            label="Begin werkgebied (PC4)"
            placeholder="van..."
            type="number"
            maxLength={4}
            max={9999}
            onChange={handleChangeRegionStart}
            value={newRegion.from}
          />
        </Box>
        <Box width={[2 / 5, 1 / 3]} px="3px" mt={2}>
          <Input
            label="Eind werkgebied (PC4)"
            placeholder="tot..."
            type="number"
            max={9999}
            maxLength={4}
            onChange={handleChangeRegionEnd}
            value={newRegion.to}
          />
        </Box>
        <Box width={[1 / 5, 1 / 3]} px="3px" mt={2}>
          <Button type="submit" label="Toevoegen" />
        </Box>
      </Flex>
    </form>
  );

  return (
    <Flex flexWrap="wrap" alignItems="center" mx={-1} mt={1}>
      {[...installationRegions].map((region, i) => (
        <Box width={[1 / 2, 1 / 3, 1 / 3]} px={1} key={'installation region ' + i}>
          <Card animation="none" borderColor="grayLight" bgColor="grayLighter" mb={1} shadow="none">
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                {region.from} - {region.to}
              </Box>
              <Box width="30px">
                <Clickable
                  onClick={() => removeRegion(i)}
                  icon={MinusCircle}
                  solid
                  fill="gray"
                  hoverColor="red"
                  width="18px"
                />
              </Box>
            </Flex>
          </Card>
        </Box>
      ))}
      {installationRegions.length === 0 && !adding && (
        <Box width={1} px={4}>
          <i>Nog geen werkgebieden ingevuld</i>
        </Box>
      )}

      {/* TODO: link to map page (and/or display inline/modal)*/}
      {/* TODO: notify about overlapping regions */}
      {/* TODO: Sort based on start-region? */}

      <Box width={adding ? 1 : 1 / 3} px={1}>
        {adding ? (
          AddNewRegion
        ) : (
          <Clickable
            onClick={() => {
              if (!hasPermission) return blockEdit();
              setAdding(true);
            }}
            icon={AddCircle}
            solid
            fill="green"
            m={1}
            width="18px"
          />
        )}
      </Box>
    </Flex>
  );
};

export default EditPC4Regions;
