import React from 'react';
import { Gray, Smaller } from '@energiebespaarders/symbols/helpers';

export const adviceGuide = (
  <>
    <Smaller>
      <Gray>Laatst gewijzigd: 09-05-2019</Gray>
    </Smaller>
    <h3>Energiepaspoort</h3>
    <p>
      Hier kun je het Energiepaspoort voor een woning aanmaken en vullen. Eerst moet je aangeven
      welke adviseur bij het Energiepaspoort komt te staan. Op de overzichtspagina zie je de
      algemene info rond het Energiepaspoort (wie, wanneer, betaalstatus, etc.) Daaronder zie je een
      tabel met alle berekeningen van de geadviseerde offertes. Deze tabel komt ook in het
      Energiepaspoort van de klant te staan. Je kunt de berekeningen van oplossingen zonder
      (geadviseerde) offerte ook tonen door het schuifje om te zetten.
    </p>

    <p>
      Door oplossingen aan te klikken, kun je zelf totalen berekenen van de geselecteerde offertes.
      Dit bootst heel simpel na wat de klant straks kan in het Energiepaspoort. Verder kun je hier
      de samenvatting schrijven en aanvinken welke bijlagen getoond moeten worden voor de klant.
      Lang niet alle bijlagen zijn altijd relevant.
    </p>

    <p>
      Per oplossing krijg je weer een aparte pagina met links de input en rechts de output. Je ziet
      links de geadviseerde offerte en bijbehorende berekeningen. Links is ook de slimme tool om
      teksten samen te stellen. Als er een geadviseerde offerte is, krijg je 5 vragen om elk een
      stukje tekst te genereren. Deze kun je kopiëren door erop te klikken en vervolgens rechts in
      de juiste volgorde plakken om zo een lopend verhaal te maken. Controleer deze wel nog altijd
      even! Je kunt zelf de tekst nog aanvullen, bijvoorbeeld met informatie over de beschikbare
      subsidies of leningen (die eronder worden ingeladen).
    </p>

    <p>
      Is er geen geadviseerde offerte, krijg je 2 vragen over waarom de oplossing niet geadviseerd
      is. In de teksteditor kun je gebruik afbeeldingen en hyperlinks plakken en tekst netjes
      formatteren. Na alle oplossingen te hebben aangevuld met een adviestekst, kun je de notendop
      schrijven/controleren, de volgorde van de oplossingen aanpassen en vervolgens het
      Energiepaspoort versturen! Er wordt dan direct een mail gestuurd naar de klant.
    </p>
  </>
);
