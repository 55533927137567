import dayjs, { Dayjs } from 'dayjs';
import { lowerCase, upperFirst } from 'lodash';

/**
 * Calculates Easter in the Gregorian/Western (Catholic and Protestant) calendar
 * based on the algorithm by Oudin (1940) from http://www.tondering.dk/claus/cal/easter.php
 */
function getEasterDate(year = new Date().getFullYear()): Dayjs {
  const f = Math.floor,
    // Golden Number - 1
    G = year % 19,
    C = f(year / 100),
    // related to Epact
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    // number of days from 21 March to the Paschal full moon
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    // weekday for the Paschal full moon
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4);

  return dayjs(`${year}-${month}-${day}`);
}

/** Returns an object with a Dayjs object for each holiday  */
function getHolidays(year = new Date().getFullYear()): { [x: string]: Dayjs } {
  const easter = getEasterDate(year);
  return {
    newYears: dayjs(`${year + 1}-01-01`),
    kingsDay: dayjs(`${year}-04-27`),
    liberation: dayjs(`${year}-05-05`),
    xmas: dayjs(`${year}-12-25`),
    xmas2: dayjs(`${year}-12-26`),
    goodFriday: easter.subtract(2, 'day'),
    easter,
    easterMonday: easter.add(1, 'day'),
    ascension: easter.add(40, 'day'),
    pentecost: easter.add(49, 'day'),
    whitMonday: easter.add(50, 'day'),
  };
}

export function getHolidayName(date: string) {
  const holidays = getHolidays();
  const formatDate = (date: string | Dayjs) => dayjs(date).format('DD/MM/YY');
  let errorMessage = '';

  for (const key in holidays) {
    if (formatDate(date) === formatDate(holidays[key])) {
      errorMessage = key;
    }
  }
  return upperFirst(lowerCase(errorMessage));
}

export function isWeekend(date: string): boolean {
  return dayjs(date).day() === 6 || dayjs(date).day() === 0;
}

export function isWithin12Months(date: string) {
  return dayjs(date).isBefore(dayjs().add(1, 'year'));
}

export function isInPast(date: string): boolean {
  return dayjs(date).isBefore(dayjs());
}

export const checkTimeRoundedByNMin = (time: string, n: number, short?: boolean) => {
  const minutes = Number(time.split(':')[1]);
  const minutesAreRound = minutes % n ? false : true;

  if (!minutesAreRound) {
    return short ? `Niet afgerond op ${n} min.` : `Tijd moet in stappen van ${n} minuten zijn`;
  }

  return '';
};

export const checkTimeWorkingHours = (time: string) => {
  const hours = Number(time.split(':')[0]);
  const timeIsInWorkingHours = hours >= 8 && hours <= 18;

  if (!timeIsInWorkingHours) {
    return 'Buiten werktijd';
  }

  return '';
};

export const validateDate = (date: string): string => {
  const holidayName = getHolidayName(date);
  let errorMessage = '';

  isWeekend(date) ? (errorMessage = 'Datum is in het weekend') : '';
  !isWithin12Months(date) ? (errorMessage = 'Datum is niet in de komende 12 maanden') : '';
  isInPast(date) ? (errorMessage = 'Datum is in het verleden') : '';
  holidayName ? (errorMessage = `Datum is op ${holidayName}`) : '';

  return errorMessage;
};
