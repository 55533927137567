import { startCase } from 'lodash';

export const formatBrandName = (brand: string) => {
  if (!brand) return '';
  const brandsNeedFormatting = [
    'HanoverSolar',
    'JASolar',
    'JinkoSolar',
    'PhonoSolar',
    'RaabKarcher',
    'TrinaSolar',
  ];
  if (brand === 'GLASSOLUTIONSSaintGobain') return 'GLASSOLUTIONS Saint-Gobain';
  return brandsNeedFormatting.includes(brand) ? startCase(brand) : brand;
};
