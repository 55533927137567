import { Icon, Modal } from '@energiebespaarders/symbols';
import { Hand } from '@energiebespaarders/symbols/icons/solid';
import { boxShadow } from '@energiebespaarders/symbols/styles/mixins';
import React from 'react';
import styled from 'styled-components';
import { useIsMobile, useIsTablet } from '@energiebespaarders/symbols/hooks';
import { useNavHelpers } from '../../hooks/useNavHelpers';
import { LinearIssueForm } from '../linear/IssueForm';

const StyledTicketBadge = styled.div`
  ${boxShadow('sm')};
  width: 42px;
  height: 42px;
  border-radius: 42px;
  line-height: 36px;
  text-align: center;
  background: ${x => x.theme.colors.orange};
  pointer-events: all;
  z-index: 999999;
  position: absolute;
  right: 24px;
  bottom: 18px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    ${boxShadow('md')};
    bottom: 21px;
  }
`;

const TicketPopup: React.FC = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { ticketPopupOpen, toggleTicketPopup } = useNavHelpers();

  return (
    <>
      {isMobile || isTablet ? (
        <></>
      ) : (
        <StyledTicketBadge title="Ticket aanmaken" onClick={toggleTicketPopup}>
          <Icon icon={Hand} fill="white" width="21px" />
        </StyledTicketBadge>
      )}

      <Modal
        title="Ticket aanmaken voor Product"
        isOpen={ticketPopupOpen}
        onRequestClose={toggleTicketPopup}
        mobile={isMobile}
        width="xl"
        headerBgColor="gold"
        bgColor="floral"
      >
        <LinearIssueForm closeModal={toggleTicketPopup} />
      </Modal>
    </>
  );
};

export default TicketPopup;
