import { gql, useMutation } from '@apollo/client';
import { JOB_TYPES_NL } from '@energiebespaarders/constants';
import { Box as BoxSymbol, Flex, Toast } from '@energiebespaarders/symbols';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SimpleBanner from '../../domains/TaskFlow/SimpleBanner';
import useToaster from '../../hooks/useToaster';
import {
  getEmailsEventsByEntityIdAndTemplate,
  getEmailsEventsByEntityIdAndTemplateVariables,
} from '../../types/generated/getEmailsEventsByEntityIdAndTemplate';
import {
  sendPlanningChaser,
  sendPlanningChaserVariables,
} from '../../types/generated/sendPlanningChaser';
import {
  JobSuspensionReason,
  JobType,
  MailjetTemplateDescriptor,
} from '../../types/graphql-global-types';
import { JobWithStatus, Planning } from '../jobs/JobControls/JobStatus';
import ChaseInstaller from './overviewTable/ChaseInstaller';
import CompleteInstallation from './overviewTable/CompleteInstallation';
import PtcSignoffAccordion from './overviewTable/PtcSignoffAccordion';
import { GET_EMAILS_BY_ENTITY_ID_AND_TEMPLATE } from './queries';
import { JOB_SUSPENSION_REASONS_NL } from './SuspendModal';
import UpdatePlanningForm from './UpdatePlanningForm';

const Box = styled(BoxSymbol)`
  padding: 9px;
  margin: 6px;

  border-radius: 3px;
`;

const CHASE_INSTALLER = gql`
  mutation sendPlanningChaser($jobId: ID!) {
    sendPlanningChaser(jobId: $jobId)
  }
`;

interface Job extends JobWithStatus {
  id: string;
  type: JobType;
  quoteId: string;
  completed: Date | null;
  installerSignoff: {
    markedAsComplete: {
      date: Date;
    } | null;
  } | null;
  plannings: Array<Planning>;
}

const PlanningControls: React.FC<{
  job: Job;
  currentDate: Date | undefined;
  installerName: string;
  installerEmail: string;
  chaserEmailTemplate: MailjetTemplateDescriptor;
  quoteId: string;
}> = ({ job, currentDate, installerName, chaserEmailTemplate, quoteId }) => {
  const toast = useToaster();

  const [chaseInstaller, { loading: chaseInstallerLoading }] = useMutation<
    sendPlanningChaser,
    sendPlanningChaserVariables
  >(CHASE_INSTALLER, {
    variables: { jobId: job.id },
    update: cache => {
      const cacheData = cache.readQuery<
        getEmailsEventsByEntityIdAndTemplate,
        getEmailsEventsByEntityIdAndTemplateVariables
      >({
        query: GET_EMAILS_BY_ENTITY_ID_AND_TEMPLATE,
        variables: { entityId: job.id, template: chaserEmailTemplate },
      });
      if (cacheData?.getEmailsEventsByEntityIdAndTemplate) {
        const newData = cacheData.getEmailsEventsByEntityIdAndTemplate.concat([
          {
            id: `newEmail${new Date().getTime()}`,
            template: chaserEmailTemplate,
            sent: new Date(),
            open: null,
            bounce: null,
            blocked: null,
            __typename: 'EmailEventHistory',
          },
        ]);
        cache.writeQuery<
          getEmailsEventsByEntityIdAndTemplate,
          getEmailsEventsByEntityIdAndTemplateVariables
        >({
          query: GET_EMAILS_BY_ENTITY_ID_AND_TEMPLATE,
          variables: { entityId: job.id, template: chaserEmailTemplate },
          data: { getEmailsEventsByEntityIdAndTemplate: newData },
        });
      }
    },
    onCompleted: () => toast({ type: 'success', message: 'Herinnering verstuurd' }),
    onError: e => toast({ type: 'error', message: e.message }),
  });

  const suspendedPlanning = useMemo(() => {
    const latestPlanning = job.plannings.at(-1);
    return latestPlanning?.suspended ? latestPlanning : undefined;
  }, [job.plannings]);

  const reason = suspendedPlanning?.metadata?.comment as JobSuspensionReason | undefined;

  return (
    <>
      {suspendedPlanning?.metadata?.comment && (
        <Toast
          type="error"
          message={
            <div>
              <p>
                Deze opdracht is op {dayjs(suspendedPlanning.suspended!).format('DD/MM/YY')} in
                afwachting gezet.
              </p>
              <p>Reden: {reason ? JOB_SUSPENSION_REASONS_NL[reason] : 'Onbekend'}</p>
              <p>Toelichting: {suspendedPlanning.metadata?.additionalInformation}</p>
            </div>
          }
          width={'100%'}
        />
      )}

      <Flex mx={-1} mb={1}>
        <Box width={[1, 1 / 2]} px={1}>
          <SimpleBanner type="warning" message={'Opdracht voorlopig niet uitvoerbaar?'} />
          <p>
            Als de opdracht voorlopig niet uitvoerbaar is, kun je de opdracht via de{' '}
            <Link to={`/opdracht/${job.quoteId}/${JOB_TYPES_NL[job.type].toLowerCase()}`}>
              opdrachtpagina
            </Link>{' '}
            uitstellen.
          </p>
        </Box>

        <Box width={[1, 1 / 2]} px={1}>
          <ChaseInstaller
            template={chaserEmailTemplate}
            jobId={job.id}
            message={
              'Nog geen planningsinformatie van de installateur ontvangen? Stuur een herinnering.'
            }
            sendEmail={chaseInstaller}
            emailSending={chaseInstallerLoading}
            buttonLabel="Stuur herinnering voor planning"
          />
        </Box>
      </Flex>

      <UpdatePlanningForm job={job} currentDate={currentDate} installerName={installerName} />

      {job.type === JobType.PTC ? (
        <PtcSignoffAccordion ptcJob={job} />
      ) : (
        <CompleteInstallation jobId={job.id} quoteId={quoteId} />
      )}
    </>
  );
};

export default PlanningControls;
