import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { me_me_Operator } from '../types/generated/me';

type Me = me_me_Operator;

type ContextValue = [Me | undefined, Dispatch<SetStateAction<Me | undefined>> | undefined];

export const MeContext = React.createContext<ContextValue>([undefined, undefined]);

export const MeProvider: React.FC = ({ children }) => {
  const [me, setMe] = useState<Me | undefined>();
  const contextValue: ContextValue = [me, setMe];
  return <MeContext.Provider value={contextValue}>{children}</MeContext.Provider>;
};

export function useMe() {
  const [me, setMe] = useContext(MeContext);
  return {
    me: me as Me,
    setMe: setMe!,
  };
}
