import { useLazyQuery } from '@apollo/client';
import { Solution, SolutionDomain } from '@energiebespaarders/constants';
import { Accordion, Box, Button, Card, SpinnerOverlay } from '@energiebespaarders/symbols';
import { Right } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { Eye, FileEuro } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useIsMounted } from 'usehooks-ts';
import Quote from '.';
import { useMe } from '../../../hooks/useMe';
import useTrainingHouse from '../../../hooks/useTrainingHouse';
import { checkIfManagerOrAdmin } from '../../../lib/permissions';
import { DEALS_BY_SOLUTION_DOMAIN } from '../../../queries/installatron';
import {
  dealsByHouseSolutionDomain,
  dealsByHouseSolutionDomainVariables,
  dealsByHouseSolutionDomain_dealsByHouseSolutionDomain as t_deal,
  dealsByHouseSolutionDomain_dealsByHouseSolutionDomain_quotes as t_quote,
} from '../../../types/generated/dealsByHouseSolutionDomain';
import { JobStatus, JobType } from '../../../types/graphql-global-types';
import { MarkDealLostModal, QuoteModal } from '../../modals';
import DealControl from '../Deal/DealControl';
import DealTitle from '../Deal/DealTitle';
import ResetQuotesForTraining from './ResetQuotesForTraining';

type DealsAndQuotesProps = {
  installationId: string | undefined;
  houseId: string;
  solutionDomain: SolutionDomain;
  solution: Solution;
  setSolution: (solution: Solution) => void;
  hasDeals: boolean;
};

const DealsAndQuotes: React.FC<DealsAndQuotesProps> = ({
  installationId,
  houseId,
  solutionDomain,
  solution,
  setSolution,
  hasDeals,
}) => {
  const isTrainingHouse = useTrainingHouse();
  const { me } = useMe();
  const mobile = useIsMobile();
  const { quoteId: urlQuoteId } = useParams<{ quoteId: string }>() as {
    quoteId: string;
  };
  const [activeQuoteId, setActiveQuoteId] = useState<string>(urlQuoteId || '');
  const [loseDealId, setLoseDealId] = useState<string | undefined>(undefined);
  const [quotes, setQuotes] = useState<t_quote[]>([]);
  const [sortedDeals, setSortedDeals] = useState<t_deal[]>([]);
  const [acceptedSolutions, setAcceptedSolutions] = useState<Solution[]>([]);
  const [quotesIsOpen, setQuotesIsOpen] = useState(hasDeals);
  const [hideCancelledQuotes, setHideCancelledQuotes] = useState<boolean>(false);
  const getIconColor = (quotes: t_quote[]) => {
    if (quotes.length === 0) {
      return 'red';
    } else if (quotes.filter(quote => quote.isAccepted).length > 0) {
      return 'green';
    }
    return 'purple';
  };

  const [getDeals, { data, loading, error }] = useLazyQuery<
    dealsByHouseSolutionDomain,
    dealsByHouseSolutionDomainVariables
  >(DEALS_BY_SOLUTION_DOMAIN, {
    variables: { houseId, solutionDomain },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: data =>
      data?.dealsByHouseSolutionDomain?.length > 0 && !quotesIsOpen && setQuotesIsOpen(true),
  });

  const isMounted = useIsMounted();

  useEffect(() => {
    if (!isMounted()) return;
    if (quotesIsOpen) setQuotesIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solutionDomain]);

  useEffect(() => {
    if (quotesIsOpen && !data && !loading && !error) getDeals();
  }, [data, error, getDeals, loading, quotesIsOpen, solutionDomain]);

  useEffect(() => {
    if (hasDeals && !quotesIsOpen) setQuotesIsOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDeals, solutionDomain]);

  useEffect(() => {
    if (data?.dealsByHouseSolutionDomain) {
      const deals = data.dealsByHouseSolutionDomain;
      setQuotes(deals.reduce((quotes, deal) => [...quotes, ...deal.quotes], [] as t_quote[]));
      setAcceptedSolutions(
        deals.reduce(
          (solutions, deal) => [
            ...solutions,
            ...deal.quotes.reduce(
              (solutions: Solution[], quote) =>
                quote.isAccepted && !quote.isCancelled ? [...solutions, quote.solution] : solutions,
              [],
            ),
          ],
          [] as Solution[],
        ),
      );
      setSortedDeals(
        deals
          .map(deal => ({
            ...deal,
            quotes: [...deal.quotes].sort(
              (a, b) =>
                a.cancelledOn - b.cancelledOn ||
                b.acceptedOn - a.acceptedOn ||
                b.created - a.created,
            ),
          }))
          .sort((a, b) => a.created - b.created),
      );
    }
  }, [data]);

  const handleCloseQuoteModal = useCallback(() => setActiveQuoteId(''), []);

  const renderQuote = (quote: t_quote, onHold: boolean, deal: t_deal) => {
    const ptcJobs = deal.quotes.flatMap(quote =>
      quote.jobs.filter(job => job.type === JobType.PTC && job.completed),
    );
    const mostRecentlyCompletedPtc = ptcJobs
      .map(job => job.completed)
      .sort()
      .reverse()[0];
    const isAfterPtc = dayjs(quote.created).isAfter(dayjs(mostRecentlyCompletedPtc));
    return (
      <Quote
        key={quote.id}
        quote={{
          ...quote,
          onHold,
          deal: { id: deal.id, noLongerActive: deal.noLongerActive, __typename: 'Deal' },
        }}
        isMobile={mobile}
        openModal={() => {
          setActiveQuoteId(quote.id);
          if (quote.solution !== solution) setSolution(quote.solution);
        }}
        houseId={houseId}
        redundant={acceptedSolutions.includes(quote.solution) && !quote.isAccepted}
        solution={quote.solution}
        solutionDomain={solutionDomain}
        isAfterPtc={isAfterPtc}
      />
    );
  };

  const isOnlyVisibleQuoteOnDeal = useMemo(() => {
    if (data?.dealsByHouseSolutionDomain.length && activeQuoteId) {
      const deals = data.dealsByHouseSolutionDomain;
      const activeDeal = deals.find(deal => deal.quotes.some(quote => quote.id === activeQuoteId));
      // if there is another quote on the deal that is not the active quote, is not cancelled and is sent
      // then the active quote is NOT the only visible quote on the deal
      return (
        activeDeal?.quotes.some(
          quote => !quote.isCancelled && quote.isSent && quote.id !== activeQuoteId,
        ) === false
      );
    }
  }, [data, activeQuoteId]);

  const renderNewTaskFlowShortcut = useCallback(
    (deal: t_deal) => {
      if (!checkIfManagerOrAdmin(me)) return null;
      const jobId = deal.quotes
        .find(quote => quote.id === deal.activeQuote?.id)
        ?.jobs.find(job => job?.type === JobType.PTC && job.status !== JobStatus.completed)?.id;
      if (!jobId) return null;
      return <Button to={`/taak/schouwresultaten/${jobId}`} label="Schouwresultaten verwerken!" />;
    },
    [me],
  );

  return (
    <>
      <Card
        p={0}
        mb={4}
        zIndex={200}
        key="quotesCard"
        animation="none"
        borderRadius="9px"
        style={{ overflowY: 'hidden' }}
      >
        <Accordion
          baseBgColor="white"
          bgColor="grayLighter"
          contentBorderColor="grayLight"
          contentPadding={mobile ? 2 : 4}
          defaultOpen={!mobile && quotesIsOpen}
          icon={FileEuro}
          iconColor={getIconColor(quotes)}
          isOpened={sortedDeals.length > 0 && quotesIsOpen}
          onClick={() => setQuotesIsOpen(!quotesIsOpen)}
          override
          title={`Deals ${sortedDeals.length > 0 ? `(${sortedDeals.length})` : ''}`}
          titleSize={5}
          zIndex={200}
        >
          {loading ? (
            <SpinnerOverlay />
          ) : (
            sortedDeals.map((deal, i) => (
              <Box
                key={deal.id}
                pb={sortedDeals.length > 1 && i !== sortedDeals.length - 1 ? 2 : 0}
              >
                <DealTitle deal={deal} openLoseDealModal={() => setLoseDealId(deal.id)} />

                {renderNewTaskFlowShortcut(deal)}

                {deal.activeQuote && (
                  <DealControl deal={{ ...deal, activeQuote: deal.activeQuote }} />
                )}

                {deal.quotes.map(quote => {
                  return hideCancelledQuotes && quote.isCancelled
                    ? null
                    : renderQuote(quote, !!deal.onHold?.since, deal);
                })}
              </Box>
            ))
          )}

          <Right block>
            <Button
              bgColor="blue"
              fontSize={6}
              iconStart={Eye}
              minimal={!hideCancelledQuotes}
              onClick={() => setHideCancelledQuotes(!hideCancelledQuotes)}
              mt={1}
              mr={0}
              label={
                hideCancelledQuotes
                  ? 'Geannuleerde offertes tonen'
                  : 'Geannuleerde offertes verbergen'
              }
            />
          </Right>
        </Accordion>

        <QuoteModal
          isOpen={!!activeQuoteId}
          closeModal={handleCloseQuoteModal}
          houseId={houseId}
          quoteId={activeQuoteId}
          solutionAccepted={Boolean(
            quotes.find(q => q.id === activeQuoteId) &&
              acceptedSolutions.includes(quotes.find(q => q.id === activeQuoteId)!.solution),
          )}
          isOnlyVisibleQuoteOnDeal={isOnlyVisibleQuoteOnDeal}
        />

        {Boolean(loseDealId) && installationId !== undefined && (
          <MarkDealLostModal
            deal={sortedDeals.find(deal => deal.id === loseDealId)!}
            closeModal={() => setLoseDealId(undefined)}
          />
        )}
      </Card>
      {isTrainingHouse && <ResetQuotesForTraining />}
    </>
  );
};

export default DealsAndQuotes;
