import { useEffect, useState } from 'react';
import { PC3_URL } from './mapUtils';

const PC3_DATA_STORAGE_KEY = 'pc3-data';

type PC3Data = {
  type: 'FeatureCollection';
  features: [{ type: 'Feature'; geometry: { type: 'Polygon'; coordinates: number[][][] } }];
};

async function loadPC3Data(): Promise<PC3Data> {
  const res = await fetch(PC3_URL, { cache: 'force-cache' });
  return await res.json();
}

const usePC3Data = ({
  onLoad,
  onError,
}: {
  onLoad: (data: PC3Data) => void;
  onError: (error: Error) => void;
}) => {
  const [data, setData] = useState<PC3Data>(() => {
    const cachedData = localStorage.getItem(PC3_DATA_STORAGE_KEY);
    if (cachedData) {
      return JSON.parse(cachedData);
    }
  });

  useEffect(() => {
    if (!data) {
      loadPC3Data()
        .then(data => {
          setData(data);
          onLoad(data);
          localStorage.setItem(PC3_DATA_STORAGE_KEY, JSON.stringify(data));
        })
        .catch(onError);
    } else {
      onLoad(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
};

export default usePC3Data;
