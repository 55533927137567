import { gql } from '@apollo/client';
import { SituationFragment, InstallerNotesFragment } from '../fragments/Situation';
import { IntakeFragment, IntakeNotesFragment } from '../fragments/Intake';

export const UPDATE_INTAKE = gql`
  ${IntakeFragment}
  mutation updateIntake($houseId: ID!, $intake: IntakeInput!) {
    updateIntake(id: $houseId, intake: $intake) {
      ...Intake
    }
  }
`;

export const UPDATE_SITUATION = gql`
  ${SituationFragment}
  mutation updateSituation($id: ID!, $situation: SituationInput!) {
    updateSituation(id: $id, situation: $situation) {
      ...Situation
    }
  }
`;

export const START_INTAKE = gql`
  ${IntakeFragment}
  mutation startIntake($houseId: ID!, $operatorId: ID!, $method: IntakeMethod!) {
    startIntake(houseId: $houseId, operatorId: $operatorId, method: $method) {
      ...Intake
    }
  }
`;

export const INTAKE_NOTES_BY_HOUSE = gql`
  ${IntakeNotesFragment}
  query intakeNotesByHouse($houseId: ID!) {
    intakeByHouse(houseId: $houseId) {
      id
      ...IntakeNotes
    }
  }
`;

export const INSTALLER_NOTES_BY_HOUSE = gql`
  ${InstallerNotesFragment}
  query installerNotesByHouse($houseId: ID!) {
    house(id: $houseId) {
      id
      situation {
        id
        ...InstallerNotes
      }
    }
  }
`;

export const SAVE_EDITOR = gql`
  ${IntakeNotesFragment}
  mutation saveIntakeEditor($content: String, $topic: String, $houseId: ID) {
    saveIntakeEditor(content: $content, topic: $topic, houseId: $houseId) {
      id
      ...IntakeNotes
    }
  }
`;

export const CANCEL_INTAKE_APPOINTMENT = gql`
  mutation cancelIntakeAppointment(
    $houseId: ID!
    $cancellationInput: IntakeAppointmentMutationInput!
  ) {
    cancelIntakeAppointment(houseId: $houseId, cancellationInput: $cancellationInput) {
      id
      appointment {
        plannedOn
        plannedFor
        initiallyPlannedOn
        initiallyPlannedFor
      }
    }
  }
`;
