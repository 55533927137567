import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex } from '@energiebespaarders/symbols';
import {
  getSolutionsFromDomain,
  Solution,
  SolutionDomain,
  SOLUTIONS_NL,
} from '@energiebespaarders/constants';

const Tile = styled.div<{ active: boolean }>`
  border-bottom: 3px solid ${x => (x.active ? x.theme.colors.green : x.theme.colors.grayLighter)};
  border-radius: 3px;
  transition: 0.3s;
  text-align: center;
  padding: 12px;

  ${x =>
    x.active
      ? css`
          background-color: ${x.theme.colors.greenSlate};
        `
      : css`
          cursor: pointer;
          &:hover {
            background-color: ${x.theme.colors.grayLight};
          }
        `};
`;

const Title = styled.div<{ active: boolean }>`
  display: inline-block;
  text-transform: uppercase;
  line-height: 1;
  height: 100%;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${x => x.theme.type.scale[5]};
  letter-spacing: 0.025em;
  text-align: center;
  width: 100%;
  opacity: 0.75;
  transition: opacity 0.2s ${x => x.theme.curves.standard};
  cursor: pointer;

  ${x =>
    x.active &&
    `
    opacity: 1;
    font-weight: 500;
  `}
`;

type SolutionSelectorProps = {
  solution: Solution;
  solutionDomain: SolutionDomain;
  setSolution: (s: Solution) => void;
};

const SolutionSelector: React.FC<SolutionSelectorProps> = ({
  solution,
  setSolution,
  solutionDomain,
}) => {
  // Manually sorted
  const sortedSolutions = useMemo(() => {
    if (solutionDomain === SolutionDomain.Heating) {
      const heatSols: Solution[] = getSolutionsFromDomain(solutionDomain);
      const order: Solution[] = [
        Solution.CentralHeatingBoiler,
        Solution.HybridHeatPump,
        Solution.ElectricHeatPump,
        Solution.AirToAirHeatPump,
      ];
      return heatSols.sort((a, b) =>
        !order.includes(a) ? 1 : order.indexOf(a) - order.indexOf(b),
      );
    }
    return getSolutionsFromDomain(solutionDomain);
  }, [solutionDomain]);
  return (
    <>
      <Flex flexWrap="wrap">
        {sortedSolutions.map(s => (
          <Box
            key={`installationSolution-${s}`}
            width={1 / sortedSolutions.length}
            onClick={() => setSolution(s)}
          >
            <Tile active={s === solution}>
              <Title active={s === solution}>{SOLUTIONS_NL[s]}</Title>
            </Tile>
          </Box>
        ))}
      </Flex>
    </>
  );
};

export default SolutionSelector;
