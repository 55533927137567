import { Radio, RadioGroup } from '@energiebespaarders/symbols';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageLoading from '../../components/PageLoading';
import { googleMapsKey } from '../../config';
import useToaster from '../../hooks/useToaster';
import GoogleMapsTooltip from './MapTooltip';
import {
  addAndMergePC3WithRegions,
  findRegionsForPC3,
  initializeMapWithPC3Regions,
  isPC3InZipRegions,
  MapCenter,
  MapContainerStyle,
  splitRegion,
  ZipRegion,
} from './mapUtils';
import usePC3Data from './usePC3Data';

const customContainerStyle = { ...MapContainerStyle, height: '60vh' };

interface IInstallerCoverageMapProps {
  regions: ZipRegion[];
  setRegions: (newRegions: ZipRegion[]) => void;
}

const PC3RegionEditor: React.FC<IInstallerCoverageMapProps> = ({ regions, setRegions }) => {
  const toast = useToaster();

  const [mode, setMode] = useState<'move' | 'add' | 'remove'>('move');

  // Refs needed because the map callbacks don't have access to the updated states for some reason
  const regionsRef = useRef<typeof regions>(regions);
  regionsRef.current = regions;

  const modeRef = useRef<typeof mode>(mode);
  modeRef.current = mode;

  const isDragging = useRef(false);

  const tooltipRef = useRef<GoogleMapsTooltip>();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsKey,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const mapRef = useRef<google.maps.Map>();

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
    mapRef.current = map;
  }, []);

  const handleAction = useCallback((e: google.maps.MapMouseEvent) => {
    const feat = (e as any).feature as google.maps.Data.Feature;

    // Get from ref because the state data is not updated in this callback
    const regions = regionsRef.current;
    const mode = modeRef.current;

    if (['Polygon', 'MultiPolygon'].includes(feat.getGeometry().getType())) {
      if (isDragging.current || e.domEvent.type === 'click') {
        const pc3Code = feat.getProperty('PC3');
        const matchingRegions = findRegionsForPC3(pc3Code, regions);

        if (mode === 'add') {
          if (matchingRegions.length === 0) {
            // if it's an expensive operation, could also do it on-submit. Seems alright though
            setRegions(addAndMergePC3WithRegions(regions, pc3Code));
          }
        } else if (mode === 'remove') {
          if (matchingRegions.length > 0) {
            // For removing a PC3 area, split the matching region in two, then remove any empty region remainders
            const regionToSplit = matchingRegions[0];
            setRegions([
              ...regions.filter(r => r !== regionToSplit),
              ...splitRegion(regionToSplit, pc3Code),
            ]);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Initialize when both map and installer data are ready
    if (!map || !regions) return;

    // Mouse event handlers
    map.data.addListener('mousedown', () => {
      if (modeRef.current !== 'move') {
        isDragging.current = true;
        map.set('draggable', false);
      }
    });
    map.data.addListener('mouseup', () => {
      if (modeRef.current !== 'move') {
        isDragging.current = false;
        map.set('draggable', true);
      }
    });
    map.data.addListener('click', handleAction);
    map.data.addListener('mouseover', handleAction);

    // Tooltip handlers:
    tooltipRef.current = new GoogleMapsTooltip();
    map.data.addListener('mouseover', event => {
      const e = event as google.maps.MapMouseEvent;
      const feat = event.feature as google.maps.Data.Feature;
      const code = feat.getProperty('PC3');
      const tooltipContent = `${code}0 - ${code}9`;
      tooltipRef.current?.inject(e, tooltipContent);
    });
    map.data.addListener('mousemove', e => tooltipRef.current?.move(e));
    map.data.addListener('mouseout', () => tooltipRef.current?.delete());

    // TODO: would be nice to drag while spacebar is pressed
    // map.data.addListener('keydown', (event: google.maps.MapMouseEvent) => ... );

    return () => {
      tooltipRef.current?.delete();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const updateMap = useCallback((regions: ZipRegion[]) => {
    const map = mapRef.current;
    if (map) {
      map.data.setStyle(feature => {
        if (!regions.length) {
          return {
            color: themify('black'),
            strokeWeight: 1,
          };
        }
        const code = feature.getProperty('PC3');
        const isInRegion = isPC3InZipRegions(code, regions);
        return {
          fillColor: isInRegion ? themify('yellow') : themify('black'),
          strokeWeight: 0.2,
        };
      });
    }
  }, []);

  usePC3Data({
    onLoad: data => {
      const interval = setInterval(() => {
        // Map might not have been initialized, so check every now and then if it has
        if (mapRef.current) {
          clearInterval(interval);
          initializeMapWithPC3Regions(mapRef.current, data);
          updateMap(regions);
        }
      }, 200);
    },
    onError: e => {
      console.error(e);
      toast({ message: 'PC3 data kon niet worden geladen: ' + e.name, type: 'error' });
    },
  });

  useEffect(() => {
    updateMap(regions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions]);

  return (
    <>
      {!isLoaded ? (
        <PageLoading />
      ) : (
        <>
          <RadioGroup onChange={setMode} divider={3} value={mode} label="Sleepmodus">
            <Radio bgColor="blue" label="Rondkijken" checked={mode === 'move'} value={'move'} />
            <Radio bgColor="green" label="Toevoegen" checked={mode === 'add'} value={'add'} />
            <Radio bgColor="red" label="Verwijderen" checked={mode === 'remove'} value={'remove'} />
          </RadioGroup>
          <GoogleMap
            mapContainerStyle={customContainerStyle}
            center={MapCenter}
            zoom={8}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{ scaleControl: true }}
          />
        </>
      )}
    </>
  );
};

export default PC3RegionEditor;
