import { Pause, Play, Stop, WrenchScrewdriver } from '@energiebespaarders/symbols/icons/solid';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { rgba } from 'polished';
import { ReactNode } from 'react';
import { ToastType } from '../../hooks/useToaster';
import { InstallerStatus } from '../../types/graphql-global-types';

type InstallerStatusProps = {
  label: string;
  description: string;
  icon: ReactNode;
  color: string;
  bgColor?: string;
  type?: ToastType;
  details: string[];
};

export const InstallerStatusIndicators: Record<InstallerStatus, InstallerStatusProps> = {
  [InstallerStatus.setup]: {
    label: 'Onboarding',
    description: 'Nieuwe IP wordt klaargezet',
    icon: WrenchScrewdriver,
    color: 'blue',
    bgColor: rgba(themify('blue'), 0.2),
    type: 'info',
    details: [
      'Bedoeld voor installateurs waar de gegevens, producten en prijzen nog voor worden ingesteld',
      'Blokkades in Installatron bij het opmaken van offertes voor Sales',
      'Voor NS en Planning is het mogelijk om (test) offertes en opdrachten te maken',
    ],
  },
  [InstallerStatus.active]: {
    label: 'Actief',
    description: 'Standaard',
    icon: Play,
    color: 'greenDark',
    details: ['Normale gang van zaken'],
  },
  [InstallerStatus.paused]: {
    label: 'Gepauzeerd',
    description: 'Geen nieuwe opdrachten',
    icon: Pause,
    color: 'orange',
    bgColor: rgba(themify('orange'), 0.2),
    type: 'warning',
    details: [
      'Bedoeld voor installateurs die het te druk hebben om nieuwe opdrachten aan te nemen',
      'Waarschuwingen in Installatron bij het opmaken van offertes',
      'Sales mag geen offertes versturen naar klanten als er niet een eerdere geaccordeerde offerte voor deze installateur was. NS wel, met een expliciete bevestiging.',
    ],
  },
  [InstallerStatus.inactive]: {
    label: 'Inactief',
    description: 'Samenwerking gestopt',
    icon: Stop,
    color: 'red',
    bgColor: rgba(themify('red'), 0.2),
    type: 'error',
    details: [
      'Bedoeld voor installateurs waar geen zaken (meer) mee worden gedaan, dus ook nieuwe installateurs waar de inrichting nog niet volledig staat in ons systeem',
      'Blokkades in Installatron bij het opmaken van offertes, offertes versturen/accorderen en versturen van opdrachten',
      'E-mail reminders over opdrachten worden niet meer verstuurd',
      'Toegang tot het Installer Account wordt geblokkeerd',
    ],
  },
};

export default InstallerStatusIndicators;
