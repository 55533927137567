import { useMutation } from '@apollo/client';
import { isUndefined } from '@energiebespaarders/constants';
import { Icon, SpinnerOverlay, Table } from '@energiebespaarders/symbols';
import { Relative } from '@energiebespaarders/symbols/helpers';
import { ArrowDown, ArrowUp } from '@energiebespaarders/symbols/icons/solid';
import { padding } from '@energiebespaarders/symbols/styles/mixins';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import useToaster from '../../hooks/useToaster';
import { delimit } from '../../lib/utils';
import { fixUnit } from '../../lib/utils/fixUnit';
import { CHANGE_PRODUCT_ORDER_IN_INSTALLATION } from '../../queries/installatron';
import { advisedQuote_quoteById } from '../../types/generated/advisedQuote';
import {
  installationByHouseSolution_installationByHouseSolution,
  installationByHouseSolution_installationByHouseSolution_items,
} from '../../types/generated/installationByHouseSolution';
import { quoteModalQuote_quoteById } from '../../types/generated/quoteModalQuote';
import ErrorBoundary from '../ErrorBoundary';
import { ProductModal } from '../modals';
import InstallationItemLine from './InstallatronCore/InstallationItemLine';
import NoSupplierControl from './Quote/NoSupplierControl';
import TotalsRows from './TotalsRows';

export const HiddenIcon = styled(Icon)<{ isMobile: boolean; isForQuote: boolean }>`
  opacity: ${x => (x.isMobile ? 1 : 0)};
  vertical-align: middle;
  cursor: help;
  transition: opacity 0.2s ${x => x.theme.curves.standard};
  ${x => !x.isForQuote && 'cursor: pointer;'}
`;

const DeletableRow = styled(Table.Row)`
  &:hover {
    ${HiddenIcon} {
      opacity: 1;
    }
  }
`;

const ClickableCell = styled(Table.Cell)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SlideSpan = styled.span<{ isMobile: boolean }>`
  ${padding(1, 'l')};
  position: absolute;
  transform: ${x => `translateX(${x.isMobile ? 0 : '-20px'})`};
  transition: 0.2s;

  &:hover {
    transform: translateX(0px);
  }
`;

const NameCell = styled(Table.Cell)`
  position: relative;

  &:hover {
    ${SlideSpan} {
      transform: translateX(0px);
    }
  }
`;

type ProductListProps = {
  installation?:
    | (Omit<installationByHouseSolution_installationByHouseSolution, 'items'> & {
        items: Omit<
          installationByHouseSolution_installationByHouseSolution_items,
          'tieredRetailPrice'
        >;
      })
    | advisedQuote_quoteById
    | quoteModalQuote_quoteById;
  items: readonly Omit<
    installationByHouseSolution_installationByHouseSolution_items,
    'tieredRetailPrice'
  >[];
  usePurchasePrices?: boolean;
  fontSize?: number;
  hideTotals?: boolean;
  readOnly?: boolean;
  isForQuote?: boolean;
};

const ProductList: React.FC<ProductListProps> = ({
  installation,
  items,
  usePurchasePrices,
  readOnly,
  hideTotals,
  fontSize,
  isForQuote,
}) => {
  const isMobile = useIsMobile();
  const toast = useToaster();

  const [activeProduct, setActiveProduct] = useState<string | undefined>();
  const [showSubTotals, setShowSubTotals] = useState(false);

  const [changeProductOrder, { loading }] = useMutation(CHANGE_PRODUCT_ORDER_IN_INSTALLATION, {
    onError: () => {
      toast({
        type: 'error',
        message: 'Kon de volgorde niet aanpassen.',
      });
    },
  });

  const getNewProductOrder = (
    items: readonly Omit<
      installationByHouseSolution_installationByHouseSolution_items,
      'tieredRetailPrice'
    >[],
    direction: 'up' | 'down',
    index: number,
  ) => {
    const newItems = [...items];
    switch (direction) {
      case 'up': {
        if (index === 0) {
          throw new Error('Can not move first product up');
        }
        const item = items[index];
        const itemAbove = items[index - 1];
        newItems[index - 1] = item;
        newItems[index] = itemAbove;
        return items.map(i => i.product.id);
      }
      case 'down': {
        if (index === items.length - 1) {
          throw new Error('Can not move last product down');
        }
        const item = items[index];
        const itemBelow = items[index + 1];
        newItems[index + 1] = item;
        newItems[index] = itemBelow;
        return items.map(i => i.product.id);
      }
      default:
        throw new Error('Invalid direction specified');
    }
  };

  const commonIconProps = useMemo(
    () => ({
      isForQuote: !!isForQuote,
      isMobile: isMobile,
      solid: true,
      fill: 'gray',
      hoverColor: 'blue',
      width: '1em',
      draggable: true,
    }),
    [isForQuote, isMobile],
  );

  return (
    <>
      {loading && <SpinnerOverlay />}
      <Table
        fontSize={fontSize ?? (isMobile ? 6 : 5)}
        mobile={isMobile}
        defaultPadding={isMobile ? 1 : 2}
        defaultOverflow="ellipsis"
        layout={[
          (readOnly ? 12 : 11) / 24,
          2 / 24,
          5 / 48,
          5 / 48,
          4 / 24,
          readOnly ? 0 : 1 / 24,
          readOnly ? 0 : 1 / 24,
        ]}
      >
        <Table.Row isHeader>
          <Table.Cell>Product</Table.Cell>
          <Table.Cell textAlign="center">Aantal</Table.Cell>
          <Table.Cell textAlign="center">Eenheid</Table.Cell>
          <Table.Cell textAlign="center">BTW</Table.Cell>
          <ClickableCell textAlign="right" onClick={() => setShowSubTotals(!showSubTotals)}>
            {showSubTotals ? 'Subtotaal / prijs' : 'Prijs / subtotaal'}
          </ClickableCell>
          <Table.Cell cellPadding={0} hide={readOnly} />
          <Table.Cell cellPadding={0} hide={readOnly} />
        </Table.Row>

        {items
          .filter(item => item.product)
          .map((item, index) => {
            const product = item.product;
            return (
              <DeletableRow key={`item-${index}-${item.product.id}`}>
                <NameCell onClick={() => item.product && setActiveProduct(item.product.id)}>
                  {item.supplier ? (
                    <InstallationItemLine
                      installationId={installation?.id || ''}
                      item={item}
                      isForQuote={!!isForQuote}
                      solution={installation?.solution}
                      readOnly={!!readOnly}
                    />
                  ) : (
                    <NoSupplierControl
                      item={item}
                      installationId={installation?.id || ''}
                      solution={installation?.solution}
                    />
                  )}
                </NameCell>
                <Table.Cell cellPadding={readOnly ? 2 : 0} textAlign="center">
                  {item.amount}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {product.priceUnit === 'unit' ? 'stuks' : fixUnit(product.priceUnit)}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {!isUndefined(product.tax) ? (product.tax ?? 0) * 100 : '-'}%
                </Table.Cell>
                <Table.Cell cellPadding={readOnly || showSubTotals ? 2 : 0} textAlign="right">
                  {showSubTotals ? (
                    `€ ${delimit(
                      (usePurchasePrices ? item.purchasePrice : item.retailPrice) * item.amount,
                    )}`
                  ) : (
                    <Relative>
                      {`€ ${delimit(usePurchasePrices ? item.purchasePrice : item.retailPrice)}`}
                    </Relative>
                  )}
                </Table.Cell>
                <Table.Cell cellPadding={isMobile ? 0 : 1} hide={readOnly} textAlign="right">
                  {installation && (
                    <>
                      {index !== 0 && (
                        <>
                          <HiddenIcon
                            {...commonIconProps}
                            icon={ArrowUp}
                            onClick={() => {
                              const order = getNewProductOrder(items, 'up', index);
                              changeProductOrder({
                                variables: {
                                  installationId: installation.id,
                                  productOrder: order,
                                },
                              });
                            }}
                          />
                          <br />
                        </>
                      )}
                      {index !== items.length - 1 && (
                        <HiddenIcon
                          {...commonIconProps}
                          icon={ArrowDown}
                          onClick={() => {
                            const order = getNewProductOrder(items, 'down', index);
                            changeProductOrder({
                              variables: {
                                installationId: installation.id,
                                productOrder: order,
                              },
                            });
                          }}
                        />
                      )}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell hide />
              </DeletableRow>
            );
          })}
      </Table>
      {!hideTotals && !usePurchasePrices && (
        <ErrorBoundary>
          <TotalsRows
            discount={installation?.discount}
            items={items}
            mobile={isMobile}
            readOnly={!!readOnly}
          />
        </ErrorBoundary>
      )}

      {!!activeProduct && (
        <ProductModal
          isOpen={Boolean(activeProduct)}
          productId={activeProduct}
          closeModal={() => setActiveProduct(undefined)}
          setActiveProduct={p => setActiveProduct(p)}
        />
      )}
    </>
  );
};

export default ProductList;
