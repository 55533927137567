import { gql } from '@apollo/client';
import DiscountFragment from './Discount';
import EnergyDeltaFragment from './EnergyDelta';
import { ExportConstraintMessagesFragment } from './Quote';

export const InstallationItemFragment = gql`
  fragment InstallationItem on InstallationItem {
    amount
    # The price at the time of this product being added to the installation
    retailPrice
    purchasePrice
    product {
      id
      title
      tax
      solution
      category
      priceUnit
      customMarkup
      isdeCode
      archived
      ... on HeatPump {
        subsidy
      }
    }
    supplier {
      id
      name
    }
    price {
      id
      # The current "live" price
      retailPrice
      purchasePrice
      availability
    }
    tieredRetailPrice
  }
`;

export const InstallationFragment = gql`
  ${InstallationItemFragment}
  ${EnergyDeltaFragment}
  ${DiscountFragment}
  ${ExportConstraintMessagesFragment}
  fragment Installation on Installation {
    id
    solution
    energyDelta {
      ...EnergyDelta
    }
    comfortScore
    discount {
      ...Discount
    }
    investment
    investmentExTax
    subsidy
    paybackTime
    items {
      ...InstallationItem
    }
    exportConstraintMessages {
      ...ExportConstraintMessages
    }
  }
`;
