import React from 'react';
import { Helmet } from 'react-helmet-async';
import { usePageTitle } from '../hooks/usePageTitle';

export const AppHelmet: React.FC = () => {
  const { pageTitle, setPageTitle } = usePageTitle();
  return (
    <Helmet
      defaultTitle="Energiebeheerders"
      titleTemplate="%s • Energiebeheerders"
      onChangeClientState={newState => {
        const newPageTitle = newState.title.split(' • ')[0];
        if (newPageTitle !== pageTitle) setPageTitle(newPageTitle);
      }}
    >
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=2, user-scalable=no"
      />
      <meta name="theme-color" content="#29cc96" />
      <meta property="og:type" content="website" />

      {/* For non-Retina iPhone, iPod Touch, and Android 2.1+ devices: */}
      <link rel="apple-touch-icon-precomposed" href="apple-touch-icon-precomposed.png" />
      {/* For the iPad mini and the first- and second-generation iPad on iOS ≤ 6: */}
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href="launcher-icon-72x72.png" />
      {/* For the iPad mini and the first- and second-generation iPad on iOS ≥ 7: */}
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href="launcher-icon-76x76.png" />
      {/* For iPhone with high-resolution Retina display running iOS ≤ 6: */}
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href="launcher-icon-114x114.png" />
      {/* For iPhone with high-resolution Retina display running iOS ≥ 7: */}
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href="launcher-icon-120x120.png" />
      {/* For iPad with high-resolution Retina display running iOS ≤ 6: */}
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href="launcher-icon-144x144.png" />
      {/* For iPad with high-resolution Retina display running iOS ≥ 7: */}
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href="launcher-icon-152x152.png" />
      {/* Chrome v31+ for Android */}
      <link rel="icon" sizes="192x192" href="launcher-icon-192.png" />
      {/* Apple "pinned tab" icons */}
      <link rel="mask-icon" href="launcher-icon.svg" color="#29cc96" />
    </Helmet>
  );
};

export default AppHelmet;
