import { useMutation } from '@apollo/client';
import { Button, Icon, Separator } from '@energiebespaarders/symbols';
import { FileEuro, Warning } from '@energiebespaarders/symbols/icons/solid';
import React from 'react';
import { isProduction } from '../../../config';
import useToaster from '../../../hooks/useToaster';
import { SEND_JOB_TO_GRIPP } from '../../../queries/installatron';
import {
  jobDomainQuote_quoteById,
  jobDomainQuote_quoteById_jobs,
} from '../../../types/generated/jobDomainQuote';
import {
  sendJobToGripp as sendJobToGrippMutation,
  sendJobToGrippVariables,
} from '../../../types/generated/sendJobToGripp';

interface PurchaseOrderControlsProps {
  job: jobDomainQuote_quoteById_jobs;
  quote: jobDomainQuote_quoteById;
}

const PurchaseOrderControls: React.FC<PurchaseOrderControlsProps> = ({ job, quote }) => {
  const toast = useToaster();
  const [
    sendJobToGripp,
    { loading: sendJobToGrippLoading, error: sendJobToGrippError },
  ] = useMutation<sendJobToGrippMutation, sendJobToGrippVariables>(SEND_JOB_TO_GRIPP, {
    onCompleted: ({ sendJobToGripp }) => {
      if (sendJobToGripp.projectGrippId) {
        toast({
          type: 'success',
          message: 'Verkooporder aangemaakt',
        });
      }
    },
  });

  return (
    <>
      <Separator my={1} />
      <Button
        bgColor="blue"
        label={'Verkooporder maken'}
        fluid
        inverse
        fontSize={6}
        iconStart={FileEuro}
        minIconSize="1rem"
        disabled={Boolean(job.projectGrippId)}
        loading={sendJobToGrippLoading}
        error={sendJobToGrippError?.message}
        onClick={() => {
          if (
            window.confirm(
              `Weet je zeker dat je de Job voor ${quote.reference} naar Gripp wilt sturen? \n` +
                'Dit gebeurt normaal gesproken automatisch bij het versturen van de opdracht. \n' +
                `De job zal worden aangemaakt voor ${job.installer.name}. ` +
                'Eenmaal in Gripp kan de Job niet meer aangepast worden.',
            )
          ) {
            sendJobToGripp({
              variables: {
                jobId: job.id,
              },
            }).catch(e => console.error(e));
          }
        }}
      />
      {job.projectGrippId ? (
        <small>
          Er bestaat al een{' '}
          <a
            href={`https://energiebespaarders${
              isProduction ? '' : '-dev'
            }.gripp.com/d/project/view/${job.projectGrippId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            verkooporder
          </a>
          . Deze kan niet meer worden aangepast.
        </small>
      ) : (
        <small>
          <Icon icon={Warning} solid color="orange" /> <b>Let op</b>: Deze actie gebeurt normaal
          gesproken automatisch bij het versturen van de opdracht.
          {/* <Link to="/review">beoordelen van de uitvoer</Link> */}
        </small>
      )}
    </>
  );
};

export default PurchaseOrderControls;
