import { gql } from '@apollo/client';

const quoteTemplateFragment = gql`
  fragment QuoteTemplate on QuoteTemplate {
    id
    title
    description
    isActive
    createdOn
    modifiedOn
    supplier {
      id
      name
    }
    items {
      amount
      product {
        id
        title
        category
        archived
      }
      supplier {
        id
        name
      }
      price {
        id
        retailPrice
        purchasePrice
        availability
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
    modifiedBy {
      id
      firstName
      lastName
    }
  }
`;
export const INSTALLERS_AND_SUPPLIERS_BY_SOLUTION = gql`
  query installersAndSuppliersBySolution($solution: Solution!, $houseId: ID!) {
    installersBySolution(solution: $solution) {
      id
      name
      status {
        value
      }
      capableOfInstallingAtHouse(houseId: $houseId)
      pairedSupplierId
    }
  }
`;
export const AVAILABLE_QUOTE_TEMPLATES = gql`
  ${quoteTemplateFragment}

  query availableQuoteTemplatesBySolution(
    $solution: Solution!
    $address: BasicAddressInput!
    $isActive: Boolean
  ) {
    quoteTemplates(isActive: $isActive, solution: $solution, isAvailableAtAddress: $address) {
      ...QuoteTemplate
    }
  }
`;
export const CREATE_TEMPLATE = gql`
  ${quoteTemplateFragment}

  mutation createQuoteTemplate($template: QuoteTemplateInput!, $operatorId: ID!) {
    createQuoteTemplate(template: $template, operatorId: $operatorId) {
      ...QuoteTemplate
    }
  }
`;
export const UPDATE_TEMPLATE = gql`
  ${quoteTemplateFragment}

  mutation updateQuoteTemplate($templateId: ID!, $template: QuoteTemplateInput!, $operatorId: ID!) {
    updateQuoteTemplate(templateId: $templateId, template: $template, operatorId: $operatorId) {
      ...QuoteTemplate
    }
  }
`;
export const DELETE_TEMPLATE = gql`
  mutation deleteQuoteTemplate($templateId: ID!) {
    deleteQuoteTemplate(templateId: $templateId)
  }
`;
