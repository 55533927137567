import React from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_HOUSE_FILES, DELETE_HOUSE_FILE } from '../queries/files';
import { Placeholder } from '@energiebespaarders/symbols';
import ImagePicker from './ImagePicker';

/** Inspired by HouseGallery */
const HouseImagePicker = ({
  exclusiveMatch,
  houseId,
  readOnly,
  tags,
  selectedFile,
  setSelectedFile,
}) => (
  <Query query={GET_HOUSE_FILES} variables={{ houseId }}>
    {({ loading, data, error }) => {
      if (loading || error) return <Placeholder error={error} />;
      const files = (data.house && data.house.files) || [];
      return (
        <Mutation mutation={DELETE_HOUSE_FILE}>
          {deleteHouseFile => (
            <ImagePicker
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              deleteFile={
                readOnly
                  ? null
                  : fileId => {
                      deleteHouseFile({
                        variables: {
                          id: houseId,
                          fileId,
                        },
                        update: store => {
                          const queryData = store.readQuery({
                            query: GET_HOUSE_FILES,
                            variables: { houseId },
                          });
                          const newFiles = queryData.house.files.filter(file => file.id !== fileId);
                          store.writeQuery({
                            query: GET_HOUSE_FILES,
                            data: {
                              house: {
                                ...queryData.house,
                                files: newFiles,
                              },
                            },
                            variables: { houseId },
                          });
                        },
                      });
                    }
              }
              exclusiveMatch={exclusiveMatch}
              files={files}
              gutter={1}
              size="ss"
              tags={tags}
              thumbSize={100}
            />
          )}
        </Mutation>
      );
    }}
  </Query>
);

HouseImagePicker.propTypes = {
  houseId: PropTypes.string.isRequired,
  selectedFile: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setSelectedFile: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
};

HouseImagePicker.defaultProps = {
  exclusiveMatch: true,
};

export default HouseImagePicker;
