export type DropdownOption<T> = { label: string; value: T; disabled?: boolean };

interface ManualJourneyData {
  utm: {
    source?: string;
    medium?: string;
  };
  referrer?: string;
  pageViews: string[];
}

export const MANUAL_JOURNEY_DATA: ManualJourneyData = {
  utm: {
    source: 'energiebeheerders',
    medium: 'manual',
  },
  referrer: '',
  pageViews: [],
};
