import { useQuery } from '@apollo/client';
import { Box, Button, Flex, Icon, Tooltip } from '@energiebespaarders/symbols';
import { Red } from '@energiebespaarders/symbols/helpers';
import { Block, Mail, MailOpen } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React from 'react';
import {
  getEmailsEventsByEntityIdAndTemplate,
  getEmailsEventsByEntityIdAndTemplateVariables,
} from '../../../types/generated/getEmailsEventsByEntityIdAndTemplate';
import { MailjetTemplateDescriptor } from '../../../types/graphql-global-types';
import { GET_EMAILS_BY_ENTITY_ID_AND_TEMPLATE } from '../queries';

interface ChaseInstallerProps {
  jobId: string;
  template: MailjetTemplateDescriptor;
  sendEmail: any;
  emailSending: boolean;
  message: string;
  buttonLabel?: string;
}
const ChaseInstaller: React.FC<ChaseInstallerProps> = ({
  jobId,
  template,
  sendEmail,
  emailSending,
  message,
  buttonLabel = 'Herinneringsmail sturen',
}) => {
  const { data, loading, error } = useQuery<
    getEmailsEventsByEntityIdAndTemplate,
    getEmailsEventsByEntityIdAndTemplateVariables
  >(GET_EMAILS_BY_ENTITY_ID_AND_TEMPLATE, {
    variables: { entityId: jobId, template },
  });

  return (
    <div>
      <Button
        iconStart={Mail}
        bgColor="blue"
        inverse
        onClick={sendEmail}
        loading={emailSending}
        disabled={emailSending}
        fluid
      >
        {emailSending ? 'Laden...' : buttonLabel}
      </Button>
      <Box p={1}>
        <p>{message}</p>
      </Box>
      {loading ? (
        <p>'...'</p>
      ) : error ? (
        <Red>{error.message}</Red>
      ) : data ? (
        data.getEmailsEventsByEntityIdAndTemplate.map((email, i) => (
          <Flex
            key={`Email event history ${email.id} ${i}`}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              Herinnering verstuurd op {dayjs(email.sent).format('DD/MM/YY') || 'Onbekend'}.
            </Box>
            <Box>
              {email.open ? (
                <Tooltip
                  bgColor="orange"
                  content={`Geopend op ${dayjs(email.open).format('DD/MM/YY')}`}
                >
                  <Icon icon={MailOpen} solid fill="orange" />
                </Tooltip>
              ) : (
                <Tooltip bgColor="gray" content={'Nog niet geopend'}>
                  <Icon icon={MailOpen} solid fill="gray" />
                </Tooltip>
              )}
              {email.blocked || email.bounce ? (
                <Tooltip bgColor="red" content={'Mail bounced/geblockeerd'}>
                  <Icon icon={Block} solid fill="red" />
                </Tooltip>
              ) : (
                <></>
              )}
            </Box>
          </Flex>
        ))
      ) : (
        <Red>No data</Red>
      )}
    </div>
  );
};

export default ChaseInstaller;
