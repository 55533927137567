import { houseCard_house_situation_energyPrices } from './../../types/generated/houseCard';
import { constants } from '@energiebespaarders/constants';
import { EnergyPrices } from '../../components/modals/EnergyPricesModal';

export const completeEnergyPrices = (
  personalEnergyPrices: houseCard_house_situation_energyPrices | undefined,
): EnergyPrices => ({
  gas: personalEnergyPrices?.gas ?? constants.energyPrices.gas,
  electricity: personalEnergyPrices?.electricity ?? constants.energyPrices.electricity,
  discountedElectricity:
    personalEnergyPrices?.discountedElectricity ?? constants.energyPrices.discountedElectricity,
  gasNetworkOperator:
    personalEnergyPrices?.gasNetworkOperator ?? constants.energyPrices.gasNetworkOperator,
});
