import React, { useCallback } from 'react';
import { Button } from '@energiebespaarders/symbols';
import { Alert } from '@energiebespaarders/symbols/icons/solid';
import { gql, useMutation } from '@apollo/client';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';

const RESET_QUOTES = gql`
  mutation resetQuotesForTraining($houseId: ID!) {
    resetQuotesForTrainingAccount(houseId: $houseId)
  }
`;

const ResetQuotesForTraining: React.FC = () => {
  const { activeHouseId } = useActiveHouseId();
  const [resetQuotes] = useMutation(RESET_QUOTES, { variables: { houseId: activeHouseId } });
  const handleReset = useCallback(() => {
    if (
      window.confirm('Weet je zeker dat je ALLE offertes, deals en opdrachten wilt verwijderen?')
    ) {
      resetQuotes();
    }
  }, [resetQuotes]);
  return (
    <Button iconStart={Alert} iconAnimation="pulse" bgColor="red" onClick={handleReset}>
      Alle offertes verwijderen
    </Button>
  );
};

export default ResetQuotesForTraining;
