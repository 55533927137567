import { useLazyQuery } from '@apollo/client';
import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import { isProduction } from '../config';
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';
import { useIsInitializing } from '../hooks/useIsInitializing';
import { useMe } from '../hooks/useMe';
import { GET_ME } from '../queries/me';
import { me as meQuery } from '../types/generated/me';
import PageLoading from './PageLoading';

export const AppInitializer: React.FC = ({ children }) => {
  const { setMe } = useMe();
  const { setIsInitializing } = useIsInitializing();
  const { setIsAuthenticated } = useIsAuthenticated();

  const [getMe, { data: meData, loading, error }] = useLazyQuery<meQuery>(GET_ME, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.me.__typename === 'Operator') {
        setMe(data.me);
        setIsAuthenticated(true);

        const { id, firstName, lastName, email, jobTitle } = data.me;

        if (isProduction) {
          LogRocket.identify(id, {
            name: `${firstName} ${lastName}`,
            email,
            // Add your own custom user variables here
            jobTitle: jobTitle || '',
          });
        }
      } else {
        setMe(undefined);
        setIsAuthenticated(false);
      }
      setIsInitializing(false);
    },
  });

  useEffect(() => {
    if (!meData && !loading && !error) {
      getMe();
    } else if (!loading) {
      setIsInitializing(false);
    }
  }, [error, getMe, loading, meData, setIsInitializing]);

  return loading ? <PageLoading /> : <>{children}</>;
};

export default AppInitializer;
