import { Box, Flex, Icon } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import { Cancel, CheckCircle, Info, Warning } from '@energiebespaarders/symbols/icons/solid';
import { SpacingProps } from '@energiebespaarders/symbols/lib/hoc/withSpacing';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { ToastType } from '../../hooks/useToaster';

const StyledBanner = styled(Box)<{ $color: string; $bgColor: string; $borderColor: string }>`
  background: ${x => rgba(x.$bgColor, 0.2)};
  color: ${x => x.$color};
  border: 0.5px solid ${x => x.$borderColor};
  border-radius: 6px;
`;

interface SimpleBannerProps extends SpacingProps {
  type: ToastType;
  message: string;
}

const SimpleBanner: React.FC<SimpleBannerProps> = ({ type, message, ...spacingProps }) => {
  let borderColor = themify('blueLighter');
  let bgColor = themify('blue');
  let color = themify('blueDark');
  let icon = Info;
  switch (type) {
    case 'error':
      borderColor = themify('redLighter');
      bgColor = themify('red');
      color = themify('redDark');
      icon = Cancel;
      break;
    case 'warning':
      borderColor = themify('orangeLighter');
      bgColor = themify('orange');
      color = themify('orangeDark');
      icon = Warning;
      break;
    case 'success':
      borderColor = themify('greenLighter');
      bgColor = themify('green');
      color = themify('greenDark');
      icon = CheckCircle;
      break;
  }
  return (
    <StyledBanner
      $color={color}
      $bgColor={bgColor}
      $borderColor={borderColor}
      p={2}
      mb={1}
      {...spacingProps}
    >
      <Flex mx={-1}>
        <Box width="2em" px={1}>
          <Icon icon={icon} fill={bgColor} verticalAlign="text-bottom" width="1.25em" />
        </Box>
        <Box width={1} px={1}>
          <Medium>{message}</Medium>
        </Box>
      </Flex>
    </StyledBanner>
  );
};

export default SimpleBanner;
