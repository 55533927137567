import { useLazyQuery, useQuery } from '@apollo/client';
import { SOLUTIONS_NL, Solution, getDomainFromSolution } from '@energiebespaarders/constants';
import { Accordion, Box, Button, Card, Flex, Placeholder } from '@energiebespaarders/symbols';
import { Center, Gray, Red, Small } from '@energiebespaarders/symbols/helpers';
import { ArrowRight } from '@energiebespaarders/symbols/icons/line';
import { SpecialCheck, Sync, Wrench } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DEALS_BY_SOLUTION, INSTALLATION_BY_HOUSE_SOLUTION } from '../../queries/installatron';
import {
  dealsByHouseSolution_dealsByHouseSolution_quotes as TQuote,
  dealsByHouseSolution,
  dealsByHouseSolutionVariables,
} from '../../types/generated/dealsByHouseSolution';
import {
  installationByHouseSolution,
  installationByHouseSolutionVariables,
} from '../../types/generated/installationByHouseSolution';
import { JobType } from '../../types/graphql-global-types';
import { MarkDealLostModal } from '../modals';
import DealControl from './Deal/DealControl';
import DealTitle from './Deal/DealTitle';
import Quote from './Quote';

interface SolutionStatusProps {
  hasDeals: boolean;
  isMobile: boolean;
  openModal: (quoteId: string) => void;
  solution: Solution;
}

const SolutionStatus: React.FC<SolutionStatusProps> = ({
  hasDeals,
  isMobile,
  openModal,
  solution,
}) => {
  const numQuotesToShow = 5;
  const [isOpen, setIsOpen] = useState(hasDeals);
  const match = useRouteMatch<{ houseId: string }>();
  const houseId = match.params.houseId;
  const [loseDealId, setLoseDealId] = useState<string | undefined>(undefined);

  const { data, loading, error } = useQuery<
    installationByHouseSolution,
    installationByHouseSolutionVariables
  >(INSTALLATION_BY_HOUSE_SOLUTION, { variables: { houseId, solution } });

  const [
    fetchDealsBySolution,
    { data: dealsData, loading: dealsLoading, error: dealsError },
  ] = useLazyQuery<dealsByHouseSolution, dealsByHouseSolutionVariables>(DEALS_BY_SOLUTION, {
    fetchPolicy: 'cache-and-network',
    variables: { houseId, solution },
  });

  useEffect(() => {
    if (isOpen && !dealsData && !dealsLoading && !dealsError) fetchDealsBySolution();
  }, [dealsData, dealsError, dealsLoading, fetchDealsBySolution, isOpen]);

  const dealsWithAcceptedQuote = useMemo(
    () =>
      dealsData
        ? dealsData.dealsByHouseSolution.filter(
            deal => deal.quotes.some(quote => quote.isAccepted && !quote.isCancelled),
            [] as TQuote[],
          )
        : [],
    [dealsData],
  );

  useEffect(() => {
    if (dealsData?.dealsByHouseSolution.some(deal => deal.quotes.length > 0)) {
      setIsOpen(true);
    }
  }, [data, setIsOpen, dealsData, houseId, solution]);

  if (!data || loading || error) return <Placeholder error={error} />;

  const installation = data.installationByHouseSolution;
  const dealWithAcceptedQuote =
    dealsWithAcceptedQuote.length > 0 ? dealsWithAcceptedQuote[0] : null;

  return (
    <Card p={0} mb={2} animation="none" style={{ overflowY: 'hidden' }}>
      <Accordion
        baseBgColor="white"
        bgColor="grayLighter"
        contentBorderColor="grayLight"
        contentPadding={isMobile ? 2 : 4}
        override
        defaultOpen={false}
        isOpened={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        icon={dealWithAcceptedQuote ? SpecialCheck : Wrench}
        iconColor={dealWithAcceptedQuote ? 'green' : installation ? 'grayDarker' : 'gray'}
        title={SOLUTIONS_NL[solution]}
        titleSize={5}
      >
        {dealsWithAcceptedQuote.length > 1 && (
          <Red>
            Er zijn meerdere deals met geaccordeerde offertes voor {SOLUTIONS_NL[solution]}!
          </Red>
        )}
        <Flex flexWrap="wrap" mx={-1}>
          {dealsData?.dealsByHouseSolution.map(deal => {
            const { id, quotes, reference, onHold, activeQuote, noLongerActive } = deal;
            return quotes.length === 0 ? (
              <></>
            ) : (
              <Box
                width={[1]}
                alignSelf="center"
                mt={[2, 2, 0]}
                px={1}
                key={`solution status ${solution} ${reference}`}
              >
                <DealTitle deal={deal} openLoseDealModal={() => setLoseDealId(deal.id)} />
                {/* This check should be unnecessary */}
                {activeQuote ? (
                  <DealControl deal={deal} />
                ) : (
                  /* This <p /> should be unnecessary */
                  <p>No relevant quote</p>
                )}
                <Flex flexWrap="wrap">
                  {quotes && quotes.length > 0 ? (
                    [...quotes]
                      .sort(
                        (a, b) =>
                          Number(b.isAccepted) - Number(a.isAccepted) ||
                          Number(b.isSent) - Number(a.isSent) ||
                          b.created - a.created,
                      )
                      .filter(
                        (quote, index) => quote.solution === solution && index < numQuotesToShow,
                      )
                      .map(quote => {
                        const ptcJobs = deal.quotes.flatMap(quote =>
                          quote.jobs.filter(job => job.type === JobType.PTC && job.completed),
                        );
                        const mostRecentlyCompletedPtc = ptcJobs
                          .map(job => job.completed)
                          .sort()[0];
                        const isAfterPtc = dayjs(quote.created).isAfter(
                          dayjs(mostRecentlyCompletedPtc),
                        );
                        return (
                          <Box width={1} key={`${solution}-${quote.id}`}>
                            <Quote
                              quote={{
                                ...quote,
                                onHold: !!onHold?.since,
                                deal: { id, noLongerActive, __typename: 'Deal' },
                              }}
                              isAfterPtc={isAfterPtc}
                              isMobile={isMobile}
                              openModal={() => openModal(quote.id)}
                              houseId={houseId}
                              redundant={Boolean(dealWithAcceptedQuote && !quote.isAccepted)}
                              solution={solution}
                              solutionDomain={getDomainFromSolution(solution)}
                            />
                          </Box>
                        );
                      })
                  ) : (
                    <Box width={1}>
                      <Small>
                        <Gray>
                          Er zijn nog geen deals voor {SOLUTIONS_NL[solution].toLowerCase()}
                        </Gray>
                      </Small>
                    </Box>
                  )}
                </Flex>
              </Box>
            );
          })}
        </Flex>

        <Center block>
          <Button
            bgColor="grayDark"
            fontSize={6}
            iconStart={Sync}
            minimal
            onClick={() => fetchDealsBySolution()}
            label="Verversen"
            mb={0}
            mt={2}
          />
          <Button
            bgColor="grayDark"
            fontSize={6}
            iconEnd={ArrowRight}
            label={installation ? 'Ga naar installatie' : 'Maak een installatie'}
            minimal
            to={`${match.url}/${getDomainFromSolution(solution)}`}
            mb={0}
            mt={2}
          />
        </Center>
      </Accordion>
      {dealsData && Boolean(loseDealId) && (
        <MarkDealLostModal
          deal={dealsData.dealsByHouseSolution.find(deal => deal.id === loseDealId)!}
          closeModal={() => setLoseDealId(undefined)}
        />
      )}
    </Card>
  );
};

export default SolutionStatus;
