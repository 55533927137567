import React, { useRef, ChangeEvent } from 'react';
import styled from 'styled-components';
import { Icon } from '@energiebespaarders/symbols';
import { Search } from '@energiebespaarders/symbols/icons/solid';
import { padding, pos } from '../../styles/mixins';
import { mediaMax } from '../../styles/breakpoints';
import { useNavHelpers } from '../../hooks/useNavHelpers';

interface StyledSearchBarProps {
  $visible: boolean;
  $bgColor: string;
  $narrowScreen: boolean;
}

export const StyledSearch = styled.input<StyledSearchBarProps>`
  ${x => padding(x.$narrowScreen ? 0 : 8, 'left')};
  appearance: none;
  position: absolute;
  left: 0;
  display: ${x => (x.$visible ? 'inline-block' : 'none')};
  width: ${x => `calc(100% - (48px * ${x.$narrowScreen ? 2 : 1}))`};
  height: 100%;
  background: ${x => x.$bgColor};
  border: 0;
  font-size: ${x => x.theme.type.scale[4]};
  opacity: ${x => (x.$narrowScreen ? 1 : 0.5)};
  z-index: 1;
  transition: all 0.2s ${x => x.theme.curves.standard};

  &::-webkit-search-cancel-button {
    display: none;
  }

  &::placeholder {
    color: ${x => x.theme.colors.grayDark};
  }

  &:focus,
  &:active,
  &:not([value='']) {
    opacity: 1;

    &::placeholder {
      color: ${x => x.theme.colors.gray};
    }

    ${mediaMax.md} {
      background: white;
    }
  }
`;

const SearchBarIcon = styled(Icon)`
  ${pos('left', 2)};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`;

interface SearchBarProps {
  'data-tour': string;
  narrowScreen: boolean;
  handleLiveSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  searchString: string;
  visible: boolean;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  'data-tour': tourStepName,
  narrowScreen,
  handleLiveSearch,
  searchString,
  visible,
}) => {
  const searchRef = useRef(null);
  const { searchActive, setSearchActive } = useNavHelpers();

  return (
    <div onClick={() => setSearchActive?.(true)}>
      {!narrowScreen && (
        <label htmlFor="searchBar">
          <SearchBarIcon
            icon={Search}
            fill={searchActive ? 'purple' : 'grayDark'}
            inline
            solid
            width="24px"
            height="24px"
          />
        </label>
      )}
      <StyledSearch
        $bgColor={narrowScreen && searchActive ? 'white' : 'none'}
        $narrowScreen={narrowScreen}
        $visible={visible}
        autoComplete="off"
        data-tour={tourStepName}
        id="searchBar"
        onChange={handleLiveSearch}
        placeholder="Zoek een klant of woning"
        ref={searchRef}
        role="search"
        type="search"
        value={searchString}
      />
    </div>
  );
};

export default SearchBar;
