import { Box, Flex, Icon, Table, Tooltip } from '@energiebespaarders/symbols';
import { Color, FontWeight, Right, Small } from '@energiebespaarders/symbols/helpers';
import { StocksDown, StocksUp } from '@energiebespaarders/symbols/icons/solid';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { sum } from 'lodash';
import { ReactNode, useCallback } from 'react';
import { useHasPricingPermission } from '../../../hooks/useHasPricingPermission';
import { delimit, fixUnit } from '../../../lib/utils';
import { installationByHouseSolution_installationByHouseSolution_items as t_item } from '../../../types/generated/installationByHouseSolution';
import { PriceType, recalculateInstallationPrices } from './recalculateInstallationPrices';

import styled from 'styled-components';

const TableWrapper = styled(Box)<{ $contentWidth: number }>`
  overflow-x: auto;
  > * {
    min-width: ${x => x.$contentWidth}px;
  }
`;

type ModifiableItem = t_item & {
  newRetailPrice: number;
  newPurchasePrice: number;
  include: boolean;
};

interface IColumn<T, P> {
  header: string;
  width: number;
  cell: (item: T, updateItem: (item: T) => void, tableProps: P) => ReactNode;
  footer?: (items: T[], tableProps: P) => ReactNode;
}

const getShownCurrentPrice = (item: ModifiableItem, targetPriceType: PriceType) =>
  targetPriceType === 'retail' ? item.retailPrice : item.purchasePrice;
const getShownTargetPrice = (item: ModifiableItem, targetPriceType: PriceType) =>
  targetPriceType === 'retail' ? item.newRetailPrice : item.newPurchasePrice;

const columns: IColumn<
  ModifiableItem,
  { targetPriceType: PriceType; isApplyingToBoth: boolean; hasMinimumRate: boolean }
>[] = [
  {
    header: 'Product',
    width: 15 / 32,
    cell: function Product(item, update) {
      return (
        <Flex flexWrap="nowrap">
          <input
            type="checkbox"
            checked={item.include}
            id={item.product.id}
            onChange={e => update({ ...item, include: e.target.checked })}
            style={{ margin: 'auto 0.5rem auto 0' }}
          />{' '}
          <label htmlFor={item.product.id}>{item.product.title}</label>
        </Flex>
      );
    },
    footer: function Total(_, { hasMinimumRate }) {
      const hasPricingPermission = useHasPricingPermission();
      return (
        <strong>
          {hasMinimumRate || hasPricingPermission ? (
            <>
              <Icon icon={StocksDown} solid mr={1} />
              Totaal (inkoop):
            </>
          ) : (
            <br />
          )}
          <br />
          <Icon icon={StocksUp} solid mr={1} />
          Totaal (verkoop):
          <br />
          <br />
          <br />
        </strong>
      );
    },
  },
  {
    header: 'Aantal',
    width: 3 / 32,
    cell: function Amount(item) {
      return (
        <>
          {item.amount} {fixUnit(item.product.priceUnit)}
        </>
      );
    },
  },
  {
    header: 'Huidig enkel',
    width: 3 / 32,
    cell: function CurrentSinglePrice(item, _, { targetPriceType }) {
      return <Right block>€ {delimit(getShownCurrentPrice(item, targetPriceType), 2)}</Right>;
    },
  },
  {
    header: 'Nieuw enkel',
    width: 3 / 32,
    cell: function Amount(item, _, { targetPriceType }) {
      return <Right block>€ {delimit(getShownTargetPrice(item, targetPriceType), 2)}</Right>;
    },
  },
  {
    header: 'Huidig totaal',
    width: 4 / 32,
    cell: function CurrentTotalPrice(item, _, { targetPriceType }) {
      return (
        <Right block>
          € {delimit(item.amount * getShownCurrentPrice(item, targetPriceType), 2)}
        </Right>
      );
    },
    footer: function CurrentTotalPriceSum(items, { hasMinimumRate }) {
      const hasPricingPermission = useHasPricingPermission();

      return (
        <Right block>
          {(hasMinimumRate || hasPricingPermission) && (
            <>€ {delimit(sum(items.map(item => item.amount * item.purchasePrice)), 2)}</>
          )}
          <br />€ {delimit(sum(items.map(item => item.amount * item.retailPrice)), 2)}
          <br /> <br /> <br />
        </Right>
      );
    },
  },
  {
    header: 'Nieuw totaal',
    width: 4 / 32,
    cell: function Amount(item, _, { targetPriceType }) {
      return (
        <Right block>
          € {delimit(item.amount * getShownTargetPrice(item, targetPriceType), 2)}
        </Right>
      );
    },
    footer: function Amount(items, { targetPriceType, hasMinimumRate }) {
      const hasPricingPermission = useHasPricingPermission();

      const totalCurPurchasePrice = sum(items.map(item => item.amount * item.purchasePrice));
      const totalNewPurchasePrice = sum(items.map(item => item.amount * item.newPurchasePrice));

      const totalCurRetailPrice = sum(items.map(item => item.amount * item.retailPrice));
      const totalNewRetailPrice = sum(items.map(item => item.amount * item.newRetailPrice));

      const isLower =
        totalCurPurchasePrice > totalNewPurchasePrice || totalCurRetailPrice > totalNewRetailPrice;

      const increasePct =
        (targetPriceType === 'purchase'
          ? totalNewPurchasePrice / totalCurPurchasePrice
          : totalNewRetailPrice / totalCurRetailPrice) - 1;

      // const margin = 1 - totalNewPurchasePrice / totalNewRetailPrice;
      // price.retailPrice / price.purchasePrice * 100 - 100
      const markupOld = totalCurRetailPrice / totalCurPurchasePrice - 1;
      const markupNew = totalNewRetailPrice / totalNewPurchasePrice - 1;
      const markupDiff = Math.round(1000 * (markupNew - markupOld)) / 1000;

      return (
        <Color c={isLower ? themify('red') : 'inherit'}>
          <Right block>
            {(hasMinimumRate || hasPricingPermission) && (
              <>
                <FontWeight weight={targetPriceType === 'purchase' ? '500' : 'normal'}>
                  € {delimit(totalNewPurchasePrice, 2)}
                </FontWeight>
                <br />
              </>
            )}
            <FontWeight weight={targetPriceType === 'retail' ? '500' : 'normal'}>
              € {delimit(totalNewRetailPrice, 2)}
            </FontWeight>
            <br />
            <Small>
              Verschil:{' '}
              <Color c={isLower ? 'inherit' : themify('green')}>
                {increasePct >= 0 ? '+' : ''}
                {delimit(100 * increasePct, 1)}%
              </Color>
              <br />
              {(hasMinimumRate || hasPricingPermission) && (
                <Tooltip
                  content={`Van ${delimit(100 * markupOld, 1)}% naar ${delimit(
                    100 * markupNew,
                    1,
                  )}% (${markupDiff >= 0 ? '+' : ''}${delimit(100 * markupDiff, 1)}%)`}
                >
                  <>
                    Markup:{' '}
                    <Color
                      c={
                        markupNew <= 0
                          ? themify('red')
                          : markupDiff === 0
                          ? 'inherit'
                          : markupDiff < 0
                          ? themify('orange')
                          : themify('green')
                      }
                    >
                      {delimit(100 * markupNew, 1)}%
                    </Color>
                  </>
                </Tooltip>
              )}
            </Small>
          </Right>
        </Color>
      );
    },
  },
];

interface PriceModificationTableProps {
  targetPriceType: PriceType;
  modifiableItems: ModifiableItem[];
  setModifiableItems: React.Dispatch<React.SetStateAction<ModifiableItem[]>>;
  targetPrice?: number;
  isApplyingToBoth: boolean;
  hasMinimumRate: boolean;
}

const PriceModificationTable: React.FC<PriceModificationTableProps> = ({
  targetPriceType = 'retail',
  modifiableItems,
  setModifiableItems,
  targetPrice,
  isApplyingToBoth,
  hasMinimumRate,
}) => {
  const recalculate = useCallback(
    (items: ModifiableItem[], target: number, targetType: PriceType, applyToBoth: boolean) =>
      setModifiableItems(recalculateInstallationPrices(items, target, targetType, applyToBoth)),
    [setModifiableItems],
  );

  const updateItem = useCallback(
    (item: ModifiableItem) => {
      const newItems = [...modifiableItems];
      newItems.splice(
        newItems.findIndex(i => i.product.id === item.product.id),
        1,
        item,
      );
      recalculate(newItems, targetPrice || 0, targetPriceType, isApplyingToBoth);
    },
    [isApplyingToBoth, modifiableItems, recalculate, targetPrice, targetPriceType],
  );

  return (
    <TableWrapper width={1} mt={1} $contentWidth={800}>
      <Table layout={columns.map(col => col.width)}>
        <Table.Row isHeader>
          {columns.map((col, index) => (
            <Table.Cell
              key={col.header}
              cellPadding="0.33rem"
              textAlign={index > 1 ? 'right' : undefined}
            >
              {col.header}
            </Table.Cell>
          ))}
        </Table.Row>
        {modifiableItems.map(item => (
          <Table.Row
            key={item.product.id}
            rowColor={item.include ? undefined : themify('darkGray')}
          >
            {columns.map(col => (
              <Table.Cell key={col.header}>
                {col.cell(item, updateItem, {
                  targetPriceType,
                  isApplyingToBoth,
                  hasMinimumRate,
                })}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
        <Table.Row isFooter>
          {columns.map(col => (
            <Table.Cell key={col.header}>
              {col.footer?.(modifiableItems, {
                targetPriceType,
                isApplyingToBoth,
                hasMinimumRate,
              })}
            </Table.Cell>
          ))}
        </Table.Row>
      </Table>
    </TableWrapper>
  );
};

export default PriceModificationTable;
