import { Accordion, Card } from '@energiebespaarders/symbols';
import { Check } from '@energiebespaarders/symbols/icons/solid';
import React from 'react';
import CompleteJobForm from '../../modals/AddRatingModal/CompleteJobForm';

interface CompletePlanningProps {
  jobId: string;
  quoteId: string;
}

const CompletePlanning: React.FC<CompletePlanningProps> = ({ jobId, quoteId }) => {
  return (
    <Card animation="none" shadow="none" p={0} borderColor="grayLight">
      <Accordion
        contentBorderColor="grayLight"
        icon={Check}
        iconColor={'blue'}
        title={'Een reeds uitgevoerde installatiedatum invoeren'}
        titleSize={5}
        zIndex={110}
      >
        <CompleteJobForm jobId={jobId} quoteId={quoteId} />
      </Accordion>
    </Card>
  );
};

export default CompletePlanning;
