import { useQuery } from '@apollo/client';
import React from 'react';
import { Accordion, Card, Placeholder, Separator } from '@energiebespaarders/symbols';
import { Medium, Small } from '@energiebespaarders/symbols/helpers';
import { Pencil } from '@energiebespaarders/symbols/icons/solid';
import { INTAKE_TOPICS_NL, IntakeTopic } from '@energiebespaarders/constants';
import { INTAKE_NOTES_BY_HOUSE } from '../../queries/intake';
import styled from 'styled-components';
import {
  intakeNotesByHouse,
  intakeNotesByHouseVariables,
} from '../../types/generated/intakeNotesByHouse';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';

export const FormattedNotes = styled.div`
  font-family: ${x => x.theme.type.fontFamilySans};
  font-style: italic;
  width: 100%;
  white-space: pre-wrap;
`;

const IntakeNotes: React.FC<{ isNarrow: boolean }> = ({ isNarrow }) => {
  const { activeHouseId } = useActiveHouseId();
  const { data, loading, error } = useQuery<intakeNotesByHouse, intakeNotesByHouseVariables>(
    INTAKE_NOTES_BY_HOUSE,
    {
      variables: { houseId: activeHouseId },
      fetchPolicy: 'cache-and-network',
    },
  );
  if (loading || error || !data) return <Placeholder error={error} />;
  const intakeNotes = data.intakeByHouse?.notes || {};
  const notesTopics = Object.keys(intakeNotes).filter(
    topic => topic !== '__typename',
  ) as (keyof typeof intakeNotes)[];
  const hasNotes = notesTopics.some(topic => intakeNotes[topic] !== '');

  return (
    <Card p={0} mb={isNarrow ? 2 : 4} zIndex={100} style={{ overflowY: 'hidden' }}>
      <Accordion
        contentBorderColor="grayLight"
        contentPadding={isNarrow ? 2 : 4}
        defaultOpen={!isNarrow && hasNotes}
        icon={Pencil}
        iconColor={hasNotes ? 'green' : 'red'}
        iconSize="24px"
        title="Notities"
        titleSize={5}
        zIndex={100}
      >
        {notesTopics
          .filter(topic => intakeNotes[topic])
          .map((topic, index, arr) => (
            <React.Fragment key={`notes-${topic}-${index}`}>
              <Small>
                <Medium>{INTAKE_TOPICS_NL[topic as IntakeTopic]}</Medium>
              </Small>
              <FormattedNotes>{intakeNotes[topic]}</FormattedNotes>
              {index !== arr.length - 1 && <Separator color="grayLight" my={1} />}
            </React.Fragment>
          ))}
      </Accordion>
    </Card>
  );
};

export default IntakeNotes;
