import { Toast, Tooltip } from '@energiebespaarders/symbols';
import dayjs from 'dayjs';
import React from 'react';
import { quoteModalQuote_quoteById_installer_status } from '../../types/generated/quoteModalQuote';
import { InstallerStatus } from '../../types/graphql-global-types';
import { installerStatusReasonNL } from '../companies/InstallerStatusConfirmationModal';
import InstallerStatusIndicators from '../companies/InstallerStatusIndicator';

const InstallerStatusBanner: React.FC<{
  installer: {
    id: string;
    name: string;
    status: quoteModalQuote_quoteById_installer_status;
  };
}> = ({ installer }) => {
  if (installer.status.value === InstallerStatus.active) return null;

  const indicator = InstallerStatusIndicators[installer.status.value];

  return (
    <Toast
      type={indicator.type}
      message={
        <span>
          Let op: <b>{installer.name}</b> staat sinds{' '}
          {dayjs(installer.status.date).format('DD/MM/YY')} op <b>"{indicator.label}"</b>{' '}
          {indicator.description ? ` (${indicator.description})` : ''} vanwege:{' '}
          <Tooltip
            content={
              <>
                Toelichting: <i>{installer.status.comment || '-'}</i>
              </>
            }
          >
            <b> {installerStatusReasonNL[installer.status.reason!] || 'Onbekend'}</b>
          </Tooltip>
        </span>
      }
      width="100%"
    />
  );
};

export default InstallerStatusBanner;
