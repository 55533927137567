import { Energiepaspoort } from '@energiebespaarders/symbols/icons/custom';
import {
  CaretRightDouble,
  Information,
  MeasuringTape,
  TriangleMeasure,
  Wrenches,
} from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useNavHelpers } from '../../hooks/useNavHelpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { padding } from '../../styles/mixins';
import { adviceGuide } from './helpGuides/advice';
import { generalGuide } from './helpGuides/general';
import { installatronGuide } from './helpGuides/installatron';
import { intakeGuide } from './helpGuides/intake';
import { obamaGuide } from './helpGuides/obama';
import SideNav from './SideNav';
import SideNavItem, { PositionedSideNavItem } from './SideNavItem';
import SideNavTabs from './SideNavTabs';

const StyledHelpWrapper = styled.div`
  ${padding(4)};
  height: ${x => `calc(100% - (${x.theme.fixedSizes.navHeight} * 2))`};
  overflow-y: scroll;
`;

const CloseHelp = styled(SideNavItem)<PositionedSideNavItem>`
  position: sticky;
  bottom: ${x => (x.$narrowScreen ? 'auto' : '0')};
  width: 100%;
`;

interface IHelpMenuProps {
  width: string;
}

const HelpMenu: React.FC<IHelpMenuProps> = ({ width }) => {
  const { helpMenuState, setHelpMenuState, closeHelpMenu } = useNavHelpers();
  const mobile = useIsMobile();
  const renderGuide = useCallback(() => {
    switch (helpMenuState.activeTab) {
      case 1:
        return generalGuide;
      case 2:
        return intakeGuide;
      case 3:
        return installatronGuide;
      case 4:
        return adviceGuide;
      case 5:
        return obamaGuide;
      default:
        return generalGuide;
    }
  }, [helpMenuState.activeTab]);
  return helpMenuState.isOpen ? (
    <SideNav
      bgColor="white"
      color="grayBlack"
      side="right"
      style={helpMenuState.isOpen ? { boxShadow: '0 0 12px #ccc', zIndex: 1320 } : { zIndex: 1320 }}
      translateX={helpMenuState.isOpen ? '0' : '100%'}
      width={width}
    >
      <SideNavTabs
        activeTab={helpMenuState.activeTab}
        setActiveTab={(tab: number) => setHelpMenuState?.({ activeTab: tab })}
        tabIcons={[Information, MeasuringTape, Wrenches, Energiepaspoort, TriangleMeasure]}
      />

      <StyledHelpWrapper>{renderGuide()}</StyledHelpWrapper>

      <CloseHelp
        bgColor="red"
        color="white"
        $narrowScreen={mobile}
        showLabel
        side="right"
        icon={CaretRightDouble}
        label="Sluiten"
        onClick={closeHelpMenu}
        to="#"
      />
    </SideNav>
  ) : (
    <></>
  );
};

export default HelpMenu;
