import {
  ProductCategory,
  Solution,
  SolutionDomain,
  constants,
} from '@energiebespaarders/constants';
import { Box, Button, Container, Flex, Separator } from '@energiebespaarders/symbols';
import { FilePartial, MagicWand, Rotate, Wrenches } from '@energiebespaarders/symbols/icons/solid';
import { Dispatch, default as React, SetStateAction, useState } from 'react';
import { useFlag } from 'react-unleash-flags';
import { FeatureFlag, humulusUrl, isProduction, isStaging } from '../../../config';
import { quoteBuilderSolutions } from '../../../domains/TaskFlow/flows/CreateQuotes/constants';
import { useHasPricingPermission } from '../../../hooks/useHasPricingPermission';
import { useMe } from '../../../hooks/useMe';
import { dealsByHouseSolutionDomain_dealsByHouseSolutionDomain } from '../../../types/generated/dealsByHouseSolutionDomain';
import { installationByHouseSolution_installationByHouseSolution as t_installation } from '../../../types/generated/installationByHouseSolution';
import Alerts from '../../Alerts';
import { generatableSolutions } from '../utils';
import InstallationItemBreakdown from './InstallationItemBreakdown';
import InstallatronItemBreakdownFooter from './InstallationItemBreakdownFooter';
import ItemConfigurator from './ItemConfigurator';
import OutOfDatePriceChecker, { PricingStatus } from './OutOfDatePriceChecker';
import PvPanelTieringUnsupportedAlert from './PvPanelTieringUnsupportedAlert';
import QuoteExportModal from './QuoteExportModal';
import RegenerateInstallationModal from './RegenerateInstallationModal';

type InstallationBuilderProps = {
  installation: t_installation;
  houseId: string;
  solution: Solution;
  solutionDomain: SolutionDomain;
  mobile: boolean;
  setInstallatronOpen: Dispatch<SetStateAction<boolean>>;
  deals: readonly dealsByHouseSolutionDomain_dealsByHouseSolutionDomain[];
};

const InstallationBuilder: React.FC<InstallationBuilderProps> = ({
  installation,
  houseId,
  solution,
  solutionDomain,
  mobile,
  setInstallatronOpen,
  deals,
}) => {
  const { me } = useMe();
  const quoteBuilderFlag = useFlag(FeatureFlag.QuoteBuilder);
  const hasPricingPermission = useHasPricingPermission();
  const [showExportModal, setShowExportModal] = useState(false);
  const [pvInfoComplete, setPvInfoComplete] = useState<boolean>(solution !== Solution.PvSystem);

  const [isRegenerateModalOpen, setRegenerateModalOpen] = useState(false);
  /** Contains the price on the quote compared to that in the DB */
  const [pricingStatus, setPricingStatus] = useState<PricingStatus>();

  // If only the insulation labor product is a lower retail price, it's allowed!
  const isTieredPriceException =
    installation.solution in constants.tieredPrices &&
    installation.items.every(i =>
      i.product.category === ProductCategory.Labor ? true : i.price.retailPrice === i.retailPrice,
    );

  // Disallow a purchase or retail price that's lower than the one in the DB
  let isPriceAllowed =
    pricingStatus?.purchasePrice !== 'lower' &&
    // Exception for tiered pricing: Labor retail price is intentionally lowered/increased based on amount
    (pricingStatus?.retailPrice !== 'lower' || isTieredPriceException);
  // Exception for ING: Prices are intentionally lowered: You must reduce the price
  // People with pricing permission may create a quote with lower prices in all cases
  // (e.g. exceptions by a Sales manager or NS for a post-PTC quote with old prices)
  if (hasPricingPermission) isPriceAllowed = true;

  // No reason for defaults not to be defined/to throw an error other then a problem connecting to the API
  return (
    <Container>
      <ItemConfigurator
        installation={installation}
        houseId={houseId}
        solution={solution}
        solutionDomain={solutionDomain}
        editing={undefined}
      />

      <Box width={1} px={2}>
        {installation.items.length > 0 && <Separator text="Concept Installatie" my={2} />}
        <InstallationItemBreakdown
          installation={installation}
          houseId={houseId}
          solution={solution}
          solutionDomain={solutionDomain}
          mobile={mobile}
        />
        <InstallatronItemBreakdownFooter installation={installation} />
        <Separator my={2} />
        <PvPanelTieringUnsupportedAlert installation={installation} />
        <Alerts
          warnings={installation.exportConstraintMessages.warnings[solution]}
          errors={installation.exportConstraintMessages.errors[solution]}
        />
        <OutOfDatePriceChecker
          installation={installation}
          pricingStatus={pricingStatus}
          setPricingStatus={setPricingStatus}
        />
        <Flex alignItems="flex-end" flexDirection="row-reverse" py={2}>
          <Button
            bgColor={
              installation.exportConstraintMessages.warnings[solution].length > 0 || !pvInfoComplete
                ? 'orange'
                : 'green'
            }
            iconStart={Wrenches}
            label="Controleer installatie"
            onClick={() => setShowExportModal(true)}
            mr={0}
            disabled={
              installation.items.length === 0 ||
              installation.exportConstraintMessages.errors[solution].length > 0 ||
              !isPriceAllowed
            }
          />
          <Button
            href={
              isProduction || isStaging
                ? `${humulusUrl}/lead-job/${installation.id}`
                : `${humulusUrl}/lead-job?installationId=${installation.id}`
            }
            label="Concept installatieopdracht"
            iconStart={FilePartial}
            bgColor={
              installation.exportConstraintMessages.warnings[solution].length > 0
                ? 'orange'
                : 'green'
            }
            mr={1}
            inverse
          />
          <Button
            href={
              isProduction || isStaging
                ? `${humulusUrl}/quote-concept/${installation.id}?operatorId=${me.id}`
                : `${humulusUrl}/quote-concept?installationId=${installation.id}&operatorId=${me.id}`
            }
            label="Concept offerte"
            iconStart={FilePartial}
            bgColor={
              installation.exportConstraintMessages.warnings[solution].length > 0
                ? 'orange'
                : 'green'
            }
            mr={1}
            inverse
          />
          {generatableSolutions.includes(solution) && (
            <Button
              label="Opnieuw genereren"
              iconStart={Rotate}
              bgColor="orange"
              inverse
              onClick={() => setRegenerateModalOpen(true)}
              mr={1}
            />
          )}
          {quoteBuilderFlag?.enabled && quoteBuilderSolutions.includes(solution) && (
            <Button
              label="Offerte Wizard"
              iconStart={MagicWand}
              bgColor="purple"
              inverse
              to={`/taak/offertes-opstellen/${houseId}/${solution}`}
              mr={1}
            />
          )}
        </Flex>
      </Box>

      {isRegenerateModalOpen && (
        <RegenerateInstallationModal
          setShow={setRegenerateModalOpen}
          houseId={houseId}
          solution={solution}
          mobile={mobile}
          closeOnGenerationCompletion
        />
      )}

      <QuoteExportModal
        isOpen={showExportModal}
        mobile={mobile}
        onClose={() => setShowExportModal(false)}
        onCompletePvInfo={() => setPvInfoComplete(true)}
        installation={installation}
        solutionDomain={solutionDomain}
        deals={deals}
        pricingStatus={pricingStatus}
        pvInfoComplete={pvInfoComplete}
        onExported={() => {
          setShowExportModal(false);
          setInstallatronOpen(true);
        }}
      />
    </Container>
  );
};

// Memo greatly improves performance when dragging split pane divider
export default React.memo(InstallationBuilder);
