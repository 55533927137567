import { SOLUTION_DOMAINS_NL, SolutionDomain } from '@energiebespaarders/constants';
import {
  Accordion,
  Box,
  Button,
  Card,
  Flex,
  Radio,
  RadioGroup,
  Select,
} from '@energiebespaarders/symbols';
import { Right } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { Info } from '@energiebespaarders/symbols/icons/solid';
import { useState } from 'react';

const cardinalOptions = [
  { label: 'Zuid', value: 'south' },
  { label: 'Zuid-Oost', value: 'southEast' },
  { label: 'Zuid-West', value: 'southWest' },
  { label: '"Oost-West" opstelling', value: 'eastWest' },
  { label: 'Oost', value: 'east' },
  { label: 'West', value: 'west' },
  { label: 'Noord-Oost', value: 'northEast' },
  { label: 'Noord-West', value: 'northWest' },
  { label: 'Noord', value: 'north' },
];

const yesNoOptions = [
  { label: 'Ja', value: true },
  { label: 'Nee', value: false },
];

const installationPlanPropertiesOptions = [
  { label: 'Beperkt beschikbaar oppervlak', value: 'limitedRoofArea' },
  { label: 'Esthetisch', value: 'aesthetics' },
  { label: 'Afgesteld op gebruik', value: 'tunedForUsage' },
  { label: 'Maximale opbrengst', value: 'maximumYield' },
  { label: 'Meerdere dakvlakken', value: 'multipleRoofSurfaces' },
  { label: 'Maximale opbrengst met schaduw', value: 'maximumYieldWithShadow' },
  { label: 'Meerdere dakvlakken en schaduw', value: 'multipleRoofSurfacesAndShadow' },
];

interface PvQuoteInfoProps {
  cardinalDirection?: string;
  setCardinalDirection?: (value: string) => void;
  multipleRoofSurfaces?: boolean;
  setMultipleRoofSurfaces?: (value: boolean) => void;
  installationPlanProperties?: string;
  setInstallationPlanProperties?: (value: string) => void;
  neighbourDiscount?: boolean;
  setNeighbourDiscount?: (value: boolean) => void;
  submit?: () => void;
  submitDisabled?: boolean;
}

export const PvQuoteInfoForm: React.FC<PvQuoteInfoProps> = ({
  cardinalDirection,
  setCardinalDirection,
  multipleRoofSurfaces,
  setMultipleRoofSurfaces,
  installationPlanProperties,
  setInstallationPlanProperties,
  neighbourDiscount,
  setNeighbourDiscount,
  submit,
  submitDisabled,
}: PvQuoteInfoProps) => {
  const mobile = useIsMobile();

  const handleRadioChange = (question: string, value: boolean) => {
    switch (question) {
      case 'multipleRoofSurfaces':
        setMultipleRoofSurfaces && setMultipleRoofSurfaces(value);
        break;
      case 'neighbourDiscount':
        setNeighbourDiscount && setNeighbourDiscount(value);
        break;
      default:
        console.error('Wrong type specifed to handleRadioChange');
    }
  };

  const handleSelectChange = (question: string, value: string) => {
    switch (question) {
      case 'cardinalDirection':
        setCardinalDirection && setCardinalDirection(value);
        break;
      case 'installationPlanProperties':
        setInstallationPlanProperties && setInstallationPlanProperties(value);
        break;
      default:
        console.error('Wrong type specifed to handleSelectChange');
    }
  };

  const cardinalSelectOptions = [
    { value: '', label: 'Kies een optie', disabled: true },
    ...cardinalOptions,
  ];
  const planOptions = [
    { value: '', label: 'Kies een optie', disabled: true },
    ...installationPlanPropertiesOptions,
  ];

  return (
    <Flex flexWrap="wrap" mx={mobile ? 1 : 0}>
      <Box width={[1, 1, 1 / 2]} px={mobile ? 0 : 1}>
        <Select
          label="Windrichting van de te plaatsen panelen"
          value={cardinalSelectOptions.find(opt => opt.value === cardinalDirection)}
          onChange={(e: { value: string }) => handleSelectChange('cardinalDirection', e.value)}
          options={cardinalSelectOptions}
          labelColor={'grayDark'}
        />
      </Box>
      <Box width={[1, 1, 1 / 2]} px={mobile ? 0 : 1}>
        <RadioGroup
          label="Panelen komen op meerdere dakvlakken te liggen"
          onChange={value => handleRadioChange('multipleRoofSurfaces', value)}
          value={multipleRoofSurfaces}
        >
          {yesNoOptions.map((opt, index) => (
            <Radio
              key={`surfaces-${opt.value}-${index}`}
              value={opt.value}
              checked={multipleRoofSurfaces === opt.value}
              id={`surfaces-${opt.value}-${index}`}
              label={opt.label}
            />
          ))}
        </RadioGroup>
      </Box>
      <Box width={[1, 1, 1 / 2]} px={mobile ? 0 : 1}>
        <Select<string>
          label="Motivatie bij legplan"
          value={planOptions.find(opt => opt.value === installationPlanProperties)}
          onChange={e => handleSelectChange('installationPlanProperties', e.value)}
          options={planOptions}
          labelColor={'grayDark'}
        />
      </Box>
      <Box width={[1, 1, 1 / 2]} px={mobile ? 0 : 1}>
        <RadioGroup
          label="Burenkorting-uitleg tonen in het Energiepaspoort?"
          onChange={value => handleRadioChange('neighbourDiscount', value)}
          value={neighbourDiscount}
        >
          {yesNoOptions.map((opt, index) => (
            <Radio
              key={`neighbour-${opt.value}-${index}`}
              value={opt.value}
              checked={neighbourDiscount === opt.value}
              id={`neighbour-${opt.value}-${index}`}
              label={opt.label}
            />
          ))}
        </RadioGroup>
      </Box>
      <Box width={1} px={mobile ? 0 : 1}>
        <Right block>
          <Button label="Opslaan" onClick={submit} disabled={submitDisabled} />
        </Right>
      </Box>
    </Flex>
  );
};

const PvQuoteInfo = ({
  pvInfoComplete,
  defaultOpen = true,
  ...rest
}: PvQuoteInfoProps & {
  pvInfoComplete: boolean;
  defaultOpen?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(defaultOpen);

  return (
    <Card p={0} mb={2} zIndex={250} animation="none" style={{ overflowY: 'hidden' }}>
      <Accordion
        baseBgColor="white"
        bgColor="grayLighter"
        contentBorderColor="grayLight"
        contentPadding={2}
        icon={Info}
        iconColor={pvInfoComplete ? 'green' : 'orange'}
        isOpened={isOpened}
        onClick={() => setIsOpened(!isOpened)}
        override
        title={`${SOLUTION_DOMAINS_NL[SolutionDomain.PvSystem]} extra offerte informatie`}
        titleSize={6}
        zIndex={300}
      >
        <PvQuoteInfoForm {...rest} />
      </Accordion>
    </Card>
  );
};

export default PvQuoteInfo;
