import { Box, Button, Flex, Icon, Input } from '@energiebespaarders/symbols';
import {
  AddCircle,
  CaretDown,
  CaretUp,
  Pencil,
  Trash,
} from '@energiebespaarders/symbols/icons/solid';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { themify } from '../styles/mixins';

const HiddenIcon = styled(Icon)`
  opacity: ${x => (x.mobile ? 1 : 0)};
  transition: opacity 0.2s ${x => x.theme.curves.standard};
  cursor: pointer;
`;

const DeletableItem = styled.div`
  opacity: ${props => (props.$isEditing ? 0.3 : 1)};
  &:hover {
    ${HiddenIcon} {
      opacity: 1;
    }
  }
`;

export const StyledError = styled.span`
  float: right;
  color: ${x => x.theme.colors.red};
  font-size: ${x => x.theme.type.scale[7]};
`;

class ArrayInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      array: props.array,
      newItemValue: '',
      editingItemIndex: undefined,
    };
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.changeItemPosition = this.changeItemPosition.bind(this);
  }
  addItem(value) {
    const { array, editingItemIndex } = this.state;
    const newArray = array ? array.slice() : [];

    if (editingItemIndex !== undefined) {
      newArray.splice(editingItemIndex, 1, value);
    } else newArray.push(value);

    this.setState({ array: newArray, newItemValue: '', editingItemIndex: undefined });
    this.props.handleChange(newArray);
  }
  removeItem(index) {
    const { array } = this.state;
    const newArray = array ? array.slice() : [];
    newArray.splice(index, 1);
    this.setState({ array: newArray });
    this.props.handleChange(newArray);
  }
  editItem(index, item) {
    this.setState({ editingItemIndex: index, newItemValue: item });
  }
  changeItemPosition(index, item, direction) {
    const newArray = [...this.state.array];
    const newIndex = direction === '+' ? index - 1 : index + 1;

    if (newIndex < 0 || newIndex > newArray.length) {
      return;
    }

    // remove from old position
    newArray.splice(index, 1);
    // add in item in new position
    newArray.splice(newIndex, 0, item);

    this.setState({ array: newArray });
    this.props.handleChange(newArray);
  }

  render() {
    const {
      addonIcon,
      buttonText,
      iconColor,
      placeholder,
      label,
      name,
      touched,
      error,
    } = this.props;
    const { array, newItemValue, editingItemIndex } = this.state;
    const isEditingItem = this.state.editingItemIndex !== undefined;

    return (
      <Flex flexWrap="wrap" mx={-1}>
        <Box width={1} px={1}>
          <Input.Label labelSize={7} labelColor={themify('grayDark')}>
            {label}
            {touched && error && <StyledError>{error}</StyledError>}
          </Input.Label>
        </Box>
        {array &&
          array.map((_, itemIndex) => (
            <Box key={`${name}-${itemIndex}`} width={[1, 1, 1 / 2]} px={1} mb={1}>
              <DeletableItem $isEditing={itemIndex === editingItemIndex}>
                <Icon icon={addonIcon} solid fill={iconColor} mr={2} />
                {array[itemIndex]}
                {!isEditingItem ? (
                  <>
                    <HiddenIcon
                      fill="gray"
                      hoverColor="green"
                      icon={Pencil}
                      width="0.85em"
                      solid
                      onClick={() => this.editItem(itemIndex, array[itemIndex])}
                      ml={2}
                    />
                    <HiddenIcon
                      fill="gray"
                      hoverColor="green"
                      icon={Trash}
                      width="0.85em"
                      solid
                      onClick={() => this.removeItem(itemIndex)}
                      ml={2}
                    />
                    <HiddenIcon
                      fill="gray"
                      hoverColor="yellow"
                      icon={CaretUp}
                      width="0.85em"
                      solid
                      onClick={() => this.changeItemPosition(itemIndex, array[itemIndex], '+')}
                      ml={2}
                    />
                    <HiddenIcon
                      fill="gray"
                      hoverColor="yellow"
                      icon={CaretDown}
                      width="0.85em"
                      solid
                      onClick={() => this.changeItemPosition(itemIndex, array[itemIndex], '-')}
                      ml={2}
                    />
                  </>
                ) : (
                  <></>
                )}
              </DeletableItem>
            </Box>
          ))}
        <Box width={[1, 2 / 3]} px={1}>
          <Input
            addonSide="start"
            icon={isEditingItem ? Pencil : addonIcon}
            iconColor={iconColor}
            name={`${name}-new`}
            onChange={e => this.setState({ newItemValue: e.target.value })}
            placeholder={placeholder}
            type="text"
            value={newItemValue}
            borderColor={(touched && error && 'red') || 'gray'}
          />
        </Box>
        <Box width={[1, 1 / 3]} px={1}>
          <Button
            bgColor="purple"
            disabled={newItemValue === ''}
            fluid
            fontSize={6}
            iconStart={AddCircle}
            inverse
            label={isEditingItem ? 'Updaten' : buttonText}
            onClick={() => this.addItem(newItemValue)}
          />
        </Box>
      </Flex>
    );
  }
}

ArrayInput.propTypes = {
  addonIcon: PropTypes.any,
  buttonText: PropTypes.string,
  error: PropTypes.string,
  iconColor: PropTypes.string,
  label: PropTypes.element,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
};

export default ArrayInput;
