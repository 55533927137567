import { Box, Icon, Tooltip } from '@energiebespaarders/symbols';
import { Gray, Smaller } from '@energiebespaarders/symbols/helpers';
import {
  Archive,
  LocationCancel,
  Shipping,
  Warning,
  Wrenches,
} from '@energiebespaarders/symbols/icons/solid';
import React from 'react';
import { getInstallationPartners_installersBySolution } from '../../../types/generated/getInstallationPartners';
import { InstallerStatus } from '../../../types/graphql-global-types';
import InstallerStatusIndicators from '../../companies/InstallerStatusIndicator';

export interface SelectFilterOption {
  data: {
    searchValue: string;
  };
}

interface Props {
  product: { id: string; title: string; archived?: Date | null; isSuppliable?: boolean | null };
  selectedInstallationPartner?: string;
  hint?: string;
}

export const ProductOption: React.FC<Props> = ({ product, selectedInstallationPartner, hint }) => {
  return (
    <Box>
      {product.isSuppliable === false && (
        <>
          <Tooltip
            content={
              selectedInstallationPartner
                ? `${selectedInstallationPartner} kan dit product niet leveren`
                : 'Er is geen installatiepartner die dit product kan leveren'
            }
            bgColor="red"
          >
            <Icon solid fill="gray" hoverColor="red" icon={Shipping} />
          </Tooltip>{' '}
        </>
      )}
      {product.archived && (
        <>
          <Tooltip
            content="Product gearchiveerd, zou niet meer aangeboden moeten worden"
            bgColor="orange"
          >
            <Icon solid fill="goldDark" hoverColor="orange" icon={Archive} />
          </Tooltip>{' '}
        </>
      )}
      {hint && (
        <Smaller>
          <Gray>
            <b>{hint}:</b>
            <br />
          </Gray>
        </Smaller>
      )}
      {product.title}
    </Box>
  );
};

export function InstallationPartnerOption({
  installationPartner,
  productSelected,
}: {
  installationPartner: getInstallationPartners_installersBySolution;
  productSelected: boolean;
}) {
  const statusIndicator = installationPartner.status.value
    ? InstallerStatusIndicators[installationPartner.status.value]
    : undefined;
  return (
    <>
      {statusIndicator && installationPartner.status.value !== InstallerStatus.active ? (
        <>
          <Tooltip content={statusIndicator.description} bgColor={statusIndicator.color}>
            <Icon
              solid
              fill={statusIndicator.color}
              hoverColor={statusIndicator.color}
              icon={statusIndicator.icon}
            />
          </Tooltip>{' '}
        </>
      ) : null}
      {installationPartner.capableOfInstallingAtHouse === false && (
        <>
          <Tooltip
            content="Deze installatiepartner kan niet op dit adres installeren"
            bgColor="red"
          >
            <Icon solid fill="red" hoverColor="red" icon={LocationCancel} />
          </Tooltip>{' '}
        </>
      )}
      {productSelected && installationPartner.capableOfInstallingProduct === false && (
        <>
          <Tooltip content="Deze installatiepartner kan dit product niet installeren" bgColor="red">
            <Icon solid fill="red" hoverColor="red" icon={Wrenches} />
          </Tooltip>{' '}
        </>
      )}
      {productSelected && installationPartner.supplier.capableOfSupplyingProduct === false && (
        <>
          <Tooltip content="Deze installatiepartner kan dit product niet leveren" bgColor="red">
            <Icon solid fill="red" hoverColor="red" icon={Shipping} />
          </Tooltip>{' '}
        </>
      )}
      {installationPartner.installationRegions.length === 0 && (
        <Tooltip content="Werkgebieden van deze installateur zijn onbekend" bgColor="red">
          <Icon solid fill="orange" icon={Warning} mr={1} />
        </Tooltip>
      )}
      {installationPartner.name}
      {installationPartner.distanceToHouse
        ? ` (${(installationPartner.distanceToHouse / 1000).toFixed(1)}km)`
        : ''}
    </>
  );
}
