import theme from '../../styles/theme';

/**
 * Generates a hsl color from a string input
 * @param stringInput A string to generate a color from
 * @returns A hsl color string, e.g. 'hsl(0, 95%, 35%)'
 */
export function stringToColor(stringInput: string) {
  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}

const themeColors: string[] = [
  theme.colors.blue,
  theme.colors.darkBlue,
  theme.colors.gold,
  theme.colors.green,
  theme.colors.lavender,
  theme.colors.mint,
  theme.colors.orange,
  theme.colors.purple,
];

/**
 * Generates a theme color from a string input
 * @param stringInput A string to generate a color from
 * @returns A theme color string, e.g. '#0fcdba'
 */
export function stringToThemeColor(stringInput: string) {
  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);

  const colorIndex = Math.abs(stringUniqueHash) % themeColors.length;
  return themeColors[colorIndex];
}
