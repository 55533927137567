import { gql } from '@apollo/client';
import EnergyDeltaFragment from './EnergyDelta';
import ProductFragment from './Product';
import DiscountFragment from './Discount';
import FileFragment from './File';

export const QuoteItemFragment = gql`
  ${ProductFragment}
  fragment QuoteItem on InstallationItem {
    amount
    retailPrice
    purchasePrice
    supplier {
      id
      name
    }
    product {
      ...Product
    }
    price {
      id
      retailPrice
      purchasePrice
      availability
    }
  }
`;

export const QuoteFragment = gql`
  ${DiscountFragment}
  ${EnergyDeltaFragment}
  ${QuoteItemFragment}
  ${FileFragment}
  fragment Quote on Quote {
    id
    created
    reference
    operator {
      id
      firstName
      lastName
    }
    isAccepted
    acceptedOn
    acceptedSubjectToFunding {
      date
      dateFundingExpected
    }
    isAdvised
    final
    isCancelled
    cancelledOn
    text
    expirationDate
    experiments
    isSent
    sentOn
    seenOn
    solution
    investment
    investmentExTax
    efficiency
    paybackTime
    subsidy
    items {
      ...QuoteItem
    }
    discount {
      ...Discount
    }
    energyDelta {
      ...EnergyDelta
    }
    comfortScore
    files {
      ...File
    }
  }
`;

export const ExportConstraintMessagesFragment = gql`
  fragment ExportConstraintMessages on ExportConstraintMessages {
    warnings {
      wallInsulation
      floorInsulation
      crawlspaceInsulation
      innerRoofInsulation
      atticFloorInsulation
      pvSystem
      windowGlazing
      windowFrames
      centralHeatingBoiler
      electricHeatPump
      hybridHeatPump
      airToAirHeatPump
      underfloorHeating
      miscellaneous
      sedum
      chargingBox
      homeBattery
    }
    errors {
      wallInsulation
      floorInsulation
      crawlspaceInsulation
      innerRoofInsulation
      atticFloorInsulation
      pvSystem
      windowGlazing
      windowFrames
      centralHeatingBoiler
      electricHeatPump
      hybridHeatPump
      airToAirHeatPump
      underfloorHeating
      miscellaneous
      sedum
      chargingBox
      homeBattery
    }
  }
`;

export default QuoteFragment;
