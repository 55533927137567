import React, { useState } from 'react';
import { Flex, Box, Modal, Checkbox, Textarea, Select, Icon } from '@energiebespaarders/symbols';
import { Gray, Red, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import useToaster, { IToast } from '../../../../hooks/useToaster';
import {
  sendJob as sendJobMutation,
  sendJobVariables,
  sendJob_sendJob,
} from '../../../../types/generated/sendJob';
import { JobIssueReason, JobType } from '../../../../types/graphql-global-types';
import dayjs from 'dayjs';
import { useMutation, useQuery } from '@apollo/client';
import {
  CUSTOMER_EMAIL_BY_HOUSE,
  RESEND_JOB_DUE_TO_ISSUE,
  SEND_JOB,
} from '../../../../queries/installatron';
import {
  customerEmailByHouse,
  customerEmailByHouseVariables,
} from '../../../../types/generated/customerEmailByHouse';
import { PaperAirplane, Warning } from '@energiebespaarders/symbols/icons/solid';
import { useMe } from '../../../../hooks/useMe';
import { useActiveHouseId } from '../../../../hooks/useActiveHouseId';
import { jobDomainQuote_quoteById_jobs } from '../../../../types/generated/jobDomainQuote';
import {
  resendJobDueToIssue,
  resendJobDueToIssueVariables,
} from '../../../../types/generated/resendJobDueToIssue';
import { enumValues } from '@energiebespaarders/constants';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';

export const JOB_ISSUE_REASONS_NL: Record<JobIssueReason, string> = {
  [JobIssueReason.installerCausedDamage]: 'Schade veroorzaakt',
  [JobIssueReason.notProperlyConnected]: 'Niet correct aangesloten',
  [JobIssueReason.wrongProduct]: 'Verkeerd product gekozen', // e.g. wrong inverter
  [JobIssueReason.installationIncomplete]: 'Installatie niet afgerond',
  [JobIssueReason.materialsNotAvailable]: 'Materialen momenteel niet beschikbaar', // also a suspension reason
  [JobIssueReason.other]: 'Anders',
};

export const jobIssueOptions: DropdownOption<JobIssueReason>[] = enumValues(JobIssueReason)
  .sort(a => (a === JobIssueReason.other ? 0 : -1))
  .map(reason => ({
    value: reason,
    label: JOB_ISSUE_REASONS_NL[reason],
  }));

interface RequestPlanningModalProps {
  job: jobDomainQuote_quoteById_jobs;
  close: () => void;
  jobPreviouslySent: boolean;
  mobile: boolean;
}

const RequestPlanningModal: React.FC<RequestPlanningModalProps> = ({
  job,
  jobPreviouslySent,
  close,
  mobile,
}) => {
  const toast = useToaster();
  const { me } = useMe();
  const { activeHouseId } = useActiveHouseId();

  const [isReplanningAfterIssue, setReplanningAfterIssue] = useState(false);
  const [sendToCustomer, setSendToCustomer] = useState(true);
  const [sendToInstaller, setSendToInstaller] = useState(true);
  const [installerSelectionReason, setInstallerSelectionReason] = useState('');
  const [issueReason, setIssueReason] = useState<JobIssueReason>();
  const [additionalReplanningInformation, setAdditionalReplanningInformation] = useState('');

  const { data: houseData } = useQuery<customerEmailByHouse, customerEmailByHouseVariables>(
    CUSTOMER_EMAIL_BY_HOUSE,
    { variables: { houseId: activeHouseId } },
  );

  const handleCompleted = (sendJob: sendJob_sendJob) => {
    const toasts: IToast[] = [];
    if (sendJob.customerEmailSent) {
      toasts.push({
        type: 'success',
        message: 'E-mail verstuurd naar klant',
      });
    }
    if (sendJob.installerEmailSent) {
      toasts.push({
        type: 'success',
        message: 'E-mail verstuurd naar installatiepartner',
      });
    }
    if (sendJob.job?.projectGrippId) {
      toasts.push({
        type: 'success',
        message: 'Verkooporder aangemaakt in Gripp',
      });
    }
    if (sendJob.errors) {
      toasts.push({
        type: 'error',
        message: sendJob.errors,
      });
    }
    toast(toasts);

    close();
  };

  const [sendJob, sendJobResult] = useMutation<sendJobMutation, sendJobVariables>(SEND_JOB, {
    onCompleted: ({ sendJob }) => handleCompleted(sendJob),
    onError: e => toast({ type: 'error', message: e.message }),
  });

  const [resendJobDueToIssue, resendDueToIssueResult] = useMutation<
    resendJobDueToIssue,
    resendJobDueToIssueVariables
  >(RESEND_JOB_DUE_TO_ISSUE, {
    onCompleted: () => {
      toast({ type: 'success', message: 'Opdracht opnieuw verstuurd' });
      close();
    },
    onError: e => toast({ type: 'error', message: e.message }),
  });

  const isSent =
    sendJobResult.data?.sendJob?.job || resendDueToIssueResult.data?.resendJobDueToIssue;
  const error = sendJobResult.error || resendDueToIssueResult.error;
  const loading = sendJobResult.loading || resendDueToIssueResult.loading;

  const handleSubmit = () => {
    if (
      !window.confirm(
        `Je gaat deze e-mail versturen naar: ${
          sendToInstaller ? job.installer.installationEmail : ''
        }${sendToInstaller && sendToCustomer ? ' en ' : ''}${
          sendToCustomer ? houseData?.house?.customer?.email : ''
        }${!(sendToCustomer || sendToInstaller) ? 'Geen van beiden' : ''}. Weet je het zeker?`,
      )
    ) {
      return;
    }

    const vars = {
      variables: {
        jobId: job.id,
        operatorName: me.firstName + ' ' + me.lastName,
        sendToCustomer,
        sendToInstaller,
        installerSelectionReason,
        comment: issueReason!,
        additionalInformation: additionalReplanningInformation,
      },
    };

    if (isReplanningAfterIssue) {
      if (!issueReason) return;

      resendJobDueToIssue(vars);
    } else {
      sendJob(vars);
    }
  };

  const jobType = job.type === JobType.PTC ? 'Schouw' : 'Installatie';
  const requestPlanningText = jobPreviouslySent
    ? 'Planning opnieuw aanvragen'
    : `${jobType}opdracht verzenden...`;

  const requestPlanningButtonDisabled = Boolean(
    !job ||
      // TODO:  If we implement job types (installation/ptc) change this to check that correct email is present
      !job.installer.installationEmail ||
      !job.installer.inspectionEmail ||
      !job.installer.customerNotificationPeriod ||
      (isReplanningAfterIssue && !issueReason),
  );

  return (
    <Modal
      isOpen={true}
      onRequestClose={close}
      mobile={mobile}
      title={`${requestPlanningText} - ${job.installer?.name || '-'}`}
      size="md"
      buttons={[
        {
          bgColor: jobPreviouslySent && !job.currentPlanning?.suspended ? 'gray' : 'orange',
          disabled: requestPlanningButtonDisabled,
          iconStart: jobPreviouslySent && !job.currentPlanning?.suspended ? Warning : PaperAirplane,
          label: isSent ? 'Verzonden' : loading ? 'Laden...' : requestPlanningText,

          loading,
          onClick: handleSubmit,
        },
        {
          label: 'Sluiten',
          bgColor: 'red',
          onClick: close,
        },
      ]}
    >
      <Flex flexWrap="wrap" mb={2}>
        <Box width={[1, 1, 1 / 2]} px={1}>
          <Checkbox
            disabled={!!sendJobResult.data?.sendJob.installerEmailSent}
            bgColor="orange"
            id={jobType + 'Stuur inst. e-mail'}
            label="Stuur installateur e-mail"
            checked={sendToInstaller}
            onChange={() => setSendToInstaller(!sendToInstaller)}
            value={true}
            width="100%"
          />
        </Box>
        <Box width={[1, 1, 1 / 2]} px={1}>
          <Checkbox
            disabled={!!sendJobResult.data?.sendJob.customerEmailSent}
            bgColor="blue"
            id={jobType + 'Stuur ook de klant e-mail'}
            label="Stuur klant e-mail"
            checked={sendToCustomer}
            onChange={() => setSendToCustomer(!sendToCustomer)}
            value={true}
            width="100%"
          />
        </Box>

        <Box width={[1]} px={1}>
          <Checkbox
            bgColor="purple"
            id={jobType + 'raiseJobIssue'}
            label="Opnieuw plannen vanwege issue/schade/incompleet"
            checked={isReplanningAfterIssue}
            onChange={() => setReplanningAfterIssue(!isReplanningAfterIssue)}
            value={true}
            width="100%"
          />
          {!job.mostRecentlySentPlanning && isReplanningAfterIssue && (
            <Smaller>
              <Icon icon={Warning} solid fill="orange" m={2} />
              Deze opdracht is nog niet eerder verstuurd, weet je zeker dat je deze opnieuw wilt
              plannen vanwege een issue?
            </Smaller>
          )}
        </Box>

        {isReplanningAfterIssue ? (
          <Box width={[1]}>
            <Select<JobIssueReason>
              clearable={false}
              label="Reden van de issue"
              value={jobIssueOptions.find(option => option.value === issueReason)}
              options={jobIssueOptions}
              onChange={e => setIssueReason(e.value)}
            />
            <Textarea
              value={additionalReplanningInformation}
              onChange={e => setAdditionalReplanningInformation(e.target.value)}
              label="Extra informatie"
            />
          </Box>
        ) : (
          <>
            <Box width={1}>
              {/* could provide some presets, e.g. previous installer is busy: would take too long. Or their stock ran out */}
              <Textarea
                value={installerSelectionReason}
                onChange={e => setInstallerSelectionReason(e.target.value)}
                label="Toelichting voor installateurkeuze naar klant (optioneel)"
                disabled={!sendToCustomer}
                placeholder="Bijvoorbeeld: 'Deze installateur is gekozen omdat dat installateur die hiervoor gekozen was het product momenteel niet op voorraad heeft.'"
              />
            </Box>
            <Small>
              Als je standaardtekstjes hiervoor hebt, stuur ze op naar Dev, dan kunnen we daar een
              dropdown voor maken!
            </Small>
          </>
        )}
      </Flex>
      {jobPreviouslySent && (
        <Small>
          <Gray>
            LET OP! Deze {jobType} opdracht was verstuurd op{' '}
            {dayjs(job.mostRecentlySentPlanning?.sent).format('DD/MM/YY')}.
          </Gray>
        </Small>
      )}

      {sendJobResult?.data?.sendJob.errors && <Small>{sendJobResult?.data.sendJob.errors}</Small>}

      {error && (
        <Small>
          <Red>{error.message}</Red>
        </Small>
      )}
    </Modal>
  );
};

export default RequestPlanningModal;
