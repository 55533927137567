import { Button, Modal } from '@energiebespaarders/symbols';
import React, { useState } from 'react';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { unfinishedJobs_unfinishedJobs } from '../../../types/generated/unfinishedJobs';
import AddRatingForm from './AddRatingForm';

const CompleteJobForm = React.lazy(() => import('./CompleteJobForm'));

type AddRatingModalProps = {
  isOpen: boolean;
  updateCache: (...args: any) => any;
  onClose: () => void;
  job: unfinishedJobs_unfinishedJobs;
};

const AddRatingModal: React.FC<AddRatingModalProps> = ({ isOpen, onClose, job, updateCache }) => {
  const mobile = useIsMobile();

  const [fullRatingForm, setFullRatingForm] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      mobile={mobile}
      onRequestClose={onClose}
      title={`Beoordeling voor ${job.installer.name}`}
      contentPadding={4}
      size="md"
      shouldCloseOnOverlayClick={false}
    >
      <Button
        onClick={() => setFullRatingForm(!fullRatingForm)}
        label={fullRatingForm ? 'Klant onbereikbaar?' : 'Klant wel bereikbaar?'}
        minimal
        bgColor="blue"
      />

      {fullRatingForm ? (
        <AddRatingForm
          job={job}
          updateCache={updateCache}
          onClose={onClose}
          onCompleted={onClose}
        />
      ) : (
        <CompleteJobForm jobId={job.id} quoteId={job.quote.id} onClose={onClose} />
      )}
    </Modal>
  );
};

export default AddRatingModal;
