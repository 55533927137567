import { Accordion, Card } from '@energiebespaarders/symbols';
import { AccordionProps } from '@energiebespaarders/symbols/components/Accordion';
import { EnergyCircle } from '@energiebespaarders/symbols/icons/solid';
import React, { useEffect, useState } from 'react';
import { installationByHouseSolution_installationByHouseSolution as t_installation } from '../../types/generated/installationByHouseSolution';
import Calculations from '../Calculations';

interface InstallationCalculationsProps {
  hasDeals: boolean;
  installation?: t_installation | null;
  isNarrow: boolean;
  useEstimate: boolean;
  wrapInAccordion?: boolean;
}

const InstallationCalculations = ({
  hasDeals,
  installation,
  isNarrow,
  useEstimate,
  wrapInAccordion,
}: InstallationCalculationsProps) => {
  const [openCalculations, setOpenCalculations] = useState(!!installation);

  useEffect(() => {
    if (!!installation && !openCalculations) setOpenCalculations(true);
    else if (!installation && openCalculations) setOpenCalculations(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installation]);

  const sharedAccordionProps: Partial<AccordionProps> = {
    baseBgColor: 'white',
    bgColor: 'grayLighter',
    contentBorderColor: 'grayLight',
    contentPadding: isNarrow ? 2 : 4,
    icon: EnergyCircle,
    iconColor: 'red',
    defaultOpen: Boolean(!isNarrow && openCalculations),
    isOpened: openCalculations,
    override: true,
    title: 'Berekeningen',
    zIndex: 300,
  };

  if (!installation?.energyDelta) {
    return (
      <Card p={0} mb={4} zIndex={250} animation="none" style={{ overflowY: 'hidden' }}>
        <Accordion {...sharedAccordionProps}>Er is nog geen installatie</Accordion>
      </Card>
    );
  }

  const renderCalculations = () => (
    <Calculations useEstimate={useEstimate} isNarrow={isNarrow} installable={installation} />
  );
  return wrapInAccordion ? (
    <Card p={0} mb={4} zIndex={250} animation="none" style={{ overflowY: 'hidden' }}>
      <Accordion
        {...sharedAccordionProps}
        iconColor={installation.id || hasDeals ? (useEstimate ? 'greenLight' : 'green') : 'red'}
        onClick={() => setOpenCalculations(!openCalculations)}
        title={`${
          installation.id && useEstimate ? 'Schattingen' : 'Berekeningen'
        } voor deze installatie`}
      >
        {renderCalculations()}
      </Accordion>
    </Card>
  ) : (
    renderCalculations()
  );
};

export default InstallationCalculations;
