import { useMutation } from '@apollo/client';
import { Solution, SOLUTION_TOPICS } from '@energiebespaarders/constants';
import { Box, Icon, Tooltip } from '@energiebespaarders/symbols';
import { Info } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import { useMe } from '../../../hooks/useMe';
import useToaster from '../../../hooks/useToaster';
import { UPDATE_COMMENTS_FOR_INSTALLER } from '../../../queries/installatron';
import {
  jobDomainQuote_quoteById,
  jobDomainQuote_quoteById_jobs,
} from '../../../types/generated/jobDomainQuote';
import WYSIWYG from '../../WYSIWYG';

interface JobPdfRemarksProps {
  job: jobDomainQuote_quoteById_jobs;
  quote: jobDomainQuote_quoteById;
}

const JobPdfRemarks: React.FC<JobPdfRemarksProps> = ({ job, quote }) => {
  const { me } = useMe();
  const toast = useToaster();

  const { activeHouseId } = useActiveHouseId();
  const [wysiwygContent, setWysiwygContent] = useState(job.commentsForInstaller || '');

  const [updateCommentsForInstaller] = useMutation(UPDATE_COMMENTS_FOR_INSTALLER, {
    onCompleted: () => {
      toast({
        type: 'success',
        message: 'Opmerking is opgeslagen',
      });
    },
  });

  function handleCommentsForInstaller(jobId: string, wysiwygContent: string) {
    setWysiwygContent(wysiwygContent);
    updateCommentsForInstaller({
      variables: { commentsForInstaller: wysiwygContent, jobId },
    });
  }

  return (
    <Box width={1} px={1} mb={1}>
      <h5>
        <Tooltip
          content="De informatie die je hier invult, kun je terugvinden onder het kopje 'Extra informatie van de adviseur' op pagina 2 van de installatie PDF"
          bgColor="blue"
        >
          <Icon icon={Info} solid fill="gray" hoverColor="blue" />
        </Tooltip>
        {'  '}
        Extra informatie voor de installateur
      </h5>
      <WYSIWYG
        operatorId={me.id}
        houseId={activeHouseId}
        topic={SOLUTION_TOPICS[quote.solution as Exclude<Solution, typeof Solution.Miscellaneous>]}
        placeholder="Typ hier een algemene opmerking voor de installateur"
        value={wysiwygContent && JSON.parse(wysiwygContent)}
        handleSave={(content: any) => handleCommentsForInstaller(job.id, content)}
      />
    </Box>
  );
};

export default JobPdfRemarks;
