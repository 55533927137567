import { Button } from '@energiebespaarders/symbols';
import { Small } from '@energiebespaarders/symbols/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import Tour from 'reactour';
import styled from 'styled-components';
import { version } from '../../package.json';
import { defaultPopupStyles } from '../domains/Dashboard/tour';
import { useServiceWorker } from '../hooks/useServiceWorker';
import { themify } from '../styles/mixins';

const VersionUpdaterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function VersionUpdater() {
  const context = useServiceWorker() as any;
  const [showNotification, setShowNotification] = useState(Boolean(context.assetsUpdateReady));

  useEffect(() => setShowNotification(Boolean(context.assetsUpdateReady)), [
    context.assetsUpdateReady,
  ]);

  const handleHideNotification = useCallback(() => setShowNotification(false), [
    setShowNotification,
  ]);

  const handleConfirm = useCallback(() => {
    context.updateAssets();
    // In case the asset updating doesn't work (or was already done in another tab), force a refresh
    setTimeout(() => window.location.reload(), 3000);
  }, [context]);

  const Content = () => (
    <>
      <p>
        {' '}
        <br /> Er is een nieuwe versie beschikbaar
      </p>
      <VersionUpdaterButtonContainer>
        <Button label="Updaten" onClick={handleConfirm} fontSize={6} m={0} />
      </VersionUpdaterButtonContainer>
    </>
  );

  if (showNotification) {
    return (
      <Tour
        accentColor={themify('green')}
        badgeContent={() => <Small>v{version}</Small>}
        isOpen={showNotification}
        onRequestClose={handleHideNotification}
        closeWithMask={false}
        showNavigation={false}
        showButtons={false}
        steps={[
          {
            content: Content,
            style: defaultPopupStyles,
          },
        ]}
      />
    );
  }
  return <></>;
}

export default VersionUpdater;
