import { useLazyQuery, useMutation } from '@apollo/client';
import {
  BrandName,
  PRODUCT_CATEGORIES_NL,
  SOLUTION_DOMAIN_PRODUCT_CATEGORIES,
  SOLUTION_PRODUCT_CATEGORIES,
  Solution,
  SolutionDomain,
  constants,
} from '@energiebespaarders/constants';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Input,
  Placeholder,
  Select,
  Tab,
  Tabs,
  Toast,
  theme,
} from '@energiebespaarders/symbols';
import { CheckCircle } from '@energiebespaarders/symbols/icons/line';
import { Add, Edit, Ellipsis, Transfer } from '@energiebespaarders/symbols/icons/solid';
import React, { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHasPricingPermission } from '../../../hooks/useHasPricingPermission';
import { useMe } from '../../../hooks/useMe';
import useToaster from '../../../hooks/useToaster';
import { currencyFilter, fixUnit } from '../../../lib/utils';
import {
  ADD_ITEM_TO_INSTALLATION,
  INSTALLATION_BY_HOUSE_SOLUTION,
  UPDATE_INSTALLATION,
} from '../../../queries/installatron';
import {
  addProductToInstallation,
  addProductToInstallationVariables,
} from '../../../types/generated/addProductToInstallation';
import {
  getInstallationPartners,
  getInstallationPartnersVariables,
  getInstallationPartners_installersBySolution as t_installationPartner,
} from '../../../types/generated/getInstallationPartners';
import {
  getPrice as getPriceQuery,
  getPriceVariables,
  getPrice_getPrice,
} from '../../../types/generated/getPrice';
import {
  getViableProducts,
  getViableProductsVariables,
  getViableProducts_allProductsBySolutionDomain as t_product,
} from '../../../types/generated/getViableProducts';
import {
  installationByHouseSolution,
  installationByHouseSolutionVariables,
  installationByHouseSolution_installationByHouseSolution as t_installation,
  installationByHouseSolution_installationByHouseSolution_items as t_item,
} from '../../../types/generated/installationByHouseSolution';
import { me_me_Operator } from '../../../types/generated/me';
import {
  updateInstallation,
  updateInstallationVariables,
} from '../../../types/generated/updateInstallation';
import {
  Solution as GqlSolution,
  InstallerStatus,
  ProductCategory,
} from '../../../types/graphql-global-types';
import { OperatorRole, OperatorTeam } from '../../operatorDirectory/OperatorProfile';
import { getInstallationConfiguratorNotifications } from '../utils';
import RetailPriceInput from './CalculatedPrice';
import ConfiguratorNotifications from './ConfiguratorNotifications';
import { InstallationPartnerOption, ProductOption, SelectFilterOption } from './DropdownOptions';
import { GET_INSTALLATION_PARTNERS, GET_PRICE, GET_VIABLE_PRODUCTS } from './queries';

// All Products are fetched for the solution with isSuppliable (in stock) and isInstallable (has installerId whose region contains zip)
// All Installation Partners (=> Suppliers) are fetched for the given solution
//
// If no Installation Partner selected:
// - Allows user to select any product that is currently suppliable and installable in their area
// - When a product is selected, Installation Partners are filtered by who has a price for that product
//
// If Installation Partner is selected first:
// - Disables all product options that are not suppliable by the selected supplier

type ProductOption = { value: string; label: ReactNode; searchValue: string; disabled?: boolean };
type InstallerOption = { value: string; label: ReactNode; searchValue: string; disabled?: boolean };

type IItemConfiguratorProps = {
  installation: t_installation;
  houseId: string;
  solution: Solution;
  solutionDomain: SolutionDomain;
  editing: t_item | undefined;
  setEditing?: (i: number | null) => void;
};

/**
 * Block Sales from picking archived products.
 * Only allowed for Planning and power users
 */
function isAllowedToPickArchivedProduct({ team, role }: me_me_Operator) {
  return (
    [OperatorTeam.Development, OperatorTeam.MT, OperatorTeam.Planning].includes(
      team as OperatorTeam,
    ) || [OperatorRole.Admin, OperatorRole.Manager].includes(role as OperatorRole)
  );
}

const RetainPricesOverview: React.FC<{ editingPrice: t_item; currentPrice: getPrice_getPrice }> = ({
  editingPrice,
  currentPrice,
}) => {
  const retailDiff = editingPrice.retailPrice - currentPrice.retailPrice;
  const purchaseDiff = editingPrice.purchasePrice - currentPrice.purchasePrice;
  return (
    <Toast
      type="warning"
      width="100%"
      message={
        <Flex flexWrap="wrap">
          <Box width={1}>De prijzen in Installatron wijken af van het huidige aanbod:</Box>
          <Box width={1 / 4} />
          <Box width={1 / 4}>Prijs in Installatron</Box>
          <Box width={1 / 4}>Huidig aanbod</Box>
          <Box width={1 / 4}>Verschil</Box>

          <Box width={1 / 4}>Verkoopprijs</Box>
          <Box width={1 / 4}>€{editingPrice?.retailPrice?.toFixed(2)}</Box>
          <Box width={1 / 4}>€{currentPrice.retailPrice?.toFixed(2) || '...'}</Box>
          <Box width={1 / 4}>
            {retailDiff ? `€${retailDiff > 0 ? '+' : ''}${retailDiff.toFixed(2)}` : '-'}
          </Box>

          <Box width={1 / 4}>Inkoopprijs</Box>
          <Box width={1 / 4}>€{editingPrice?.purchasePrice?.toFixed(2)}</Box>
          <Box width={1 / 4}>€{currentPrice.purchasePrice?.toFixed(2) || '...'}</Box>
          <Box width={1 / 4}>
            {purchaseDiff ? `€${purchaseDiff > 0 ? '+' : ''}${purchaseDiff.toFixed(2)}` : '-'}
          </Box>
          <Box pt={1}>
            Kies alleen om de prijzen te behouden goede reden, bijvoorbeeld als je een wijziging
            doorvoert na een schouw.
          </Box>
        </Flex>
      }
    />
  );
};

// const productCategories: Record<Solution, ProductCategory[]> = {

// }

const ItemConfigurator: React.FC<IItemConfiguratorProps> = ({
  installation,
  houseId,
  solution,
  solutionDomain,
  editing,
  setEditing,
}) => {
  const toast = useToaster();
  const { me } = useMe();

  const hasPricingPermission = useHasPricingPermission();

  const [selectedProduct, setSelectedProduct] = useState(undefined as undefined | t_product);

  const [isRetainingPrices, setRetainingPrices] = useState(false);

  const [
    fetchInstallationPartners,
    {
      data: installationPartnersData,
      loading: installationPartnersLoading,
      error: installationPartnersError,
      refetch: refetchInstallationPartners,
    },
  ] = useLazyQuery<getInstallationPartners, getInstallationPartnersVariables>(
    GET_INSTALLATION_PARTNERS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        solution: solution as GqlSolution,
        houseId,
        productId: selectedProduct?.id,
      },
    },
  );

  const [selectedInstallationPartner, setSelectedInstallationPartner] = useState<
    undefined | t_installationPartner
  >(
    // Restore the installer that was picked for the first item on the installation if exists
    installationPartnersData?.installersBySolution?.find(
      i => i.supplier.id === installation?.items?.[0]?.supplier?.id,
    ),
  );

  const productCategories = SOLUTION_PRODUCT_CATEGORIES[solution];

  const initialCategory = editing
    ? productCategories.find(c => c === editing.product?.category)
    : productCategories[0];

  const [amount, setAmount] = useState((editing?.amount && editing.amount.toFixed(0)) || '1');
  const [price, setPrice] = useState(
    (editing?.retailPrice && editing.retailPrice!.toFixed(0)) || '',
  );
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  useEffect(() => {
    if (categoriesLoading) setTimeout(() => setCategoriesLoading(false), 150);
  }, [categoriesLoading, setCategoriesLoading]);

  const [getPrice, { data: priceData, loading: priceLoading, error: priceError }] = useLazyQuery<
    getPriceQuery,
    getPriceVariables
  >(GET_PRICE, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      const value = res.getPrice.tieredRetailPrice ?? res.getPrice.retailPrice;
      setPrice(value.toFixed(2));
    },
  });

  // TODO: The UI shouldn't change depending on your permission, we should just add blocks. Fix this later!
  const isRetainPricesAllowed =
    !priceLoading &&
    editing?.product?.id === selectedProduct?.id &&
    editing?.supplier?.id === selectedInstallationPartner?.supplier.id &&
    (editing?.retailPrice !== priceData?.getPrice.retailPrice ||
      editing?.purchasePrice !== priceData?.getPrice.purchasePrice);

  const toggleRetainPrices = useCallback(() => {
    if (!editing || !priceData?.getPrice) return;
    setRetainingPrices(!isRetainingPrices);

    if (!isRetainingPrices) {
      // Reset product, installer and retail price to the values saved on the installation
      setPrice(editing.retailPrice!.toFixed(2));
    } else {
      const curRetailPrice = priceData.getPrice.tieredRetailPrice ?? priceData.getPrice.retailPrice;

      setPrice(curRetailPrice!.toFixed(2));
    }
  }, [editing, isRetainingPrices, priceData?.getPrice]);

  // Queries:
  //  GET_VIABLE_PRODUCTS => products installable AND suppliable to the house
  //  GET_INSTALLATION_PARTNERS => suppliers with connected installers and their regions
  const [
    fetchProducts,
    {
      data: productsData,
      loading: productsLoading,
      error: productsError,
      refetch: refetchProducts,
    },
  ] = useLazyQuery<getViableProducts, getViableProductsVariables>(GET_VIABLE_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      solutionDomain,
      supplierId: selectedInstallationPartner && selectedInstallationPartner.supplier.id,
    },
  });

  // Mutations:
  //  ADD_ITEM_TO_INSTALLATION => add a new product
  //  UPDATE_INSTALLATION => update current installation items
  const [addToInstallation, { loading: addToInstallationLoading }] = useMutation<
    addProductToInstallation,
    addProductToInstallationVariables
  >(ADD_ITEM_TO_INSTALLATION, {
    onCompleted: () => {
      setSelectedProduct(undefined);
      setAmount('1');
      setPrice('');
    },
    update: (cache, { data }) => {
      if (data?.addProductToInstallation) {
        const queryData = cache.readQuery<
          installationByHouseSolution,
          installationByHouseSolutionVariables
        >({
          query: INSTALLATION_BY_HOUSE_SOLUTION,
          variables: { houseId, solution: solution as GqlSolution },
        });

        if (queryData) {
          const updatedInstallation = { ...installation, ...data.addProductToInstallation };
          cache.writeQuery<installationByHouseSolution, installationByHouseSolutionVariables>({
            query: INSTALLATION_BY_HOUSE_SOLUTION,
            variables: { houseId, solution: solution as GqlSolution },
            data: { installationByHouseSolution: updatedInstallation },
          });
        }
      }
    },
  });

  const [updateInstallation] = useMutation<updateInstallation, updateInstallationVariables>(
    UPDATE_INSTALLATION,
    {
      onCompleted: () => setEditing!(null),
    },
  );

  // Collect initial data when no data is present already
  useEffect(() => {
    if (!productsData && !productsLoading && !productsError) {
      fetchProducts();
    }
    if (!installationPartnersData && !installationPartnersLoading && !installationPartnersError) {
      fetchInstallationPartners();
    }

    // Populate the fields if editing with the current values
    if (productsData && editing && editing.product && !selectedProduct)
      setSelectedProduct(
        productsData.allProductsBySolutionDomain.find(p => p.id === editing.product!.id),
      );
    if (installationPartnersData && editing && editing.supplier && !selectedInstallationPartner) {
      setSelectedInstallationPartner(
        installationPartnersData.installersBySolution.find(
          p => p.supplier.id === editing.supplier!.id,
        ),
      );
    }
  }, [
    fetchProducts,
    fetchInstallationPartners,
    productsData,
    installationPartnersData,
    editing,
    selectedInstallationPartner,
    selectedProduct,
    productsLoading,
    installationPartnersLoading,
    installationPartnersError,
    productsError,
  ]);

  // Refetch the data when information in dropdowns are changed
  useEffect(() => {
    if (!selectedProduct && !productsLoading && !productsError) {
      fetchProducts();
    }
    // Intentionally excluding loading from deps to avoid infinite request loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProducts, productsError, selectedProduct]);

  useEffect(() => {
    if (
      !selectedInstallationPartner &&
      !installationPartnersLoading &&
      !installationPartnersError
    ) {
      fetchInstallationPartners();
    }
    // Intentionally excluding loading from deps to avoid infinite request loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchInstallationPartners, installationPartnersError, selectedInstallationPartner]);
  useEffect(() => {
    if (selectedProduct && selectedInstallationPartner) {
      getPrice({
        variables: {
          productId: selectedProduct.id,
          supplierId: selectedInstallationPartner.supplier.id,
          amount: parseFloat(amount || '1'),
        },
      });
    }
  }, [amount, getPrice, selectedInstallationPartner, selectedProduct]);

  // Reset the category when the solution is changed to clear filters
  useEffect(() => {
    setSelectedCategory(initialCategory);
  }, [solution, setSelectedCategory, initialCategory]);

  // Reset the product when the category is changed
  useEffect(() => {
    setSelectedProduct(undefined);
  }, [selectedCategory]);

  // On mount (or when installers are loaded): Restore the selected installer if there already was an item added
  useEffect(() => {
    if (installation.items.length && !selectedInstallationPartner) {
      const installer = installationPartnersData?.installersBySolution.find(
        i => i.supplier.id === installation.items[0].supplier.id,
      );
      setSelectedInstallationPartner(installer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationPartnersData?.installersBySolution]);

  // Handle submission of the form (whether to add or edit an item)
  const handleSubmit = useCallback(async () => {
    if (editing) {
      if (!priceData?.getPrice) {
        toast({ message: 'Er is iets mis gegaan met het bepalen van de prijs', type: 'error' });
        return;
      }

      const installationItems = installation.items.map(i => {
        if (editing.product!.id === i.product!.id && editing.supplier!.id === i.supplier!.id) {
          return {
            productId: selectedProduct!.id,
            supplierId: selectedInstallationPartner!.supplier.id,
            amount: Number(amount),
            retailPrice: Number(price),
            // When modifying an item, use the purchase price fetched from the DB
            // (so that changing the installer also updates the purchase price accordingly)
            // ..but not if explicitly retaining the prices saved on the installation, e.g. restored from an existing quote that is being modified
            purchasePrice: isRetainingPrices ? i.purchasePrice : priceData.getPrice.purchasePrice!,
          };
        } else {
          return {
            productId: i.product!.id,
            supplierId: i.supplier!.id,
            amount: i.amount,
            retailPrice: i.retailPrice,
            purchasePrice: i.purchasePrice,
          };
        }
      });
      updateInstallation({
        variables: {
          id: installation.id,
          installation: {
            id: installation.id,
            solution: installation.solution,
            items: installationItems,
          },
        },
      });
    } else {
      if (installation.items.some(i => i.product && i.product.id === selectedProduct!.id)) {
        toast({
          type: 'error',
          message: 'Dit product is reeds toegevoegd. Pas eventueel het aantal aan.',
        });
      } else {
        const purchasePrice = priceData?.getPrice.purchasePrice;
        if (typeof purchasePrice !== 'number') {
          toast({
            type: 'error',
            message: 'Er is iets mis gegaan met het bepalen van de prijs, probeer het opnieuw.',
          });
          return;
        }
        addToInstallation({
          variables: {
            id: installation.id,
            item: {
              productId: selectedProduct!.id,
              amount: Number(amount),
              supplierId: selectedInstallationPartner!.supplier.id,
              retailPrice: Number(price),
              purchasePrice: purchasePrice!,
            },
          },
        });
      }
    }
  }, [
    editing,
    priceData?.getPrice,
    installation.items,
    installation.id,
    installation.solution,
    updateInstallation,
    toast,
    selectedProduct,
    selectedInstallationPartner,
    amount,
    price,
    isRetainingPrices,
    addToInstallation,
  ]);

  const productOptions = useCallback(
    (category: ProductCategory): ProductOption[] => {
      if (!productsData) return [];
      let suggestedOption: ProductOption | undefined = undefined;

      const isSolutionSpecific = SOLUTION_DOMAIN_PRODUCT_CATEGORIES[solutionDomain].find(
        c => c.name === category,
      )?.solutionSpecific;

      // Suggest the labor product corresponding to a material product
      if (category === ProductCategory.Labor) {
        const materialProduct = installation.items.find(i =>
          [
            ProductCategory.RoofInsulationMaterial,
            ProductCategory.WallInsulationMaterial,
            ProductCategory.FloorInsulationMaterial,
          ].includes(i.product.category),
        );
        if (materialProduct) {
          const lowercasedMaterialTitle = materialProduct.product.title?.toLowerCase() || '';
          const laborProduct = productsData.allProductsBySolutionDomain.find(
            p =>
              p.category === ProductCategory.Labor &&
              // Usually "Aanbrengen van <material title>"
              p.title?.toLowerCase().includes(lowercasedMaterialTitle),
          );
          if (laborProduct) {
            suggestedOption = {
              value: laborProduct.id,
              label: (
                <ProductOption
                  key={`suggested-${laborProduct.id}`}
                  product={laborProduct}
                  selectedInstallationPartner={selectedInstallationPartner?.name}
                  hint="💡 Suggestie bij materiaal"
                />
              ),
              disabled: !laborProduct.isSuppliable,
              searchValue: laborProduct.title || '',
            };
          }
        }
      }

      const options = productsData.allProductsBySolutionDomain
        .filter((product: t_product) => {
          const isHybridOrElectricHP =
            solution === Solution.HybridHeatPump || solution === Solution.ElectricHeatPump;
          if (
            isHybridOrElectricHP &&
            category === ProductCategory.HeatPump &&
            product.__typename === 'HeatPump' &&
            product.brand?.name === BrandName.LG
          ) {
            // TODO: Ugly edge case #1: LG heat pumps can be used as hybrid and all-electric
            return true;
          } else if (
            solution === Solution.HybridHeatPump &&
            category === ProductCategory.HeatPump
          ) {
            // TODO: Ugly edge case #2: Vaillant all-electric heat pumps can also be used as hybrid
            // We don't support multiple solutions per product, so just explicitly including them in the product options here
            if (
              product.solution === Solution.ElectricHeatPump &&
              product.__typename === 'HeatPump' &&
              product.brand?.name === BrandName.Vaillant
            ) {
              return true;
            }
            // We need to be able to see the cv products in the hybrid dropdowns
            return (
              (product.category === ProductCategory.HeatPump &&
                product.solution === Solution.HybridHeatPump) ||
              product.category === ProductCategory.CentralHeatingBoiler
            );
          } else if (
            isHybridOrElectricHP &&
            [ProductCategory.Labor, ProductCategory.Service, ProductCategory.Other].includes(
              category,
            )
          ) {
            // TODO: extension of edge case 1 & 2 for heatpump solutions: other categories like labor must also be visible in other HP solution
            return product.category === category;
          } else if (isSolutionSpecific) {
            // If not we can separate out the main products and the labor between the solutions where there is multiple within the domain
            return product.solution === solution && product.category === selectedCategory;
          } else {
            return product.category === selectedCategory;
          }
        })
        // Sort alphanumerically, with not-suppliable ones at the bottom, archived ones above that, suppliable ones at the top
        // (archived products may need to be still used for follow-up quotes, e.g. after a PTC. Can hide them after job-mutations are a thing)
        .sort((a, b) =>
          `${a.isSuppliable ? 0 : 1}${a.archived ? 1 : 0}${a.title}`.localeCompare(
            `${b.isSuppliable ? 0 : 1}${b.archived ? 1 : 0}${b.title}`,
            'nl',
            { numeric: true },
          ),
        )
        .map((product: t_product) => ({
          value: product.id,
          label: (
            <ProductOption
              key={product.id}
              product={product}
              selectedInstallationPartner={selectedInstallationPartner?.name}
            />
          ),
          disabled:
            !product.isSuppliable || (product.archived && !isAllowedToPickArchivedProduct(me)),
          searchValue: product.title || '',
        }));
      return suggestedOption && suggestedOption.value !== options[0]?.value
        ? [suggestedOption, ...options]
        : options;
    },
    [
      installation.items,
      me,
      productsData,
      selectedCategory,
      selectedInstallationPartner?.name,
      solution,
      solutionDomain,
    ],
  );

  const handleChangeProduct = useCallback(
    (e: { value: string }) =>
      setSelectedProduct(
        e
          ? productsData?.allProductsBySolutionDomain.find((i: t_product) => i.id === e.value)
          : undefined,
      ),
    [productsData],
  );

  const handleRefetchProducts = useCallback(() => {
    if (refetchProducts) {
      refetchProducts({
        solutionDomain,
        ...(selectedInstallationPartner
          ? {
              installerId: selectedInstallationPartner.id,
              supplierId: selectedInstallationPartner.supplier.id,
            }
          : {}),
      });
    }
  }, [refetchProducts, selectedInstallationPartner, solutionDomain]);

  const installerOptions: InstallerOption[] = useMemo(() => {
    if (!installationPartnersData) return [];
    const sortValue = (i: t_installationPartner) =>
      `${
        i.capableOfInstallingAtHouse &&
        i.installationRegions.length > 0 &&
        (!selectedProduct || (i.capableOfInstallingProduct && i.supplier.capableOfSupplyingProduct))
          ? 0
          : 1
      }${i.name}`;
    return [...installationPartnersData.installersBySolution]
      .sort((a, b) => sortValue(a).localeCompare(sortValue(b), 'nl', { numeric: true }))
      .map((ip: t_installationPartner) => ({
        value: ip.id,
        label: (
          <InstallationPartnerOption
            key={ip.id}
            installationPartner={ip}
            productSelected={Boolean(selectedProduct)}
          />
        ),
        disabled:
          (!selectedProduct &&
            (!ip.capableOfInstallingAtHouse || ip.installationRegions.length === 0)) ||
          (selectedProduct &&
            (!ip.capableOfInstallingProduct ||
              !ip.supplier.capableOfSupplyingProduct ||
              !ip.capableOfInstallingAtHouse ||
              ip.status.value === InstallerStatus.inactive)),
        searchValue: ip.name,
      }));
  }, [installationPartnersData, selectedProduct]);

  const handleChangeInstaller = useCallback(
    (e: { value: string }) =>
      setSelectedInstallationPartner(
        e
          ? installationPartnersData?.installersBySolution.find(
              (i: t_installationPartner) => i.id === e.value,
            )
          : undefined,
      ),
    [installationPartnersData],
  );

  const refetchInstallers = useCallback(() => {
    if (refetchInstallationPartners) {
      refetchInstallationPartners({
        solution,
        houseId,
        ...(selectedProduct ? { productId: selectedProduct.id } : {}),
      });
    }
  }, [houseId, refetchInstallationPartners, selectedProduct, solution]);

  const { defaultMarkup } = constants;

  const purchasePrice = useMemo(
    () =>
      selectedProduct &&
      selectedInstallationPartner &&
      defaultMarkup &&
      priceData?.getPrice?.purchasePrice,
    [defaultMarkup, priceData, selectedProduct, selectedInstallationPartner],
  );

  const tooExpensive = useMemo(
    () =>
      purchasePrice && defaultMarkup
        ? purchasePrice * (1 + defaultMarkup[solution].max) < Number(price)
        : false,
    [defaultMarkup, price, purchasePrice, solution],
  );

  const handleChangeCategory = useCallback(
    (category: ProductCategory) => {
      if (category !== selectedCategory) {
        setSelectedCategory(category);
        setCategoriesLoading(true);
      }
    },
    [selectedCategory],
  );

  // If there is an item on the installation with the same price unit (e.g. m2),
  // show a hint to set the current amount to that amount
  const amountShortcut = useMemo(() => {
    const existingAmount = installation.items.find(
      item => selectedProduct?.priceUnit === item.product.priceUnit,
    )?.amount;
    if (existingAmount && existingAmount.toString() !== amount) {
      const suggestedAmountStr = existingAmount.toFixed(0);
      return (
        <a onClick={() => setAmount(suggestedAmountStr)}>
          Gebruik {suggestedAmountStr}
          {/* Adding the unit messes up the vertical alignment */}
          {/* {fixUnit(selectedProduct?.priceUnit)} */}
        </a>
      );
    }
  }, [amount, installation.items, selectedProduct?.priceUnit]);

  const configuratorNotifications = useMemo(
    () =>
      priceData && selectedInstallationPartner
        ? getInstallationConfiguratorNotifications(
            solution,
            tooExpensive,
            priceData.getPrice,
            selectedInstallationPartner,
          )
        : [],
    [priceData, selectedInstallationPartner, solution, tooExpensive],
  );

  if (productsError || installationPartnersError) {
    return <Placeholder error={productsError || installationPartnersError} />;
  }

  const isUsingDefaultMarkup = Boolean(
    purchasePrice &&
      defaultMarkup &&
      Math.round(Number(price)) ===
        Math.round(purchasePrice * (1 + defaultMarkup[solution].default)),
  );

  return (
    <>
      <Tabs
        tabBgColor="grayLighter"
        zIndex={500}
        initialActiveTab={selectedCategory ? productCategories.indexOf(selectedCategory) : 0}
        shadow="none"
      >
        {productCategories.map(category => {
          const opts = productOptions(category);
          return (
            <Tab
              key={`add-${category}-${solution}`}
              onClick={() => handleChangeCategory(category)}
              label={
                solution === Solution.HybridHeatPump && category === ProductCategory.HeatPump
                  ? 'Warmtepomp/CV'
                  : PRODUCT_CATEGORIES_NL[category]
              }
              icon={
                installation.items.some(i => i.product!.category === category) ? CheckCircle : null
              }
              p={2}
            >
              <Flex mx={-1} flexWrap="wrap">
                <Box width={[1, 1, 10 / 21]} px={1} py={1}>
                  <Flex flexDirection="column" alignItems="center" justifyContent="center">
                    <Box width={1}>
                      {!productsLoading && productsData?.allProductsBySolutionDomain ? (
                        <Select
                          id="installatron-product-input"
                          label="Geschikte producten"
                          zIndex={theme.z.toast + 10}
                          refetchOptions={handleRefetchProducts}
                          disabled={
                            categoriesLoading ||
                            !(!productsLoading && productsData?.allProductsBySolutionDomain) ||
                            isRetainingPrices
                          }
                          placeholder={
                            categoriesLoading ||
                            !(!productsLoading && productsData?.allProductsBySolutionDomain)
                              ? 'Laden...'
                              : 'Kies product'
                          }
                          options={opts}
                          filterOption={(option: SelectFilterOption, searchString: string) =>
                            option?.data?.searchValue?.match(new RegExp(searchString, 'gi'))
                          }
                          onChange={handleChangeProduct}
                          value={opts.find(opt => opt.value === selectedProduct?.id)}
                          clearable
                        />
                      ) : (
                        // This is just a stand in while the products load
                        <Select
                          options={[]}
                          label="Geschikte producten"
                          placeholder="Producten laden..."
                          disabled={true}
                          onChange={() => null}
                        />
                      )}
                    </Box>
                  </Flex>
                </Box>
                <Box width={1 / 21}>
                  <Flex justifyContent="center" alignItems="center" height="100%">
                    <Icon icon={Transfer} fill="gray" solid />
                  </Flex>
                </Box>
                <Box width={[1, 1, 10 / 21]} px={1} py={1}>
                  <Flex flexDirection="column" alignItems="center" justifyContent="center">
                    <Box width={1}>
                      <Select
                        zIndex={theme.z.toast + 10}
                        label="Geschikte installatiepartners"
                        refetchOptions={() => refetchInstallers()}
                        placeholder="Kies installatiepartner"
                        options={installerOptions}
                        filterOption={(option: SelectFilterOption, searchString: string) =>
                          option?.data?.searchValue?.match(new RegExp(searchString, 'gi'))
                        }
                        onChange={handleChangeInstaller}
                        value={installerOptions.find(
                          opt => opt.value === selectedInstallationPartner?.id,
                        )}
                        loading={
                          installationPartnersLoading ||
                          !installationPartnersData?.installersBySolution
                        }
                        disabled={isRetainingPrices}
                        clearable
                      />
                    </Box>
                  </Flex>
                </Box>
              </Flex>

              <Flex alignItems="flex-end" mx={-1} flexWrap="wrap">
                <Box width={[1, 1, 1 / 2]} px={1} py={1}>
                  <Input
                    label={
                      <Flex justifyContent="space-between">
                        <Box>Aantal</Box>
                        <Box>{amountShortcut}</Box>
                      </Flex>
                    }
                    disabled={!(selectedProduct && selectedInstallationPartner)}
                    addonContent={
                      selectedProduct &&
                      selectedProduct.priceUnit !== 'unit' &&
                      fixUnit(selectedProduct.priceUnit)
                    }
                    addonSide="end"
                    min="1"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setAmount(currencyFilter(e.target.value))
                    }
                    placeholder="1"
                    type="number"
                    value={amount || ''}
                  />
                </Box>
                <Box width={[1, 1, 1 / 2]} px={1} py={1}>
                  {selectedProduct && selectedInstallationPartner ? (
                    <RetailPriceInput
                      price={price}
                      setPrice={setPrice}
                      selectedProduct={selectedProduct}
                      isFixedPrice={!isUsingDefaultMarkup}
                      hasPricingPermission={hasPricingPermission}
                      loading={priceLoading}
                      error={priceError}
                    />
                  ) : (
                    <Input
                      label={`Verkoopprijs ${
                        selectedProduct ? 'per ' + fixUnit(selectedProduct.priceUnit, true) : ''
                      } (ex. BTW)`}
                      placeholder=""
                      disabled
                      addonContent={'€'}
                      addonSide="start"
                    />
                  )}
                </Box>
                <Box width={[1, 1, 1 / 2]} px={1} py={1}>
                  {isRetainPricesAllowed && (
                    <Checkbox
                      id={`isRetainingPrices-${editing?.product.id}`}
                      label="Prijzen in Installatron behouden"
                      checked={isRetainingPrices}
                      value
                      bgColor="blue"
                      onChange={toggleRetainPrices}
                    />
                  )}
                </Box>
                <Box width={[1, 1, 1 / 2]} px={1} py={1}>
                  <Button
                    disabled={
                      addToInstallationLoading ||
                      !(selectedProduct && selectedInstallationPartner && amount && price) ||
                      configuratorNotifications.some(n => n.type === 'error')
                    }
                    fluid
                    label={`${Number(amount) > 1 ? 'Producten' : 'Product'} ${
                      editing ? 'aanpassen' : 'toevoegen'
                    }`}
                    iconStart={editing ? Edit : addToInstallationLoading ? Ellipsis : Add}
                    minIconSize="1em"
                    onClick={handleSubmit}
                  />
                </Box>
                {isRetainingPrices && priceData?.getPrice && editing && (
                  <RetainPricesOverview editingPrice={editing} currentPrice={priceData.getPrice} />
                )}
              </Flex>
              {!priceLoading && selectedInstallationPartner && (
                <ConfiguratorNotifications notifications={configuratorNotifications} />
              )}
            </Tab>
          );
        })}
      </Tabs>
    </>
  );
};

export default ItemConfigurator;
