import { gql } from '@apollo/client';
import { FileFragment, RatingFragment } from '../fragments';
import { CancellationFragment } from '../fragments/Cancellation';

export const GET_UNFINISHED_JOBS = gql`
  query unfinishedJobs($offset: Int, $limit: Int, $search: String) {
    unfinishedJobs(offset: $offset, limit: $limit, search: $search) {
      id
      installer {
        id
        name
      }
      type
      plannings {
        sent
      }
      isSent
      completed
      quote {
        id
        reference
        deal {
          id
          pipedriveDealId
        }
        house {
          id
          address {
            street
            zip
            number
            suffix
          }
          customer {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_RATED_JOBS = gql`
  ${RatingFragment}

  query ratedJobs($offset: Int, $limit: Int, $search: String) {
    ratedJobs(offset: $offset, limit: $limit, search: $search) {
      id
      installer {
        id
        name
      }
      completed
      quote {
        id
        reference
        house {
          id
          address {
            street
            zip
            number
            suffix
          }
          customer {
            id
            firstName
            lastName
          }
        }
      }
      rating {
        ...Rating
      }
    }
  }
`;

export const GET_CANCELLED_JOBS = gql`
  ${CancellationFragment}

  query cancelledJobs($offset: Int, $limit: Int, $search: String) {
    cancelledJobs(offset: $offset, limit: $limit, search: $search) {
      id
      installer {
        id
        name
      }
      completed
      quote {
        id
        reference
        house {
          id
          address {
            street
            zip
            number
            suffix
          }
          customer {
            id
            firstName
            lastName
          }
        }
      }
      cancellation {
        ...Cancellation
      }
    }
  }
`;

export const UPLOAD_JOB_FILES = gql`
  ${FileFragment}
  mutation uploadJobFiles($jobId: ID!, $files: [Upload!]!, $tags: [String!]!) {
    uploadJobFiles(jobId: $jobId, files: $files, tags: $tags) {
      id
      files {
        ...File
      }
    }
  }
`;

export const DELETE_JOB_FILE = gql`
  ${FileFragment}
  mutation deleteJobFile($jobId: ID!, $fileId: String!) {
    deleteJobFile(jobId: $jobId, fileId: $fileId) {
      id
      files {
        ...File
      }
    }
  }
`;

export const SEND_JOB_FOLLOW_UP_EMAIL = gql`
  mutation sendJobCompletedFollowUp($jobFollowUpType: JobFollowUpType!, $jobId: String!) {
    sendJobCompletedFollowUp(jobFollowUpType: $jobFollowUpType, jobId: $jobId) {
      error
      success
    }
  }
`;

export const COMPLETE_JOB = gql`
  mutation completeJob($id: ID!, $completionDate: Date!) {
    completeJob(id: $id, completionDate: $completionDate) {
      id
      completed
    }
  }
`;
