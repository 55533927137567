import { MutationUpdaterFn, useLazyQuery, useMutation } from '@apollo/client';
import {
  getDomainFromSolution,
  PRODUCT_CATEGORIES_NL,
  PRODUCT_ENUMS_NL,
  Solution,
  SOLUTIONS_NL,
} from '@energiebespaarders/constants';
import {
  Accordion,
  Box,
  Flex,
  Icon,
  Image,
  Modal,
  Placeholder,
  Spacer,
  Table,
  Toast,
} from '@energiebespaarders/symbols';
import { Center, Heading, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import {
  AddCircle,
  Archive,
  ArchiveUpload,
  Cancel,
  CaretLeft,
  Check,
  ClipboardAdd,
  ClockRetro,
  Edit,
  MinusCircle,
  StocksUp,
} from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeepNonNullable } from 'react-select/src/components';
import styled from 'styled-components';
import { fileUrl } from '../../config';
import { useHasPricingPermission } from '../../hooks/useHasPricingPermission';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { useMe } from '../../hooks/useMe';
import useToaster from '../../hooks/useToaster';
import productSpecs from '../../lib/dataMaps/productData';
import { GET_PRODUCT_FILES, UPLOAD_PRODUCT_FILES } from '../../queries/files';
import { ADD_PRODUCT, PRODUCT_BY_ID, UPDATE_PRODUCT } from '../../queries/products';
import { addProduct, addProductVariables } from '../../types/generated/addProduct';
import { getProductFiles, getProductFilesVariables } from '../../types/generated/getProductFiles';
import {
  productById,
  productByIdVariables,
  productById_productById,
  productById_productById_InstallationMaterial,
  productById_productById_RoofFinish_files,
} from '../../types/generated/productById';
import { updateProduct, updateProductVariables } from '../../types/generated/updateProduct';
import {
  uploadProductFiles,
  uploadProductFilesVariables,
} from '../../types/generated/uploadProductFiles';
import { ProductInput, UserType } from '../../types/graphql-global-types';
import DateWithTooltip from '../DateWithTooltip';
import ProductGallery from '../ProductGallery';
import { PriceChangelogByProduct } from '../products/PriceChangelog';
import ProductChangelog from '../products/ProductChangelog';
import ProductForm from '../products/ProductForm';
import ProductPricesTable from '../products/ProductPricesTable';
import ProductUploader from '../ProductUploader';

const SpecLabel = styled.div`
  flex-grow: 1;
  font-size: ${x => x.theme.type.scale[7]};
  font-weight: 600;
`;

const SpecValue = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${x => x.theme.type.scale[7]};

  &:hover {
    overflow: visible;
    line-height: 0.5;
  }
`;

const MultiLineSmall = styled(Small)`
  white-space: pre-line;
  max-height: 150px;
  overflow: auto;
  display: inline-block;
  width: 100%;
`;

interface ProductSpec {
  key: string;
  label: string;
  format?: (value: any) => void;
  prefix?: string;
  suffix?: string;
  width?: number;
  required?: boolean;
}

export type MainProduct = productById_productById & {
  advantages: string[];
  disadvantages: string[];
};

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  productId: string;
  setActiveProduct: (activeProductId: string) => void;
  onUpdate?: MutationUpdaterFn<updateProduct>;
}

/**
 * Prepares product input values to for the product update mutation:
 * Inverse of convertInitialValues with some extra fields being unset
 **/
export function convertSubmissionValues(product: ProductInput) {
  const res = {
    ...product,
  };

  Object.keys(product as DeepNonNullable<ProductInput>).forEach(key => {
    const typedKey = key as keyof ProductInput;

    const spec = productSpecs(product.category).find(spec => spec.key === key);

    // Convert % values from 0-100 back to 0-1 range
    if (spec?.suffix === '%') {
      res[typedKey] = typeof res[typedKey] === 'number' ? res[typedKey] / 100 : res[typedKey];
    }
  });

  return res;
}

const ProductModal: React.FC<Props> = ({
  closeModal,
  isOpen,
  productId,
  setActiveProduct,
  onUpdate,
}) => {
  const [editing, setEditing] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const toast = useToaster();
  const mobile = useIsMobile();
  const hasPermission = useHasPricingPermission();

  const { me } = useMe();

  const blockEdit = () =>
    toast({ type: 'error', message: 'Alleen NS en DEV kunnen producten bewerken.' });

  const [getProductQuery, { data: productData, loading, error }] = useLazyQuery<
    productById,
    productByIdVariables
  >(PRODUCT_BY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: queryData => {
      if (queryData?.productById && modalTitle !== queryData.productById.title) {
        setModalTitle(queryData.productById.title || '');
      }

      if (duplicating) setDuplicating(false);
    },
  });

  const [updateProductMutation] = useMutation<updateProduct, updateProductVariables>(
    UPDATE_PRODUCT,
    {
      update: (store, res) => {
        const { data } = res;
        if (!data?.updateProduct) return;
        store.writeQuery<productById, productByIdVariables>({
          query: PRODUCT_BY_ID,
          data: {
            productById: { ...data.updateProduct },
          },
          variables: { id: productId },
        });
        onUpdate?.(store, res);
      },
      onCompleted: () => {
        toast({
          type: 'success',
          message: 'Het product is succesvol aangepast',
        });
        setEditing(false);
      },
    },
  );

  const [addProductMutation] = useMutation<addProduct, addProductVariables>(ADD_PRODUCT, {
    // TODO: update the GET_SORTED_PRODUCTS query in the cache
    onCompleted: data => {
      if (!data.addProduct) throw new Error('No data received from addProduct mutation');
      toast({
        type: 'success',
        message: 'Het product is aangemaakt',
      });
    },
    onError: e =>
      toast({
        type: 'error',
        message: `er is iets misgegaan ${e.message}`,
      }),
  });

  const [performUpload] = useMutation<uploadProductFiles, uploadProductFilesVariables>(
    UPLOAD_PRODUCT_FILES,
    {
      update: (store, { data }) => {
        const queryData = store.readQuery<getProductFiles, getProductFilesVariables>({
          query: GET_PRODUCT_FILES,
          variables: { productId },
        });
        if (!queryData?.productById || !data?.uploadProductFiles) return;
        const newFiles = queryData.productById.files
          ? queryData.productById.files.concat(data.uploadProductFiles)
          : [...data.uploadProductFiles];
        store.writeQuery({
          query: GET_PRODUCT_FILES,
          variables: { productId },
          data: {
            productById: {
              ...queryData.productById,
              files: newFiles,
            },
          },
        });
      },
      onCompleted: () => {
        toast({
          type: 'success',
          message: 'Bestanden zijn succesvol geüpload',
        });
      },
      onError: e =>
        toast({
          type: 'error',
          message: `Er is een fout opgetreden bij het uploaden van bestanden ${e.message}`,
        }),
    },
  );

  const handleUpdateProduct = useCallback(
    async (product: ProductInput) => {
      return updateProductMutation({
        variables: {
          id: productId,
          product: convertSubmissionValues(product),
        },
      });
    },
    [productId, updateProductMutation],
  );

  useEffect(() => {
    if (productId && productId !== productData?.productById?.id) {
      getProductQuery({ variables: { id: productId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  if (loading || error) return <Placeholder error={error} />;
  if (!productData) return <div />;
  const product = productData.productById!;

  const constructDuplicateProduct = (
    productInput: ProductInput,
    originalProduct: productById_productById,
  ): Partial<productById_productById> => {
    const mergedProduct = {
      ...originalProduct,
      ...productInput,
    };

    // Remove fields from product that aren't specified in the ProductInput
    delete (mergedProduct as any).created;
    delete (mergedProduct as any).createdBy;
    delete (mergedProduct as any).files;

    // Remove null values
    for (const key in mergedProduct) {
      const typedKey = key as keyof productById_productById;
      if (mergedProduct[typedKey] === null) {
        delete mergedProduct[typedKey];
      }
    }

    // Convert brand to brandId to match gql schema
    if ('brand' in mergedProduct && mergedProduct.brand !== null) {
      mergedProduct.brandId = mergedProduct.brand.id;
      delete (mergedProduct as any).brand;
    }

    // eslint-disable-next-line
    const { id, ...rest } = mergedProduct as Partial<productById_productById>;

    return rest;
  };

  const downloadFile = async (file: productById_productById_RoofFinish_files) => {
    const res = await fetch(`${fileUrl}/${file.id}.${file.extension}`);
    const filename = file.metadata.title || `Upload ${new Date().toLocaleString()}`;
    const blob = await res.blob();
    return new File([blob], `${filename}.${file.extension}`);
  };

  const uploadFilesToDuplicateProduct = async (
    productId: string,
    files: productById_productById_RoofFinish_files[],
  ) => {
    const downloadedFiles = await Promise.all(files.map(file => downloadFile(file)));

    return performUpload({
      variables: {
        id: productId,
        files: downloadedFiles,
        tags: ['photo'],
        uploaderId: me.id,
        uploaderUserType: UserType.operator,
      },
    });
  };

  const duplicateProductAndUploadFiles = async (productInput: ProductInput) => {
    try {
      const { files, ...rest } = constructDuplicateProduct(
        convertSubmissionValues(productInput), // only update % values on the input, not the existing product fields
        product,
      );

      const { data } = await addProductMutation({
        variables: { product: rest },
      });

      if (!data?.addProduct) {
        throw new Error('No data received from addProduct mutation');
      }

      const newProduct = data.addProduct;

      if (files?.length) {
        const uploadedFiles = await uploadFilesToDuplicateProduct(
          newProduct.id,
          files as productById_productById_RoofFinish_files[],
        );

        if (!uploadedFiles) throw new Error('Product files were not uploaded');
      }

      setActiveProduct(newProduct.id);
    } catch (e) {
      return toast({ type: 'error', message: e.message });
    }
  };

  const hiddenSpecs = [
    'title',
    'description',
    'internalDescription',
    'advantages',
    'disadvantages',
    'priceUnit',
  ];
  const hiddenInputFields = [
    // NOTE: needs to be kept in sync with the AddProductModal
    'id',
    'created',
    'createdBy',
    'modified',
    'solution',
    'isInstallable',
    'isSuppliable',
    'installers',
    'quotes',
    'acceptedQuoteCount',
    'files',
    'revisions',
    'archived',
    'priceRevisions',
    ...(product.category === 'completePvSystem'
      ? ['description', 'pMax', 'pvPanel', 'inverter', 'optimizer', 'installationMaterial', 'labor']
      : []),
    // Labor material and thickness is only needed for wall & floor, for auto installation gen
    ...(product.category === 'labor' &&
    !(product.solution === Solution.WallInsulation || product.solution === Solution.FloorInsulation)
      ? ['material', 'thickness']
      : []),
    ...(duplicating ? ['category'] : []),
  ];
  const rightSpecs: ProductSpec[] = productSpecs(product.category, product.solution).filter(
    spec =>
      hiddenSpecs.indexOf(spec.key) < 0 &&
      ((product as any)[spec.key] as any) !== null &&
      ((product as any)[spec.key] as any) !== undefined,
  );
  const leftSpecs = rightSpecs.splice(0, Math.ceil(rightSpecs.length / 2));

  const handleArchiveProduct = (archived: Date | null) =>
    updateProductMutation({
      variables: {
        id: productId,
        product: { archived: archived ? null : new Date() },
      },
    });

  const renderModalTitle = () => {
    if (editing) return 'Product bewerken';
    if (product.archived) return `${modalTitle} (Gearchiveerd)`;
    if (duplicating) return 'Product dupliceren';

    return modalTitle;
  };

  const renderSpecsTable = (specs: ProductSpec[], product: any) => (
    <Box width={[1, 1, 1 / 2]} px={2} mb={2}>
      <Table>
        {specs.map(spec => {
          let value = spec.key && spec.format ? spec.format(product[spec.key]) : product[spec.key];
          if (value === true) value = <Icon icon={Check} solid fill="green" width="1em" />;
          if (value === false) value = <Icon icon={Cancel} solid fill="red" width="1em" />;
          return (
            <Table.Row key={spec.key}>
              <Table.Cell textAlign="left">
                <SpecLabel>{spec.label}</SpecLabel>
              </Table.Cell>
              <Table.Cell textAlign="right">
                <SpecValue
                  onClick={() =>
                    spec.label.indexOf('Bijbehorend') > -1
                      ? setActiveProduct(product[spec.key])
                      : null
                  }
                >
                  {spec.prefix !== 'ID' && spec.prefix} {value} {spec.suffix}
                </SpecValue>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
    </Box>
  );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() =>
        editing
          ? window.alert(
              'Je moet eerst je wijzigingen opslaan of annuleren, voor je de modal kunt sluiten.',
            )
          : closeModal()
      }
      title={renderModalTitle()}
      mobile={mobile}
      timeout={mobile ? 250 : undefined}
      buttons={
        duplicating
          ? [
              {
                bgColor: 'red',
                inverse: true,
                iconStart: CaretLeft,
                label: 'Annuleren',
                minIconSize: 'auto',
                onClick: () => setDuplicating(false),
                m: 0,
              },
            ]
          : editing
          ? [
              {
                bgColor: 'red',
                inverse: true,
                iconStart: CaretLeft,
                label: 'Annuleren',
                minIconSize: 'auto',
                onClick: () => setEditing(false),
                m: 0,
              },
            ]
          : productId
          ? [
              {
                bgColor: 'goldDark',
                inverse: true,
                label: 'Dupliceren',
                onClick: () => {
                  if (!hasPermission) return blockEdit();
                  setDuplicating(true);
                },
                iconStart: ClipboardAdd,
                m: 0,
              },
              {
                bgColor: 'orange',
                inverse: !product.archived,
                label: product.archived ? 'Herstellen uit archief' : 'Archiveren',
                onClick: () => {
                  if (!hasPermission) return blockEdit();
                  handleArchiveProduct(product.archived);
                },
                iconStart: product.archived ? ArchiveUpload : Archive,
                m: 0,
              },
              {
                bgColor: 'green',
                inverse: true,
                iconStart: Edit,
                label: 'Product bewerken',
                minIconSize: 'auto',
                onClick: () => {
                  if (!hasPermission) return blockEdit();
                  setEditing(true);
                },
                m: 0,
              },
              {
                bgColor: 'red',
                inverse: true,
                label: 'Sluiten',
                onClick: closeModal,
                m: 0,
              },
            ]
          : [
              {
                bgColor: 'red',
                inverse: true,
                label: 'Sluiten',
                onClick: closeModal,
                m: 0,
              },
            ]
      }
    >
      {editing ? (
        <>
          <ProductGallery
            productId={product.id}
            productCategory={product.category}
            readOnly={product.category === 'completePvSystem'}
            tags={['photo']}
          />
          {product.category !== 'completePvSystem' && (
            <ProductUploader
              productId={productId}
              tags={['photo']}
              text="Productfoto's toevoegen"
            />
          )}

          <ProductGallery
            placeholderText="Er zijn nog geen brochures of datasheets toegevoegd"
            productId={productId}
            productCategory={product.category}
            readOnly={product.category === 'completePvSystem'}
            tags={['download']}
            thumbSize={60}
          />
          {product.category !== 'completePvSystem' && (
            <ProductUploader
              accept="application/pdf"
              productId={productId}
              tags={['download']}
              text="Brochures en datasheets toevoegen"
            />
          )}
          <ProductForm
            handleFormSubmit={handleUpdateProduct}
            hiddenFields={hiddenInputFields}
            product={product}
            schemaName={product!.__typename}
            category={product.category}
            solution={product.solution}
          />
        </>
      ) : duplicating ? (
        <ProductForm
          product={product}
          handleFormSubmit={duplicateProductAndUploadFiles}
          hiddenFields={hiddenInputFields}
          schemaName={product!.__typename}
          category={product.category}
          solution={product.solution}
        />
      ) : (
        <>
          <Flex flexWrap="wrap" mx={-2} mt={0}>
            <Box width={1 / 2} px={2}>
              <Smaller>
                <Link
                  to={`/products/${getDomainFromSolution(product.solution)}`}
                  title="De type oplossing van dit product"
                  onClick={closeModal}
                >
                  {SOLUTIONS_NL[product.solution]}
                </Link>{' '}
                {'>'}{' '}
                <Link
                  to={`/products/${product.solution}/${product.category}`}
                  title="De categorie van dit product"
                  onClick={closeModal}
                >
                  {PRODUCT_CATEGORIES_NL[product.category]}
                </Link>
              </Smaller>
            </Box>
            <Box width={1 / 2} px={2} style={{ textAlign: 'right' }}>
              <Smaller>
                Aangemaakt op{' '}
                {!product.created ? 'onbekende datum' : <DateWithTooltip date={product.created} />}{' '}
                door{' '}
                {product.createdBy?.__typename === 'Operator'
                  ? `${product.createdBy.firstName} ${product.createdBy.lastName}`
                  : 'onbekend'}
              </Smaller>
            </Box>
            {product.internalDescription && (
              <Box width={1} px={2} mb={2}>
                {product.internalDescription && (
                  <Toast
                    message={<MultiLineSmall>{product.internalDescription}</MultiLineSmall>}
                    width="100%"
                    toastId={999}
                  />
                )}
              </Box>
            )}
            <Box width={1} px={2} mb={2}>
              <ProductGallery
                productId={product.id}
                productCategory={product.category}
                readOnly
                tags={['photo']}
              />
            </Box>

            <Box width={[1, 1, 3 / 4]} px={2} mb={3}>
              <Heading>{product.title}</Heading>
              <MultiLineSmall>{product.description}</MultiLineSmall>
            </Box>

            <Box width={[1, 1, 1 / 4]} px={2} mb={3} alignSelf="center">
              <Center block>
                {'brand' in product && product.brand && (
                  <Image
                    src={
                      product.brand.logo
                        ? `${fileUrl}/${product.brand.logo?.id}.${product.brand.logo?.extension}`
                        : ''
                    }
                    alt={product.brand.name || ''}
                  />
                )}
                <Spacer vertical amount={3} />
                {(product.customMarkup || product.customMarkup === 0) && (
                  <div>
                    <Smaller>Dit product heeft een aangepaste markup</Smaller>
                  </div>
                )}
              </Center>
            </Box>

            {(product as MainProduct).advantages && (
              <Box width={1} px={2} mb={2}>
                <Small>
                  {(product as MainProduct).advantages.map((advantage: string, index: number) => (
                    <div key={`advantage-${index}`}>
                      <Icon icon={AddCircle} solid fill="green" width="1rem" mr={2} /> {advantage}
                    </div>
                  ))}
                </Small>
              </Box>
            )}
            {(product as MainProduct).disadvantages && (
              <Box width={1} px={2} mb={2}>
                <Small>
                  {(product as MainProduct).disadvantages.map(
                    (disadvantage: string, index: number) => (
                      <div key={`disadvantage-${index}`}>
                        <Icon icon={MinusCircle} solid fill="red" width="1rem" mr={2} />{' '}
                        {disadvantage}
                      </div>
                    ),
                  )}
                </Small>
              </Box>
            )}
            {(product as productById_productById_InstallationMaterial).materials?.length && (
              <Box width={1} px={2} mb={2}>
                <Heading>
                  <Small>Inbegrepen installatiematerialen</Small>
                </Heading>
                <Small>
                  {(product as productById_productById_InstallationMaterial).materials!.map(
                    (material: string, index: number) => (
                      <div key={`material-${index}`}>
                        <Icon icon={AddCircle} solid fill="green" width="1rem" mr={2} />{' '}
                        {PRODUCT_ENUMS_NL[material]}
                      </div>
                    ),
                  )}
                </Small>
              </Box>
            )}

            <Box width={1} px={2} mb={1}>
              <Heading>
                <Small>Downloads</Small>
              </Heading>
              <ProductGallery
                placeholderText="Er zijn nog geen brochures of datasheets toegevoegd"
                productId={productId}
                readOnly
                tags={['download']}
                thumbSize={60}
              />

              {'youTubeId' in product && product.youTubeId && (
                <Center block>
                  <iframe
                    title="video"
                    width="360"
                    height="200"
                    src={`https://www.youtube.com/embed/${product.youTubeId}`}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Center>
              )}
            </Box>

            {renderSpecsTable(leftSpecs, product)}
            {renderSpecsTable(rightSpecs, product)}

            <Box width={1} px={2} mb={5}>
              <ProductPricesTable productId={productId} />
            </Box>

            <Accordion title="Productwijzigingslogboek" icon={ClockRetro} unmountCollapsedContent>
              <ProductChangelog productId={product.id} />
            </Accordion>
            <Accordion title="Prijswijzigingslogboek" icon={StocksUp} unmountCollapsedContent>
              <PriceChangelogByProduct productId={product.id} />
            </Accordion>
          </Flex>
        </>
      )}
    </Modal>
  );
};

export default ProductModal;
