import _ from 'lodash';
import { useState, useEffect } from 'react';

interface Size {
  width: number;
  height: number;
}

export function useWindowSize(): Size {
  function getSize(): Size {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }
    const throttledHandler = _.throttle(() => handleResize(), 200);
    window.addEventListener('resize', throttledHandler);
    return () => window.removeEventListener('resize', throttledHandler);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
