import { ApolloError } from '@apollo/client';
import { Input, Placeholder } from '@energiebespaarders/symbols';
import React, { ChangeEvent } from 'react';
import useToaster from '../../../hooks/useToaster';
import { currencyFilter, fixUnit } from '../../../lib/utils';
import { getViableProducts_allProductsBySolutionDomain as t_product } from '../../../types/generated/getViableProducts';

type Props = {
  price: string;
  setPrice: (p: string) => void;
  selectedProduct: undefined | t_product;
  isFixedPrice: boolean;
  hasPricingPermission: boolean;
  loading: boolean;
  error?: ApolloError;
  disabled?: boolean;
};

const RetailPriceInput: React.FC<Props> = ({
  price,
  setPrice,
  selectedProduct,
  isFixedPrice,
  hasPricingPermission,
  loading,
  error,
  disabled,
}) => {
  const toast = useToaster();

  if (loading)
    return (
      <Input
        label="Verkoopprijs"
        value="Prijs laden..."
        disabled
        addonContent="€"
        addonSide="start"
      />
    );
  if (error) return <Placeholder error={error} />;

  return (
    <Input
      label={`Verkoopprijs ${
        selectedProduct ? 'per ' + fixUnit(selectedProduct.priceUnit, true) : ''
      } (ex. BTW)`}
      addonContent={'€'}
      disabled={disabled}
      addonSide="start"
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        isFixedPrice && !hasPricingPermission
          ? toast({
              type: 'error',
              message:
                'Alleen NS en DEV mogen prijzen aanpassen voor producten met een vaste verkoopprijs',
            })
          : setPrice(currencyFilter(e.target.value))
      }
      placeholder="1"
      type="number"
      value={price}
    />
  );
};

export default RetailPriceInput;
