import { gql } from '@apollo/client';

export const CancellationFragment = gql`
  fragment Cancellation on Cancellation {
    id
    reason
    comment
    operator {
      id
      firstName
      lastName
    }
    created
  }
`;

export default CancellationFragment;
