import { theme } from '@energiebespaarders/symbols';

export const fixedSizes = {
  leftNavCollapsed: '72px',
  leftNavOpened: '288px',
  rightNavCollapsed: '0px',
  rightNavOpened: '288px',
  navHeight: '72px',
  navWidthMobile: '290px',
  scrollbarWidth: '17px',
  subNavHeight: '36px',
};

export const urlBarHeight = () => {
  const totalHeight = parseFloat(getComputedStyle(document.documentElement).perspective);
  const clientHeight = document.documentElement.clientHeight;
  const urlBarHeight = totalHeight - clientHeight;
  return isNaN(urlBarHeight) ? 0 : urlBarHeight;
};

export default {
  ...theme,
  fixedSizes,
  urlBarHeight,
};
