import { DropdownOption } from '../constants';

export const MATERIAL_OPTIONS: DropdownOption<string>[] = [
  { label: 'Glaswol', value: 'glassWool' },
  { label: 'Glaswol met afwerking', value: 'glassWoolWithFinish' },
  { label: 'Schuim', value: 'foam' },
  { label: 'Milieuvriendelijk schuim', value: 'ecofoam' },
  { label: 'Parels', value: 'pearls' },
  // Popular specific type of foam
  { label: 'PUR', value: 'pur' },
  // Type of foam, improved version of PUR
  // e.g. Unilin insulation is a type of PIR
  // https://warmerhuis.nl/voor-pur-of-pir-kiezen
  { label: 'PIR', value: 'pir' },
  { label: 'Isolatiefolie', value: 'insulationFoil' },
  { label: 'Thermoskussens', value: 'thermalInsulationPads' },
  { label: 'Keramiek', value: 'ceramic' },
  { label: 'Anders (graag bij DEV aangeven!)', value: 'other' },
];
