// import _ from 'lodash';
import { getSolutionsFromDomain, Solution, SolutionDomain } from '@energiebespaarders/constants';
import { Accordion, Card, Spinner } from '@energiebespaarders/symbols';
import { Center } from '@energiebespaarders/symbols/helpers';
import { Wrenches } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import { dealsByHouseSolutionDomain_dealsByHouseSolutionDomain } from '../../../types/generated/dealsByHouseSolutionDomain';
import { installationByHouseSolution_installationByHouseSolution as t_installation } from '../../../types/generated/installationByHouseSolution';
import CreateInstallation from './CreateInstallation';
import InstallationBuilder from './InstallationBuilder';
import SolutionSelector from './SolutionSelector';

type InstallatronCoreProps = {
  installation?: t_installation | null;
  installationLoading: boolean;
  houseId: string;
  solutionDomain: SolutionDomain;
  solution: Solution;
  setSolution: (s: Solution) => void;
  isNarrow: boolean;
  deals: readonly dealsByHouseSolutionDomain_dealsByHouseSolutionDomain[];
};

const InstallatronCore: React.FC<InstallatronCoreProps> = ({
  installation,
  installationLoading,
  houseId,
  solutionDomain,
  solution,
  setSolution,
  deals,
}) => {
  const [installatronOpen, setInstallatronOpen] = useState(deals.length === 0);
  return (
    <>
      <Card
        p={0}
        mb={4}
        zIndex={300}
        animation="none"
        borderRadius="9px"
        style={{ overflowY: 'hidden' }}
      >
        <Accordion
          baseBgColor="white"
          bgColor="grayLighter"
          title="Installatron"
          override
          onClick={() => setInstallatronOpen(!installatronOpen)}
          isOpened={installatronOpen}
          icon={Wrenches}
          iconColor={!!installation || deals.length > 0 ? 'green' : 'red'}
          contentBorderColor="grayLight"
          contentPadding={0}
          titleSize={5}
          zIndex={300}
        >
          {getSolutionsFromDomain(solutionDomain).length > 1 && (
            <SolutionSelector
              solution={solution}
              solutionDomain={solutionDomain}
              setSolution={setSolution}
            />
          )}
          {installatronOpen && (
            <>
              {installationLoading ? (
                <Center block p={5}>
                  <Spinner />
                </Center>
              ) : installation === undefined || installation === null ? (
                <CreateInstallation houseId={houseId} solution={solution} />
              ) : (
                <InstallationBuilder
                  installation={installation}
                  houseId={houseId}
                  solution={solution}
                  solutionDomain={solutionDomain}
                  mobile={false}
                  setInstallatronOpen={setInstallatronOpen}
                  deals={deals.filter(deal => deal.solution === installation.solution)}
                />
              )}
            </>
          )}
        </Accordion>
      </Card>
    </>
  );
};

export default InstallatronCore;
