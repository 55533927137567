import { gql } from '@apollo/client';
import { ConsumptionFragment, FileFragment } from '../../fragments';
import { IntakeWindowsFragment } from '../../fragments/Intake';
import {
  CrawlspaceFragment,
  FloorFragment,
  HeatingFragment,
  RoofFragment,
  WallsFragment,
} from './../../fragments/House';
import {
  ChargingBoxSituationFragment,
  CrawlspaceSituationFragment,
  ElectricsSituationFragment,
  FloorSituationFragment,
  HeatingSituationFragment,
  RoofSituationFragment,
  SedumSituationFragment,
  SolarSituationFragment,
  WallSituationFragment,
  WindowsSituationFragment,
} from './../../fragments/Situation';

export const HouseSummaryFragments = gql`
  ${SolarSituationFragment}
  ${HeatingSituationFragment}
  ${WallSituationFragment}
  ${FloorSituationFragment}
  ${CrawlspaceSituationFragment}
  ${RoofSituationFragment}
  ${WindowsSituationFragment}
  ${SedumSituationFragment}
  ${ChargingBoxSituationFragment}
  ${ElectricsSituationFragment}

  ${WallsFragment}
  ${FloorFragment}
  ${CrawlspaceFragment}
  ${RoofFragment}
  ${IntakeWindowsFragment}
  ${HeatingFragment}
  ${IntakeWindowsFragment}

  ${ConsumptionFragment}

  fragment CommonIntakeFields on Intake {
    id
    constructionYear
    type
    monument
    vve
  }

  fragment CommonSituationFields on Situation {
    id
    currentOccupant
    consumption {
      ...Consumption
    }
  }

  fragment PvSummary on House {
    situation {
      ...CommonSituationFields
      solar {
        ...SolarSituation
      }
      electrics {
        ...ElectricsSituation
      }
    }
    intake {
      pvSystemPower
      ...CommonIntakeFields
      progress {
        general {
          value
        }
        pvSystem {
          value
        }
      }
      notes {
        general
        pvSystem
      }
    }
  }

  fragment HeatingSummary on House {
    situation {
      ...CommonSituationFields
      heating {
        ...HeatingSituation
      }
    }
    intake {
      ...CommonIntakeFields
      progress {
        general {
          value
        }
        heating {
          value
        }
      }
      heating {
        ...Heating
      }
      notes {
        general
        heating
      }
    }
  }

  fragment WallInsulationSummary on House {
    situation {
      ...CommonSituationFields
      walls {
        ...WallSituation
      }
    }
    intake {
      ...CommonIntakeFields
      walls {
        ...Walls
      }
      progress {
        general {
          value
        }
        walls {
          value
        }
      }
      notes {
        general
        walls
      }
    }
  }

  fragment FloorInsulationSummary on House {
    situation {
      ...CommonSituationFields
      floor {
        ...FloorSituation
      }
      crawlspace {
        ...CrawlspaceSituation
      }
    }
    intake {
      ...CommonIntakeFields
      floor {
        ...Floor
      }
      crawlspace {
        ...Crawlspace
      }
      progress {
        general {
          value
        }
        floor {
          value
        }
      }
      notes {
        general
        floor
      }
    }
  }

  fragment RoofInsulationSummary on House {
    situation {
      ...CommonSituationFields
      roof {
        ...RoofSituation
      }
    }
    intake {
      ...CommonIntakeFields
      roof {
        ...Roof
      }
      progress {
        general {
          value
        }
        roof {
          value
        }
      }
      notes {
        general
        roof
      }
    }
  }

  fragment InsulationGlazingSummary on House {
    situation {
      ...CommonSituationFields
      windows {
        ...WindowsSituation
      }
    }
    intake {
      ...CommonIntakeFields
      glazingTypes {
        living
        sleeping
      }
      windows {
        ...IntakeWindows
      }
      progress {
        general {
          value
        }
        windows {
          value
        }
      }
      notes {
        general
        windows
      }
    }
  }

  fragment MiscellaneousSummary on House {
    situation {
      ...CommonSituationFields
      chargingBox {
        ...ChargingBoxSituation
      }
      sedum {
        ...SedumSituation
      }
    }
    intake {
      ...CommonIntakeFields
      progress {
        general {
          value
        }
        other {
          value
        }
      }
      notes {
        general
        other
      }
    }
  }
`;

export const SignoffFragment = gql`
  ${FileFragment}
  fragment Signoff on SignoffDocument {
    jobId
    type
    creatorId
    creatorType
    created
    started
    modified
    jobExecutionDate
    signoffPoints {
      key
      files {
        ...File
      }
      comment
    }
    markedAsComplete {
      date
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const DealItemFragment = gql`
  fragment DealItem on DealItem {
    amount
    retailPrice
    purchasePrice
    product {
      id
    }
    supplier {
      id
    }
  }
`;
