import { ApolloCache, DefaultContext, FetchResult } from '@apollo/client';
import {
  availableQuoteTemplatesBySolution,
  availableQuoteTemplatesBySolutionVariables,
} from '../../../types/generated/availableQuoteTemplatesBySolution';
import { createQuoteTemplate } from '../../../types/generated/createQuoteTemplate';
import { AVAILABLE_QUOTE_TEMPLATES } from './queries';
import { updateQuoteTemplate } from '../../../types/generated/updateQuoteTemplate';
import {
  deleteQuoteTemplate,
  deleteQuoteTemplateVariables,
} from '../../../types/generated/deleteQuoteTemplate';

export const updateCacheOnTemplateCreation = (
  cache: ApolloCache<any>,
  result: Omit<FetchResult<createQuoteTemplate>, 'context'>,
  variables: availableQuoteTemplatesBySolutionVariables,
) => {
  if (!result.data?.createQuoteTemplate) return;

  const cachedTemplates = cache.readQuery<
    availableQuoteTemplatesBySolution,
    availableQuoteTemplatesBySolutionVariables
  >({
    query: AVAILABLE_QUOTE_TEMPLATES,
    variables,
  });

  cache.writeQuery<availableQuoteTemplatesBySolution, availableQuoteTemplatesBySolutionVariables>({
    query: AVAILABLE_QUOTE_TEMPLATES,
    variables,
    data: {
      quoteTemplates: [...(cachedTemplates?.quoteTemplates || []), result.data.createQuoteTemplate],
    },
  });
};

export const updateCacheOnTemplateUpdate = (
  cache: ApolloCache<any>,
  result: Omit<FetchResult<updateQuoteTemplate>, 'context'>,
  variables: availableQuoteTemplatesBySolutionVariables,
) => {
  const updatedTemplate = result.data?.updateQuoteTemplate;
  if (!updatedTemplate) return;

  const cachedTemplates = cache.readQuery<
    availableQuoteTemplatesBySolution,
    availableQuoteTemplatesBySolutionVariables
  >({
    query: AVAILABLE_QUOTE_TEMPLATES,
    variables,
  });

  const newTemplates = [...(cachedTemplates?.quoteTemplates || [])];
  const index = newTemplates.findIndex(temp => temp.id === updatedTemplate.id, updatedTemplate);
  newTemplates.splice(index, 1, updatedTemplate);

  cache.writeQuery<availableQuoteTemplatesBySolution, availableQuoteTemplatesBySolutionVariables>({
    query: AVAILABLE_QUOTE_TEMPLATES,
    variables,
    data: { quoteTemplates: newTemplates },
  });
};

export const updateCacheOnTemplateDeletion = (
  cache: ApolloCache<any>,
  result: Omit<FetchResult<deleteQuoteTemplate>, 'context'>,
  options: {
    context?: DefaultContext | undefined;
    variables?: deleteQuoteTemplateVariables | undefined;
  },
  variables: availableQuoteTemplatesBySolutionVariables,
) => {
  if (!result.data?.deleteQuoteTemplate) return;

  const cachedTemplates = cache.readQuery<
    availableQuoteTemplatesBySolution,
    availableQuoteTemplatesBySolutionVariables
  >({
    query: AVAILABLE_QUOTE_TEMPLATES,
    variables,
  });

  const newTemplates = [...(cachedTemplates?.quoteTemplates || [])].filter(
    template => template.id !== options.variables?.templateId,
  );

  cache.writeQuery<availableQuoteTemplatesBySolution, availableQuoteTemplatesBySolutionVariables>({
    query: AVAILABLE_QUOTE_TEMPLATES,
    variables,
    data: { quoteTemplates: newTemplates },
  });
};
