import React from 'react';
import { Gray, Smaller } from '@energiebespaarders/symbols/helpers';

export const obamaGuide = (
  <>
    <Smaller>
      <Gray>Laatst gewijzigd: 30-01-2020</Gray>
    </Smaller>
    <h3>OBAMA</h3>
    <p>
      OBAMA (<i>Optical Building Analysis and Measurement Assistant</i>) is een overkoepelende term
      voor de digitale gereedschappen die je helpen bij het opmeten van een huis. Het doel voor het
      ontwikkelen hiervan was de benodigheid om nauwkeurige schattingen te maken van de isoleerbare
      oppervlaktes. Momenteel bestaan er drie verschillende tools: voor de gevel, de vloer en het
      dak.
    </p>

    <h4>1. Gevel</h4>
    <p>
      In het bovenaanzicht aan de rechter bovenhoek staat een bovenaanzicht van het huis. De
      perimeter van het huis is opgedeeld in meerdere zijdes. Een zijde kan gekozen worden door er
      op te klikken. Het vooraanzicht van deze zijde zal dan gevisualiseerd worden aan de linker
      kant. Daar zijn 3 modi beschikbaar om verschillende aanpassingen te maken aan de zijde. Elke
      aanpassing is ongedaan of hergedaan te maken met Ctrl/Cmnd + Z of Y.
    </p>

    <h5>Oriënteren</h5>
    <p>
      Er kan rondgekeken worden door te klikken en slepen op de achtergrond. In- en uitzoomen kan
      gedaan worden door te scrollen.
    </p>

    <h5>Oppervlaktes toevoegen</h5>
    <p>In de Oppervlaktes- en Ramenmodus zijn op twee manieren oppervlaktes toe te voegen:</p>

    <ol>
      <li>Met Ctrl/Cmnd ingedrukt, klik en sleep om een rechthoek toe te voegen</li>
      <li>
        Met Ctrl/Cmnd ingedrukt, klik eenmaals om een nieuwe vorm te beginnen. Elke opeenvolgende
        klik voegt een nieuw punt toe. Sluit de vorm door te dubbelklikken of door op het beginpunt
        te klikken. Dit is ook mogelijk met de '+Nieuwe vorm' knop.
      </li>
    </ol>

    <h5>Oppervlaktes aanpassen</h5>
    <p>
      Bestaande oppervlaktes kunnen worden versleept om ze te verplaatsen. Individuele punten kunnen
      ook worden versleept. Een nieuw punt kan worden toegevoegd door op de lijn tussen twee punten
      te klikken. Een oppervlak of punt kan worden verwijderd door er eenmaals op te klikken en op
      de Delete of X toets te drukken. Wanneer de Shift-toets ingedrukt is, zullen de punten op
      0.25m afgerond worden. Handig om vormen goed uit te lijnen!
    </p>

    <h5>Zijde-vorm aanpassen</h5>
    <p>
      Elke zijde krijgt een standaardhoogte die overeenkomt met de gemiddelde hoogte van het huis
      die wordt gebaseerd op hoogtedata. De vorm is aan te passen net zoals andere oppervlaktes,
      behalve dat niet de onder- of zijkanten aangepast kunnen worden.
    </p>
    <p>
      Indien de goothoogte niet klopt is het aan te passen met via de Goothoogte knop aan de rechter
      zijde. Hiermee kunnen ook gemeenschappelijke zijdes aangegeven worden door de hoogtehoogte op
      0 meter te zetten. Bovendien is hier de geveldiepte onder grondniveau aanpasbaar, wat
      standaard op 0.5 meter staat. Ook kan je de omhoog en omlaag pijltjestoetsen gebruiken om de
      goothoogte voor de huidige gevel aan te passen. Houd Ctrl/Cmd ingedrukt om de goothoogte voor
      alle zijdes aan te passen.
    </p>

    <h5>Zijdes samenvoegen</h5>
    <p>
      Selecteer een zijde door er op de klikken. Selecteer een andere zijde met shift + klik. De
      zijdes tussen deze geselecteerde zijdes worden rood gemarkeerd en een knop wordt zichtbaar om
      ze samen te voegen.
    </p>

    <h4>2. Vloer</h4>
    <p>
      De vloer-tool gebruikt dezelfde component als de gevel-tool, zodat op dezelfde manier
      oppervlaktes toegevoegd en gewijzigd kunnen worden. Je kunt hier wel de perimeter automatisch
      bijdraaien zodat je gemakkelijk rechthoeken kunt tekenen.
    </p>
    <p>Gebruik de linker en rechter pijltjestoetsen om handmatig bij te draaien.</p>

    <h4>3. Dak</h4>
    <p>
      De dak-tool is momenteel nog in ontwikkeling, maar werkt al wel in heel Nederland. Deze tool
      maakt het mogelijk om met hulp van hoogtedata op een intuitieve manier dakoppervlaktes in
      kaart te brengen en op te meten.
    </p>
  </>
);
