import { Icon, Tooltip } from '@energiebespaarders/symbols';
import { Bold } from '@energiebespaarders/symbols/helpers';
import { Energiepaspoort } from '@energiebespaarders/symbols/icons/custom';
import {
  ChessPawn,
  Crown,
  FormatTextAlt,
  Hardhat,
  Tag,
  Time,
  Wifi,
  WomanFancy,
} from '@energiebespaarders/symbols/icons/solid';
import { boxShadow, pos, themify } from '@energiebespaarders/symbols/styles/mixins';
import { readableColor } from 'polished';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import MuntSymbol from '../icons/custom/Munt';
import TriodosSymbol from '../icons/custom/Triodos';
import { notEmpty } from '../typeHelpers';
import { houseCard_house_orders_orderLines } from '../types/generated/houseCard';
import { DutyName, DutyPackageName } from '../types/graphql-global-types';

type RibbonProps = {
  bgColor: string;
  left?: number;
  right?: number;
  width?: string;
};

export const Ribbon = styled.div<RibbonProps>`
  ${x => x.left && pos('left', x.left)};
  ${x => x.right && pos('right', x.right)};
  ${boxShadow('xl')};
  background: ${x => x.bgColor};
  position: absolute;
  top: -3px;
  height: ${x => x.width || '30px'};
  width: ${x => x.width || '30px'};
  text-align: center;
  border-radius: 3px 3px 0 0;
  transition: top 0.15s;
  z-index: 2;
  color: ${x => readableColor(x.bgColor)};

  &:hover {
    top: -1px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: ${x => `calc(-${x.width} / 2)`};
  }

  &::before {
    left: 0;
    border-left: ${x => `calc(${x.width} / 2) solid ${x.bgColor}`};
    border-right: ${x => `calc(${x.width} / 2) solid ${x.bgColor}`};
    border-bottom: ${x => `calc(${x.width} / 2) solid transparent`};
  }
`;

const TextRibbonIcon: React.FC<{ text: string; isTextIcon?: boolean }> = ({ text }) => (
  <Bold>{text}</Bold>
);

TextRibbonIcon.defaultProps = { isTextIcon: true };

interface IRibbon {
  key: string;
  bgColor?: string;
  icon?: any;
  tooltip?: string;
  color?: string;
  to?: string;
}

export const dutyPackageRibbons: Record<DutyPackageName | string, IRibbon | undefined> = {
  [DutyPackageName.complete]: {
    key: DutyPackageName.complete,
    bgColor: 'green',
    icon: Crown,
    tooltip: 'Verduurzamen Compleet',
  },
  [DutyPackageName.simple]: {
    key: DutyPackageName.simple,
    bgColor: 'orange',
    icon: ChessPawn,
    tooltip: 'Verduurzamen Simpel',
  },
  [DutyPackageName.direct]: {
    key: DutyPackageName.direct,
    bgColor: 'blue',
    icon: Wifi,
    tooltip: 'Verduurzamen Direct',
  },
  [DutyPackageName.energiepaspoortLegacy]: {
    key: DutyPackageName.energiepaspoortLegacy,
    bgColor: 'green',
    icon: Energiepaspoort,
    tooltip: 'Energiepaspoort (oud)',
  },
  [DutyPackageName.energiepaspoortSupplementary]: {
    key: DutyPackageName.energiepaspoortSupplementary,
    bgColor: 'greenLight',
    icon: Energiepaspoort,
    tooltip: 'Energiepaspoort los',
  },
  [DutyPackageName.energyLabelSupplementary]: {
    key: DutyPackageName.energyLabelSupplementary,
    bgColor: 'greenLight',
    icon: Tag,
    tooltip: 'Energielabel los',
  },
  [DutyPackageName.financieelFitPlan]: {
    key: DutyPackageName.financieelFitPlan,
    bgColor: 'financieelFit.primary',
    icon: Crown,
    tooltip: 'Financieel Fit Plan',
    color: 'white',
  },
  [DutyPackageName.financieelFitSimplePlus]: {
    key: DutyPackageName.financieelFitSimplePlus,
    bgColor: 'financieelFit.primary',
    icon: ChessPawn,
    tooltip: 'Financieel Fit Simpel+',
    color: 'white',
  },
  [DutyPackageName.florius]: {
    key: DutyPackageName.florius,
    bgColor: 'florius.primary',
    icon: <TextRibbonIcon text="F" />,
    tooltip: 'Florius Adviespakket',
    color: 'florius.secondary',
  },
  [DutyPackageName.floriusGreen]: {
    key: DutyPackageName.floriusGreen,
    bgColor: 'florius.secondary',
    icon: <TextRibbonIcon text="F" />,
    tooltip: 'Florius Adviespakket Groen',
    color: 'florius.primary',
  },
  [DutyPackageName.lotHypotheken]: {
    key: DutyPackageName.lotHypotheken,
    bgColor: '#e6006f',
    icon: <TextRibbonIcon text="L" />,
    tooltip: 'Lot Verduurzaamt',
  },
  [DutyPackageName.veldsinkComplete]: {
    key: DutyPackageName.veldsinkComplete,
    bgColor: '#519e19',
    icon: Crown,
    tooltip: 'Veldsink Compleet',
  },
  [DutyPackageName.veldsinkSimple]: {
    key: DutyPackageName.veldsinkSimple,
    bgColor: '#ec8636',
    icon: ChessPawn,
    tooltip: 'Veldsink Simpel',
  },
  [DutyPackageName.CMISDirect]: {
    key: DutyPackageName.CMISDirect,
    bgColor: '#ec6d02',
    color: 'white',
    icon: <TextRibbonIcon text="C" />,
    tooltip: 'CMIS Direct',
  },
  [DutyPackageName.CMISSimple]: {
    key: DutyPackageName.CMISSimple,
    bgColor: '#ec6d02',
    color: 'white',
    icon: <TextRibbonIcon text="C" />,
    tooltip: 'CMIS Simpel',
  },
  [DutyPackageName.CMISComplete]: {
    key: DutyPackageName.CMISComplete,
    bgColor: '#ec6d02',
    color: 'white',
    icon: <TextRibbonIcon text="C" />,
    tooltip: 'CMIS Compleet',
  },
  [DutyPackageName.muntHypotheken]: {
    key: DutyPackageName.muntHypotheken,
    bgColor: 'munt.primary',
    color: 'white',
    icon: MuntSymbol,
    tooltip: 'MUNT Adviespakket',
  },
  [DutyPackageName.yonegoSimple]: {
    key: DutyPackageName.yonegoSimple,
    bgColor: '#00b5ef',
    icon: <TextRibbonIcon text="Y" />,
    tooltip: 'Yonego Simpel',
  },
  [DutyPackageName.solvariSimple]: {
    key: DutyPackageName.solvariSimple,
    bgColor: '#13568b',
    icon: <TextRibbonIcon text="S" />,
    tooltip: 'Solvari Simpel',
  },
  [DutyPackageName.offerteAdviseurSimple]: {
    key: DutyPackageName.offerteAdviseurSimple,
    bgColor: 'orange',
    icon: <TextRibbonIcon text="O" />,
    tooltip: 'OfferteAdviseur Simpel',
  },
  [DutyPackageName.viisiSimple]: {
    key: DutyPackageName.viisiSimple,
    bgColor: '#98D0C0',
    icon: <TextRibbonIcon text="V" />,
    tooltip: 'Viisi Simpel',
  },
  [DutyPackageName.viisiSimpleGreen]: {
    key: DutyPackageName.viisiSimpleGreen,
    bgColor: '#98D0C0',
    icon: <TextRibbonIcon text="V" />,
    tooltip: 'Viisi Simpel Groen',
  },
  [DutyPackageName.vvaaSimple]: {
    key: DutyPackageName.vvaaSimple,
    bgColor: '#f5821f',
    icon: ChessPawn,
    tooltip: 'VvAA Simpel',
  },
  [DutyPackageName.vvaaComplete]: {
    key: DutyPackageName.vvaaComplete,
    bgColor: '#f5821f',
    icon: Crown,
    tooltip: 'VvAA Compleet pakket',
  },
  [DutyPackageName.habitata]: {
    key: DutyPackageName.habitata,
    bgColor: 'black',
    icon: <TextRibbonIcon text="Ha" />,
    tooltip: 'Habitata',
  },
  [DutyPackageName.habitataLingewaard]: {
    key: DutyPackageName.habitataLingewaard,
    bgColor: '#283583',
    icon: <TextRibbonIcon text="HL" />,
    tooltip: 'Habitata Lingewaard',
  },
  [DutyPackageName.hpHypothekenSimplePlus]: {
    key: DutyPackageName.hpHypothekenSimplePlus,
    bgColor: '#ff8e00',
    icon: ChessPawn,
    tooltip: '100% Hypotheken Verduurzaamadvies',
  },
  [DutyPackageName.hpHypothekenSimplePlusGreen]: {
    key: DutyPackageName.hpHypothekenSimplePlusGreen,
    bgColor: '#8ec500',
    icon: ChessPawn,
    tooltip: '100% Groen Verduurzaamadvies',
  },
  [DutyPackageName.RISKSimple]: {
    key: DutyPackageName.RISKSimple,
    bgColor: '#BA342F',
    icon: <TextRibbonIcon text="S" />,
    tooltip: 'Subliem Verduurzamingsadvies',
  },
  [DutyPackageName.RISKSimpleGreen]: {
    key: DutyPackageName.RISKSimpleGreen,
    bgColor: '#E6B24E',
    icon: <TextRibbonIcon text="S" />,
    tooltip: 'Subliem Geregeld',
  },
  [DutyPackageName.analyseSimple]: {
    key: DutyPackageName.analyseSimple,
    bgColor: 'orange',
    icon: ChessPawn,
    tooltip: 'Analyse Simpel',
  },
  [DutyPackageName.analyseComplete]: {
    key: DutyPackageName.analyseComplete,
    bgColor: 'green',
    icon: Crown,
    tooltip: 'Analyse Compleet',
  },
  [DutyPackageName.mantgumComplete]: {
    key: DutyPackageName.mantgumComplete,
    bgColor: 'green',
    icon: Crown,
    tooltip: 'Duorsum Mantgum',
  },
  [DutyPackageName.slimsterSimple]: {
    key: DutyPackageName.slimsterSimple,
    bgColor: 'orange',
    icon: ChessPawn,
    tooltip: 'Slimster Simpel',
  },
  [DutyPackageName.slimsterSimpleFree]: {
    key: DutyPackageName.slimsterSimpleFree,
    bgColor: 'orange',
    icon: ChessPawn,
    tooltip: 'Slimster Simpel Gratis',
  },
  [DutyPackageName.compareWithIsaFree]: {
    key: DutyPackageName.compareWithIsaFree,
    bgColor: '#FA554E',
    icon: WomanFancy,
    tooltip: 'Compare with Isa Simpel',
  },
  [DutyPackageName.offerteNL]: {
    key: DutyPackageName.offerteNL,
    bgColor: 'orange',
    icon: ChessPawn,
    tooltip: 'Offerte.nl Simpel',
  },
  [DutyPackageName.installerPartnerSimple]: {
    key: DutyPackageName.installerPartnerSimple,
    bgColor: 'orange',
    icon: Hardhat,
    tooltip: 'Partnerinstallateur Simpel',
  },
  [DutyPackageName.triodosPlusLabel]: {
    key: DutyPackageName.triodosPlusLabel,
    bgColor: 'triodos.secondary',
    icon: TriodosSymbol,
    color: 'triodos.primary',
    tooltip: 'Triodos met Energielabel',
  },
  [DutyPackageName.triodosLabelOnly]: {
    key: DutyPackageName.triodosLabelOnly,
    bgColor: 'triodos.secondary',
    icon: TriodosSymbol,
    color: 'triodos.primary',
    tooltip: 'Triodos alleen Energielabel',
  },
  [DutyPackageName.triodosLabelDifference]: {
    key: DutyPackageName.triodosLabelDifference,
    bgColor: 'triodos.secondary',
    icon: <TextRibbonIcon text="+" />,
    color: 'triodos.primary',
    tooltip: 'Triodos meerprijs Energielabel',
  },
  [DutyPackageName.gavoora]: {
    key: DutyPackageName.gavoora,
    bgColor: 'labels.a',
    icon: FormatTextAlt,
    color: 'white',
    tooltip: 'GavoorA',
  },
  [DutyPackageName.triodos]: {
    key: DutyPackageName.triodos,
    bgColor: 'triodos.primary',
    icon: TriodosSymbol,
    color: 'triodos.secondary',
    tooltip: 'Triodos Compleet',
  },
  [DutyPackageName.homeInvest]: {
    key: DutyPackageName.homeInvest,
    bgColor: '#fab31e',
    icon: <TextRibbonIcon text="HI" />,
    color: 'grayBlack',
    tooltip: 'HomeInvest',
  },
  [DutyPackageName.soldsupply]: {
    key: DutyPackageName.soldsupply,
    bgColor: 'grayDarker',
    icon: ChessPawn,
    color: 'white',
    tooltip: 'Soldsupply Simpel',
  },
  [DutyPackageName.dataFanaticsSimple]: {
    key: DutyPackageName.dataFanaticsSimple,
    bgColor: 'grayDarker',
    icon: ChessPawn,
    color: 'white',
    tooltip: 'DataFanatics Simpel',
  },
  [DutyPackageName.promodomo]: {
    key: DutyPackageName.promodomo,
    bgColor: 'grayDarker',
    icon: ChessPawn,
    color: 'white',
    tooltip: 'Promodomo Simpel',
  },
};

// TODO: Remove big-ass const above and generate ribbons based on global rules, like below, or fetch all orderItems _once_ for info
// We can also push an array of ribbons based on duties, so it's clearer what this customer should receive (EP, discounts, cashbacks, etc).
const createRibbonByOrderLine = (line: houseCard_house_orders_orderLines): IRibbon => {
  let icon: any = <TextRibbonIcon text={line.orderItem.fullName[0]} />;
  switch (true) {
    case line.orderItem.key.includes('triodos'):
      icon = TriodosSymbol;
      break;
    case line.orderItem.key === DutyPackageName.gavoora:
      icon = FormatTextAlt;
      break;
    case line.orderItem.duties.includes(DutyName.virtualVisit):
      icon = Wifi;
      break;
    case line.orderItem.duties.includes(DutyName.energiepaspoort):
      icon = Energiepaspoort;
      break;
    case line.orderItem.key.toLowerCase().includes('simple'):
      icon = ChessPawn;
      break;
  }
  return {
    key: line.orderItem.key,
    bgColor: themify(line.orderItem.color),
    icon,
    color: readableColor(themify(line.orderItem.color)),
    tooltip: line.orderItem.fullName,
    to: `/pakketten?key=${line.orderItem.key}`,
  };
};

interface HouseCardRibbonsProps {
  legacy: boolean;
  isGVAHouse: boolean;
  orderLines: houseCard_house_orders_orderLines[];
}

const HouseCardRibbons: React.FC<HouseCardRibbonsProps> = ({ legacy, isGVAHouse, orderLines }) => {
  const history = useHistory();
  const orderRibbons: IRibbon[] = useMemo(
    () => [
      ...orderLines
        .map(line =>
          dutyPackageRibbons[line.orderItem.key]
            ? {
                ...dutyPackageRibbons[line.orderItem.key],
                key: line.orderItem.key,
                show: true,
              }
            : createRibbonByOrderLine(line),
        )
        .filter(notEmpty),
    ],
    [orderLines],
  );
  const ribbons: IRibbon[] = useMemo(() => {
    return [
      ...(legacy
        ? [
            {
              key: 'legacy',
              bgColor: 'orange',
              icon: Time,
              tooltip: 'Deze woning is van voor de grote update',
            },
          ]
        : []),
      ...orderRibbons,
      ...(isGVAHouse && !orderRibbons.some(r => r.key.includes('gavoora'))
        ? [{ ...dutyPackageRibbons.gavoora, key: 'gavoora' }]
        : []),
      // Uncomment this to show all duty package ribbons for testing (might need to cast the whole thing to `any`)
      // ...(Object.values(DutyPackageName)
      //   .map(
      //     dp =>
      //       dutyPackageRibbons[dp] && {
      //         ...dutyPackageRibbons[dp],
      //         key: dp,
      //         show: true,
      //       },
      //   )
      //   .filter(v => v !== undefined) as any),
    ];
  }, [isGVAHouse, legacy, orderRibbons]);

  return (
    <>
      {ribbons.filter(notEmpty).map((ribbon, index) => (
        <Ribbon
          key={ribbon.key}
          bgColor={themify(ribbon.bgColor)}
          left={(index + 1) * 6}
          width="30px"
          onClick={() => history.push(ribbon.to || '#')}
        >
          <Tooltip
            content={ribbon.tooltip}
            bgColor={ribbon.bgColor}
            color={ribbon.color || 'white'}
          >
            {ribbon.icon.props?.isTextIcon ? (
              ribbon.icon
            ) : (
              <Icon icon={ribbon.icon} solid fill={ribbon.color || 'white'} />
            )}
          </Tooltip>
        </Ribbon>
      ))}
    </>
  );
};

export default HouseCardRibbons;
