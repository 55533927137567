import { gql } from '@apollo/client';
import {
  ConsumptionFragment,
  HouseEnergyFragment,
  BasicAddressFragment,
  CustomerNameFragment,
  FileFragment,
  SituationEnergyPricesFragment,
} from '../fragments';

export const HOUSE_CARD = gql`
  ${CustomerNameFragment}
  ${BasicAddressFragment}
  ${ConsumptionFragment}
  ${HouseEnergyFragment}
  ${FileFragment}
  ${SituationEnergyPricesFragment}
  query houseCard($houseId: ID!) {
    house(id: $houseId) {
      id
      created
      legacy
      ...BasicAddress
      isGVAHouse
      initialQuestionsAnswered
      energy {
        ...HouseEnergy
      }
      orders {
        id
        status
        created
        orderLines {
          subject
          orderItem {
            key
            fullName
            color
            duties
          }
        }
      }
      dossier {
        id
        agent {
          id
          firstName
          lastName
          office {
            id
            company {
              id
              name
            }
          }
        }
      }
      advice {
        id
        isRequested
        requestedOn
        summary
      }
      area
      constructionYear
      type
      intake {
        id
        type
        area
        constructionYear
        isStarted
        startedOn
        isPlanned
        plannedFor
      }
      situation {
        id
        consumption {
          ...Consumption
        }
        energyPrices {
          ...SituationEnergyPrices
        }
      }
      files {
        ...File
      }
      customer {
        id
        ...CustomerName
        landingPage
        utm {
          source
          campaign
        }
      }
    }
  }
`;

export const HOUSE_ADDRESS = gql`
  ${BasicAddressFragment}
  query houseAddress($houseId: ID!) {
    house(id: $houseId) {
      id
      ...BasicAddress
      customer {
        id
      }
    }
  }
`;
