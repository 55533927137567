import { Gallery, Icon, ImageModal, ProgressiveImage } from '@energiebespaarders/symbols';
import { Gray } from '@energiebespaarders/symbols/helpers';
import { CancelCircle, ZoomIn } from '@energiebespaarders/symbols/icons/solid';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { fileUrl } from '../config';
import { isImage } from '../domains/Advice/utils';
import { pos } from '../styles/mixins';

const ZoomIcon = styled(Icon)`
  position: absolute;
  ${pos('t', 1)};
  ${pos('r', 4)};
  opacity: 0.25;
  transition: opacity 0.25s ${x => x.theme.curves.standard};
  z-index: 1;
`;

const EnhancedThumbnail = styled(Gallery.Thumbnail)`
  &:hover {
    ${ZoomIcon} {
      opacity: 0.9;
    }
  }
`;
/** Inspired by Gallery */
// TODO: Replace with Uploader symbol
export class ImagePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: undefined,
      loadedFiles: [],
    };
  }
  render() {
    const {
      deleteFile,
      exclusiveMatch,
      files,
      gutter,
      placeholderText,
      size,
      tags,
      thumbSize,
      selectedFile,
      setSelectedFile,
    } = this.props;
    const { activeFile, loadedFiles } = this.state;
    let filteredImages = files.filter(file => isImage(file.extension));
    if (files && tags.length > 0) {
      filteredImages = filteredImages.filter(file =>
        exclusiveMatch
          ? tags.every(tag => file.metadata.tags.indexOf(tag) > -1)
          : tags.some(tag => file.metadata.tags.indexOf(tag) > -1),
      );
    }
    return !filteredImages || filteredImages.length === 0 ? (
      <Gray>{placeholderText}</Gray>
    ) : (
      <>
        <Gallery.ThumbContainer gutter={gutter}>
          {filteredImages.map(file => (
            <EnhancedThumbnail
              key={file.id}
              gutter={gutter}
              thumbSize={thumbSize}
              style={
                selectedFile && file.id === selectedFile.id ? { outline: '3px solid #29cc96' } : {}
              }
            >
              <ProgressiveImage
                file={file}
                fileUrl={fileUrl}
                disableBlur={loadedFiles.includes(file.id)}
                onClick={() => setSelectedFile(file)}
                onLoad={() => this.setState({ loadedFiles: [...loadedFiles, file.id] })}
                size={size}
                thumbSize={thumbSize}
                title={file.metadata.title}
              />
              {deleteFile && (
                <Gallery.DeleteIcon
                  icon={CancelCircle}
                  solid
                  fill="white"
                  hoverColor="red"
                  onClick={() =>
                    window.confirm(
                      'Weet je zeker dat je dit bestand wilt verwijderen? Dit kan niet ongedaan gemaakt worden.',
                    )
                      ? deleteFile(file.id)
                      : null
                  }
                />
              )}
              <ZoomIcon
                icon={ZoomIn}
                solid
                fill="white"
                hoverColor="blue"
                onClick={() => this.setState({ activeFile: file })}
              />
            </EnhancedThumbnail>
          ))}
        </Gallery.ThumbContainer>
        {activeFile && (
          <ImageModal
            closeModal={() => this.setState({ activeFile: undefined })}
            file={activeFile}
            fileUrl={fileUrl}
            disableBlur={loadedFiles.includes(activeFile.id)}
            onLoad={() => this.setState({ loadedFiles: [...loadedFiles, activeFile.id] })}
            isOpen={Boolean(activeFile)}
          />
        )}
      </>
    );
  }
}

ImagePicker.propTypes = {
  selectedFile: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setSelectedFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func,
  exclusiveMatch: PropTypes.bool,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        title: PropTypes.string,
        uploadedOn: PropTypes.object,
      }),
    }),
  ),
  placeholderText: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'ss', 'sm', 'md', 'lg', 'xl']),
  modifiable: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
};

ImagePicker.defaultProps = {
  files: [],
  gutter: 1,
  modifiable: false,
  placeholderText: 'Er zijn nog geen bestanden geüpload',
  tags: [],
};

export default ImagePicker;
