import { useMutation } from '@apollo/client';
import { getQuoteTotals } from '@energiebespaarders/constants';
import _ from 'lodash';
import React, { useCallback } from 'react';
import useToaster from '../../../hooks/useToaster';
import { UPDATE_DISCOUNT } from '../../../queries/installatron';
import { installationByHouseSolution_installationByHouseSolution } from '../../../types/generated/installationByHouseSolution';
import { updateDiscount, updateDiscountVariables } from '../../../types/generated/updateDiscount';
import { DiscountInput, DiscountType } from '../../../types/graphql-global-types';
import DiscountForm from './DiscountForm';
interface InstallationDiscountFormProps {
  installation: installationByHouseSolution_installationByHouseSolution;
  onClose: () => void;
}

const InstallationDiscountForm: React.FC<InstallationDiscountFormProps> = ({
  installation,
  onClose,
}) => {
  const toast = useToaster();
  const currentDiscount = installation.discount;

  const onDiscountComplete = useCallback(
    (newDiscountAmount: number) => {
      onClose();
      toast({
        type: 'success',
        message: newDiscountAmount === 0 ? 'De korting is verwijderd' : 'De korting is toegepast',
      });
    },
    [onClose, toast],
  );

  const [updateDiscountMutation] = useMutation<updateDiscount, updateDiscountVariables>(
    UPDATE_DISCOUNT,
    { onCompleted: data => onDiscountComplete(data.updateDiscount?.discount?.amount ?? 0) },
  );

  const handleSubmit = useCallback(
    (discount: DiscountInput) => {
      const percentageBasedAmount = getQuoteTotals([...installation.items], discount)
        .totalDiscountIncludingTax;

      updateDiscountMutation({
        variables: {
          installationId: installation.id,
          discount: {
            ...discount,
            type: DiscountType.amountInclTax, // NOTE: This is now fixed to store only fixed amounts, so it doesn't change after job mutations
            amount: Math.round(percentageBasedAmount * 100) / 100,
          },
        },
      });
    },
    [installation.id, installation.items, updateDiscountMutation],
  );

  const handleRemoveDiscount = useCallback(
    () =>
      updateDiscountMutation({
        variables: {
          installationId: installation.id,
          discount: null,
        },
      }),
    [updateDiscountMutation, installation.id],
  );

  // A function to retain the same absolute discount amount when switching between percentage and fixed value
  const transformDiscount = useCallback(
    (discount: DiscountInput, newType: DiscountType) => {
      const totals = getQuoteTotals([...installation.items], discount);

      if (
        discount.type === DiscountType.percentageInclTax &&
        newType === DiscountType.amountInclTax
      ) {
        return totals.totalDiscountIncludingTax;
      } else if (
        discount.type === DiscountType.amountInclTax &&
        newType === DiscountType.percentageInclTax
      ) {
        // Return a percentage rounded to 2 decimals
        const discountFactor = totals.totalDiscountIncludingTax / totals.totalIncludingTax;
        _.round(discountFactor * 100, 2);
      }
      return discount.amount;
    },
    [installation.items],
  );

  return (
    <DiscountForm
      initialValue={currentDiscount as DiscountInput}
      onCancel={onClose}
      onClear={handleRemoveDiscount}
      onSubmit={handleSubmit}
      transformDiscount={transformDiscount}
    />
  );
};

export default InstallationDiscountForm;
