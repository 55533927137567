import { gql } from '@apollo/client';

export const GET_EMAILS_BY_ENTITY_ID_AND_TEMPLATE = gql`
  query getEmailsEventsByEntityIdAndTemplate(
    $entityId: ID!
    $template: MailjetTemplateDescriptor!
  ) {
    getEmailsEventsByEntityIdAndTemplate(entityId: $entityId, template: $template) {
      id
      template
      sent
      open
      bounce
      blocked
    }
  }
`;
