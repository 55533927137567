import { Box, Card, Flex, theme, Toast } from '@energiebespaarders/symbols';
import { Center, Orange, Small } from '@energiebespaarders/symbols/helpers';
import {
  CalendarCancel,
  CalendarCheck,
  CalendarNext,
  CalendarSnooze,
  CalendarUser,
} from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  jobDomainQuote_quoteById,
  jobDomainQuote_quoteById_jobs,
} from '../../../types/generated/jobDomainQuote';
import { JobPlanningStatus, JobType } from '../../../types/graphql-global-types';
import CompanyLogo from '../../CompanyLogo';
import JobItemPurchasePricesTable from '../JobItemPurchasePricesTable';
import JobActions from './JobActions';
import JobFiles from './JobFiles';
import JobPdfRemarks from './JobPdfRemarks';
import JobStatus from './JobStatus';
import PlanningProposals from './PlanningProposals';
import PricePdfRemarks from './PricePdfRemarks';
import PurchaseOrderControls from './PurchaseOrderControls';
import SignoffDetailsLink from './SignoffDetailsLink';
import UncompletionControls from './UncompletionControls';

const RightBorder = styled(Box)`
  border-right: 1px solid ${theme.colors.gray};
`;

export const JobPlanningStatusIcons: Record<
  JobPlanningStatus,
  {
    title: (job: {
      currentPlanning: { plannedFor?: Date } | null;
      proposals: readonly any[];
    }) => string;
    color: string;
    icon: any;
  }
> = {
  plannedIn: {
    title: job =>
      `Ingepland voor ${dayjs(job.currentPlanning?.plannedFor).format('DD/MM/YY HH:mm')}`,
    color: 'green',
    icon: CalendarCheck,
  },
  proposalExpired: {
    title: job => `Planningsvoorstel${job.proposals.length === 1 ? '' : 'len'} verlopen`,
    color: 'red',
    icon: CalendarCancel,
  },
  proposalRejected: {
    title: () => 'Geen planningsvoorstel geschikt voor klant',
    color: 'orange',
    icon: CalendarCancel,
  },
  proposalSent: {
    title: () => 'Planningsvoorstel verstuurd naar klant',
    color: 'blue',
    icon: CalendarUser,
  },
  noProposal: {
    title: () => 'Nog geen planningsvoorstel gedaan door installateur',
    color: 'gray',
    icon: CalendarSnooze,
  },
  uncompletedPassedPlannedDate: {
    title: () => 'Ingeplande datum is verstreken maar de opdracht is nog niet afgerond',
    color: 'orange',
    icon: CalendarNext,
  },
  unsent: {
    title: () => 'Nog niet verstuurd',
    color: 'grayDark',
    icon: CalendarNext,
  },
  // completed: {
  //   title: () => 'Uitgevoerd en afgerond',
  //   color: 'green',
  //   icon: SpecialCheck,
  // },
};

function getWarnings(job: jobDomainQuote_quoteById_jobs) {
  const warnings = [];
  if (!job.installer.inspectionEmail) {
    warnings.push(
      'Er is geen e-mailadres bekend voor deze installatiepartner. Je zult de opdracht handmatig moeten versturen.',
    );
  }
  if (!job.installer.customerNotificationPeriod) {
    warnings.push(
      'Er is geen planning period of klant notificatie period voor deze installatiepartner.',
    );
  }

  return warnings.map(warning => (
    <Small key={`warning ${warning}`}>
      <Orange>{warning}</Orange>
    </Small>
  ));
}

interface JobControlsProps {
  job: jobDomainQuote_quoteById_jobs;
  quote: jobDomainQuote_quoteById;
}

const JobControls: React.FC<JobControlsProps> = ({ quote, job }) => {
  const planningStatusProps = JobPlanningStatusIcons[job.planningStatus];
  const incorrectJobType =
    (quote.final && job.type === JobType.PTC) ||
    (!quote.final && job.type === JobType.INSTALLATION);

  return (
    <>
      {job.type === JobType.INSTALLATION && (
        <Flex>
          <Box width={[1, 1, quote.deal.ptcJob && job.installerSignoff ? 1 / 2 : 1]}>
            {/* If it's an installation job and there is also a schouw on the deal */}
            {quote.deal.ptcJob && <SignoffDetailsLink job={quote.deal.ptcJob} />}
          </Box>
          <Box width={[1, 1, quote.deal.ptcJob && job.installerSignoff ? 1 / 2 : 1]}>
            {/* If there is an oplever doc */}
            {job.installerSignoff && <SignoffDetailsLink job={job} />}
          </Box>
        </Flex>
      )}
      {/* If it's a PTC and there are results */}
      {job.type === JobType.PTC && <SignoffDetailsLink job={job} />}

      <JobItemPurchasePricesTable quote={quote} job={job} />

      {incorrectJobType && (
        <Toast
          type="alert"
          message={`LET OP! De offerte is ${!quote.final ? 'niet ' : 'al '}definitief!`}
          width="100%"
          fontSize={4}
          my={2}
        />
      )}
      <Box width={1} mt={2}>
        <Card>
          <Flex flexWrap="wrap">
            <RightBorder width={[1, 1, 2 / 8]} px={2}>
              <JobFiles job={job} />
            </RightBorder>
            <RightBorder width={[1, 1, 3 / 8]} px={2}>
              <JobStatus
                job={{ ...job, plannings: job.plannings.slice(), proposals: job.proposals.slice() }}
              />
            </RightBorder>
            <Box width={[1, 1, 3 / 8]} px={1}>
              <JobActions job={job} quote={quote} />
              {getWarnings(job)}
              {job.type === 'INSTALLATION' && <PurchaseOrderControls job={job} quote={quote} />}
              {job.completed && <UncompletionControls job={job} quote={quote} />}
            </Box>
          </Flex>
        </Card>
      </Box>

      <Flex flexWrap="wrap" alignItems="flex-start" mx={-1}>
        <Box width={[1, 2 / 3]} px={1} mt={2}>
          <JobPdfRemarks quote={quote} job={job} />
        </Box>
        <Box width={[1, 1 / 3]} px={1} mt={2}>
          <Box width={1} mb={2}>
            <Center block>
              <Link to={`/installer/${job.installer.id}`} target="_blank">
                <CompanyLogo companyId={job.installer.id || ''} companyType="installer" />
              </Link>
            </Center>
          </Box>
          <PricePdfRemarks job={job} />
        </Box>
        <Box width={1} px={1} mt={2}>
          <PlanningProposals planningStatusProps={planningStatusProps} job={job} />
        </Box>
      </Flex>
    </>
  );
};

export default JobControls;
