import { gql, useMutation } from '@apollo/client';
import { useForm } from '@energiebespaarders/hooks';
import { Accordion, Box, Button, Card, Checkbox, Flex, Input } from '@energiebespaarders/symbols';
import { CalendarAdd } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React from 'react';
import { useMe } from '../../hooks/useMe';
import useToaster from '../../hooks/useToaster';
import {
  updateJobPlanning,
  updateJobPlanningVariables,
} from '../../types/generated/updateJobPlanning';
import { PlannerUserType } from '../../types/graphql-global-types';
import JobStatus, { JobWithStatus } from '../jobs/JobControls/JobStatus';

export const UPDATE_JOB_PLANNING = gql`
  mutation updateJobPlanning(
    $jobId: ID!
    $date: Date!
    $sendConfirmationEmail: Boolean!
    $myId: ID!
    $myType: PlannerUserType!
  ) {
    updateJobPlanning(
      jobId: $jobId
      date: $date
      sendConfirmationEmail: $sendConfirmationEmail
      myId: $myId
      myType: $myType
    ) {
      id
      plannings {
        created
        sent
        created
        plannedOn
        plannedFor
      }
    }
  }
`;

type UpdatePlanningFormProps = {
  currentDate: Date | undefined;
  installerName: string;
} & { job: JobWithStatus };

type PlanningForm = {
  date: string;
  time: string;
  confirmationEmail: boolean;
};

const UpdatePlanningForm: React.FC<UpdatePlanningFormProps> = ({
  job,
  currentDate,
  installerName,
}) => {
  const toast = useToaster();
  const { me } = useMe();

  const [updatePlanning, { loading }] = useMutation<updateJobPlanning, updateJobPlanningVariables>(
    UPDATE_JOB_PLANNING,
    {
      onCompleted: () => toast({ type: 'success', message: 'Planningsdatum succesvol gewijzigd' }),
      onError: e => toast({ type: 'error', message: e.message }),
    },
  );

  const { formState, handleChange, submitForm } = useForm<PlanningForm>({
    initialValues: {
      date: currentDate ? currentDate.toISOString().substr(0, 10) : '',
      time: currentDate ? dayjs(currentDate).format('HH:mm') : '',
      confirmationEmail: false,
    },
    validate: (v, e) => {
      if (v.date === '') e.date = 'Verplicht';
      return e;
    },
    handleSubmit: v =>
      updatePlanning({
        variables: {
          jobId: job.id,
          date: new Date(v.date + ' ' + v.time),
          sendConfirmationEmail: v.confirmationEmail,
          myId: me.id,
          myType: PlannerUserType.operator,
        },
      }),
  });

  return (
    <Card animation="none" shadow="none" p={0} borderColor="grayLight" mb={2}>
      <Accordion
        contentBorderColor="grayLight"
        icon={CalendarAdd}
        iconColor="blue"
        title="Nieuwe planningsinformatie invoeren"
        titleSize={5}
        zIndex={110}
      >
        <Flex m={-1}>
          <Box width={1 / 2} p={1}>
            <JobStatus job={job} />
          </Box>
          <Box width={1 / 2} p={1}>
            <p>
              Als je een nieuwe planningsdatum van {installerName} hebt, kun je deze hier invullen:
            </p>
            <Input
              type="date"
              value={formState.date.value}
              onChange={e => handleChange({ date: e.target.value })}
              label="Datum"
            />
            <Input
              type="time"
              value={formState.time.value}
              onChange={e => handleChange({ time: e.target.value })}
              label="Tijdstip (optioneel)"
            />
            <p>Dit zal zowel ons systeem als Pipedrive updaten.</p>
            <p>Wil je ook een bevestiging naar de klant sturen?</p>
            <Checkbox
              checked={formState.confirmationEmail.value}
              value={'customerConfirmation'}
              id={`customerConfirmation-${job.id}`}
              label="Bevestiging sturen"
              onChange={() =>
                handleChange({ confirmationEmail: !formState.confirmationEmail.value })
              }
            />
            <Button disabled={loading} onClick={() => submitForm()}>
              Opslaan
            </Button>
          </Box>
        </Flex>
      </Accordion>
    </Card>
  );
};

export default UpdatePlanningForm;
