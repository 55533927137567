import { delimit, SolutionDomain } from '@energiebespaarders/constants';
import { Box, Button, Icon, Input, Table, Tooltip } from '@energiebespaarders/symbols';
import { Color, Medium, Right } from '@energiebespaarders/symbols/helpers';
import { CaretDown, CaretUp, Check, Trash } from '@energiebespaarders/symbols/icons/solid';
import { sum } from 'lodash';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { fixUnit } from '../../lib/utils';
import { PriceAvailability, PriceUnit } from '../../types/graphql-global-types';
import ItemConfiguratorLite from './ItemConfiguratorLite';

import styled, { css } from 'styled-components';
import getPriceAvailabilityProps from '../../lib/utils/getPriceAvailabilityProps';
import { getProductsBySolutionsAndSupplier_products } from '../../types/generated/getProductsBySolutionsAndSupplier';
import { jobForMutationModal_jobById_mutation_items } from '../../types/generated/jobForMutationModal';

type t_product = getProductsBySolutionsAndSupplier_products;

export type MutationItem = {
  amount: number;
  price: {
    purchasePrice: number;
    retailPrice: number;
    availability: PriceAvailability | null;
  };
  product?: { id: string; title: string; priceUnit?: PriceUnit | null; archived?: Date };
};

export type EditingMutationItem = MutationItem & { index: number };

export function installationItemsAsMutationItems(
  items: jobForMutationModal_jobById_mutation_items[],
): MutationItem[] {
  return items.map(({ retailPrice, purchasePrice, ...item }) => ({
    ...item,
    price: { retailPrice, purchasePrice, availability: item.price.availability },
  }));
}

interface InstallableItemsTableProps {
  readonly items: ReadonlyArray<MutationItem>;
  readonly editingItem: EditingMutationItem | undefined;
  setEditingItem: (item?: EditingMutationItem) => void;
  submitEditingItem: () => void;
  removeEditingItem: () => void;
  reorderItem: (offset: ReorderOffset) => void;
  themeColor: string;
  supplierId: string;
  solutionDomain: SolutionDomain;
  hidePrices?: boolean;
  isReadOnly?: boolean;
}

export const useInstallableItemsTableHelpers = ({
  items,
  setItems,
  editingItem,
  setEditingItem,
}: {
  readonly editingItem: EditingMutationItem | undefined;
  setItems: (items: MutationItem[]) => void;
  readonly items: ReadonlyArray<MutationItem>;
  setEditingItem: (item?: EditingMutationItem) => void;
}) => {
  const [newProduct, setNewProduct] = useState<t_product | undefined>();
  const [isAddingNewProduct, setIsAddingNewProduct] = useState(false);

  // This will need to be possible at some point: e,g. when adding 2 extra panels after their price has changed
  const isNewProductAlreadyAdded =
    newProduct && items.some(item => item.product?.id === newProduct.id);

  const submitNewProduct = useCallback(() => {
    const newItem = {
      product: newProduct!,
      amount: 1,
      // What about using previous prices?
      price: newProduct!.price!,
    };
    setItems([...items.map(item => ({ ...item, isEditing: false })), newItem]);
    setEditingItem({ ...newItem, index: items.length });
    setNewProduct(undefined);
  }, [items, newProduct, setEditingItem, setItems]);

  const submitEditingItem = useCallback(() => {
    if (editingItem) {
      const { index, ...newItem } = editingItem;
      if (
        items.some((otherItem, otherIndex) =>
          otherIndex === index ? false : otherItem.product?.id === editingItem.product?.id,
        )
      ) {
        return window.alert('Dit product kan nog nogmaals worden toegevoegd!');
      }
      const newItems = [...items];
      newItems[index] = newItem;
      setItems(newItems);
      setEditingItem(undefined);
    }
  }, [editingItem, items, setEditingItem, setItems]);

  const removeEditingItem = useCallback(() => {
    if (editingItem) {
      const { index } = editingItem;
      setItems(items.filter((_, i) => i !== index));
      setEditingItem(undefined);
    }
  }, [editingItem, items, setEditingItem, setItems]);

  const reorderItem = useCallback(
    (offset: number) => {
      if (editingItem) {
        // Swap the order of the items
        const newItems = [...items];
        const tmp = items[editingItem.index];
        newItems[editingItem.index] = newItems[editingItem.index + offset];
        newItems[editingItem.index + offset] = tmp;
        setItems(newItems);

        // Update the index of the item being edited (stored in a separate state)
        setEditingItem({ ...editingItem, index: editingItem.index + offset });
      }
    },
    [editingItem, items, setEditingItem, setItems],
  );

  return {
    submitEditingItem,
    removeEditingItem,
    reorderItem,
    newProduct,
    setNewProduct,
    submitNewProduct,
    isNewProductAlreadyAdded,
    isAddingNewProduct,
    setIsAddingNewProduct,
  };
};

const ClickableRow = styled(Table.Row)<{ $isEditable: boolean }>`
  cursor: ${x => (x.$isEditable ? 'pointer' : 'inherit')};

  ${x =>
    x.$isEditable &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`;

type CellContext = { supplierId: string; solutionDomain: SolutionDomain; hidePrices?: boolean };

type CellProps = {
  item: MutationItem & { index?: number };
  updateItem: (newItem: MutationItem) => void;
  removeItem: (item: MutationItem) => void;
  toggleEdit: (item: MutationItem) => void;
  submitItem: (item: MutationItem) => void;
  reorderItem: (offset: ReorderOffset) => void;
  amountItems: number;
  context: CellContext;
};

type Column = {
  id: string;
  header: ReactNode | React.FC<CellProps>;
  Cell: React.FC<CellProps>;
  EditableCell: React.FC<CellProps>;
  width: number;
  Footer?: React.FC<{ readonly items: ReadonlyArray<MutationItem> }>;
};

const cellStyling = { padding: '1em 6px', verticalAlign: 'center' };

const columns: Column[] = [
  {
    id: 'amount',
    header: 'Aantal',
    width: 1 / 12,
    Cell: function ProductAmount({ item, toggleEdit }) {
      return (
        <Box textAlign="center" style={cellStyling} onClick={toggleEdit} data-editable>
          {item.amount}
          {item.product && item.product.priceUnit !== PriceUnit.unit ? (
            <> {fixUnit(item.product.priceUnit)}</>
          ) : undefined}
        </Box>
      );
    },
    EditableCell: function ProductAmount({ item, updateItem }) {
      return (
        <Input
          autoFocus
          type="number"
          fontSize={6}
          textAlign="center"
          value={item.amount}
          onChange={e => updateItem?.({ ...item, amount: Number(e.target.value) })}
          min={0}
          mb={0}
          px={1}
          data-editable
        />
      );
    },
  },
  {
    id: 'title',
    header: 'Product',
    width: 5 / 12,
    Cell: function ProductTitle({ item, toggleEdit }) {
      let label: ReactNode = item.product?.title || '';
      if (item.product?.archived) {
        label = <Color c="darkGray">{label}</Color>;
      } else if (item.price.availability && item.price.availability !== PriceAvailability.inStock) {
        const indicator = getPriceAvailabilityProps(item.price.availability);
        label = (
          <>
            <Tooltip content={indicator.toolTip} bgColor={indicator.fill}>
              <Icon icon={indicator.icon} solid fill={indicator.fill} />
            </Tooltip>{' '}
            <Color c={indicator.fill}>{label}</Color>
          </>
        );
      }
      return (
        <Box style={cellStyling} onClick={toggleEdit} data-editable>
          {label}
        </Box>
      );
    },
    EditableCell: React.memo(function ProductTitle({ item, updateItem, context }) {
      return (
        <Box style={{ ...cellStyling, paddingTop: 4, paddingBottom: 0 }} data-editable>
          <ItemConfiguratorLite
            value={item.product}
            solutionDomain={context.solutionDomain}
            supplierId={context.supplierId}
            onChange={prod => {
              if (!prod) return updateItem({ ...item, product: undefined });
              const price = prod.price;
              if (!price)
                return window.alert(
                  'Oeps, dit zou niet moeten gebeuren: dit product lijkt toch niet beschikbaar',
                );
              updateItem({
                ...item,
                product: prod,
                price,
              });
            }}
            clearable={false}
            data-editable
          />
        </Box>
      );
    }),
    Footer: function TotalTitle() {
      return <Medium style={cellStyling}>Totaal</Medium>;
    },
  },
  {
    id: 'purchasePrice',
    header: <Right block>Inkoopprijs</Right>,
    width: 2 / 12,
    Cell: function PurchasePrice({ item }) {
      return (
        <Box textAlign="right" style={cellStyling}>
          € {delimit(item.price.purchasePrice ?? 0, 2)}
        </Box>
      );
    },
    EditableCell: function PurchasePrice({ item }) {
      return (
        <Box textAlign="right" style={cellStyling}>
          € {delimit(item.price.purchasePrice ?? 0, 2)}
        </Box>
      );
    },
    Footer: function TotalPurchasePrice({ items }) {
      const total = sum(items.map(i => i.amount * i.price.purchasePrice));
      return (
        <Right block style={cellStyling}>
          <Medium>€ {delimit(total ?? 0, 2)}</Medium>
        </Right>
      );
    },
  },
  {
    id: 'retailPrice',
    header: <Right block>Verkoopprijs</Right>,
    width: 2 / 12,
    Cell: function RetailPrice({ item, toggleEdit }) {
      return (
        <Box textAlign="right" style={cellStyling} onClick={toggleEdit} data-editable>
          € {delimit(item.price.retailPrice ?? 0, 2)}
        </Box>
      );
    },
    EditableCell: function EditingRetailPrice({ item, updateItem }) {
      return (
        <Input
          type="number"
          textAlign="center"
          fontSize={6}
          addonContent="€"
          addonSide="start"
          value={item.price.retailPrice}
          onChange={e =>
            updateItem?.({ ...item, price: { ...item.price, retailPrice: Number(e.target.value) } })
          }
          onBlur={() =>
            updateItem?.({
              ...item,
              price: { ...item.price, retailPrice: Math.round(item.price.retailPrice * 100) / 100 },
            })
          }
          mb={0}
          px="3px"
          data-editable
        />
      );
    },
  },
  {
    id: 'subtotal',
    header: function SubtotalHeader({ context }) {
      return context.hidePrices ? null : <Right block>Subtotaal</Right>;
    },
    width: 2 / 12,
    Cell: function Subtotal({ item, context }) {
      if (context.hidePrices) return null;
      return (
        <Right block style={cellStyling}>
          € {delimit(item.amount * item.price.retailPrice ?? 0, 2)}
        </Right>
      );
    },
    EditableCell: function ConfirmEdit({ item, amountItems, submitItem, removeItem, reorderItem }) {
      return (
        <Box textAlign="right" px={1} data-editable>
          <Button minimal mb={0} p={0} onClick={() => reorderItem(-1)} disabled={item.index === 0}>
            <Icon icon={CaretUp} fill="gray" />
          </Button>

          <Button
            minimal
            mb={0}
            p={0}
            onClick={() => reorderItem(1)}
            disabled={item.index === amountItems - 1}
          >
            <Icon icon={CaretDown} fill="gray" />
          </Button>

          <Button bgColor="red" minimal mb={0} onClick={() => removeItem(item)}>
            <Icon icon={Trash} fill="red" />
          </Button>

          <Button bgColor="green" minimal mb={0} mr={0} onClick={() => submitItem(item)}>
            <Icon icon={Check} fill="green" />
          </Button>
        </Box>
      );
    },
    Footer: function TotalCosts({ items }) {
      // TODO: Might be good to take into account the editingItem here
      const total = sum(items.map(i => i.amount * i.price.retailPrice));
      return (
        <Box textAlign="right" px={1}>
          <Medium>€ {delimit(total ?? 0, 2)}</Medium>
        </Box>
      );
    },
  },
];

type ReorderOffset = 1 | -1;

const InstallableItemsTable: React.FC<InstallableItemsTableProps> = ({
  items,
  editingItem,
  setEditingItem,
  submitEditingItem,
  removeEditingItem,
  themeColor,
  reorderItem,
  isReadOnly,
  ...context
}) => {
  const onClickBackground = (e: React.MouseEvent) => {
    const el = e.target as HTMLElement;
    // Also includes the element itself
    const hasEditableAncestor = el.closest('[data-editable="true"]');
    // Exception for the React Select input: it has a pop-out in a different element
    const isSelectMenu = el.closest('[class$="menu"]'); // className ending with "menu"

    if (editingItem && !(hasEditableAncestor || isSelectMenu)) {
      setEditingItem(undefined);
    }
  };

  const relevantColumns = columns.filter(col =>
    context.hidePrices ? !col.id.includes('Price') : true,
  );
  const totalWidth = sum(relevantColumns.map(col => col.width));
  const layout = useMemo(() => relevantColumns.map(col => col.width / totalWidth), [
    relevantColumns,
    totalWidth,
  ]);

  return (
    <Table layout={layout} themeColor={themeColor}>
      <Table.Row isHeader onClick={onClickBackground}>
        {relevantColumns.map(c => (
          <Table.Cell key={`header-${c.id}`} textAlign="left">
            {c.header}
          </Table.Cell>
        ))}
      </Table.Row>

      {items.map((item, i) => {
        const isEditing = editingItem?.index === i;
        const props = {
          toggleEdit: () => (isReadOnly ? undefined : setEditingItem({ ...item, index: i })),
          updateItem: (newItem: MutationItem) => setEditingItem({ ...newItem, index: i }),
          removeItem: removeEditingItem,
          submitItem: submitEditingItem,
          reorderItem,
          amountItems: items.length,
          context: context,
        };
        return (
          <ClickableRow
            $isEditable={!isReadOnly && !isEditing}
            key={item.product?.id || 'no-product'}
            onClick={isReadOnly || isEditing ? undefined : props.toggleEdit}
          >
            {relevantColumns.map(({ id, Cell, EditableCell: EditingCell }) => (
              <Table.Cell key={`cell-${id}`} cellPadding="0" onClick={onClickBackground}>
                {isEditing ? (
                  <EditingCell item={editingItem} {...props} />
                ) : (
                  <Cell item={item} {...props} />
                )}
              </Table.Cell>
            ))}
          </ClickableRow>
        );
      })}

      {!context.hidePrices && relevantColumns.some(c => c.Footer) && (
        <Table.Row isFooter onClick={onClickBackground}>
          {columns.map(({ id, Footer }) => (
            <Table.Cell key={`footer-${id}`} textAlign="left" cellPadding="0">
              {Footer ? <Footer items={items} /> : ''}
            </Table.Cell>
          ))}
        </Table.Row>
      )}
    </Table>
  );
};

export default InstallableItemsTable;
