import {
  Accordion,
  Box,
  Checkbox,
  Radio,
  RadioGroup,
  Select,
  Separator,
} from '@energiebespaarders/symbols';
import { Red, Small } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { QuoteOpen } from '@energiebespaarders/symbols/icons/solid';
import React, { Dispatch, SetStateAction } from 'react';
import useToaster from '../../hooks/useToaster';
import { copyToClipboard } from '../../lib/utils';
import { generateAdviceTexts_generateAdviceTexts } from '../../types/generated/generateAdviceTexts';
import { ComposedAdvice, Paragraph, QuoteMark } from './AutomaticAdviceComposer';

interface Props {
  sortedParts: generateAdviceTexts_generateAdviceTexts[];
  setSortedParts: Dispatch<SetStateAction<generateAdviceTexts_generateAdviceTexts[]>>;
  editingTexts: boolean;
  adviceComposerLoading: boolean;
  separatorText?: string;
  /** Prefixes headers with ##. Enabled by default, intended for WYSIWG editor. Can be disabled when copying to a plain TextArea */
  formatAsMarkdownInCopy?: boolean;
}

export const copyAllTextsToClipboard = (
  sortedParts: generateAdviceTexts_generateAdviceTexts[],
  markdownHeaders = true,
) => {
  const allTextsArray = sortedParts.map(part => {
    const partTextsMet = part.value.adviceTexts.filter(text => text.value.met);
    const hasSomeMet = partTextsMet.length > 0;

    let partText = '';

    if (hasSomeMet || part.value.required) {
      if (part.value.partTextTitle.length > 0) {
        partText += `${markdownHeaders ? '##' : ''}${part.value.partTextTitle}\n`; // ## is parsed as a header in WYSIWYG
      }
      if (part.value.required && !hasSomeMet) {
        partText += `<<<${part.value.partTitle} GEEN TEKST GESELECTEERD>>>\n`;
      }
    }

    partText += partTextsMet.map(text => text.value.text + '\n');
    return partText;
  });
  const allTexts = allTextsArray.reduce((acc, curr) => {
    return acc + curr;
  });
  copyToClipboard(allTexts);
};

const GeneratedAdviceTexts: React.FC<Props> = ({
  sortedParts,
  editingTexts,
  adviceComposerLoading,
  setSortedParts,
  separatorText,
  formatAsMarkdownInCopy = true,
}) => {
  const mobile = useIsMobile();
  const toast = useToaster();

  const handleChange = (value: string, key: string, type: string) => {
    const partIndex = sortedParts.findIndex(p => p.key === key);
    const part = { ...sortedParts[partIndex] };
    const adviceTexts = part.value.adviceTexts.map(text => {
      if (type === 'radio' || type === 'select') {
        return {
          ...text,
          value: { ...text.value, met: text.value.key === value },
        };
      } else if (type === 'checkbox') {
        return {
          ...text,
          value: {
            ...text.value,
            met: text.value.key === value ? !text.value.met : text.value.met,
          },
        };
      }
      return text;
    });

    const newParts = [...sortedParts];
    newParts[partIndex] = {
      ...newParts[partIndex],
      value: { ...newParts[partIndex].value, adviceTexts },
    };
    setSortedParts(newParts);
  };

  const handleClickCopyAllTexts = () => {
    copyAllTextsToClipboard(sortedParts, formatAsMarkdownInCopy);
    toast({
      type: 'info',
      message: 'Tekst gekopieerd',
    });
  };

  return (
    <>
      {sortedParts?.length > 0 && !editingTexts && (
        <>
          <Separator my={3} text={separatorText || 'Alle tekst'} />
          <Paragraph>
            <ComposedAdvice onClick={handleClickCopyAllTexts}>
              <QuoteMark icon={QuoteOpen} solid fill="green" />
              {sortedParts.map((part, partIndex) => {
                const partTexts = part.value.adviceTexts.filter(text => text.value.met);
                return (
                  <div key={`part-${partIndex}`}>
                    {part.value.partTextTitle.length > 0 &&
                      (part.value.required || partTexts.length > 0) && (
                        <Box mt={partIndex > 0 ? 8 : 0}>
                          <strong>{part.value.partTextTitle}</strong>
                        </Box>
                      )}
                    {partTexts.length === 0 && part.value.required && (
                      <p>
                        <Red>{`<<<${part.value.partTitle} GEEN TEKST GESELECTEERD>>>`}</Red>
                      </p>
                    )}
                    {partTexts.map(text => {
                      return text.value.text.split('\n').map((paragraph, parIndex) => (
                        <p key={text.value.key + '-p-' + parIndex}>
                          <Small key={text.value.key + '-p-' + parIndex}>{paragraph}</Small>
                          <br />
                        </p>
                      ));
                    })}
                  </div>
                );
              })}
            </ComposedAdvice>
          </Paragraph>
        </>
      )}
      {!adviceComposerLoading && editingTexts && (
        <>
          <Separator my={3} text={'Teksten bewerken...'} />
          {sortedParts.map(({ key, value: part }) => {
            const selectedText = part.adviceTexts.find(t => t.value.met);
            const textOptions = [
              { value: '', label: '(Geen tekst)' },
              ...part.adviceTexts.map(text => {
                return {
                  value: text.value.key,
                  label: text.value.title,
                };
              }),
            ];
            return (
              <Accordion
                key={key}
                baseBgColor="white"
                bgColor="grayLighter"
                contentBgColor="white"
                contentBorderColor="grayLight"
                contentPadding={2}
                title={part.partTitle}
                titleSize={5}
                mb={3}
              >
                {part.adviceTexts.length < 6 ? (
                  <RadioGroup
                    bgColor="green"
                    divider={mobile ? 2 : 3}
                    fontSize={6}
                    labelColor="grayBlack"
                    labelSize={6}
                    onChange={(value: string) =>
                      handleChange(value, key, part.multiple ? 'checkbox' : 'radio')
                    }
                  >
                    {!part.multiple && (
                      <Radio
                        checked={part.adviceTexts.filter(t => t.value.met).length === 0}
                        label="(Geen tekst)"
                        value=""
                        id={part.partTitle + '-noText'}
                      />
                    )}
                    {part.multiple
                      ? part.adviceTexts.map(({ key, value: adviceText }) => {
                          return (
                            <Checkbox
                              checked={adviceText.met}
                              label={adviceText.title}
                              value={adviceText.key}
                              key={key}
                              id={key}
                            />
                          );
                        })
                      : part.adviceTexts.map(({ key, value: adviceText }) => {
                          return (
                            <Radio
                              checked={adviceText.met}
                              label={adviceText.title}
                              value={adviceText.key}
                              key={key}
                              id={key}
                            />
                          );
                        })}
                  </RadioGroup>
                ) : (
                  <Select<string>
                    value={textOptions.find(opt => opt.value === selectedText?.value.key)}
                    onChange={event => handleChange(event.value, key, 'select')}
                    options={textOptions}
                  />
                )}
                {part.adviceTexts
                  .filter(text => text.value.met)
                  .map(text => {
                    return (
                      <ComposedAdvice
                        key={key}
                        onClick={() => {
                          copyToClipboard(text.value.text || '');
                          toast({
                            type: 'info',
                            message: 'Tekst gekopieerd',
                          });
                        }}
                      >
                        <QuoteMark icon={QuoteOpen} solid fill="green" />
                        {part.partTextTitle.length > 0 && (
                          <p>
                            <strong>{part.partTextTitle}</strong>
                          </p>
                        )}
                        {text.value.text.split('\n\n').map((paragraph, pIndex) => (
                          <p key={text.value.key + '-p-' + pIndex}>
                            {paragraph.split('\n').map((t, tIndex) => (
                              <>
                                <Small key={text.value.key + '-p-' + pIndex + '-' + tIndex}>
                                  {t}
                                </Small>
                                <br />
                              </>
                            ))}
                          </p>
                        ))}
                      </ComposedAdvice>
                    );
                  })}
              </Accordion>
            );
          })}
        </>
      )}
    </>
  );
};

export default GeneratedAdviceTexts;
