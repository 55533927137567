export const secondsToTime = (
  seconds?: number | null,
  showSeconds?: boolean,
  useSentence?: boolean,
) => {
  if (!seconds) return '';
  const minutes = Math.floor((seconds / 60) % 60);
  const hours = Math.floor((seconds / (60 * 60)) % 24);
  const days = Math.floor(seconds / (60 * 60 * 24));

  if (useSentence) {
    const sentenceChunks = {
      days: days > 0 ? `${days} dagen` : '',
      hours: hours > 0 ? `${hours} uur` : '',
      minutes: `${minutes} minuten`,
      seconds: showSeconds ? `${seconds} seconden` : '',
    };
    const sentence = Object.values(sentenceChunks).filter(chunk => chunk);
    const lastChunk = sentence.pop();
    return `${sentence.join(', ')}${sentence.length > 0 ? ' en ' : ''}${lastChunk}`;
  } else {
    return `${days > 0 ? `${days}:` : ''}${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }${showSeconds ? `:${seconds < 10 ? `0${seconds}` : seconds}` : ''}`;
  }
};

export function isImage(url: string) {
  return ['jpg', 'gif', 'jpeg', 'png'].some(ext => url.indexOf(ext) > -1);
}
