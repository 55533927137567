// Based on https://dev.to/taylorbeeston/resizing-images-client-side-with-vanilla-js-4ng2

const scaleCanvas = (canvas: HTMLCanvasElement, scale: number) => {
  const scaledCanvas = document.createElement('canvas');
  scaledCanvas.width = canvas.width * scale;
  scaledCanvas.height = canvas.height * scale;
  const ctx = scaledCanvas.getContext('2d');
  if (!ctx) throw new Error('Canvas2D not supported');
  ctx.drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);
  return scaledCanvas;
};

const imageBlobToCanvas = async (photo: Blob) => {
  const canvas = document.createElement('canvas');
  const img = document.createElement('img');

  // create img element from File object
  img.src = await new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = e => (e.target ? res(e.target.result as string) : rej('No target.result'));
    reader.readAsDataURL(photo);
  });
  await new Promise(resolve => (img.onload = resolve));

  // draw image in canvas element
  canvas.width = img.width;
  canvas.height = img.height;

  const ctx = canvas.getContext('2d');
  if (!ctx) throw new Error('Canvas2D not supported');
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  return canvas;
};

export const scaleImage = async (photo: Blob, maxSize: number, quality = 0.85) => {
  let canvas = await imageBlobToCanvas(photo);

  // The largest dimension
  const dim = canvas.width > canvas.height ? 'width' : 'height';

  while (canvas[dim] >= 2 * maxSize) {
    canvas = scaleCanvas(canvas, 0.5);
  }

  if (canvas[dim] > maxSize) {
    canvas = scaleCanvas(canvas, maxSize / canvas[dim]);
  } else {
    return photo;
  }

  const newPhoto = await new Promise<Blob>(resolve =>
    canvas.toBlob(b => resolve(b!), 'image/jpeg', quality),
  );

  // Restore original metadata
  return new File([newPhoto], (photo as File).name, photo as File);
};
