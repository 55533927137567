import React from 'react';
import styled, { css } from 'styled-components';

const width = '24';
const height = '24';
const viewBox = '0 0 24 24';

const getDimensions = () => ({
  height,
  width,
});

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`;

const Image = styled.svg`
  ${({ noStyles }) => (!noStyles ? getDimensionsCss() : null)};
`;

const defaultProps = {
  children: [
    <path
      key="key-0"
      d="M24,12.49a12.6,12.6,0,0,0-3.34-8.57,13.61,13.61,0,0,0-3.79-2.87l-.33.52a10.72,10.72,0,0,1,2.16,1.7,12.44,12.44,0,0,1,3.53,8.5c0,.46,0,1.24-.06,1.9A8.82,8.82,0,0,0,20.08,10,3,3,0,0,0,19,9.09,15.12,15.12,0,0,1,18.44,12a7.06,7.06,0,0,1,1,.92A4.54,4.54,0,0,1,20.93,16a2.11,2.11,0,0,1-.72,1.57,3.64,3.64,0,0,1-2.29.79,6.92,6.92,0,0,1-3.53-1.18,12.38,12.38,0,0,0,3.33-5.89,11.54,11.54,0,0,0,.39-3A9.93,9.93,0,0,0,16.87,3.4,6.37,6.37,0,0,0,11.25,0,10.84,10.84,0,0,0,3.53,3.53,11.65,11.65,0,0,0,0,11.84a11.86,11.86,0,0,0,.07,1.57H.65A11.21,11.21,0,0,1,4.19,5.17a13.69,13.69,0,0,1,4-2.75A8.07,8.07,0,0,0,6,6.08a5.06,5.06,0,0,0-.2,1.38,11.21,11.21,0,0,1,2.81-1,6.45,6.45,0,0,1,1.64-3.66A2.4,2.4,0,0,1,12,2a3,3,0,0,1,2.42,1.63,6.48,6.48,0,0,1,.72,3.66,11,11,0,0,0-6.8,0,10.45,10.45,0,0,0-5,2.82A7.81,7.81,0,0,0,.92,15.3a7.16,7.16,0,0,0,2.35,5.1A12.31,12.31,0,0,0,12,23.87,12.44,12.44,0,0,0,19,21.65l-.13-.4a10.89,10.89,0,0,1-5.36,1.38,12.78,12.78,0,0,1-7.92-2.55,6.31,6.31,0,0,0,1.9.32,8.54,8.54,0,0,0,3.6-.78,13.28,13.28,0,0,1-2.29-2,8.11,8.11,0,0,1-2.75.59,3.32,3.32,0,0,1-2.42-.85,2.11,2.11,0,0,1-.59-1.51A4.26,4.26,0,0,1,4.51,13a5.77,5.77,0,0,1,1.9-1.38,13.26,13.26,0,0,0,3.4,6,10.9,10.9,0,0,0,7.26,3.27A6.55,6.55,0,0,0,21.78,19,10.67,10.67,0,0,0,24,12.49m-9.09-2a12.09,12.09,0,0,1-3,4.84,11.19,11.19,0,0,1-2.61-5,9.9,9.9,0,0,1,2.35-.26,13.72,13.72,0,0,1,3.27.46"
    />,
  ],
  viewBox,
};

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'TriodosSymbol',
});
