import React from 'react';
import { Solution } from '@energiebespaarders/constants';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { Smaller } from '@energiebespaarders/symbols/helpers';

// From https://docs.google.com/spreadsheets/d/1QpQ6yLMvnKEVBvVPIt9pWID9D2oyZbgnzGmXHmlxpLU/edit#gid=0
// Needs to be occasionally updated, see step 2 on https://www.rvo.nl/subsidies-financiering/isde/woningeigenaren/stappenplan-woningeigenaren
export const isdeDataDate = 'Februari 2023';
import insulationData from './insulation.json';
import glazingData from './glazing.json';
import styled from 'styled-components';

type DropdownOptionWithSearchValue = DropdownOption & { searchValue: string };

const StyledOption = styled.div`
  line-height: 90%;
`;

function constructInsulationOptions(items: typeof insulationData) {
  return items.map(
    (item): DropdownOptionWithSearchValue => ({
      value: item.Meldcode,
      searchValue: `${item.Meldcode}: ${item['Producttype of productnaam']}, ${item.Merk} (${item.Materiaalsoort}, ${item.Categorie})`,
      label: (
        <StyledOption>
          {item.Meldcode}: {item['Producttype of productnaam']}
          <br />
          <Smaller>
            {item.Merk}, {item.Materiaalsoort}, {item.Categorie}
          </Smaller>
        </StyledOption>
      ),
    }),
  );
}

const glazingOptions = glazingData.map(
  (item): DropdownOptionWithSearchValue => ({
    value: item.Meldcode,
    searchValue: `${item.Meldcode}: ${item['Model/type']}, ${item.Merk}`,
    label: (
      <StyledOption>
        {item.Meldcode}: {item['Model/type']}
        <br /> <Smaller>{item.Merk}</Smaller>
      </StyledOption>
    ),
  }),
);

export function isdeProductOptions(solution?: Solution): DropdownOptionWithSearchValue[] {
  if (!solution) return [...constructInsulationOptions(insulationData), ...glazingOptions];

  if (solution === 'windowGlazing') return glazingOptions;

  const solutionToISDECategory: Partial<Record<Solution, string>> = {
    wallInsulation: 'Binnen- of buitengevelisolatie',
    floorInsulation: 'Vloerisolatie',
    crawlspaceInsulation: 'Bodemisolatie',
    innerRoofInsulation: 'Dakisolatie',
    atticFloorInsulation: 'Zolder- of vlieringvloer',
    // TODO: could also integrate the heatpump ISDE codes https://www.rvo.nl/subsidies-financiering/isde/woningeigenaren/warmtepomp
    // 'centralHeatingBoiler':
    // 'electricHeatPump':
    // 'hybridHeatPump':
  };

  if (solution in solutionToISDECategory) {
    return constructInsulationOptions(
      insulationData.filter(v => v.Categorie === solutionToISDECategory[solution]),
    );
  }

  return [];
}
