import dayjs from 'dayjs';
import { delimit } from './delimit';

export function formatDataValue(value: any, unit?: string, isOptional?: boolean) {
  if (value === 0) return 0;
  if (typeof value === 'number' && !isNaN(value)) {
    if (value > new Date('2000-01-01').getTime()) {
      return dayjs(value).format('DD MMMM YYYY');
    }
    if (unit) {
      if (value > 999) {
        const roundedValue = Math.round(value * 100) / 100;
        return delimit(roundedValue, 0);
      }
      return delimit(value, value % 1 !== 0 ? 2 : 0);
    }
    return value;
  }
  if (Array.isArray(value)) {
    if (value.length > 0) return value.join(', ');
    return isOptional ? '-' : '?';
  }
  if (value === true) return 'Ja';
  if (value === false) return 'Nee';
  if (value) {
    if (value instanceof Date) {
      const date = dayjs(value);
      // Fix for issue with phone numbers: Those are valid dates, but year is ~600
      if (date.year() >= 1800 && date.year() < 2100) {
        return date.format('DD MMMM YYYY');
      }
    }
    return value;
  }
  if (value === null || value === undefined || isNaN(value)) return '?';
  return isOptional ? '-' : '?';
}

export default formatDataValue;
