import { useMutation, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { Modal, Placeholder, Textarea } from '@energiebespaarders/symbols';
import useToaster from '../../hooks/useToaster';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { IntakeTopic, INTAKE_TOPICS_NL } from '@energiebespaarders/constants';
import { DataPointObj } from '@energiebespaarders/data-maps';
import { INTAKE_NOTES_BY_HOUSE, SAVE_EDITOR } from '../../queries/intake';
import {
  saveIntakeEditor,
  saveIntakeEditorVariables,
} from '../../types/generated/saveIntakeEditor';
import {
  intakeNotesByHouse,
  intakeNotesByHouseVariables,
} from '../../types/generated/intakeNotesByHouse';

interface NotesModalProps {
  closeModal: () => void;
  dataItem?: DataPointObj;
  houseId: string;
  isOpen: boolean;
  topic: IntakeTopic;
  mobile: boolean;
}

type Notes = { [x: string]: string };

const NotesModal = ({ closeModal, dataItem, houseId, isOpen, topic }: NotesModalProps) => {
  const [notes, setNotes] = useState<Notes>({
    general: '',
    pvSystem: '',
    walls: '',
    floor: '',
    roof: '',
    windows: '',
    heating: '',
  });
  const mobile = useIsMobile();
  const toast = useToaster();
  const topicExcludingPhotos = useMemo(() => {
    return (
      (dataItem?.topics?.find(t => t !== IntakeTopic.Photos) as Exclude<IntakeTopic, 'photos'>) ||
      topic
    );
  }, [topic, dataItem?.topics]);

  const { loading: notesLoading, error: notesError, data: queriedNotes } = useQuery<
    intakeNotesByHouse,
    intakeNotesByHouseVariables
  >(INTAKE_NOTES_BY_HOUSE, {
    fetchPolicy: 'network-only',
    variables: { houseId },
    onCompleted: queryData => {
      if (queryData?.intakeByHouse?.notes) {
        const topicKey = topicExcludingPhotos as keyof typeof queryData.intakeByHouse.notes;
        const topicNotes = queryData.intakeByHouse.notes[topicKey];
        if (dataItem && (!topicNotes || topicNotes.indexOf(dataItem.label) < 0)) {
          // pre-fill the question label in the notes field
          setNotes({
            ...queryData.intakeByHouse.notes,
            [topicKey]: `${
              queryData.intakeByHouse.notes[topicKey]
                ? queryData.intakeByHouse.notes[topicKey] + '\n'
                : ''
            }${dataItem.label}: `,
          } as Notes);
        } else {
          setNotes((queryData.intakeByHouse.notes as unknown) as Notes);
        }
      }
    },
  });
  const [saveEditor] = useMutation<saveIntakeEditor, saveIntakeEditorVariables>(SAVE_EDITOR, {
    onCompleted: data => {
      if (data.saveIntakeEditor) {
        setTimeout(
          () =>
            toast({
              type: 'success',
              message: `Notities opgeslagen voor ${INTAKE_TOPICS_NL[topic]}`,
            }),
          300,
        );
      }
    },
    update: (cache, res) => {
      if (!res.data?.saveIntakeEditor) return;
      cache.writeQuery<intakeNotesByHouse, intakeNotesByHouseVariables>({
        query: INTAKE_NOTES_BY_HOUSE,
        variables: { houseId },
        data: {
          intakeByHouse: {
            ...res.data.saveIntakeEditor,
          },
        },
      });
    },
  });
  return (
    <Modal
      key={`notesModal-${topicExcludingPhotos}`}
      isOpen={isOpen}
      onRequestClose={closeModal}
      title={`Notities voor ${INTAKE_TOPICS_NL[topicExcludingPhotos]}`}
      mobile={mobile}
      timeout={mobile ? 250 : undefined}
      buttons={[
        {
          bgColor: 'green',
          inverse: true,
          label: 'Opslaan en sluiten',
          onClick: closeModal,
          m: 0,
        },
      ]}
    >
      {notesLoading || notesError ? (
        <Placeholder height="50vh" error={notesError} />
      ) : (
        <Textarea
          autoFocus
          value={notes[topicExcludingPhotos] || ''}
          onChange={e => setNotes({ ...notes, [topicExcludingPhotos]: e.target.value })}
          label="Notitie"
          onBlur={e => {
            if (queriedNotes?.intakeByHouse) {
              // Don't save if nothing changed
              const topicKey = topicExcludingPhotos as keyof typeof queriedNotes.intakeByHouse.notes;
              if (e.target.value === queriedNotes.intakeByHouse.notes[topicKey]) {
                return;
              }
            }
            saveEditor({
              variables: {
                content: e.target.value,
                topic: topicExcludingPhotos,
                houseId,
              },
            });
          }}
          placeholder={`Typ hier een notitie voor ${INTAKE_TOPICS_NL[
            topicExcludingPhotos
          ].toLowerCase()}`}
          resize="vertical"
          rows={8}
        />
      )}
    </Modal>
  );
};

export default NotesModal;
