import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { installerRegions_installers } from '../../types/generated/installerRegions';
import styled from 'styled-components';

export type MapInstaller = Pick<installerRegions_installers, 'id' | 'name' | 'installationRegions'>;
export type ZipRegion = { from: number; to: number };

export const MapColors = ['red', 'gold', 'green'].map(themify);

export const MapLegend = styled.div`
  font-family: Arial, sans-serif;
  background: #fff;
  padding: 10px;
  margin: 10px;
  border: 3px solid #000;

  /* hidden at first */
  display: none;
`;

// Note about how I got PC3 data:
// - Downloaded the shape file from https://hub.arcgis.com/datasets/esrinl-content::postcodevlakken-pc-3
//   (also has PC2, PC4, etc.)
// - Then, converted into GeoJSON and simplified it (24MB zip to 2MB json) using https://mapshaper.org/
//   with default simplification at 1%
// - Tried to put it in a bucket, but had CORS issues, so it's in /public for now
//   const PC3_URL = 'https://storage.googleapis.com/storage/v1/deb-documents/data/PC3.json';
export const PC3_URL = '/PC3.json';

export const MapContainerStyle = {
  width: '100%',
  height: '85vh',
  minHeight: '500px',
};

export const MapCenter = {
  lat: 52.092876,
  lng: 5.10448,
};

export function initializeMapWithPC3Regions(map: google.maps.Map, pc3Data: any) {
  map.data.addGeoJson(pc3Data);
}

export function findRegionsForPC3(pc3Code: string, regions: ZipRegion[]) {
  const codeNumStart = Number(pc3Code + '0');
  const codeNumEnd = Number(pc3Code + '9');
  return regions.filter(region => region.from! <= codeNumStart && region.to! >= codeNumEnd);
}

export function isPC3InZipRegions(pc3Code: string, regions: ZipRegion[]) {
  return findRegionsForPC3(pc3Code, regions).length > 0;
}

export function findRegionalEntities(pc3Code: string, installers: readonly MapInstaller[]) {
  const codeNumStart = Number(pc3Code + '0');
  const codeNumEnd = Number(pc3Code + '9');
  // todo: quicker to do a lookup: pre-compute a dict of PC3 -> installer
  return installers.filter(inst =>
    inst?.installationRegions.some(
      region => region.from! <= codeNumStart && region.to! >= codeNumEnd,
    ),
  );
}

function createInfoWindow(listBody: string, pc3Code: string) {
  return `<div style="max-height: 200px"><h3>${pc3Code}0-${pc3Code}9</h3> <ul> ${listBody} </ul></div>`;
}

/**
 * Creates HTML content for the info window shown on top of a PC3 region
 * @param installerRegionData All installer's region data
 * @param selectedInstallers The current set of selected installers
 * @param pc3Code The active PC3 code
 * @returns HTML content
 */
export function constructMultiInstallerPC3InfoWindowContent(
  installerRegionData: MapInstaller[],
  selectedInstallers: Set<string>,
  pc3Code: string,
): string {
  let content = '';
  const createItem = (i: MapInstaller) => `<li><a href="/installer/${i.id}">${i.name}</a></li>`;

  if (installerRegionData?.length) {
    let regionalInstallers = findRegionalEntities(pc3Code, installerRegionData);
    if (selectedInstallers.size > 0) {
      const selectedRegInst = regionalInstallers.filter(inst => selectedInstallers.has(inst.id));
      content += selectedRegInst.map(i => createItem(i)).join('\n');
      if (!selectedRegInst.length) content += '<li>Geen van de gekozen installateurs</li>\n';
      content += '</ul> <p>Overige installateurs:</p><ul>';
      regionalInstallers = regionalInstallers.filter(inst => !selectedInstallers.has(inst.id));
    }
    content += regionalInstallers.map(i => createItem(i)).join('\n');
  }

  return createInfoWindow(content, pc3Code);
}

export function pc3ToZipRegion(pc3Code: string): ZipRegion {
  return { from: Number(pc3Code + '0'), to: Number(pc3Code + '9') };
}

/** Given a PC4 region, remove a PC3 section from it */
export function splitRegion(region: ZipRegion, pc3Code: string): ZipRegion[] {
  const pc3Region = pc3ToZipRegion(pc3Code);

  if (region.from === pc3Region.from && region.to === pc3Region.to) {
    return [];
  }
  if (region.from === pc3Region.from) {
    return [{ from: pc3Region.to + 1, to: region.to }];
  }
  if (region.to === pc3Region.to) {
    return [{ from: region.from, to: pc3Region.from - 1 }];
  }
  // Else, the PC3 region is in somewhere in the middle of the other region
  return [
    {
      from: region.from,
      to: pc3Region.from - 1,
    },
    { from: pc3Region.to + 1, to: region.to },
  ];
}

/** Given a list of PC4 regions (from and to values) and a PC3 code,
 * add the PC3 area to the PC4 regions and merge resulting regions if they line up */
export function addAndMergePC3WithRegions(regions: ZipRegion[], pc3Code: string): ZipRegion[] {
  // See if it can be merged with an existing region
  // could be up to two: one before and one after
  const pc3Region = pc3ToZipRegion(pc3Code);

  const preRegion = regions.find(r => r.to + 1 === pc3Region.from);
  const postRegion = regions.find(r => r.from - 1 === pc3Region.to);

  let newRegions: ZipRegion[];
  if (preRegion && postRegion) {
    newRegions = regions.filter(r => r !== preRegion && r !== postRegion);
    newRegions.push({ from: preRegion.from, to: postRegion.to });
  } else if (preRegion) {
    newRegions = regions.filter(r => r !== preRegion);
    newRegions.push({ from: preRegion.from, to: pc3Region.to });
  } else if (postRegion) {
    newRegions = regions.filter(r => r !== postRegion);
    newRegions.push({ from: pc3Region.from, to: postRegion.to });
  } else {
    newRegions = [...regions, pc3Region];
  }

  return newRegions;
}
