import { gql, useMutation } from '@apollo/client';
import { useForm } from '@energiebespaarders/hooks';
import { isMissing } from '@energiebespaarders/hooks/useForm';
import { Modal, Select, Textarea } from '@energiebespaarders/symbols';
import { Red } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import React from 'react';
import useToaster from '../../hooks/useToaster';
import {
  setDealNoLongerActiveStatus,
  setDealNoLongerActiveStatusVariables,
} from '../../types/generated/setDealNoLongerActiveStatus';
import { DealStatus } from '../../types/graphql-global-types';

export const SET_DEAL_LOST = gql`
  mutation setDealNoLongerActiveStatus($dealId: ID!, $details: DealLostDetailsInput) {
    setDealNoLongerActiveStatus(dealId: $dealId, details: $details) {
      id
      noLongerActive {
        lostDate
        reason
        comment
        responsible
      }
      # For the cache:
      status
    }
  }
`;

type Responsible = 'Installateur' | 'Adviseur' | 'Klant' | 'Anders';

const options: { reason: string; responsible: Responsible }[] = [
  { reason: 'Budgetbeperkingen - Wil niet financieren', responsible: 'Klant' },
  { reason: 'Concurrentie - Prijs', responsible: 'Klant' },
  { reason: 'Concurrentie - Ander product', responsible: 'Klant' },
  { reason: 'Concurrentie - Anders', responsible: 'Klant' },
  { reason: 'Doorschuiven voor langere termijn', responsible: 'Klant' },
  { reason: 'Geen akkoord financiering', responsible: 'Klant' },
  { reason: 'Geen financiering/vergunning verkregen', responsible: 'Klant' },
  { reason: 'Akkoord met een vergelijkbaar product', responsible: 'Klant' },
  { reason: 'Reden niet duidelijk - Niet meer te bereiken', responsible: 'Klant' },
  { reason: 'Niet tevreden met dienstverlening', responsible: 'Anders' }, // maybe add DEB as responsible?
  { reason: 'Prijs - Terugverdientijd te lang', responsible: 'Klant' },
  { reason: 'Prijs - Vindt de investering te hoog', responsible: 'Klant' },
  { reason: 'Installatie liet te lang op zich wachten', responsible: 'Anders' }, // idem
  { reason: 'Niet uitvoerbaar', responsible: 'Adviseur' },
  { reason: 'Anders', responsible: 'Anders' },
];

const reasonOptions = options.map(option => {
  return { value: option.reason, label: option.reason };
});

type SingleDealProps = {
  deal: { id: string; reference: string; status: DealStatus };
  deals?: never;
  closeModal: () => void;
};

type MultiDealProps = {
  deal?: never;
  deals: readonly { id: string; reference: string; status: DealStatus }[];
  closeModal: () => void;
};

type Props = SingleDealProps | MultiDealProps;

const MarkDealLostModal: React.FC<Props> = props => {
  const { closeModal, deal } = props;
  const deals = (props.deals || [deal]) as { id: string; reference: string; status: DealStatus }[];
  const isMobile = useIsMobile();
  const toast = useToaster();

  const [setDealAsLost, { loading }] = useMutation<
    setDealNoLongerActiveStatus,
    setDealNoLongerActiveStatusVariables
  >(SET_DEAL_LOST, {
    onCompleted: () => {
      toast({
        type: 'success',
        message: 'Deal succesvol aangepast',
      });
      closeModal();
    },
    onError: e => {
      toast({
        type: 'error',
        message: `Er is iets misgegaan! ${e.message}`,
      });
    },
  });

  const { formState, handleChange, submitForm } = useForm<{
    reason: string;
    comment: string;
  }>({
    blockEnterToSubmit: true, // TODO: enterToSubmit in useForm is broken: enters in textarea also submit
    initialValues: {
      reason: '',
      comment: '',
    },
    handleSubmit: values => {
      return Promise.all(
        deals
          .filter(deal => deal.status !== DealStatus.accepted)
          .map(d =>
            setDealAsLost({
              variables: {
                dealId: d.id,
                details: {
                  lostDate: new Date(),
                  reason: values.reason,
                  comment: values.comment,
                  responsible:
                    options.find(option => option.reason === values.reason)?.responsible ||
                    'Anders',
                },
              },
            }),
          ),
      );
    },
    validate: (values, errors) => {
      if (isMissing(values.reason)) errors.reason = 'Verplicht';
      if (values.reason === 'Anders' && isMissing(values.comment))
        errors.comment =
          'Deze vraag is verplicht omdat we zoveel mogelijk willen leren over de reden waarom deals verloren gaan. Vul hem a.u.b. goed in.';
      return errors;
    },
  });

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen
      mobile={isMobile}
      title="Markeer deal als verloren"
      size="sm"
      buttons={[
        {
          label: 'Markeer deal als verloren',
          bgColor: 'green',
          disabled: loading,
          onClick: submitForm as () => void,
        },
      ]}
    >
      <p>{deals.length === 1 ? deals[0].reference : 'Alle deals'}</p>

      <Select
        label="Reden"
        options={reasonOptions}
        zIndex={2}
        onChange={(e: { value: string }) => handleChange({ reason: e.value })}
        clearable={false}
        value={reasonOptions.find(option => option.value === formState.reason.value)}
        error={formState.reason.error}
        touched={formState.reason.touched}
      />
      <Textarea
        label="Belangrijk: toelichting"
        rows={5}
        onChange={e => handleChange({ comment: e.target.value })}
        placeholder="Licht toe waarom dit de reden is dat deze deal is verloren"
      />
      {formState.comment.error && <Red>{formState.comment.error}</Red>}
    </Modal>
  );
};

export default MarkDealLostModal;
