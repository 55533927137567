import React from 'react';
import { Placeholder } from '@energiebespaarders/symbols';
import { useQuery } from '@apollo/client';

import { GET_ALL_QUOTE_FILES } from '../queries/files';
import ImagePicker from './ImagePicker';

interface IQuoteImagePickerProps {
  houseId: string;
  selectedFile: any;
  setSelectedFile: (file: any) => void;
  tags: string[];
  exclusiveMatch: boolean;
}

interface IGetAllQueryFilesProps {
  house: {
    id: string;
    quotes: [
      {
        id: string;
        files: any[];
      },
    ];
  };
}

interface IGetAllQueryFilesVars {
  houseId: string;
}

const QuotesImagePicker = ({
  houseId,
  tags,
  selectedFile,
  setSelectedFile,
  exclusiveMatch = true,
}: IQuoteImagePickerProps) => {
  const { loading, data, error } = useQuery<IGetAllQueryFilesProps, IGetAllQueryFilesVars>(
    GET_ALL_QUOTE_FILES,
    { variables: { houseId } },
  );

  if (loading || error || !data) return <Placeholder error={error} />;

  const files = data.house.quotes.flatMap(quote => quote.files);

  return (
    <ImagePicker
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      exclusiveMatch={exclusiveMatch}
      files={files}
      gutter={1}
      size="ss"
      tags={tags}
      thumbSize={100}
    />
  );
};

export default QuotesImagePicker;
