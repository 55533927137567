import { Box, Flex, Icon, Input, Tooltip } from '@energiebespaarders/symbols';
import { ArrowUpRight } from '@energiebespaarders/symbols/icons/solid';
import React, { ChangeEvent } from 'react';

function extractYouTubeID(url: string): string | null {
  const youTubeRegex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
  const match = url.match(youTubeRegex);
  return match ? match[1] : null;
}

/** Not official, but practically true https://webapps.stackexchange.com/questions/54443/format-for-id-of-youtube-video */
const YOUTUBE_ID_LENGTH = 11;

type YouTubeInputProps = {
  onChange: (value: string) => void;
  value?: string;
  touched?: boolean;
  error?: string;
};

const YouTubeInput: React.FC<YouTubeInputProps> = ({ value, onChange, ...props }) => {
  return (
    <Input
      name={'youTubeId'}
      label={
        <Flex justifyContent="space-between">
          <Box mr={2}>
            <Tooltip content="Zet hier de ID of URL van een YouTube video">
              <>YouTube ID</>
            </Tooltip>
          </Box>
          <Box>
            {value && (
              <a
                href={`https://www.youtube.com/watch?v=${value}`}
                target="blank"
                rel="noopener noreferrer"
              >
                Bekijk <Icon icon={ArrowUpRight} solid />
              </a>
            )}
          </Box>
        </Flex>
      }
      value={value}
      placeholder={'Plak hier een YouTube ID of URL'}
      onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (!value) {
          onChange('');
        } else if (value.length === YOUTUBE_ID_LENGTH) {
          onChange(value);
        } else {
          // Try to extract the ID from the URL
          const extractedId = extractYouTubeID(value);
          if (extractedId) {
            onChange(extractedId);
          } else {
            alert(
              'Geen geldige YouTube ID. Je kunt het makkelijkst een volledige YouTube URL hier in plakken',
            );
          }
        }
      }}
      {...props}
    />
  );
};

export default YouTubeInput;
