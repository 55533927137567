import { gql } from '@apollo/client';
import FileFragment from './File';

export const CompanyAddressFragment = gql`
  fragment CompanyAddress on Company {
    address {
      street
      number
      suffix
      zip
      city
    }
  }
`;

export const InstallerFragment = gql`
  fragment Installer on Installer {
    workRadius
    size
    rating
    supplier {
      id
      name
    }
  }
`;

export const SupplierFragment = gql`
  fragment Supplier on Supplier {
    suppliableProducts {
      id
      title
      priceUnit
    }
    prices {
      id
      product {
        id
      }
      purchasePrice
      retailPrice
      minimumInstallerRate
      supplierId
      availability
      grippId
    }
    installer {
      id
      name
    }
    grippId
  }
`;

export const CompanyFragment = gql`
  ${CompanyAddressFragment}
  ${InstallerFragment}
  ${SupplierFragment}
  ${FileFragment}
  fragment Company on Company {
    id
    name
    ...CompanyAddress
    email
    phone
    website
    solutions
    vat
    coc
    legalName
    files {
      ...File
    }
    ... on Installer {
      ...Installer
      inspectionEmail
      installationEmail
      planningPeriod
      customerNotificationPeriod
      installationRegions {
        to
        from
      }
      pipedriveId
    }
    ... on Supplier {
      ...Supplier
    }
  }
`;

export default CompanyFragment;
