import React from 'react';
import { Box, Card, Icon } from '@energiebespaarders/symbols';
import InstallerStatusIndicators from './InstallerStatusIndicator';

export const InstallerStatusInfo = () => (
  <Box width={1}>
    <Card title="Installateur status info" mx={4}>
      {Object.values(InstallerStatusIndicators).map(indicator => (
        <div key={indicator.label}>
          <h4>
            <Icon icon={indicator.icon} solid fill={indicator.color} /> {indicator.label} (
            {indicator.description})
          </h4>
          <ul>
            {indicator.details?.map((line, i) => (
              <li key={i}>{line}</li>
            ))}
          </ul>
        </div>
      ))}
    </Card>
  </Box>
);

export default InstallerStatusInfo;
