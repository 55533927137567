import { gql, useMutation } from '@apollo/client';
import { Accordion, Box, Button, Card, Separator, Toast } from '@energiebespaarders/symbols';
import { Clipboard, ClipboardPencil } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React from 'react';
import useToaster from '../../../hooks/useToaster';
import {
  getEmailsEventsByEntityIdAndTemplate,
  getEmailsEventsByEntityIdAndTemplateVariables,
} from '../../../types/generated/getEmailsEventsByEntityIdAndTemplate';
import {
  sendPtcResultsChaser,
  sendPtcResultsChaserVariables,
} from '../../../types/generated/sendPtcResultsChaser';
import { MailjetTemplateDescriptor } from '../../../types/graphql-global-types';
import { GET_EMAILS_BY_ENTITY_ID_AND_TEMPLATE } from '../queries';
import ChaseInstaller from './ChaseInstaller';

export const CHASE_INSTALLER_PTC_RESULTS = gql`
  mutation sendPtcResultsChaser($jobId: ID!) {
    sendPtcResultsChaser(jobId: $jobId)
  }
`;

interface PtcSignoffAccordionProps {
  ptcJob: {
    id: string;
    quoteId: string;
    completed: Date | null;
    installerSignoff: {
      markedAsComplete: {
        date: Date;
      } | null;
    } | null;
  };
}

const PtcSignoffAccordion: React.FC<PtcSignoffAccordionProps> = ({ ptcJob }) => {
  const toast = useToaster();

  const [chaseInstaller, { loading: chaseInstallerLoading }] = useMutation<
    sendPtcResultsChaser,
    sendPtcResultsChaserVariables
  >(CHASE_INSTALLER_PTC_RESULTS, {
    variables: { jobId: ptcJob?.id || '' },
    update: cache => {
      const cacheData = cache.readQuery<
        getEmailsEventsByEntityIdAndTemplate,
        getEmailsEventsByEntityIdAndTemplateVariables
      >({
        query: GET_EMAILS_BY_ENTITY_ID_AND_TEMPLATE,
        variables: {
          entityId: ptcJob?.id || '',
          template: MailjetTemplateDescriptor.PTCResultsReminder,
        },
      });
      if (cacheData?.getEmailsEventsByEntityIdAndTemplate) {
        const newData = cacheData.getEmailsEventsByEntityIdAndTemplate.concat([
          {
            id: `newEmail${new Date().getTime()}`,
            template: MailjetTemplateDescriptor.PTCResultsReminder,
            sent: new Date(),
            open: null,
            bounce: null,
            blocked: null,
            __typename: 'EmailEventHistory',
          },
        ]);
        cache.writeQuery<
          getEmailsEventsByEntityIdAndTemplate,
          getEmailsEventsByEntityIdAndTemplateVariables
        >({
          query: GET_EMAILS_BY_ENTITY_ID_AND_TEMPLATE,
          variables: {
            entityId: ptcJob?.id || '',
            template: MailjetTemplateDescriptor.PTCResultsReminder,
          },
          data: { getEmailsEventsByEntityIdAndTemplate: newData },
        });
      }
    },
    onCompleted: () => toast({ type: 'success', message: 'Herinnering verstuurd' }),
    onError: e => toast({ type: 'error', message: e.message }),
  });

  return (
    <Card animation="none" shadow="none" p={0} borderColor="grayLight" borderRadius="3px">
      <Accordion
        contentBorderColor="grayLight"
        icon={ClipboardPencil}
        iconColor={ptcJob.completed ? 'blue' : 'gray'}
        title={'Schouwresultaten'}
        titleSize={5}
        zIndex={110}
      >
        {ptcJob.completed && (
          <>
            <Toast
              type={'success'}
              message={`Schouw uitgevoerd op ${dayjs(ptcJob.completed).format('DD/MM/YYYY')}`}
              width="auto"
            />
          </>
        )}

        {ptcJob.installerSignoff ? (
          <p>
            {!ptcJob.installerSignoff.markedAsComplete && (
              <p>De installatiepartner heeft de oplevering gestart maar het is niet voltooid.</p>
            )}
            <a href={`/schouw-resultaten/${ptcJob.id}`} target="_blank" rel="noreferrer">
              Klik hier
            </a>{' '}
            om naar de schouwresultaten te gaan
          </p>
        ) : (
          <>
            <Box>
              <a href={`/opdracht/${ptcJob.quoteId}/schouw`} target="_blank" rel="noreferrer">
                <Button
                  iconStart={Clipboard}
                  fluid
                  title="Schouwresultaten uploaden"
                  label="Schouwresultaten uploaden"
                />
              </a>
              <p>
                Schouwresultaten ontvangen per mail? Ga naar de schouwpagina om de resultaten te
                uploaden
              </p>
            </Box>
            <Separator my={4} />
            <Box>
              {/* TODO:  Figure out if this can be removed */}
              <p>
                <ChaseInstaller
                  template={MailjetTemplateDescriptor.PTCResultsReminder}
                  jobId={ptcJob.id}
                  sendEmail={chaseInstaller}
                  emailSending={chaseInstallerLoading}
                  message={
                    'Nog geen schouwresultaten van de installateur? Je kan hier een herinnering sturen'
                  }
                  buttonLabel="Herinnering sturen voor schouwresultaten"
                />
              </p>
            </Box>
          </>
        )}
      </Accordion>
    </Card>
  );
};

export default PtcSignoffAccordion;
