import { ApolloError } from '@apollo/client';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Toaster from '../components/Toaster';

export type ToastType = 'success' | 'error' | 'info' | 'warning';
export interface IToast {
  /** The text to show in the toast notification. Will auto-collapse when it exceeds certain length.
   * Try to keep it short and concise, and keep in mind that the duration needs to cover reading and understanding it. */
  message: string;
  /** Type of notification. Different colors and icons to match. Defaults to `info`. */
  type?: ToastType;
  /** How long to show the toast message for in ms. Defaults to `5000`. Setting this to `0` disables a timed self-destruct. */
  duration?: number;
  /** Pass the Apollo error to handle this automatically from the Toast component. */
  error?: ApolloError;
  /** Unique ID per toast, set automatically. */
  uid?: number;
  /** Function passed to Toast component to remove itself from the array of toasts. */
  selfDestruct?: () => void;
}

export type PopToast = (toast: IToast | IToast[]) => void;

export const ToasterContext = React.createContext<PopToast>(null as any);

export const ToasterProvider: React.FC = ({ children }: any) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  const [nextUid, setNextUid] = useState(1);

  /** Using a ref to ensure the Toaster doesn't rerender every time we update the state,
   * so the array of toasts and their respective UIDs stays intact
   */
  const toastsRef = useRef(toasts);

  useEffect(() => {
    if (toastsRef.current !== toasts) {
      toastsRef.current = toasts;
    }
  }, [toasts]);

  /** IDEA: We could mark toasts as expired rather than hard delete them from the array of toasts
   * and then only render the active / unexpired toasts. This would make it easier to animate and keep track +
   * maybe even undo removing one.
   */
  const deleteToast = (uid: number) => {
    if (!setToasts) return;
    setToasts(toastsRef.current.filter(t => t.uid !== uid));
  };

  const popToast = (toast: IToast | IToast[]) => {
    if (!setToasts) return;

    /** Asynchronous state setting means that you cannot add multiple toasts synchronously because the
     * uid state does not update in time. Must therefore be able to pass array if we want to do multiple
     * toasts at once
     */
    if (Array.isArray(toast)) {
      const newToasts: IToast[] = toast.map((t, i) => ({
        ...t,
        uid: nextUid + i,
        duration: t.duration === 0 ? 0 : t.duration || 5000,
      }));
      setToasts([...toastsRef.current, ...newToasts]);
      setNextUid(nextUid + toast.length);
    } else {
      const uid = nextUid;
      const newToast: IToast = {
        ...toast,
        uid,
        duration: toast.duration === 0 ? 0 : toast.duration || 5000,
      };
      setNextUid(uid + 1);
      setToasts([...toastsRef.current, newToast]);
    }
  };

  return (
    <ToasterContext.Provider value={popToast}>
      <Toaster toasts={toasts} deleteToast={deleteToast} />
      {children}
    </ToasterContext.Provider>
  );
};

export default function useToaster(): PopToast {
  return useContext(ToasterContext);
}
