import { useQuery } from '@apollo/client';
import { Icon, Placeholder, Table, Tooltip } from '@energiebespaarders/symbols';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { CaretDown, Play } from '@energiebespaarders/symbols/icons/solid';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { delimit } from '../../lib/utils';
import getPriceAvailabilityProps from '../../lib/utils/getPriceAvailabilityProps';
import { GET_PRICES_AND_SUPPLIER } from '../../queries/products';
import {
  getPricesAndSupplier,
  getPricesAndSupplierVariables,
  getPricesAndSupplier_findPricesByProduct,
} from '../../types/generated/getPricesAndSupplier';
import { InstallerStatus } from '../../types/graphql-global-types';
import InstallerStatusIndicators from '../companies/InstallerStatusIndicator';
import styled from 'styled-components';
import { useLocalStorage } from 'usehooks-ts';

const ClickableCell = styled(Table.Cell)`
  cursor: pointer;
`;

const InstallerStatusOrder: Record<InstallerStatus, number> = {
  [InstallerStatus.active]: 0,
  [InstallerStatus.setup]: 1,
  [InstallerStatus.paused]: 2,
  [InstallerStatus.inactive]: 3,
};

const columns = ['status', 'name', 'isdeCode', 'retailPrice'] as const;
const columnLabels = [
  <Icon key="status" icon={Play} solid fill="white" />,
  'Leverancier',
  'ISDE meldcode',
  'Verkoopprijs',
] as const;

function sortPrices(
  a: getPricesAndSupplier_findPricesByProduct,
  b: getPricesAndSupplier_findPricesByProduct,
  key: typeof columns[number] = 'status',
) {
  if (key === 'status') {
    if (a.supplier.installer?.status.value === b.supplier.installer?.status.value) {
      return a.retailPrice - b.retailPrice;
    }
    const statusA = a.supplier.installer?.status.value;
    const statusB = b.supplier.installer?.status.value;
    return (
      InstallerStatusOrder[statusA || InstallerStatus.inactive] -
      InstallerStatusOrder[statusB || InstallerStatus.inactive]
    );
  } else if (key === 'name') {
    return a.supplier.name.localeCompare(b.supplier.name);
  } else if (key === 'isdeCode') {
    return a.isdeCode?.localeCompare(b?.isdeCode || '') || a.retailPrice - b.retailPrice;
  }

  return a.retailPrice - b.retailPrice;
}

const ProductPricesTable = ({ productId }: { productId: string }) => {
  const mobile = useIsMobile();
  const [sortBy, setSortBy] = useLocalStorage<typeof columns[number]>(
    'productPricesSortBy',
    'status',
  );

  const { data: pricesData, loading: pricesLoading, error: pricesError } = useQuery<
    getPricesAndSupplier,
    getPricesAndSupplierVariables
  >(GET_PRICES_AND_SUPPLIER, {
    variables: { productId },
    skip: !productId,
  });

  if (pricesLoading || pricesError) return <Placeholder error={pricesError} height="100px" />;

  const prices = pricesData?.findPricesByProduct || [];

  return (
    <Table
      fontSize={mobile ? 7 : 6}
      mobile={mobile}
      defaultPadding={mobile ? 1 : 2}
      defaultOverflow="ellipsis"
      layout={[1.5 / 20, 10.5 / 20, 4 / 20, 4 / 20]}
    >
      <Table.Row isHeader>
        {columnLabels.map((label, index) => (
          <ClickableCell
            key={`header-${index}`}
            onClick={() => setSortBy(columns[index])}
            fontWeight="bold"
          >
            {label}
            {sortBy === columns[index] ? (
              <Icon icon={CaretDown} solid ml={0} width="8px" color="white" />
            ) : null}
          </ClickableCell>
        ))}
      </Table.Row>
      {prices.length > 0 ? (
        prices
          .filter(p => !!p)
          .sort((a, b) => sortPrices(a!, b!, sortBy))
          .map((price, index) => {
            if (!price) return <></>;
            const availabilityProps = getPriceAvailabilityProps(price.availability);
            const status = price.supplier.installer?.status.value;
            const statusIndicator = status && InstallerStatusIndicators[status];

            return (
              <Table.Row key={`price-${index}`}>
                <Table.Cell>
                  {statusIndicator && status !== InstallerStatus.active ? (
                    <>
                      <Tooltip
                        content={statusIndicator.description}
                        bgColor={statusIndicator.color}
                      >
                        <Icon
                          solid
                          fill={statusIndicator.color}
                          hoverColor={statusIndicator.color}
                          icon={statusIndicator.icon}
                        />
                      </Tooltip>{' '}
                    </>
                  ) : null}
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/supplier/${price.supplier!.id}`}>{price.supplier!.name}</Link>
                </Table.Cell>
                <Table.Cell>{price.isdeCode}</Table.Cell>

                <Table.Cell textAlign="center">
                  € {delimit(price.retailPrice)}
                  <Tooltip
                    content={availabilityProps.toolTip}
                    bgColor={availabilityProps.fill}
                    maxWidth="120px"
                  >
                    <Icon solid {...availabilityProps} verticalAlign="middle" mx={1} />
                  </Tooltip>
                </Table.Cell>
              </Table.Row>
            );
          })
      ) : (
        <Table.Row>
          <Table.Cell>Geen prijzen bekend</Table.Cell>
          <Table.Cell />
          <Table.Cell />
        </Table.Row>
      )}
    </Table>
  );
};

ProductPricesTable.propTypes = {
  productId: PropTypes.string,
};

export default ProductPricesTable;
