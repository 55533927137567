import { Accordion, Card, Icon } from '@energiebespaarders/symbols';
import { Check } from '@energiebespaarders/symbols/icons/solid';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import dayjs from 'dayjs';
import React from 'react';
import { jobDomainQuote_quoteById_jobs } from '../../../types/generated/jobDomainQuote';

interface PlanningProposalsProps {
  job: jobDomainQuote_quoteById_jobs;
  planningStatusProps: {
    title: (job: jobDomainQuote_quoteById_jobs) => string;
    color: string;
    icon: any;
  };
}

const PlanningProposals: React.FC<PlanningProposalsProps> = ({ job, planningStatusProps }) => {
  return (
    <Card p={0}>
      <Accordion
        baseBgColor="white"
        bgColor="grayLighter"
        contentBorderColor="grayLight"
        titleSize={5}
        contentPadding={2}
        title={`Planning: ${planningStatusProps.title(job)}`}
        icon={planningStatusProps.icon}
        iconColor={planningStatusProps.color}
      >
        {/* Current planning status */}
        <p>
          <strong>Status:</strong>{' '}
          {job.currentPlanning?.plannedFor ? (
            `Ingepland voor ${dayjs(job.currentPlanning.plannedFor).format('dd D MMMM YYYY HH:mm')}`
          ) : (
            <i>Nog niet ingepland</i>
          )}
        </p>
        {/* List of all planning proposals */}
        <h5>Planningsvoorstellen</h5>
        {!job.proposals.length ? (
          <p>
            <i>Nog geen voorstel verstuurd door de installateur</i>
          </p>
        ) : (
          <ol>
            {job.proposals.map((p, i) => (
              <li key={i}>
                Verstuurd op {dayjs(p.created).format('DD/MM/YY')},{' '}
                {p.expirationDate < new Date() ? 'verlopen' : 'verloopt'} op{' '}
                {dayjs(p.expirationDate).format('DD/MM/YY')}
                {p.fulfilled?.key === 'noOption' && (
                  <>
                    <br />
                    <b>Geen optie geschikt voor klant</b>
                  </>
                )}
                <ul>
                  {p.options.map((option, j) => (
                    <li key={j}>
                      {dayjs(option.date).format('dd D MMMM YYYY HH:mm')}{' '}
                      {p.fulfilled?.key === option.key && (
                        <Icon
                          color={themify('blue')}
                          solid
                          icon={Check}
                          title="Optie gekozen door klant"
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ol>
        )}
      </Accordion>
    </Card>
  );
};

export default PlanningProposals;
