import { darken } from 'polished';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Spacer } from '@energiebespaarders/symbols';
import { Color } from '@energiebespaarders/symbols/helpers';
import { isDeveloping, isStaging } from '../../config';
import { padding, themify } from '../../styles/mixins';

interface LeftNavLogoProps {
  $borderColor: string;
  $color: string;
  $height: string;
  $width: string;
  $translateX: string;
}

const LeftNavLogo = styled.div<LeftNavLogoProps>`
  ${padding(2, 'left')};
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid ${x => x.$borderColor};
  overflow: hidden;
  white-space: nowrap;
  color: ${x => x.$color};
  height: ${x => x.$height};
  text-transform: uppercase;
  line-height: ${x => x.$height};
  font-weight: 600;
  width: ${x => x.$width};
  text-align: left;
  font-size: ${x => x.theme.type.scale[5]};
  font-family: ${x => x.theme.type.fontFamilySans};
  transform: ${x => `translateX(${x.$translateX})`};
  transition: all 0.2s ${x => x.theme.curves.standard};
  z-index: ${x => x.theme.z.maskedNav + 10};
`;

const LogoText = styled.div<{ $visible: boolean }>`
  display: inline-block;
  transform: ${x => `scaleX(${x.$visible ? 1 : 0})`};
  transform-origin: 0;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
  transition: all 0.2s ${x => x.theme.curves.standard};

  & a {
    color: black;
    letter-spacing: 0.025em;
  }
`;

interface NavLogoProps {
  borderColor?: string;
  color?: string;
  height: string;
  iconSize: string;
  navIcon: ReactNode;
  logoColor?: string;
  toggleLeftNav?: () => void;
  showText: boolean;
  translateX: string;
  width: string;
}

const NavLogo: React.FC<NavLogoProps> = ({
  borderColor = 'grayLight',
  color = 'grayBlack',
  height,
  iconSize,
  navIcon,
  logoColor = isDeveloping ? 'purple' : isStaging ? 'orange' : 'green',
  toggleLeftNav,
  showText,
  translateX,
  width,
}) => (
  <LeftNavLogo
    id="leftNavLogo"
    $color={themify(color)}
    $borderColor={themify(borderColor)}
    $height={height}
    $translateX={translateX}
    $width={width}
  >
    <Icon
      icon={navIcon}
      fill={themify(logoColor)}
      hoverColor={darken(0.1, themify(logoColor))}
      onClick={toggleLeftNav}
      solid
      inline
      height={iconSize}
      width={iconSize}
    />
    <LogoText $visible={showText}>
      <Spacer amount={2} />
      <Link to="/" title="Terug naar het startscherm">
        Energiebeheerders
      </Link>
      {isDeveloping && (
        <Color c={logoColor}>
          <sup>DEV</sup>
        </Color>
      )}
      {isStaging && (
        <Color c={logoColor}>
          <sup>STAGING</sup>
        </Color>
      )}
    </LogoText>
  </LeftNavLogo>
);

export default NavLogo;
