import {
  ComfortDeterminants,
  getComfortStarsPerSolution,
  Solution,
} from '@energiebespaarders/constants';
import { Icon } from '@energiebespaarders/symbols';
import { Star as StarStroked } from '@energiebespaarders/symbols/icons/line';
import { Star } from '@energiebespaarders/symbols/icons/solid';
import React from 'react';

export function renderComfortStars(
  solution: Solution,
  comfortProps: ComfortDeterminants,
  comfortScoreDelta: number,
) {
  const comfortStars = getComfortStarsPerSolution(solution, comfortProps, comfortScoreDelta);
  const noComfortSolution = [
    Solution.PvSystem,
    Solution.HybridHeatPump,
    Solution.ElectricHeatPump,
  ].some(s => s === solution);

  if (!comfortStars || noComfortSolution) return <>n.v.t.</>;

  const { currStars, starsToAdd, leftoverStars } = comfortStars;
  return solution === Solution.PvSystem ||
    solution === Solution.HybridHeatPump ||
    solution === Solution.ElectricHeatPump ? (
    <>n.v.t.</>
  ) : (
    <>
      {[...Array(currStars)].map((_, i) => (
        <Icon key={`currComf-${i}`} icon={Star} fill="grayDark" />
      ))}
      {starsToAdd > 0 &&
        [...Array(starsToAdd)].map((_, i) => (
          <Icon key={`deltaComf-${i}`} icon={Star} fill="orange" />
        ))}
      {leftoverStars > 0 &&
        [...Array(Math.min(4, Math.round(leftoverStars)))].map((_, i) => (
          <Icon key={`leftComf-${i}`} icon={StarStroked} stroke="orange" />
        ))}
    </>
  );
}
