export const cancellationReasons = [
  { value: '', label: 'Kies reden', disabled: true },
  { value: 'quoteMutated', label: 'Andere/nieuwe offerte' },
  { value: 'partnerChange', label: 'Partner gewisseld' },
  { value: 'tooSlow', label: 'Afhandeling duurde te lang' },
  { value: 'customerCancelled', label: 'Klant heeft afgezien' },
  { value: 'infeasible', label: 'Opdracht bleek onmogelijk' },
  { value: 'outsideOfServiceArea', label: 'Opdracht is buiten werkgebied' },
  { value: 'tooExpensive', label: 'Opdracht levert te weinig op' },
  { value: 'unsatisfied', label: 'Partner is ontevreden met ons' },
  { value: 'cancelledQuote', label: 'Offerte geannuleerd' },
  { value: 'other', label: 'Overig, zie commentaar' },
];
