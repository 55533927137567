import { gql, useMutation } from '@apollo/client';
import { Box, Button, Flex, Image, Input, Modal, Uploader } from '@energiebespaarders/symbols';
import React, { useState } from 'react';
import { fileUrl } from '../../config';
import useToaster from '../../hooks/useToaster';
import { getBrands_brands } from '../../types/generated/getBrands';
import { updateBrand } from '../../types/generated/updateBrand';
import { updateBrandLogo, updateBrandLogoVariables } from '../../types/generated/updateBrandLogo';
import PageLoading from '../PageLoading';
import { UPDATE_BRAND_LOGO } from './AddBrandModal';

interface Props {
  open: boolean;
  handleClose: () => void;
  refetchBrands: () => void;
  brand: getBrands_brands;
}

const UPDATE_BRAND = gql`
  mutation updateBrand($id: ID!, $brand: BrandInput!) {
    updateBrand(id: $id, brand: $brand) {
      id
    }
  }
`;

const UpdateBrandModal: React.FC<Props> = ({ open, handleClose, brand, refetchBrands }) => {
  const { name, logo, id: brandId } = brand;

  const [newBrandName, setNewBrandName] = useState(name);

  const toast = useToaster();

  const [setBrandLogo, { data: logoData, loading: uploading, error: uploadError }] = useMutation<
    updateBrandLogo,
    updateBrandLogoVariables
  >(UPDATE_BRAND_LOGO, {
    onCompleted: data =>
      toast({
        type: 'success',
        message: data.updateBrandLogo?.id ? 'Brand logo updated!' : 'Brand logo not updated :( ',
      }),
  });

  // TODO: make this update the brand in the cache rather than refetching all brands on completed
  const [updateBrand] = useMutation<updateBrand>(UPDATE_BRAND, {
    onCompleted: data => {
      refetchBrands();
      handleClose();
      toast({
        type: 'success',
        message: data.updateBrand?.id ? 'Brand updated!' : 'Brand not updated :( ',
      });
    },
  });

  const newLogo = logoData?.updateBrandLogo;

  // TODO: update this so that it retrieves the new logo src from the cache rather than the returned logo data
  const logoSrc: string = newLogo
    ? `${fileUrl}/${newLogo?.id}.${newLogo?.extension}`
    : `${fileUrl}/${logo?.id}.${logo?.extension}`;

  return (
    <Modal isOpen={open} onRequestClose={handleClose} title="Merk updaten">
      <Flex flexDirection="column">
        <Input
          onChange={e => setNewBrandName(e.target.value)}
          label="Merknaam"
          value={newBrandName}
          type="text"
          name="name"
          mb={4}
        />
        <Flex flexDirection="column" mb={4}>
          <Box mb={2}>Huidig logo</Box>
          <Flex flexDirection="row">
            <Flex flexDirection="column" width={1 / 4} justifyContent="center">
              {uploading ? (
                <PageLoading />
              ) : (
                <Image src={logoSrc} alt="logo" height="100px" width="100px" mr={4} />
              )}
            </Flex>

            <Uploader
              multiple={false}
              loading={uploading}
              accept=".jpg, .png, .svg, .webp"
              error={uploadError}
              text={'Upload een logo'}
              upload={({ variables }: { variables: { files: File[] } }) => {
                setBrandLogo({
                  variables: { id: brandId, logo: variables.files[0] },
                });
              }}
            />
          </Flex>
        </Flex>

        <Button
          style={{ margin: 0 }}
          onClick={() => updateBrand({ variables: { id: brandId, brand: { name: newBrandName } } })}
        >
          Save!
        </Button>
      </Flex>
    </Modal>
  );
};

export default UpdateBrandModal;
