import { Toast } from '@energiebespaarders/symbols';
import React from 'react';
import { ToastType } from '../../../hooks/useToaster';

type ConfiguratorNotificationsProps = {
  notifications: { key: string; type: ToastType; message: string }[];
};

const ConfiguratorNotifications: React.FC<ConfiguratorNotificationsProps> = ({ notifications }) => {
  return (
    <>
      {notifications.map((n, index) => (
        <Toast type={n.type} message={n.message} key={n.key} width="100%" toastId={999 + index} />
      ))}
    </>
  );
};

export default ConfiguratorNotifications;
