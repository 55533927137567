import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Box, Flex, Modal, Separator } from '@energiebespaarders/symbols';
import { Small, Medium } from '@energiebespaarders/symbols/helpers';
import { cancellationReasons } from '../../domains/Rating/utils';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';

export const CancellationModal = ({ job, isOpen, onClose }) => {
  const mobile = useIsMobile();
  if (!isOpen) return null;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title="Opdracht annulering"
      size="sm"
      mobile={mobile}
      buttons={[
        {
          label: 'Sluiten',
          bgColor: 'red',
          onClick: onClose,
        },
      ]}
    >
      <Flex flexWrap="wrap" mb={2} mx={-1}>
        <Box width={1} mx={1}>
          <Small>
            <p>
              Gennuleerd door{' '}
              <Medium>
                {job.cancellation.operator.firstName} {job.cancellation.operator.lastName}
              </Medium>{' '}
              op <Medium>{dayjs(job.cancellation.created).format('dddd DD MMMM YYYY')}.</Medium>
            </p>
          </Small>

          <Separator mt={1} mb={3} />
        </Box>

        <Box width={1 / 2} px={1}>
          <Medium>Installatiebedrijf</Medium>
        </Box>
        <Box width={1 / 2} px={1}>
          {job.installer.name}
        </Box>
        <Box width={1 / 2} px={1}>
          <Medium>Reden</Medium>
        </Box>
        <Box width={1 / 2} px={1}>
          {cancellationReasons.find(reason => reason.value === job.cancellation.reason).label}
        </Box>
        <Box width={1} px={1}>
          <Medium>Commentaar</Medium>
          <p>{job.cancellation.comment}</p>
        </Box>
      </Flex>
    </Modal>
  );
};

CancellationModal.propTypes = {
  job: PropTypes.object,
  mobile: PropTypes.bool,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

CancellationModal.defaultProps = {
  show: true,
};

export default CancellationModal;
