import { delimit, SolutionDomain } from '@energiebespaarders/constants';
import {
  adviceComposer_house,
  adviceComposer_house_quotes_items_product,
  adviceComposer_house_quotes_items_product_CompletePvSystem,
  adviceComposer_house_quotes_items_product_CompletePvSystem_optimizer,
  adviceComposer_house_quotes_items_product_HeatPump,
  adviceComposer_house_quotes_items_product_Inverter,
  adviceComposer_house_quotes_items_product_Optimizer,
  adviceComposer_house_quotes_items_product_PvPanel,
} from '../../types/generated/adviceComposer';
import {
  quoteModalQuote_quoteById_items_price,
  quoteModalQuote_quoteById_items_supplier,
} from '../../types/generated/quoteModalQuote';
import { ProductCategory } from '../../types/graphql-global-types';

const formatSubsidy = (amount: number): string => {
  return amount ? `${Math.round(amount)},-` : '';
};

type quoteItem = {
  amount: number;
  retailPrice: number;
  purchasePrice: number;
  supplierId?: string;
  product: adviceComposer_house_quotes_items_product;
  price?: quoteModalQuote_quoteById_items_price;
  supplier?: quoteModalQuote_quoteById_items_supplier;
};

type AdviceTextQuoteInputPvSystem = {
  __typename: 'QuotePvSystemInfo';
  cardinalDirection: string | null;
  installationPlanProperties: string | null;
  multipleRoofSurfaces: boolean | null;
  neighbourDiscount: boolean | null;
};

type AdviceTextQuoteInputEnergyDelta = {
  money: number;
  electricityConsumption: number;
  gasConsumption: number;
  electricityProduction: number;
  gasFactor: number;
};

export type AdviceTextQuoteInput = {
  energyDelta: AdviceTextQuoteInputEnergyDelta;
  pvSystemInfo: AdviceTextQuoteInputPvSystem | null;
  investment: number;
  paybackTime: number;
  items: readonly quoteItem[];
};

const getPvSystemInfoInput = (
  advisedQuote: AdviceTextQuoteInput | null,
  house: adviceComposer_house | undefined,
) => {
  const pvSystemInfo = advisedQuote?.pvSystemInfo;
  const cardinalDirection = pvSystemInfo?.cardinalDirection ? pvSystemInfo.cardinalDirection : '';
  const multipleRoofSurfaces =
    pvSystemInfo?.multipleRoofSurfaces !== null ? !!pvSystemInfo?.multipleRoofSurfaces : false;
  const installationPlanProperties = pvSystemInfo?.installationPlanProperties
    ? pvSystemInfo.installationPlanProperties
    : '';
  const neighbourDiscount =
    pvSystemInfo?.neighbourDiscount !== null ? !!pvSystemInfo?.neighbourDiscount : false;
  const pvSystem = advisedQuote?.items.find(
    i => i.product.category === ProductCategory.CompletePvSystem,
  );

  let panelPMax: string;
  let panelCellType: string;
  let panelBrand: string;
  let inverterBrand: string;
  let withOptimizer:
    | adviceComposer_house_quotes_items_product_CompletePvSystem_optimizer
    | null
    | undefined;
  let panelAmount: string;

  if (pvSystem) {
    const pvSystemProduct = pvSystem?.product as
      | adviceComposer_house_quotes_items_product_CompletePvSystem
      | undefined;
    panelPMax = pvSystemProduct?.pvPanel?.pMax ? delimit(pvSystemProduct.pvPanel.pMax, 0) : '';
    panelCellType = pvSystemProduct?.pvPanel?.cellType ? pvSystemProduct?.pvPanel.cellType : '';
    panelBrand = pvSystemProduct?.pvPanel?.brand ? pvSystemProduct?.pvPanel.brand.name : '';
    inverterBrand = pvSystemProduct?.inverter?.brand?.name
      ? pvSystemProduct?.inverter.brand?.name
      : '';
    withOptimizer = pvSystemProduct?.optimizer;
    panelAmount = pvSystemProduct?.panelAmount ? delimit(pvSystemProduct.panelAmount, 0) : '';
  } else {
    const panelItem = advisedQuote?.items.find(i => i.product.category === ProductCategory.PvPanel);
    const panelProduct = panelItem?.product as
      | adviceComposer_house_quotes_items_product_PvPanel
      | undefined;
    panelPMax = panelProduct?.pMax ? delimit(panelProduct.pMax, 0) : '';
    panelCellType = panelProduct?.cellType ? panelProduct.cellType : '';
    panelBrand = panelProduct?.brand ? panelProduct.brand.name : '';
    panelAmount = panelItem ? delimit(panelItem.amount, 0) : '';

    const inverterProduct = advisedQuote?.items.find(
      i => i.product.category === ProductCategory.Inverter,
    )?.product as adviceComposer_house_quotes_items_product_Inverter | undefined;
    inverterBrand = inverterProduct?.brand ? inverterProduct.brand.name : '';
    withOptimizer = advisedQuote?.items.find(i => i.product.category === ProductCategory.Optimizer)
      ?.product as adviceComposer_house_quotes_items_product_Optimizer | undefined;
  }

  const electricityAmount = advisedQuote?.energyDelta.electricityProduction
    ? delimit(advisedQuote.energyDelta.electricityProduction, 0)
    : '';
  const electricityPercentage =
    house?.situation?.consumption?.electricity && advisedQuote?.energyDelta.electricityProduction
      ? delimit(
          (advisedQuote.energyDelta.electricityProduction /
            house.situation.consumption.electricity) *
            100,
          1,
        )
      : '';
  return {
    cardinalDirection,
    multipleRoofSurfaces,
    installationPlanProperties,
    neighbourDiscount,
    panelPMax,
    panelCellType,
    panelBrand,
    inverterBrand,
    withOptimizer: !!withOptimizer,
    panelAmount,
    electricityAmount,
    electricityPercentage,
  };
};

export const generateAdviceFromSolutionDomain = (
  advisedQuote: AdviceTextQuoteInput | null,
  house: adviceComposer_house | undefined,
  solutionDomain: SolutionDomain,
  generateText: (variables: any) => void,
) => {
  const moneySavings = advisedQuote?.energyDelta.money
    ? delimit(advisedQuote.energyDelta.money, 0)
    : '';
  const investmentDelimited = advisedQuote ? delimit(advisedQuote.investment, 2) : '';
  const paybackTime = advisedQuote ? delimit(advisedQuote.paybackTime, 1) : '';
  const gasSavings = advisedQuote?.energyDelta.gasConsumption
    ? delimit(advisedQuote.energyDelta.gasConsumption, 0)
    : '';

  switch (solutionDomain) {
    case SolutionDomain.WallInsulation: {
      const wallQuoteItem = advisedQuote
        ? advisedQuote.items.find(
            i => i.product.category === ProductCategory.WallInsulationMaterial,
          )
        : null;
      const wallMeters = wallQuoteItem ? delimit(wallQuoteItem.amount, 0) : '';

      generateText({
        id: house!.advice!.id ? house!.advice!.id : '',
        solution: solutionDomain,
        cavitySavings: {
          meters: wallMeters,
          moneySavings,
          gasSavings,
          investment: investmentDelimited,
          paybackTime,
        },
      });
      break;
    }
    case SolutionDomain.PvSystem: {
      const pvSystemInfo = getPvSystemInfoInput(advisedQuote, house);
      generateText({
        id: house!.advice!.id ? house!.advice!.id : '',
        solution: solutionDomain,
        pvSystemInfo: {
          ...pvSystemInfo,
          moneySavings,
          investment: investmentDelimited,
          paybackTime,
        },
      });
      break;
    }
    case SolutionDomain.FloorInsulation: {
      const floorQuoteItem = advisedQuote
        ? advisedQuote.items.find(
            i => i.product.category === ProductCategory.FloorInsulationMaterial,
          )
        : null;
      // The quoted area can be higher than the insulatableArea to compensate for insulation material to be applied on cavity walls or between beams
      const floorMeters = floorQuoteItem?.amount ?? house?.intake?.floor?.insulatableArea ?? 0;

      generateText({
        id: house!.advice!.id ? house!.advice!.id : '',
        solution: solutionDomain,
        floorSavings: {
          meters: delimit(floorMeters, 0),
          moneySavings,
          gasSavings,
          investment: investmentDelimited,
          paybackTime,
        },
      });
      break;
    }
    case SolutionDomain.RoofInsulation: {
      const roofQuoteItem = advisedQuote
        ? advisedQuote.items.find(
            i => i.product.category === ProductCategory.RoofInsulationMaterial,
          )
        : null;
      const roofMeters = roofQuoteItem ? delimit(roofQuoteItem.amount, 0) : '';

      generateText({
        id: house!.advice!.id ? house!.advice!.id : '',
        solution: solutionDomain,
        roofSavings: {
          meters: roofMeters,
          moneySavings,
          gasSavings,
          investment: investmentDelimited,
          paybackTime,
        },
      });
      break;
    }
    case SolutionDomain.InsulationGlazing: {
      const windowsQuoteItem = advisedQuote
        ? advisedQuote.items.find(i => i.product.category === 'glazing')
        : null;
      const windowMeters = windowsQuoteItem ? delimit(windowsQuoteItem.amount, 0) : '';

      generateText({
        id: house!.advice!.id ? house!.advice!.id : '',
        solution: solutionDomain,
        windowsSavings: {
          meters: windowMeters,
          moneySavings,
          gasSavings,
          investment: investmentDelimited,
          paybackTime,
        },
      });
      break;
    }
    case SolutionDomain.Heating: {
      const heatPumpQuoteItem = advisedQuote
        ? advisedQuote.items.find(i => i.product.category === ProductCategory.HeatPump)
        : null;
      const investment = advisedQuote?.investment;
      // TODO: do we want to take the heatpump item product subsidy or do we want to use the total quote subsidy?
      //       The difference: product subsidy is only for 1 heatpump unit, while total quote subsidy includes the subsidy for all subsidizable quote items (e.g. 2 heatpumps)
      const subsidy = (heatPumpQuoteItem?.product as
        | adviceComposer_house_quotes_items_product_HeatPump
        | undefined)?.subsidy;

      generateText({
        id: house!.advice!.id ? house!.advice!.id : '',
        solution: solutionDomain,
        heatingSavings: {
          moneySavings,
          gasSavings,
          percentageGasSavings: advisedQuote?.energyDelta.gasFactor
            ? delimit(advisedQuote?.energyDelta.gasFactor * 100, 1)
            : '',
          increaseEnergyUsage:
            advisedQuote?.energyDelta.electricityConsumption &&
            advisedQuote?.energyDelta.electricityConsumption < 0
              ? delimit(advisedQuote?.energyDelta.electricityConsumption * -1, 0)
              : '',
          investment: typeof investment === 'number' ? delimit(investment, 0) : '',
          paybackTime,
          subsidy: typeof subsidy === 'number' ? formatSubsidy(subsidy) : '',
        },
      });
      break;
    }
    case SolutionDomain.Miscellaneous: {
      generateText({
        id: house!.advice!.id ? house!.advice!.id : '',
        solution: solutionDomain,
      });
      break;
    }
    default:
      console.error(
        `Wrong solution for automatic advice composer given, not implemented yet for ${solutionDomain}`,
      );
  }
};
