import { gql } from '@apollo/client';
import { InstallationFragment } from '../../../fragments';

export const UPDATE_SUPPLIER_IN_INSTALLATION = gql`
  ${InstallationFragment}
  mutation updateSupplierInInstallation(
    $installationId: ID!
    $oldItem: InstallationItemInput!
    $newSupplierId: ID!
  ) {
    updateSupplierInInstallation(
      installationId: $installationId
      oldItem: $oldItem
      newSupplierId: $newSupplierId
    ) {
      ...Installation
    }
  }
`;
