import { useQuery } from '@apollo/client';
import { getSolutionsFromDomain, SolutionDomain } from '@energiebespaarders/constants';
import { Select } from '@energiebespaarders/symbols';
import { Color } from '@energiebespaarders/symbols/helpers';
import React, { useCallback, useMemo } from 'react';
import constructProductOptions from '../../components/installatron/InstallatronCore/constructProductOptions';
import { SelectFilterOption } from '../../components/installatron/InstallatronCore/DropdownOptions';
import { GET_PRODUCTS_BY_SOLUTIONS_AND_SUPPLIER } from '../../components/installatron/InstallatronCore/queries';
import {
  getProductsBySolutionsAndSupplier,
  getProductsBySolutionsAndSupplierVariables,
  getProductsBySolutionsAndSupplier_products,
} from '../../types/generated/getProductsBySolutionsAndSupplier';

type t_product = getProductsBySolutionsAndSupplier_products;

const filterOption = (option: SelectFilterOption, searchString: string) =>
  option?.data?.searchValue?.match(new RegExp(searchString, 'gi'));

const ItemConfiguratorLite: React.FC<{
  installerName?: string;
  supplierId: string;
  solutionDomain: SolutionDomain;
  value: { id: string; title: string } | undefined;
  onChange: (product: t_product | undefined) => void;
  clearable?: boolean;
}> = ({ installerName, supplierId, solutionDomain, value, onChange, clearable = true }) => {
  const { data, loading, error } = useQuery<
    getProductsBySolutionsAndSupplier,
    getProductsBySolutionsAndSupplierVariables
  >(GET_PRODUCTS_BY_SOLUTIONS_AND_SUPPLIER, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      solutions: getSolutionsFromDomain(solutionDomain),
      supplierId,
    },
  });

  const options = useMemo(() => {
    const options = constructProductOptions([...(data?.products || [])]);

    // If the currently entered product does not exist in the list, add it to the top, marked as unavailable
    const valueOption = options.find(o => o.value === value?.id);
    if (value && !valueOption) {
      options.unshift({
        value: value.id,
        label: (
          <Color c="grayDarker">
            {value.title} <i>(niet beschikbaar)</i>
          </Color>
        ),
        searchValue: value?.title || '',
        disabled: true,
      });
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.products]);

  const handleChange = useCallback(
    option => onChange(!option ? undefined : data!.products.find(p => p.id === option.value)),
    [data, onChange],
  );

  return (
    <Select
      label={installerName ? `Beschikbare producten bij ${installerName}` : undefined}
      placeholder="Kies een product..."
      value={!value ? null : options.find(o => o.value === value.id)}
      onChange={handleChange}
      filterOption={filterOption}
      options={options}
      // Not needed, should always refetch, since we don't want outdated data
      // refetchOptions={refetch}
      loading={loading}
      error={error?.name}
      clearable={clearable}
    />
  );
};

export default React.memo(ItemConfiguratorLite);
