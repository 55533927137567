import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Icon,
  Modal,
  Separator,
  Tooltip,
} from '@energiebespaarders/symbols';
import { Gallery } from '@energiebespaarders/symbols/components/Gallery';
import { Heading, Medium, Small } from '@energiebespaarders/symbols/helpers';
import { Add, EnergyCircle, Flame, Info, Reply } from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { fileUrl } from '../../../config';
import { dealsByHouseSolutionDomain_dealsByHouseSolutionDomain } from '../../../types/generated/dealsByHouseSolutionDomain';

export type SelectedDeal = { dealId: string; quoteToCopyId?: string };

interface DealSelectorProps {
  selectDeal: React.Dispatch<React.SetStateAction<SelectedDeal | undefined>>;
  deals: readonly dealsByHouseSolutionDomain_dealsByHouseSolutionDomain[];
  selectedDeal: SelectedDeal | undefined;
}

const CardButton = styled(Card)<React.HTMLAttributes<HTMLDivElement> & { active: boolean }>`
  cursor: pointer;
  transition: 0.3s;
  height: 100%;

  &:hover {
    background-color: ${x => x.theme.colors.greenSlate};
  }

  ${x =>
    x.active &&
    css`
      color: white;
      background-color: ${x => x.theme.colors.green};

      &:hover {
        background-color: ${x => x.theme.colors.greenDark};
      }
    `}
`;

const QuoteCopyHeader: React.FC = () => {
  return (
    <Flex px={2}>
      <Box px={4}></Box>
      <Box width={1 / 7}>
        <Small>Referentie</Small>
      </Box>
      <Box width={1 / 7}>
        <Small>Besparingen</Small>
      </Box>
      <Box width={2 / 7}>
        <Small>Opmerkingen</Small>
      </Box>
      <Box width={2 / 7}>
        <Small>Bijlagen</Small>
      </Box>
    </Flex>
  );
};

const QuoteCopySelector: React.FC<{
  deal: dealsByHouseSolutionDomain_dealsByHouseSolutionDomain;
  quoteToCopyId?: string;
  setQuoteToCopyId: (id: string) => void;
}> = ({ deal, quoteToCopyId, setQuoteToCopyId }) => {
  return (
    <>
      <QuoteCopyHeader />
      {deal?.quotes.map(quote => {
        const { energyDelta, reference, files } = quote;
        return (
          <Box key={`quote-copy-${quote.id}`} pb={2} width="100%">
            <CardButton
              active={quoteToCopyId === quote.id}
              onClick={() => setQuoteToCopyId(quote.id)}
            >
              <Flex alignItems="center">
                <Box px={3}>
                  <input type="radio" checked={quote.id === quoteToCopyId} value="" />
                </Box>
                <Box width={1 / 7} pr={1}>
                  <Heading heading="h5" style={{ margin: 0 }}>
                    {reference || 'CONCEPT'}
                  </Heading>
                </Box>
                <Box width={1 / 7} pr={1}>
                  <Icon solid fill="red" icon={Flame} />{' '}
                  {Math.round(
                    energyDelta.gasConsumption - energyDelta.gasFactor * energyDelta.gasFactor,
                  )}{' '}
                  m3 / jaar
                  <br />
                  <Icon solid fill="gold" icon={EnergyCircle} />{' '}
                  {Math.round(energyDelta.electricityProduction)} kWh / jaar
                </Box>
                <Box width={2 / 7} pr={1}>
                  <span>{quote.text || 'Geen opmerkingen'}</span>
                </Box>
                <Box width={2 / 7}>
                  {files.length ? (
                    <Gallery
                      files={quote.files}
                      fileUrl={fileUrl}
                      size="sm"
                      tags={['quote']}
                      thumbSize={50}
                    />
                  ) : (
                    <span>Geen bijlagen</span>
                  )}
                </Box>
              </Flex>
            </CardButton>
          </Box>
        );
      })}
    </>
  );
};

const HIDE_NEW_DEAL_ALERT_FLAG = 'hideNewDealAlert';

const DealSelector: React.FC<DealSelectorProps> = ({ deals, selectDeal, selectedDeal }) => {
  const localStorageFlag = window.localStorage.getItem(HIDE_NEW_DEAL_ALERT_FLAG);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [dontShowAlertAgain, setDontShowAlertAgain] = useState(false);
  const [isCopyingQuoteData, setIsCopyingQuoteData] = useState(false);

  const setQuoteToCopyId = (id: string | undefined) => {
    selectDeal(state => (state ? { dealId: state.dealId, quoteToCopyId: id } : undefined));
  };

  const setSelectedDealId = (id: string) => {
    selectDeal({ dealId: id, quoteToCopyId: undefined });
  };

  useEffect(() => {
    if (deals.length > 0) {
      selectDeal({ dealId: deals[0].id });
    } else {
      selectDeal({ dealId: 'new', quoteToCopyId: undefined });
    }
  }, [deals, isCopyingQuoteData, selectDeal]);

  const handleSelectNewDeal = useCallback(() => {
    if (deals.length > 0 && (!localStorageFlag || JSON.parse(localStorageFlag) !== true)) {
      setAlertModalIsOpen(true);
    } else {
      selectDeal({ dealId: 'new', quoteToCopyId: undefined });
    }
  }, [deals.length, localStorageFlag, selectDeal]);

  const handleConfirmNewDeal = useCallback(() => {
    selectDeal({ dealId: 'new', quoteToCopyId: undefined });
    setAlertModalIsOpen(false);

    dontShowAlertAgain
      ? window.localStorage.setItem(HIDE_NEW_DEAL_ALERT_FLAG, 'true')
      : window.localStorage.removeItem(HIDE_NEW_DEAL_ALERT_FLAG);
  }, [dontShowAlertAgain, selectDeal]);

  const newDealExplainer = (
    <span>
      Maak <Medium>alleen</Medium> een nieuwe deal aan{' '}
      <Medium>als deze offerte los te realiseren is</Medium> van de andere bestaande deals.
      Bijvoorbeeld als de klant een tweede zonnestroomsysteem wil. Verschillende deals worden
      namelijk als <Medium>aparte installatie-tijdlijnen</Medium> in het account van de klant
      getoond.
    </span>
  );

  // If "new deal" is selected then this will be undefined
  const fullDeal = deals.find(deal => deal.id === selectedDeal?.dealId);

  return (
    <>
      <p>
        <Medium>Selecteer een deal om aan de offerte toe te voegen.</Medium>
        {deals.length > 0 && (
          <Tooltip content={newDealExplainer} padding={2} lineHeight={1.5}>
            <Icon icon={Info} fill="blue" mx={1} />
          </Tooltip>
        )}
      </p>
      <Flex flexWrap="wrap" mx={-1} alignItems="flex-start">
        {deals.map(deal => (
          <Box width={[1, 1 / 2, 1 / 4]} px={1} key={`dealSelector-${deal.reference}`}>
            <CardButton
              p={2}
              onClick={() => setSelectedDealId(deal.id)}
              active={selectedDeal?.dealId === deal.id}
            >
              <Small>
                <Medium>{deal.reference}</Medium>
                <div>
                  {deal.quotes.length} offerte{deal.quotes.length === 1 ? '' : 's'},{' '}
                  {deal.quotes.flatMap(q => q.jobs).length} opdracht
                  {deal.quotes.flatMap(q => q.jobs).length === 1 ? '' : 'en'}
                </div>
              </Small>
            </CardButton>
          </Box>
        ))}
        <Box width={[1, 1 / 2, 1 / 4]} p={2}>
          <CardButton p={2} onClick={handleSelectNewDeal} active={selectedDeal?.dealId === 'new'}>
            <Small>
              <Medium>Nieuwe deal maken</Medium>
            </Small>
          </CardButton>
        </Box>
      </Flex>

      {fullDeal && (
        <>
          <Box mt={3}>
            <p>Wil je de gegevens kopiëren van een bestaande offerte op deze deal?</p>
            <Checkbox
              id="quote copy checkbox"
              checked={isCopyingQuoteData}
              label="Offertedata kopiëren"
              value={true}
              onClick={() => setIsCopyingQuoteData(!isCopyingQuoteData)}
            />
          </Box>

          {isCopyingQuoteData ? (
            <>
              <Separator my={3} />
              <h4>Offertedata kopiëren</h4>
              <p>
                Deze deal heeft al offertes. Selecteer een offerte om de data naar de nieuwe offerte
                te kopiëren.
              </p>
              <QuoteCopySelector
                deal={fullDeal}
                quoteToCopyId={selectedDeal?.quoteToCopyId}
                setQuoteToCopyId={setQuoteToCopyId}
              />
              <Button
                minimal
                onClick={() => {
                  setIsCopyingQuoteData(false);
                  setQuoteToCopyId(undefined);
                }}
              >
                Annuleren
              </Button>
            </>
          ) : null}
        </>
      )}

      <Modal
        isOpen={alertModalIsOpen}
        onRequestClose={() => setAlertModalIsOpen(false)}
        title="Weet je zeker dat dit een nieuwe deal moet worden?"
        buttons={[
          {
            label: 'Deze offerte hoort bij een eerdere deal',
            bgColor: 'purple',
            iconStart: Reply,
            onClick: () => setAlertModalIsOpen(false),
            fontSize: 6,
          },
          {
            label: 'Ja, dit is een nieuwe deal',
            iconStart: Add,
            bgColor: 'purple',
            onClick: handleConfirmNewDeal,
            fontSize: 6,
          },
        ]}
      >
        <p>{newDealExplainer}</p>
        <Checkbox
          value={dontShowAlertAgain}
          checked={dontShowAlertAgain}
          onChange={() => setDontShowAlertAgain(!dontShowAlertAgain)}
          label="Deze melding niet meer tonen als ik nieuwe deals maak"
          id="hideNewDealAlert"
        />
      </Modal>
    </>
  );
};

export default DealSelector;
