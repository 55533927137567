import { gql, useMutation, useQuery } from '@apollo/client';
import { Solution, isUndefined } from '@energiebespaarders/constants';
import { DataPointObj, IntakeValue, QuestionType } from '@energiebespaarders/data-maps';
import {
  Accordion,
  Box,
  Card,
  Checkbox,
  Flex,
  Icon,
  Input,
  Placeholder,
  Radio,
  RadioGroup,
  Range,
  Spacer,
  SpinnerOverlay,
  Textarea,
  Tooltip,
} from '@energiebespaarders/symbols';
import { Center, Gray, Medium, Red, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import {
  Calculator,
  Camera,
  CheckCircle,
  Flag,
  Flame,
  Grid,
  Info,
  Pencil,
  RadioButton,
} from '@energiebespaarders/symbols/icons/solid';
import { darken, rgba } from 'polished';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { submitOnEnter } from '../../domains/Intake/utils';
import { useNavHelpers } from '../../hooks/useNavHelpers';
import correctAnswerValueForDisplay from '../../lib/dataMaps/correctAnswerValueForDisplay';
import { fixUnit, getValueFromKey } from '../../lib/utils';
import { margin, padding, themify } from '../../styles/mixins';
import { intakeOverview_house } from '../../types/generated/intakeOverview';
import {
  setIntakeAnswerAsIncorrect,
  setIntakeAnswerAsIncorrectVariables,
} from '../../types/generated/setIntakeAnswerAsIncorrect';
import QuestionIcons from './QuestionIcons';
import RCInputHelper from './RCInputHelper';

export type QuestionModel = 'house' | 'intake' | 'situation' | 'customer';

const QuestionTextContainer: React.FC<{ label: string; question?: string }> = ({
  label,
  question,
}) => <span dangerouslySetInnerHTML={{ __html: question || label }} />;

export function getQuestionModel(question: DataPointObj): QuestionModel {
  const type = question.key.match(/[^.]*/) || '';
  return type[0] as QuestionModel;
}

export function getQuestionModelId(model: QuestionModel, house: intakeOverview_house) {
  switch (model) {
    case 'house':
      return house.intake.id;
    case 'situation':
      return house.situation.id;
    case 'customer':
      return house.customer?.id || '';
    default:
      return '';
  }
}

export function getQuestionQueryVariables(model: QuestionModel, houseId: string, modelId: string) {
  if (model === 'house' && houseId) {
    return { id: houseId };
  } else if (model === 'customer' && modelId) {
    return { id: modelId };
  } else if (model === 'situation' && houseId) {
    return { houseId };
  }
  return { id: modelId };
}

export function getIntakeValue(
  model: QuestionModel,
  question: DataPointObj,
  data: any,
  emptyValue: string | string[] | undefined,
) {
  switch (model) {
    case 'house':
      return getValueFromKey(question.key.replace('house', 'intake'), data?.house);
    case 'situation':
      return getValueFromKey(question.key.replace('house', 'situation'), data);
    case 'customer':
      return getValueFromKey(question.key.replace('house', 'customer'), data);
    default:
      return emptyValue;
  }
}

export function getEmptyQuestionValue(question: DataPointObj) {
  switch (question.type) {
    case QuestionType.STRING:
    case QuestionType.TEXTAREA:
      return '';
    case QuestionType.CHECKBOX:
      return [];
    case QuestionType.BOOLEAN:
    case QuestionType.NUMBER:
    case QuestionType.RADIO:
    default:
      return undefined;
  }
}

export function formatIntakeAnswer(question: DataPointObj, intakeValue: any, answered = true) {
  switch (question.type) {
    case QuestionType.CHECKBOX:
      return question.options
        ?.filter(option => (intakeValue as any[])?.includes(option.value))
        .map(option => option.label)
        .join(', ');
    case QuestionType.RADIO:
      return answered
        ? question.options?.filter(
            option => !isUndefined(intakeValue) && intakeValue === option.value,
          )[0]?.label || '-'
        : '';
    case QuestionType.BOOLEAN:
      return intakeValue === true ? 'Ja' : intakeValue === false ? 'Nee' : '';
    case QuestionType.STRING:
    case QuestionType.TEXTAREA:
    case QuestionType.RANGE:
    case QuestionType.NUMBER:
      if (intakeValue !== undefined && intakeValue !== null) {
        return `${intakeValue} ${fixUnit(question.unit, true)}`;
      }
      return;
    default:
      return;
  }
}

export const StyledQuestion = styled(Card)<{ $isHighlighted?: boolean }>`
  ${margin(2, 'bottom')};
  position: relative;
  display: flex;
  align-content: center;
  outline: ${x => (x.$isHighlighted ? `2px solid ${x.theme.colors.blue}` : undefined)};
  transition: outline 1s ease;

  ${RadioGroup.Label}, ${Input.Label} {
    ${margin(1, 'bottom')};
  }
`;

export const QuestionAccordionTitle = styled(Flex)`
  position: 'relative';
  width: calc(100% - 60px);
  font-weight: 500;
  font-size: ${x => x.theme.type.scale[5]};
  vertical-align: middle;
  display: inline-flex;

  > *:nth-child(1) {
    white-space: noWrap;
  }

  > *:nth-child(2) {
    white-space: noWrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    color: ${themify('grayDark')};
  }
`;

StyledQuestion.defaultProps = {
  p: 0,
};

interface QuestionNumberProps {
  $bgColor: string;
  $color?: string;
  $hideCheckmark?: boolean;
  $disabled?: boolean;
  $answered?: boolean;
  $clickable?: boolean;
  $compact?: boolean;
}

export const QuestionNumber = styled.div<QuestionNumberProps>`
  ${padding(2)};
  text-align: center;
  background: ${x => rgba(x.$bgColor, 0.2)};
  border-right: 1px solid ${x => x.theme.colors.grayLight};
  border-radius: 6px 0 0 6px;
  font-weight: bold;
  min-width: 60px;
  transition: background 0.2s ${x => x.theme.curves.standard}, padding 0.3s;
  pointer-events: ${x => (x.$hideCheckmark ? 'none' : 'all')};
  color: ${x => x.$color || x.theme.colors.grayBlack};

  ${x =>
    x.$compact &&
    css<QuestionNumberProps>`
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    `}

  ${x =>
    x.$answered &&
    x.$clickable &&
    css<QuestionNumberProps>`
      cursor: ${x => (x.$disabled ? 'default' : 'pointer')};

      &:hover {
        background: ${x => darken(0.1, rgba(x.$bgColor, 0.15))};
      }

      &:active {
        background: ${x => darken(0.2, rgba(x.$bgColor, 0.2))};
      }
    `}

  & svg {
    ${margin('auto')};
  }
`;

QuestionNumber.defaultProps = {
  $clickable: true,
  $bgColor: 'white',
};

type QuestionContentProps = {
  $bgColor?: string;
};

export const QuestionContent = styled.div<QuestionContentProps>`
  ${padding(2)};
  width: 100%;
  min-width: 0;
  background: ${x => rgba(x.$bgColor ?? 'white', 0.2)};
`;

export const QuestionIcon = styled(Icon)`
  transition: opacity 0.2s ${x => x.theme.curves.standard};
  cursor: pointer;
`;

export const getCorrectedNumberValue = (question: DataPointObj, value: number) => {
  if (question.minValue && value < question.minValue) return question.minValue;
  if (question.maxValue && value > question.maxValue) return question.maxValue;
  if (value === 0) return 0;
  return value;
};

export const useCollapsingQuestionCard = (
  collapseIfAnswered: boolean,
  answered: boolean,
  questionType: QuestionType,
  answer: IntakeValue,
  excludeFromProgress: boolean,
) => {
  const [isCollapsed, setCollapsed] = useState(answered || excludeFromProgress);
  const toggleCollapsed = useCallback(() => setCollapsed(v => !v), []);

  const prevAnsweredRef = useRef(answered);
  const collapseTimeoutRef = useRef(0);

  useEffect(() => {
    // Set collapsed with a timeout when an answer is given
    if (
      collapseIfAnswered &&
      answered &&
      questionType !== QuestionType.CHECKBOX &&
      answer !== 'other' // other is usually labeled as "Other, see note" and to enter a note, it shouldn't collapse
    ) {
      if (collapseTimeoutRef.current) {
        clearTimeout(collapseTimeoutRef.current);
      }
      collapseTimeoutRef.current = window.setTimeout(() => setCollapsed(true), 500);
    }
    if (prevAnsweredRef.current && !answered) setCollapsed(false);
    prevAnsweredRef.current = answered;

    return () => {
      collapseTimeoutRef.current && clearTimeout(collapseTimeoutRef.current);
    };
  }, [answer, answered, collapseIfAnswered, questionType]);
  return { isCollapsed, toggleCollapsed };
};

interface IQuestionProps {
  forQuote: Solution[];
  hideCheckmark?: boolean;
  houseId: string;
  id: string;
  isNarrow: boolean;
  model: QuestionModel;
  number: number;
  openNotesModal: (question: DataPointObj) => void;
  openTableModal: () => void;
  openJouleToGasModal?: (question: DataPointObj) => void;
  openCalculatorModal: (question: DataPointObj) => void;
  openUploadModal: (question: DataPointObj) => void;
  hasSentQuotes: boolean;
  question: DataPointObj;
  bgColor?: string;
  // Updating this will set the local state to this value
  answerOverride?: any;
  collapseIfAnswered?: boolean;
  isHighlighted?: boolean;
  isFocused?: boolean;
  setFocusedKey: Dispatch<SetStateAction<string>>;
  incorrectMap?: Map<string, boolean>;
}

const SET_INTAKE_ANSWER_AS_INCORRECT = gql`
  mutation setIntakeAnswerAsIncorrect($houseId: ID!, $key: String!) {
    setIntakeAnswerAsIncorrect(houseId: $houseId, key: $key) {
      id
      incorrectMap {
        key
        incorrect
      }
    }
  }
`;

export const Question: React.FC<IQuestionProps> = ({
  bgColor = 'white',
  forQuote,
  hideCheckmark,
  houseId,
  id,
  isNarrow,
  model,
  number,
  openNotesModal,
  openTableModal,
  openJouleToGasModal,
  openCalculatorModal,
  openUploadModal,
  question,
  answerOverride,
  collapseIfAnswered,
  isHighlighted,
  isFocused,
  setFocusedKey,
  hasSentQuotes,
  incorrectMap,
}) => {
  const { setHelpMenuState } = useNavHelpers();

  const queryVariables = useMemo(() => getQuestionQueryVariables(model, houseId, id), [
    model,
    houseId,
    id,
  ]);

  const { data, loading, error } = useQuery(question.query!, { variables: queryVariables });

  const [answerQuestionMutation, { loading: saving, error: answerError }] = useMutation(
    question.mutation!,
  );

  // The question key currently also contains info of the model (Intake, Situation) it comes from
  // The mutation though is already specific per model and expects the key without that part. So this strips the first part of the string to get a valid key,
  // this is not ideal as it can lead to confusion what keys to use when. but so far this is the best thing I could come up with
  const questionKey = question.key.split('.').slice(1).join('.');

  const [
    setIntakeAnswerAsIncorrectMutation,
    { loading: savingAnswerAsIncorrect, error: incorrectAnswerError },
  ] = useMutation<setIntakeAnswerAsIncorrect, setIntakeAnswerAsIncorrectVariables>(
    SET_INTAKE_ANSWER_AS_INCORRECT,
    { variables: { houseId, key: questionKey } },
  );

  // once situation supports the incorrect structure update this to select the correct mutation based on the model
  const setAnswerAsIncorrectMutation =
    model === 'intake' || model === 'house' ? setIntakeAnswerAsIncorrectMutation : console.log;

  const answerQuestion = useCallback(
    (value: any) => {
      answerQuestionMutation({ variables: { id, answer: value ?? null } });
    },
    [id, answerQuestionMutation],
  );

  const closeAlert = useCallback((e: Event) => {
    const confirmationMessage = '';
    (e || window.event).returnValue = Boolean(confirmationMessage); // Gecko + IE
    return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', closeAlert);
    return window.removeEventListener('beforeunload', closeAlert);
  }, [closeAlert]);

  const emptyValue: IntakeValue = useMemo(() => {
    return getEmptyQuestionValue(question);
  }, [question]);

  // The intake value stored in the back-end
  const intakeValue: IntakeValue = useMemo(
    () => correctAnswerValueForDisplay(question, getIntakeValue(model, question, data, emptyValue)),
    [question, model, data, emptyValue],
  );

  // The value shown in the input, set to the stored intakeValue by default
  const [value, setValue] = useState<any>(intakeValue);

  // Override the local state with the value passed from the parent, when it changes
  useEffect(() => {
    if (answerOverride) setValue(answerOverride);
  }, [answerOverride]);

  // In case the stored value was not loaded yet on mount, set it once it finishes loading
  const [isStoredValueSet, setStoredValueSet] = useState(!loading);

  useEffect(() => {
    if (!isStoredValueSet && !loading) {
      setStoredValueSet(true);
      setValue(intakeValue);
    }
  }, [intakeValue, isStoredValueSet, loading]);

  const showTableButton = useMemo(
    () =>
      question.isSurfaceQuestion ||
      question.isSurfaceTotalQuestion ||
      question.isSurfaceAmountQuestion,
    [question],
  );

  const handleTextAnswer = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') return;
    setValue(e.target.value);
  }, []);

  const handleNumberAnswer = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value === '' ? undefined : parseFloat(e.target.value);
    setValue(val);
  }, []);

  const handleCheckboxAnswer = useCallback(
    (answer: string | number) => {
      const newValue = (value as any[])?.slice(0) ?? [];
      const val = newValue.includes(answer)
        ? newValue.filter(x => x !== answer)
        : [...newValue, answer];
      setValue(val);
      answerQuestion(val);
    },
    [answerQuestion, value],
  );

  const handleRadioAnswer = useCallback(
    (answer: string | number) => {
      setValue(answer);
      answerQuestion(answer);
    },
    [answerQuestion],
  );

  const handleBooleanAnswer = useCallback(
    (value: boolean) => {
      setValue(value);
      answerQuestion(value);
    },
    [answerQuestion],
  );

  const handleRangeAnswer = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') return;
      const val = parseFloat(e.target.value);
      setValue(val);
      answerQuestion(val);
    },
    [answerQuestion],
  );

  const handleFocus = useCallback(() => {
    setFocusedKey(question.key);
  }, [question.key, setFocusedKey]);

  const renderInput = useCallback(
    (renderLabel = true) => {
      if (
        question.type === QuestionType.NUMBER &&
        (intakeValue === null || typeof intakeValue === 'number')
      ) {
        if (question.key.endsWith('rc')) {
          return (
            <RCInputHelper
              question={question}
              onBlur={v => {
                setValue(v), answerQuestion(v);
              }}
              onChange={handleNumberAnswer}
              value={value}
              renderLabel={renderLabel}
            />
          );
        }

        const isGasOrElectricityConsumptionInput =
          question.key === 'situation.consumption.gas' ||
          question.key === 'situation.consumption.electricity';

        const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
          let v: number | undefined = undefined;
          if (e.target.value !== '') {
            v = parseFloat(e.target.value);
            if (v < (question.minValue || 0) || v > (question.maxValue || 0)) {
              v = getCorrectedNumberValue(question, v);
            }
          }

          const isUpdatingConsumptionAnswer =
            v &&
            intakeValue &&
            v !== intakeValue &&
            !isNaN(v) &&
            isGasOrElectricityConsumptionInput &&
            hasSentQuotes;

          if (
            isUpdatingConsumptionAnswer &&
            !window.confirm(
              'Er zijn al offertes verstuurd, weet je zeker dat je het energieverbruik wilt aanpassen? Dit heeft invloed op de besparingsberekeningen op de offerte voor de klant',
            )
          ) {
            // Reset the value of the input field back to its currently saved value when cancelling the submission
            return setValue(intakeValue);
          }

          setValue(v);
          answerQuestion(v);
        };

        // TODO: set min and max for area questions. Negative numbers break calculations

        return (
          <Input
            type="number"
            label={renderLabel ? <QuestionTextContainer {...question} /> : undefined}
            labelColor="grayBlack"
            labelSize={5}
            min={question.minValue}
            max={question.maxValue}
            name={question.key}
            value={value ?? ''}
            onChange={handleNumberAnswer}
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder={question.label}
            addonSide={question.unit ? (question.unit === '€' ? 'start' : 'end') : undefined}
            addonContent={fixUnit(question.unit)}
            onKeyDown={submitOnEnter}
            touched={isGasOrElectricityConsumptionInput && hasSentQuotes && isFocused}
            error={
              isGasOrElectricityConsumptionInput && hasSentQuotes
                ? 'Let op: Deze waarde beïnvloedt de besparing op verzonden offertes'
                : ''
            }
          />
        );
      }

      const handleTextBlur = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
      ) => {
        setValue(e.target.value);
        answerQuestion(e.target.value);
      };

      if (question.type === 'string') {
        return (
          <Input
            type="text"
            label={renderLabel ? <QuestionTextContainer {...question} /> : undefined}
            labelColor="grayBlack"
            labelSize={5}
            name={question.label}
            onChange={handleTextAnswer}
            onBlur={handleTextBlur}
            onFocus={handleFocus}
            placeholder={question.label}
            value={value}
            addonSide={question.unit ? 'end' : undefined}
            addonContent={fixUnit(question.unit)}
            onKeyDown={submitOnEnter}
          />
        );
      }
      if (question.type === 'textarea') {
        return (
          <Textarea
            label={renderLabel ? <QuestionTextContainer {...question} /> : undefined}
            labelColor="grayBlack"
            labelSize={5}
            name={question.label}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}
            onBlur={handleTextBlur}
            onFocus={handleFocus}
            placeholder={question.label}
            value={value || ''}
          />
        );
      }
      if (question.type === 'radio') {
        return (
          <RadioGroup
            divider={isNarrow ? 1 : 2}
            label={renderLabel ? <QuestionTextContainer {...question} /> : undefined}
            id={question.key}
            labelColor="grayBlack"
            labelSize={5}
            onChange={handleRadioAnswer}
          >
            {question.options?.map((option, index) => (
              <Radio
                key={`${option.label.replace(/\s/g, '')}-${index}`}
                checked={Boolean(!isUndefined(intakeValue) && intakeValue === option.value)}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroup>
        );
      }
      if (
        question.type === 'boolean' &&
        (intakeValue === null || typeof intakeValue === 'boolean')
      ) {
        return (
          <RadioGroup
            divider={isNarrow ? 1 : 2}
            label={renderLabel ? <QuestionTextContainer {...question} /> : undefined}
            id={question.key}
            labelColor="grayBlack"
            labelSize={5}
            onChange={handleBooleanAnswer}
          >
            <Radio
              checked={Boolean(!isUndefined(intakeValue) && intakeValue === true)}
              label="Ja"
              value={true}
            />
            <Radio
              checked={Boolean(!isUndefined(intakeValue) && intakeValue === false)}
              label="Nee"
              value={false}
            />
          </RadioGroup>
        );
      }
      if (question.type === 'checkbox' && (Array.isArray(intakeValue) || intakeValue === null)) {
        return (
          <RadioGroup
            divider={isNarrow ? 1 : 2}
            bgColor="orange"
            label={renderLabel ? <QuestionTextContainer {...question} /> : undefined}
            id={question.key}
            labelColor="grayBlack"
            labelSize={5}
            onChange={handleCheckboxAnswer}
          >
            {question.options?.map(option => (
              <Checkbox
                checked={((intakeValue as any[]) ?? []).includes(option.value)}
                id={`${option.label}-${question.key}`}
                key={`${option.label}-${question.key}`}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroup>
        );
      }
      if (question.type === 'range' && question.range) {
        return (
          <>
            {renderLabel && (
              <RadioGroup.Label $labelColor="grayBlack">
                <QuestionTextContainer {...question} />{' '}
              </RadioGroup.Label>
            )}
            <Range
              value={intakeValue as number | undefined}
              onChange={handleRangeAnswer}
              min={question.range.min}
              max={question.range.max}
              step={question.range.step}
              showValue={false}
            />
            <Center block>
              <Small>
                <Medium>{intakeValue}</Medium> {question.unit}
              </Small>
            </Center>
          </>
        );
      }
    },
    [
      question,
      intakeValue,
      isFocused,
      value,
      handleNumberAnswer,
      hasSentQuotes,
      answerQuestion,
      handleFocus,
      handleTextAnswer,
      isNarrow,
      handleRadioAnswer,
      handleBooleanAnswer,
      handleCheckboxAnswer,
      handleRangeAnswer,
    ],
  );

  const answered = useMemo(() => !isUndefined(intakeValue, true), [intakeValue]);

  const { isCollapsed, toggleCollapsed } = useCollapsingQuestionCard(
    !!collapseIfAnswered,
    answered,
    question.type!,
    intakeValue,
    !!question.excludeFromProgress,
  );

  const formattedIntakeAnswer = formatIntakeAnswer(question, intakeValue, answered);

  const confirmResetMessage = `\n Huidig antwoord: ${formattedIntakeAnswer} \n\n Weet je zeker dat je het antwoord wilt verwijderen?`;

  const handleResetAnswer = useCallback(() => {
    if (answered && window.confirm(confirmResetMessage)) {
      setValue(emptyValue);
      answerQuestion(emptyValue);
    }
  }, [answerQuestion, answered, confirmResetMessage, emptyValue]);

  const answeredOther = useMemo(
    () =>
      Array.isArray(intakeValue) && typeof intakeValue[0] === 'string'
        ? (intakeValue as string[]).includes('other')
        : intakeValue === 'other',
    [intakeValue],
  );

  const cardContent = (
    <>
      {renderInput(true)}

      {(answerError || incorrectAnswerError) && <Red>Opslaan mislukt</Red>}

      {question.minValue && (
        <Smaller>
          <Gray>
            Minimale waarde: <Medium>{question.minValue}</Medium>
          </Gray>
        </Smaller>
      )}
      {question.minValue && question.maxValue && <Spacer amount={1} />}
      {question.maxValue && (
        <Smaller>
          <Gray>
            Maximale waarde: <Medium>{question.maxValue}</Medium>
          </Gray>
        </Smaller>
      )}

      <Flex justifyContent="space-between">
        <Box>
          <QuestionIcons question={question} />
        </Box>
        <Box>
          {/* TODO: For RC input: A <select /> for input type: Intypen/Tempex/Steenwol/etc.. if Intypen: show <input />, otherwise <select> for thickness */}

          {question.key === 'situation.consumption.gas' && openJouleToGasModal && (
            <Tooltip content="MJ / m³ omrekenen" bgColor="blue" disabled={isNarrow}>
              <QuestionIcon
                height="18px"
                hoverColor="blue"
                fill="gray"
                icon={Flame}
                onClick={() => openJouleToGasModal(question)}
                solid
                width="18px"
                mx={1}
              />
            </Tooltip>
          )}
          {question.type === QuestionType.NUMBER && (
            <Tooltip content="Waarden berekenen" bgColor="blue" disabled={isNarrow}>
              <QuestionIcon
                height="18px"
                hoverColor="blue"
                fill="gray"
                icon={Calculator}
                onClick={() => openCalculatorModal(question)}
                solid
                width="18px"
                mx={1}
              />
            </Tooltip>
          )}
          {showTableButton && (
            <>
              <Tooltip content="Via tabel invullen" bgColor="blue" disabled={isNarrow}>
                <QuestionIcon
                  height="18px"
                  hoverColor="blue"
                  fill="gray"
                  icon={Grid}
                  onClick={openTableModal}
                  solid
                  width="18px"
                  mx={1}
                />
              </Tooltip>
            </>
          )}
          {question.key.endsWith('.rc') && (
            <>
              <Tooltip content="Informatie en voorbeelden" bgColor="blue" disabled={isNarrow}>
                <QuestionIcon
                  height="18px"
                  hoverColor="blue"
                  fill="gray"
                  icon={Info}
                  onClick={() => {
                    setHelpMenuState?.({ isOpen: true, activeTab: 2 });
                    setTimeout(
                      () =>
                        document
                          .getElementById('rc')
                          ?.scrollIntoView({ block: 'start', behavior: 'smooth' }),
                      50,
                    );
                  }}
                  solid
                  width="18px"
                  mx={1}
                />
              </Tooltip>
            </>
          )}
          {openUploadModal && (
            <Tooltip content="Foto toevoegen" bgColor={'blue'} disabled={isNarrow}>
              <QuestionIcon
                height="18px"
                hoverColor={'blue'}
                fill={'gray'}
                icon={Camera}
                onClick={() => openUploadModal(question)}
                solid
                width="18px"
                mx={1}
              />
            </Tooltip>
          )}
          {openNotesModal && (
            <Tooltip
              content={`Notitie toevoegen ${answeredOther ? 'verplicht' : ''}`}
              bgColor={answeredOther ? 'red' : 'blue'}
              disabled={isNarrow}
            >
              <QuestionIcon
                fill={answeredOther ? 'red' : 'gray'}
                height="18px"
                width="18px"
                icon={Pencil}
                hoverColor={answeredOther ? 'red' : 'blue'}
                solid
                onClick={() => openNotesModal(question)}
                mx={1}
              />
            </Tooltip>
          )}
          {incorrectMap && incorrectMap.get(question.key) !== undefined && (
            <Tooltip
              content={'Vraag als fout/onvolledig markeren'}
              bgColor="blue"
              disabled={isNarrow}
            >
              <QuestionIcon
                height="18px"
                hoverColor="blue"
                fill="gray"
                icon={Flag}
                onClick={() => {
                  if (
                    window.confirm(
                      'Weet je zeker dat je deze vraag als fout/onvolledig wilt markeren?',
                    )
                  ) {
                    setAnswerAsIncorrectMutation();
                  }
                }}
                solid
                width="18px"
                mx={1}
              />
            </Tooltip>
          )}
        </Box>
      </Flex>
    </>
  );

  return error ? (
    <Placeholder height="200px" error={error} />
  ) : (
    <StyledQuestion
      id={`${question.key}-question`}
      bgColor={themify(bgColor)}
      $isHighlighted={isHighlighted}
    >
      {(loading || saving || savingAnswerAsIncorrect) && <SpinnerOverlay />}
      <QuestionNumber
        onClick={handleResetAnswer}
        $answered={answered}
        $bgColor={themify(
          question.quoteSolutions?.some(solution => forQuote.includes(solution))
            ? 'orange'
            : question.key.includes('reasonNotAdvised')
            ? 'red'
            : 'white',
        )}
        $hideCheckmark={Boolean(hideCheckmark)}
        $disabled={loading || saving || savingAnswerAsIncorrect}
        $compact
        $color={themify(
          question.quoteSolutions?.some(solution => forQuote.includes(solution))
            ? question.excludeFromProgress
              ? 'orangeLight'
              : 'orangeDarker'
            : question.key.includes('reasonNotAdvised')
            ? question.excludeFromProgress
              ? 'redLight'
              : 'redDarker'
            : question.excludeFromProgress
            ? 'gray'
            : 'grayBlack',
        )}
      >
        {number}
        {!hideCheckmark && !question.excludeFromProgress && (
          <Icon
            fill={answered ? 'green' : 'gray'}
            height="14px"
            width="14px"
            icon={answered ? CheckCircle : RadioButton}
            solid
            inline={false}
            mx="auto"
            my={1}
          />
        )}
      </QuestionNumber>
      <QuestionContent $bgColor={incorrectMap?.get(question.key) ? 'red' : 'white'}>
        {!collapseIfAnswered ? (
          cardContent
        ) : (
          <Accordion
            override
            customBase={
              <QuestionAccordionTitle>
                <Box>{question.label || question.question}</Box>
                {!isCollapsed ? null : (
                  <Box minWidth={0} flexGrow={1} ml={1} mr={-2} title={formattedIntakeAnswer}>
                    {formattedIntakeAnswer}
                  </Box>
                )}
              </QuestionAccordionTitle>
            }
            titleSize={5}
            basePadding={0}
            contentPadding={0}
            onClick={toggleCollapsed}
            isOpened={!isCollapsed}
            style={{ minWidth: 0 }}
            baseBgColor={themify(bgColor)}
            contentBgColor={themify(bgColor)}
          >
            <Spacer amount={1} vertical />
            {cardContent}
          </Accordion>
        )}
      </QuestionContent>
    </StyledQuestion>
  );
};

export default Question;
