import React from 'react';
import { ActiveHouseIdProvider } from '../hooks/useActiveHouseId';
import { NavHelpersProvider } from '../hooks/useNavHelpers';
import { IsAuthenticatedProvider } from '../hooks/useIsAuthenticated';
import { MeProvider } from '../hooks/useMe';
import { PageTitleProvider } from '../hooks/usePageTitle';
import { ToasterProvider } from '../hooks/useToaster';
import { IsInitializingProvider } from '../hooks/useIsInitializing';

const ComposedContextProvider: React.FC = ({ children }) => (
  <IsInitializingProvider>
    <IsAuthenticatedProvider>
      <MeProvider>
        <ActiveHouseIdProvider>
          <PageTitleProvider>
            <ToasterProvider>
              <NavHelpersProvider>{children}</NavHelpersProvider>
            </ToasterProvider>
          </PageTitleProvider>
        </ActiveHouseIdProvider>
      </MeProvider>
    </IsAuthenticatedProvider>
  </IsInitializingProvider>
);

export default ComposedContextProvider;
