import { ApolloError } from '@apollo/client';
import { useForm } from '@energiebespaarders/hooks';
import { isMissing } from '@energiebespaarders/hooks/useForm';
import { Box, Button, Flex, Input } from '@energiebespaarders/symbols';
import { Writable } from '../../typeHelpers';
import { BasicAddress_address } from '../../types/generated/BasicAddress';
import { AddressInput } from '../../types/graphql-global-types';

export type IAddressFormProps<basic extends boolean = false> = {
  initialValues?: basic extends true
    ? Pick<BasicAddress_address, 'zip' | 'number' | 'suffix'>
    : BasicAddress_address;
  onSubmit: basic extends true
    ? (address: Pick<AddressInput, 'zip' | 'number' | 'suffix'>) => void
    : (address: AddressInput) => void;
  onCancel?: () => void;
  /** if true, displays a basic address form with only zip, number and suffix. If false (default), then show street and city as well. */
  basic?: basic;
  loading: boolean;
  error?: ApolloError;
};

function AddressForm<basic extends boolean = false>({
  initialValues,
  onSubmit,
  onCancel,
  basic,
  loading,
  error,
}: IAddressFormProps<basic>): JSX.Element {
  const { formState, submitForm, handleChange } = useForm<Writable<AddressInput>>({
    initialValues: initialValues || { zip: '', number: '' },
    validate: (values, errors) => {
      if (isMissing(values.zip)) errors.zip = 'Ontbreekt';
      if (isMissing(values.number)) errors.number = 'Ontbreekt';
      return errors;
    },
    handleSubmit: onSubmit,
  });

  return (
    <form onSubmit={submitForm}>
      <Flex flexWrap="wrap">
        <Box width={3 / 5} px={1}>
          <Input
            type="text"
            {...formState.zip}
            label="Postcode"
            placeholder="1234AB"
            maxLength={6}
            onChange={e => {
              handleChange({ zip: e.target.value });
            }}
          />
        </Box>
        <Box width={1 / 5} px={1}>
          <Input
            type="number"
            {...formState.number}
            label="Huisnummer"
            onChange={e => {
              handleChange({ number: Number(e.target.value) });
            }}
            maxLength={5}
          />
        </Box>
        <Box width={1 / 5} px={1}>
          <Input
            type="text"
            {...formState.suffix}
            label="Toevoeging"
            placeholder="a"
            onChange={e => {
              handleChange({ suffix: e.target.value });
            }}
          />
        </Box>

        {!basic && (
          <>
            <Box width={3 / 5} px={1}>
              <Input
                type="text"
                {...formState.street}
                label="Straat"
                onChange={e => {
                  handleChange({ street: e.target.value });
                }}
              />
            </Box>
            <Box width={2 / 5} px={1}>
              <Input
                type="text"
                {...formState.city}
                label="Stad"
                placeholder="Amsterdam"
                onChange={e => {
                  handleChange({ city: e.target.value });
                }}
              />
            </Box>
          </>
        )}
      </Flex>
      <Flex>
        <Box width={1 / 2} px="3px" mt={1}>
          {onCancel && <Button fluid label="Annuleren" onClick={onCancel} bgColor="red" />}
        </Box>
        <Box width={1 / 2} px="3px" mt={1}>
          <Button
            type="submit"
            fluid
            label="Opslaan"
            loading={loading}
            error={error && error.message}
          />
        </Box>
      </Flex>
    </form>
  );
}

export default AddressForm;
