import { DataPointObj } from '@energiebespaarders/data-maps';

/**
 *
 * Transforms answers to certain questions of data-maps items into a value that can be displayed properly
 * E.g., the houseType can be a value between 1 and 22, but we only show 8 options:
 * this function maps any value into one of those 8 categories.
 * @param question
 * @param value
 * @returns
 */
function correctAnswerValueForDisplay<T = any>(question: DataPointObj, value: T) {
  switch (question.key) {
    case 'house.type': {
      const type = (value as unknown) as number;
      if (type > 6 && type < 22) {
        return type <= 13 ? 6 : 5;
      }
    }
  }
  return value;
}

export default correctAnswerValueForDisplay;
