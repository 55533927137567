import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { themify } from '../../styles/mixins';
import mediaMin from '../../styles/breakpoints';
import { FontSize } from '@energiebespaarders/symbols/helpers';
import { usePageTitle } from '../../hooks/usePageTitle';

const StyledTitleBar = styled.div<{ bgColor: string; color: string; height: string }>`
  display: none;

  ${mediaMin.sm} {
    display: block;
    position: absolute;
    left: 0;
    width: calc(100% - (2 * 48px));
    text-align: left;
    background: ${x => x.bgColor};
    color: ${x => x.color};
    pointer-events: none;
    font-weight: 500;
    height: ${x => x.height};
    line-height: ${x => x.height};
    white-space: nowrap;
  }

  ${mediaMin.md} {
    right: 72px;
    text-align: right;
    width: calc(100% - 72px);
  }
`.withComponent(props => {
  return <div {..._.omit(props, ['bgColor', 'color', 'height'])} />;
});

interface TitleBarProps {
  bgColor?: string;
  color?: string;
  height: string;
}

const TitleBar: React.FC<TitleBarProps> = ({ bgColor = 'none', color = 'grayDark', height }) => {
  const { pageTitle } = usePageTitle();
  return (
    <StyledTitleBar bgColor={themify(bgColor)} color={themify(color)} height={height}>
      <FontSize scale={5}>{pageTitle}</FontSize>
    </StyledTitleBar>
  );
};

export default TitleBar;
