import { ApolloError } from '@apollo/client';
import { Flex, Box } from '@energiebespaarders/symbols';
import { Center, Gray, Right, Small } from '@energiebespaarders/symbols/helpers';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatRelativeTime } from '../domains/Communications/EmailHistoryTable';

const FlexContainer = styled(Flex)`
  max-height: 300px;
  overflow-y: auto;
`;

type Revision = {
  revision: string;
  modifiedBy: { name: string; link?: string };
  field: string;
  change: ReactNode;
  timestamp: Date;
};

interface IChangelogProps {
  loading?: boolean;
  error?: ApolloError;
  changes: Revision[];
  fetchMore?: () => void;
}

const Changelog: React.FC<IChangelogProps> = ({ changes, fetchMore, loading }) => {
  return (
    <FlexContainer flexWrap="wrap">
      {changes.map(({ revision, modifiedBy, timestamp, field, change }) => (
        <React.Fragment key={revision}>
          <Box width={1} mt={1} key={revision}>
            {field} → <strong>{change}</strong>
          </Box>
          <Box width={[1, 1, 1 / 2]}>
            <Small>
              Door{' '}
              {modifiedBy.link ? (
                <Link to={modifiedBy.link!}>{modifiedBy.name}</Link>
              ) : (
                <strong>{modifiedBy.name}</strong>
              )}{' '}
              · {formatRelativeTime(timestamp)}
            </Small>
          </Box>
          <Box width={[1, 1, 1 / 2]} pr={2}>
            <Small>
              <Gray>
                <Right block>{revision}</Right>
              </Gray>
            </Small>
          </Box>
        </React.Fragment>
      ))}
      {changes.length === 0 && <i>Er zijn nog geen aanpassingen geregistreerd</i>}
      {fetchMore && (
        <Box width={1}>
          <Center block>
            <Small>
              {loading ? (
                'Laden...'
              ) : (
                <a href="#" onClick={() => fetchMore()}>
                  Meer laden
                </a>
              )}
            </Small>
          </Center>
        </Box>
      )}
    </FlexContainer>
  );
};

export default Changelog;
