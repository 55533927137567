import React from 'react';
import styled, { css } from 'styled-components';

const width = '24';
const height = '24';
const viewBox = '0 0 24 24';

const getDimensions = () => ({
  height,
  width,
});

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`;

const Image = styled.svg`
  ${({ noStyles }) => (!noStyles ? getDimensionsCss() : null)};
`;

const defaultProps = {
  children: [
    <path
      key="path-0"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#000000"
      d="M12,0c2.2,0,4.41,0,6.61,0a5.16,5.16,0,0,1,4.64,2.59,5.69,5.69,0,0,1,.74,3c0,4.34,0,8.67,0,13a5.12,5.12,0,0,1-2.64,4.69,5.91,5.91,0,0,1-2.94.7c-4.33,0-8.67,0-13,0A5.16,5.16,0,0,1,.07,19.18C0,18.76,0,18.33,0,17.9,0,13.73,0,9.56,0,5.4A5.07,5.07,0,0,1,3,.57,6,6,0,0,1,5.72,0ZM7.52,19.46h3V14.61A10,10,0,0,0,12,15.3a4.55,4.55,0,0,0,5.19-2.09,6.72,6.72,0,0,0,.86-4.34,5.81,5.81,0,0,0-1.36-3.29,4.33,4.33,0,0,0-6.33-.2s-.07.06-.1.1c-.23-.55-.39-.75-.77-.87a3.09,3.09,0,0,0-.8-.11c-.79,0-1.57,0-2.36,0H6v3H7.11c.4,0,.41,0,.41.4V19.46Z"
    />,
    <path
      key="path-1"
      fill="#000000"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.39,9.73a3.81,3.81,0,0,1-.34,1.69,2.27,2.27,0,0,1-2.14,1.33,2.09,2.09,0,0,1-2-1.33,3.9,3.9,0,0,1,0-3.29,2.14,2.14,0,0,1,2.05-1.35A2.17,2.17,0,0,1,15,8.06,3.53,3.53,0,0,1,15.39,9.73Z"
    />,
  ],
  viewBox,
};

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'PipedriveIcon',
});
