import { gql } from '@apollo/client';

export const RatingFragment = gql`
  fragment Rating on Rating {
    id
    work
    communication
    extraWork
    cleanliness
    friendliness
    professionalism
    punctuality
    rater {
      ... on AuthenticatedUser {
        id
        firstName
        lastName
      }
    }
    created
    serviceRating
    notesOnDEB
    notesOnInstaller
  }
`;

export default RatingFragment;
