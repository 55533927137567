import { Solution } from '@energiebespaarders/constants';
import { Toast } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import React from 'react';
import { installationByHouseSolution_installationByHouseSolution as t_installation } from '../../../types/generated/installationByHouseSolution';

interface PvPanelTieringUnsupportedAlertProps {
  installation: t_installation;
}

const T2_SUPPLIER_ID = '62ce75740c24515c5be0ee9a';
const ISOLTREND_SUPPLIER_ID = '630f826379f158f924706b5d';

const PvPanelTieringUnsupportedAlert: React.FC<PvPanelTieringUnsupportedAlertProps> = ({
  installation,
}) => {
  if (installation.solution !== Solution.PvSystem) return <></>;
  const tieredSuppliers = [T2_SUPPLIER_ID, ISOLTREND_SUPPLIER_ID];
  if (!installation.items.some(item => tieredSuppliers.includes(item.supplier.id))) return <></>;

  const message = (
    <div>
      <Medium>Let op!</Medium>{' '}
      {installation.items.find(item => tieredSuppliers.includes(item.supplier.id))?.supplier.name}{' '}
      werkt sindskort met bepaalde paneelgroepen of klasses.{' '}
      <u>
        De klant moet op de hoogte gebracht worden dat het aangeboden paneel kan afwijken van wat de
        installateur daadwerkelijk kan inkopen en plaatsen.
      </u>{' '}
      De eigenschappen (uiterlijk, vermogen, garantie, e.d.) van de alternatieve panelen zijn altijd
      ten minste gelijk. We willen uiteindelijk ook PV-systemen op deze manier kunnen aanbieden in
      ons eigen systeem.
    </div>
  );
  return <Toast type="alert" message={message} width="100%" />;
};

export default PvPanelTieringUnsupportedAlert;
