import React, { ReactElement, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ActiveIndicator } from './SubNavBar';
import ScrollLink from '../ScrollLink';
import { shade, themify } from '../../styles/mixins';

type StyledSubNavItemProps = {
  $bgColor: string;
  $borderColor: string;
  $color: string;
  $height: number | string;
  $indicatorOffset: number;
};

const StyledSubNavItem = styled.div<StyledSubNavItemProps>`
  flex: 1 1 auto;
  position: relative;
  display: block;
  height: ${x => x.$height};
  line-height: ${x => x.$height};
  width: 100%;
  background: ${x => x.$bgColor};
  border-bottom: 1px solid ${x => x.$borderColor};
  color: ${x => x.$color};
  font-size: ${x => x.theme.type.scale[5]};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.25s;

  &:hover,
  &:focus {
    background: ${x => shade(0.95, x.$bgColor)};
    color: ${x => x.theme.colors.grayBlack};
  }

  &:active {
    background: ${x => shade(0.9, x.$bgColor)};
  }

  &.isActive {
    background: ${x => x.$bgColor};
    color: ${x => x.theme.colors.grayBlack};
    font-weight: 500;
    pointer-events: none;
    cursor: default;

    & ~ ${ActiveIndicator} {
      left: ${x => x.$indicatorOffset}%;
      display: block;
    }
  }
`;

interface ISubNavItemProps {
  label: string | ReactElement;
  to?: string;

  activeItem?: number;
  bgColor?: string;
  borderColor?: string;
  handleSetActive?: (index: number) => void;
  height?: string;
  itemIndex?: number;
  indicatorOffset?: number;
  scrollTo?: string;
  className?: string;
  color?: string;
  isDropdown?: boolean;
  toggleSubNav?: () => void;

  /** Props for https://github.com/fisshy/react-scroll */
  containerId?: string;
  duration?: number;
  delay?: number;
  hashSpy?: boolean;
  ignoreCancelEvents?: boolean;
  isDynamic?: boolean;
  offset?: number;
  onSetActive?: () => void;
  onSetInactive?: () => void;
  smooth?:
    | boolean
    | 'linear'
    | 'easeInQuad'
    | 'easeOutQuad'
    | 'easeInOutQuad'
    | 'easeInCubic'
    | 'easeOutCubic'
    | 'easeInOutCubic'
    | 'easeInQuart'
    | 'easeOutQuart'
    | 'easeInOutQuart'
    | 'easeInQuint'
    | 'easeOutQuint'
    | 'easeInOutQuint';
  spy?: boolean;
}

function filterReactDomProps<T extends { [key: string]: any }>(props: T) {
  const filterProps: Partial<T> = {};

  const invalidProps = [
    'activeItem',
    'bgColor',
    'borderColor',
    'color',
    'height',
    'indicatorOffset',
  ];

  for (const prop in props) {
    if (props.hasOwnProperty(prop) && !invalidProps.includes(prop)) {
      filterProps[prop] = props[prop];
    }
  }
  return filterProps;
}

const SubNavItem: React.FC<ISubNavItemProps> = ({
  activeItem,
  className,
  handleSetActive,
  itemIndex,
  label,
  scrollTo,
  to,
  toggleSubNav,
  isDropdown,
  bgColor = 'none',
  borderColor = 'transparent',
  color = 'grayDark',
  containerId = 'contentWrapper',
  height = '36px',
  indicatorOffset = 0,
  offset = 0,
  smooth = 'easeInOutQuint',
  spy = true,
}) => {
  const SubNavScrollLink = useCallback(
    (props: any) => <ScrollLink spy hashSpy to={to || ''} {...filterReactDomProps(props)} />,
    [to],
  );

  const subNavItemProps = {
    $bgColor: themify(bgColor),
    $borderColor: themify(borderColor),
    className,
    $color: themify(color),
    $height: isDropdown ? `calc(1.5 * ${height})` : height,
    $indicatorOffset: indicatorOffset,
    ...(scrollTo
      ? {
          activeClass: 'isActive',
          as: SubNavScrollLink,
          containerId,
          offset,
          onClick: isDropdown ? toggleSubNav : undefined,
          onSetActive: () =>
            activeItem !== itemIndex && handleSetActive && handleSetActive(itemIndex!),
          smooth,
          spy,
          to: scrollTo,
        }
      : {
          activeClassName: 'isActive',
          as: function SubNavLink(props: any) {
            return <NavLink {...(filterReactDomProps(props) as any)} />;
          },
          onClick: () => {
            if (isDropdown) {
              toggleSubNav?.();
            }
            if (activeItem !== itemIndex && handleSetActive) handleSetActive(itemIndex!);
          },
          to,
        }),
  };
  return <StyledSubNavItem {...subNavItemProps}>{label}</StyledSubNavItem>;
};

export default SubNavItem;
