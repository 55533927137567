import { useMutation } from '@apollo/client';
import { JobType } from '@energiebespaarders/constants';
import { Box, Gallery, Input, Modal, Textarea, Uploader } from '@energiebespaarders/symbols';
import { Gray, Small } from '@energiebespaarders/symbols/helpers';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { fileUrl } from '../../../../config';
import {
  MARK_SIGNOFF_COMPLETE,
  REMOVE_SIGNOFF_FILE,
  START_INSTALLER_SIGNOFF,
  UPDATE_SIGNOFF_COMMENT,
  UPLOAD_SIGNOFF_FILES,
} from '../../../../domains/TaskFlow/queries';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { useMe } from '../../../../hooks/useMe';
import useToaster from '../../../../hooks/useToaster';
import {
  addInstallerSignoffFiles,
  addInstallerSignoffFilesVariables,
} from '../../../../types/generated/addInstallerSignoffFiles';
import {
  markInstallerSignoffCompleted,
  markInstallerSignoffCompletedVariables,
} from '../../../../types/generated/markInstallerSignoffCompleted';
import {
  removeInstallerSignoffFiles,
  removeInstallerSignoffFilesVariables,
} from '../../../../types/generated/removeInstallerSignoffFiles';
import { startSignoff, startSignoffVariables } from '../../../../types/generated/startSignoff';
import {
  updateSignoffComment,
  updateSignoffCommentVariables,
} from '../../../../types/generated/updateSignoffComment';
import PtcSignoffOverview, { InstallerSignoff } from './PtcSignoffOverview';

interface SignoffModalProps {
  job: {
    id: string;
    type: JobType;
    completed: Date | null;
    installerSignoff: InstallerSignoff | null;
  };
  close: () => void;
  /** Called after marking results as completed.
   * If not provided, will link to the page to confirm the results after uploading results (/schouw-resultaten/) */
  onCompleted?: (data: markInstallerSignoffCompleted) => void;
}

// If we are uploading it for them then for now it's just one document - we're not filling the whole form in
const QUESTION_KEY = 'uploadYourOwn';

/**
 * NOTE:  This a stop-gap solution to allow DEBers to upload opleverdocs/PTC results.
 *        In the future we just want a plug and play form that can be used in both IA
 *        and EBH.
 */
const SignoffModal: React.FC<SignoffModalProps> = ({ job, close, onCompleted }) => {
  const toast = useToaster();
  const { me } = useMe();
  const history = useHistory();

  const [comment, setComment] = useState(job.installerSignoff?.signoffPoints[0]?.comment || '');
  const [jobExecutionDate, setJobExecutionDate] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [updateComment] = useMutation<updateSignoffComment, updateSignoffCommentVariables>(
    UPDATE_SIGNOFF_COMMENT,
    {
      onError(e) {
        toast({ type: 'error', message: e.message });
      },
      onCompleted: () => {
        setHasUnsavedChanges(false);
      },
    },
  );

  const debounceUpdate = useMemo(() => {
    return debounce(comment => {
      updateComment({
        variables: {
          jobId: job.id,
          key: QUESTION_KEY,
          comment,
        },
      });
    }, 500);
  }, [job.id, updateComment]);

  const handleUpdateComment = useCallback(
    (comment: string) => {
      setComment(comment);
      setHasUnsavedChanges(true);
      debounceUpdate(comment);
    },
    [debounceUpdate],
  );

  const [startSignoff, { loading: startLoading }] = useMutation<
    startSignoff,
    startSignoffVariables
  >(START_INSTALLER_SIGNOFF, {
    onError(e) {
      toast({ type: 'error', message: e.message });
    },
  });

  const [markComplete, { loading: markCompleteLoading }] = useMutation<
    markInstallerSignoffCompleted,
    markInstallerSignoffCompletedVariables
  >(MARK_SIGNOFF_COMPLETE, {
    onError(e) {
      toast({ type: 'error', message: e.message });
    },
    onCompleted(data) {
      onCompleted?.(data);
    },
  });

  const handleStartSignoff = useCallback(
    (date: string) => {
      startSignoff({
        variables: {
          jobId: job.id,
          userId: me.id,
          jobExecutionDate: new Date(date),
        },
      });
    },
    [job.id, me.id, startSignoff],
  );

  const [uploadFiles, { loading: uploading, error: uploadError }] = useMutation<
    addInstallerSignoffFiles,
    addInstallerSignoffFilesVariables
  >(UPLOAD_SIGNOFF_FILES, {
    onError(e) {
      toast({ type: 'error', message: e.message });
    },
  });

  const [removeFile] = useMutation<
    removeInstallerSignoffFiles,
    removeInstallerSignoffFilesVariables
  >(REMOVE_SIGNOFF_FILE, {
    onError(e) {
      toast({ type: 'error', message: e.message });
    },
  });

  const signoffPageLink = `/${
    job.type === 'INSTALLATION' ? 'opleverdocument' : 'schouw-resultaten'
  }/${job.id}`;

  const signoffPoint = job.installerSignoff?.signoffPoints[0];

  return (
    <Modal
      onRequestClose={hasUnsavedChanges ? undefined : close}
      title={`${job.type === 'INSTALLATION' ? 'Opleverdocument' : 'Schouwresultaten'} aanleveren`}
      isOpen={true}
      mobile={useIsMobile()}
      buttons={[
        ...(!job.installerSignoff
          ? [
              {
                name: 'start',
                label: `${
                  job.type === 'INSTALLATION' ? 'Opleverdocument' : 'Schouwdocument'
                } maken`,
                disabled: !jobExecutionDate || startLoading,
                onClick: () => handleStartSignoff(jobExecutionDate),
              },
            ]
          : []),
        ...(!job.installerSignoff?.markedAsComplete
          ? [
              {
                name: 'complete',
                label: 'Resultaten opslaan',
                disabled:
                  hasUnsavedChanges || // Wait for changes to be saved
                  uploading || // Wait for files to be uploaded
                  (!signoffPoint?.comment && !signoffPoint?.files.length) || // If the form is empty
                  markCompleteLoading, // Wait for mutation (prevent double-click)
                onClick: () => {
                  markComplete({ variables: { jobId: job.id } }).then(() =>
                    history.push(signoffPageLink),
                  );
                },
              },
            ]
          : []),
      ]}
    >
      {job.installerSignoff?.markedAsComplete ? (
        <>
          {/* TODO: re-use SignoffPoints instead of this new component. Needs slight refactoring due to different props */}
          <PtcSignoffOverview signoff={job.installerSignoff} />
          <br />
          <Link to={`/schouw-resultaten/${job.id}`}>Klik hier</Link> om de laatste stap uit te
          voeren
        </>
      ) : job.installerSignoff ? (
        <Box p={1}>
          <Uploader
            accept="image/*, .pdf, .eps, .svg"
            multiple={true}
            loading={uploading}
            error={uploadError}
            text="Upload bestanden"
            upload={({ variables }: any) => {
              uploadFiles({
                variables: {
                  files: variables.files,
                  jobId: job.id,
                  key: QUESTION_KEY,
                  userId: me.id,
                },
              });
            }}
          />
          <Gallery
            files={job.installerSignoff.signoffPoints[0]?.files || []}
            fileUrl={fileUrl}
            size="sm"
            tags={['signoff']}
            deleteFile={(fileId: string) => {
              removeFile({
                variables: {
                  jobId: job.id,
                  fileId,
                  key: QUESTION_KEY,
                },
              });
            }}
          />
          <Textarea
            label="Opmerkingen"
            placeholder="Hier kan je de opmerkingen van de uitgevoerde schouw invullen"
            value={comment}
            onChange={e => handleUpdateComment(e.target.value)}
          />
          {hasUnsavedChanges && (
            <Small>
              <Gray>Wijzigingen worden opgeslagen...</Gray>
            </Small>
          )}
        </Box>
      ) : (
        <Box>
          <Input
            type="date"
            value={jobExecutionDate}
            onChange={e => setJobExecutionDate(e.target.value)}
            label={
              job.type === 'INSTALLATION'
                ? 'Wanneer is het opleverdocument aangeleverd?'
                : 'Wanneer zijn de schouwresultaten aangeleverd?'
            }
            max={dayjs().format('YYYY-MM-DD')}
          />
        </Box>
      )}
    </Modal>
  );
};

export default SignoffModal;
