import { ResizeObserver } from '@juggle/resize-observer';
import React, { useMemo, useState } from 'react';
import useDimensions from 'react-cool-dimensions';
import styled from 'styled-components';
import { useRVH } from '../../hooks/useRVH';
import { themify } from '../../styles/mixins';

interface StyledContainerProps {
  $bgColor: string;
  $height: string;
  $offsetRight: string;
  $offsetLeft: string;
  $offsetTop: string;
  $masked: boolean;
  $translateX: string;
  innerRef?: any;
}

export const StyledContainer = styled.main<StyledContainerProps>`
  position: fixed;
  top: ${x => x.$offsetTop};
  left: ${x => x.$offsetLeft};
  width: ${x => `calc(100vw - ${x.$offsetLeft} - ${x.$offsetRight})`};
  height: ${x => x.$height};
  overflow: hidden;
  background: ${x => x.$bgColor};
  z-index: ${x => (x.$masked ? -1 : x.theme.z.base)};
  transform: ${x => `translateX(${x.$translateX})`};
  filter: ${x => (x.$masked ? 'blur(3px)' : 'none')};
  transition-property: top, left, width, height, transform, opacity;
  transition-duration: 0.25s;
  transition-timing-function: ${x => x.theme.curves.standard};
`;

interface ContentContainerProps {
  bgColor?: string;
  leftNavOpen: boolean;
  masked: boolean;
  narrowScreen: boolean;
  offsetRight: string;
  offsetLeft: string;
  offsetTop: string;
  translateX: string;
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  bgColor = '#eee',
  children,
  leftNavOpen,
  masked,
  narrowScreen,
  offsetRight,
  offsetLeft,
  offsetTop,
  translateX = '0',
}) => {
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const rvh = useRVH();
  const narrowContainer = useMemo(() => containerWidth > 0 && containerWidth < 768, [
    containerWidth,
  ]);
  const { ref: measureRef } = useDimensions({
    onResize: ({ width }) => width > 0 && setContainerWidth(width),
    polyfill: ResizeObserver,
  });
  return (
    <StyledContainer
      $bgColor={themify(bgColor)}
      $height={`calc(${100 * rvh}px - ${offsetTop})`}
      $offsetRight={offsetRight}
      $offsetLeft={offsetLeft}
      $offsetTop={offsetTop}
      $masked={masked}
      $translateX={translateX}
      ref={measureRef}
    >
      {React.Children.map(children, (child: any) =>
        React.cloneElement(child, {
          props: {
            ...child.props.props,
            containerWidth,
            leftNavOpen,
            narrowContainer,
            narrowScreen,
          },
        }),
      )}
    </StyledContainer>
  );
};

export default ContentContainer;
