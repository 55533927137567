import { gql } from '@apollo/client';
import EnergyDeltaFragment from './EnergyDelta';
import { HouseEnergyFragment } from './HouseEnergy';

export const CoordinatesFragment = gql`
  fragment Coordinates on Address {
    coordinates {
      latitude
      longitude
    }
  }
`;

export const BasicAddressFragment = gql`
  fragment BasicAddress on House {
    address {
      street
      number
      suffix
      zip
      city
    }
  }
`;

export const CompleteAddressFragment = gql`
  ${CoordinatesFragment}
  fragment CompleteAddress on House {
    address {
      street
      number
      suffix
      zip
      city
      municipality
      ...Coordinates
    }
  }
`;

export const DetailsFragment = gql`
  ${HouseEnergyFragment}
  fragment Details on House {
    area
    constructionYear
    energyLabel
    energy {
      ...HouseEnergy
    }
    residents
    type
    conservatory
    vve
    woz
    stories
    monument
  }
`;

export const EstimateFragment = gql`
  ${EnergyDeltaFragment}
  fragment Estimate on Estimate {
    energyDelta {
      ...EnergyDelta
    }
    comfortScore
    investment
  }
`;

export const EstimatesFragment = gql`
  ${EstimateFragment}
  fragment Estimates on AverageEstimates {
    wallInsulation {
      ...Estimate
    }
    floorInsulation {
      ...Estimate
    }
    crawlspaceInsulation {
      ...Estimate
    }
    innerRoofInsulation {
      ...Estimate
    }
    atticFloorInsulation {
      ...Estimate
    }
    windowGlazing {
      ...Estimate
    }
    windowFrames {
      ...Estimate
    }
    pvSystem {
      ...Estimate
    }
    centralHeatingBoiler {
      ...Estimate
    }
    hybridHeatPump {
      ...Estimate
    }
    electricHeatPump {
      ...Estimate
    }
    airToAirHeatPump {
      ...Estimate
    }
    underfloorHeating {
      ...Estimate
    }
    miscellaneous {
      ...Estimate
    }
    sedum {
      ...Estimate
    }
    chargingBox {
      ...Estimate
    }
    homeBattery {
      ...Estimate
    }
  }
`;

export const WallsFragment = gql`
  fragment Walls on Walls {
    amount
    area
    cavity
    cavityWidth
    connectedGarage
    gutterHeight
    insulatableArea
    insulatedArea
    nonInsulatableArea
    rc
  }
`;

export const FloorFragment = gql`
  fragment Floor on Floor {
    area
    floorSystem
    insulatableArea
    insulatedArea
    material
    nonInsulatableArea
    rc
    rcBasedOnYearAndDecree
    types
    moisturePercentage
  }
`;

export const CrawlspaceFragment = gql`
  fragment Crawlspace on Crawlspace {
    compartments
    height
    insulatableArea
    ventilation
  }
`;

export const RoofFragment = gql`
  fragment Roof on Roof {
    area
    insulatableInnerRoofArea
    insulatableAtticFloorArea
    atticFloorInsulationDepth
    insulatedArea
    nonInsulatableArea
    azimuth
    type
    roofing
    ridgeHeight
    rc
    shape
    inclination
  }
`;

export const WindowFragment = gql`
  fragment Window on Window {
    area
    type
    u
    zta
    space
  }
`;

export const WindowsZTAUFragment = gql`
  fragment WindowsZTAU on WindowsZTAU {
    livingZTA
    sleepingZTA
    livingU
    sleepingU
  }
`;

export const HeatingFragment = gql`
  fragment Heating on Heating {
    type
    heatEmissionSystems
    installations
    ventilationType
    draught
    pilotLight
    boilerAge
    floorHeatingSupplyTemperature
    floorHeatingGroups
    heatPumpPipelineLength
  }
`;

export const AppliancesFragment = gql`
  fragment Appliances on Appliances {
    ledLighting
    dishwasher
    shower
    savingShower
    bathtub
    kitchenBoiler
    boilerType
  }
`;

export const HouseSurfacesFragment = gql`
  ${WallsFragment}
  ${FloorFragment}
  ${RoofFragment}
  ${CrawlspaceFragment}
  ${WindowFragment}
  ${WindowsZTAUFragment}
  ${HeatingFragment}
  fragment HouseSurfaces on House {
    walls {
      ...Walls
    }
    floor {
      ...Floor
    }
    crawlspace {
      ...Crawlspace
    }
    roof {
      ...Roof
    }
    windows {
      ...Window
    }
    windowsZTAU {
      ...WindowsZTAU
    }
    heating {
      ...Heating
    }
  }
`;
