import { enumValues, JobType } from '@energiebespaarders/constants';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { Box, Flex, Icon, theme, Tooltip } from '@energiebespaarders/symbols';
import { Heading, Small } from '@energiebespaarders/symbols/helpers';
import {
  Calendar,
  Clipboard,
  Clock,
  PaperAirplane,
  Star,
  Unicorn,
  User,
  Wrenches,
} from '@energiebespaarders/symbols/icons/solid';
import { margin } from '@energiebespaarders/symbols/styles/mixins';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { JobIssueReason, JobStatus as JobStatusType } from '../../../types/graphql-global-types';
import { RatingModal } from '../../modals';
import { JOB_ISSUE_REASONS_NL } from './JobActions/RequestPlanningModal';

const HistoryConnector = styled(Box)`
  border-right: 2px solid ${theme.colors.green};
  height: 10px;
  width: 15px;
`;

const HistoryBox = styled(Box)`
  line-height: 0;
`;

const ClickableFlex = styled(Flex)<{ handleClick?: (...p: any) => void }>`
  ${p => (p.handleClick ? 'cursor: pointer;' : '')}
`;
const HistoryLabel = styled(Small)`
  line-height: 1;
  display: block;
  ${p => p.$clickable && 'text-decoration: underline;'}
`;
const Status = styled.p`
  font-size: 2rem;
  margin: 0;
  ${margin(2, 'bottom')}
`;

function getPlanningEventProps(planning: {
  created: Date;
  sent?: Date | null;
  plannedOn?: Date | null;
  plannedFor?: Date | null;
  suspended?: Date | null;
  fulfilled?: { key: string } | null;
  metadata?: { comment: string | null; additionalInformation: string | null } | null;
  __typename: string;
}) {
  if (planning.__typename === 'JobPlanning') {
    if (planning.sent) {
      if (
        enumValues(JobIssueReason).includes((planning.metadata?.comment || '') as JobIssueReason)
      ) {
        return {
          icon: PaperAirplane,
          iconColor: 'purple',
          text: 'Planningdatum aangevraagd (vanwege issue)',
          date: planning.sent,
          tooltip: `${JOB_ISSUE_REASONS_NL[planning.metadata!.comment! as JobIssueReason]}: ${
            planning.metadata!.additionalInformation || 'Geen extra informatie'
          }`,
        };
      } else {
        return {
          icon: PaperAirplane,
          iconColor: 'green',
          text: 'Planningdatum aangevraagd',
          date: planning.sent,
        };
      }
    }
    if (planning.plannedOn) {
      return {
        icon: Calendar,
        iconColor: 'green',
        text: 'Ingepland',
        date: planning.plannedOn,
      };
    }
    if (planning.suspended) {
      return {
        icon: Clock,
        iconColor: 'orange',
        text: 'Uitgesteld',
        date: planning.suspended,
      };
    }
  }
  if (planning.__typename === 'PlanningProposal') {
    return {
      icon: User,
      iconColor: planning.fulfilled ? 'blue' : 'grey',
      text: `Voorstel verstuurd${planning.fulfilled ? ' - voltooid door de klant' : ''}`,
      date: planning.created,
    };
  }
  return {
    icon: Unicorn,
    iconColor: 'red',
    text: 'onbekend',
    date: new Date('2000-01-01'),
  };
}

const PlanningHistoryRow: React.FC<{
  event: {
    icon: any;
    iconColor: string;
    text: string;
    date: any;
    tooltip?: string;
  };
  lastRow: boolean;
  handleClick?: () => void;
}> = ({ event, lastRow, handleClick }) => {
  const Content = () => (
    <>
      <ClickableFlex alightItems="center" onClick={handleClick}>
        <HistoryBox p={1}>
          <Icon icon={event.icon} solid fill={event.iconColor} />
        </HistoryBox>
        <HistoryBox p={1}>
          <HistoryLabel $clickable={handleClick || event.tooltip}>
            [{dayjs(event.date).format('DD/MM/YY')}] {event.text}
          </HistoryLabel>
        </HistoryBox>
      </ClickableFlex>
      {!lastRow && <HistoryConnector />}
    </>
  );

  if (event.tooltip) {
    return (
      <Tooltip content={event.tooltip} bgColor={event.iconColor}>
        <Content />
      </Tooltip>
    );
  } else {
    return <Content />;
  }
};

export interface Planning {
  created: Date;
  sent: Date | null;
  plannedFor: Date | null;
  plannedOn: Date | null;
  suspended: Date | null;
  metadata: { comment: string | null; additionalInformation: string | null } | null;
  __typename: 'JobPlanning';
}

export interface Proposal {
  created: Date;
  fulfilled: { key: string } | null;
  __typename: 'PlanningProposal';
}

export interface JobWithStatus {
  id: string;
  type: JobType;
  created: Date;
  completed: Date | null;
  status: JobStatusType;
  currentPlanning: Planning | null;
  plannings: Array<Planning>;
  proposals: Array<Proposal>;
  installerSignoff: {
    markedAsComplete: {
      date: Date;
    } | null;
  } | null;
  rating: {
    id: string;
    rater: {
      firstName: string;
      lastName: string;
      __typename: string;
    };
  } | null;
}

type JobStatusProps = {
  job: JobWithStatus;
};

const JobStatus: React.FC<JobStatusProps> = ({ job }) => {
  const status = useMemo(() => {
    switch (job.status) {
      case JobStatusType.completed:
        return 'Uitgevoerd';
      case JobStatusType.unsent:
        return 'Nog niet verstuurd';
      case JobStatusType.plannedIn:
        return 'Ingepland';
      case JobStatusType.proposalSent:
        return 'Planningsvoorstel verstuurd naar de klant';
      case JobStatusType.sent:
        return 'In afwachting van inplannen';
      case JobStatusType.suspended:
        return 'Uitgesteld';
      case JobStatusType.signedOff:
        return 'In afwachting van bevestigen op oplevering';
      case JobStatusType.rated:
        return 'Beoordeeld';
      default:
        return '?';
    }
  }, [job]);

  const [activeRating, setActiveRating] = useState('');
  const mobile = useIsMobile();

  const planningsAndProposals = [...job.plannings, ...job.proposals].sort(
    (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime(),
  );
  const lastPlanning = planningsAndProposals.slice(-1)?.[0];

  return (
    <>
      <Box pb={1}>
        <Heading>Huidige status:</Heading>
        <Status>{status}</Status>
        {planningsAndProposals.length
          ? planningsAndProposals.map((planning, i) => {
              const event = getPlanningEventProps(planning);
              return (
                <PlanningHistoryRow
                  key={planning.__typename + i}
                  event={event}
                  lastRow={
                    i === planningsAndProposals.length - 1 &&
                    !(lastPlanning?.__typename === 'JobPlanning' && lastPlanning.plannedFor) &&
                    !job.completed &&
                    !(job.type === 'PTC' && job.installerSignoff?.markedAsComplete)
                  }
                />
              );
            })
          : 'Nog geen planningsgebeurtenissen...'}
        {lastPlanning?.__typename === 'JobPlanning' && lastPlanning.plannedFor && !job.completed && (
          <PlanningHistoryRow
            key={'plannedFor'}
            event={{
              icon: Calendar,
              iconColor: 'gray',
              text: 'Verwachte uitvoerdatum',
              date: lastPlanning.plannedFor,
            }}
            lastRow={!job.completed && !job.installerSignoff?.markedAsComplete}
          />
        )}
        {/* TODO: !!! This is not robust - completing a schouw needs to be sorted out ofter job domain refactor */}
        {job.type === 'PTC' && job.installerSignoff?.markedAsComplete && (
          <PlanningHistoryRow
            key={'completed'}
            event={{
              icon: Clipboard,
              iconColor: job.completed ? 'green' : 'gray', // If the job isn't marked completed then the ptc results haven't been confirmed
              text: 'Geschouwd',
              date: job.installerSignoff?.markedAsComplete?.date,
            }}
            lastRow
          />
        )}
        {job.type === 'INSTALLATION' && job.installerSignoff?.markedAsComplete && (
          <PlanningHistoryRow
            key={'completed'}
            event={{
              icon: Clipboard,
              iconColor: 'green',
              text: 'Opleverdocument geüpload',
              date: job.installerSignoff?.markedAsComplete?.date,
            }}
            lastRow
          />
        )}
        {job.type === 'INSTALLATION' && job.completed && (
          <PlanningHistoryRow
            key={'completed'}
            event={{
              icon: Wrenches,
              iconColor: 'green',
              text: 'Uitgevoerd',
              date: job.completed,
            }}
            lastRow={!job.rating}
          />
        )}
        {job.rating && (
          <PlanningHistoryRow
            key={'rating'}
            event={{
              icon: Star,
              iconColor: 'gold',
              text: `Beoordeeld door ${
                job.rating.rater.__typename === 'Customer'
                  ? 'de klant'
                  : `${job.rating.rater.firstName} ${job.rating.rater.lastName}`
              }`,
              date: job.completed,
            }}
            handleClick={() => setActiveRating(job.rating!.id)}
            lastRow
          />
        )}
      </Box>
      <RatingModal
        show={!!activeRating}
        onClose={() => setActiveRating('')}
        jobId={job.id}
        mobile={mobile}
      />
    </>
  );
};

export default JobStatus;
