import { withApollo } from '@apollo/client/react/hoc';
import isUrl from 'is-url';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Card, Icon, Image, ImageModal, Tooltip } from '@energiebespaarders/symbols';
import { Color, Highlight } from '@energiebespaarders/symbols/helpers';
import {
  Code,
  FormatBold,
  FormatItalic,
  FormatUnderline,
  Image as ImageIcon,
  Link,
  LinkOff,
  List,
  ListNumbered,
  OneSquare,
  Palette,
  QuoteOpen,
  Reply,
  Send,
  Subscript,
  Superscript,
  TwoSquare,
  TypeSquare,
} from '@energiebespaarders/symbols/icons/solid';
import { Block, Value } from 'slate';
import PasteLinkify from 'slate-paste-linkify';
import { Editor, getEventRange, getEventTransfer } from 'slate-react';
import SoftBreak from 'slate-soft-break';
import styled, { css } from 'styled-components';
import { fileUrl } from '../config';
import { isImage } from '../domains/Advice/utils';
import { withSpacing } from '../lib/hoc/withSpacing';
import { debounce } from '../lib/utils/debounce';
import { UPLOAD_HOUSE_FILES } from '../queries/files';
import { margin, padding, shade, themify } from '../styles/mixins';
import UploadPickerModal from './modals/UploadPickerModal';

function MarkHotkey(options) {
  const { type, key } = options;
  return {
    onKeyDown(event, editor, next) {
      if ((!event.ctrlKey && !event.metaKey) || event.key !== key) return next();
      event.preventDefault();
      editor.toggleMark(type);
    },
  };
}

const DEFAULT_NODE = 'paragraph';
const SCHEMA = {
  document: {
    last: { types: ['paragraph'] },
    normalize: (editor, { code, node }) => {
      switch (code) {
        case 'last_child_type_invalid': {
          const paragraph = Block.create('paragraph');
          return editor.insertNodeByKey(node.key, node.nodes.size, paragraph);
        }
        default:
          return;
      }
    },
  },
  blocks: {
    image: {
      isVoid: true,
    },
  },
};
const PLUGINS = [
  MarkHotkey({ key: 'b', type: 'bold' }),
  MarkHotkey({ key: '`', type: 'code' }),
  MarkHotkey({ key: 'i', type: 'italic' }),
  MarkHotkey({ key: '~', type: 'strikethrough' }),
  MarkHotkey({ key: 'u', type: 'underline' }),
  PasteLinkify(),
  SoftBreak({ shift: true }),
];
export const initialValue = topic => ({
  document: {
    key: topic,
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            text: '',
          },
        ],
      },
    ],
  },
});

const StyledWrapper = styled(Card)`
  background: linear-gradient(white, white), ${x => x.bgColor};

  ${x =>
    x.readOnly &&
    css`
      background: none;
      box-shadow: none;
    `};
`;

const StyledEditor = styled.div`
  ${x =>
    x.readOnly
      ? css`
          font-size: ${x => x.theme.type.scale[5]};
        `
      : css`
          ${padding(4)};
          background: ${x => x.bgColor};
          color: ${x => x.color};
          border-radius: 0 0 9px 9px;
          min-height: 12rem;
          height: 30rem;
          overflow-y: scroll;
          resize: vertical;
        `};
`;

const StyledToolbar = styled.div`
  ${padding(0.5)};
  width: 100%;
  border-radius: 9px 9px 0 0;
  border-bottom: 1px solid ${x => x.theme.colors.grayLight};
  background: ${x => x.theme.colors.grayLighter};
`;

const StyledToolbarButton = styled.div`
  ${margin(0.5)};
  display: inline-block;
  padding: 0.5em;
  line-height: 1;
  border: 1px solid ${x => x.theme.colors.grayLight};
  border-radius: 3px;
  cursor: pointer;
  background: ${x => themify(x.buttonBgColor || 'white')};
  transition: background 0.15s ${x => x.theme.curves.standard};

  &:hover {
    background: ${x =>
      shade(
        0.9,
        x.theme.colors[
          x.isActive ? (x.buttonBgColor ? x.buttonBgColor : 'grayBlack') : 'grayLighter'
        ],
      )};
  }

  &:active {
    background: ${x => x.theme.colors[x.isActive ? 'grayBlack' : 'grayLight']};
    border-color: ${x => x.theme.colors.gray};
  }
`;

const InsertedImage = styled(Image)`
  border-radius: 3px;
  border: 1px solid transparent;
  max-width: 100%;
  max-height: 320px;
  cursor: pointer;

  ${x =>
    x.isSelected &&
    css`
      border-color: ${x.theme.colors.green};
    `};
`;

const ColorBlock = styled.div`
  ${margin(1, 'right')};
  display: inline-block;
  width: 2em;
  height: 2em;
  border: 1px solid ${x => x.theme.colors.grayDark};
  background: ${x => (x.color === 'reset' ? 'none' : x.color)};
  cursor: pointer;
  transition: border-color 0.15s ${x => x.theme.curves.standard};

  &:hover {
    border-color: ${x => x.theme.colors.gray};
  }

  &:last-child {
    margin: 0;
  }

  ${x =>
    x.color === 'reset' &&
    css`
      &:last-child {
        position: relative;
        border-radius: 50%;
        border-color: white;
        background: ${x => x.theme.colors.grayDarker};
        overflow: hidden;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: 'X';
        color: white;
        font-size: ${x => x.theme.type.scale[8]};
        line-height: 2.3;
        border-radius: 50%;
      }
    `};
`;

class WYSIWYG extends React.Component {
  constructor(props) {
    super(props);
    const { handleSave, loading, topic, value } = props;
    this.state = {
      imageModalSource: '',
      isImagePickerOpen: false,
      loading,
      topic,
      value: Value.fromJSON(value || initialValue(topic)),
    };
    this.handleSave = debounce(handleSave, 1000);
    this.handleBlock = this.handleBlock.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDropOrPaste = this.handleDropOrPaste.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleMark = this.handleMark.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleRedo = this.handleRedo.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.hasBlock = this.hasBlock.bind(this);
    this.hasLink = this.hasLink.bind(this);
    this.hasMark = this.hasMark.bind(this);
    this.handleUpdateMark = this.handleUpdateMark.bind(this);
    this.insertImage = this.insertImage.bind(this);
    this.handleInsertImageFile = this.handleInsertImageFile.bind(this);
    this.renderInline = this.renderInline.bind(this);
    this.renderMark = this.renderMark.bind(this);
    this.renderBlock = this.renderBlock.bind(this);
    this.unwrapLink = this.unwrapLink.bind(this);
    this.wrapLink = this.wrapLink.bind(this);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.topic !== prevState.topic) {
      return {
        ...prevState,
        topic: nextProps.topic,
        value: Value.fromJSON(nextProps.value ? nextProps.value : initialValue(nextProps.topic)),
      };
    }
    return prevState;
  }
  handleChange({ value }) {
    const { topic } = this.props;
    if (value.document !== this.state.value.document) {
      const content = JSON.stringify(value.toJSON());
      this.handleSave(content, topic);
    }
    this.setState({ value });
  }
  /**
   *
   * @param {*} event
   * @param {SlateEditor} editor
   * @param {*} next
   * @returns
   */
  async handleDropOrPaste(event, editor, next) {
    const target = getEventRange(event, editor);
    if (!target && event.type === 'drop') return next();

    const transfer = getEventTransfer(event);
    const { type, text, files } = transfer;

    const { client, houseId, operatorId, topic } = this.props;

    // Handle pasting images
    if (type === 'files' && files.length >= 0) {
      event.persist(); // needed due to async await
      const uplRes = await client.mutate({
        mutation: UPLOAD_HOUSE_FILES,
        variables: {
          id: houseId,
          files: files[0],
          tags: ['intake', topic, 'adviceText'],
          uploaderId: operatorId,
          uploaderUserType: 'operator',
        },
      });

      const fenFile = uplRes.data.uploadHouseFiles[0];

      const imgUrl = `${fileUrl}/${fenFile.id}.${fenFile.extension}`;
      this.editor.command(this.insertImage, imgUrl);
    }

    // Handle pasting links
    if (type !== 'text' && type !== 'html') return next();
    if (type === 'text') {
      if (isUrl(text) && !isImage(text)) {
        if (this.hasLink()) {
          // Replace selected text with link
          editor.command(this.unwrapLink);
          return editor.command(this.wrapLink, text);
        } else {
          // Insert text with link
          return editor
            .insertText(text)
            .moveFocusBackward(text.length)
            .command(this.wrapLink, text);
        }
      } else if (text.startsWith('#')) {
        event.preventDefault();

        const hasSelection = !editor.value.selection.isCollapsed;

        // If a paragraph starts with markdown header, do simple parsing of headers
        const lines = text.split('\n');
        for (const line of lines) {
          if (line.startsWith('#')) {
            const isHeaderTwo = line.startsWith('##');
            const trimmedHeader = line.substr(isHeaderTwo ? 2 : 1).trim();
            editor.insertBlock({
              type: isHeaderTwo ? 'heading-two' : 'heading-one',
              nodes: [{ object: 'text', leaves: [{ text: trimmedHeader }] }],
            });
          } else {
            editor.insertBlock({
              type: 'paragraph',
              nodes: [{ object: 'text', leaves: [{ text: line }] }],
            });
          }
        }
        // Remove empty new lines at the end
        if (!hasSelection) {
          editor.deleteForward();
        } else {
          editor.deleteBackward();
        }
        // back to the top
        editor.moveToStartOfDocument();
        // And there is an empty line at the front too for some reason
        editor.deleteForward();
        return;
      }
    }
    if (editor.value.selection.isCollapsed) {
      return next();
    }
    return next();
  }
  handleRedo(event) {
    event.preventDefault();
    this.editor.redo();
  }
  handleUndo(event) {
    event.preventDefault();
    this.editor.undo();
  }
  handleImage() {
    this.setState({ isImagePickerOpen: true });
  }
  handleInsertImageFile(fileOrUrl) {
    this.editor.focus();

    // Close modal and wait for when editor is focused (rerender happens after setState)
    this.setState({ isImagePickerOpen: false }, () => {
      if (typeof fileOrUrl === 'string') {
        this.editor.command(this.insertImage, fileOrUrl);
      } else {
        const file = fileOrUrl;
        const url = `${fileUrl}/${file.id}.${file.extension}`;
        this.editor.command(this.insertImage, url);
      }
    });
  }
  handleBlock(event, type) {
    event.preventDefault();
    const { value } = this.editor;
    const { document } = value;
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock('list-item');

      if (isList) {
        this.editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else {
        this.editor.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock('list-item');
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type);
      });

      if (isList && isType) {
        this.editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else if (isList) {
        this.editor
          .unwrapBlock(type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list')
          .wrapBlock(type);
      } else {
        this.editor.setBlocks('list-item').wrapBlock(type);
      }
    }
  }
  handleMark(event, type) {
    event.preventDefault();
    this.editor.toggleMark(type);
  }
  handleUpdateMark(event, type, mark) {
    event.preventDefault();
    const { value } = this.state;
    if (this.hasMark(type)) {
      value.activeMarks
        .filter(mark => mark.type === type)
        .map(prevMark => this.editor.removeMark(prevMark));
      this.editor.addMark(mark);
    } else {
      this.editor.addMark(mark);
    }
  }
  handleLink(event) {
    event.preventDefault();
    const { value } = this.editor;
    const hasLink = this.hasLink();
    if (hasLink) {
      this.editor.command(this.unwrapLink);
    } else if (value.selection.isExpanded) {
      const href = window.prompt('URL van de link:');
      if (href === null) {
        return;
      }
      this.editor.command(this.wrapLink, href);
    } else {
      const href = window.prompt('URL van de link:');
      if (href === null) {
        return;
      }
      const text = window.prompt('Tekst van de link:');
      if (text === null) {
        return;
      }
      this.editor.insertText(text).moveFocusBackward(text.length).command(this.wrapLink, href);
    }
  }
  hasBlock(type) {
    const { value } = this.state;
    return value.blocks.some(node => node.type === type);
  }
  hasMark(type) {
    const { value } = this.state;
    return value.activeMarks.some(mark => mark.type === type);
  }
  hasLink() {
    const { value } = this.state;
    return value.inlines.some(inline => inline.type === 'link');
  }
  insertImage(editor, src, target) {
    if (target) {
      editor.select(target);
    }
    editor.insertBlock({
      type: 'image',
      data: { src },
    });
  }
  renderMark(props, _, next) {
    const { children, mark, attributes } = props;
    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'code':
        return <code {...attributes}>{children}</code>;
      case 'italic':
        return <em {...attributes}>{children}</em>;
      case 'underlined':
        return <u {...attributes}>{children}</u>;
      case 'super':
        return <sup {...attributes}>{children}</sup>;
      case 'sub':
        return <sub {...attributes}>{children}</sub>;
      case 'highlight':
        return (
          <Highlight highlightColor={mark.getIn(['data', 'highlight'])} t={0.5} {...attributes}>
            {children}
          </Highlight>
        );
      case 'color':
        return (
          <Color c={mark.getIn(['data', 'color'])} {...attributes}>
            {children}
          </Color>
        );
      default:
        return next();
    }
  }
  renderBlock(props) {
    const { attributes, children, isSelected, node } = props;
    switch (node.type) {
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>;
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'heading-one':
        return <h3 {...attributes}>{children}</h3>;
      case 'heading-two':
        return <h4 {...attributes}>{children}</h4>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      case 'link': {
        const { data } = node;
        const href = data.get('href');
        return (
          <a {...attributes} href={href}>
            {children}
          </a>
        );
      }
      case 'image': {
        const src = node.data.get('src');
        return (
          <InsertedImage
            src={src}
            alt="Afbeelding in het groot bekijken"
            isSelected={isSelected}
            onClick={() => this.setState({ imageModalSource: src })}
            {...attributes}
          />
        );
      }
      default:
        return <p {...attributes}>{children}</p>;
    }
  }
  renderInline(props) {
    const { attributes, children, node } = props;
    switch (node.type) {
      case 'link': {
        const { data } = node;
        const href = data.get('href');
        return (
          <a {...attributes} href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      }
      default:
        return <p {...attributes}>{children}</p>;
    }
  }
  renderActionButton(fn, icon, title) {
    return (
      <StyledToolbarButton onMouseDown={event => fn(event)} title={title}>
        <Icon icon={icon} solid fill="grayDark" width="1em" />
      </StyledToolbarButton>
    );
  }
  renderBlockButton(type, icon, title, activeIcon) {
    if (!type) return;
    let _activeIcon = activeIcon;
    if (!_activeIcon) _activeIcon = icon;
    let isActive = this.hasBlock(type);
    if (['numbered-list', 'bulleted-list'].includes(type)) {
      const {
        value: { document, blocks },
      } = this.state;
      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key);
        isActive = this.hasBlock('list-item') && parent && parent.type === type;
      }
    }
    return (
      <StyledToolbarButton
        buttonBgColor={isActive ? 'grayDarker' : 'white'}
        onMouseDown={event => this.handleBlock(event, type)}
        data-active={isActive}
        isActive={isActive}
        title={title}
      >
        <Icon
          icon={isActive ? _activeIcon : icon}
          fill={isActive ? 'white' : 'grayDark'}
          hoverColor="grayDark"
          solid
          width="1em"
        />
      </StyledToolbarButton>
    );
  }
  renderColorButton(type, icon, title, activeIcon) {
    if (!type) return;
    let _activeIcon = activeIcon;
    if (!_activeIcon) _activeIcon = icon;
    const { value } = this.state;
    const colors =
      type === 'highlight'
        ? ['green', 'gold', 'orange', 'red', 'blue', 'purple']
        : ['green', 'gold', 'orange', 'red', 'blue', 'purple', 'gray', 'white'];
    const isActive = this.hasMark(type);
    const activeColor =
      isActive &&
      value.activeMarks
        .filter(mark => mark.getIn(['data', type]))
        .map(mark => mark.getIn(['data', type]))
        .first();
    const colorBlocks = (
      <>
        {colors.map(color => (
          <ColorBlock
            key={color}
            color={themify(color)}
            onMouseDown={event =>
              this.handleUpdateMark(event, type, { type, data: { [type]: color } })
            }
          />
        ))}
        {isActive && (
          <ColorBlock
            color="reset"
            onMouseDown={() => {
              value.activeMarks
                .filter(mark => mark.type === type)
                .map(prevMark => this.editor.removeMark(prevMark));
            }}
          />
        )}
      </>
    );
    return (
      <Tooltip trigger="click" content={colorBlocks} theme={this.props.theme}>
        <StyledToolbarButton
          buttonBgColor={isActive && activeColor ? activeColor : 'white'}
          data-active={isActive}
          isActive={isActive}
          title={title}
        >
          <Icon
            icon={isActive ? _activeIcon : icon}
            fill={isActive ? (activeColor === 'white' ? 'grayLight' : 'white') : 'grayDark'}
            solid
            width="1em"
          />
        </StyledToolbarButton>
      </Tooltip>
    );
  }
  renderLinkButton(title) {
    const isActive = this.hasLink();
    return (
      <StyledToolbarButton
        buttonBgColor={isActive ? themify('grayDarker') : 'white'}
        onMouseDown={event => this.handleLink(event, 'link')}
        data-active={isActive}
        isActive={isActive}
        title={title}
      >
        <Icon
          icon={isActive ? LinkOff : Link}
          solid
          fill={isActive ? 'white' : 'grayDark'}
          width="1em"
        />
      </StyledToolbarButton>
    );
  }
  renderMarkButton(type, icon, title, activeIcon) {
    if (!type) return;
    let _activeIcon = activeIcon;
    if (!_activeIcon) _activeIcon = icon;
    const isActive = this.hasMark(type);
    return (
      <StyledToolbarButton
        buttonBgColor={isActive ? themify('grayDarker') : 'white'}
        onMouseDown={event => this.handleMark(event, type)}
        data-active={isActive}
        isActive={isActive}
        title={title}
      >
        <Icon
          icon={isActive ? _activeIcon : icon}
          solid
          fill={isActive ? 'white' : 'grayDark'}
          width="1em"
        />
      </StyledToolbarButton>
    );
  }
  unwrapLink(editor) {
    editor.unwrapInline('link');
  }
  wrapLink(editor, url) {
    editor.wrapInline({
      type: 'link',
      data: { href: url },
    });
  }
  render() {
    const {
      autoCorrect,
      autoFocus,
      bgColor,
      color,
      placeholder,
      plugins,
      readOnly,
      spellCheck,
      style,
      topic,
      houseId,
      adviceText,
      ...cardProps
    } = this.props;
    const { imageModalSource, isImagePickerOpen, value } = this.state;
    return (
      <>
        <StyledWrapper
          bgColor={themify(bgColor)}
          readOnly={readOnly}
          p={0}
          {...cardProps}
          value={null}
        >
          {!readOnly && (
            <StyledToolbar>
              {this.renderMarkButton('bold', FormatBold, 'Dikgedrukt')}
              {this.renderMarkButton('italic', FormatItalic, 'Schuingedrukt')}
              {this.renderMarkButton('underlined', FormatUnderline, 'Onderstrepen')}
              {this.renderMarkButton('super', Superscript, 'Superscript')}
              {this.renderMarkButton('sub', Subscript, 'Subscript')}
              {this.renderMarkButton('code', Code, 'Code invoegen')}
              {this.renderColorButton('color', Palette, 'Tekstkleur')}
              {this.renderColorButton('highlight', TypeSquare, 'Markeren')}
              {this.renderLinkButton('Link invoegen')}
              {this.renderActionButton(this.handleImage, ImageIcon, 'Afbeelding invoegen')}
              {this.renderBlockButton('heading-one', OneSquare, 'Kop 1')}
              {this.renderBlockButton('heading-two', TwoSquare, 'Kop 2')}
              {this.renderBlockButton('block-quote', QuoteOpen, 'Quote')}
              {this.renderBlockButton('numbered-list', ListNumbered, 'Genummerde lijst')}
              {this.renderBlockButton('bulleted-list', List, 'Bullet points')}
              {this.renderActionButton(this.handleUndo, Reply, 'Ongedaan maken')}
              {this.renderActionButton(this.handleRedo, Send, 'Opnieuw doen')}
            </StyledToolbar>
          )}
          <StyledEditor
            bgColor={themify(bgColor)}
            color={themify(color)}
            readOnly={readOnly}
            onClick={() => this.editor.focus()}
          >
            <Editor
              autoCorrect={autoCorrect}
              autoFocus={autoFocus}
              // onBlur={this.handleChange} // TODO: Maybe switch to using state and onBlur
              onChange={this.handleChange}
              onDrop={this.handleDropOrPaste}
              onPaste={this.handleDropOrPaste}
              placeholder={placeholder}
              plugins={plugins}
              renderBlock={this.renderBlock}
              renderMark={this.renderMark}
              renderInline={this.renderInline}
              readOnly={readOnly}
              ref={x => (this.editor = x)}
              schema={SCHEMA}
              spellCheck={spellCheck}
              style={style}
              value={value}
              className={readOnly ? 'readonly' : ''}
            />
          </StyledEditor>
        </StyledWrapper>
        {imageModalSource && (
          <ImageModal
            closeModal={() => this.setState({ imageModalSource: null })}
            isOpen={Boolean(imageModalSource)}
            src={imageModalSource}
          />
        )}
        {isImagePickerOpen && (
          <UploadPickerModal
            isOpen={Boolean(isImagePickerOpen)}
            closeModal={() => this.setState({ isImagePickerOpen: false })}
            onPick={this.handleInsertImageFile}
            houseId={houseId}
            topic={topic}
            adviceText={adviceText}
          />
        )}
      </>
    );
  }
}

WYSIWYG.propTypes = {
  autoCorrect: PropTypes.bool,
  autoFocus: PropTypes.bool,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  handleSave: PropTypes.func,
  operatorId: PropTypes.string,
  placeholder: PropTypes.string,
  plugins: PropTypes.array,
  readOnly: PropTypes.bool,
  spellCheck: PropTypes.bool,
  topic: PropTypes.string.isRequired,
  houseId: PropTypes.string,
};

WYSIWYG.defaultProps = {
  autoCorrect: false,
  autoFocus: false,
  bgColor: 'white',
  color: 'grayBlack',
  plugins: PLUGINS,
  readOnly: false,
  spellCheck: true,
};

export default withApollo(withSpacing(WYSIWYG));
