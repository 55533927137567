import { gql, useQuery } from '@apollo/client';
import { Box, Card, Flex, Icon, Modal, Spinner } from '@energiebespaarders/symbols';
import { Red } from '@energiebespaarders/symbols/helpers';
import { Check, Info, PaperAirplane } from '@energiebespaarders/symbols/icons/solid';
import React, { useMemo } from 'react';
import {
  quoteFinalization,
  quoteFinalizationVariables,
} from '../../../types/generated/quoteFinalization';
import { FinalizationInformation } from './QuoteStatusButtons';

const QUOTE_FINALIZATION = gql`
  query quoteFinalization($quoteId: ID!) {
    quoteById(quoteId: $quoteId) {
      id
      deal {
        id
        hasHadAPTC
      }
      ptc {
        id
        type
        completed
        isSent
        currentPlanning {
          sent
          plannedFor
          suspended
        }
        installerSignoff {
          jobId
          type
          markedAsComplete {
            date
          }
        }
      }
    }
  }
`;

type Props = {
  closeModal: () => void;
  mobile: boolean;
  loading: boolean;
  isOpen: boolean;
  quoteId: string;
  submit: (finalizationInfo: FinalizationInformation) => void;
};

type Status = 'hasHadAPTC' | 'noPTCRequired' | 'ptcSentButNotCompleted' | 'ptcResultsNotConfirmed';

const FinalizationModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  mobile,
  submit,
  quoteId,
  loading,
}) => {
  const { data, loading: dataLoading, error } = useQuery<
    quoteFinalization,
    quoteFinalizationVariables
  >(QUOTE_FINALIZATION, {
    variables: {
      quoteId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const quote = data?.quoteById;

  const status = useMemo((): Status => {
    // The PTC has happened and has been confirmed
    if (quote?.deal.hasHadAPTC) return 'hasHadAPTC';
    // A PTC has been sent and schouwresults have been filled in, but not confirmed by DEB
    if (quote?.ptc?.installerSignoff?.markedAsComplete) {
      return 'ptcResultsNotConfirmed';
    }
    // A PTC has been sent to the installer but has no signoff (schouwresults)
    if (quote?.ptc?.isSent && !quote.ptc.currentPlanning!.suspended) {
      return 'ptcSentButNotCompleted';
    }
    // No PTC has been sent (so we assume there doesnt need to be one if you are making the quote final)
    return 'noPTCRequired';
  }, [quote]);

  // If there has been an IA schouwdoc filled in but it hasn't been confirmed yet,
  // go there. Otherwise go to the job page where you can fill in the PTC results
  // manually
  const ptcLink =
    status === 'ptcResultsNotConfirmed'
      ? `/schouw-resultaten/${quote?.ptc?.id}`
      : status === 'ptcSentButNotCompleted'
      ? `/opdracht/${quoteId}/schouw`
      : '';

  const buttons = useMemo(() => {
    const makeFinalWithoutEmail = {
      label: 'Definitief maken zonder e-mailbevestiging',
      iconStart: Check,
      bgColor: 'orange',
      onClick: () => submit({ sendCustomerEmail: false }),
      disabled: loading,
      loading,
    };
    const makeFinalWithoutPTC = {
      ...makeFinalWithoutEmail,
      label: 'Definitief maken zonder schouw',
    };
    const makeFinalWithEmail = {
      label: 'Definitief maken met e-mailbevestiging',
      bgColor: 'blue',
      iconStart: PaperAirplane,
      onClick: () => submit({ sendCustomerEmail: true }),
      disabled: loading,
      loading,
    };

    // If the PTC is finished then give option to notify customer or not
    if (status === 'hasHadAPTC') {
      return [makeFinalWithoutEmail, makeFinalWithEmail];
    }

    // Info needs to be filled in before proceeding
    if (status === 'ptcResultsNotConfirmed' || status === 'ptcSentButNotCompleted') {
      return [];
    }

    // There has been a PTC and it needs to be confirmed
    if (status === 'noPTCRequired') {
      return [makeFinalWithoutPTC];
    }
  }, [status, loading, submit]);

  if (error) return <Red>{error}</Red>;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      mobile={mobile}
      title="Offerte definitief maken"
      buttons={buttons}
    >
      {dataLoading || !quote ? (
        <Spinner />
      ) : (
        <>
          <Box width={1} mb={3}>
            {status === 'hasHadAPTC' ? (
              <>
                <p>Wil je een e-mailbevestiging sturen naar de klant?</p>
                <p>
                  Hierin vermelden we dat uit de schouw is gebleken dat er geen aanpassingen nodig
                  zijn voor de offerte en dat de installateur binnenkort een afspraak zal inplannen.
                  {/* TODO: Combine with sendJob button. The finalization email contains almost the same info */}
                </p>
              </>
            ) : status === 'ptcResultsNotConfirmed' || status === 'ptcSentButNotCompleted' ? (
              <Card bgColor="orange" color="white">
                <Flex>
                  <Box pr={2}>
                    <Icon icon={Info} width="1rem" solid />
                  </Box>
                  <Box py={0.1}>
                    De schouw is nog niet bevestigd voor deze offerte.
                    <br />
                    Als er wel een schouw is geweest, dan kun je{' '}
                    <a
                      target="_blank"
                      href={ptcLink}
                      rel="noreferrer"
                      style={{ color: 'white', textDecoration: 'underline' }}
                    >
                      hier
                    </a>{' '}
                    de schouwresultaten bevestigen
                  </Box>
                </Flex>
              </Card>
            ) : (
              <p>
                Er is bij deze deal nog geen schouwopdracht verstuurd. <br /> Weet je zeker dat je
                deze offerte definitief wilt maken zonder schouw?
              </p>
            )}
          </Box>
        </>
      )}
    </Modal>
  );
};

export default FinalizationModal;
