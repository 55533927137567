import { Box, Button } from '@energiebespaarders/symbols';
import { Red } from '@energiebespaarders/symbols/helpers';
import React, { Component, ReactNode } from 'react';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error?: string;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: `${error.name}: ${error.message}` };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box p={4}>
          <p>
            <Red>Er is iets misgegaan.</Red>
          </p>

          {this.state.error && (
            <pre>
              <Red>{this.state.error}</Red>
            </pre>
          )}

          {/* Not using a Symbols Button here, since that might have been a source of the error */}
          <Button onClick={() => window.location.reload()}>
            Klik hier om de pagina te herladen
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
