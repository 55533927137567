import { SolutionDomain, SOLUTION_DOMAIN_TOPICS } from '@energiebespaarders/constants';
import { Box, Button, Flex, Separator, Toast } from '@energiebespaarders/symbols';
import { SkipForward } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useActiveHouseId } from '../hooks/useActiveHouseId';
import { themify } from '../styles/mixins';
import IntakeIncompletionModal from './modals/IntakeIncompletionModal';

export const LinkAlert: React.FC<{ message: string; linkUrl: string; linkLabel: string }> = ({
  message,
  linkUrl,
  linkLabel,
}) => (
  <Flex justifyContent="space-between" flexWrap="wrap" width="100%">
    <Box mr={1}>{message}</Box>
    <Box>
      <Link to={linkUrl} style={{ color: themify('redDark') }}>
        <strong>{linkLabel}</strong>
      </Link>
    </Box>
  </Flex>
);

const AlertWidgets: Record<string, React.FC<{ message: string }>> = {
  'huidige aantal fases': function PhasesWarningWidget({ message }) {
    // TODO: would be cool to show an inline question here, just needs 1 and 3 as radio buttons
    // maybe even just pass the question key like "[question=situation.electrics.transformerPhases]"
    const { activeHouseId } = useActiveHouseId();
    return (
      <LinkAlert
        message={message}
        linkUrl={`/house/${activeHouseId}/intake/pvSystem#situation.electrics.transformerPhases-question`}
        linkLabel="Ga naar de opname →"
      />
    );
  },
  'huidige stroomsterkte': function CurrentWarningWidget({ message }) {
    const { activeHouseId } = useActiveHouseId();
    return (
      <LinkAlert
        message={message}
        linkUrl={`/house/${activeHouseId}/intake/pvSystem#situation.electrics.transformerCurrent-question`}
        linkLabel="Ga naar de opname →"
      />
    );
  },
  // '3-fase hoofdschakelaar': function ThreePhaseProductWidget({ message }) {
  //   TODO: Would be cool to add a button to add the product immediately
  //   const searchForProduct = () => {
  //     const elem = document.getElementById('installatron-product-input');
  //     console.log(elem);
  //     if (elem) {
  //       elem.focus();
  //       elem.click();
  //       setTimeout(() => {
  //         (elem as HTMLInputElement).value = '3-fase hoofdschakelaar';
  //       }, 50);
  //     }
  //   };
  //   return (
  //     <span>
  //       {message} <Button onClick={searchForProduct}>Zoek product</Button>
  //     </span>
  //   );
  // },
  'opname-onderdeel is nog niet ingevuld voor een offerte': function IntakeNotForQuote({
    message,
  }) {
    const { activeHouseId } = useActiveHouseId();
    const solutionDomain = window.location.pathname.split('/').pop();
    const topic = SOLUTION_DOMAIN_TOPICS[solutionDomain as SolutionDomain];
    return (
      <LinkAlert
        message={message}
        linkUrl={`/house/${activeHouseId}/intake/${topic || ''}`}
        linkLabel="Ga naar de opname →"
      />
    );
  },
  'Dit opname-onderdeel is nog niet afgerond': function IntakeIncomplete({ message }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <Flex flexWrap="wrap" justifyContent="space-between" width="100%">
        <Box>{message}</Box>
        <Box>
          <Button iconStart={SkipForward} onClick={() => setIsOpen(true)}>
            Laat me offertes maken!
          </Button>
        </Box>
        <IntakeIncompletionModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      </Flex>
    );
  },
  // TODO: this would be nice for Sales to know why IPs aren't active
  // Would need to set up a InstallatronContext in order to pass in data about the quote/installer
  // 'op inactief': function InactiveInstaller({ message, installerId }) {
  //   return (
  //     <LinkAlert
  //       message={message}
  //       linkUrl={`/installer/${installerId}`}
  //       linkLabel="Ga naar de IP →"
  //     />
  //   );
  // },
  // 'op pauze': function PausedInstaller({ message, installerId }) {
  //   return (
  //     <LinkAlert
  //       message={message}
  //       linkUrl={`/installer/${installerId}`}
  //       linkLabel="Ga naar de IP →"
  //     />
  //   );
  // },
};

const Alerts = ({
  warnings,
  errors,
  title,
}: {
  warnings: readonly string[];
  errors: readonly string[];
  title?: string;
}) => {
  if (warnings.length === 0 && errors.length === 0) return null;
  return (
    <>
      {title && <Separator text={title} my={2} />}
      {warnings
        .filter(w => !!w)
        .map(warning => {
          const widgetEntry = Object.entries(AlertWidgets).find(([key]) => warning.includes(key));
          const Widget = widgetEntry?.[1];
          return (
            <Toast
              type="warning"
              message={Widget ? <Widget message={warning} /> : warning}
              key={'warn' + warning}
              width="100%"
            />
          );
        })}
      {errors
        .filter(e => !!e)
        .map(error => {
          const widgetEntry = Object.entries(AlertWidgets).find(([key]) => error.includes(key));
          const Widget = widgetEntry?.[1];
          return (
            <Toast
              type="error"
              message={Widget ? <Widget message={error} /> : error}
              key={'error' + error}
              width="100%"
            />
          );
        })}
    </>
  );
};

export default Alerts;
