import { useMutation, useQuery } from '@apollo/client';
import { Gallery, Placeholder } from '@energiebespaarders/symbols';
import React, { useCallback } from 'react';
import { fileUrl } from '../config';
import { DELETE_PRODUCT_FILE, GET_PRODUCT_FILES } from '../queries/files';
import {
  deleteProductFile,
  deleteProductFileVariables,
} from '../types/generated/deleteProductFile';
import { getProductFiles, getProductFilesVariables } from '../types/generated/getProductFiles';
import { ProductCategory } from '../types/graphql-global-types';

interface ProductGalleryProps {
  placeholderText?: string;
  productId: string;
  productCategory?: string;
  readOnly?: boolean;
  tags: string[];
  thumbSize?: number;
  onDelete?: () => void;
}

const ProductGallery: React.FC<ProductGalleryProps> = ({
  onDelete,
  placeholderText = "Er zijn nog geen productfoto's toegevoegd",
  productId,
  productCategory,
  readOnly,
  tags,
  thumbSize = 100,
}) => {
  const { data, loading, error } = useQuery<getProductFiles, getProductFilesVariables>(
    GET_PRODUCT_FILES,
    {
      variables: { productId },
      skip: !productId,
    },
  );

  const [deleteProductFileMutation] = useMutation<deleteProductFile, deleteProductFileVariables>(
    DELETE_PRODUCT_FILE,
  );

  const deleteFile = useCallback(
    (fileId: string) => {
      deleteProductFileMutation({
        variables: {
          id: productId,
          fileId,
        },
        update: store => {
          const queryData = store.readQuery<getProductFiles, getProductFilesVariables>({
            query: GET_PRODUCT_FILES,
            variables: { productId },
          });
          if (queryData) {
            const newFiles = queryData.productById?.files.filter(file => file.id !== fileId);
            store.writeQuery({
              query: GET_PRODUCT_FILES,
              data: {
                productById: {
                  ...queryData.productById,
                  files: newFiles,
                },
              },
              variables: { productId },
            });
          }
          onDelete?.();
        },
      });
    },
    [deleteProductFileMutation, onDelete, productId],
  );
  if (loading || error) return <Placeholder error={error} />;
  return (
    <Gallery
      deleteFile={readOnly ? undefined : deleteFile}
      exclusiveMatch={productCategory !== ProductCategory.CompletePvSystem}
      files={data?.productById?.files ?? []}
      fileUrl={fileUrl}
      gutter={1}
      placeholderText={placeholderText}
      size="ss"
      tags={tags}
      thumbSize={thumbSize}
    />
  );
};

export default ProductGallery;
