import { Energiepaspoort, Logomark } from '@energiebespaarders/symbols/icons/custom';
import {
  CalendarAlt,
  Cancel,
  Database,
  GameControllerAlt,
  HouseDoor,
  Mail,
  MeasuringTape,
  Right,
  Search,
  Tag,
  Ticket,
  TriangleMeasure,
  User,
  Wrenches,
} from '@energiebespaarders/symbols/icons/solid';
import { rgba } from 'polished';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useNavHelpers } from '../../hooks/useNavHelpers';
import { useRVH } from '../../hooks/useRVH';
import { useWindowSize } from '../../hooks/useWindowSize';
import { breakpointsPx } from '../../styles/breakpoints';
import theme from '../../styles/theme';
import HelpMenu from './HelpMenu';
import LeftNav from './LeftNav';
import RightNav from './RightNav';
import {
  ContentContainer,
  NavBar,
  NavBarIcon,
  NavLogo,
  SearchBar,
  SearchContainer,
  SearchResults,
  TitleBar,
} from './index';

export interface ISideNavItem {
  side: string;
  label: string;
  icon: JSX.Element;
  to?: string;
  exact?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  height?: string;
}

interface MaskProps {
  $offsetTop: string;
  $zIndex: number;
}

const Mask = styled.div<MaskProps>`
  position: fixed;
  top: ${x => x.$offsetTop};
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: ${x => x.$zIndex};
  outline: none;
  background: ${x => rgba(x.theme.colors.grayBlack, 0.75)};
  cursor: pointer;
  transition: all 0.25s;
`;

const StyledNavigation = styled.div<{ $paddingBottom: string }>`
  width: 100%;
  height: 100%;
  transition: margin 0.2s ${x => x.theme.curves.standard};
  z-index: ${x => x.theme.z.nav};
  padding-bottom: ${x => x.$paddingBottom};
`;

export const Navigation: React.FC = ({ children }) => {
  const location = useLocation();
  const windowWidth = useWindowSize().width;
  const narrowScreen = useMemo(() => windowWidth < breakpointsPx.md, [windowWidth]);
  const rvh = useRVH();
  const {
    searchActive,
    setSearchActive,
    leftNavOpen,
    setLeftNavOpen,
    helpMenuState,
    setHelpMenuState,
    rightNavState,
    setRightNavState,
    closeRightNav,
  } = useNavHelpers();

  const [searchString, setSearchString] = useState('');

  const toggleSearch = useCallback(() => {
    if (setSearchActive) setSearchActive(!searchActive);
  }, [searchActive, setSearchActive]);

  const navIcon = useMemo(() => {
    const path = location.pathname;
    if (narrowScreen) {
      if (searchActive) return Search;
      if (path.includes('/products')) return Database;
      if (path.includes('/customer')) return User;
      if (path.includes('/labels')) return Tag;
      if (path.includes('/email')) return Mail;
      if (path.includes('/installatron')) return Wrenches;
      if (path.includes('/intake')) return MeasuringTape;
      if (path.includes('/advice')) return Energiepaspoort;
      if (path.includes('/calendar')) return CalendarAlt;
      if (path.includes('/obama')) return TriangleMeasure;
      if (path.includes('/controle')) return GameControllerAlt;
      if (path.includes('/coupons')) return Ticket;
      if (path.includes('/house')) return HouseDoor;
    }
    return Logomark;
  }, [location.pathname, narrowScreen, searchActive]);

  const handleLiveSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const cleanedValue = e.target.value.replace(/[^a-zA-Z0-9 @.,+-_]/g, '');
      setSearchString(cleanedValue);
      if (!searchActive && setSearchActive) setSearchActive(true);
    },
    [setSearchString, searchActive, setSearchActive],
  );

  const leftNavWidth = useMemo(
    () =>
      leftNavOpen
        ? theme.fixedSizes.leftNavOpened
        : narrowScreen
        ? '0px'
        : theme.fixedSizes.leftNavCollapsed,
    [leftNavOpen, narrowScreen],
  );
  const rightNavWidth = useMemo(
    () =>
      rightNavState.isOpen
        ? theme.fixedSizes.rightNavOpened
        : narrowScreen
        ? '0px'
        : theme.fixedSizes.rightNavCollapsed,
    [narrowScreen, rightNavState.isOpen],
  );

  const helpMenuWidth = useMemo(() => {
    if (windowWidth >= breakpointsPx.md && windowWidth < breakpointsPx.lg) return '50%';
    if (windowWidth >= breakpointsPx.lg && windowWidth < breakpointsPx.xl) return '33%';
    if (windowWidth >= breakpointsPx.xl) return '25%';
    return '100%';
  }, [windowWidth]);

  const navMaskActive = useMemo(() => (leftNavOpen && narrowScreen) || rightNavState.isOpen, [
    leftNavOpen,
    narrowScreen,
    rightNavState.isOpen,
  ]);

  const searchMaskActive = useMemo(() => Boolean(searchActive && (narrowScreen || searchString)), [
    narrowScreen,
    searchActive,
    searchString,
  ]);

  return (
    <StyledNavigation $paddingBottom={`calc(${100 * rvh}px + ${theme.urlBarHeight()}px)`}>
      {(navMaskActive || searchMaskActive) && (
        <Mask
          $offsetTop={theme.fixedSizes.navHeight}
          $zIndex={navMaskActive ? theme.z.navMask : 1}
          onClick={() => {
            if (narrowScreen && !rightNavState.isOpen) setLeftNavOpen?.(false);
            closeRightNav();
            setHelpMenuState?.({ isOpen: false });
            setSearchActive?.(false);
          }}
        />
      )}
      <NavLogo
        height={theme.fixedSizes.navHeight}
        iconSize={narrowScreen ? '32px' : '48px'}
        navIcon={navIcon}
        showText={!narrowScreen && leftNavOpen}
        toggleLeftNav={
          !(narrowScreen && searchActive) ? () => setLeftNavOpen?.(!leftNavOpen) : undefined
        }
        translateX={rightNavState.isOpen ? `-${rightNavWidth}` : '0'}
        width={narrowScreen ? theme.fixedSizes.leftNavCollapsed : leftNavWidth}
      />

      <LeftNav narrowScreen={narrowScreen} />

      <NavBar
        height={theme.fixedSizes.navHeight}
        narrowScreen={narrowScreen}
        offsetLeft={narrowScreen ? theme.fixedSizes.leftNavCollapsed : leftNavWidth}
        offsetRight={!narrowScreen && helpMenuState.isOpen ? helpMenuWidth : '0px'}
        transform={rightNavState.isOpen && !helpMenuState.isOpen ? `-${rightNavWidth}` : '0'}
      >
        <SearchBar
          data-tour="searchBar"
          handleLiveSearch={handleLiveSearch}
          narrowScreen={narrowScreen}
          searchString={searchString}
          visible={narrowScreen ? searchActive : true}
        />
        <TitleBar height={theme.fixedSizes.navHeight} />
        {narrowScreen && !leftNavOpen && (
          <NavBarIcon
            icon={searchActive ? Cancel : Search}
            hoverColor={searchActive ? 'red' : 'green'}
            onClick={toggleSearch}
            zIndex={theme.z.maskedNav + 10}
          />
        )}
        {(!narrowScreen || (narrowScreen && !searchActive) || (narrowScreen && !leftNavOpen)) && (
          <NavBarIcon
            icon={Right}
            onClick={() => {
              setRightNavState?.({ isOpen: !rightNavState.isOpen });
              setHelpMenuState?.({ isOpen: false });
            }}
            data-tour="rightNavToggle"
          />
        )}
      </NavBar>

      <RightNav narrowScreen={narrowScreen} />

      <ContentContainer
        masked={searchMaskActive}
        leftNavOpen={leftNavOpen}
        narrowScreen={narrowScreen}
        offsetTop={theme.fixedSizes.navHeight}
        offsetLeft={narrowScreen ? '0px' : leftNavWidth}
        offsetRight={!narrowScreen && helpMenuState.isOpen ? helpMenuWidth : '0px'}
        translateX={rightNavState.isOpen && !helpMenuState.isOpen ? `-${rightNavWidth}` : '0'}
      >
        {children}
      </ContentContainer>

      <HelpMenu width={helpMenuWidth} />

      {searchActive && (
        <SearchContainer
          narrowScreen={narrowScreen}
          offsetLeft={narrowScreen ? '0px' : leftNavWidth}
          offsetTop={theme.fixedSizes.navHeight}
          searchString={searchString}
          toggleSearch={toggleSearch}
          translateX={rightNavState.isOpen ? `-${rightNavWidth}` : '0'}
          visible={searchActive && (narrowScreen || !!searchString)}
        >
          <SearchResults
            narrowScreen={narrowScreen}
            toggleSearch={toggleSearch}
            searchString={searchString}
          />
        </SearchContainer>
      )}
    </StyledNavigation>
  );
};

export default Navigation;
