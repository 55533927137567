// Checks whether a value is not null or undefined, and TypeScript will infer the correct type.
// Useful for filtering an array where some elements might be empty
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
/**
 * Removes the readonly properties recursively from a type.
 * Useful for the generated Apollo types in case you construct type object of that type yourself
 */
export type Writable<T> = { -readonly [P in keyof T]: Writable<T[P]> };
export type WritableOpt<T> = { -readonly [P in keyof T]-?: T[P] };

/**
 * Returns the values of an enum as an array. Works for both string and number enums.
 * The typing may permit more arguments than just enums, but don't use it for that..
 *
 * *NOTE:* Keep it in sync with the methods in GQL and Chili too!
 *
 * @param enumm an enum. Can be a numeric enum or string enum.
 */
export function enumValues<T extends { [key: number]: string | number }>(
  enumm: T,
): Array<T[keyof T]> {
  const keys = Object.keys(enumm).filter(key => isNaN(Number(key)));
  return keys.map(key => enumm[key as keyof T]);
}

/**
 * Returns all keys of a type union
 * https://stackoverflow.com/questions/49401866/all-possible-keys-of-an-union-type
 */
export type KeysOfUnion<T> = T extends T ? keyof T : never;
