import { gql, useQuery } from '@apollo/client';
import {
  INTAKE_TOPIC_SOLUTIONS,
  IntakeTopic,
  SOLUTION_DOMAINS_NL,
  SolutionDomain,
  getSolutionsFromDomain,
} from '@energiebespaarders/constants';
import { Accordion, Box, Card, Flex, Icon, Spacer } from '@energiebespaarders/symbols';
import { Medium, StrikeThrough } from '@energiebespaarders/symbols/helpers';
import { Alert, CheckCircle, CheckSquare, Checkbox } from '@energiebespaarders/symbols/icons/solid';
import { lighten } from 'polished';
import React, { useMemo } from 'react';
import { AdviceTextsFragment, FileFragment, NeedsFragment } from '../../fragments';
import { IntakeProgressFragment } from '../../fragments/Intake';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import { themify } from '../../styles/mixins';
import { SolutionAdvice } from '../../types/generated/SolutionAdvice';
import {
  installatronChecklist,
  installatronChecklistVariables,
  installatronChecklist_house,
} from '../../types/generated/installatronChecklist';

const INSTALLATRON_CHECKLIST = gql`
  ${FileFragment}
  ${NeedsFragment}
  ${AdviceTextsFragment}
  ${IntakeProgressFragment}
  query installatronChecklist($houseId: ID!) {
    house(id: $houseId) {
      id
      intake {
        id
        ...Progress
        walls {
          rc
        }
        floor {
          rc
        }
        roof {
          rc
        }
      }
      situation {
        id
        consumptionAvailable
        consumption {
          gas
          electricity
        }
      }
      quotes {
        id
        solution
        isAdvised
      }
      advice {
        id
        ...AdviceTexts
      }
      customer {
        id
        needs {
          ...Needs
        }
      }
      files {
        ...File
      }
    }
  }
`;

function getTodos(
  solutionDomain: SolutionDomain,
  translatedSolution: string,
  house?: installatronChecklist_house,
) {
  if (!house) return [];
  const {
    files,
    quotes,
    customer,
    intake: { walls, floor, roof, progress },
    situation: { consumption, consumptionAvailable },
    advice,
  } = house;

  const profilePhotoUploaded = files.some(file => file.metadata.tags.includes('profile'));
  const energyBillUploaded = files.some(file =>
    file.metadata.tags.map(tag => tag.includes('energyBill')),
  );

  const customerNeedsDone =
    customer && customer.needs
      ? Object.values(customer.needs)
          .filter(answer => typeof answer !== 'object')
          .every(answer => Boolean(answer))
      : false;
  const solutionDomainHasQuotes =
    quotes.filter(quote => getSolutionsFromDomain(solutionDomain).includes(quote.solution)).length >
    0;
  const firstSolution = getSolutionsFromDomain(solutionDomain)[0];
  const intakeTopic = Object.entries(INTAKE_TOPIC_SOLUTIONS).find(([, solutions]) =>
    solutions.includes(firstSolution),
  )?.[0] as Exclude<IntakeTopic, IntakeTopic.Photos>;
  const todos = [
    { task: 'Profielfoto woning instellen', done: profilePhotoUploaded },
    { task: 'Energienota uploaden', done: energyBillUploaded },
    {
      task: `Opname voor ${translatedSolution} compleet`,
      done: progress[intakeTopic].value === 1,
    },
    { task: 'Wensen klant in kaart', done: customerNeedsDone },
    { task: 'Rc-waardes ingevuld', done: walls.rc && floor.rc && roof.rc },
    {
      task:
        consumptionAvailable && !consumptionAvailable.includes('yes')
          ? 'Verbruik niet bekend'
          : 'Verbruik ingevuld',
      done: consumptionAvailable?.includes('yes') || (consumption.gas && consumption.electricity),
    },
    {
      task: `Offerte opstellen voor ${translatedSolution}`,
      done: solutionDomainHasQuotes,
    },
  ];
  if (advice?.id) {
    todos.push({
      task: `Advies over ${translatedSolution} geschreven`,
      done: Boolean(
        (advice as Partial<Record<SolutionDomain, Partial<SolutionAdvice>>>)[solutionDomain]?.text,
      ),
    });
  }
  if (solutionDomainHasQuotes) {
    todos.push({
      task: 'Offerte adviseren',
      done: quotes.filter(quote => quote.isAdvised).length > 0,
    });
  }
  return todos;
}

interface InstallatronChecklistProps {
  isNarrow: boolean;
  solutionDomain: SolutionDomain;
}
const InstallatronChecklist: React.FC<InstallatronChecklistProps> = ({
  isNarrow,
  solutionDomain,
}) => {
  const { activeHouseId } = useActiveHouseId();
  const { data } = useQuery<installatronChecklist, installatronChecklistVariables>(
    INSTALLATRON_CHECKLIST,
    { variables: { houseId: activeHouseId } },
  );
  const todos = useMemo(
    () => getTodos(solutionDomain, SOLUTION_DOMAINS_NL[solutionDomain].toLowerCase(), data?.house),
    [data?.house, solutionDomain],
  );
  const amountTodos = todos.length;
  const amountDone = todos.filter(todo => todo.done).length;
  const amountRemaining = amountTodos - amountDone;
  const statusColor = useMemo(() => {
    if (amountRemaining <= amountTodos / 2) {
      return 'orange';
    }
    if (amountRemaining === 0) {
      return 'green';
    }
    if (amountRemaining > amountTodos / 2) {
      return 'red';
    }
  }, [amountRemaining, amountTodos]);
  const statusIcon = useMemo(() => {
    if (amountRemaining <= amountTodos / 2) {
      return Alert;
    }
    if (amountRemaining === 0) {
      return CheckCircle;
    }
    if (amountRemaining > amountTodos / 2) {
      return Alert;
    }
  }, [amountRemaining, amountTodos]);
  return (
    <Card p={0} mb={4} style={{ overflowY: 'hidden' }}>
      <Accordion
        baseBgColor={themify(statusColor)}
        baseColor="white"
        borderColor={themify(statusColor)}
        contentBgColor={lighten(0.1, themify(statusColor))}
        contentBorderColor={themify(statusColor)}
        contentColor="white"
        defaultOpen={amountRemaining > 0}
        icon={statusIcon}
        iconSize="24px"
        title={
          amountRemaining === 0
            ? `Goed werk! ${amountDone} uit ${amountTodos}!`
            : `Nog ${amountRemaining} van ${amountTodos} to-dos`
        }
        titleSize={5}
      >
        <Flex flexWrap="wrap" mx={-2}>
          {todos.map(todo => (
            <Box key={`${todo.task}-${Date.now()}`} width={isNarrow ? 1 : 1 / 2} px={2}>
              <Icon
                icon={todo.done ? CheckSquare : Checkbox}
                solid
                inline
                fill="white"
                height="18px"
                width="18px"
              />
              <Spacer amount={2} />
              {todo.done ? (
                <StrikeThrough strikeColor={themify(statusColor)} width="2px">
                  <Medium>{todo.task}</Medium>
                </StrikeThrough>
              ) : (
                <Medium>{todo.task}</Medium>
              )}
            </Box>
          ))}
        </Flex>
      </Accordion>
    </Card>
  );
};

export default InstallatronChecklist;
