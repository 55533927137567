import { gql, useMutation } from '@apollo/client';
import { useForm } from '@energiebespaarders/hooks';
import { isMissing } from '@energiebespaarders/hooks/useForm';
import { Modal, Select, Textarea } from '@energiebespaarders/symbols';
import { Gray } from '@energiebespaarders/symbols/helpers';
import { Check } from '@energiebespaarders/symbols/icons/solid';
import { containers } from '@energiebespaarders/symbols/styles/theme';
import React from 'react';
import useToaster from '../../../hooks/useToaster';
import { dealsByHouseSolutionDomain_dealsByHouseSolutionDomain } from '../../../types/generated/dealsByHouseSolutionDomain';
import { setDealOnHold, setDealOnHoldVariables } from '../../../types/generated/setDealOnHold';
import { DealOnHoldReason, WaitingFor } from '../../../types/graphql-global-types';
// import { DealOnHoldReasonInput, WaitingFor } from '../../../types/graphql-global-types';

export const SET_DEAL_ON_HOLD = gql`
  mutation setDealOnHold(
    $dealId: ID!
    $waitingFor: WaitingFor!
    $reason: DealOnHoldReason!
    $comment: String!
  ) {
    setDealOnHold(dealId: $dealId, waitingFor: $waitingFor, reason: $reason, comment: $comment) {
      id
      onHold {
        since
        time
        waitingFor
        reason
        comment
      }
      # For the cache:
      status
    }
  }
`;

const waitingOnOptions = [
  {
    label: 'Installateur',
    value: 'installer',
  },
  {
    label: 'Klant',
    value: 'customer',
  },
  {
    label: 'De Energiebespaarders',
    value: 'deb',
  },
];

const dealOnHoldReasonNL: Record<DealOnHoldReason, string> = {
  [DealOnHoldReason.productsNotAvailable]: 'Producten niet op voorraad',
  [DealOnHoldReason.forgotUnfulfilledAppointment]: 'Afspraak vergeten of niet nagekomen',
  [DealOnHoldReason.notPlannedInTime]: 'Niet op tijd ingepland',
  [DealOnHoldReason.delayedDueToOtherWork]: 'Uitloop door andere werkzaamheden',
  [DealOnHoldReason.permitIssues]: 'Problemen met vergunning',
  [DealOnHoldReason.customerNotHome]: 'Was niet thuis',
  [DealOnHoldReason.problemsWithFunding]: 'Problemen met financiering',
  [DealOnHoldReason.noInstallerAvailable]: 'Geen installateur beschikbaar',
  [DealOnHoldReason.setInPipedrive]: 'Opgegeven in Pipedrive',
  [DealOnHoldReason.other]: 'Anders',
};

const installerValues: DealOnHoldReason[] = [
  DealOnHoldReason.productsNotAvailable,
  DealOnHoldReason.forgotUnfulfilledAppointment,
  DealOnHoldReason.notPlannedInTime,
  DealOnHoldReason.other,
];

const customerValues: DealOnHoldReason[] = [
  DealOnHoldReason.delayedDueToOtherWork,
  DealOnHoldReason.permitIssues,
  DealOnHoldReason.customerNotHome,
  DealOnHoldReason.problemsWithFunding,
  DealOnHoldReason.other,
];

const debValues = [DealOnHoldReason.noInstallerAvailable, DealOnHoldReason.other];

const reasonOptions = {
  installer: installerValues.map(v => ({
    label: dealOnHoldReasonNL[v],
    value: v,
  })),
  customer: customerValues.map(v => ({
    label: dealOnHoldReasonNL[v],
    value: v,
  })),
  deb: debValues.map(v => ({
    label: dealOnHoldReasonNL[v],
    value: v,
  })),
};

type OnHoldForm = {
  waitingFor: '' | 'installer' | 'customer' | 'deb';
  reason: DealOnHoldReason;
  comment: string;
};

interface SetDealOnHoldModalProps {
  closeModal: () => void;
  deal: dealsByHouseSolutionDomain_dealsByHouseSolutionDomain;
}

const SetDealOnHoldModal: React.FC<SetDealOnHoldModalProps> = ({ closeModal, deal }) => {
  const toast = useToaster();
  const [setDealOnHoldStatus, { loading }] = useMutation<setDealOnHold, setDealOnHoldVariables>(
    SET_DEAL_ON_HOLD,
    {
      onCompleted: () => {
        toast({ message: 'Deal gepauzeerd', type: 'success' });
        closeModal();
      },
      onError: e => {
        toast({ message: 'Er is iets mis gegaan: ' + e.name, type: 'error' });
        closeModal();
      },
    },
  );

  const { formState, handleChange, submitForm } = useForm<OnHoldForm>({
    initialValues: {
      waitingFor: '',
      reason: '',
      comment: '',
    },
    validate: (values, errors) => {
      if (isMissing(values.waitingFor)) errors.waitingFor = 'Verplicht';
      if (isMissing(values.reason)) errors.reason = 'Verplicht';
      if (values.reason === 'other' && isMissing(values.comment)) errors.comment = 'Verplicht';
      return errors;
    },
    handleSubmit: values => {
      setDealOnHoldStatus({
        variables: {
          dealId: deal.id,
          waitingFor: values.waitingFor as WaitingFor,
          reason: values.reason as DealOnHoldReason,
          comment: values.comment,
        },
      });
    },
  });

  const specificReasonOptions = formState.waitingFor.value
    ? reasonOptions[formState.waitingFor.value]
    : [];

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      title={'Deal pauzeren'}
      buttons={[
        {
          label: 'Annuleren',
          bgColor: 'red',
          inverse: true,
          onClick: closeModal,
          disabled: loading,
        },
        {
          label: 'Bevestigen',
          bgColor: 'green',
          iconStart: Check,
          onClick: () => submitForm(),
          disabled: loading,
        },
      ]}
      size={'sm' as keyof typeof containers}
    >
      <Select
        clearable={false}
        label="Op wie moet we wachten?"
        placeholder="Partij selecteren"
        onChange={e => handleChange({ waitingFor: e.value })}
        options={waitingOnOptions}
        error={formState.waitingFor.error}
        touched={formState.waitingFor.touched}
        value={waitingOnOptions.find(o => o.value === formState.waitingFor.value)}
        disabled={loading}
      />
      <Select
        clearable={false}
        label="Wat is de reden om de opdracht te pauzeren?"
        placeholder="Reden selecteren"
        onChange={e => handleChange({ reason: e.value })}
        options={specificReasonOptions}
        error={formState.reason.error}
        touched={formState.reason.touched}
        value={specificReasonOptions.find(o => o.value === formState.reason.value)}
        disabled={loading}
      />
      {formState.reason.value === 'other' && (
        <Textarea
          placeholder="Schrijf hier de reden om te pauzeren..."
          value={formState.comment.value}
          onChange={e => handleChange({ comment: e.target.value })}
          error={formState.comment.error}
          touched={formState.comment.touched}
        />
      )}
      {deal.pipedriveDealUrl ? (
        <p>
          De{' '}
          <a href={deal.pipedriveDealUrl} rel="noreferrer" target="_blank">
            deal in pipedrive
          </a>{' '}
          zal naar de 'Uitvoer gepauzeerd' pijplijn verplaatst worden
        </p>
      ) : (
        <p>
          <Gray>Deal staat niet in pipedrive</Gray>
        </p>
      )}
      <p></p>
    </Modal>
  );
};

export default SetDealOnHoldModal;
