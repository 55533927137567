import { sum } from 'lodash';
import { installationByHouseSolution_installationByHouseSolution_items as t_item } from '../../../types/generated/installationByHouseSolution';

export type PriceType = 'purchase' | 'retail';

export type ModifiableItem = t_item & {
  /** When set to false, it locks/freezes the item's price: Other items will be changed more to compensate */
  include: boolean;
  newRetailPrice: number;
  newPurchasePrice: number;
};

/**
 * Recalculates the retail- and purchase prices of an installation, given a total target price.
 * All prices on all items will be increased equally (except for those marked as locked/frozen).
 *
 * Useful for: meeting the minimum rate of an installer
 *
 * @param items The items of an installation
 * @param target The total target price to of the given items
 * @param targetType The type of the target price: either the total retail or total purchase price
 * @param applyToBoth Whether to only update the retail price, or both the retail and purchase price
 * @returns A new list of items, with a total price greater or equal to the given target price.
 * It may be greater, since prices on individual items are ceiled.
 * When rounding, the price may be lower than the target,
 * which would make it unusable to meet the minimum rate of an installer
 */
export const recalculateInstallationPrices = (
  items: ModifiableItem[],
  target: number,
  targetType: PriceType,
  applyToBoth: boolean,
) => {
  // Subtract the excluded items from the target as the remainder
  // TODO: Discounts. Not trivial to include, since it could be a percentage. Leaving out for now
  // TODO: Could maybe prioritize the price increase of certain items over others to our benefit (e.g. taxes)
  // TODO: input/output types could be defined better: this function just needs { amount, retailPrice, purchasePrice } per item

  const priceKey = `${targetType}Price` as 'retailPrice' | 'purchasePrice';

  const remainder = target - sum(items.filter(i => !i.include).map(i => i.amount * i[priceKey]));

  // Calculate the weight of each included item
  let weights = items.map(i => (!i.include ? 0 : i.amount * i[priceKey]));
  const weightSum = sum(weights);
  weights = weights.map(w => w / weightSum);

  return items.map((item, index) => {
    const newItem = {
      ...item,
      newRetailPrice: item.retailPrice,
      newPurchasePrice: item.purchasePrice,
    };

    // when changing retail price, recompute purchase price while retaining same margin pct, and vice versa
    if (item.include) {
      // m = 1 - (p / r)
      // m - 1 = - (p / r)
      // 1 - m = (p / r)
      const margin = 1 - item.purchasePrice / item.retailPrice;
      if (targetType === 'retail') {
        newItem.newRetailPrice = (remainder * weights[index]) / item.amount;
        if (applyToBoth) {
          // Increase purchase price too with respect to current margin:
          //  p = (1 - m) * r
          newItem.newPurchasePrice = (1 - margin) * newItem.newRetailPrice || 0;
        }
      } else {
        newItem.newPurchasePrice = (remainder * weights[index]) / item.amount;
        if (applyToBoth) {
          // Increase retail price too with respect to current margin:
          //  r / p = 1 / (1 - m)
          //  r = p / (1 - m)
          newItem.newRetailPrice = newItem.newPurchasePrice / (1 - margin) || 0;
        }
      }
    }
    return newItem;
  });
};
