import { useMutation } from '@apollo/client';
import { Solution, SolutionDomain } from '@energiebespaarders/constants';
import { Box, Button, Flex, Icon, Modal, Table, theme, Tooltip } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import { Archive, Back, Edit, TrashCancel } from '@energiebespaarders/symbols/icons/solid';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { rgba } from 'polished';
import React, { useState } from 'react';
import styled from 'styled-components';
import { delimit, fixUnit } from '../../../lib/utils';
import getPriceAvailabilityProps from '../../../lib/utils/getPriceAvailabilityProps';
import { DELETE_INSTALLATION_ITEM, UPDATE_INSTALLATION } from '../../../queries/installatron';
import { deleteItem, deleteItemVariables } from '../../../types/generated/deleteItem';
import {
  installationByHouseSolution_installationByHouseSolution as t_installation,
  installationByHouseSolution_installationByHouseSolution_items as t_item,
} from '../../../types/generated/installationByHouseSolution';
import {
  updateInstallation,
  updateInstallationVariables,
} from '../../../types/generated/updateInstallation';
import { PriceAvailability, Solution as GQLSolution } from '../../../types/graphql-global-types';
import { ProductModal } from '../../modals';
import TotalsRows from '../TotalsRows';
import ItemConfigurator from './ItemConfigurator';
import ReorderControls from './ReorderControls';

type t_slider = {
  slide: boolean;
};

const RelativeContainerCell = styled(Table.Cell)`
  position: relative;
`;

const Slider = styled(Box)`
  transition: all 0.2s ${theme.curves.standard};
  transform: translateX(${(p: t_slider) => (p.slide ? '0' : 'calc(-12px - 2rem)')});
  cursor: pointer;
`;

const Controls = styled(Box)`
  transition: all 0.2s ${theme.curves.standard};
  opacity: ${(p: t_slider) => (p.slide ? '1' : '0')};
`;

type NameCellProps = {
  item: t_item;
  installation: t_installation;
  solution: Solution;
  index: number;
  setEditing: (_: number) => void;
  deleteItem: (_: any) => void;
  setViewProduct: (_: string) => void;
};

const NameCell: React.FC<NameCellProps> = ({
  item,
  installation,
  index,
  setEditing,
  deleteItem,
  setViewProduct,
}) => {
  const [slide, setSlide] = useState(false);
  const availabilityIndicator = getPriceAvailabilityProps(item.price.availability);
  return (
    <Flex
      alignItems="center"
      onMouseOver={() => setSlide(true)}
      onMouseLeave={() => setSlide(false)}
    >
      <Controls slide={slide}>
        <Flex>
          <Box width="1rem" mx="3px">
            <Icon
              icon={Edit}
              solid
              fill="gray"
              width="1rem"
              hoverColor="blue"
              onClick={() => setEditing(index)}
            />
          </Box>
          <Box width="1rem" mx="3px">
            <Icon
              icon={TrashCancel}
              solid
              fill="gray"
              width="1rem"
              hoverColor="red"
              onClick={() =>
                deleteItem({
                  variables: {
                    installationId: installation.id,
                    productId: item.product ? item.product.id : null,
                  },
                })
              }
            />
          </Box>
        </Flex>
      </Controls>
      <Slider
        slide={slide}
        title={item.product!.title || ''}
        onClick={() => setViewProduct(item.product!.id)}
      >
        <div>
          {item.product.archived && (
            <>
              <Tooltip bgColor="orange" content="Product gearchiveerd, gebruik bewust!">
                <Icon icon={Archive} fill="orange" />
              </Tooltip>{' '}
            </>
          )}
          <Medium>{item.product!.title}</Medium>
        </div>

        {item.price.availability !== PriceAvailability.inStock && (
          <>
            <Tooltip
              bgColor={availabilityIndicator.fill}
              content={`Let op: ${availabilityIndicator.toolTip} bij ${item.supplier.name}`}
            >
              <Icon icon={availabilityIndicator.icon} fill={availabilityIndicator.fill} />
            </Tooltip>{' '}
          </>
        )}
        {item.supplier ? item.supplier.name : 'Geen installatiepartner geselecteerd'}
      </Slider>
    </Flex>
  );
};

const EditModal: React.FC<{
  item: t_item;
  installation: t_installation;
  houseId: string;
  solution: Solution;
  solutionDomain: SolutionDomain;
  mobile: boolean;
  setEditing: (i: null | number) => void;
}> = ({ item, installation, houseId, solution, solutionDomain, mobile, setEditing }) => {
  return (
    <Modal
      isOpen={true}
      mobile={mobile}
      onRequestClose={() => setEditing(null)}
      title="Installatieproducten aanpassen"
    >
      <ItemConfigurator
        installation={installation}
        houseId={houseId}
        solution={solution}
        solutionDomain={solutionDomain}
        editing={item}
        setEditing={setEditing}
      />
    </Modal>
  );
};

const HorizontalTableScroll = styled.div`
  overflow: auto;
  > * {
    min-width: 650px;
  }
`;

const Underline = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

type Props = {
  installation: t_installation;
  houseId: string;
  solution: Solution;
  solutionDomain: SolutionDomain;
  mobile: boolean;
  readOnly?: boolean;
};

const InstallationItemBreakdown: React.FC<Props> = ({
  installation,
  houseId,
  solution,
  solutionDomain,
  mobile,
  readOnly,
}) => {
  const [editing, setEditing] = useState<number | null>(null);
  const [viewProduct, setViewProduct] = useState<string | null>(null);
  const [reorderLoading, setReorderLoading] = useState(false);
  const [showSubtotal, setShowSubtotal] = useState(false);

  const [deleteItemMutation] = useMutation<deleteItem, deleteItemVariables>(
    DELETE_INSTALLATION_ITEM,
  );
  const [updateInstallationMutation] = useMutation<updateInstallation, updateInstallationVariables>(
    UPDATE_INSTALLATION,
  );
  return (
    <>
      {!readOnly && installation.items.length > 0 && (
        <Box textAlign="right">
          <Button
            onClick={() =>
              updateInstallationMutation({
                variables: {
                  id: installation.id,
                  installation: {
                    id: installation.id,
                    solution: solution as GQLSolution,
                    items: [],
                  },
                },
              })
            }
            minimal
            bgColor="grayDark"
            fontSize={6}
            iconStart={Back}
            mr={0}
            mb={0}
          >
            Installatie leegmaken
          </Button>
        </Box>
      )}
      <HorizontalTableScroll>
        <Box my={2}>
          <Table
            mobile={mobile}
            defaultPadding={mobile ? 1 : 2}
            defaultOverflow="ellipsis"
            layout={
              !readOnly
                ? [1 / 20, 11 / 20, 2 / 20, 2 / 20, 2 / 20, 2 / 20]
                : [5 / 10, 1 / 10, 1 / 10, 1 / 10, 2 / 10]
            }
          >
            <Table.Row isHeader>
              {!readOnly && <Table.Cell textAlign={readOnly ? 'right' : 'left'} />}
              <Table.Cell>Product</Table.Cell>
              <Table.Cell textAlign="center">Aantal</Table.Cell>
              <Table.Cell textAlign="center">Eenheid</Table.Cell>
              <Table.Cell textAlign="center">BTW</Table.Cell>
              <Table.Cell textAlign="right">
                <Underline onClick={() => setShowSubtotal(!showSubtotal)}>
                  {showSubtotal ? 'Subtotaal' : 'Prijs'}
                </Underline>
              </Table.Cell>
            </Table.Row>
            {installation.items.map((item: t_item, index: number) =>
              item.product ? (
                <Table.Row
                  key={'installation_item_' + index}
                  rowBgColor={item.product.archived ? rgba(themify('orange'), 0.2) : undefined}
                >
                  {/* Reorder items */}
                  {!readOnly && (
                    <Table.Cell>
                      <ReorderControls
                        installation={installation}
                        index={index}
                        houseId={houseId}
                        loading={reorderLoading}
                        setLoading={setReorderLoading}
                      />
                    </Table.Cell>
                  )}

                  <RelativeContainerCell>
                    <NameCell
                      item={item}
                      setEditing={setEditing}
                      installation={installation}
                      index={index}
                      solution={solution}
                      deleteItem={deleteItemMutation}
                      setViewProduct={setViewProduct}
                    />
                  </RelativeContainerCell>

                  {/* Amount */}
                  <Table.Cell textAlign="center">{item.amount}</Table.Cell>

                  {/* Units */}
                  <Table.Cell textAlign="center">
                    {item.product!.priceUnit === 'unit'
                      ? 'stuks'
                      : fixUnit(item.product!.priceUnit)}
                  </Table.Cell>

                  {/* Tax */}
                  <Table.Cell textAlign="center">
                    {item.product!.tax ? `${item.product!.tax * 100}%` : '-'}
                  </Table.Cell>

                  {/* Price */}
                  <Table.Cell textAlign="right" cellOverflow="clip">
                    {item.retailPrice || item.retailPrice === 0
                      ? `€${delimit(
                          showSubtotal ? item.retailPrice * item.amount : item.retailPrice,
                          2,
                        )}`
                      : '?'}
                  </Table.Cell>
                </Table.Row>
              ) : (
                console.log('No product found!', item)
              ),
            )}
          </Table>
          <TotalsRows
            items={installation.items}
            discount={installation.discount || undefined}
            mobile={mobile}
            readOnly={true}
          />
          {editing !== null && (
            <EditModal
              item={installation.items[editing]}
              installation={installation}
              houseId={houseId}
              solution={solution}
              solutionDomain={solutionDomain}
              mobile={mobile}
              setEditing={setEditing}
            />
          )}
          {viewProduct && (
            <ProductModal
              isOpen={Boolean(viewProduct)}
              productId={viewProduct}
              closeModal={async () => setViewProduct(null)}
              setActiveProduct={activeProduct => setViewProduct(activeProduct)}
            />
          )}
        </Box>
      </HorizontalTableScroll>
    </>
  );
};

export default InstallationItemBreakdown;
