import { gql } from '@apollo/client';
import FileFragment from './File';

export const JobFragment = gql`
  ${FileFragment}
  fragment Job on Job {
    id
    created
    type
    commentsForInstaller
    comment
    items {
      purchasePrice
      amount
      product {
        id
        title
      }
    }
    installer {
      id
      name
      installationEmail
      inspectionEmail
      planningPeriod
      customerNotificationPeriod
    }
    pdfLink
    isSent
    sentOn
    files {
      ...File
    }
    plannings {
      created
      sent
      plannedFor
      plannedOn
    }
    projectGrippId
    completed
  }
`;

export const QuoteJobFragment = gql`
  ${FileFragment}
  fragment QuoteJob on Job {
    id
    created
    type
    sentOn
    completed
    commentsForInstaller
    planningStatus
    status
    comment
    projectGrippId
    items {
      purchasePrice
      retailPrice
      amount
      product {
        id
        title
      }
      supplierId
    }
    files {
      ...File
    }
    plannings {
      created
      sent
      plannedFor
      plannedOn
      suspended
      metadata {
        comment
        additionalInformation
      }
    }
    currentPlanning {
      created
      sent
      plannedFor
      plannedOn
      suspended
      metadata {
        comment
        additionalInformation
      }
    }
    mostRecentlySentPlanning {
      sent
    }
    installer {
      id
      name
      customerNotificationPeriod
      installationEmail
      inspectionEmail
      pairedSupplierId
    }
    installerSignoff {
      jobId
      type
      creatorId
      created
    }
    proposals {
      expirationDate
      created
      options {
        key
        date
      }
      fulfilled {
        key
      }
    }
  }
`;

export default JobFragment;
