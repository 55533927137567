import { getCalculations_house_averageEstimates_atticFloorInsulation_energyDelta } from './../../types/generated/getCalculations';
import { calculationData } from '../dataMaps/calculationData';
import { getEnergyLabel } from './labelUtils';
import {
  getEfficiency,
  getPaybackTime,
  getYearlyFinancialSavings,
} from '@energiebespaarders/constants';

export interface EnergyDelta {
  electricityConsumption: number;
  electricityProduction: number;
  gasConsumption: number;
  gasFactor: number;
  emission: number;
  money: number;
  energyIndex: number;
}

interface Calculation {
  key: string;
  color: string;
  icon: any;
  label: string;
  prefix?: string;
  suffix?: string;
  decimals?: number;
  value?: number | string;
}

interface CalculationValues {
  [x: string]: number | string;
  money: number;
  gas: number;
  electricity: number;
  emission: number;
  investment: number;
  efficiency: number;
  paybackTime: number;
  energyLabel: string;
}

export const getCalculations = (
  energyDelta: getCalculations_house_averageEstimates_atticFloorInsulation_energyDelta,
  consumption: {
    electricity: number;
    gas: number;
  },
  investment: number,
  energyIndex: number,
  energyPrices: {
    electricity: number;
    gas: number;
  },
  emissionFactors: {
    electricity: number;
    gas: number;
  },
  subsidy: number,
  includeSubsidy: boolean,
): Calculation[] => {
  if (
    !(energyDelta && consumption && energyIndex && energyPrices && emissionFactors) &&
    (!investment || investment === 0)
  ) {
    return calculationData;
  }

  const _subsidy = includeSubsidy ? subsidy : 0;

  const gasSavings = energyDelta.gasConsumption;
  const electricitySavings = energyDelta.electricityConsumption + energyDelta.electricityProduction;
  const emissionSavings =
    gasSavings * emissionFactors.gas + electricitySavings * emissionFactors.electricity;
  const energyIndexSavings = energyDelta.energyIndex;

  const values: CalculationValues = {
    money: getYearlyFinancialSavings(consumption, energyDelta, energyPrices).total,
    gas: gasSavings,
    electricity: electricitySavings,
    emission: emissionSavings,
    investment: investment - _subsidy,
    efficiency: getEfficiency(consumption, energyDelta, investment - _subsidy, energyPrices),
    paybackTime: getPaybackTime(consumption, energyDelta, investment - _subsidy, energyPrices),
    energyLabel: getEnergyLabel(energyIndex - energyIndexSavings),
    subsidy,
  };

  const calculations: Calculation[] = [...calculationData];

  return calculations.map(calculation => ({
    ...calculation,
    value: values[calculation.key] ?? '',
    color: calculation.key === 'subsidy' && !includeSubsidy ? 'red' : calculation.color,
  }));
};

export default getCalculations;
