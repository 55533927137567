import { gql } from '@apollo/client';
import { FileFragment, ProductFragment } from '../fragments';

export const PRODUCT_BY_ID = gql`
  ${FileFragment}
  ${ProductFragment}
  query productById($id: ID!) {
    productById(id: $id) {
      ...Product
      files {
        ...File
      }
    }
  }
`;

// Added to get total product count for pagination
export const PRODUCT_COUNT = gql`
  query productCount(
    $supplierId: ID
    $solutionDomain: String
    $category: ProductCategory
    $searchString: String
    $searchAll: Boolean
    $onlyArchived: Boolean
    $availabilities: [PriceAvailability!]
  ) {
    productCount(
      supplierId: $supplierId
      solutionDomain: $solutionDomain
      category: $category
      searchString: $searchString
      searchAll: $searchAll
      onlyArchived: $onlyArchived
      availabilities: $availabilities
    )
  }
`;

export const PRODUCTS_BY_SOLUTION = gql`
  query productsBySolution($solution: Solution!) {
    productsBySolution(solution: $solution) {
      id
      title
      tax
      category
      # installerIds
      priceUnit
    }
  }
`;

export const PRODUCTS_BY_SOLUTION_DOMAIN = gql`
  query productsBySolutionDomain($solutionDomain: String!) {
    productsBySolutionDomain(solutionDomain: $solutionDomain) {
      id
      title
      tax
      category
      # installerIds
      priceUnit
    }
  }
`;

export const INSTALLABLE_PRODUCTS_BY_SOLUTION = gql`
  query notUsedAbyNore($solution: Solution!) {
    productsBySolution(solution: $solution) {
      id
      title
      tax
      category
      # installerIds
      priceUnit
    }
  }
`;

export const GET_PRODUCTS = gql`
  query sortedProducts(
    $sortOrder: String
    $sortCriterion: String
    $offset: Int
    $limit: Int
    $supplierId: ID
    $solutionDomain: String
    $category: ProductCategory
    $searchString: String
    $searchAll: Boolean
    $availabilities: [PriceAvailability!]
    $onlyArchived: Boolean
    $include_description: Boolean = true
    $include_files: Boolean = true
    $include_prices: Boolean = true
    $include_quotes: Boolean = true
    $include_acceptedQuoteCount: Boolean = true
  ) {
    sortedProducts(
      sortOrder: $sortOrder
      sortCriterion: $sortCriterion
      offset: $offset
      limit: $limit
      supplierId: $supplierId
      solutionDomain: $solutionDomain
      category: $category
      searchString: $searchString
      searchAll: $searchAll
      availabilities: $availabilities
      onlyArchived: $onlyArchived
    ) {
      id
      archived
      title
      tax
      category
      solution
      isdeCode
      description @include(if: $include_description)
      priceUnit
      customMarkup
      youTubeId
      created
      modified
      files @include(if: $include_files) {
        id
        extension
        metadata {
          title
        }
      }
      prices @include(if: $include_prices) {
        id
        purchasePrice
        retailPrice
        availability
        minimumInstallerRate
        grippId
        modified
        supplierId
      }
      quotes(offset: 0, limit: 1, order: "desc", field: "accepted") @include(if: $include_quotes) {
        id
        acceptedOn
      }
      acceptedQuoteCount @include(if: $include_acceptedQuoteCount)
      ... on CompletePvSystem {
        pvPanel {
          id
          brand {
            id
            name
          }
        }
      }
      ... on PvPanel {
        brand {
          id
          name
        }
        cellType
        countryOfOrigin
        pMax
        tier
        warrantedPMax
      }
      ... on Inverter {
        brand {
          id
          name
        }
      }
      ... on Optimizer {
        brand {
          id
          name
        }
      }
      ... on WallCavityInsulation {
        brand {
          id
          name
        }
        rd
        thickness
        material
      }
      ... on FloorInsulation {
        brand {
          id
          name
        }
        rd
        thickness
        material
      }
      ... on RoofInsulation {
        brand {
          id
          name
        }
        rd
        thickness
        placement
      }
      ... on Glazing {
        brand {
          id
          name
        }
        u
        zta
        glassType
      }
      ... on WindowFrame {
        brand {
          id
          name
        }
      }
      ... on CentralHeatingBoiler {
        brand {
          id
          name
        }
        cwClass
        efficiency
        energyLabel
      }
      ... on Thermostat {
        brand {
          id
          name
        }
      }
      ... on HeatPump {
        brand {
          id
          name
        }
        heatPumpType
        subsidy
      }
      ... on HeatingSystem {
        brand {
          id
          name
        }
      }
    }
  }
`;

export const GET_SCHEMA = gql`
  query getSchemaProducts($name: String!) {
    __type(name: $name) {
      kind
      name
      fields {
        name
        type {
          kind
          name
          ofType {
            name
            kind
            enumValues {
              name
            }
          }
          enumValues {
            name
          }
        }
      }
    }
  }
`;

export const ADD_PRODUCT = gql`
  ${ProductFragment}
  mutation addProduct($product: ProductInput!) {
    addProduct(product: $product) {
      ...Product
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  ${FileFragment}
  ${ProductFragment}
  mutation updateProduct($id: ID!, $product: ProductInput!) {
    updateProduct(id: $id, product: $product) {
      ...Product
      files {
        ...File
      }
    }
  }
`;

export const GET_SUPPLIER_NAMES = gql`
  query supplierNames {
    suppliers {
      id
      name
      grippId
    }
  }
`;

export const GET_INSTALLER_NAMES = gql`
  query installerNames {
    installers {
      id
      name
    }
  }
`;

export const GET_PRICES_AND_SUPPLIER = gql`
  query getPricesAndSupplier($productId: ID!) {
    findPricesByProduct(productId: $productId) {
      id
      isdeCode
      purchasePrice
      retailPrice
      product {
        id
      }
      availability
      grippId
      supplier {
        id
        name
        grippId
        installer {
          id
          status {
            value
          }
        }
      }
    }
  }
`;

export const UPSERT_PRICE = gql`
  mutation upsertPrice($price: PriceInput!, $id: ID) {
    upsertPrice(price: $price, id: $id) {
      id
      purchasePrice
      retailPrice
      minimumInstallerRate
      supplier {
        id
        name
        grippId
      }
      product {
        id
      }
      availability
      modified
      grippId
    }
  }
`;

export const UPDATE_PRICE = gql`
  mutation updatePrice($id: ID!, $price: PriceInput!) {
    updatePrice(id: $id, price: $price) {
      id
      purchasePrice
      retailPrice
      supplier {
        id
        name
        grippId
      }
      product {
        id
      }
      availability
      grippId
    }
  }
`;

export const BATCH_UPDATE_PRICE_AVAILABILITY = gql`
  mutation batchUpdatePriceAvailability($priceIds: [ID!]!, $availability: PriceAvailability!) {
    batchUpdatePriceAvailability(priceIds: $priceIds, availability: $availability) {
      id
      availability
    }
  }
`;

export const UPDATE_PRICES = gql`
  mutation updatePrices($prices: [PriceInput!]!) {
    updatePrices(prices: $prices) {
      id
      purchasePrice
      retailPrice
      availability
      grippId
      supplierId
      productId
    }
  }
`;
