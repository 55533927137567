import { useMutation, useQuery } from '@apollo/client';
import { enumValues } from '@energiebespaarders/constants';
import {
  Accordion,
  Box,
  Button,
  Card,
  Container,
  Flex,
  Icon,
  Image,
  Select,
  Separator,
  Switch,
  Uploader,
  Wrapper,
} from '@energiebespaarders/symbols';
import { CardProps } from '@energiebespaarders/symbols/components/Card';
import { Center, Gray, Medium, Small } from '@energiebespaarders/symbols/helpers';
import { ArrowLeft, Edit } from '@energiebespaarders/symbols/icons/line';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { graphqlUrl } from '../../config';
import { useMe } from '../../hooks/useMe';
import useToaster from '../../hooks/useToaster';
import { DropdownOption } from '../../lib/constants';
import { checkManagerRole, checkTeamMember } from '../../lib/permissions';
import {
  GET_OPERATOR,
  GET_OPERATORS,
  SET_OPERATOR_PROFILE_PHOTO,
  UPDATE_OPERATOR,
} from '../../queries/operatorDirectory';
import theme from '../../styles/theme';
import { File } from '../../types/generated/File';
import { getOperators, getOperatorsVariables } from '../../types/generated/getOperators';
import { operatorById, operatorByIdVariables } from '../../types/generated/operatorById';
import {
  setOperatorProfilePhoto,
  setOperatorProfilePhotoVariables,
} from '../../types/generated/setOperatorProfilePhoto';
import { updateOperator, updateOperatorVariables } from '../../types/generated/updateOperator';
import PageLoading from '../PageLoading';
import EditPC4Regions from '../companies/EditPC4Regions';
import { generateOperatorVriendenkortingCode } from '../coupons/utils';
import AddressForm from './AddressForm';
import EditableField from './EditableField';
import OrdersWithVriendenkortingCode from './OrdersWithVriendenkortingCode';

interface OperatorProfileProps {
  operatorId: string;
}

export enum OperatorTeam {
  MT = 'MT',
  Development = 'Development',
  NetworkSuccess = 'NetworkSuccess',
  CustomerHappiness = 'CustomerHappiness',
  Sales = 'Sales',
  InsideSales = 'InsideSales',
  HumanResources = 'HumanResources',
  UserExperience = 'UserExperience',
  Acquisition = 'Acquisition',
  Planning = 'Planning',
  Product = 'Product',
  Marketing = 'Marketing',
  Data = 'Data',
  PartnerManagement = 'PartnerManagement',
  Finance = 'Finance',
  Habitata = 'Habitata',
}

export enum OperatorRole {
  Admin = 'admin',
  Manager = 'manager',
  Standard = 'standard',
  Adviser = 'adviser',
}

const roleOptions = (isAdmin: boolean) => [
  ...(isAdmin ? [{ label: 'Admin', value: 'admin', disabled: !isAdmin }] : []),
  ...[
    {
      label: 'Manager',
      value: 'manager',
    },
    {
      label: 'Standard',
      value: 'standard',
    },
    {
      label: 'Adviseur',
      value: 'adviser',
    },
  ],
];

export const operatorTeamOptions = enumValues(OperatorTeam).map(k => {
  return {
    label: _.startCase(OperatorTeam[k]),
    value: k,
  };
});

const OperatorProfile: React.FC<OperatorProfileProps> = ({ operatorId }) => {
  const { me } = useMe();
  const toast = useToaster();
  const [showReferralOrders, setShowReferralOrders] = useState(false);

  const { data: opData, loading, error } = useQuery<operatorById, operatorByIdVariables>(
    GET_OPERATOR,
    { variables: { id: operatorId } },
  );

  const { data: allOperatorsData } = useQuery<getOperators, getOperatorsVariables>(GET_OPERATORS, {
    variables: { includeInactive: false },
    onError: e => toast({ message: e.message, type: 'error' }),
  });

  const [updateOperator, { loading: updating }] = useMutation<
    updateOperator,
    updateOperatorVariables
  >(UPDATE_OPERATOR, {
    onCompleted: () => toast({ type: 'success', message: 'Beheerder opgeslagen!' }),
    onError: e => toast({ type: 'error', message: 'Opslaan mislukt: ' + e.message }),
  });

  const operator = opData?.operatorById;

  const hasPermissionToEdit =
    (me.role !== 'adviser' && me.id === operatorId) || checkManagerRole(me, operator);

  // You shouldn't be able to update your own role/team unless you're manager
  const hasPermissionToChangeRole =
    checkManagerRole(me, operator) || checkTeamMember(me, OperatorTeam.MT);

  const [setProfilePhoto, { loading: uploading, error: uploadError }] = useMutation<
    setOperatorProfilePhoto,
    setOperatorProfilePhotoVariables
  >(SET_OPERATOR_PROFILE_PHOTO, {
    onCompleted: data =>
      toast({
        type: 'success',
        message: data.setOperatorProfilePhoto?.id
          ? 'Profielfoto aangepast'
          : 'Profielfoto verwijderd',
      }),
    onError: e => toast({ type: 'error', message: 'Opslaan mislukt: ' + e.message }),
    update: (cache, { data }) => {
      const cachedOperator = cache.readQuery<operatorById, operatorByIdVariables>({
        query: GET_OPERATOR,
        variables: { id: operatorId },
      });

      if (!cachedOperator) return;

      const updated = {
        ...cachedOperator?.operatorById,
        profilePhotoUrl: data?.setOperatorProfilePhoto?.id
          ? `${graphqlUrl}/files/${data?.setOperatorProfilePhoto?.id}.${data?.setOperatorProfilePhoto?.extension}`
          : '',
      };

      cache.writeQuery<operatorById, operatorByIdVariables>({
        query: GET_OPERATOR,
        variables: { id: operatorId },
        data: { operatorById: updated },
      });
    },
  });

  const removeProfilePhoto = useCallback(() => {
    if (!hasPermissionToEdit) return;
    if (
      window.confirm('Wil je deze profielfoto verwijderen? Dit kan niet ongedaan gemaakt worden.')
    ) {
      setProfilePhoto({ variables: { id: operatorId, file: null } });
    }
  }, [hasPermissionToEdit, operatorId, setProfilePhoto]);

  const allOperatorsOptions: DropdownOption<string>[] = useMemo(() => {
    if (allOperatorsData?.operators) {
      return allOperatorsData.operators.map(o => ({
        value: o.id,
        label: `${o.firstName} ${o.lastName}`,
      }));
    }
    return [];
  }, [allOperatorsData?.operators]);

  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);

  if (loading) return <PageLoading />;
  if (error || !operator) return <div>{error}</div>;

  const {
    id,
    firstName,
    lastName,
    email,
    phone,
    created,
    isFreelancer,
    modified,
    jobTitle,
    role,
    pipedriveId,
    address,
    workRegions,
    profilePhotoUrl,
    salesManager,
    lastLogin,
  } = operator;

  const cardProps: CardProps = {
    shadow: 'none',
    borderColor: 'grayLight',
  };

  const dateFormat = 'dddd D MMMM YYYY [om] HH:mm:ss';

  return (
    <Wrapper>
      <Container py={10}>
        <Button to="/users/operators" disabled={loading || uploading} minimal iconStart={ArrowLeft}>
          Terug naar overzicht
        </Button>
        <Card>
          <Flex flexWrap="wrap" width={1} alignItems="center">
            <Box p={3} width={[1, 1, 1 / 2]}>
              <Center block>
                {profilePhotoUrl ? (
                  <Image
                    src={profilePhotoUrl}
                    alt={`${firstName} ${lastName}`}
                    height="150px"
                    mb={5}
                    onClick={removeProfilePhoto}
                    style={{ cursor: 'pointer' }}
                  />
                ) : hasPermissionToEdit ? (
                  <Uploader
                    multiple={false}
                    loading={uploading}
                    error={uploadError}
                    text="Upload een profielfoto"
                    upload={({ variables }: { variables: { files: File[] } }) => {
                      setProfilePhoto({
                        variables: { id: operatorId, file: variables.files[0] },
                      });
                    }}
                    mb={1}
                  />
                ) : (
                  <Gray>Geen profielfoto</Gray>
                )}
                <h3>
                  {firstName} {lastName}
                </h3>
              </Center>
              {hasPermissionToEdit && (
                <div>
                  <Center block>
                    <p>Role: {role || 'None set'}</p>
                  </Center>
                  <Separator text="ADMIN" my={1} />
                  <Card {...cardProps} animation="none">
                    <Switch
                      isOn={!!operator.isActive}
                      disabled={updating}
                      toggleSwitch={() =>
                        updateOperator({
                          variables: {
                            id: operator.id,
                            operator: { isActive: !operator.isActive },
                          },
                        })
                      }
                      onLabel="Actief"
                      offLabel=" "
                      mb={2}
                    />
                    <Switch
                      isOn={operator.isFreelancer}
                      disabled={updating}
                      toggleSwitch={() => {
                        const newIsFreelancer = !operator.isFreelancer;
                        updateOperator({
                          variables: {
                            id: operator.id,
                            operator: {
                              isFreelancer: newIsFreelancer,
                              // Only freelancers need sales specialists (according to Hans). If they are being
                              // set to not being a freelancer then clear the sales specialist to avoid confusion
                              salesManagerId: newIsFreelancer ? operator.salesManager?.id : '',
                            },
                          },
                        });
                      }}
                      onLabel="Freelancer"
                      bgColorOff={'blueLight'}
                      bgColorOn={'blue'}
                      offLabel=" "
                      mb={2}
                    />
                    {isFreelancer && (
                      <>
                        <Select
                          label="Sales specialist"
                          clearable={true}
                          options={allOperatorsOptions}
                          value={
                            allOperatorsOptions.find(o => o.value === salesManager?.id) || {
                              label: 'Sales specialist selecteren...',
                              value: '',
                            }
                          }
                          onChange={e => {
                            if (!hasPermissionToChangeRole)
                              return window.alert(
                                'Dit is voor jou niet toegestaan, vraag een administrator voor hulp',
                              );
                            updateOperator({
                              variables: {
                                id: operator.id,
                                operator: { salesManagerId: e?.value || '' },
                              },
                            });
                          }}
                          loading={updating}
                          disabled={updating}
                        />
                        <Small>
                          Freelancers hebben een Sales Specialist die hun deals zullen overnemen in
                          het Sales proces na het uitvoeren van de woningopname
                        </Small>
                      </>
                    )}
                  </Card>
                </div>
              )}
            </Box>
            <Box p={3} width={[1, 1, 1 / 2]}>
              <Card {...cardProps} animation="none" mb={2}>
                <EditableField
                  operator={operator}
                  name="E-mailadres"
                  field="email"
                  value={email}
                  mutation={UPDATE_OPERATOR}
                  id={id}
                />
                <EditableField
                  operator={operator}
                  name="Telefoon"
                  field="phone"
                  value={phone}
                  mutation={UPDATE_OPERATOR}
                  id={id}
                />
                <Select
                  label="Team"
                  options={operatorTeamOptions}
                  value={operatorTeamOptions.find(opt => opt.value === operator.team)}
                  onChange={e => {
                    if (!hasPermissionToChangeRole)
                      return window.alert(
                        'Dit is voor jou niet toegestaan, vraag een administrator voor hulp',
                      );
                    updateOperator({
                      variables: {
                        id: operator.id,
                        operator: { team: e.value },
                      },
                    });
                  }}
                  loading={updating}
                />
                <Select<string>
                  label="Role"
                  options={roleOptions(me.role === 'admin')}
                  value={roleOptions(me.role === 'admin').find(opt => opt.value === operator.role)}
                  onChange={e => {
                    if (!hasPermissionToChangeRole)
                      return window.alert(
                        'Dit is voor jou niet toegestaan, vraag een administrator voor hulp',
                      );
                    updateOperator({
                      variables: {
                        id: operator.id,
                        operator: { role: e.value },
                      },
                    });
                  }}
                  loading={updating}
                />
                <EditableField
                  operator={operator}
                  name="Functietitel"
                  value={jobTitle}
                  field="jobTitle"
                  mutation={UPDATE_OPERATOR}
                  id={id}
                />
                <EditableField
                  operator={operator}
                  name="Pipedrive ID"
                  value={pipedriveId}
                  field="pipedriveId"
                  type="number"
                  mutation={UPDATE_OPERATOR}
                  id={id}
                />

                {!isUpdatingAddress ? (
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <p>
                      Adres:
                      <br />
                      <Medium>
                        {address?.zip
                          ? `${address.street} ${address.number}${
                              address.suffix ? ` ${address.suffix}` : ''
                            }, ${address.zip} ${address.city}`
                          : 'Unknown'}
                      </Medium>
                    </p>
                    <Icon
                      icon={Edit}
                      onClick={() => setIsUpdatingAddress(true)}
                      ml={2}
                      stroke="grayBlack"
                    />
                  </Flex>
                ) : (
                  <AddressForm
                    basic={false}
                    initialValues={address || undefined}
                    onSubmit={address => {
                      updateOperator({
                        variables: { id: operator.id, operator: { address } },
                      }).then(() => setIsUpdatingAddress(false));
                    }}
                    loading={updating}
                    error={error}
                    onCancel={() => setIsUpdatingAddress(false)}
                  />
                )}
              </Card>

              <Card {...cardProps} animation="none" mb={2}>
                <EditPC4Regions
                  regions={workRegions || []}
                  onUpdate={workRegions =>
                    updateOperator({ variables: { id: operator.id, operator: { workRegions } } })
                  }
                  regionUpdatePermittedOperatorEmails={[
                    'steven@energiebespaarders.nl',
                    'hans@energiebespaarders.nl',
                  ]}
                />
                {/* TODO: Add button to preview regions here just like for installer page */}
              </Card>

              <Card {...cardProps} animation="none" mb={2}>
                <Accordion
                  isOpened={showReferralOrders}
                  onClick={() => setShowReferralOrders(v => !v)}
                  title="Vriendenkorting"
                  contentPadding={0}
                  basePadding={0}
                  baseColor="grayBlack"
                  contentBgColor="white"
                >
                  <h4 style={{ color: theme.colors.green }}>
                    {generateOperatorVriendenkortingCode(operator)}
                  </h4>
                  <OrdersWithVriendenkortingCode operator={operator} />
                </Accordion>
              </Card>

              <Small>
                <p>Aangemaakt op {dayjs(created).format(dateFormat)}</p>
                <p>Laatst gewijzigd op {dayjs(modified).format(dateFormat)}</p>
                <p>Laatst ingelogd op {dayjs(lastLogin).format(dateFormat)}</p>
              </Small>
            </Box>
          </Flex>
        </Card>
      </Container>
    </Wrapper>
  );
};
export default OperatorProfile;
