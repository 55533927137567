import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Flex,
  Modal,
  Placeholder,
  Rating,
  Separator,
  Spinner,
} from '@energiebespaarders/symbols';
import { RatingProps } from '@energiebespaarders/symbols/components/Rating';
import { Medium, Small } from '@energiebespaarders/symbols/helpers';
import dayjs from 'dayjs';
import React from 'react';
import { jobWithRating, jobWithRatingVariables } from '../../types/generated/jobWithRating';
import Thumbs from './AddRatingModal/Thumbs';

const JOB_WITH_RATING = gql`
  query jobWithRating($jobId: ID!) {
    jobById(jobId: $jobId) {
      id
      completed
      installer {
        id
        name
      }
      rating {
        id
        created
        work
        communication
        rater {
          ... on AuthenticatedUser {
            id
            firstName
            lastName
          }
        }
        friendliness
        extraWork
        cleanliness
        professionalism
        punctuality
        serviceRating
        notesOnDEB
        notesOnInstaller
      }
    }
  }
`;

interface RatingModalProps {
  show: boolean;
  onClose: () => void;
  jobId: string;
  mobile: boolean;
}

export const RatingModal: React.FC<RatingModalProps> = ({ show, jobId, onClose, mobile }) => {
  const ratingProps: Partial<RatingProps> = {
    minRating: 0,
    maxRating: 5,
    readOnly: true,
    fillColor: 'orange',
    strokeColor: 'orange',
  };

  const { data, loading, error } = useQuery<jobWithRating, jobWithRatingVariables>(
    JOB_WITH_RATING,
    {
      variables: {
        jobId,
      },
    },
  );

  if (error) return <Placeholder error={error} />;
  if (loading || !data?.jobById) return <Spinner />;

  const job = data.jobById;

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      mobile={mobile}
      title={`Beoordeling voor ${job.installer?.name || '-'}`}
      size="sm"
      buttons={[
        {
          label: 'Sluiten',
          bgColor: 'red',
          onClick: onClose,
        },
      ]}
    >
      {job.rating ? (
        <>
          <Flex flexWrap="wrap" mb={2}>
            <Box width={1}>
              <Small>
                <p>
                  Beoordeeld door{' '}
                  <Medium>
                    {job.rating.rater.__typename === 'Customer'
                      ? 'de klant zelf'
                      : `${job.rating.rater.firstName} ${job.rating.rater.lastName}`}
                  </Medium>{' '}
                  op <Medium>{dayjs(job.rating.created).format('dddd DD MMMM YYYY')}.</Medium>
                </p>
                <p>
                  Opdracht uitgevoerd op{' '}
                  <Medium>
                    {job.completed ? dayjs(job.completed).format('dddd DD MMMM YYYY') : 'onbekend'}
                  </Medium>
                  .
                </p>
              </Small>

              <Separator mt={1} mb={3} />
            </Box>
            <Box width={1 / 2}>
              <Medium>Kwaliteit geleverd</Medium>
            </Box>
            <Box width={1 / 2} textAlign="right">
              <Rating rating={job.rating.work} {...ratingProps} />
            </Box>
          </Flex>

          <Flex flexWrap="wrap" mb={2}>
            <Box width={1 / 2}>
              <Medium>Communicatie</Medium>
            </Box>
            <Box width={1 / 2} textAlign="right">
              <Rating rating={job.rating.communication} {...ratingProps} />
            </Box>
          </Flex>

          <Separator mt={1} mb={3} />

          <Small>
            <Thumbs label="Klantvriendelijk" status={job.rating.friendliness} readonly />
            <Thumbs label="Hoeveelheid werk" status={job.rating.extraWork} readonly />
            <Thumbs label="Netjes" status={job.rating.cleanliness} readonly />
            <Thumbs label="Professioneel" status={job.rating.professionalism} readonly />
            <Thumbs label="Punctueel" status={job.rating.punctuality} readonly />
            <Separator mt={1} mb={3} />
            <Flex flexWrap="wrap" mb={2}>
              <Box width={1}>
                <Medium>Verbeterpunten voor IP</Medium>
                <p>{job.rating.notesOnInstaller}</p>
              </Box>
            </Flex>
          </Small>
          <Flex flexWrap="wrap" mb={2}>
            <Box width={1 / 2}>
              <Medium>Dienstverlening Energiebespaarders</Medium>
            </Box>
            <Box width={1 / 2} textAlign="right">
              <Rating rating={job.rating.serviceRating || 0} {...ratingProps} />
            </Box>
          </Flex>
          <Small>
            <Flex flexWrap="wrap" mb={2}>
              <Box width={1}>
                <Medium>Wat kunnen we nog beter doen</Medium>
                <p>{job.rating.notesOnDEB}</p>
              </Box>
            </Flex>
          </Small>
        </>
      ) : (
        <p>Deze opdracht heeft geen beoordeling</p>
      )}
    </Modal>
  );
};

export default RatingModal;
