import React from 'react';
import { ProductOption } from './DropdownOptions';

type t_product = { id: string; title: string; isSuppliable?: boolean; archived?: Date };

function constructProductOptions(viableProducts: t_product[]) {
  return (
    viableProducts
      // Sort alphanumerically, with not-suppliable ones at the bottom, archived ones above that, suppliable ones at the top
      // (archived products may need to be still used for follow-up quotes, e.g. after a PTC. Can hide them after job-mutations are a thing)
      .sort((a, b) =>
        `${a.isSuppliable ? 0 : 1}${a.archived ? 1 : 0}${a.title}`.localeCompare(
          `${b.isSuppliable ? 0 : 1}${b.archived ? 1 : 0}${b.title}`,
          'nl',
          { numeric: true },
        ),
      )
      .map(product => ({
        value: product.id,
        label: <ProductOption key={product.id} product={product} />,
        disabled: product.isSuppliable === false,
        searchValue: product.title || '',
      }))
  );
}

export default constructProductOptions;
