import { ApolloClient, ApolloProvider } from '@apollo/client';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React, { useEffect, useState } from 'react';
import { FlagsProvider } from 'react-unleash-flags';
import { ThemeProvider } from 'styled-components';
import { version } from '../package.json';
import Routes from './Routes';
import AppHelmet from './components/AppHelmet';
import AppInitializer from './components/AppInitializer';
import ComposedContextProvider from './components/ComposedContextProvider';
import PageLoading from './components/PageLoading';
import VersionUpdater from './components/VersionUpdater';
import { isProduction, logrocketAppId, unleashConfig } from './config';
import './dayjs';
import { ServiceWorkerProvider } from './hooks/useServiceWorker';
import { setupClient } from './setupApolloClient';
import './styles.css';
import { GlobalStyle } from './styles/global';
import theme from './styles/theme';

// Initialize LogRocket
if (isProduction) {
  setupLogRocketReact(LogRocket);
  LogRocket.init(logrocketAppId, {
    release: version,
    network: {
      isEnabled: true,
      requestSanitizer: (req: any) => {
        if (req.headers['x-app-token']) {
          req.headers['x-app-token'] = '';
        }
        // Scrub out any sensitive data
        if (req?.body && typeof req.body === 'string') {
          const pwPattern = /(,?"password":")(.*?)"/g;
          req.body = req.body.replace(pwPattern, '');
        }
        return req;
      },
    },
  });
}

const flagsConfig = {
  appName: isProduction ? 'production' : 'staging',
  host: unleashConfig.host,
  instanceId: unleashConfig.instanceId,
};

const App: React.FC = () => {
  const [client, setClient] = useState<ApolloClient<any> | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    async function setup() {
      const apolloClient = await setupClient();
      setClient(apolloClient);
      setInitialized(true);
    }
    if (!initialized) setup();
  }, [setClient, setInitialized, initialized]);

  if (!initialized || !client) {
    return (
      <ThemeProvider theme={theme}>
        <PageLoading />
      </ThemeProvider>
    );
  }

  return (
    <ServiceWorkerProvider>
      <ApolloProvider client={client}>
        <FlagsProvider config={flagsConfig}>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <ComposedContextProvider>
              <VersionUpdater />
              <AppInitializer>
                <AppHelmet />
                <Routes />
              </AppInitializer>
            </ComposedContextProvider>
          </ThemeProvider>
        </FlagsProvider>
      </ApolloProvider>
    </ServiceWorkerProvider>
  );
};

export default App;
