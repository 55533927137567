import React from 'react';
import { Tooltip, Icon } from '@energiebespaarders/symbols';
import { InstallerStatus } from '../../types/graphql-global-types';
import InstallerStatusIndicators from './InstallerStatusIndicator';
import { House } from '@energiebespaarders/symbols/icons/solid';

const InstallerDropdownLabel: React.FC<{
  installerStatus: InstallerStatus;
  name: string;
  capableOfInstallingAtHouse?: boolean;
}> = ({ installerStatus, capableOfInstallingAtHouse, name }) => {
  const statusIndicator = InstallerStatusIndicators[installerStatus];
  const statusIcon =
    installerStatus !== InstallerStatus.active && statusIndicator ? (
      <Tooltip
        content={[statusIndicator.label, statusIndicator.description].join(': ')}
        bgColor={statusIndicator.color}
      >
        <Icon
          icon={statusIndicator.icon}
          fill="orange"
          strokeWidth={1}
          stroke="gray"
          mr={1}
          hoverColor="red"
          solid
        />
      </Tooltip>
    ) : null;

  const capabilityIndicator =
    capableOfInstallingAtHouse === false ? (
      <Tooltip content="Deze woning ligt buiten het werkgebied van de installateur" bgColor="red">
        <Icon icon={House} fill="red" mr={1} hoverColor="red" solid />
      </Tooltip>
    ) : null;

  return (
    <span>
      {capabilityIndicator}
      {statusIcon}
      {statusIcon ? ' ' : ''}
      {name}
    </span>
  );
};

export default InstallerDropdownLabel;
