import { Icon, Spacer, Tooltip } from '@energiebespaarders/symbols';
import {
  Block,
  FileEuro,
  Flag,
  Heart,
  PaperAirplane,
  Pencil,
  Photo,
  Redeye,
  Star,
  ThumbsUp,
  Time,
  Wrench,
} from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import { DocumentNode } from 'graphql';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ACCEPT_QUOTE, SEND_QUOTE, UPDATE_QUOTE } from '../../../queries/installatron';
import { padding, themify } from '../../../styles/mixins';
import { jobsByQuote_jobsByQuote } from '../../../types/generated/jobsByQuote';
import { quoteModalQuote_quoteById } from '../../../types/generated/quoteModalQuote';
import { quotesBySolution_quotesBySolution } from '../../../types/generated/quotesBySolution';
import { JobPlanningStatus, JobType } from '../../../types/graphql-global-types';
import { JobPlanningStatusIcons } from '../../jobs/JobControls';

type StatusIconProps = { iconColor: string };

export const StatusIcon = styled.div<StatusIconProps>`
  ${padding(1)};
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 2em !important;
  width: 2em !important;
  line-height: 0;
  border-radius: 2em;
  background: ${x => x.iconColor};
  transition: background 0.2s ${x => x.theme.curves.standard};
`;

export enum QuoteStatus {
  Final = 'final',
  Accepted = 'accepted',
  AcceptedSubjectToFunding = 'acceptedSubjectToFunding',
  Advised = 'advised',
  Sent = 'sent',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Completed = 'completed',
}

export type QuoteActionButton = {
  color: string;
  icon: ReactNode;
  label: string;
  status?: QuoteStatus;
  mutation?: DocumentNode;
};

export function getQuoteActions(
  allActions?: boolean,
  quote?: quoteModalQuote_quoteById | quotesBySolution_quotesBySolution,
  jobs?: readonly jobsByQuote_jobsByQuote[],
  files?: any[],
) {
  const acceptedSubjectToFunding = quote?.acceptedSubjectToFunding;
  const accepted = quote?.isAccepted || (allActions && !acceptedSubjectToFunding);
  const isAdvised = quote?.isAdvised || allActions;
  const cancelled = quote?.isCancelled || allActions;
  const final = quote?.final || allActions;
  const sent = quote?.isSent || allActions;
  const seen = quote?.seenOn;
  const hasCustomText = Boolean(quote?.text);
  const hasPtcJob = jobs?.find(j => j.type === JobType.PTC)?.isSent;

  const hasInstallationJob = jobs?.find(j => j.type === JobType.INSTALLATION)?.isSent;
  const hasUpload = files && files.length > 0;
  const expired = dayjs(quote?.expirationDate).diff(dayjs(quote?.sentOn), 'minute') <= 0;
  const iconButtons = [];
  const completed = jobs?.some(job => job.type === JobType.INSTALLATION && job.completed);

  // Pick a single planning status to show from all installation jobs for this quote
  // => importance as in: if one of them is plannedIn, doesn't matter if another one is rejected, etc.
  const planningJobs =
    (hasInstallationJob ? jobs?.filter(job => job.type === JobType.INSTALLATION) : jobs) || [];
  const planningStatuses = planningJobs.map(job => job.planningStatus);
  const statusImportance: JobPlanningStatus[] = [
    JobPlanningStatus.plannedIn,
    JobPlanningStatus.proposalRejected,
    JobPlanningStatus.proposalSent,
    JobPlanningStatus.proposalExpired,
    JobPlanningStatus.noProposal,
  ];
  const dealPlanningStatus = statusImportance.find(status =>
    planningStatuses?.some(s => s === status),
  );
  const relevantPlanningJob = dealPlanningStatus
    ? planningJobs[planningStatuses.findIndex(s => s === dealPlanningStatus)]
    : undefined;

  if (isAdvised) {
    iconButtons.push({
      color: 'blue',
      icon: Heart,
      label: 'Geadviseerd',
      status: 'advised',
    });
  }

  if (!allActions) {
    iconButtons.push({
      color: seen ? 'greenLight' : 'gray',
      icon: Redeye,
      label: seen ? `Gezien op ${dayjs(seen).format('DD/MM/YY')}` : 'Nog niet gezien',
    });
  }

  if (hasCustomText) {
    iconButtons.push({
      color: 'blue',
      icon: Pencil,
      label: 'Offertetekst',
    });
  }
  if (hasUpload) {
    iconButtons.push({
      color: 'blue',
      icon: Photo,
      label: 'Uploads',
    });
  }
  if (final) {
    // final means that a PTC is not/no longer necessary
    iconButtons.push({
      color: 'purple',
      icon: Flag,
      label: 'Definitief',
      status: 'final',
      mutation: UPDATE_QUOTE,
    });
  }
  if (sent) {
    iconButtons.push({
      color: 'orange',
      icon: PaperAirplane,
      label: 'Zichtbaar voor klant', // TODO: Separate 'visible' from 'published'
      status: 'sent',
      mutation: SEND_QUOTE,
    });
  }
  if (accepted) {
    iconButtons.push({
      color: 'green',
      icon: ThumbsUp,
      label: 'Akkoord',
      status: 'accepted',
      mutation: ACCEPT_QUOTE,
    });
  } else if (acceptedSubjectToFunding) {
    iconButtons.push({
      color: 'green',
      icon: FileEuro,
      label: 'Akkoord O.V.V. financiering',
      status: 'acceptedSubjectToFunding',
      mutation: ACCEPT_QUOTE,
    });
  }
  if (hasPtcJob) {
    iconButtons.push({
      color: 'grayDark',
      icon: Wrench,
      label: 'Schouwopdracht',
    });
  }
  if (hasInstallationJob) {
    iconButtons.push({
      color: 'grayDarker',
      icon: Wrench,
      label: 'Installatieopdracht',
    });
  }
  if (dealPlanningStatus) {
    const iconProps = JobPlanningStatusIcons[dealPlanningStatus];
    iconButtons.push({
      icon: iconProps.icon,
      color: iconProps.color,
      label: `${
        relevantPlanningJob?.type === JobType.INSTALLATION ? 'Installatie' : 'Schouw'
      }: ${iconProps.title(relevantPlanningJob!)}`,
    });
  }
  if (cancelled) {
    iconButtons.push({
      color: 'red',
      icon: Block,
      label: 'Geannuleerd',
      status: 'cancelled',
      mutation: UPDATE_QUOTE,
    });
  }
  if (expired) {
    iconButtons.push({
      color: 'red',
      icon: Time,
      label: 'Verlopen',
      status: 'expired',
    });
  }
  if (completed) {
    iconButtons.push({
      color: 'gold',
      icon: Star,
      label: 'Geïnstalleerd',
      status: 'completed',
    });
  }
  return iconButtons;
}

type QuoteStatusIconsProps = {
  jobs: readonly jobsByQuote_jobsByQuote[];
  quote: quoteModalQuote_quoteById | quotesBySolution_quotesBySolution;
  files: any[];
};
const QuoteStatusIcons: React.FC<QuoteStatusIconsProps> = ({ jobs, quote, files }) => {
  const iconButtons = getQuoteActions(false, quote, jobs, files);
  return (
    <>
      {iconButtons.map((icon, index) => (
        <React.Fragment key={index}>
          <Tooltip
            key={`${icon.label}-${index}`}
            delay={100}
            content={icon.label.toUpperCase()}
            bgColor={icon.color}
            fontSize={8}
            fontWeight={600}
          >
            <StatusIcon iconColor={themify(icon.color)}>
              <Icon icon={icon.icon} solid fill="white" strokeWidth={2} width="16px" />
            </StatusIcon>
          </Tooltip>
          <Spacer />
        </React.Fragment>
      ))}
    </>
  );
};

export default QuoteStatusIcons;
