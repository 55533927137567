import _ from 'lodash';
import { useEffect, useState } from 'react';

/** Returns the window.innerheight as Real Viewport Height, so that mobile browsers work consistently
 * This is used for adjusting heights to mobile viewpowrts, where the URL bar scrolls out of view and messes up the native css 'vh' unit
 * @returns Real Viewport Height in pixels
 */
export function useRVH(): number {
  const [rvh, setRvh] = useState(window.innerHeight * 0.01);

  useEffect(() => {
    function handleResize() {
      setRvh(window.innerHeight * 0.01);
    }
    const throttledHandler = _.throttle(() => handleResize(), 100);
    window.addEventListener('resize', throttledHandler);
    return () => window.removeEventListener('resize', throttledHandler);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return rvh;
}
