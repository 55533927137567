import { gql } from '@apollo/client';

export const GET_ME = gql`
  query me {
    me {
      id
      __typename
      ... on Operator {
        firstName
        lastName
        email
        jobTitle
        role
        team
        isFreelancer
      }
    }
  }
`;
