import { isProduction } from '../config';
import { useActiveHouseId } from './useActiveHouseId';

const TRAINING_HOUSE_ID = '61f95b341398da94fa87960e';

const useTrainingHouse = () => {
  const { activeHouseId } = useActiveHouseId();
  return !isProduction && activeHouseId === TRAINING_HOUSE_ID;
};

export default useTrainingHouse;
