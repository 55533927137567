import React, { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { breakpointsPx } from '../styles/breakpoints';
import { useWindowSize } from './useWindowSize';

type HelpMenuState = { activeTab: number; isOpen: boolean };
type RightNavState = { activeTab: number; isOpen: boolean };

type NavHelperCtx = {
  searchActive: boolean;
  setSearchActive?: Dispatch<SetStateAction<boolean>>;

  leftNavOpen: boolean;
  setLeftNavOpen?: (state: boolean) => void;

  helpMenuState: HelpMenuState;
  setHelpMenuState?: (state: Partial<HelpMenuState>) => void;
  closeHelpMenu: () => void;

  rightNavState: RightNavState;
  setRightNavState?: (state: Partial<HelpMenuState>) => void;
  closeRightNav: () => void;

  ticketPopupOpen: boolean;
  toggleTicketPopup: () => void;
};

const defaultHelpMenuState = { activeTab: 1, isOpen: false };
const defaultRightNavState = { activeTab: 1, isOpen: false };

export const NavHelpersContext = React.createContext<NavHelperCtx>({
  searchActive: false,
  leftNavOpen: false,
  helpMenuState: defaultHelpMenuState,
  closeHelpMenu: () => null,
  rightNavState: defaultRightNavState,
  closeRightNav: () => null,
  ticketPopupOpen: false,
  toggleTicketPopup: () => null,
});

export const NavHelpersProvider: React.FC = ({ children }) => {
  const windowWidth = useWindowSize().width;
  const narrowScreen = useMemo(() => windowWidth < breakpointsPx.md, [windowWidth]);

  const [searchActive, setSearchActive] = useState(false);
  const [leftNavOpen, setLeftNavOpen] = useState<boolean>(
    window.localStorage.getItem('leftNavOpen')
      ? JSON.parse(window.localStorage.getItem('leftNavOpen') || '')
      : windowWidth >= 1600,
  );
  const [rightNavState, setRightNavState] = useState<RightNavState>(defaultRightNavState);
  const [helpMenuState, setHelpMenuState] = useState<HelpMenuState>(defaultHelpMenuState);
  const [ticketPopupOpen, setTicketPopupOpen] = useState(false);

  const handleSetLeftNavOpen = useCallback(
    (state: boolean) => {
      setLeftNavOpen(state);
      window.localStorage.setItem('leftNavOpen', JSON.stringify(state));
      if (narrowScreen) setSearchActive(false);
    },
    [narrowScreen],
  );

  const handleSetRightNavState = ({ activeTab, isOpen }: Partial<HelpMenuState>) => {
    if (activeTab !== undefined && isOpen !== undefined) setRightNavState({ activeTab, isOpen });
    else if (activeTab !== undefined) setRightNavState(prev => ({ ...prev, activeTab }));
    else if (isOpen !== undefined) setRightNavState(prev => ({ ...prev, isOpen }));
    if (narrowScreen) {
      setSearchActive(false);
    }
  };

  const handleSetHelpMenuState = ({ activeTab, isOpen }: Partial<HelpMenuState>) => {
    if (activeTab !== undefined && isOpen !== undefined) setHelpMenuState({ activeTab, isOpen });
    else if (activeTab !== undefined) setHelpMenuState(prev => ({ ...prev, activeTab }));
    else if (isOpen !== undefined) setHelpMenuState(prev => ({ ...prev, isOpen }));
    if (narrowScreen && isOpen) {
      // handleSetRightNavState({ isOpen: false });
      setSearchActive(false);
    }
  };

  const handleToggleTicketPopup = () => setTicketPopupOpen(!ticketPopupOpen);

  const closeHelpMenu = useCallback(() => {
    setHelpMenuState(prev => ({ ...prev, isOpen: false }));
  }, []);

  const closeRightNav = useCallback(() => {
    setRightNavState(prev => ({ ...prev, isOpen: false }));
  }, []);

  return (
    <NavHelpersContext.Provider
      value={{
        searchActive,
        setSearchActive,
        leftNavOpen,
        setLeftNavOpen: handleSetLeftNavOpen,
        helpMenuState,
        setHelpMenuState: handleSetHelpMenuState,
        closeHelpMenu,
        rightNavState,
        setRightNavState: handleSetRightNavState,
        closeRightNav,
        ticketPopupOpen,
        toggleTicketPopup: handleToggleTicketPopup,
      }}
    >
      {children}
    </NavHelpersContext.Provider>
  );
};

export function useNavHelpers() {
  return useContext(NavHelpersContext);
}
