import {
  getQuoteTotals,
  IDiscount,
  IInstallationCalculationItem,
} from '@energiebespaarders/constants';
import { Counter, Table } from '@energiebespaarders/symbols';
import React from 'react';
import { delimit } from '../../lib/utils';
import {
  installationByHouseSolution_installationByHouseSolution_discount as t_discount,
  installationByHouseSolution_installationByHouseSolution_items as t_item,
  installationByHouseSolution_installationByHouseSolution_items_product as t_item_product,
} from '../../types/generated/installationByHouseSolution';

type TotalsRow = {
  title: string;
  value: number;
  rowProps: Record<string, any>;
};

type TotalsRowsProps = {
  items: readonly (Omit<t_item, 'product' | 'tieredRetailPrice'> & {
    product: Omit<t_item_product, 'archived'>;
  })[];
  discount?: t_discount;
  mobile: boolean;
  readOnly: boolean;
  explicitlyLabelAsRetailPrice?: boolean;
};

const TotalsRows: React.FC<TotalsRowsProps> = ({
  items,
  discount,
  mobile,
  readOnly,
  explicitlyLabelAsRetailPrice,
}) => {
  const {
    totalExcludingTax,
    totalLowTax,
    totalHighTax,
    totalIncludingTax,
    totalDiscountIncludingTax,
    totalIncludingTaxAfterDiscount,
  } = getQuoteTotals([...items] as IInstallationCalculationItem[], discount as IDiscount);

  const mobileTotalsLayout = mobile
    ? [(readOnly ? 16 : 15) / 24, 8 / 24, readOnly ? 0 : 1 / 24]
    : [(readOnly ? 21 : 20) / 24, 3 / 24, readOnly ? 0 : 1 / 24];
  const hasDiscount = totalDiscountIncludingTax > 0;
  const rows: TotalsRow[] = [
    {
      title: `Totaal ${explicitlyLabelAsRetailPrice ? 'verkoop ' : ''}excl. BTW`,
      value: totalExcludingTax,
      rowProps: {
        rowBorderColor: 'gray',
        rowBorderStyle: '3px double',
        rowColor: 'grayDarker',
      },
    },
    ...(totalLowTax > 0
      ? [
          {
            title: '9% BTW',
            value: totalLowTax,
            rowProps: { rowColor: 'grayDarker' },
          },
        ]
      : []),
    ...(totalHighTax > 0
      ? [
          {
            title: '21% BTW',
            value: totalHighTax,
            rowProps: { rowColor: 'grayDarker' },
          },
        ]
      : []),
    ...(discount && hasDiscount
      ? [
          {
            title: 'Totaal incl. BTW zonder korting',
            value: totalIncludingTax,
            rowProps: {
              fontWeight: 600,
              rowColor: 'grayDarker',
            },
          },
          {
            title: `Korting (${
              Math.round((totalDiscountIncludingTax / totalIncludingTax) * 100 * 100) / 100
            }%): ${discount.text}`,
            value: -totalDiscountIncludingTax,
            rowProps: { fontWeight: 600, rowColor: 'green' },
          },
        ]
      : []),
    {
      title: 'Totaal incl. BTW',
      value: totalIncludingTaxAfterDiscount,
      rowProps: {
        fontWeight: 600,
        rowColor: hasDiscount ? 'green' : 'grayDarker',
      },
    },
  ];
  return (
    <>
      <Table>
        {rows.map((row: TotalsRow, index: number) => (
          <Table.Row
            key={`total-${index}`}
            keepLayout
            layout={mobileTotalsLayout}
            rowBgColor="white"
            {...row.rowProps}
          >
            <Table.Cell textAlign="right">{row.title}</Table.Cell>
            <Table.Cell textAlign="right">
              <div style={{ whiteSpace: 'nowrap' }}>
                €{' '}
                <Counter
                  value={row.value || 0}
                  formatValue={(n: number) => delimit(n)}
                  duration={300}
                />
              </div>
            </Table.Cell>
            <Table.Cell hide={readOnly} />
          </Table.Row>
        ))}
      </Table>
    </>
  );
};

export default TotalsRows;
