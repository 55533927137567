import { useMutation } from '@apollo/client';
import {
  Box,
  Container,
  Flex,
  Input,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from '@energiebespaarders/symbols';
import { Gray, Medium, Small } from '@energiebespaarders/symbols/helpers';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMe } from '../../hooks/useMe';
import useToaster from '../../hooks/useToaster';
import { MANUAL_JOURNEY_DATA } from '../../lib/constants';
import { ACCEPT_QUOTE, ACCEPT_QUOTE_SUBJECT_TO_FUNDING } from '../../queries/installatron';
import { acceptQuote, acceptQuoteVariables } from '../../types/generated/acceptQuote';
import {
  acceptSubjectToFunding,
  acceptSubjectToFundingVariables,
} from '../../types/generated/acceptSubjectToFunding';
import { quoteModalQuote_quoteById } from '../../types/generated/quoteModalQuote';

type AcceptQuoteModalProps = {
  quote: quoteModalQuote_quoteById;
  closeModal: () => void;
  isOpen?: boolean;
  mobile?: boolean;
  src?: string;
  alt?: string;
  isGVAHouse: boolean;
};

const AcceptQuoteModal: React.FC<AcceptQuoteModalProps> = ({
  quote,
  closeModal,
  isOpen,
  mobile,
  isGVAHouse,
}) => {
  const toast = useToaster();
  const { me } = useMe();
  const now = useMemo(() => dayjs(new Date()).format('YYYY-MM-DD'), []);
  const [acceptType, setAcceptType] = useState('');
  const [dateFundingExpected, setDateFundingExpected] = useState(now);
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(false);
  const [subjectToFunding, setSubjectToFunding] = useState(false);
  const options = useMemo(
    () => [
      { value: '', label: 'Kies een optie', disabled: true },
      { value: 'inPerson', label: 'Thuis' },
      { value: 'phone', label: 'Telefonisch' },
      { value: 'email', label: 'Via de mail' },
      { value: 'jobMutation', label: 'Opdrachtwijziging' }, // used internally by NS, temporary entry until the snowball has fully melted
    ],
    [],
  );
  useEffect(() => {
    if (isGVAHouse) setSendConfirmationEmail(false);
  }, [isGVAHouse]);

  const [acceptQuote, { loading: acceptLoading }] = useMutation<acceptQuote, acceptQuoteVariables>(
    ACCEPT_QUOTE,
    {
      variables: {
        id: quote.id,
        acceptType,
        journeyData: MANUAL_JOURNEY_DATA,
        acceptedBy: `${me.firstName} ${me.lastName}`,
        skipCustomerEmail: !sendConfirmationEmail,
      },
      onCompleted: () => closeModal(),
      onError: ({ message }) => toast({ type: 'error', message }),
    },
  );
  const [acceptQuoteSubjectToFunding, { loading: acceptSubjectToFundingLoading }] = useMutation<
    acceptSubjectToFunding,
    acceptSubjectToFundingVariables
  >(ACCEPT_QUOTE_SUBJECT_TO_FUNDING, {
    variables: {
      quoteId: quote.id,
      dateFundingExpected: new Date(dateFundingExpected),
      acceptType,
      skipCustomerEmail: !sendConfirmationEmail,
    },
    onCompleted: () => closeModal(),
    onError: ({ message }) => toast({ type: 'error', message }),
  });

  const handleAccept = useCallback(() => {
    if (!acceptType) {
      return window.alert(
        'Er is geen wijze van akkoord aangegeven. Dit is belangrijk voor verdere communicatie en de timing van vervolgacties en herinneringen.',
      );
    }
    return subjectToFunding ? acceptQuoteSubjectToFunding() : acceptQuote();
  }, [acceptQuote, acceptQuoteSubjectToFunding, acceptType, subjectToFunding]);

  const loading = acceptLoading || acceptSubjectToFundingLoading;

  return (
    <Modal
      isOpen={!!isOpen}
      mobile={mobile}
      width="md"
      title="Ter bevestiging van akkoord"
      shouldCloseOnOverlayClick={false}
      timeout={mobile ? 250 : undefined}
      buttons={[
        {
          disabled: loading,
          bgColor: 'red',
          inverse: true,
          label: 'Terug',
          onClick: closeModal,
        },
        {
          loading,
          label: 'Bevestigen',
          onClick: handleAccept,
          disabled: !acceptType,
        },
      ]}
    >
      <Flex>
        <Box width={1} my={2}>
          <Small>
            <p>
              <Medium>
                Voor je de offerte op akkoord kunt zetten, moet je onderstaande vragen beantwoorden.
              </Medium>
            </p>

            <Container size="md">
              <p>
                Wil je een bevestiging per mail naar de klant versturen?{' '}
                {isGVAHouse && <Small>(Niet van toepassing voor GavoorA klanten)</Small>}
              </p>
              <Flex flexWrap="wrap" mx="-3px">
                <Box width={1 / 2} px="3px">
                  <Radio
                    id="dontSend"
                    value={false}
                    label="Nee, niet mailen"
                    onChange={() => setSendConfirmationEmail(false)}
                    checked={sendConfirmationEmail === false}
                    width="100%"
                  />
                </Box>
                <Box width={1 / 2} px="3px">
                  <Radio
                    id="doSend"
                    value={true}
                    label="Ja, stuur bevestiging"
                    onChange={() => setSendConfirmationEmail(true)}
                    checked={sendConfirmationEmail}
                    width="100%"
                    disabled={isGVAHouse}
                  />
                </Box>
                <Box width={1} px="3px">
                  <Select
                    isClearable={false}
                    options={options}
                    value={options.find(opt => opt.value === acceptType)}
                    onChange={(e: { value: string }) => setAcceptType(e.value)}
                    label="Wijze van akkoord"
                    placeholder="Kies een optie"
                  />
                </Box>{' '}
                <Box width={1} px="3px" mt={4}>
                  <p>
                    Vink hieronder aan om een extra notitie te maken bij de deal, zodat de opdracht
                    nog niet wordt ingepland.
                  </p>
                  <RadioGroup
                    label="Akkoord is onder voorbehoud van financiering"
                    id="isSubject"
                    value={true}
                    onChange={() => setSubjectToFunding(!subjectToFunding)}
                  >
                    <Radio
                      label="Financiering is rond"
                      value={true}
                      checked={subjectToFunding === false}
                      key="rond"
                    />
                    <Radio
                      label="Onder voorbehoud van financiering"
                      value={false}
                      checked={subjectToFunding === true}
                      key="ovvf"
                    />
                  </RadioGroup>
                </Box>
                {subjectToFunding && (
                  <Box width={1 / 2}>
                    <p>
                      <Gray>
                        Er wordt een deal aangemaakt in de 'Onder voorbehoud van financiering'
                        pijplijn en de advies deal wordt naar het stadium 'On hold / voorbehoud van
                        financiering' verplaatst.
                      </Gray>
                    </p>
                    <label>Verwachtte datum financiering rond</label>
                    <Input
                      type="date"
                      min={dayjs().format('YYYY-MM-DD')}
                      label="Datum"
                      value={dateFundingExpected}
                      onChange={e => setDateFundingExpected(e.target.value)}
                    />
                  </Box>
                )}
              </Flex>
            </Container>
          </Small>
        </Box>
      </Flex>
    </Modal>
  );
};

export default AcceptQuoteModal;
