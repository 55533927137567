import React, { useCallback } from 'react';
import { GET_HOUSE_FILES, DELETE_HOUSE_FILE } from '../queries/files';
import { Gallery, Placeholder } from '@energiebespaarders/symbols';
import { fileUrl } from '../config';
import { useMutation, useQuery } from '@apollo/client';
import { getHouseFiles, getHouseFilesVariables } from '../types/generated/getHouseFiles';
import { deleteHouseFile, deleteHouseFileVariables } from '../types/generated/deleteHouseFile';

interface HouseGalleryProps {
  exclusiveMatch?: boolean;
  houseId: string;
  readOnly?: boolean;
  tags: string[];
  onDelete?: () => void;
}

const HouseGallery: React.FC<HouseGalleryProps> = ({
  exclusiveMatch = true,
  houseId,
  readOnly,
  tags,
  onDelete,
}) => {
  const { data, loading, error } = useQuery<getHouseFiles, getHouseFilesVariables>(
    GET_HOUSE_FILES,
    { variables: { houseId } },
  );
  const [deleteHouseFileMutation] = useMutation<deleteHouseFile, deleteHouseFileVariables>(
    DELETE_HOUSE_FILE,
  );
  const deleteFile = useCallback(
    (fileId: string) => {
      deleteHouseFileMutation({
        variables: {
          id: houseId,
          fileId,
        },
        update: store => {
          const queryData = store.readQuery<getHouseFiles, getHouseFilesVariables>({
            query: GET_HOUSE_FILES,
            variables: { houseId },
          });
          if (queryData) {
            const newFiles = queryData.house.files.filter(file => file.id !== fileId);
            store.writeQuery({
              query: GET_HOUSE_FILES,
              data: {
                house: {
                  ...queryData.house,
                  files: newFiles,
                },
              },
              variables: { houseId },
            });
          }
          onDelete?.();
        },
      });
    },
    [deleteHouseFileMutation, houseId, onDelete],
  );
  if (loading || error) return <Placeholder error={error} />;
  return (
    <Gallery
      deleteFile={readOnly ? undefined : deleteFile}
      exclusiveMatch={exclusiveMatch}
      files={data?.house.files ?? []}
      fileUrl={fileUrl}
      gutter={1}
      size="ss"
      tags={tags}
      thumbSize={100}
    />
  );
};

export default HouseGallery;
