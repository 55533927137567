import { Box, Button } from '@energiebespaarders/symbols';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { JobType } from '../../../types/graphql-global-types';

interface SignoffDetailsProps {
  job: {
    id: string;
    type: JobType;
    installerSignoff: {
      created: Date;
    } | null;
  };
}

const SignoffDetailsLink: React.FC<SignoffDetailsProps> = ({ job }) => {
  if (!job.installerSignoff) return null;
  const isInstallation = job.type === JobType.INSTALLATION;
  return (
    <Box py={1}>
      <p>
        De {isInstallation ? 'opleverdocument' : 'schouwresultaten'} voor deze deal zijn op{' '}
        {dayjs(job.installerSignoff.created).format('DD/MM/YY')} aangemaakt.
      </p>
      <Link to={`/${isInstallation ? 'opleverdocument' : 'schouw-resultaten'}/${job.id}`}>
        <Button>Ga naar {isInstallation ? 'opleverdocument' : 'schouw resultaten'}</Button>
      </Link>
    </Box>
  );
};

export default SignoffDetailsLink;
