import { useMutation } from '@apollo/client';
import { Modal, Button } from '@energiebespaarders/symbols';
import { MeasuringTape, Page } from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useState } from 'react';
import { fileUrl } from '../../../config';
import { useMe } from '../../../hooks/useMe';
import useToaster from '../../../hooks/useToaster';
import { UPLOAD_QUOTE_FILES, GET_QUOTE_FILES } from '../../../queries/files';
import { getQuoteFiles, getQuoteFilesVariables } from '../../../types/generated/getQuoteFiles';
import {
  uploadQuoteFiles,
  uploadQuoteFiles_uploadQuoteFiles,
} from '../../../types/generated/uploadQuoteFiles';
import HouseImagePicker from '../../HouseImagePicker';
import QuotesImagePicker from '../../QuotesImagePicker';

type QuoteFile = uploadQuoteFiles_uploadQuoteFiles;

interface ICopyFileToQuoteModalProps {
  quoteId: string;
  houseId: string;
  tags: string[];
}

const CopyFileToQuoteModal = ({ quoteId, houseId, tags }: ICopyFileToQuoteModalProps) => {
  const [selectedUpload, setSelectedUpload] = useState<QuoteFile | null>();
  const [isPickingFile, setIsPickingFile] = useState<'quote' | 'house'>();
  const handleClose = useCallback(() => setIsPickingFile(undefined), []);
  const [isLoading, setIsLoading] = useState(false);

  const { me } = useMe();
  const toast = useToaster();
  const [uploadQuoteFilesMutation, { error }] = useMutation<uploadQuoteFiles>(UPLOAD_QUOTE_FILES, {
    update: (store, { data }) => {
      const queryData = store.readQuery<getQuoteFiles, getQuoteFilesVariables>({
        query: GET_QUOTE_FILES,
        variables: { quoteId },
      });
      if (queryData) {
        store.writeQuery({
          query: GET_QUOTE_FILES,
          data: {
            quoteById: {
              ...queryData.quoteById,
              files: queryData.quoteById?.files
                ? queryData.quoteById.files.concat(data?.uploadQuoteFiles ?? [])
                : data?.uploadQuoteFiles ?? [],
            },
          },
          variables: { quoteId },
        });
      }
    },
    onCompleted: () => {
      setIsLoading(false);
      setIsPickingFile(undefined);
      toast({
        type: 'success',
        message: 'Bestanden zijn succesvol geüpload',
      });
    },
    onError: () =>
      toast({
        type: 'error',
        message: 'Er is een fout opgetreden bij het uploaden van bestanden',
      }),
  });

  const handleInsert = useCallback(async () => {
    if (!selectedUpload) return;
    setIsLoading(true);

    // Two options: Either just copy the file ID over to quote.files, or re-upload entire file.
    // re-uploading is safer in case the old file is deleted.
    // probably will only be used for 1 or 2 specific files anyways, so not a lot of overhead
    // So, re-uploading it is:

    // 1. Fetch file
    const res = await fetch(`${fileUrl}/${selectedUpload.id}.${selectedUpload.extension}`);
    const filename = selectedUpload.metadata.title || `Upload ${new Date().toLocaleString()}`;
    const blob = await res.blob();
    const copiedFile = new File([blob], `${filename}.${selectedUpload.extension}`);

    // 2. Upload to quote (copied from QuoteUploader.tsx)
    uploadQuoteFilesMutation({
      variables: {
        quoteId,
        files: [copiedFile],
        tags,
        uploaderId: me?.id,
        uploaderUserType: 'operator',
      },
    });
  }, [me?.id, quoteId, selectedUpload, tags, uploadQuoteFilesMutation]);

  return (
    <>
      <Button
        fluid
        iconStart={MeasuringTape}
        onClick={() => setIsPickingFile('house')}
        label="Kies afbeelding uit opname"
        mr={0}
      />
      <Button
        fluid
        iconStart={Page}
        onClick={() => setIsPickingFile('quote')}
        label="Of uit een andere offerte"
        bgColor="orange"
        mr={0}
        mb={0}
      />
      <Modal
        title="Bestand kopiëren naar offerte"
        isOpen={!!isPickingFile}
        onRequestClose={handleClose}
        buttons={[
          {
            disabled: !selectedUpload,
            label: 'Invoegen',
            bgColor: 'orange',
            onClick: handleInsert,
            loading: isLoading,
            error,
            fontSize: 5,
          },
          {
            label: 'Sluiten',
            bgColor: 'red',
            onClick: handleClose,
            fontSize: 5,
          },
        ]}
      >
        {isPickingFile === 'house' ? (
          <HouseImagePicker
            selectedFile={selectedUpload}
            setSelectedFile={setSelectedUpload}
            houseId={houseId}
            tags={[]}
            exclusiveMatch
          />
        ) : (
          <QuotesImagePicker
            selectedFile={selectedUpload}
            setSelectedFile={setSelectedUpload}
            houseId={houseId}
            tags={[]}
            exclusiveMatch
          />
        )}
      </Modal>
    </>
  );
};

export default CopyFileToQuoteModal;
