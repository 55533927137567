import { ReactNode, useState, useMemo } from 'react';

export interface IStep {
  id: string;
  title: string;
  content: ReactNode;
  isInputValid: boolean;
  isIrrelevant?: boolean;
  onSubmit?: () => Promise<void>;
}

const useStepper = (steps: IStep[], setIsLastStep: (value: boolean) => void) => {
  const [stepId, setStepId] = useState(steps[0]?.id);

  const relevantSteps = useMemo(() => steps.filter(s => !s.isIrrelevant), [steps]);
  const step = relevantSteps.find(step => step.id === stepId);

  const setStepIdHandler = (id: string) => {
    setStepId(id);
    setIsLastStep(id === relevantSteps[relevantSteps.length - 1].id);
  };

  const handlePrevStep = () => {
    setStepIdHandler(relevantSteps[relevantSteps.findIndex(s => s.id === stepId) - 1]?.id);
  };

  const handleNextStep = () => {
    const goToNext = () =>
      setStepIdHandler(relevantSteps[relevantSteps.findIndex(s => s.id === stepId) + 1]?.id);

    if (step?.onSubmit) {
      step.onSubmit().then(goToNext);
      return;
    }
    goToNext();
  };

  const resetProgress = () => setStepId(relevantSteps[0]?.id);

  return {
    relevantSteps,
    step,
    handlePrevStep,
    handleNextStep,
    resetProgress,
    setStepId: setStepIdHandler,
  };
};

export default useStepper;
