import { darken, rgba } from 'polished';
import React, { useState, useEffect, ReactNode, CSSProperties, useCallback } from 'react';
import { scrollSpy } from 'react-scroll';
import styled from 'styled-components';
import { Icon } from '@energiebespaarders/symbols';
import { Cancel, EllipsisVertical } from '@energiebespaarders/symbols/icons/solid';
import { themify } from '../../styles/mixins';
import theme from '../../styles/theme';
import { useLocation } from 'react-router';

interface StyledSubNavBarProps {
  $amountItems: number;
  $bgColor: string;
  $borderColor: string;
  $height: string;
  $isDropdown: boolean;
  $offsetLeft: number;
  $offsetRight: number;
  $open: boolean;
}

const StyledSubNavBar = styled.nav<StyledSubNavBarProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: ${x => x.$offsetRight};
  display: ${x => (x.$isDropdown ? 'block' : 'flex')};
  flex-flow: row nowrap;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  height: ${x => (x.$isDropdown && x.$open ? `calc(${x.$height} * ${x.$amountItems})` : x.$height)};
  line-height: ${x => x.$height};
  background: ${x => (x.$isDropdown ? x.theme.colors.grayLighter : x.$bgColor)};
  border-bottom: 1px solid ${x => x.$borderColor};
  transform: ${x => `translateX(${x.$offsetLeft || `calc(-1 * ${x.$offsetRight})`}) translateZ(0)`};
  z-index: ${x => x.theme.z.maskedNav};
  transition: all 0.2s ${x => x.theme.curves.standard};
`;

export const ActiveIndicator = styled.div<{ $amountItems: number }>`
  position: absolute;
  bottom: 1px;
  left: 0;
  display: none;
  width: ${x => `calc(100% / ${x.$amountItems})`};
  height: 3px;
  border-radius: 3px 3px 0 0;
  background: ${x => x.theme.colors.green};
  transition: left 0.2s ${x => x.theme.curves.sharp};
`;

export const SubNavItems = styled.div<{
  $activeNavItem: number;
  $height: string;
  $isDropdown: boolean;
  $open: boolean;
}>`
  position: ${x => (x.$isDropdown ? 'relative' : 'fixed')};
  display: ${x => (x.$isDropdown ? 'block' : 'flex')};
  flex-flow: row nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  width: 100%;
  height: ${x => `calc(${x.$isDropdown ? 1.5 : 1} * ${x.$height})`};
  white-space: nowrap;
  transform: ${x =>
    x.$isDropdown && !x.$open
      ? `translateY(calc(-${x.$activeNavItem} * ${x.$height} * 1.5))`
      : 'none'};
  transition: transform 0.2s ${x => x.theme.curves.standard};
`;

const SubNavToggle = styled.div<{ $bgColor: string }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 100%;
  cursor: pointer;
  pointer-events: all;
  transition: background 0.2s ${x => x.theme.curves.sharp};

  &:hover {
    background: ${x => rgba(darken(0.1, x.$bgColor), 0.5)};
  }
`;

const DropdownIcon = styled(Icon)`
  position: absolute;
  top: 1em;
  right: 1em;
`;

interface SubNavBarProps {
  bgColor?: string;
  borderColor?: string;
  children: ReactNode[];
  height?: string;
  isDropdown: boolean;
  offsetLeft?: number;
  offsetRight?: number;
  role?: string;
  style?: CSSProperties;
  shouldUpdateActiveNavFromPath?: boolean;
}

const SubNavBar: React.FC<SubNavBarProps> = ({
  bgColor = 'white',
  borderColor = 'grayLight',
  children,
  offsetLeft = 0,
  offsetRight = 0,
  isDropdown,
  style,
  shouldUpdateActiveNavFromPath = true,
}) => {
  const [activeNavItem, setActiveNavItem] = useState(0);
  const { pathname } = useLocation();
  const [subNavOpen, setSubNavOpen] = useState(false);
  const height = theme.fixedSizes.subNavHeight;

  useEffect(() => {
    scrollSpy.update();
    if (shouldUpdateActiveNavFromPath) {
      const subNavLinks = React.Children.map(
        children,
        (child: any) => child.props.to || '',
      ) as string[];
      const activeNavIndex = subNavLinks.indexOf(pathname);
      if (activeNavIndex >= 0 && activeNavIndex !== activeNavItem) {
        setActiveNavItem(activeNavIndex);
      }
    }
  }, [activeNavItem, children, pathname, shouldUpdateActiveNavFromPath]);

  const toggleSubNav = useCallback(() => setSubNavOpen(v => !v), []);

  return (
    <StyledSubNavBar
      $amountItems={React.Children.count(children)}
      $bgColor={themify(bgColor)}
      $borderColor={themify(borderColor)}
      $height={isDropdown ? `calc(1.5 * ${height})` : height}
      $isDropdown={isDropdown}
      $offsetLeft={offsetLeft}
      $offsetRight={offsetRight}
      onClick={isDropdown ? toggleSubNav : undefined}
      $open={subNavOpen}
      role="navigation"
      style={style}
    >
      <SubNavItems
        $activeNavItem={activeNavItem}
        $height={theme.fixedSizes.subNavHeight}
        $isDropdown={isDropdown}
        onClick={isDropdown && subNavOpen ? toggleSubNav : undefined}
        $open={subNavOpen}
      >
        {React.Children.map(children, (child: any) =>
          React.cloneElement(child, {
            activeItem: activeNavItem,
            indicatorOffset: (100 / React.Children.count(children)) * children.indexOf(child),
            isDropdown,
            itemIndex: children.indexOf(child),
            handleSetActive: (item: number) => setActiveNavItem(item),
            toggleSubNav,
          }),
        )}
        {!isDropdown && <ActiveIndicator $amountItems={React.Children.count(children)} />}
      </SubNavItems>

      {isDropdown && (
        <SubNavToggle $bgColor={themify(bgColor)}>
          <DropdownIcon
            icon={subNavOpen ? Cancel : EllipsisVertical}
            solid
            width="18px"
            height="18px"
            hoverColor={subNavOpen ? 'red' : 'green'}
            rotate={subNavOpen ? 180 : 0}
          />
        </SubNavToggle>
      )}
    </StyledSubNavBar>
  );
};

export default SubNavBar;
