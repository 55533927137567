import { ApolloError, useMutation } from '@apollo/client';
import { Box, Button, Icon, Range, Spacer, Toast, Tooltip } from '@energiebespaarders/symbols';
import { Center, Medium, Red, Small } from '@energiebespaarders/symbols/helpers';
import { AddCircle, CancelCircle, Time } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import { sum } from 'lodash';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHasPricingPermission } from '../../hooks/useHasPricingPermission';
import useToaster from '../../hooks/useToaster';
import { delimit } from '../../lib/utils';
import { UPDATE_QUOTE_VALIDITY } from '../../queries/installatron';
import {
  quoteModalQuote_quoteById_items,
  quoteModalQuote_quoteById_updatedRetailPrices,
} from '../../types/generated/quoteModalQuote';
import { PriceAvailability } from '../../types/graphql-global-types';

const TimeIcon = styled(Icon)`
  display: inline-block;
`;

const ExtendIcon = styled(Icon)`
  display: none;
`;

const StyledExpirationDate = styled.div`
  position: relative;

  &:hover {
    ${TimeIcon} {
      display: none;
    }

    ${ExtendIcon} {
      display: inline-block;
    }
  }
`;

type Props = {
  expirationDate: Date;
  quoteId: string;
  quoteItems: readonly quoteModalQuote_quoteById_items[];
  updatedRetailPrices: readonly quoteModalQuote_quoteById_updatedRetailPrices[];
};

const Expiration: React.FC<Props> = ({
  expirationDate,
  quoteId,
  quoteItems,
  updatedRetailPrices,
}) => {
  const [showExtender, setShowExtender] = useState(false);
  const [extensionPeriod, setExtensionPeriod] = useState(7);
  const toast = useToaster();
  const hasPricingPermission = useHasPricingPermission();

  const [updateQuoteValidity] = useMutation(UPDATE_QUOTE_VALIDITY, {
    variables: {
      quoteId,
      date: dayjs(expirationDate).add(extensionPeriod, 'day').toISOString(),
    },
    onCompleted: () => {
      toast({
        type: 'success',
        message: 'Offerte succesvol aangepast',
      });
    },
    onError: (error: ApolloError) => {
      toast({
        type: 'error',
        message: error.message,
      });
    },
  });

  const currentDate = dayjs();
  const minutesRemaining = dayjs(expirationDate).diff(currentDate, 'minute');
  const formattedExpirationDate = dayjs(expirationDate).format('dddd D MMMM').toLowerCase();

  const currPrice = useMemo(() => sum(quoteItems.map(item => item.retailPrice * item.amount)), [
    quoteItems,
  ]);

  const newPrice = useMemo(
    () =>
      sum(
        quoteItems.map(
          item =>
            item.amount *
            (updatedRetailPrices.find(p => item.product.id === p.product.id)?.retailPrice ??
              item.retailPrice),
        ),
      ),
    [quoteItems, updatedRetailPrices],
  );
  // If new prices are higher than current ones: block quote extending
  // if new prices are lower, that's fine, more money for us!
  const areNewPricesHigher = newPrice > currPrice;

  // Compare current and new prices here. The updatedRetailPrices query now returns the new prices
  const updatedPricesMessage = (
    <div>
      <p>De volgende producten hebben nieuwe verkoopprijzen:</p>
      <ul>
        {updatedRetailPrices.map(item => {
          const currItem = quoteItems.find(i => i.product.id === item.product.id);
          return (
            <li key={item.product!.id!}>
              {item.product!.title} (€{delimit(currItem?.retailPrice, 2)} ⇒ €
              {delimit(item.retailPrice, 2)})
            </li>
          );
        })}
      </ul>
      {areNewPricesHigher && (
        <p>
          Er moet een <Medium>nieuwe offerte</Medium> opgesteld worden.
        </p>
      )}
    </div>
  );

  const hasUnavailablePrices = useMemo(
    () => quoteItems.some(i => i.price.availability !== PriceAvailability.inStock),
    [quoteItems],
  );

  const unavailableProductsMessage = (
    <div>
      <p>De volgende producten zijn niet meer beschikbaar bij de installateur</p>
      <ul>
        {quoteItems
          .filter(i => i.price.availability !== PriceAvailability.inStock)
          .map(item => (
            <li key={item.product.id}>
              {item.product!.title} €{delimit(item.retailPrice, 2)})
            </li>
          ))}
      </ul>
    </div>
  );

  const handleExtendQuote = useCallback(() => {
    if (hasUnavailablePrices || areNewPricesHigher) {
      const reason = hasUnavailablePrices
        ? 'Sommige producten zijn niet meer beschikbaar bij de installateur.'
        : 'Er zijn nieuwe verkoopprijzen beschikbaar.';
      if (!hasPricingPermission) {
        window.alert(
          `${reason} Er moet een nieuwe offerte gemaakt worden, of vraag MT of NS om deze offerte toch nog te verlengen.`,
        );
      } else if (
        window.confirm(
          `Let op: ${reason} In principe is verlengen van deze offerte niet toegestaan.\n
            Jij kunt deze blokkade omzeilen.
            \nWeet je zeker dat je wilt verlengen?`,
        )
      ) {
        updateQuoteValidity();
      }
    } else {
      updateQuoteValidity();
    }
  }, [areNewPricesHigher, hasPricingPermission, hasUnavailablePrices, updateQuoteValidity]);

  return (
    <>
      <StyledExpirationDate onClick={() => setShowExtender(!showExtender)}>
        <TimeIcon
          icon={Time}
          fill={minutesRemaining > 0 ? 'gray' : 'red'}
          inline
          solid
          width="1.25rem"
        />
        <Tooltip
          content={showExtender ? 'Annuleren' : 'Aanpassen'}
          bgColor={showExtender ? 'red' : 'green'}
        >
          <ExtendIcon
            icon={showExtender ? CancelCircle : AddCircle}
            inline
            solid
            fill={showExtender ? 'red' : 'green'}
            width="1.25rem"
          />
        </Tooltip>
        <Spacer amount={2} />
        <Small>
          Geldig t/m{' '}
          <Medium>
            {formattedExpirationDate} {minutesRemaining <= 0 && <Red>VERLOPEN</Red>}
          </Medium>
        </Small>
      </StyledExpirationDate>

      {showExtender && (
        <Box width={1} mt={1}>
          {updatedRetailPrices.length > 0 && (
            <Toast type="alert" message={updatedPricesMessage} width="100%" toastId={1000} />
          )}
          {hasUnavailablePrices && (
            <Toast type="alert" message={unavailableProductsMessage} width="100%" toastId={1001} />
          )}
          <Center block>
            <Range
              min={-14}
              max={14}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setExtensionPeriod(Number(e.target.value))
              }
              showValue={false}
              step={1}
              value={extensionPeriod}
              trackBgColor={extensionPeriod === 0 ? 'gray' : extensionPeriod < 0 ? 'red' : 'green'}
              mb={0}
            />
            <Button
              bgColor={extensionPeriod === 0 ? 'gray' : extensionPeriod < 0 ? 'red' : 'green'}
              label={`Geldigheid met ${Math.abs(extensionPeriod)} dag${
                Math.abs(extensionPeriod) !== 1 ? 'en' : ''
              } ${extensionPeriod >= 0 ? 'verlengen' : 'verkorten'}`}
              fontSize={6}
              fluid
              mb={0}
              minimal
              disabled={extensionPeriod > 0 && (areNewPricesHigher || hasUnavailablePrices)}
              onClick={handleExtendQuote}
              p={1}
            />
          </Center>
        </Box>
      )}
    </>
  );
};

export default Expiration;
