import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Flex, Box } from '@energiebespaarders/symbols';

import { TinyHeading } from '@energiebespaarders/symbols/helpers';

const DataModal = ({ closeModal, dataItem, isOpen, mobile }) => {
  return (
    <Modal
      key={`modal-${dataItem.label}`}
      isOpen={isOpen}
      onRequestClose={closeModal}
      title={`${dataItem.label}`}
      mobile={mobile}
      size="sm"
      timeout={mobile ? 250 : undefined}
      buttons={[
        {
          bgColor: 'red',
          inverse: true,
          label: 'Terug',
          onClick: () => closeModal(),
          m: 0,
        },
      ]}
    >
      <Flex>
        <Box width={1 / 2}>
          <TinyHeading color="grayDark">Bron</TinyHeading>
          Bespaarcheck
        </Box>
        <Box width={1 / 2}>
          <TinyHeading color="grayDark">Waarde</TinyHeading>
          {dataItem.value}
        </Box>
      </Flex>
    </Modal>
  );
};

DataModal.propTypes = {
  closeModal: PropTypes.func,
  dataItem: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    unit: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default DataModal;
