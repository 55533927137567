import { ApolloError, gql, useQuery } from '@apollo/client';
import { delimit } from '@energiebespaarders/constants';
import { Placeholder } from '@energiebespaarders/symbols';
import { Color, Small } from '@energiebespaarders/symbols/helpers';
import React, { ReactNode, useState } from 'react';
import { useHasPricingPermission } from '../../hooks/useHasPricingPermission';
import { PRICE_AVAILABILITY_NL } from '../../lib/utils/getPriceAvailabilityProps';
import {
  getPriceWithRevisions,
  getPriceWithRevisionsVariables,
  getPriceWithRevisions_getPrice_revisions,
} from '../../types/generated/getPriceWithRevisions';
import {
  productPriceRevisions,
  productPriceRevisionsVariables,
  productPriceRevisions_productById_priceRevisions,
  productPriceRevisions_productById_priceRevisions_diff,
} from '../../types/generated/productPriceRevisions';
import Changelog from '../Changelog';

const PriceFieldLabels: Record<
  keyof productPriceRevisions_productById_priceRevisions_diff,
  string
> = {
  __typename: '',
  id: '',
  retailPrice: 'Verkoopprijs',
  purchasePrice: 'Inkoopprijs',
  availability: 'Beschikbaarheid',
  minimumInstallerRate: 'Min. tarief',
  supplier: 'Leverancier',
  product: 'Product',
};

const PriceFieldFormatter: Partial<
  Record<
    keyof productPriceRevisions_productById_priceRevisions_diff,
    (value: productPriceRevisions_productById_priceRevisions_diff) => ReactNode
  >
> = {
  supplier: ({ supplier }) => supplier?.name,
  product: ({ product }) => product?.title,
  availability: ({ availability }) => PRICE_AVAILABILITY_NL[availability!],
  purchasePrice: ({ purchasePrice }) => `€ ${delimit(purchasePrice!, 2)}`,
  retailPrice: ({ retailPrice }) => `€ ${delimit(retailPrice!, 2)}`,
};

interface PriceChangelogProps {
  revisions: readonly (
    | productPriceRevisions_productById_priceRevisions
    | getPriceWithRevisions_getPrice_revisions
  )[];
  showSupplierName?: boolean;
  loadMore: (limit: number) => void;
  loading: boolean;
  error?: ApolloError;
}

const defaultLimit = 10;

const PriceChangelog: React.FC<PriceChangelogProps> = ({
  revisions,
  showSupplierName,
  loadMore,
  loading,
  error,
}) => {
  // Temporary pagination alternative, a single "load more" button
  const [hasLoadedMore, setHasLoadedMore] = useState(false);

  const hasPricingPermission = useHasPricingPermission();

  return (
    <Changelog
      fetchMore={
        revisions.length === defaultLimit && !hasLoadedMore
          ? () => {
              setHasLoadedMore(true);
              loadMore(50);
            }
          : undefined
      }
      loading={loading}
      error={error}
      changes={
        revisions.flatMap(({ modifiedBy, timestamp, diff, revision, ...rest }) => {
          const user =
            modifiedBy && 'firstName' in modifiedBy
              ? {
                  name: `${modifiedBy.firstName} ${modifiedBy.lastName}`,
                  link: `/users/operators/${modifiedBy.id}`,
                }
              : { name: 'Onbekend' };

          // TODO: this is mostly duplicate of Product/InstallerChangelog.tsx, it has the same TODOs as in there
          const changedKeys = Object.keys(diff).filter(
            k => !['id', '__typename'].includes(k) && diff[k as keyof typeof diff],
          );

          const isMultiChange = changedKeys.length > 1;

          return changedKeys.map((key, index) => {
            const typedKey = key as keyof typeof diff;
            return {
              revision: isMultiChange
                ? `${revision}-${String.fromCharCode(index + 'A'.charCodeAt(0))}`
                : revision.toString(),
              modifiedBy: user,
              timestamp,
              field: PriceFieldLabels[typedKey] || key,
              change: (
                <>
                  {showSupplierName && (
                    <Color c={'grayDark'}>
                      <Small>
                        (
                        {'document' in rest &&
                          'supplier' in rest.document &&
                          rest.document?.supplier?.name}
                        ){' '}
                      </Small>
                    </Color>
                  )}
                  {typedKey === 'purchasePrice' && !hasPricingPermission
                    ? '-'
                    : PriceFieldFormatter[typedKey]?.(diff) || JSON.stringify(diff[typedKey])}
                </>
              ),
            };
          });
        }) || []
      }
    />
  );
};

const PriceRevisionFragment = gql`
  fragment PriceRevision on PriceRevision {
    id
    modifiedBy {
      ... on AuthenticatedUser {
        id
        firstName
        lastName
      }
    }
    timestamp
    revision
    diff {
      id
      retailPrice
      purchasePrice
      availability
      minimumInstallerRate
      supplier {
        id
        name
      }
      product {
        id
        title
      }
    }
    document {
      id
      retailPrice
      purchasePrice
      availability
      minimumInstallerRate
    }
  }
`;

const PRODUCT_PRICE_REVISIONS = gql`
  ${PriceRevisionFragment}
  query productPriceRevisions($productId: ID!, $offset: Int, $limit: Int) {
    productById(id: $productId) {
      id
      priceRevisions(offset: $offset, limit: $limit) {
        ...PriceRevision
        # For price changes on all products, we need to be able to show for which supplier it was
        document {
          id
          supplier {
            id
            name
          }
        }
      }
    }
  }
`;

export const PriceChangelogByProduct: React.FC<{ productId: string }> = ({ productId }) => {
  const { data, loading, error, refetch } = useQuery<
    productPriceRevisions,
    productPriceRevisionsVariables
  >(PRODUCT_PRICE_REVISIONS, {
    variables: { productId, limit: defaultLimit },
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <Placeholder error={error} />;

  const revisions = data?.productById?.priceRevisions || [];

  return (
    <PriceChangelog
      revisions={revisions}
      showSupplierName
      loadMore={limit => refetch({ productId, limit })}
      loading={loading}
      error={error}
    />
  );
};

export const PRICE_REVISIONS = gql`
  ${PriceRevisionFragment}
  query getPriceWithRevisions($productId: ID!, $supplierId: ID!, $offset: Int, $limit: Int) {
    getPrice(productId: $productId, supplierId: $supplierId) {
      id
      revisions(offset: $offset, limit: $limit) {
        ...PriceRevision
      }
    }
  }
`;

export const PriceChangelogByPrice: React.FC<{ productId: string; supplierId: string }> = ({
  productId,
  supplierId,
}) => {
  const { data, loading, error, refetch } = useQuery<
    getPriceWithRevisions,
    getPriceWithRevisionsVariables
  >(PRICE_REVISIONS, {
    variables: { productId, supplierId, limit: defaultLimit },
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <Placeholder error={error} />;

  const revisions = data?.getPrice?.revisions || [];

  return (
    <PriceChangelog
      revisions={revisions}
      loadMore={limit => refetch({ productId, limit })}
      loading={loading}
      error={error}
    />
  );
};
