import { ReactourStep, ReactourStepContentArgs } from 'reactour';
import React from 'react';
import { Image, TextLink } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';

export const defaultPopupStyles = {
  backgroundColor: 'white',
  borderRadius: 6,
  padding: 18,
};

type TourHelpers = {
  rightNavOpen?: boolean;
  setRightNavOpen?: () => void;
};

const renderContent = (
  stepName: string,
  helpers: TourHelpers & ReactourStepContentArgs,
): JSX.Element => {
  const { close, rightNavOpen, setRightNavOpen } = helpers;
  switch (stepName) {
    case 'welcome':
      return (
        <>
          <p>
            <Image
              src="https://media.giphy.com/media/l0HlHFRbmaZtBRhXG/giphy.gif"
              alt="welcome gif"
              mb={1}
            />
            Welkom bij Energiebeheerders! Deze tour is er om je te helpen de nieuwe omgeving te
            ontdekken. Als je dit icoontje ergens op de pagina vindt, kun je een tour als deze
            starten.
          </p>
          <p>
            <Medium>Pro-tip: gebruik de pijltoetsen!</Medium>
          </p>
        </>
      );
    case 'leftNav':
      return (
        <p>
          In deze zijbalk vind je alle tools om een specifieke klant te helpen. De knoppen worden
          actief zodra je een klant of woning hebt gezocht via de zoekbalk.
        </p>
      );
    case 'leftNavToggle':
      return <p>Je kunt hem ook in- en uitklappen. Handig. Probeer maar.</p>;
    case 'searchBar':
      return (
        <>
          <p>
            Dit is de zoekbalk. Je kunt hier zoeken naar woningen, klanten, installatiebedrijven,
            leveranciers, contactpersonen en onze eigen medewerkers. Kun je ook even proberen.
          </p>
          <p>
            Let op: je kunt nog niet op combinaties zoeken. Dus straatnaam + huisnummer levert niets
            op. Postcodes zijn wat dat betreft het nauwkeurigst.
          </p>
        </>
      );
    case 'addHouse':
      return (
        <p>
          Hier kun je handmatig een woning en klant toevoegen aan onze database. Hoef je niet per se
          te proberen.
        </p>
      );
    case 'rightNavToggle':
      return (
        <p>
          <Image
            src="https://media.giphy.com/media/NdKVEei95yvIY/giphy.gif"
            alt="secret gif"
            mb={1}
          />
          Als je hierop klikt, komt er een geheime zijbalk tevoorschijn.
          {/* {rightNavOpen &&
            setTimeout(() => {
              goTo(step + 1);
            }, 200)} */}
        </p>
      );
    case 'rightNav':
      return (
        <p>
          {!rightNavOpen && setRightNavOpen?.()}
          Dit is de algemene zijbalk voor Energiebeheerders, waar je algemene tools en pagina's kunt
          vinden.
        </p>
      );
    case 'navTabs':
      return (
        <p>
          Er zijn 3 menuutjes: Dashboard, Gebruiker en Instellingen. Die worden vanzelf (niet écht
          vanzelf, was het maar zo'n feest) komende tijd gevuld met nieuwe functies.
        </p>
      );
    case 'finish':
      return (
        <p>
          Finito! Probeer dit icoontje op andere pagina's te vinden voor meer leuke gifjes en wat
          uitleg.{' '}
          <TextLink onClick={close}>
            <Medium>Einde van deze tour!</Medium>
          </TextLink>
        </p>
      );
    default:
      return <></>;
  }
};

const getTooltipPosition = (stepName: string): 'top' | 'right' | 'bottom' | 'left' => {
  switch (stepName) {
    case 'leftNav':
      return 'right';
    case 'leftNavToggle':
      return 'bottom';
    case 'searchBar':
      return 'bottom';
    default:
      return 'top';
  }
};

export const welcomeSteps = (helpers: TourHelpers): ReactourStep[] => {
  const steps = [
    'welcome',
    'leftNav',
    'leftNavToggle',
    'searchBar',
    'addHouse',
    'rightNavToggle',
    'rightNav',
    'navTabs',
    'finish',
  ];

  return steps.map(stepName => ({
    selector: `[data-tour=${stepName}]`,
    content: contentArgs => renderContent(stepName, { ...helpers, ...contentArgs }),
    position: getTooltipPosition(stepName),
    style: defaultPopupStyles,
  }));
};
