import React from 'react';
import { Box } from '@energiebespaarders/symbols';

interface WithSpacingProps {
  m?: number;
  mx?: number;
  my?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  p?: number;
  px?: number;
  py?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

export const withSpacing = (
  Component: React.FC<any>,
  defaults?: WithSpacingProps,
): React.FC<WithSpacingProps & any> => {
  const WithSpacing: React.FC<WithSpacingProps & any> = ({
    ...componentProps
  }: WithSpacingProps & any) => {
    const spacingKeys = [
      'm',
      'mx',
      'my',
      'mt',
      'mr',
      'mb',
      'ml',
      'p',
      'px',
      'py',
      'pt',
      'pr',
      'pb',
      'pl',
    ];
    const spacingProps = Object.keys(componentProps)
      .filter(key => spacingKeys.includes(key))
      .reduce(
        (prev, curr) => ({ ...prev, [curr]: componentProps[curr] }),
        {} as { [x: string]: number },
      );

    if (defaults || Object.keys(spacingProps).length) {
      return (
        <Box {...defaults} {...spacingProps}>
          <Component {...componentProps} />
        </Box>
      );
    }
    return <Component {...componentProps} />;
  };

  WithSpacing.displayName = `withSpacing(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WithSpacing;
};

export default withSpacing;
