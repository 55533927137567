import { gql } from '@apollo/client';
import {
  DiscountFragment,
  EnergyDeltaFragment,
  FileFragment,
  InstallationItemFragment,
} from '../../fragments';
import { DealItemFragment, SignoffFragment } from './fragments';

export const TASK_FLOW_BY_HOUSE_ID = gql`
  query taskFlowByHouseId($houseId: ID!) {
    house(id: $houseId) {
      id
      customer {
        id
      }
      intake {
        id
      }
      situation {
        id
      }
      advice {
        id
      }
      orders {
        id
      }
    }
  }
`;

export const TASK_FLOW_BY_JOB_ID = gql`
  query taskFlowByJobId($jobId: ID!) {
    job: jobById(jobId: $jobId) {
      id
      quote {
        id
        solution
      }
      house {
        id
        customer {
          id
        }
      }
      installer {
        id
      }
    }
  }
`;

export const TASK_FLOW_BY_QUOTE_ID = gql`
  query taskFlowByQuoteId($quoteId: ID!) {
    quote: quoteById(quoteId: $quoteId) {
      id
      house {
        id
        customer {
          id
        }
      }
      jobs {
        id
        installer {
          id
        }
      }
    }
  }
`;

export const PTC_RESULTS_BY_JOB_ID = gql`
  ${SignoffFragment}
  query ptcResultsByJobId($jobId: ID!) {
    job: jobById(jobId: $jobId) {
      id
      sentOn
      currentPlanning {
        plannedFor
      }
      quote {
        id
        reference
      }
      type
      completed
      installerSignoff {
        ...Signoff
      }
    }
  }
`;

export const JOB_COMPLETED_BY_JOB_ID = gql`
  query jobCompletedByJobId($jobId: ID!) {
    job: jobById(jobId: $jobId) {
      id
      completed
    }
  }
`;

export const JOB_PLANNING_BY_JOB_ID = gql`
  query jobPlanningByJobId($jobId: ID!) {
    job: jobById(jobId: $jobId) {
      id
      currentPlanning {
        plannedFor
      }
    }
  }
`;

export const JOB_MUTATION_BY_JOB_ID = gql`
  ${EnergyDeltaFragment}
  ${DiscountFragment}
  ${InstallationItemFragment}
  ${DealItemFragment}
  query jobMutationByJobId($jobId: ID!) {
    job: jobById(jobId: $jobId) {
      id
      deal {
        id
        solution
        items {
          ...DealItem
        }
      }
      quote {
        # TODO: we shouldn't be needing this, needed for creating a new quote for making it work
        id
        energyDelta {
          ...EnergyDelta
        }
        discount {
          ...Discount
        }
        files {
          id
        }
      }
      items {
        ...InstallationItem
      }
      mutation {
        reason
        notes
        approvedBy
        approvedOn
        items {
          ...InstallationItem
        }
      }
    }
  }
`;

export const JOB_FOLLOW_UP_QUOTE_OR_JOB_BY_JOB_ID = gql`
  query jobFollowUpQuoteOrJobByJobId($jobId: ID!) {
    job: jobById(jobId: $jobId) {
      id
      mutation {
        approvedOn
        approvedBy
      }
      deal {
        id
        quotes {
          id
          created
        }
        jobs {
          id
          created
        }
      }
    }
  }
`;

export const GENERATE_INSTALLATION_JOB = gql`
  mutation generateInstallationJob($dealId: ID!) {
    generateInstallationJob(dealId: $dealId) {
      id
    }
  }
`;

export const JOB_MUTATION_MODAL = gql`
  ${InstallationItemFragment}
  ${FileFragment}
  query jobForMutationModal($jobId: ID!) {
    jobById(jobId: $jobId) {
      id
      quote {
        id
        solution
      }
      installer {
        id
        name
        pairedSupplierId
        files {
          ...File
        }
      }
      items {
        ...InstallationItem
      }
      mutation {
        reason
        notes
        approvedBy
        approvedOn
        items {
          ...InstallationItem
        }
      }
    }
  }
`;

export const UPLOAD_SIGNOFF_FILES = gql`
  ${SignoffFragment}
  mutation addInstallerSignoffFiles($jobId: ID!, $key: String!, $files: [Upload!]!, $userId: ID!) {
    addSignoffFiles(
      jobId: $jobId
      key: $key
      type: installer
      files: $files
      userId: $userId
      userType: operator
    ) {
      id
      installerSignoff {
        ...Signoff
      }
    }
  }
`;

export const REMOVE_SIGNOFF_FILE = gql`
  ${SignoffFragment}
  mutation removeInstallerSignoffFiles($jobId: ID!, $key: String!, $fileId: String!) {
    removeSignoffFiles(jobId: $jobId, type: installer, key: $key, fileId: $fileId) {
      id
      installerSignoff {
        ...Signoff
      }
    }
  }
`;

export const UPDATE_SIGNOFF_COMMENT = gql`
  ${SignoffFragment}
  mutation updateSignoffComment($jobId: ID!, $key: String!, $comment: String!) {
    updateSignoffComment(jobId: $jobId, type: installer, key: $key, comment: $comment) {
      id
      installerSignoff {
        ...Signoff
      }
    }
  }
`;

export const START_INSTALLER_SIGNOFF = gql`
  ${SignoffFragment}
  mutation startSignoff($jobId: ID!, $userId: ID!, $jobExecutionDate: Date!) {
    startSignoff(
      jobId: $jobId
      type: installer
      userId: $userId
      userType: operator
      jobExecutionDate: $jobExecutionDate
    ) {
      id
      installerSignoff {
        ...Signoff
      }
    }
  }
`;

export const MARK_SIGNOFF_COMPLETE = gql`
  ${SignoffFragment}
  mutation markInstallerSignoffCompleted($jobId: ID!) {
    markInstallerSignoffCompleted(jobId: $jobId) {
      id
      installerSignoff {
        ...Signoff
      }
    }
  }
`;

export const UPDATE_DEAL_ITEMS = gql`
  ${DealItemFragment}
  mutation updateDealItems(
    $dealId: ID!
    $items: [DealItemInput!]!
    $linkId: ID!
    $linkType: String!
  ) {
    updateDealItems(dealId: $dealId, items: $items, linkId: $linkId, linkType: $linkType) {
      id
      items {
        ...DealItem
      }
    }
  }
`;
