import { OperatorTeam } from '../components/operatorDirectory/OperatorProfile';
import { useMe } from './useMe';

/** Checks if current user has permission to adjust prices in the product DB and on installations */
export function useHasPricingPermission(): boolean {
  const { me } = useMe();

  // TODO: would be nice to have a permission view per operator, that overrides team-based permissions
  // - [ ] Product permission
  // - [ ] Pricing permission
  // - [ ] Planning permission
  // - [ ] Team management permission
  // Could also do that through the role perhaps, "standard, pricing-permission, product-permission"
  // Until then, some email-based permission rules:
  const userEmailsWithPricingPermission = [
    'jeroen.nieuwenhuys@energiebespaarders.nl',
    'joost@energiebespaarders.nl',
    'laurens@energiebespaarders.nl',
    'hans@energiebespaarders.nl',
    'danielle@energiebespaarders.nl',
  ];

  return (
    me.team === OperatorTeam.MT ||
    me.team === OperatorTeam.NetworkSuccess ||
    me.team === OperatorTeam.Planning ||
    me.team === OperatorTeam.Development ||
    me.team === OperatorTeam.Finance ||
    userEmailsWithPricingPermission.includes(me.email)
  );
}
