import { gql } from '@apollo/client';
import {
  ConsumptionFragment,
  EnergyDeltaFragment,
  EstimatesFragment,
  HouseEnergyFragment,
  SituationEnergyPricesFragment,
} from '../fragments';

export const CALCULATIONS_TABLE = gql`
  ${EstimatesFragment}
  ${EnergyDeltaFragment}
  ${ConsumptionFragment}
  ${HouseEnergyFragment}
  ${SituationEnergyPricesFragment}
  query calculationsTable($houseId: ID!) {
    house(id: $houseId) {
      id
      isGVAHouse
      feasibleSolutions
      energy {
        ...HouseEnergy
      }
      type
      comfortScore
      walls {
        rc
      }
      floor {
        rc
      }
      roof {
        rc
      }
      windowsZTAU {
        livingU
        sleepingU
      }
      heating {
        heatEmissionSystems
        installations
      }
      intake {
        id
        type
        walls {
          rc
        }
        floor {
          rc
        }
        roof {
          rc
        }
        windows {
          livingU
          sleepingU
        }
        heating {
          heatEmissionSystems
          installations
        }
      }
      advice {
        id
        solutionDomainOrder
      }
      situation {
        id
        consumption {
          ...Consumption
        }
        energyPrices {
          ...SituationEnergyPrices
        }
      }
      averageEstimates {
        ...Estimates
      }
      quotes {
        id
        isAdvised
        isCancelled
        cancelledOn
        subsidy
        efficiency
        paybackTime
        energyDelta {
          ...EnergyDelta
        }
        comfortScore
        items {
          amount
          retailPrice
          product {
            id
            title
            tax
            solution
            priceUnit
            category
            isdeCode
            ... on HeatPump {
              heatPumpType
              subsidy
            }
          }
        }
        solution
        investment
        investmentExTax
        discount {
          amount
          type
          text
        }
      }
    }
  }
`;

export const GET_CONSUMPTION = gql`
  ${SituationEnergyPricesFragment}
  query getConsumption($houseId: ID!) {
    house(id: $houseId) {
      id
      energy {
        gasConsumption
        electricityConsumption
        electricityProduction
      }
      situation {
        id
        consumption {
          gas
          electricity
        }
        energyPrices {
          ...SituationEnergyPrices
        }
      }
    }
  }
`;
