import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { darken } from 'polished';
import { themify, padding, margin } from '../../styles/mixins';

import { Icon } from '@energiebespaarders/symbols';

export interface StyledSideNavItemProps {
  $activeBgColor?: string;
  $bgColor?: string;
  $color?: string;
  $disabled?: boolean;
  $height: string;
  $side: string;
  to: string;
}

export interface PositionedSideNavItem {
  $narrowScreen: boolean;
}

export const StyledSideNavItem = styled(NavLink)<StyledSideNavItemProps>`
  ${padding(4)};
  position: relative;
  display: block;
  height: ${x => x.$height};
  background: ${x => x.$bgColor || 'transparent'};
  color: ${x => (x.$disabled ? x.theme.colors.grayDark : x.$color)};
  font-family: ${x => x.theme.type.fontFamilySans};
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  cursor: ${x => (x.$disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${x => (x.$disabled ? 'none' : 'auto')};

  &:visited,
  &:focus {
    color: ${x => (x.$disabled ? x.theme.colors.grayDark : x.$color)};
  }

  &:hover {
    color: ${x => x.$color};
    background: ${x => (x.$bgColor ? darken(0.1, x.$bgColor) : x.$activeBgColor)};
  }

  &:active {
    color: ${x => darken(0.2, x.$color || '')};
  }

  & * {
    fill: ${x => (x.$disabled ? x.theme.colors.grayDark : 'white')} !important;
  }

  &.isActive {
    ${x =>
      x.to !== '#' &&
      css<StyledSideNavItemProps>`
        background: ${x => (x.$bgColor ? darken(0.1, x.$bgColor) : x.$activeBgColor)};
        pointer-events: none;
        cursor: default;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: ${x => (x.$side === 'right' ? 'auto' : 0)};
          left: ${x => (x.$side === 'left' ? 'auto' : 0)};
          width: ${x => x.theme.spacer};
          height: ${x => x.$height};
          background: ${x => x.theme.colors.green};
        }

        & * {
          fill: white !important;
        }
      `}
  }
`;

interface ItemLabelProps {
  $fontSize: number;
  $side: string;
  $visible: boolean;
}

const ItemLabel = styled.div<ItemLabelProps>`
  ${margin(3, 'left')};
  display: inline-block;
  font-size: ${x => x.theme.type.scale[x.$fontSize]};
  transform: ${x => `scaleX(${x.$visible ? '1' : '0'})`};
  transform-origin: ${x => (x.$side === 'left' ? '0 0' : '100% 0')};
  transition: all 0.2s ${x => x.theme.curves.standard};
`;

interface SideNavItemProps {
  activeBgColor?: string;
  bgColor?: string;
  className?: string;
  color?: string;
  disabled?: boolean;
  'data-tour'?: string;
  exact?: boolean;
  fontSize?: number;
  height?: string;
  icon: ReactNode;
  label?: string;
  onRouteChange?: any;
  onClick?: any;
  showLabel?: boolean;
  side: string;
  strict?: boolean;
  style?: Record<string, any>;
  to?: string;
}

const SideNavItem: React.FC<SideNavItemProps> = ({
  'data-tour': tourStepName,
  activeBgColor = 'grayDarker',
  bgColor,
  className,
  color = 'white',
  disabled,
  exact,
  fontSize = 5,
  height,
  icon,
  label,
  onClick,
  onRouteChange,
  showLabel,
  side,
  strict,
  style,
  to,
}) => (
  <StyledSideNavItem
    $activeBgColor={themify(activeBgColor || '')}
    $bgColor={themify(bgColor)}
    $color={themify(color || '')}
    $disabled={disabled}
    $height={height || ''}
    $side={side}
    activeClassName="isActive"
    className={className}
    data-tour={tourStepName}
    exact={exact}
    onClick={onClick || onRouteChange}
    strict={strict}
    style={style}
    to={to || ''}
  >
    <Icon
      icon={icon}
      inline
      solid
      width="24px"
      height="24px"
      fill={themify(disabled ? 'grayDark' : color)}
    />
    {label && (
      <ItemLabel $fontSize={fontSize} $side={side} $visible={Boolean(showLabel)}>
        {label}
      </ItemLabel>
    )}
  </StyledSideNavItem>
);

export default SideNavItem;
