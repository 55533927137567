import { gql, useLazyQuery } from '@apollo/client';
import { Box, Button, Card, Container, Flex, Icon, Spinner } from '@energiebespaarders/symbols';
import { CancelCircle } from '@energiebespaarders/symbols/icons/line';
import { useDebounce } from '../../hooks/useDebounce';
import _ from 'lodash';
import {
  BadgeId,
  CaretLeft,
  CaretRight,
  Hardhat,
  Help,
  HouseDoor,
  Shipping,
  User,
} from '@energiebespaarders/symbols/icons/solid';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useKeyCode } from '../../hooks/useKeyCode';
import { margin, padding, themify } from '../../styles/mixins';
import { search, searchVariables } from '../../types/generated/search';
import SearchResult from './SearchResult';
import { Medium } from '@energiebespaarders/symbols/helpers';
import { useSessionStorage } from 'usehooks-ts';

const SEARCH = gql`
  query search($text: String!, $offset: Int, $limit: Int, $entityType: String) {
    search(text: $text, offset: $offset, limit: $limit, entityType: $entityType) {
      id
      searchResult {
        title
        subtitle
        extraInfo
        created
        houseId
      }
      __typename
    }
  }
`;

export const Searching = styled(Spinner)`
  position: absolute;
  top: 60px;
  left: calc(50% - 30px);
`;

export const ErrorText = styled.div`
  ${margin(2, 'left')};
  display: inline-block;
  vertical-align: middle;
`;

export const StyledFilters = styled(Card)<{ mobile: boolean }>`
  ${margin(2, 'bottom')};
  display: flex;
  flex-flow: row ${x => (x.mobile ? 'wrap' : 'nowrap')};
  justify-items: stretch;
  align-items: center;
`;

interface FilterButtonProps {
  $active: boolean;
  $color: string;
  $narrowScreen: boolean;
}

export const FilterButton = styled(Box)<FilterButtonProps>`
  ${x => padding(x.$narrowScreen ? 2 : 1)}
  flex: 1 1 ${x => (x.$narrowScreen ? '50%' : 'auto')};
  text-align: center;
  color: ${x => themify(x.$active ? x.$color : 'gray')};
  font-weight: ${x => (x.$active ? 600 : 'normal')};
  pointer-events: ${x => (x.$active ? 'none' : 'auto')};
  cursor: pointer;
  transition: all 0.2s ${x => x.theme.curves.standard};

  &:hover {
    color: ${x => themify(x.$color)};
  }
`;

export function getResultStyle(type: string) {
  let color: string, icon: ReactNode;
  switch (type) {
    case 'Customer':
      color = 'gold';
      icon = User;
      break;
    case 'House':
      color = 'blue';
      icon = HouseDoor;
      break;
    case 'Operator':
      color = 'green';
      icon = BadgeId;
      break;
    case 'Employee':
      color = 'purple';
      icon = BadgeId;
      break;
    case 'Installer':
      color = 'orange';
      icon = Hardhat;
      break;
    case 'Supplier':
      color = 'red';
      icon = Shipping;
      break;
    case 'PartnerAgent':
      color = 'pink';
      icon = BadgeId;
      break;
    default:
      color = 'grayDarker';
      icon = Help;
  }
  return { color: themify(color), icon };
}

interface SearchResultsProps {
  narrowScreen: boolean;
  searchString: string;
  toggleSearch: (state?: boolean) => void;
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  narrowScreen,
  searchString,
  toggleSearch,
}) => {
  const debouncedSearchString = useDebounce(searchString, 100);

  const isDebugging = useKeyCode(16); // shift
  const [filter, setFilter] = useSessionStorage('search-bar-filter-type', '');
  const [page, setPage] = useState(1);
  const [amountPerPage] = useState(12);
  const [searchError, setSearchError] = useState(false);

  const [search, { data, loading, error }] = useLazyQuery<search, searchVariables>(SEARCH, {
    onError: () => setSearchError(true),
    onCompleted: () => setSearchError(false),
  });

  useEffect(() => {
    if (debouncedSearchString && !loading && !searchError) {
      search({
        variables: {
          text: debouncedSearchString,
          limit: amountPerPage,
          offset: (page - 1) * amountPerPage,
          entityType: filter,
        },
      });
    }
  }, [amountPerPage, debouncedSearchString, filter, loading, page, search, error, searchError]);

  useEffect(() => {
    // reset to first page when search string changes
    if (page !== 1) setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString, searchError]);

  useEffect(() => {
    if (searchError) setSearchError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString]);

  const renderFilterButton = useCallback(
    (type: string, label: string) => (
      <FilterButton
        $active={filter === type}
        $color={getResultStyle(_.startCase(type)).color}
        $narrowScreen={narrowScreen}
        onClick={() => setFilter(type)}
        width={1}
      >
        {label}
      </FilterButton>
    ),
    [filter, narrowScreen, setFilter],
  );

  const results = data?.search || [];

  return (
    <Container>
      <Flex flexWrap="wrap" mx={-2}>
        <Box width={1} px={2}>
          <StyledFilters mobile={narrowScreen} p={2}>
            <Flex width={1}>
              {renderFilterButton('', 'Default')}
              {renderFilterButton('house', 'Woningen')}
              {renderFilterButton('customer', 'Klanten')}
              {renderFilterButton('operator', 'Operators')}
              {renderFilterButton('installer', 'Installateurs')}
              {renderFilterButton('supplier', 'Leveranciers')}
              {renderFilterButton('partnerAgent', 'Partners')}
            </Flex>
          </StyledFilters>
        </Box>
        {loading ? (
          <Searching width="60px" />
        ) : (
          <Box width={1}>
            <Flex flexWrap="wrap" style={{ position: 'relative' }}>
              {results
                .filter(result =>
                  filter ? result.__typename.toLowerCase() === filter.toLowerCase() : result,
                )
                .map((result, index) => (
                  <Box
                    key={`result_${result.id}-${index}`}
                    px={2}
                    mb={2}
                    width={[1, 1, 1, 1 / 2, 1 / 2]}
                  >
                    <SearchResult
                      showIds={isDebugging}
                      index={index}
                      resultData={result}
                      toggleSearch={toggleSearch}
                    />
                  </Box>
                ))}
              {error ? (
                <Box width={1} px={2}>
                  <Card>
                    <Icon icon={CancelCircle} inline fill="red" stroke="white" width="24px" />
                    <ErrorText>
                      Er is iets misgegaan bij het ophalen van de resultaten: {error.message}
                    </ErrorText>
                  </Card>
                </Box>
              ) : results.length === 0 ? (
                <Box width={1} px={2}>
                  <Card>
                    <Icon icon={CancelCircle} inline fill="red" stroke="white" width="24px" />
                    <ErrorText>
                      Er zijn geen resultaten gevonden voor de zoekopdracht{' '}
                      <Medium>{searchString}</Medium>
                    </ErrorText>
                  </Card>
                </Box>
              ) : (
                <></>
              )}
              {filter && (
                <Box width={1}>
                  <Flex>
                    <Box width={1 / 2} px={2} textAlign="center">
                      <Button
                        fluid
                        disabled={page === 1}
                        minimal
                        bgColor="white"
                        onClick={() => setPage(v => v - 1)}
                        iconStart={CaretLeft}
                      >
                        {page === 1 ? '' : 'Vorige 12 resultaten'}
                      </Button>
                    </Box>
                    <Box width={1 / 2} px={2} textAlign="center">
                      <Button
                        disabled={results.length < 12}
                        fluid
                        minimal
                        bgColor="white"
                        onClick={() => setPage(v => v + 1)}
                        iconEnd={CaretRight}
                      >
                        {results.length < 12 ? '' : 'Volgende 12 resultaten'}
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              )}
            </Flex>
          </Box>
        )}
      </Flex>
    </Container>
  );
};

export default SearchResults;
