/**
 * Used for showing a tooltip on a Google maps component
 * Based on https://medium.com/@asimmittal/custom-tooltips-for-google-maps-in-pure-javascript-a8004b8e9458
 */
class GoogleMapsTooltip {
  // create a global variable that will point to the tooltip in the DOM
  tipObj: HTMLDivElement | null = null;

  // offset along x and y in px
  offset = {
    x: 20,
    y: 20,
  };

  /** ******************************************************************
   * injectTooltip(e,data)
   * inject the custom tooltip into the DOM
   ********************************************************************/
  inject(event: google.maps.MapMouseEvent, data: string) {
    if (!this.tipObj && event) {
      // create the tooltip object
      this.tipObj = document.createElement('div');
      this.tipObj.style.width = '100px';
      this.tipObj.style.height = '35px';
      this.tipObj.style.background = 'white';
      this.tipObj.style.borderRadius = '5px';
      this.tipObj.style.padding = '5px';
      this.tipObj.style.fontFamily = 'Arial,Helvetica';
      this.tipObj.style.textAlign = 'center';
      this.tipObj.style.zIndex = '1400';
      this.tipObj.innerHTML = data;

      // position it
      this.tipObj.style.position = 'fixed';

      this.tipObj.style.top =
        (event.domEvent as any).clientY + window.scrollY + this.offset.y + 'px';
      this.tipObj.style.left =
        (event.domEvent as any).clientX + window.scrollX + this.offset.x + 'px';

      // add it to the body
      document.body.appendChild(this.tipObj);
    }
  }

  /** ******************************************************************
   * moveTooltip(e)
   * update the position of the tooltip based on the event data
   ********************************************************************/
  move(event: google.maps.MapMouseEvent) {
    const tipObj = this.tipObj;
    if (tipObj && event) {
      // position it
      tipObj.style.top = (event.domEvent as any).clientY + window.scrollY + this.offset.y + 'px';
      tipObj.style.left = (event.domEvent as any).clientX + window.scrollX + this.offset.x + 'px';
    }
  }

  /** ******************************************************************
   * deleteTooltip(e)
   * delete the tooltip if it exists in the DOM
   ********************************************************************/
  delete() {
    const tipObj = this.tipObj;
    if (tipObj) {
      // delete the tooltip if it exists in the DOM
      document.body.removeChild(tipObj);
      this.tipObj = null;
    }
  }
}

export default GoogleMapsTooltip;
