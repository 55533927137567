import { gql } from '@apollo/client';

export const GET_VIABLE_PRODUCTS = gql`
  query getViableProducts($solutionDomain: String!, $supplierId: ID) {
    allProductsBySolutionDomain(solutionDomain: $solutionDomain) {
      id
      archived
      title
      category
      solution
      priceUnit
      customMarkup
      isSuppliable(supplierId: $supplierId)
      ... on HeatPump {
        brand {
          id
          name
        }
      }
    }
  }
`;

export const GET_INSTALLATION_PARTNERS = gql`
  query getInstallationPartners($solution: Solution!, $houseId: ID!, $productId: ID) {
    installersBySolution(solution: $solution) {
      id
      name
      status {
        value
      }
      capableOfInstallingAtHouse(houseId: $houseId)
      capableOfInstallingProduct(productId: $productId)
      distanceToHouse(houseId: $houseId)
      minimumRate
      installationRegions {
        to
        from
      }
      supplier {
        id
        capableOfSupplyingProduct(productId: $productId)
      }
    }
  }
`;

export const GET_PRICE = gql`
  query getPrice($productId: ID!, $supplierId: ID!, $amount: Float!) {
    getPrice(productId: $productId, supplierId: $supplierId) {
      id
      purchasePrice
      retailPrice
      tieredRetailPrice(amount: $amount)
      minimumInstallerRate
    }
  }
`;

export const GET_PRODUCTS_BY_SOLUTIONS_AND_SUPPLIER = gql`
  query getProductsBySolutionsAndSupplier($solutions: [String!]!, $supplierId: ID!) {
    products(
      filter: {
        solution: { in: $solutions }
        archived: { eq: null }
        availability: { supplierId: { eq: $supplierId }, status: { eq: inStock } }
      }
    ) {
      id
      archived
      title
      category
      solution
      priceUnit
      tax
      price(supplierId: $supplierId) {
        id
        retailPrice
        purchasePrice
        availability
      }
    }
  }
`;
