import { MutationUpdaterFn, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  SOLUTIONS_NL,
  SOLUTION_TOPICS,
  Solution,
  SolutionDomain,
  getDomainFromSolution,
} from '@energiebespaarders/constants';
import {
  Accordion,
  Box,
  Button,
  Card,
  Flex,
  Modal,
  Spacer,
  Spinner,
  Toast,
} from '@energiebespaarders/symbols';
import { Center } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import {
  Cart,
  Check,
  EnergyCircle,
  FileEuro,
  FrankensteinMonster,
  Hardhat,
  PageCheck,
  Paperclip,
  Pdf,
  Reply,
  Transfer,
  Vision,
} from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { humulusUrl, isProduction, isStaging } from '../../../config';
import JobMutationDiffs from '../../../domains/TaskFlow/JobMutationDiffs';
import { useMe } from '../../../hooks/useMe';
import { checkTeamMember } from '../../../lib/permissions';
import { CALCULATIONS_TABLE } from '../../../queries/calculations';
import {
  INSTALLATION_BY_HOUSE_SOLUTION,
  QUOTE_TO_INSTALLATION,
  UPDATE_QUOTE,
} from '../../../queries/installatron';
import { QUOTE_MODAL_QUOTE } from '../../../queries/quote';
import {
  installationByHouseSolution,
  installationByHouseSolutionVariables,
} from '../../../types/generated/installationByHouseSolution';
import {
  quoteModalQuote,
  quoteModalQuoteVariables,
} from '../../../types/generated/quoteModalQuote';
import {
  quoteToInstallation,
  quoteToInstallationVariables,
} from '../../../types/generated/quoteToInstallation';
import { updateQuote, updateQuoteVariables } from '../../../types/generated/updateQuote';
import { InstallerStatus } from '../../../types/graphql-global-types';
import { LinkAlert } from '../../Alerts';
import Calculations from '../../Calculations';
import { ProductList, QuoteStatusButtons } from '../../installatron';
import CalculationInput from '../../installatron/CalculationInput';
import InstallerStatusBanner from '../../installatron/InstallerStatusBanner';
import PvQuoteInfo from '../../installatron/PvQuoteInfo';
import TransferQuoteModal from '../../installatron/Quote/TransferQuoteModal';
import { OperatorTeam } from '../../operatorDirectory/OperatorProfile';
import { QuoteModalAdviceAdditions } from './sections/AdviceAdditions';
import { QuoteModalGeneralInfo } from './sections/GeneralInfo';

type QuoteModalProps = {
  closeModal: () => void;
  houseId: string;
  isOpen: boolean;
  mobile?: boolean;
  quoteId: string;
  solutionAccepted?: boolean;
  isOnlyVisibleQuoteOnDeal?: boolean;
};

const QuoteModal: React.FC<QuoteModalProps> = ({
  closeModal,
  houseId,
  isOpen,
  quoteId,
  solutionAccepted,
  isOnlyVisibleQuoteOnDeal,
}: QuoteModalProps) => {
  const match = useRouteMatch<{ solutionDomain?: SolutionDomain }>();
  const history = useHistory();
  const inInstallatron = !!match.params.solutionDomain;
  const { me } = useMe();

  const mobile = useIsMobile();
  const [manualElectricity, setManualElectricity] = useState(0);
  const [manualGas, setManualGas] = useState(0);
  const [quoteInInstallation, setQuoteInInstallation] = useState('');
  const [isQuoteTransferModalOpen, setQuoteTransferModalOpen] = useState(false);

  const [cardinalDirection, setCardinalDirection] = useState<string>('');
  const [multipleRoofSurfaces, setMultipleRoofSurfaces] = useState<boolean>();
  const [installationPlanProperties, setInstallationPlanProperties] = useState<string>('');
  const [neighbourDiscount, setNeighbourDiscount] = useState<boolean>();

  const onQuoteToInstallationUpdate: MutationUpdaterFn<quoteToInstallation> = (cache, { data }) => {
    if (data?.quoteToInstallation) {
      const queryData = cache.readQuery<
        installationByHouseSolution,
        installationByHouseSolutionVariables
      >({
        query: INSTALLATION_BY_HOUSE_SOLUTION,
        variables: {
          houseId,
          solution: data.quoteToInstallation.solution,
        },
      });

      if (queryData) {
        const installation = { ...queryData.installationByHouseSolution! };
        installation.discount = data.quoteToInstallation.discount;
        installation.items = data.quoteToInstallation.items;
        cache.writeQuery<installationByHouseSolution, installationByHouseSolutionVariables>({
          query: INSTALLATION_BY_HOUSE_SOLUTION,
          data: { installationByHouseSolution: installation },
          variables: {
            houseId,
            solution: data.quoteToInstallation.solution,
          },
        });
      }
    }
  };

  const [quoteToInstallationMutation, mutationData] = useMutation<
    quoteToInstallation,
    quoteToInstallationVariables
  >(QUOTE_TO_INSTALLATION, {
    onCompleted: () => setQuoteInInstallation(quoteId),
    update: onQuoteToInstallationUpdate,
  });

  // In order to do stuff to this in the cache it first needs to be in there, if it's not
  // then shit breaks when we try to update the cache in the update functions of other queries
  const [installationIntoCache] = useLazyQuery<
    installationByHouseSolution,
    installationByHouseSolutionVariables
  >(INSTALLATION_BY_HOUSE_SOLUTION, {
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      if (data.installationByHouseSolution) {
        quoteToInstallationMutation({
          variables: { installationId: data.installationByHouseSolution.id, quoteId },
        });
      }
    },
  });

  const onQueryCompleted = useCallback(
    (data: quoteModalQuote) => {
      if (data?.quoteById?.energyDelta && !manualElectricity && !manualGas) {
        const {
          energyDelta: { electricityProduction, gasConsumption },
          solution,
        } = data.quoteById;
        if (electricityProduction && solution === Solution.PvSystem && electricityProduction > 0) {
          setManualElectricity(electricityProduction);
        } else if (gasConsumption && gasConsumption > 0) {
          setManualGas(gasConsumption);
        }
      }
    },
    [manualElectricity, manualGas],
  );

  const { data, loading: loadingQuote, error: quoteError } = useQuery<
    quoteModalQuote,
    quoteModalQuoteVariables
  >(QUOTE_MODAL_QUOTE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !quoteId,
    onCompleted: onQueryCompleted,
    variables: { quoteId },
  });

  useEffect(() => {
    if (data?.quoteById?.pvSystemInfo?.cardinalDirection) {
      setCardinalDirection(data.quoteById.pvSystemInfo.cardinalDirection);
    }
    if (data?.quoteById?.pvSystemInfo?.multipleRoofSurfaces !== null) {
      setMultipleRoofSurfaces(data?.quoteById?.pvSystemInfo?.multipleRoofSurfaces);
    }
    if (data?.quoteById?.pvSystemInfo?.installationPlanProperties) {
      setInstallationPlanProperties(data.quoteById.pvSystemInfo.installationPlanProperties);
    }
    if (data?.quoteById?.pvSystemInfo?.neighbourDiscount !== null) {
      setNeighbourDiscount(data?.quoteById?.pvSystemInfo?.neighbourDiscount);
    }
  }, [data?.quoteById?.pvSystemInfo]);

  const intakeStartedOn = data?.quoteById?.house.intake.startedOn;

  const handleRestoreInstallationToQuote = useCallback(
    (solution: Solution) => {
      installationIntoCache({
        variables: { houseId, solution },
      });

      if (!inInstallatron) {
        history.push(`/house/${houseId}/installatron/${getDomainFromSolution(solution)}`);
      }
    },
    [history, houseId, inInstallatron, installationIntoCache],
  );

  const [updateQuoteMutation] = useMutation<updateQuote, updateQuoteVariables>(UPDATE_QUOTE, {
    refetchQueries: [{ query: CALCULATIONS_TABLE, variables: { houseId } }],
  });

  if (!data?.quoteById) return <></>;

  const quote = data.quoteById;
  const solution = quote.solution;

  const { items, installer, acceptedSiblingInDeal } = quote;

  // Find the most recent (accepted) quote in the deal before this one
  const mostRecentAcceptedQuote = [...quote.deal.history]
    .sort((a, b) => b.timestamp - a.timestamp)
    .find(entry => entry.linkType === 'quote' && entry.linkId !== quote.id);

  // The quote is read-only if
  // - the installer is inactive, or
  // - the installer is paused and there was no previously accepted quote for the same installer
  const isReadOnly = installer
    ? installer.status.value === InstallerStatus.inactive ||
      (installer.status.value === InstallerStatus.paused &&
        acceptedSiblingInDeal?.installer?.id !== installer.id)
    : true;

  let quotePdfRoute = 'quote';
  if (quote.house.isHabitataHouse) quotePdfRoute = 'habitata-quote';

  return (
    <Modal
      isOpen={isOpen}
      mobile={mobile}
      onRequestClose={closeModal}
      title={`${quote.reference ? `Offerte ${quote.reference}` : 'Concept offerte'} voor ${
        solution ? SOLUTIONS_NL[solution] : '...'
      }${quote.deal.onHold?.since ? ' - deal ON HOLD' : ''}`}
      size="xl"
      buttons={[
        {
          label: 'PDF downloaden',
          bgColor: 'orange',
          iconStart: Pdf,
          href: `${
            isProduction || isStaging
              ? `${humulusUrl}/${quotePdfRoute}/${quote.id}`
              : `${humulusUrl}/${quotePdfRoute}?quoteId=${quote.id}`
          }`,
          target: '_blank',
          fontSize: 5,
        },
        {
          label: 'Bekijken als klant',
          bgColor: 'orange',
          iconStart: Vision,
          href: `${
            isProduction
              ? 'https://energiebespaarders.nl'
              : isStaging
              ? 'https://staging.energiebespaarders.nl'
              : 'http://localhost:3000'
          }/operator-control-panel/quote/${quoteId}`,
          target: '_blank',
          fontSize: 5,
        },
        {
          label: quoteInInstallation
            ? 'Installatron staat gelijk (ga naar installatie)'
            : 'Installatron terugzetten naar deze offerte',
          bgColor: 'purple',
          iconStart: quoteInInstallation ? Check : Reply,
          minIconSize: '1em',
          onClick: () => handleRestoreInstallationToQuote(solution),
          loading: mutationData.loading,
          fontSize: 5,
        },
        {
          label: 'Opnieuw opstellen voor andere installateur',
          bgColor: 'purple',
          iconStart: Transfer,
          onClick: () => setQuoteTransferModalOpen(true),
          fontSize: 5,
        },
        {
          label: 'Sluiten',
          bgColor: 'red',
          onClick: closeModal,
          fontSize: 5,
        },
      ]}
    >
      {loadingQuote || quoteError || !data || !quote ? (
        <Center block p={5}>
          <Spinner />
          <Spacer vertical amount={5} />
          {quoteError && quoteError.message}
        </Center>
      ) : (
        <>
          {installer && <InstallerStatusBanner installer={installer} />}
          {!quote.hasCompletedIntake &&
            solution !== Solution.Miscellaneous &&
            dayjs(intakeStartedOn).isAfter(dayjs('2022-09-11')) && (
              <Toast
                type={checkTeamMember(me, OperatorTeam.Planning) ? 'alert' : 'error'}
                width="100%"
                message={
                  <LinkAlert
                    message={`Het opname-onderdeel voor deze oplossing is nog niet afgerond. Je kunt deze offerte daarom nog niet definitief maken en er kunnen geen opdrachten gemaakt of verzonden worden.${
                      checkTeamMember(me, OperatorTeam.Planning)
                        ? 'Als Planner kun je dit wél, maar blijf alert op mogelijk ongewenste gevolgen!'
                        : ''
                    }`}
                    linkUrl={`/house/${houseId}/intake/${SOLUTION_TOPICS[solution]}`}
                    linkLabel="Ga naar de opname →"
                  />
                }
              />
            )}

          {quote.deal.hasHadAPTC && mostRecentAcceptedQuote?.items && (
            <Card p={0} animation="none" mb={2} borderRadius="9px" style={{ overflowY: 'hidden' }}>
              <Accordion
                contentBorderColor="grayLight"
                baseBgColor="yellowLighter"
                icon={FrankensteinMonster}
                iconSize="1em"
                title="Mutaties na schouw"
                titleSize={6}
              >
                <JobMutationDiffs
                  originalItems={[...mostRecentAcceptedQuote.items]}
                  mutationItems={[...quote.items]}
                />
              </Accordion>
            </Card>
          )}

          <Card p={0} animation="none" mb={2} borderRadius="9px" style={{ overflowY: 'hidden' }}>
            <Accordion
              contentBorderColor="grayLight"
              defaultOpen
              icon={FileEuro}
              iconSize="1em"
              title="Algemeen"
              titleSize={6}
            >
              <QuoteModalGeneralInfo quote={quote} solutionAccepted={solutionAccepted} />
            </Accordion>
          </Card>

          <Card p={0} animation="none" mb={2} borderRadius="9px" style={{ overflowY: 'hidden' }}>
            <Accordion
              contentBorderColor="grayLight"
              defaultOpen={false}
              icon={Cart}
              iconSize="1em"
              title="Producten"
              titleSize={6}
            >
              <ProductList fontSize={6} items={items} installation={quote} isForQuote readOnly />
            </Accordion>
          </Card>

          {solution === Solution.PvSystem && (
            <PvQuoteInfo
              cardinalDirection={cardinalDirection}
              setCardinalDirection={setCardinalDirection}
              multipleRoofSurfaces={multipleRoofSurfaces}
              setMultipleRoofSurfaces={setMultipleRoofSurfaces}
              installationPlanProperties={installationPlanProperties}
              setInstallationPlanProperties={setInstallationPlanProperties}
              neighbourDiscount={neighbourDiscount}
              setNeighbourDiscount={setNeighbourDiscount}
              pvInfoComplete={true}
              defaultOpen={false}
              submit={() => {
                updateQuoteMutation({
                  variables: {
                    id: quote.id,
                    quote: {
                      pvSystemInfo: {
                        cardinalDirection,
                        multipleRoofSurfaces,
                        installationPlanProperties,
                        neighbourDiscount,
                      },
                    },
                  },
                });
              }}
              submitDisabled={quote.isAccepted}
            />
          )}

          <Card p={0} animation="none" mb={2} borderRadius="9px" style={{ overflowY: 'hidden' }}>
            <Accordion
              contentBorderColor="grayLight"
              defaultOpen={false}
              icon={Paperclip}
              iconSize="1em"
              title="Toevoegingen"
              titleSize={6}
              unmountCollapsedContent
            >
              <QuoteModalAdviceAdditions houseId={houseId} quote={quote} />
            </Accordion>
          </Card>

          <Card p={0} animation="none" mb={2} borderRadius="9px" style={{ overflowY: 'hidden' }}>
            <Accordion
              contentBorderColor="grayLight"
              defaultOpen={false}
              icon={EnergyCircle}
              iconSize="1em"
              title="Berekeningen"
              titleSize={6}
            >
              <CalculationInput
                houseId={houseId}
                quote={quote}
                manualElectricity={manualElectricity}
                setManualElectricity={setManualElectricity}
                manualGas={manualGas}
                setManualGas={setManualGas}
              />
              <Calculations useEstimate={false} isNarrow={mobile} installable={quote} />
            </Accordion>
          </Card>

          <Flex flexWrap="wrap" mb={2}>
            <Box width={1}>
              <QuoteStatusButtons
                quote={quote}
                mobile={mobile}
                updateQuote={updateQuoteMutation}
                houseId={houseId}
                isOnlyVisibleQuoteOnDeal={isOnlyVisibleQuoteOnDeal}
                isReadOnly={isReadOnly}
              />
            </Box>
          </Flex>

          <Flex flexWrap="wrap" mx={-1}>
            <Box px={1} width={1 / 2}>
              <Button
                to={`/opdracht/${quote.id}/schouw`}
                bgColor="orange"
                label="Ga naar schouwopdracht →"
                disabled={!!quote.final}
                iconStart={PageCheck}
                fluid
                mb={0}
              />
            </Box>
            <Box px={1} width={1 / 2}>
              <Button
                to={`/opdracht/${quote.id}/installatie`}
                label="Ga naar installatieopdracht →"
                disabled={!quote.final}
                iconStart={Hardhat}
                fluid
                mb={0}
              />
            </Box>
          </Flex>

          <TransferQuoteModal
            isOpen={isQuoteTransferModalOpen}
            onClose={() => setQuoteTransferModalOpen(false)}
            quote={quote}
          />
        </>
      )}
    </Modal>
  );
};

export default QuoteModal;
