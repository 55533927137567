import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Placeholder,
  Spinner,
  SpinnerOverlay,
  Switch,
  Table,
  Toast,
} from '@energiebespaarders/symbols';
import { Small } from '@energiebespaarders/symbols/helpers';
import React from 'react';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import useToaster from '../../hooks/useToaster';
import { delimit } from '../../lib/utils';
import {
  jobDomainQuote_quoteById,
  jobDomainQuote_quoteById_jobs,
} from '../../types/generated/jobDomainQuote';
import {
  jobLatestProductPrices,
  jobLatestProductPricesVariables,
} from '../../types/generated/jobLatestProductPrices';
import {
  setJobItemPurchasePrice as setJobItemPurchasePriceMutation,
  setJobItemPurchasePriceVariables,
} from '../../types/generated/setJobItemPurchasePrice';
import TotalsRows from '../installatron/TotalsRows';

const GET_JOB_LATEST_PRICES = gql`
  query jobLatestProductPrices($jobId: ID!) {
    jobLatestProductPrices(jobId: $jobId) {
      id
      purchasePrice
      productId
    }
  }
`;

const SET_JOB_ITEM_PURCHASE_PRICE = gql`
  mutation setJobItemPurchasePrice($jobId: ID!, $productId: ID!, $purchasePrice: Float!) {
    setJobItemPurchasePrice(jobId: $jobId, productId: $productId, purchasePrice: $purchasePrice) {
      id
      items {
        product {
          id
        }
        purchasePrice
      }
    }
  }
`;

interface Props {
  quote: jobDomainQuote_quoteById;
  job: jobDomainQuote_quoteById_jobs;
}

const JobItemPurchasePricesTable = ({ quote, job }: Props) => {
  const mobile = useIsMobile();
  const toast = useToaster();

  const { data: jobLatestPrices, error: jobLatestError, loading: jobLatestLoading } = useQuery<
    jobLatestProductPrices,
    jobLatestProductPricesVariables
  >(GET_JOB_LATEST_PRICES, {
    fetchPolicy: 'network-only',
    variables: {
      jobId: job.id,
    },
  });

  const [
    setJobItemPurchasePrice,
    { error: setJobItemPurchasePriceError, loading: setJobItemPurchasePriceLoading },
  ] = useMutation<setJobItemPurchasePriceMutation, setJobItemPurchasePriceVariables>(
    SET_JOB_ITEM_PURCHASE_PRICE,
    {
      onCompleted: () => {
        toast({
          type: 'success',
          message: 'Inkoopprijs aangepast op opdracht',
        });
      },
      onError: error => {
        console.error(error);
        toast({
          type: 'error',
          message: error.message,
        });
      },
    },
  );

  if (jobLatestLoading) return <Spinner />;
  if (!jobLatestPrices || jobLatestError || setJobItemPurchasePriceError)
    return <Placeholder error={jobLatestError || setJobItemPurchasePriceError} />;

  let totalPurchasePrice = 0;
  const suppliedItems = job.items.filter(
    item => item.supplierId === job.installer.pairedSupplierId,
  );
  suppliedItems.forEach(item => {
    totalPurchasePrice += item.purchasePrice * item.amount;
  });

  return (
    <Box width={1} mt={2}>
      {quote.updatedPurchasePrices.length > 0 && (
        <Toast
          type="alert"
          message={
            'De inkoopprijzen van deze opdracht zijn veranderd sinds de offerte was aangemaakt. Controleer de Opdrachtprijs!'
          }
          toastId={1}
          width="100%"
          my={3}
        />
      )}
      <Table mobile={mobile} layout={[6 / 16, 2 / 16, 3 / 16, 2 / 16, 1 / 16, 2 / 16]}>
        {setJobItemPurchasePriceLoading && <SpinnerOverlay />}
        <Table.Row isHeader>
          <Table.Cell>Product</Table.Cell>
          <Table.Cell textAlign="right">Recente prijs</Table.Cell>
          <Table.Cell textAlign="center">Gebruik</Table.Cell>
          <Table.Cell textAlign="left">Offerte prijs</Table.Cell>
          <Table.Cell textAlign="right">Aantal</Table.Cell>
          <Table.Cell textAlign="right">Huidig</Table.Cell>
        </Table.Row>
        {job.items.map(i => {
          const quotePrice = quote.items.find(qi => qi.product.id === i.product.id)!.purchasePrice;
          const productDbPrice = jobLatestPrices?.jobLatestProductPrices?.find(
            jobPrice => jobPrice.productId === i.product.id,
          )!.purchasePrice;
          const isUsingQuotePrice = i.purchasePrice === quotePrice;
          return (
            <Table.Row key={i.product.id}>
              <Table.Cell>{i.product.title}</Table.Cell>
              <Table.Cell textAlign="right" fontWeight={isUsingQuotePrice ? 300 : 500}>
                € {delimit(productDbPrice)}
              </Table.Cell>
              <Table.Cell>
                {quotePrice !== productDbPrice ? (
                  <Switch
                    bgColorOff="blue"
                    bgColorOn="darkBlue"
                    isOn={isUsingQuotePrice}
                    offLabel="Recent"
                    onLabel="Offerte"
                    toggleSwitch={() => {
                      setJobItemPurchasePrice({
                        variables: {
                          jobId: job.id,
                          productId: i.product.id,
                          purchasePrice: isUsingQuotePrice ? productDbPrice ?? 0 : quotePrice,
                        },
                      });
                    }}
                  />
                ) : (
                  <Small>Prijs hetzelfde</Small>
                )}
              </Table.Cell>
              <Table.Cell textAlign="left" fontWeight={isUsingQuotePrice ? 500 : 300}>
                € {delimit(quotePrice)}
              </Table.Cell>
              <Table.Cell textAlign="right">{i.amount}</Table.Cell>
              <Table.Cell textAlign="right">€ {delimit(i.purchasePrice)}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
      <Table layout={[7 / 8, 1 / 8]}>
        <Table.Row mobile={mobile} rowBgColor="white" rowColor="grayDarker">
          <Table.Cell textAlign="right">Totale inkoopprijs</Table.Cell>
          <Table.Cell textAlign="right" fontWeight="bold">
            € {delimit(totalPurchasePrice)}
          </Table.Cell>
        </Table.Row>
      </Table>
      <TotalsRows
        discount={quote.discount}
        items={quote.items}
        mobile={mobile}
        readOnly={true}
        explicitlyLabelAsRetailPrice
      />
    </Box>
  );
};

export default JobItemPurchasePricesTable;
