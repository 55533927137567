import { gql, useQuery } from '@apollo/client';
import { SOLUTIONS_NL } from '@energiebespaarders/constants';
import {
  Accordion,
  Box,
  Card,
  Flex,
  Icon,
  Modal,
  Placeholder,
  Separator,
  Tooltip,
} from '@energiebespaarders/symbols';
import { Heading } from '@energiebespaarders/symbols/helpers';
import {
  AndroidPhone,
  Calendar,
  CheckboxActive,
  PaperAirplane,
  Wrenches,
} from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React from 'react';
import { quoteWithJobs, quoteWithJobsVariables } from '../../types/generated/quoteWithJobs';
import { JobType, MailjetTemplateDescriptor } from '../../types/graphql-global-types';
import PlanningControls from './PlanningControls';

interface PlanningControlsModalProps {
  quoteId: string;
  close: () => void;
}

const QUOTE_WITH_JOBS = gql`
  query quoteWithJobs($quoteId: ID!) {
    quoteById(quoteId: $quoteId) {
      id
      solution
      jobs {
        id
        created
        planningStatus
        status
        type
        completed
        plannings {
          jobId
          created
          sent
          plannedFor
          plannedOn
          suspended
          metadata {
            comment
            additionalInformation
          }
        }
        currentPlanning {
          jobId
          created
          sent
          plannedFor
          plannedOn
          suspended
          metadata {
            comment
            additionalInformation
          }
        }
        proposals {
          jobId
          created
          fulfilled {
            key
          }
        }
        installerSignoff {
          jobId
          type
          markedAsComplete {
            date
          }
        }
        rating {
          id
          rater {
            ... on Customer {
              id
              firstName
              lastName
            }
            ... on Operator {
              id
              firstName
              lastName
            }
          }
        }
        installer {
          id
          name
          inspectionEmail
          installationEmail
          email
        }
      }
      house {
        id
        customer {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const PlanningControlsModal: React.FC<PlanningControlsModalProps> = ({ close, quoteId }) => {
  const { data, loading, error } = useQuery<quoteWithJobs, quoteWithJobsVariables>(
    QUOTE_WITH_JOBS,
    {
      skip: !quoteId,
      variables: { quoteId },
      fetchPolicy: 'cache-first',
    },
  );

  if (loading || error || !data?.quoteById) {
    return (
      <Modal isOpen={!!quoteId} title="Opdrachtplanning" onRequestClose={close}>
        {error && <Placeholder error={error} />}
        {!loading && !error && !data?.quoteById && <p>No data?</p>}
      </Modal>
    );
  }

  const quote = data.quoteById;

  const installation = quote.jobs.find(j => j.type === JobType.INSTALLATION);
  const ptc = quote.jobs.find(j => j.type === JobType.PTC);

  const ptcSentDate = ptc?.plannings?.slice().sort((a, b) => b.sent - a.sent)[0]?.sent;
  const ptcPlanned = ptc?.plannings?.slice().sort((a, b) => b.created - a.created)[0]?.plannedFor;
  const ptcPlannedDate = ptcPlanned ? new Date(ptcPlanned) : undefined;
  const ptcCompleted = ptc?.completed;
  const ptcCompletedDate = ptcCompleted ? new Date(ptcCompleted) : undefined;

  const installationSentDate = installation?.plannings?.slice().sort((a, b) => b.sent - a.sent)[0]
    ?.sent;
  const installationPlanned = installation?.plannings
    ?.slice()
    .sort((a, b) => b.created - a.created)[0]?.plannedFor;
  const installationPlannedDate = installationPlanned ? new Date(installationPlanned) : undefined;
  const installationCompleted = installation?.completed;
  const installationCompletedDate = installationCompleted
    ? new Date(installationCompleted)
    : undefined;

  const dates = [
    { text: 'Verzonden', icon: PaperAirplane, date: ptcSentDate },
    { text: 'Ingepland voor', icon: Calendar, date: ptcPlannedDate },
    { text: 'Uitgevoerd', icon: CheckboxActive, date: ptcCompletedDate },
    { text: 'Verzonden', icon: PaperAirplane, date: installationSentDate },
    { text: 'Ingepland voor', icon: Calendar, date: installationPlannedDate },
    { text: 'Uitgevoerd', icon: CheckboxActive, date: installationCompletedDate },
  ];

  return (
    <Modal
      isOpen={true}
      onRequestClose={close}
      title={`Opdrachtplanning ${quote.house.customer!.firstName} ${
        quote.house.customer!.lastName
      } (${SOLUTIONS_NL[quote.solution]})`}
      minHeight={'70vh'}
    >
      <Flex textAlign="center">
        <Box width={1 / 2}>
          <Heading>Schouw</Heading>
        </Box>
        <Box width={1 / 2}>
          <Heading>Installatie</Heading>
        </Box>
      </Flex>
      <Flex justifyContent="space-around" textAlign="center">
        {dates.map((date, i) => (
          <Box key={`job date name ${i}`} width={1}>
            <Tooltip content={date.text}>
              <>
                <Icon icon={date.icon} fill={date.date ? 'green' : 'gray'} />
                <br />
                {date.date ? dayjs(date.date).format('DD/MM/YYYY') : '-'}
              </>
            </Tooltip>
          </Box>
        ))}
      </Flex>

      <Separator my={4} />

      <Card
        animation="none"
        shadow="none"
        borderRadius="3px"
        borderColor="orangeLight"
        p={0}
        mb={2}
      >
        <Accordion
          baseBgColor="floral"
          contentBgColor="white"
          contentBorderColor="orangeLight"
          icon={AndroidPhone}
          iconColor={ptc ? 'orange' : 'gray'}
          title={'Schouw ' + (ptc?.plannings[0]?.suspended ? ' [IN AFWACHTING]' : '')}
          titleSize={5}
          zIndex={110}
        >
          {ptc ? (
            <PlanningControls
              job={{
                ...ptc,
                quoteId,
                plannings: ptc.plannings.slice(),
                proposals: ptc.proposals.slice(),
              }}
              quoteId={quote.id}
              currentDate={ptcPlannedDate}
              installerName={ptc.installer.name}
              installerEmail={ptc.installer.inspectionEmail || ptc.installer.email}
              chaserEmailTemplate={MailjetTemplateDescriptor.PTCResultsReminder}
            />
          ) : (
            'Geen schouw'
          )}
        </Accordion>
      </Card>

      <Card animation="none" shadow="none" p={0} borderRadius="3px" borderColor="greenLight">
        <Accordion
          baseBgColor="greenSlate"
          contentBorderColor="greenLight"
          contentBgColor="white"
          icon={Wrenches}
          iconColor={installation ? 'green' : 'gray'}
          title={'Installatie ' + (installation?.plannings[0]?.suspended ? ' [IN AFWACHTING]' : '')}
          titleSize={5}
          zIndex={110}
        >
          {installation ? (
            <PlanningControls
              job={{
                ...installation,
                quoteId,
                plannings: installation.plannings.slice(),
                proposals: installation.proposals.slice(),
              }}
              quoteId={quote.id}
              currentDate={ptcPlannedDate}
              installerName={installation.installer.name}
              installerEmail={
                installation.installer.installationEmail || installation.installer.email
              }
              chaserEmailTemplate={MailjetTemplateDescriptor.PTCResultsReminder}
            />
          ) : (
            'Geen installatie'
          )}
        </Accordion>
      </Card>
    </Modal>
  );
};

export default PlanningControlsModal;
