import React from 'react';
import { GET_QUOTE_FILES, UPLOAD_QUOTE_FILES } from '../queries/files';
import { Uploader } from '@energiebespaarders/symbols';
import useToaster from '../hooks/useToaster';
import { useMe } from '../hooks/useMe';
import { uploadQuoteFiles } from '../types/generated/uploadQuoteFiles';
import { useMutation } from '@apollo/client';
import { getQuoteFiles, getQuoteFilesVariables } from '../types/generated/getQuoteFiles';

interface QuoteUploaderProps {
  bgColor?: string;
  borderColor?: string;
  height?: string;
  quoteId: string;
  multiple?: boolean;
  tags: string[];
  text?: string;
}

const QuoteUploader: React.FC<QuoteUploaderProps> = ({
  bgColor = 'orange',
  borderColor,
  height,
  quoteId,
  multiple = true,
  tags = [],
  text,
}) => {
  const { me } = useMe();
  const toast = useToaster();
  const [uploadQuoteFilesMutation, { loading, error }] = useMutation<uploadQuoteFiles>(
    UPLOAD_QUOTE_FILES,
    {
      update: (store, { data }) => {
        const queryData = store.readQuery<getQuoteFiles, getQuoteFilesVariables>({
          query: GET_QUOTE_FILES,
          variables: { quoteId },
        });
        if (queryData) {
          store.writeQuery({
            query: GET_QUOTE_FILES,
            data: {
              quoteById: {
                ...queryData.quoteById,
                files: queryData.quoteById?.files
                  ? queryData.quoteById.files.concat(data?.uploadQuoteFiles ?? [])
                  : data?.uploadQuoteFiles ?? [],
              },
            },
            variables: { quoteId },
          });
        }
      },
      onCompleted: () =>
        toast({
          type: 'success',
          message: 'Bestanden zijn succesvol geüpload',
        }),
      onError: () =>
        toast({
          type: 'error',
          message: 'Er is een fout opgetreden bij het uploaden van bestanden',
        }),
    },
  );
  return (
    <Uploader
      bgColor={bgColor}
      borderColor={borderColor}
      height={height}
      loading={loading}
      error={error}
      multiple={multiple}
      text={text}
      upload={({ variables }: { variables: any }) => {
        uploadQuoteFilesMutation({
          variables: {
            quoteId,
            files: variables.files,
            tags,
            uploaderId: me.id,
            uploaderUserType: 'operator',
          },
        });
      }}
      mb={1}
    />
  );
};

export default QuoteUploader;
