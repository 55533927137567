import { gql } from '@apollo/client';
import { FileFragment } from '../fragments/index';

export const GET_HOUSE_FILES = gql`
  ${FileFragment}
  query getHouseFiles($houseId: ID!) {
    house(id: $houseId) {
      id
      files {
        ...File
      }
    }
  }
`;

export const UPLOAD_HOUSE_FILES = gql`
  ${FileFragment}
  mutation uploadHouseFiles(
    $id: ID!
    $files: [Upload!]!
    $tags: [String!]
    $uploaderId: String
    $uploaderUserType: String
  ) {
    uploadHouseFiles(
      id: $id
      files: $files
      tags: $tags
      uploaderId: $uploaderId
      uploaderUserType: $uploaderUserType
    ) {
      ...File
    }
  }
`;

export const DELETE_HOUSE_FILE = gql`
  mutation deleteHouseFile($id: ID!, $fileId: ID!) {
    deleteHouseFile(id: $id, fileId: $fileId)
  }
`;

export const GET_PRODUCT_FILES = gql`
  ${FileFragment}
  query getProductFiles($productId: ID!) {
    productById(id: $productId) {
      id
      files {
        ...File
      }
    }
  }
`;

export const UPLOAD_PRODUCT_FILES = gql`
  ${FileFragment}
  mutation uploadProductFiles(
    $id: ID!
    $files: [Upload!]!
    $tags: [String!]
    $uploaderId: String
    $uploaderUserType: String
  ) {
    uploadProductFiles(
      id: $id
      files: $files
      tags: $tags
      uploaderId: $uploaderId
      uploaderUserType: $uploaderUserType
    ) {
      ...File
    }
  }
`;

export const DELETE_PRODUCT_FILE = gql`
  mutation deleteProductFile($id: ID!, $fileId: ID!) {
    deleteProductFile(id: $id, fileId: $fileId)
  }
`;

export const GET_QUOTE_FILES = gql`
  ${FileFragment}
  query getQuoteFiles($quoteId: ID!) {
    quoteById(quoteId: $quoteId) {
      id
      files {
        ...File
      }
    }
  }
`;

export const GET_ALL_QUOTE_FILES = gql`
  ${FileFragment}
  query getQuoteFilesByHouse($houseId: ID!) {
    house(id: $houseId) {
      id
      quotes {
        id
        files {
          ...File
        }
      }
    }
  }
`;

export const UPLOAD_QUOTE_FILES = gql`
  ${FileFragment}
  mutation uploadQuoteFiles(
    $quoteId: ID!
    $files: [Upload!]!
    $tags: [String!]
    $uploaderId: String
    $uploaderUserType: String
  ) {
    uploadQuoteFiles(
      quoteId: $quoteId
      files: $files
      tags: $tags
      uploaderId: $uploaderId
      uploaderUserType: $uploaderUserType
    ) {
      ...File
    }
  }
`;

export const DELETE_QUOTE_FILE = gql`
  mutation deleteQuoteFile($quoteId: ID!, $fileId: ID!) {
    deleteQuoteFile(quoteId: $quoteId, fileId: $fileId)
  }
`;

export const GET_INSTALLER_FILES = gql`
  ${FileFragment}
  query getInstallerFiles($id: ID!) {
    installerById(id: $id) {
      id
      name
      files {
        ...File
      }
    }
  }
`;

export const UPLOAD_INSTALLER_FILES = gql`
  ${FileFragment}
  mutation uploadInstallerFiles(
    $id: ID!
    $files: [Upload!]!
    $tags: [String!]
    $uploaderId: String
    $uploaderUserType: String
  ) {
    uploadInstallerFiles(
      id: $id
      files: $files
      tags: $tags
      uploaderId: $uploaderId
      uploaderUserType: $uploaderUserType
    ) {
      ...File
    }
  }
`;

export const DELETE_INSTALLER_FILE = gql`
  mutation deleteInstallerFile($id: ID!, $fileId: ID!) {
    deleteInstallerFile(id: $id, fileId: $fileId)
  }
`;

export const GET_SUPPLIER_FILES = gql`
  ${FileFragment}
  query getSupplierFiles($id: ID!) {
    supplierById(id: $id) {
      id
      name
      files {
        ...File
      }
    }
  }
`;

export const UPLOAD_SUPPLIER_FILES = gql`
  ${FileFragment}
  mutation uploadSupplierFiles(
    $id: ID!
    $files: [Upload!]!
    $tags: [String!]
    $uploaderId: String
    $uploaderUserType: String
  ) {
    uploadSupplierFiles(
      id: $id
      files: $files
      tags: $tags
      uploaderId: $uploaderId
      uploaderUserType: $uploaderUserType
    ) {
      ...File
    }
  }
`;

export const DELETE_SUPPLIER_FILE = gql`
  mutation deleteSupplierFile($id: ID!, $fileId: ID!) {
    deleteSupplierFile(id: $id, fileId: $fileId)
  }
`;
