import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Icon,
  Modal,
  Placeholder,
  Select,
  Spacer,
  Tooltip,
  Wrapper,
} from '@energiebespaarders/symbols';
import { Add, Filter, Sync } from '@energiebespaarders/symbols/icons/solid';
import CompanyLogo from '../../components/CompanyLogo';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { useQuery } from '@apollo/client';
import {
  companies,
  companies_installers,
  companies_suppliers,
} from '../../types/generated/companies';
import { InstallerStatus } from '../../types/graphql-global-types';
import AddInstallerForm from '../../components/dashboard/AddInstallerForm';
import InstallerStatusIndicators from '../../components/companies/InstallerStatusIndicator';
import { useLocalStorage } from 'usehooks-ts';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { Center } from '@energiebespaarders/symbols/helpers';

export const GET_COMPANIES = gql`
  query companies {
    installers {
      id
      name
      status {
        value
      }
    }
    suppliers {
      id
      name
      pairedInstallerId
    }
  }
`;

const InstallerStatusOptions: DropdownOption<InstallerStatus>[] = Object.entries(
  InstallerStatusIndicators,
).map(([value, indicator]) => ({
  label: (
    <span>
      <Icon icon={indicator.icon} solid fill={indicator.color} /> {indicator.label}
    </span>
  ),
  value: value as InstallerStatus,
}));

const initialStatusFilter = [InstallerStatus.setup, InstallerStatus.active, InstallerStatus.paused];

const PartnerDirectory = () => {
  const mobile = useIsMobile();

  const [newInstallerModal, setNewInstallerModal] = useState(false);

  const { loading, data, error, refetch } = useQuery<companies>(GET_COMPANIES);

  const [statusFilter, setStatusFilter] = useLocalStorage<InstallerStatus[]>(
    'installer-status-filter',
    initialStatusFilter,
  );

  useEffect(() => {
    if (!Array.isArray(statusFilter)) {
      setStatusFilter(initialStatusFilter);
    }
  }, [setStatusFilter, statusFilter]);

  const renderCompanies = (
    companies: ReadonlyArray<companies_installers | companies_suppliers>,
  ) => {
    const installerPerSupplier: Record<string, companies_installers> = {};
    data?.suppliers.map(
      s => (installerPerSupplier[s.id] = data.installers.find(i => i.id === s.pairedInstallerId)!),
    );

    const filteredCompanies = companies.filter(c =>
      c.__typename === 'Installer'
        ? statusFilter?.includes(c.status.value)
        : statusFilter?.includes(installerPerSupplier[c.id].status.value),
    );
    return filteredCompanies
      .sort((a, b) => (b.name > a.name ? -1 : 1))
      .map(company => {
        const { id, name, __typename } = company;
        const companyType = __typename.toLowerCase() as 'installer' | 'supplier';

        const status =
          __typename === 'Installer'
            ? company.status.value
            : installerPerSupplier[company.id]?.status.value;

        const indicator =
          status !== InstallerStatus.active ? InstallerStatusIndicators[status] : undefined;

        return (
          <Box key={`${companyType}-${name}`} width={[1, 1, 1 / 2]} px={1}>
            <Link to={`/${companyType}/${id}`}>
              <Card mb={2} bgColor={indicator?.bgColor}>
                <CompanyLogo companyType={companyType} companyId={id} hideEmpty size="sm" />
                <div>
                  {indicator ? (
                    <Tooltip
                      bgColor={indicator.color}
                      content={[indicator.label, indicator.description].join(': ')}
                    >
                      <Icon icon={indicator.icon} solid fill={indicator.color} />
                    </Tooltip>
                  ) : null}{' '}
                  {name}
                </div>
              </Card>
            </Link>
          </Box>
        );
      });
  };

  return (
    <Wrapper mobile={mobile} p={mobile ? 2 : 4} pb={mobile ? 10 : 5}>
      <Helmet title="Onze partners" />
      <Container>
        {loading || error ? (
          <Placeholder error={error} />
        ) : (
          <>
            <Flex flexWrap="wrap">
              <Box width={[1, 1, 1, 1 / 3]}>
                <h2>Installatiebedrijven</h2>
              </Box>
              <Box width={[1, 1, 1, 2 / 3]}>
                <Flex flexWrap="wrap" justifyContent="space-between">
                  <Box flex={1}>
                    <Select
                      isMulti
                      label="Filter op status"
                      options={InstallerStatusOptions}
                      value={
                        statusFilter?.map(status =>
                          InstallerStatusOptions.find(o => o.value === status),
                        ) || []
                      }
                      onChange={options =>
                        setStatusFilter(
                          ((options as unknown) as DropdownOption<InstallerStatus>[])?.map(
                            o => o.value,
                          ),
                        )
                      }
                    />
                  </Box>

                  <Button
                    bgColor="green"
                    label="Verversen"
                    onClick={() => refetch()}
                    ml={3}
                    minimal
                    iconStart={Sync}
                  />
                </Flex>
              </Box>
            </Flex>
            <Flex flexWrap="wrap" mx={-1}>
              <Box width={[1, 1, 1 / 2]} px={1} mb={2}>
                <Button
                  fluid
                  iconStart={Add}
                  label="Installatiebedrijf toevoegen"
                  minIconSize="1em"
                  onClick={() => setNewInstallerModal(true)}
                  mb={0}
                />
              </Box>

              {renderCompanies(data!.installers)}

              {(!statusFilter || statusFilter.length === 0) && (
                <Box width={1}>
                  <Center block>
                    <Spacer vertical amount={16} />
                    <i>Geen filter ingesteld</i>
                    <br />
                    <Button onClick={() => setStatusFilter(initialStatusFilter)} iconStart={Filter}>
                      Toon actieve bedrijven
                    </Button>
                    <Spacer vertical amount={16} />
                  </Center>
                </Box>
              )}
            </Flex>
            <h2>
              Leveranciers
              <Button
                bgColor="green"
                label="Verversen"
                onClick={() => refetch()}
                ml={3}
                minimal
                iconStart={Sync}
              />
            </h2>
            <Flex flexWrap="wrap" mx={-1}>
              {renderCompanies(data!.suppliers)}
            </Flex>
          </>
        )}
      </Container>

      <Modal
        isOpen={newInstallerModal}
        mobile={mobile}
        onRequestClose={() => setNewInstallerModal(false)}
        title="Installatiebedrijf toevoegen"
      >
        <AddInstallerForm onSubmitCompleted={() => setNewInstallerModal(false)} />
      </Modal>
    </Wrapper>
  );
};

export default PartnerDirectory;
