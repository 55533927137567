import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

type IsAuthenticatedCtx = [boolean, Dispatch<SetStateAction<boolean>> | null];

export const IsAuthenticatedContext = React.createContext<IsAuthenticatedCtx>([false, null]);

export const IsAuthenticatedProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const contextValue: IsAuthenticatedCtx = [isAuthenticated, setIsAuthenticated];
  return (
    <IsAuthenticatedContext.Provider value={contextValue}>
      {children}
    </IsAuthenticatedContext.Provider>
  );
};

export function useIsAuthenticated() {
  const [isAuthenticated, setIsAuthenticated] = useContext(IsAuthenticatedContext);
  return {
    isAuthenticated,
    setIsAuthenticated: setIsAuthenticated as Dispatch<SetStateAction<boolean>>, // It will always be set after initialization, no need to check for null
  };
}
