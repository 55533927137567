import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Solution } from '@energiebespaarders/constants';
import { SEND_JOB_FOLLOW_UP_EMAIL } from '../../../queries/job';
import { QUOTE_BY_ID } from '../../../queries/quote';
import { quoteById, quoteByIdVariables } from '../../../types/generated/quoteById';
import {
  sendJobCompletedFollowUp,
  sendJobCompletedFollowUpVariables,
} from '../../../types/generated/sendJobCompletedFollowUp';
import { DeepPartial, FormValueObject } from '@energiebespaarders/hooks/useForm';
import { Checkbox } from '@energiebespaarders/symbols';
import useToaster from '../../../hooks/useToaster';
import { JobFollowUpType } from '../../../types/graphql-global-types';

export const useInvoiceExpectationsEmailData = (quoteId: string) => {
  const toast = useToaster();

  const { data: quoteData } = useQuery<quoteById, quoteByIdVariables>(QUOTE_BY_ID, {
    variables: { quoteId },
  });
  const invoiceEmailType: JobFollowUpType =
    quoteData?.quoteById?.solution === Solution.PvSystem
      ? JobFollowUpType.invoiceBtw
      : quoteData?.quoteById?.items.some(i => i.product.isdeCode)
      ? JobFollowUpType.invoiceISDE
      : JobFollowUpType.invoice;

  const [sendJobFollowUpEmail] = useMutation<
    sendJobCompletedFollowUp,
    sendJobCompletedFollowUpVariables
  >(SEND_JOB_FOLLOW_UP_EMAIL, {
    onCompleted: () => toast({ type: 'success', message: 'Email over factuur verstuurd!' }),
    onError: e =>
      toast({ type: 'error', message: `Fout bij versturen van factuur email: ${e.message}` }),
  });

  return {
    invoiceEmailType,
    sendInvoiceEmailMutation: sendJobFollowUpEmail,
  };
};

const InvoiceExpectationsEmailCheckbox: React.FC<{
  invoiceEmailType: JobFollowUpType;
  handleChange: (
    values: DeepPartial<FormValueObject<{ sendInvoiceEmail: JobFollowUpType }>>,
  ) => void;
  value: boolean;
}> = ({ handleChange, invoiceEmailType, value }) => {
  return (
    <Checkbox
      id="sendInvoiceEmail"
      label={`E-mail sturen naar klant met verwachtingen over factuur${
        invoiceEmailType === 'invoiceBtw'
          ? ' en BTW-teruggave'
          : invoiceEmailType === 'invoiceISDE'
          ? ' en ISDE subsidie'
          : ''
      }`}
      value
      checked={value}
      onChange={() => handleChange({ sendInvoiceEmail: !value })}
      width="100%"
    />
  );
};

export default InvoiceExpectationsEmailCheckbox;
