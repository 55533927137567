import { DocumentNode, useMutation } from '@apollo/client';
import {
  CATEGORY_TYPES,
  getDomainFromSolution,
  getSolutionsFromDomain,
  PRODUCT_CATEGORIES_NL,
  Solution,
  SOLUTIONS,
  SOLUTIONS_NL,
  SOLUTION_DOMAIN_PRODUCT_CATEGORIES,
  SOLUTION_PRODUCT_CATEGORIES,
} from '@energiebespaarders/constants';
import { Icon, Modal, Select } from '@energiebespaarders/symbols';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { Blue, Small } from '@energiebespaarders/symbols/helpers';
import { Info } from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import useToaster from '../../hooks/useToaster';
import { ADD_PRODUCT } from '../../queries/products';
import { addProduct, addProductVariables } from '../../types/generated/addProduct';
import { sortedProducts_sortedProducts } from '../../types/generated/sortedProducts';
import {
  ProductCategory,
  ProductInput,
  Solution as GQLSolution,
} from '../../types/graphql-global-types';
import ProductForm from '../products/ProductForm';
import { convertSubmissionValues } from './ProductModal';

interface AddProductModalProps {
  closeModal: () => void;
  isOpen: boolean;
  refetchQueries: { query: DocumentNode; variables: Record<string, any> }[];
  solution?: Solution;
  category?: ProductCategory;
}

export const AddProductModal: React.FC<AddProductModalProps> = ({
  closeModal,
  isOpen,
  refetchQueries,
  ...props
}) => {
  const mobile = useIsMobile();
  const toast = useToaster();
  const [solution, setSolution] = useState<Solution | undefined>(props.solution);
  const [category, setCategory] = useState<ProductCategory | undefined>(props.category);
  const prevSolutionRef = useRef(solution);
  useEffect(() => {
    prevSolutionRef.current = solution;
  });
  const prevSolution = prevSolutionRef.current;
  useEffect(() => {
    if (solution !== prevSolution) {
      setSolution(solution);
      setCategory(undefined);
    }
  }, [solution, prevSolution]);

  const [addProductMutation] = useMutation<addProduct, addProductVariables>(ADD_PRODUCT, {
    onCompleted: () => {
      closeModal();
      toast({
        type: 'success',
        message: 'Het product is aangemaakt',
      });
    },
  });
  const handleAddProduct = useCallback(
    async (product: ProductInput) => {
      addProductMutation({
        variables: {
          product: {
            ...convertSubmissionValues(product),
            solution: solution as GQLSolution,
            category: category as ProductCategory,
          },
        },
        refetchQueries,
      });
    },
    [addProductMutation, category, solution, refetchQueries],
  );
  const domain = solution && getDomainFromSolution(solution);

  const solutionOptions: DropdownOption<Solution | ''>[] = useMemo(
    () => [
      { label: 'Kies een oplossing', value: '', disabled: true },
      ...SOLUTIONS.map(solution => ({
        value: solution,
        label: SOLUTIONS_NL[solution],
      })),
    ],
    [],
  );

  // FIXME: Annoying ProductCategory type mismatch cause of schema being lower case and typename being title case
  // const categoryOptions: DropdownOption<ProductCategory | ''>[] = useMemo(() => {
  const categoryOptions: DropdownOption<any>[] = useMemo(() => {
    if (!domain || !solution) return [];
    return [
      { label: 'Kies een categorie', value: '', disabled: true },
      ...SOLUTION_DOMAIN_PRODUCT_CATEGORIES[domain]
        .filter(
          cat =>
            (!cat.solutionSpecific || SOLUTION_PRODUCT_CATEGORIES[solution]?.includes(cat.name)) &&
            (cat.name as any) !== ProductCategory.CompletePvSystem,
        )
        .map(category => ({
          value: category.name,
          label: PRODUCT_CATEGORIES_NL[category.name],
        })),
    ];
  }, [domain, solution]);

  const hiddenFields = useMemo(
    // TODO: This should be keyof sortedproducts, leaving in string for hotfix
    (): (keyof sortedProducts_sortedProducts | string)[] => [
      // NOTE: Needs to be kept in sync with the ProductModal (for updating products)
      'id',
      'created',
      'createdBy',
      'modified',
      'solution',
      'category',
      'installers',
      'files',
      'quotes',
      'acceptedQuoteCount',
      'isInstallable',
      'isSuppliable',
      'revisions',
      'archived',
      'priceRevisions',
      ...(category === ProductCategory.CompletePvSystem
        ? [
            'description',
            'pMax',
            'pvPanel',
            'inverter',
            'optimizer',
            'installationMaterial',
            'labor',
          ]
        : []),
      // Labor material and thickness is only needed for wall & floor, for auto installation gen
      ...(category === ProductCategory.Labor &&
      !(solution === Solution.WallInsulation || solution === Solution.FloorInsulation)
        ? ['material', 'thickness']
        : []),
    ],
    [category, solution],
  );

  return (
    <Modal
      minHeight="60vh"
      isOpen={isOpen}
      onRequestClose={() =>
        category
          ? window.confirm('Wil je dit product annuleren?')
            ? closeModal()
            : null
          : closeModal()
      }
      title={category ? `${PRODUCT_CATEGORIES_NL[category]} toevoegen` : 'Nieuw product toevoegen'}
      mobile={mobile}
      shouldCloseOnOverlayClick={false}
      timeout={mobile ? 250 : undefined}
      buttons={[
        {
          bgColor: 'red',
          inverse: true,
          label: 'Annuleren',
          onClick: closeModal,
          m: 0,
        },
      ]}
    >
      <Select<Solution | ''>
        clearable={false}
        id="productGroup"
        name="solution"
        disabled={Boolean(props.solution)}
        label="Oplossing"
        onChange={e => setSolution(e.value as Solution)}
        options={solutionOptions}
        value={solutionOptions.find(option => option.value === solution)}
        zIndex={30}
      />
      {solution && (
        <Select<ProductCategory | ''>
          id="productCategory"
          clearable={false}
          name="category"
          label="Categorie"
          onChange={e => setCategory(e.value as ProductCategory)}
          options={categoryOptions}
          value={categoryOptions.find(option => option.value === category)}
          zIndex={20}
        />
      )}
      {domain &&
        SOLUTION_DOMAIN_PRODUCT_CATEGORIES[domain].find(
          cat => cat.name === (category as any) && !cat.solutionSpecific,
        ) &&
        getSolutionsFromDomain(domain).length > 1 && (
          <Small>
            <Blue>
              <Icon icon={Info} solid fill="blue" /> Dit product zal ook beschikbaar zijn voor:{' '}
              {getSolutionsFromDomain(domain)
                .filter(s => s !== solution)
                .map(s => SOLUTIONS_NL[s])
                .join(', ')}
            </Blue>
          </Small>
        )}
      {solution && category && (
        <ProductForm
          handleFormSubmit={handleAddProduct}
          hiddenFields={hiddenFields}
          schemaName={CATEGORY_TYPES[category]}
          category={category}
          solution={solution}
        />
      )}
    </Modal>
  );
};

export default AddProductModal;
