import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Placeholder } from '@energiebespaarders/symbols';
import React from 'react';
import {
  getInstallationCapacity,
  getInstallationCapacityVariables,
} from '../../types/generated/getInstallationCapacity';
import {
  updateInstallerInstallationCapacity,
  updateInstallerInstallationCapacityVariables,
} from '../../types/generated/updateInstallerInstallationCapacity';
import { WeeklyInstallationCapacityInput } from '../../types/graphql-global-types';
import CapacityTable from './CapacityTable';
import { InstallationCapacityFragment } from '../../fragments/Capacity';

const GET_INSTALLATION_CAPACITY = gql`
  ${InstallationCapacityFragment}

  query getInstallationCapacity($id: ID!) {
    installerById(id: $id) {
      id
      solutions
      name
      installationCapacity {
        ...WeeklyJobCapacity
      }
    }
  }
`;

const UPDATE_INSTALLATION_CAPACITY = gql`
  ${InstallationCapacityFragment}

  mutation updateInstallerInstallationCapacity(
    $id: ID!
    $capacity: [WeeklyInstallationCapacityInput!]!
  ) {
    updateInstallerInstallationCapacity(id: $id, capacity: $capacity) {
      id
      installationCapacity {
        ...WeeklyJobCapacity
      }
    }
  }
`;

type InstallationCapacityEditorProps = {
  installerId: string;
};

/**
 * An editor for the installation capacity of an installer.
 * Currently configurable as amount of slots per week per solution.
 */
const InstallationCapacityEditor: React.FC<InstallationCapacityEditorProps> = ({ installerId }) => {
  // Idea:
  // - Columns: solutions
  // - Rows: weeks
  // - Paginated by years

  const { data, loading, error } = useQuery<
    getInstallationCapacity,
    getInstallationCapacityVariables
  >(GET_INSTALLATION_CAPACITY, {
    variables: {
      id: installerId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [updateInstallationCapacity, mutationStatus] = useMutation<
    updateInstallerInstallationCapacity,
    updateInstallerInstallationCapacityVariables
  >(UPDATE_INSTALLATION_CAPACITY);

  const onSubmit = (capacity: WeeklyInstallationCapacityInput[]) => {
    updateInstallationCapacity({
      variables: {
        id: installerId,
        capacity,
      },
    });
  };

  if (loading || !data?.installerById || error) return <Placeholder error={error} />;

  return (
    <Flex flexWrap="wrap">
      <Box width={1}>
        <CapacityTable
          solutions={data.installerById.solutions}
          capacity={data.installerById.installationCapacity}
          onSave={onSubmit}
          isSubmitting={mutationStatus.loading}
          submitError={mutationStatus.error?.message}
        />
      </Box>
    </Flex>
  );
};

export default InstallationCapacityEditor;
