import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Solution } from '@energiebespaarders/constants';
import { Icon, Placeholder, Select, Spinner } from '@energiebespaarders/symbols';
import { Alert } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import useToaster from '../../../hooks/useToaster';
import { INSTALLATION_BY_HOUSE_SOLUTION } from '../../../queries/installatron';
import { installationByHouseSolution_installationByHouseSolution_items } from '../../../types/generated/installationByHouseSolution';
import {
  updateSupplierInInstallation as updateSupplierMutation,
  updateSupplierInInstallationVariables,
} from '../../../types/generated/updateSupplierInInstallation';
import { UPDATE_SUPPLIER_IN_INSTALLATION } from './mutations';
import { SUPPLIERS_BY_PRODUCT } from './queries';

const Choose = styled.p`
  cursor: pointer;
`;

type NoSupplierControlProps = {
  item: Omit<installationByHouseSolution_installationByHouseSolution_items, 'tieredRetailPrice'>;
  installationId: string;
  solution?: Solution;
};

const NoSupplierControl: React.FC<NoSupplierControlProps> = ({
  item,
  installationId,
  solution,
}) => {
  const { activeHouseId } = useActiveHouseId();
  const toast = useToaster();
  const { data: eligableSupplierData, loading: suppliersLoading, error } = useQuery(
    SUPPLIERS_BY_PRODUCT,
    {
      variables: { productId: item.product.id },
      fetchPolicy: 'network-only',
    },
  );

  const [isEditing, setIsEditing] = useState(false);

  const [selectedSupplier, setSelectedSupplier] = useState('');

  const { product, ...rest } = item;
  const [updateInstallation] = useMutation<
    updateSupplierMutation,
    updateSupplierInInstallationVariables
  >(UPDATE_SUPPLIER_IN_INSTALLATION, {
    onError: (e: ApolloError) => {
      toast({
        type: 'error',
        message: e.message,
      });
    },
    update: (cache, { data: updatedInstallation }) => {
      const queryVars = { houseId: activeHouseId, solution };
      cache.writeQuery({
        query: INSTALLATION_BY_HOUSE_SOLUTION,
        variables: queryVars,
        data: updatedInstallation,
      });
    },
    onCompleted: () => {
      toast({
        type: 'success',
        message: 'Installatie succesvol aangepast',
      });
    },
  });

  if (!isEditing)
    return (
      <Choose
        onClick={e => {
          e.stopPropagation();
          setIsEditing(true);
        }}
      >
        <Icon icon={Alert} solid fill="orange" /> {item.product.title}
      </Choose>
    );

  if (error || suppliersLoading || !eligableSupplierData) return <Placeholder error={error} />;

  if (eligableSupplierData.length === 0) return <p>Product niet meer leverbaar!</p>;

  const options = [
    { label: 'Kies leverancier', value: '' },
    ...eligableSupplierData.suppliersByProduct.map((s: any) => ({
      label: s.name,
      value: s.id,
    })),
  ];

  return suppliersLoading || !eligableSupplierData ? (
    <Spinner />
  ) : (
    <div onClick={e => e.stopPropagation()}>
      <Select
        label="Beschikbare leveranciers"
        placeholder="Kies leverancier"
        options={options}
        onChange={(e: { value: string }) => {
          setSelectedSupplier(options.find((i: any) => i.value === e.value));
          if (e.value !== '') {
            updateInstallation({
              variables: {
                installationId,
                oldItem: {
                  ...rest,
                  supplierId: rest.supplier.id,
                  productId: product.id,
                  ...({ supplier: undefined } as any),
                },
                newSupplierId: e.value,
              },
            });
          }
        }}
        value={options.find(opt => opt.value === selectedSupplier)}
      />
    </div>
  );
};

export default NoSupplierControl;
