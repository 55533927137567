import { useMutation, useQuery } from '@apollo/client';
import { getDomainFromSolution, Solution } from '@energiebespaarders/constants';
import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Modal,
  Placeholder,
  RadioGroup,
  Spinner,
} from '@energiebespaarders/symbols';
import { Bold } from '@energiebespaarders/symbols/helpers';
import {
  Block,
  Clock,
  SpecialCheck,
  Star,
  Wrenches,
} from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import { INTAKE_OVERVIEW } from '../../../domains/Intake/query';
import useToaster from '../../../hooks/useToaster';
import {
  GENERATE_INSTALLATION,
  INSTALLATION_BY_HOUSE_SOLUTION,
} from '../../../queries/installatron';
import {
  generateInstallation as generateInstallationMutation,
  generateInstallationVariables,
} from '../../../types/generated/generateInstallation';
import {
  installationByHouseSolution as t_installationQuery,
  installationByHouseSolutionVariables as t_installationVariables,
} from '../../../types/generated/installationByHouseSolution';
import { intakeOverview, intakeOverviewVariables } from '../../../types/generated/intakeOverview';
import { parseInstallationGenerationError } from '../utils';
import generationConditions from './generationConditions';
import InstallationItemBreakdown from './InstallationItemBreakdown';

export const MissingInstallationGenerationData = ({
  missingData,
  blockingData,
}: // references,
{
  missingData?: string[];
  blockingData?: string[];
  // references?: { [key: string]: React.Ref<Question> };
}) => (
  <>
    {missingData && missingData.length > 0 ? (
      <div>
        <Bold>
          <Icon icon={Block} fill="red" solid /> De volgende benodigde vragen zijn nog niet
          beantwoord:
        </Bold>
        <ul>
          {missingData.map(text => (
            <li key={`missing ${text}`}>{text}</li>
          ))}
        </ul>
      </div>
    ) : (
      <Bold>
        <Icon icon={SpecialCheck} fill="green" solid /> Alle benodigde vragen zijn beantwoord om een
        installatie te kunnen genereren
      </Bold>
    )}
    {blockingData && blockingData.length > 0 && (
      <div>
        <Bold>
          <Icon icon={Block} fill="red" solid /> Er kan geen installatie gegenereerd worden vanwege
          de antwoorden op de volgende vragen:
        </Bold>
        <ul>
          {blockingData.map(text => (
            <li key={`blocking ${text}`}>{text}</li>
          ))}
        </ul>
      </div>
    )}
  </>
);

type Props = {
  setShow: (s: boolean) => void;
  houseId: string;
  solution: Solution;
  mobile: boolean;
  showInstallatronLink?: boolean;
  closeOnGenerationCompletion?: boolean;
};

const RegenerateInstallationModal: React.FC<Props> = ({
  setShow,
  houseId,
  solution,
  mobile,
  showInstallatronLink,
  closeOnGenerationCompletion,
}) => {
  const toast = useToaster();
  const [confirmation, setConfirmation] = useState(false);

  const [
    generateInstallation,
    { loading: generateLoading, data: generatedInstallation },
  ] = useMutation<generateInstallationMutation, generateInstallationVariables>(
    GENERATE_INSTALLATION,
    {
      variables: {
        houseId,
        solution,
      },
      onError: e => {
        const parsedError = parseInstallationGenerationError(e.graphQLErrors[0].message);
        toast({
          type: 'error',
          message: parsedError.message,
        });
      },
      update: (cache, { data }) => {
        if (data) {
          cache.writeQuery({
            query: INSTALLATION_BY_HOUSE_SOLUTION,
            variables: {
              houseId,
              solution,
            },
            data: {
              installationByHouseSolution: {
                ...data.generateInstallation,
              },
            },
          });
          if (closeOnGenerationCompletion) {
            setShow(false);
          }
        } else console.error('NO DATA FROM MUTATION generateInstallation');
      },
    },
  );

  const { data: intakeData, loading: intakeLoading, error: intakeError } = useQuery<
    intakeOverview,
    intakeOverviewVariables
  >(INTAKE_OVERVIEW, {
    variables: {
      houseId,
    },
    fetchPolicy: 'network-only',
  });

  const missingData = intakeData ? generationConditions?.missingAnswers(solution, intakeData) : [];
  const blockingData = intakeData
    ? generationConditions?.blockingAnswers(solution, intakeData)
    : [];

  const { data: existingInstallation } = useQuery<t_installationQuery, t_installationVariables>(
    INSTALLATION_BY_HOUSE_SOLUTION,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        houseId,
        solution,
      },
    },
  );
  return (
    <Modal
      isOpen={true}
      mobile={mobile}
      size="md"
      onRequestClose={() => setShow(false)}
      title="Installatie opnieuw genereren"
      buttons={[
        // Show link to installatron when generation is complete
        ...(showInstallatronLink && Boolean(generatedInstallation?.generateInstallation)
          ? [
              {
                label: 'Ga naar Installatron',
                to: `/house/${houseId}/installatron/${getDomainFromSolution(
                  solution,
                )}?solution=${solution}`,
                bgColor: 'orange',
                iconStart: Wrenches,
              },
            ]
          : []),
        {
          label: 'Opnieuw genereren',
          onClick: generateInstallation as () => void,
          disabled: generateLoading || !confirmation,
          loading: generateLoading,
        },
      ]}
    >
      {intakeLoading ? (
        <>
          <Flex flexDirection="column" alignItems="center">
            <Box>
              <Spinner />
            </Box>
            <Box>Data laden...</Box>
          </Flex>
        </>
      ) : intakeError ? (
        <Placeholder error={intakeError} />
      ) : !intakeData ? (
        <p>Geen opname data!</p>
      ) : (
        <>
          <MissingInstallationGenerationData
            missingData={missingData}
            blockingData={blockingData}
          />
          <Box my={3}>
            <p>
              Als je de installatie opnieuw genereert zal de huidige versie{' '}
              <Bold>permanent verloren</Bold> gaan.
            </p>
            <p>Weet je zeker dat je deze installatie opnieuw wilt genereren?</p>
            <RadioGroup onChange={() => setConfirmation(!confirmation)} divider={5}>
              {[
                <Checkbox
                  key={'yes Im sure'}
                  checked={confirmation}
                  label={'Ja'}
                  id={'confirmation'}
                  name={'confirmation'}
                  value={'confirmation'}
                  bgColor="orange"
                />,
              ]}
            </RadioGroup>
          </Box>
        </>
      )}

      {/* Show the existing installation */}
      {!(generateLoading || generatedInstallation) &&
        (existingInstallation?.installationByHouseSolution ? (
          <>
            <h5>
              <Icon icon={Clock} fill="orange" solid /> Huidige installatie
            </h5>

            <InstallationItemBreakdown
              installation={existingInstallation.installationByHouseSolution}
              houseId={houseId}
              solution={solution}
              solutionDomain={getDomainFromSolution(solution)}
              readOnly
              mobile={false}
            />
          </>
        ) : (
          <h5>Huidige installatie: Nog niet aangemaakt</h5>
        ))}

      {/* Show the generated installation */}
      {generatedInstallation?.generateInstallation && (
        // The `mobile` Table makes it all unreadable, so added a horizontal scroll wrapper
        <>
          <h5>
            <Icon icon={Star} fill="green" solid /> Gegenereerde installatie
          </h5>
          <InstallationItemBreakdown
            installation={generatedInstallation?.generateInstallation}
            houseId={houseId}
            solution={solution}
            solutionDomain={getDomainFromSolution(solution)}
            mobile={false}
          />
        </>
      )}
    </Modal>
  );
};

export default RegenerateInstallationModal;
