import { gql, useQuery } from '@apollo/client';
import { Box, Flex } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  getCustomerAndHouse,
  getCustomerAndHouseVariables,
} from '../../types/generated/getCustomerAndHouse';
import {
  getOrdersWithVriendenkortingCode,
  getOrdersWithVriendenkortingCodeVariables,
} from '../../types/generated/getOrdersWithVriendenkortingCode';
import { operatorById_operatorById } from '../../types/generated/operatorById';
import { generateOperatorVriendenkortingCode } from '../coupons/utils';
interface Props {
  operator: operatorById_operatorById;
}

const GET_ORDERS_WITH_VRIENDENKORTING_CODE = gql`
  query getOrdersWithVriendenkortingCode($voucherCode: String!) {
    ordersWithVoucherCode(voucherCode: $voucherCode) {
      id
      discount
      voucherId
      voucherCode
      pipedriveDealId
      orderLines {
        houseId
      }
    }
  }
`;

const GET_CUSTOMER_AND_HOUSE = gql`
  query getCustomerAndHouse($id: ID!) {
    house(id: $id) {
      id
      customer {
        id
        firstName
        lastName
      }
      address {
        zip
        number
        suffix
        city
        street
        municipality
      }
    }
  }
`;

interface OrderInfoProps {
  houseId: string;
}

const OrderInfo: React.FC<OrderInfoProps> = ({ houseId }) => {
  const { data: customerAndHouseData } = useQuery<
    getCustomerAndHouse,
    getCustomerAndHouseVariables
  >(GET_CUSTOMER_AND_HOUSE, {
    variables: { id: houseId },
  });

  const customer = customerAndHouseData?.house.customer;
  const address = customerAndHouseData?.house.address;
  const customerName = `${customer?.firstName} ${customer?.lastName}`;

  return (
    <Flex flexDirection="column">
      <Box>{customerName}</Box>
      <Link to={`/house/${customerAndHouseData?.house.id}`}>
        <Box mb={2}>
          <Medium>
            {address?.street} {address?.number}, {address?.zip} {address?.municipality}
          </Medium>
        </Box>
      </Link>
    </Flex>
  );
};

const OrdersWithVriendenkortingCode: React.FC<Props> = ({ operator }) => {
  const { data: ordersWithVoucherData } = useQuery<
    getOrdersWithVriendenkortingCode,
    getOrdersWithVriendenkortingCodeVariables
  >(GET_ORDERS_WITH_VRIENDENKORTING_CODE, {
    variables: { voucherCode: generateOperatorVriendenkortingCode(operator) },
  });

  const orders = ordersWithVoucherData?.ordersWithVoucherCode || [];

  return (
    <>
      {orders.length > 0
        ? orders.map(o => <OrderInfo key={o.id} houseId={o.orderLines[0].houseId!} />)
        : 'Nog geen aanvragen gedaan'}
    </>
  );
};

export default OrdersWithVriendenkortingCode;
