import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { themify } from '../../styles/mixins';

interface StyledSideNavProps {
  $offsetTop: string;
  $side: 'left' | 'right';
  $width: string;
  $color: string;
  $bgColor: string;
  $borderColor: string;
  $mobile: boolean;
  $translateX: string;
}

export const StyledSideNav = styled.div<StyledSideNavProps>`
  position: fixed;
  top: ${x => x.$offsetTop};
  left: ${x => (x.$side === 'left' ? 0 : 'auto')};
  right: ${x => (x.$side === 'right' ? 0 : 'auto')};
  width: ${x => x.$width};
  min-height: ${x => `calc(100vh - ${x.$offsetTop})`};
  height: ${x => `calc(100% - ${x.$offsetTop})`};
  overflow-y: auto;
  color: ${x => x.$color};
  border-right: ${x =>
    !x.$mobile && x.$borderColor && x.$side === 'left' ? `1px solid ${x.$borderColor}` : 'none'};
  border-left: ${x =>
    !x.$mobile && x.$borderColor && x.$side === 'right' ? `1px solid ${x.$borderColor}` : 'none'};
  background: ${x => x.$bgColor};
  transform: ${x => `translateX(${x.$translateX})`};
  transition: all 0.2s ${x => x.theme.curves.standard};
  z-index: ${x => x.theme.z.maskedNav};

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface SideNavProps {
  bgColor?: string;
  borderColor?: string;
  children?: any;
  color?: string;
  'data-tour'?: string;
  itemHeight?: string;
  offsetTop?: string;
  side: 'left' | 'right';
  style?: CSSProperties;
  translateX?: string;
  width: string;
}

const SideNav: React.FC<SideNavProps> = ({
  'data-tour': tourStepName,
  bgColor = 'grayBlack',
  borderColor,
  children,
  color = 'white',
  offsetTop = '0px',
  side = 'left',
  style,
  translateX = '0',
  width = 'auto',
}) => {
  const mobile = useIsMobile();
  // TODO: Passing data down to children like this is hacky and needs loads of ts-ignores. Should use a context or something
  return (
    <StyledSideNav
      $bgColor={themify(bgColor)}
      $borderColor={themify(borderColor)}
      $color={themify(color)}
      data-tour={tourStepName}
      $offsetTop={offsetTop}
      role="navigation"
      $mobile={mobile}
      $side={side}
      style={style}
      $translateX={translateX}
      $width={width}
    >
      {children}
    </StyledSideNav>
  );
};

export default SideNav;
