import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Accordion,
  Box,
  Card,
  Counter,
  Flex,
  Input,
  Range,
  Separator,
  Switch,
} from '@energiebespaarders/symbols';
import { Center, Small, Right, Medium } from '@energiebespaarders/symbols/helpers';
import { delimit } from '../../lib/utils';

import { SolarPanel } from '@energiebespaarders/symbols/icons/solid';

const PvCalculator: React.FC<{ isNarrow: boolean }> = ({ isNarrow }) => {
  const [amountPanels, setAmountPanels] = useState(12);
  const [desiredAmountPanels, setDesiredAmountPanels] = useState(12);
  const [desiredPanelWp, setDesiredPanelWp] = useState(300);
  const [kWhPerYear, setKWhPerYear] = useState('');
  const [maxAmountPanels] = useState(40);
  const [panelWp, setPanelWp] = useState(300);
  const [useOptimizers, setUseOptimizers] = useState(false);
  const [yearlyYield, setYearlyYield] = useState(0);

  const handleToggle = () => {
    setUseOptimizers(!useOptimizers);
  };

  useEffect(() => {
    setYearlyYield(
      Number(kWhPerYear) *
        (panelWp / desiredPanelWp) *
        (amountPanels / desiredAmountPanels) *
        (useOptimizers ? 1.05 : 1),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useOptimizers, amountPanels, desiredAmountPanels, kWhPerYear, panelWp]);

  const optimizerSwitch = (
    <Box width={isNarrow ? 1 : 1 / 3} py={isNarrow ? 2 : 0}>
      <Center block>
        <Small>Optimizers</Small>
      </Center>
      <Switch
        bgColorOff="red"
        bgColorOn="green"
        isOn={useOptimizers}
        offLabel="Nee"
        onLabel="Ja"
        toggleSwitch={handleToggle}
      />
    </Box>
  );
  return (
    <Card mt={4} p={0} style={{ overflowY: 'hidden' }}>
      <Accordion
        baseBgColor="white"
        bgColor="grayLighter"
        contentBorderColor="grayLight"
        defaultOpen
        icon={SolarPanel}
        iconColor="green"
        title="Opbrengstcalculator"
        titleSize={5}
      >
        <Flex flexWrap="wrap" mx={-1} px={1}>
          <Box width={isNarrow ? 1 / 2 : 1 / 3} px={1}>
            <Right block>
              <Input
                type="number"
                name="originalAmount"
                label={'Oorspronkelijk aantal panelen'}
                value={desiredAmountPanels}
                onChange={e => setDesiredAmountPanels(Number(e.target.value))}
              />
            </Right>
          </Box>
          <Box width={isNarrow ? 1 / 2 : 1 / 3} px={1}>
            <Right block>
              <Input
                addonContent="Wp"
                addonSide="end"
                name="originalPower"
                label={'Oorspronkelijk Wp per paneel'}
                type="number"
                value={desiredPanelWp}
                onChange={e => setDesiredPanelWp(Number(e.target.value))}
              />
            </Right>
          </Box>
          <Box width={isNarrow ? 1 / 2 : 1 / 3} px={1}>
            <Right block>
              <Input
                type="number"
                addonContent="kWh / jaar"
                addonSide="end"
                name="originalYield"
                placeholder="0"
                label={'Oorspronkelijk berekende opbrengst'}
                value={kWhPerYear}
                onChange={e => setKWhPerYear(e.target.value)}
              />
            </Right>
          </Box>
        </Flex>
        <Flex flexWrap="wrap" alignItems="flex-start" px={2}>
          <Box width={1}>
            <Separator text="Nieuw systeem" fontSize={6} my={2} />
          </Box>
          <Box width={isNarrow ? 1 : 1 / 3} pb={isNarrow ? 0 : 2}>
            <Center block>
              <Medium>{amountPanels}</Medium>
              <Small> {amountPanels === 1 ? 'paneel' : 'panelen'}</Small>
              <br />
              <Range
                step={1}
                min={1}
                max={maxAmountPanels}
                value={amountPanels}
                showValue={false}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setAmountPanels(Number(e.target.value))
                }
              />
            </Center>
          </Box>
          {!isNarrow && optimizerSwitch}
          <Box width={isNarrow ? 1 : 1 / 3} pb={isNarrow ? 0 : 2}>
            <Center block>
              <Medium>{panelWp}Wp</Medium> <Small>per paneel</Small>
              <br />
              <Range
                step={5}
                min={275}
                max={375}
                value={panelWp}
                showValue={false}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPanelWp(Number(e.target.value))}
              />
            </Center>
          </Box>
          {isNarrow && optimizerSwitch}
          <Box width={1 / 2} py={isNarrow ? 1 : 2}>
            <Center block>
              <Small>Systeemvermogen</Small>
              <br />
              <Medium>
                <Counter
                  value={panelWp * amountPanels}
                  formatValue={(n: number) => delimit(n, 0)}
                  duration={300}
                />{' '}
                Wp
              </Medium>
            </Center>
          </Box>
          <Box width={1 / 2} py={isNarrow ? 1 : 2}>
            <Center block>
              <Small>Opbrengst</Small>
              <br />
              <Medium>
                <Counter
                  value={yearlyYield}
                  formatValue={(n: number) => delimit(n, 0)}
                  duration={300}
                />{' '}
                kWh / jaar
              </Medium>
            </Center>
          </Box>
        </Flex>
      </Accordion>
    </Card>
  );
};

export default PvCalculator;
