import { Box, Flex, Icon } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import { ThumbsDown, ThumbsUp } from '@energiebespaarders/symbols/icons/solid';
import React from 'react';

interface ThumbsProps {
  status: number | null;
  onChange?: (v: number) => void;
  label: string;
  readonly?: boolean;
  mb?: number;
}

const Thumbs: React.FC<ThumbsProps> = ({ status, onChange, label, readonly, mb = 2 }) => (
  <Flex flexWrap="wrap" alignItems="center" mx={-1} mb={mb}>
    <Box width={1 / 2} pl={1}>
      <Medium>{label}</Medium>
    </Box>

    <Box width={[1 / 4, 1 / 4, 1 / 6, 1 / 8]} px={1}>
      <Icon
        icon={ThumbsDown}
        width="2em"
        solid
        fill={status === -1 ? 'red' : 'gray'}
        hoverColor={readonly || status === -1 ? undefined : 'redLight'}
        onClick={onChange && !readonly ? () => onChange(status === -1 ? 0 : -1) : undefined}
        mr={2}
      />
    </Box>

    <Box width={[1 / 4, 1 / 4, 1 / 6, 1 / 8]} pr={1}>
      <Icon
        icon={ThumbsUp}
        solid
        width="2em"
        fill={status === 1 ? 'green' : 'gray'}
        hoverColor={readonly || status === 1 ? undefined : 'greenLight'}
        onClick={onChange && !readonly ? () => onChange(status === 1 ? 0 : 1) : undefined}
      />
    </Box>
  </Flex>
);

export default Thumbs;
