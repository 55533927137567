import { useMutation, useQuery } from '@apollo/client';
import { Solution as ConstantsSolution } from '@energiebespaarders/constants';
import { Button, Spinner } from '@energiebespaarders/symbols';
import { Add, List, MagicWand } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import useToaster from '../../../hooks/useToaster';
import { HOUSE_ADDRESS } from '../../../queries/house';
import {
  CREATE_INSTALLATION,
  GENERATE_INSTALLATION,
  INSTALLATION_BY_HOUSE_SOLUTION,
} from '../../../queries/installatron';
import {
  createInstallation as createInstallationMutation,
  createInstallationVariables,
} from '../../../types/generated/createInstallation';
import {
  generateInstallation as generateInstallationMutation,
  generateInstallationVariables,
} from '../../../types/generated/generateInstallation';
import { houseAddress, houseAddressVariables } from '../../../types/generated/houseAddress';
import {
  installationByHouseSolution,
  installationByHouseSolutionVariables,
} from '../../../types/generated/installationByHouseSolution';
import { Solution } from '../../../types/graphql-global-types';
import TemplatePicker from '../QuoteTemplate/TemplatePicker';
import { generatableSolutions, parseInstallationGenerationError } from '../utils';
import { quoteBuilderSolutions } from '../../../domains/TaskFlow/flows/CreateQuotes/constants';
import { FeatureFlag } from '../../../config';
import { useFlag } from 'react-unleash-flags';

export const useCreateInstallation = (houseId: string, solution: Solution) => {
  const toast = useToaster();

  return useMutation<createInstallationMutation, createInstallationVariables>(CREATE_INSTALLATION, {
    variables: {
      houseId,
      solution,
    },
    onError: e => {
      toast({
        type: 'error',
        message: e.message,
      });
    },
    update: (cache, { data }) => {
      if (data) {
        cache.writeQuery({
          query: INSTALLATION_BY_HOUSE_SOLUTION,
          variables: {
            houseId,
            solution,
          },
          data: {
            installationByHouseSolution: {
              ...data.createInstallation,
            },
          },
        });
      } else console.error('NO DATA FROM MUTATION createInstallation');
    },
  });
};

export const useGenerateInstallation = (
  houseId: string,
  solution: ConstantsSolution,
  createInstallation: () => void,
) => {
  const toast = useToaster();

  return useMutation<generateInstallationMutation, generateInstallationVariables>(
    GENERATE_INSTALLATION,
    {
      variables: {
        houseId,
        solution,
      },
      onError: e => {
        const parsedError = parseInstallationGenerationError(e.graphQLErrors[0].message);
        toast({
          type: 'error',
          message: parsedError.message,
        });
        createInstallation();
      },
      update: (cache, { data }) => {
        if (data) {
          cache.writeQuery<installationByHouseSolution, installationByHouseSolutionVariables>({
            query: INSTALLATION_BY_HOUSE_SOLUTION,
            variables: {
              houseId,
              solution,
            },
            data: {
              installationByHouseSolution: {
                ...data.generateInstallation!,
              },
            },
          });
        } else console.error('NO DATA FROM MUTATION generateInstallation');
      },
    },
  );
};

interface CreateInstallationProps {
  houseId: string;
  solution: ConstantsSolution;
}

const CreateInstallation: React.FC<CreateInstallationProps> = ({ houseId, solution }) => {
  const [templatePickerOpen, setTemplatePickerOpen] = useState(false);
  const quoteBuilderFlag = useFlag(FeatureFlag.QuoteBuilder);

  const [createInstallation, { loading: createLoading }] = useCreateInstallation(
    houseId,
    solution as Solution,
  );
  const [generateInstallation, { loading: generateLoading }] = useGenerateInstallation(
    houseId,
    solution,
    createInstallation,
  );

  const { data: houseData } = useQuery<houseAddress, houseAddressVariables>(HOUSE_ADDRESS, {
    variables: { houseId },
  });
  const address = houseData?.house.address;

  if (createLoading || generateLoading || !address) return <Spinner />;

  return (
    <>
      <Button
        fontSize={6}
        iconStart={Add}
        label="Maak een installatie"
        onClick={() =>
          generatableSolutions.includes(solution) ? generateInstallation() : createInstallation()
        }
        loading={createLoading || generateLoading}
        m={4}
        mb={4}
      />
      <Button
        inverse
        onClick={() => setTemplatePickerOpen(true)}
        label="Templates"
        iconStart={List}
        fontSize={6}
        bgColor="blue"
        m={4}
        mb={4}
        ml={2}
      />
      {templatePickerOpen && (
        <TemplatePicker
          isOpen={templatePickerOpen}
          solution={solution as Solution}
          closeModal={() => setTemplatePickerOpen(false)}
          address={address}
        />
      )}

      {quoteBuilderFlag?.enabled && quoteBuilderSolutions.includes(solution) && (
        <Button
          label="Offerte Wizard"
          iconStart={MagicWand}
          bgColor="purple"
          inverse
          to={`/taak/offertes-opstellen/${houseId}/${solution}`}
          fontSize={6}
          m={4}
          ml={2}
          mb={4}
        />
      )}
    </>
  );
};

export default CreateInstallation;
