type Breakpoints = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
};

export const breakpointsPx: Breakpoints = {
  xs: 0,
  sm: 425,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1920,
};

type Sizes = keyof Breakpoints;

const rootEm = 16;
export const breakpointsEm: { [x in Sizes]: string } = {
  xs: `${breakpointsPx.xs / rootEm}em`,
  sm: `${breakpointsPx.sm / rootEm}em`,
  md: `${breakpointsPx.md / rootEm}em`,
  lg: `${breakpointsPx.lg / rootEm}em`,
  xl: `${breakpointsPx.xl / rootEm}em`,
  xxl: `${breakpointsPx.xxl / rootEm}em`,
};

const customMediaQueryMin = (minWidth: number) => `@media (min-width: ${minWidth}px)`;
const customMediaQueryMax = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;

export const mediaMin = {
  custom: customMediaQueryMin,
  xs: customMediaQueryMin(breakpointsPx.xs),
  sm: customMediaQueryMin(breakpointsPx.sm),
  md: customMediaQueryMin(breakpointsPx.md),
  lg: customMediaQueryMin(breakpointsPx.lg),
  xl: customMediaQueryMin(breakpointsPx.xl),
  xxl: customMediaQueryMin(breakpointsPx.xxl),
};

export const mediaMax = {
  xs: customMediaQueryMax(breakpointsPx.xs - 1),
  sm: customMediaQueryMax(breakpointsPx.sm - 1),
  md: customMediaQueryMax(breakpointsPx.md - 1),
  lg: customMediaQueryMax(breakpointsPx.lg - 1),
  xl: customMediaQueryMax(breakpointsPx.xl - 1),
  xxl: customMediaQueryMax(breakpointsPx.xxl - 1),
};

export default mediaMin;
