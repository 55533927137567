import { Energiepaspoort } from '@energiebespaarders/symbols/icons/custom';
import {
  CaretRightDouble,
  GameControllerAlt,
  HouseDoor,
  SendMail,
  MeasuringTape,
  TriangleMeasure,
  Receipt,
  Tag,
  User,
  Wrenches,
} from '@energiebespaarders/symbols/icons/solid';
import { rgba } from 'polished';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import { useNavHelpers } from '../../hooks/useNavHelpers';
import theme from '../../styles/theme';
import { SideNav } from './index';
import { ISideNavItem } from './Navigation';
import SideNavItem, { PositionedSideNavItem } from './SideNavItem';

interface SideNavToggleProps extends PositionedSideNavItem {
  $narrowScreen: boolean;
  $rotate: number;
}

const SideNavToggle = styled(SideNavItem)<SideNavToggleProps>`
  @media (min-height: ${x => `calc(2 * ${x.theme.fixedSizes.navHeight})`}) {
    position: sticky;
    top: auto;
    bottom: ${x => (x.$narrowScreen ? 'auto' : '0')};
    width: 100%;
    background-color: ${x => rgba(x.theme.colors.grayBlack, 0.75)};
  }

  & svg {
    transform: ${x => `rotate(${x.$rotate}deg)`};
    transition: transform 0.2s ${x => x.theme.curves.standard};
  }
`;

interface ILeftNavProps {
  narrowScreen: boolean;
}

const LeftNav: React.FC<ILeftNavProps> = ({ narrowScreen }) => {
  const { activeHouseId } = useActiveHouseId();
  const { setSearchActive, leftNavOpen, setLeftNavOpen, rightNavState } = useNavHelpers();
  const handleRouteChange = useCallback(() => {
    setLeftNavOpen?.(false);
    setSearchActive?.(false);
  }, [setLeftNavOpen, setSearchActive]);
  const leftSideNavItems: ISideNavItem[] = useMemo(() => {
    const common = {
      disabled: !activeHouseId,
      height: theme.fixedSizes.navHeight,
      side: 'left',
      onRouteChange: handleRouteChange,
      showLabel: leftNavOpen,
    };
    return [
      { label: 'Klant', to: `/house/${activeHouseId}/customer`, icon: User, ...common },
      { label: 'Woning', to: `/house/${activeHouseId}`, icon: HouseDoor, exact: true, ...common },
      { label: 'Energielabel', to: `/house/${activeHouseId}/labels`, icon: Tag, ...common },
      { label: 'Communicatie', to: `/house/${activeHouseId}/email`, icon: SendMail, ...common },
      { label: 'Aanvragen', to: `/house/${activeHouseId}/orders`, icon: Receipt, ...common },
      { label: 'Opname', to: `/house/${activeHouseId}/intake`, icon: MeasuringTape, ...common },
      {
        label: 'Installatron',
        to: `/house/${activeHouseId}/installatron`,
        icon: Wrenches,
        ...common,
      },
      {
        label: 'Energiepaspoort',
        to: `/house/${activeHouseId}/advice`,
        icon: Energiepaspoort,
        ...common,
      },
      { label: 'OBAMA', to: `/house/${activeHouseId}/obama`, icon: TriangleMeasure, ...common },
      {
        label: 'Controle',
        to: `/house/${activeHouseId}/controle`,
        icon: GameControllerAlt,
        ...common,
      },
    ];
  }, [activeHouseId, handleRouteChange, leftNavOpen]);
  return (
    <SideNav
      itemHeight={theme.fixedSizes.navHeight}
      offsetTop={theme.fixedSizes.navHeight}
      side="left"
      translateX={rightNavState.isOpen ? `-${theme.fixedSizes.rightNavOpened}` : '0'}
      width={
        leftNavOpen
          ? theme.fixedSizes.leftNavOpened
          : narrowScreen
          ? '0px'
          : theme.fixedSizes.leftNavCollapsed
      }
      data-tour="leftNav"
    >
      {leftSideNavItems.map(item => (
        <SideNavItem key={item.label} {...item} />
      ))}
      <SideNavToggle
        $narrowScreen={narrowScreen}
        $rotate={leftNavOpen ? 180 : 0}
        data-tour="leftNavToggle"
        icon={CaretRightDouble}
        onClick={() => setLeftNavOpen?.(!leftNavOpen)}
        side="left"
        to="#"
      />
    </SideNav>
  );
};

export default LeftNav;
