import { Solution, getDomainFromSolution } from '@energiebespaarders/constants';
import { Accordion, Box, Button, Card, Flex } from '@energiebespaarders/symbols';
import { Add, AddCircle } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import InstallableItemsTable, {
  EditingMutationItem,
  MutationItem,
  useInstallableItemsTableHelpers,
} from '../../../domains/TaskFlow/InstallableItemsTable';
import ItemConfiguratorLite from '../../../domains/TaskFlow/ItemConfiguratorLite';

interface TemplateBuilderProps {
  supplierId: string;
  installerName?: string;
  solution: Solution;
  items: ReadonlyArray<MutationItem>;
  setItems: (items: MutationItem[]) => void;
  isUnsaved?: boolean;
  isReadOnly?: boolean;
}

const TemplateBuilder: React.FC<TemplateBuilderProps> = ({
  supplierId,
  solution,
  items,
  setItems,
  isUnsaved,
  isReadOnly,
  installerName,
}) => {
  const [editingItem, setEditingItem] = useState<EditingMutationItem>();

  const {
    submitEditingItem,
    removeEditingItem,
    reorderItem,
    newProduct,
    setNewProduct,
    submitNewProduct,
    isNewProductAlreadyAdded,
    isAddingNewProduct,
    setIsAddingNewProduct,
  } = useInstallableItemsTableHelpers({ items, setItems, editingItem, setEditingItem });

  return (
    <>
      <InstallableItemsTable
        items={items}
        submitEditingItem={submitEditingItem}
        editingItem={editingItem}
        setEditingItem={setEditingItem}
        removeEditingItem={removeEditingItem}
        reorderItem={reorderItem}
        themeColor={isUnsaved ? 'grayDark' : 'purpleDarker'}
        supplierId={supplierId}
        solutionDomain={getDomainFromSolution(solution)}
        hidePrices
        isReadOnly={isReadOnly}
      />

      {!isReadOnly && (
        <Card
          shadow="none"
          animation="none"
          borderColor="grayLight"
          p={0}
          borderRadius="3px"
          mt={1}
        >
          <Accordion
            baseBgColor="greenSlate"
            contentBorderColor="grayLight"
            isOpened={isAddingNewProduct}
            icon={AddCircle}
            iconColor="green"
            title="Product toevoegen"
            unmountCollapsedContent
            onClick={() => setIsAddingNewProduct(!isAddingNewProduct)}
          >
            <Flex flexWrap="wrap" alignItems="flex-end" mx={-1}>
              <Box width={[1, 1, 2 / 3]} px={1}>
                <ItemConfiguratorLite
                  value={newProduct}
                  solutionDomain={getDomainFromSolution(solution)}
                  supplierId={supplierId}
                  onChange={setNewProduct}
                  installerName={installerName}
                />
              </Box>
              <Box width={[1, 1, 1 / 3]} px={1}>
                <Button
                  fluid
                  disabled={!newProduct || isNewProductAlreadyAdded}
                  onClick={submitNewProduct}
                  label="Product toevoegen"
                  error={isNewProductAlreadyAdded ? 'Al toegevoegd' : undefined}
                  iconStart={Add}
                />
              </Box>
            </Flex>
          </Accordion>
        </Card>
      )}
    </>
  );
};

export default TemplateBuilder;
