/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  getDomainFromSolution,
  SolutionDomain,
  SOLUTION_DOMAINS_NL,
} from '@energiebespaarders/constants';
import { Accordion, Button, Card, Icon, SpinnerOverlay } from '@energiebespaarders/symbols';
import {
  ClipboardCheck,
  ClipboardContent,
  ClipboardPencil,
  Pattern,
  Rotate,
} from '@energiebespaarders/symbols/icons/solid';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EnergyDeltaFragment, SituationEnergyPricesFragment } from '../../fragments';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import useToaster from '../../hooks/useToaster';
import { ADVICE_OVERVIEW } from '../../queries/advice';
import { margin, padding, pos } from '../../styles/mixins';
import { adviceComposer, adviceComposerVariables } from '../../types/generated/adviceComposer';
import {
  generateAdviceTexts,
  generateAdviceTextsVariables,
  generateAdviceTexts_generateAdviceTexts,
} from '../../types/generated/generateAdviceTexts';
import { generateAdviceFromSolutionDomain } from './adviceTextGenerateUtils';
import GeneratedAdviceTexts, { copyAllTextsToClipboard } from './GeneratedAdviceTexts';

export const Paragraph = styled.div`
  ${margin(2, 'bottom')};
  ${padding(2)};
  background: white;
  border-radius: 3px;
  border: 1px solid ${x => x.theme.colors.grayLight};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ComposedAdvice = styled.div`
  ${margin(2, 'top')};
  ${margin(4, 'left')};
  position: relative;
  transition: transform 0.1s ${x => x.theme.curves.sharp};
  transform: scale(0.99);
  perspective: 999px;
  cursor: pointer;

  &:hover {
    transform: scale(1);
  }

  &:active {
    transform: scale(0.99);
  }
`;

export const QuoteMark = styled(Icon)`
  position: absolute;
  top: 0;
  ${pos('l', -4)};
`;

export const ADVICE_COMPOSER = gql`
  ${EnergyDeltaFragment}
  ${SituationEnergyPricesFragment}
  query adviceComposer($houseId: ID!) {
    house(id: $houseId) {
      id
      type
      energy {
        electricityConsumption
      }
      advice {
        id
        isRequested
        solutionDomainOrder
      }
      intake {
        id
        type
        startedOn
        operator {
          id
          firstName
          isFreelancer
        }
        walls {
          insulatableArea
        }
        roof {
          type
          insulatableInnerRoofArea
          insulatableAtticFloorArea
        }
        floor {
          types
          insulatableArea
        }
        crawlspace {
          height
        }
        heating {
          boilerAge
        }
        pvSystemPower
      }
      situation {
        id
        energyPrices {
          ...SituationEnergyPrices
        }
        consumption {
          gas
          electricity
        }
        walls {
          reasonNotAdvised
        }
        floor {
          reasonNotAdvised
        }
        roof {
          reasonNotAdvised
        }
        windows {
          areaRequiringReplacementHr
          areaRequiringReplacementSafety
          reasonNotAdvised
        }
        heating {
          reasonNotAdvised
        }
        solar {
          reasonNotAdvised
          currentSystem {
            panels
          }
        }
      }
      quotes {
        id
        isAdvised
        solution
        investment
        paybackTime
        items {
          amount
          retailPrice
          purchasePrice
          supplierId
          product {
            id
            title
            category
            ... on CompletePvSystem {
              panelAmount
              pvPanel {
                id
                brand {
                  id
                  name
                }
                pMax
                cellType
              }
              inverter {
                id
                brand {
                  id
                  name
                }
              }
              optimizer {
                id
                brand {
                  id
                  name
                }
              }
            }
            ... on CentralHeatingBoiler {
              brand {
                id
                name
              }
              cwClass
            }
            ... on HeatPump {
              subsidy
            }
            ... on PvPanel {
              pMax
              cellType
              brand {
                id
                name
              }
            }
            ... on Inverter {
              brand {
                id
                name
              }
            }
            ... on Optimizer {
              brand {
                id
                name
              }
            }
          }
        }
        energyDelta {
          ...EnergyDelta
        }
        comfortScore
        pvSystemInfo {
          cardinalDirection
          multipleRoofSurfaces
          installationPlanProperties
          neighbourDiscount
        }
      }
      customer {
        id
        motivation
      }
    }
  }
`;

export const GENERATE_ADVICE_TEXTS = gql`
  mutation generateAdviceTexts(
    $id: ID!
    $solution: String!
    $cavitySavings: CavityAdviceTextSavingsInput
    $pvSystemInfo: PvSystemInfoInput
    $floorSavings: FloorAdviceTextSavingsInput
    $roofSavings: RoofAdviceTextSavingsInput
    $windowsSavings: WindowsAdviceTextSavingsInput
    $heatingSavings: HeatingAdviceTextSavingsInput
  ) {
    generateAdviceTexts(
      id: $id
      solution: $solution
      cavitySavings: $cavitySavings
      pvSystemInfo: $pvSystemInfo
      floorSavings: $floorSavings
      roofSavings: $roofSavings
      windowsSavings: $windowsSavings
      heatingSavings: $heatingSavings
    ) {
      key
      value {
        partTitle
        partTextTitle
        ordering
        multiple
        required
        adviceTexts {
          key
          value {
            key
            text
            title
            met
          }
        }
      }
    }
  }
`;

interface AutomaticAdviceComposerProps {
  houseId: string;
  solutionDomain: SolutionDomain;
}

export const AutomaticAdviceComposer: React.FC<AutomaticAdviceComposerProps> = ({
  houseId,
  solutionDomain,
}) => {
  const mobile = useIsMobile();
  const toast = useToaster();

  const [sortedParts, setSortedParts] = useState<generateAdviceTexts_generateAdviceTexts[]>([]);
  const [textsError, setTextsError] = useState<ApolloError | undefined>();
  const [editingTexts, setEditingTexts] = useState(false);

  const [getAdviceOverview, { loading: loadingOverview }] = useLazyQuery(ADVICE_OVERVIEW, {
    variables: { houseId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const { data, loading: adviceComposerLoading, error } = useQuery<
    adviceComposer,
    adviceComposerVariables
  >(ADVICE_COMPOSER, {
    variables: { houseId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [generateTexts, { loading: textsLoading }] = useMutation<
    generateAdviceTexts,
    generateAdviceTextsVariables
  >(GENERATE_ADVICE_TEXTS, {
    onCompleted: data => {
      const sortedParts = [...data.generateAdviceTexts].sort((a, b) => {
        if (a.value.ordering < b.value.ordering) return -1;
        if (a.value.ordering > b.value.ordering) return 1;
        return 0;
      });
      // We spread here to prevent a weird flicker when changing a part for the first time
      setSortedParts(sortedParts);
    },
    onError: error => {
      setTextsError(error);
    },
  });

  useEffect(() => {
    setSortedParts([]);
    setTextsError(undefined);
  }, [solutionDomain]);

  if (error) console.error(error);
  const house = data?.house;
  const advisedQuotes = (house?.quotes || []).filter(
    quote => getDomainFromSolution(quote.solution) === solutionDomain && quote.isAdvised,
  );
  // TODO: Shouldn't the user be able to select which quote to use in the texts? Modified it from first to last quote for now
  const advisedQuote = advisedQuotes.length > 0 ? advisedQuotes[advisedQuotes.length - 1] : null;

  const handleGenerateText = (variables: generateAdviceTextsVariables) => {
    generateTexts({
      variables,
    }).then(() => getAdviceOverview());
  };

  const handleClickGenerate = () => {
    if (!adviceComposerLoading) {
      generateAdviceFromSolutionDomain(advisedQuote, house, solutionDomain, handleGenerateText);
    }
  };

  const handleClickCopyAllTexts = () => {
    copyAllTextsToClipboard(sortedParts);
    toast({
      type: 'info',
      message: 'Tekst gekopieerd',
    });
  };

  const toggleEditingTexts = () => setEditingTexts(!editingTexts);

  return (
    <Card p={0} mb={4} zIndex={100} style={{ overflowY: 'hidden' }}>
      {loadingOverview && <SpinnerOverlay />}
      <Accordion
        baseBgColor="white"
        bgColor="grayLighter"
        contentBorderColor="grayLight"
        contentPadding={2}
        defaultOpen
        icon={Pattern}
        iconColor="green"
        title="Advies samenstellen"
        titleSize={5}
        zIndex={110}
      >
        {!editingTexts ? (
          <>
            <Button
              bgColor="green"
              error={textsError?.message}
              loading={adviceComposerLoading || textsLoading}
              iconStart={Rotate}
              label={`${SOLUTION_DOMAINS_NL[solutionDomain]} teksten genereren`}
              onClick={handleClickGenerate}
            />
            <Button
              bgColor="green"
              disabled={!sortedParts || sortedParts.length === 0}
              iconStart={ClipboardContent}
              label="Kopieren"
              onClick={handleClickCopyAllTexts}
            />
            <Button
              bgColor="goldDark"
              disabled={!sortedParts || sortedParts.length === 0}
              iconStart={ClipboardPencil}
              label="Bewerken"
              onClick={toggleEditingTexts}
            />
          </>
        ) : (
          <Button
            bgColor="green"
            iconStart={ClipboardCheck}
            label="Klaar met bewerken"
            onClick={toggleEditingTexts}
          />
        )}

        <GeneratedAdviceTexts
          sortedParts={sortedParts}
          setSortedParts={setSortedParts}
          adviceComposerLoading={adviceComposerLoading}
          editingTexts={editingTexts}
        />
      </Accordion>
    </Card>
  );
};
