import { useMutation } from '@apollo/client';
import useForm, { isMissing } from '@energiebespaarders/hooks/useForm';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Input,
  Rating,
  Textarea,
} from '@energiebespaarders/symbols';
import { Medium, Red, Smaller } from '@energiebespaarders/symbols/helpers';
import { Calendar } from '@energiebespaarders/symbols/icons/solid';
import React, { ChangeEvent, useMemo } from 'react';
import { GET_RATED_JOBS } from '../../../queries/job';
import { ADD_RATING } from '../../../queries/rating';
import {
  addRating as addRatingMutation,
  addRatingVariables,
} from '../../../types/generated/addRating';
import { unfinishedJobs_unfinishedJobs } from '../../../types/generated/unfinishedJobs';
import { JobType } from '../../../types/graphql-global-types';
import InvoiceExpectationsEmailCheckbox, {
  useInvoiceExpectationsEmailData,
} from './InvoiceExpectationsEmailCheckbox';
import Thumbs from './Thumbs';

type RatingForm = {
  sent?: string;
  plannedOn?: string;
  plannedFor?: string;
  installationDoneOn: string;
  work: number;
  communication: number;
  extraWork: number;
  cleanliness: number;
  punctuality: number;
  friendliness: number;
  professionalism: number;
  addFollowUp: boolean;
  serviceRating: number;
  sendInvoiceEmail: boolean;

  notesOnDEB: string;
  notesOnInstaller: string;
};

interface AddRatingFormProps {
  job: unfinishedJobs_unfinishedJobs;
  onCompleted?: () => void;
  onClose?: () => void;
  updateCache: (...args: any) => any;
}

const AddRatingForm: React.FC<AddRatingFormProps> = ({
  job,
  onCompleted,
  onClose,
  updateCache,
}) => {
  const ratingProps = {
    minRating: 0,
    maxRating: 5,
    starFillColor: 'orange',
    starStrokeColor: 'orange',
  };

  const [addRating, { loading, error }] = useMutation<addRatingMutation, addRatingVariables>(
    ADD_RATING,
    {
      update: (cache, res) => {
        if (res) {
          const { data } = res;
          if (data) {
            updateCache({
              cache: cache,
              data: data?.addRating,
              property: 'ratedJobs',
              query: GET_RATED_JOBS,
            });
          } else console.error('No data in addRating update func');
        } else console.error('No res in addRating update func');
      },
      onCompleted: () => onCompleted?.(),
    },
  );

  const { sendInvoiceEmailMutation, invoiceEmailType } = useInvoiceExpectationsEmailData(
    job.quote.id,
  );

  const { formState, handleChange, formHasErrors, submitForm, errors } = useForm<RatingForm>({
    blockEnterToSubmit: true,
    initialValues: {
      sent: '',
      plannedOn: '',
      plannedFor: '',
      installationDoneOn: '',
      work: 0,
      communication: 0,
      extraWork: 0,
      cleanliness: 0,
      punctuality: 0,
      friendliness: 0,
      professionalism: 0,
      addFollowUp: false,
      serviceRating: 0,
      sendInvoiceEmail: true,

      notesOnDEB: '',
      notesOnInstaller: '',
    },
    handleSubmit: ({
      installationDoneOn,
      sent,
      plannedFor,
      plannedOn,
      addFollowUp,
      sendInvoiceEmail,
      ...rest
    }) => {
      addRating({
        variables: {
          sent: sent || undefined,
          plannedFor: plannedFor || undefined,
          plannedOn: plannedOn || undefined,
          installationDoneOn,
          jobId: job.id,
          rating: rest,
          addFollowUp,
        },
      });

      if (sendInvoiceEmail) {
        sendInvoiceEmailMutation({
          variables: { jobFollowUpType: invoiceEmailType, jobId: job.id },
        });
      }
    },
    validate: (v, e) => {
      if (v.work === 0) e.work = 'Verplicht';
      if (v.communication === 0) e.communication = 'Verplicht';
      if (isMissing(v.installationDoneOn)) e.installationDoneOn = 'Verplicht';
      if (job.type === JobType.INSTALLATION && !job.isSent) {
        if (isMissing(v.sent)) e.sent = 'Verplicht';
        if (isMissing(v.plannedOn)) e.plannedOn = 'Verplicht';
        if (isMissing(v.plannedFor)) e.plannedFor = 'Verplicht';
        if (e.sent || e.plannedOn || e.plannedFor)
          e.general =
            'We hebben geen informatie over de planning van de installatie van deze opdracht. Ga naar boven om de data te invullen.';
      }
      return e;
    },
  });

  const {
    sent,
    plannedOn,
    plannedFor,
    work,
    communication,
    extraWork,
    cleanliness,
    punctuality,
    friendliness,
    professionalism,
    installationDoneOn,
    addFollowUp,
    serviceRating,

    notesOnDEB,
    notesOnInstaller,
  } = formState;

  const requiredValuesMissing = useMemo(() => !installationDoneOn || !work || !communication, [
    communication,
    installationDoneOn,
    work,
  ]);

  return (
    <>
      {job.type === JobType.INSTALLATION && job.isSent ? (
        <></>
      ) : (
        <>
          <Medium>
            LET OP! We hebben geen informatie over de planning van de installatie van deze opdracht
          </Medium>
          <p>Als je deze opdracht wil beoordelen, moet je eerst deze data invullen:</p>
          <Input
            type="date"
            label="Opdracht verzenddatum"
            error={sent?.error}
            value={sent?.value || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange({ sent: e.target.value })}
          />
          <Input
            type="date"
            label="Inplanningsdatum"
            error={plannedOn?.error}
            value={plannedOn?.value || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange({ plannedOn: e.target.value })
            }
          />
          <Input
            type="date"
            label="Geplande installatiedatum"
            error={plannedFor?.error}
            value={plannedFor?.value || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange({ plannedFor: e.target.value })
            }
          />
        </>
      )}
      <Card animation="none" bgColor="goldSlate" borderColor="orange" p={4} mb={2}>
        <Input
          type="date"
          addonSide="start"
          icon={Calendar}
          label="Installatiedatum"
          error={installationDoneOn.error}
          value={installationDoneOn.value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange({ installationDoneOn: e.target.value })
          }
        />
        {new Date(installationDoneOn.value) > new Date() && (
          <Red>Let op: Deze datum ligt in de toekomst.</Red>
        )}

        <Flex flexWrap="wrap" pt={4} alignItems="flex-end" style={{ lineHeight: 1 }}>
          <Box width={1 / 2} mb={4}>
            <Medium>Kwaliteit geleverd</Medium>
            {work.error && (
              <Red>
                <Smaller> {work.error}</Smaller>
              </Red>
            )}
          </Box>
          <Box width={1 / 2} mb={4}>
            <Rating
              rating={work.value}
              onRatingChange={(work: number) => handleChange({ work })}
              {...ratingProps}
            />
          </Box>
          <Box width={1 / 2}>
            <Medium>Communicatie</Medium>
            {communication.error && (
              <Red>
                <Smaller> {communication.error}</Smaller>
              </Red>
            )}
          </Box>
          <Box width={1 / 2}>
            <Rating
              rating={communication.value}
              onRatingChange={(rating: number) => handleChange({ communication: rating })}
              {...ratingProps}
            />
          </Box>
        </Flex>
      </Card>

      <Card animation="none" bgColor="white" borderColor="grayLight" p={4} mb={2}>
        <Thumbs
          label="Klantvriendelijk"
          status={friendliness.value}
          onChange={(friendliness: number) => handleChange({ friendliness })}
        />
        <Thumbs
          label="Netjes"
          status={cleanliness.value}
          onChange={(cleanliness: number) => handleChange({ cleanliness })}
        />
        <Thumbs
          label="Hoeveelheid werk"
          status={extraWork.value}
          onChange={(extraWork: number) => handleChange({ extraWork })}
        />
        <Thumbs
          label="Professioneel"
          status={professionalism.value}
          onChange={(professionalism: number) => handleChange({ professionalism })}
        />
        <Thumbs
          label="Punctueel"
          status={punctuality.value}
          onChange={(punctuality: number) => handleChange({ punctuality })}
          mb={0}
        />
      </Card>

      <Flex flexWrap="wrap" mb={2}>
        <Box width={1}>
          <Textarea
            label={`Is er nog iets wat ${job.installer.name} kan verbeteren?`}
            value={notesOnInstaller.value}
            onChange={e => handleChange({ notesOnInstaller: e.target.value })}
          />
        </Box>
      </Flex>
      <Checkbox
        onChange={() => handleChange({ addFollowUp: !addFollowUp.value })}
        id="Opvolging happy call nodig"
        label="Opvolging happy call nodig"
        checked={addFollowUp.value}
        value={true}
        width="100%"
      />

      <Flex flexWrap="wrap" pt={4} alignItems="flex-end" style={{ lineHeight: 1 }}>
        <Box width={1 / 2} mb={4}>
          <Medium>Dienstverlening Energiebespaarders</Medium>
          {serviceRating.error && (
            <Red>
              <Smaller> {serviceRating.error}</Smaller>
            </Red>
          )}
        </Box>
        <Box width={1 / 2} mb={4}>
          <Rating
            rating={serviceRating.value}
            onRatingChange={(serviceRating: number) => handleChange({ serviceRating })}
            {...ratingProps}
          />
        </Box>
      </Flex>

      <Flex flexWrap="wrap" mb={2}>
        <Box width={1}>
          <Textarea
            label="Is er nog iets wat De Energiebespaarders beter had kunnen doen?"
            value={notesOnDEB.value}
            onChange={e => handleChange({ notesOnDEB: e.target.value })}
          />
        </Box>
      </Flex>

      <Flex flexWrap="wrap" mb={2}>
        <Box width={1}>
          <InvoiceExpectationsEmailCheckbox
            invoiceEmailType={invoiceEmailType}
            handleChange={handleChange}
            value={formState.sendInvoiceEmail.value}
          />
        </Box>
      </Flex>

      <Flex mt={1} mx={-1}>
        {onClose && (
          <Box width="50%" px={1}>
            <Button fluid bgColor="red" label="Sluiten" onClick={onClose} />
          </Box>
        )}
        <Box width="100%" px={1}>
          <Button
            bgColor="green"
            label="Opdracht beoordelen"
            disabled={requiredValuesMissing || loading}
            onClick={submitForm as () => void}
            error={error}
            fluid
          />
          <Smaller>
            {errors.general ? (
              <Red>{errors.general}</Red>
            ) : formHasErrors ? (
              <Red>Is alles ingevuld?</Red>
            ) : (
              <></>
            )}
          </Smaller>
        </Box>
      </Flex>
    </>
  );
};

export default AddRatingForm;
