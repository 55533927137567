import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  INTAKE_TOPICS_NL,
  INTAKE_TOPIC_SOLUTIONS,
  IntakeTopic,
  SolutionDomain,
  getDomainFromSolution,
  getSolutionsFromDomain,
} from '@energiebespaarders/constants';
import { Modal, Spinner } from '@energiebespaarders/symbols';
import React from 'react';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import { INSTALLATION_BY_HOUSE_SOLUTION } from '../../queries/installatron';
import {
  installationByHouseSolution,
  installationByHouseSolutionVariables,
} from '../../types/generated/installationByHouseSolution';
import { intakeAdviser, intakeAdviserVariables } from '../../types/generated/intakeAdviser';
import { IntakeIncompletionForm } from '../intake/IncompletionQuestion';

const INTAKE_ADVISER = gql`
  query intakeAdviser($houseId: ID!) {
    intake: intakeByHouse(houseId: $houseId) {
      id
      operator {
        id
      }
    }
  }
`;

interface IntakeIncompletionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const IntakeIncompletionModal: React.FC<IntakeIncompletionModalProps> = ({ isOpen, onClose }) => {
  const { activeHouseId } = useActiveHouseId();
  const solutionDomain = window.location.pathname.split('/').pop();
  const topic = Object.entries(INTAKE_TOPIC_SOLUTIONS).find(
    ([, solutions]) => getDomainFromSolution(solutions[0]) === solutionDomain,
  )?.[0] as IntakeTopic;

  const { data, loading, error } = useQuery<intakeAdviser, intakeAdviserVariables>(INTAKE_ADVISER, {
    variables: { houseId: activeHouseId },
  });
  const [refetchInstallation] = useLazyQuery<
    installationByHouseSolution,
    installationByHouseSolutionVariables
  >(INSTALLATION_BY_HOUSE_SOLUTION, { fetchPolicy: 'network-only' });

  return (
    <Modal
      title={`Waarom kun je de opname voor ${
        INTAKE_TOPICS_NL[topic]?.toLowerCase() || 'dit onderdeel'
      } niet volledig invullen?`}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      {loading ? (
        <Spinner />
      ) : error || !data ? (
        <p>{error}</p>
      ) : (
        <IntakeIncompletionForm
          houseId={activeHouseId}
          intakeOperatorId={data.intake?.operator?.id || ''}
          topic={topic}
          onCompleted={() => {
            // Re-fetch installation to trigger new alert errors/warnings:
            // The intake incompletion error should be gone now!

            // TODO: Would be more efficient to get the current solution
            // but that one is stored in local state...
            getSolutionsFromDomain(solutionDomain as SolutionDomain).forEach(sol =>
              refetchInstallation({
                variables: {
                  houseId: activeHouseId,
                  solution: sol,
                },
              }),
            );
            onClose();
          }}
        />
      )}
    </Modal>
  );
};

export default IntakeIncompletionModal;
