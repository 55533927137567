import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { SOLUTION_DOMAINS_NL, getDomainFromSolution } from '@energiebespaarders/constants';
import { Box, Button, Flex, Textarea } from '@energiebespaarders/symbols';
import { Red, Small } from '@energiebespaarders/symbols/helpers';
import {
  ClipboardCheck,
  ClipboardContent,
  ClipboardPencil,
  Rotate,
} from '@energiebespaarders/symbols/icons/solid';
import { useState } from 'react';
import useToaster from '../../../../hooks/useToaster';
import { ADVICE_OVERVIEW } from '../../../../queries/advice';
import { CALCULATIONS_TABLE } from '../../../../queries/calculations';
import { UPDATE_QUOTE } from '../../../../queries/installatron';
import {
  adviceComposer,
  adviceComposerVariables,
} from '../../../../types/generated/adviceComposer';
import {
  generateAdviceTexts,
  generateAdviceTextsVariables,
  generateAdviceTexts_generateAdviceTexts,
} from '../../../../types/generated/generateAdviceTexts';
import { quoteModalQuote_quoteById } from '../../../../types/generated/quoteModalQuote';
import { updateQuote, updateQuoteVariables } from '../../../../types/generated/updateQuote';
import QuoteGallery from '../../../QuoteGallery';
import QuoteUploader from '../../../QuoteUploader';
import { ADVICE_COMPOSER, GENERATE_ADVICE_TEXTS } from '../../../advice/AutomaticAdviceComposer';
import GeneratedAdviceTexts, {
  copyAllTextsToClipboard,
} from '../../../advice/GeneratedAdviceTexts';
import { generateAdviceFromSolutionDomain } from '../../../advice/adviceTextGenerateUtils';
import CopyFileToQuoteModal from '../CopyFileToQuoteModal';

export type QuoteModalAdviceAdditionsProps = {
  houseId: string;
  quote: quoteModalQuote_quoteById;
};

export function QuoteModalAdviceAdditions({
  houseId,
  quote,
}: QuoteModalAdviceAdditionsProps): JSX.Element {
  const solutionDomain = getDomainFromSolution(quote.solution);

  const toast = useToaster();
  const [showAdviceGenerator, setShowAdviceGenerator] = useState(false);
  const [isEditingTexts, setIsEditingTexts] = useState(false);
  const [sortedParts, setSortedParts] = useState<generateAdviceTexts_generateAdviceTexts[]>([]);
  const [generateTextsError, setGenerateTextsError] = useState<ApolloError | undefined>();

  const [getAdviceOverview] = useLazyQuery(ADVICE_OVERVIEW, {
    variables: { houseId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const { data, loading, error } = useQuery<adviceComposer, adviceComposerVariables>(
    ADVICE_COMPOSER,
    {
      variables: { houseId },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );
  const house = data?.house;

  if (error) console.error(error);

  const [generateTexts, { loading: loadingGeneratedTexts }] = useMutation<
    generateAdviceTexts,
    generateAdviceTextsVariables
  >(GENERATE_ADVICE_TEXTS, {
    onCompleted: data => {
      const sortedParts = [...data.generateAdviceTexts].sort((a, b) => {
        if (a.value.ordering < b.value.ordering) return -1;
        if (a.value.ordering > b.value.ordering) return 1;
        return 0;
      });
      // We spread here to prevent a weird flicker when changing a part for the first time
      setSortedParts(sortedParts);
    },
    onError: error => {
      setGenerateTextsError(error);
    },
  });

  const [updateQuote] = useMutation<updateQuote, updateQuoteVariables>(UPDATE_QUOTE, {
    refetchQueries: [{ query: CALCULATIONS_TABLE, variables: { houseId } }],
  });

  const handleGenerateText = (variables: generateAdviceTextsVariables) => {
    generateTexts({
      variables,
    }).then(() => getAdviceOverview());
  };

  const handleClickGenerate = () => {
    setShowAdviceGenerator(true);
    generateAdviceFromSolutionDomain(quote, house, solutionDomain, handleGenerateText);
  };

  const handleClickCopyAllTexts = () => {
    copyAllTextsToClipboard(sortedParts, false);
    toast({
      type: 'info',
      message: 'Tekst gekopieerd',
    });
  };

  const handleCloseAdviceGenerator = () => {
    setShowAdviceGenerator(false);
    isEditingTexts && setIsEditingTexts(false);
  };

  const editingButtonProps = isEditingTexts
    ? {
        bgColor: 'goldDark',
        disabled: !sortedParts || sortedParts.length === 0,
        iconStart: ClipboardPencil,
        label: 'Bewerken',
      }
    : {
        bgColor: 'green',
        iconStart: ClipboardCheck,
        label: 'Klaar met bewerken',
      };

  return (
    <Flex flexWrap="wrap" mx={-1}>
      <Box width={[1]} px={1} mb={2}>
        <Flex>
          {showAdviceGenerator && sortedParts.length ? (
            <Box>
              <Button
                bgColor="green"
                disabled={!sortedParts || sortedParts.length === 0}
                iconStart={ClipboardContent}
                label="Kopiëren"
                onClick={handleClickCopyAllTexts}
              />
              <Button onClick={() => setIsEditingTexts(!isEditingTexts)} {...editingButtonProps} />
              <Button onClick={handleCloseAdviceGenerator} inverse bgColor="red">
                Sluiten
              </Button>
            </Box>
          ) : (
            <Button
              bgColor="green"
              error={generateTextsError?.message}
              loading={loading || loadingGeneratedTexts}
              iconStart={Rotate}
              label={`${SOLUTION_DOMAINS_NL[solutionDomain]} teksten genereren`}
              onClick={handleClickGenerate}
            />
          )}
        </Flex>

        <Flex flexDirection="column">
          <Textarea
            onBlur={(e: { target: { value: string } }) => {
              updateQuote({
                variables: {
                  id: quote.id,
                  quote: {
                    text: e.target.value,
                  },
                },
              });
            }}
            label="Persoonlijke tekst voor de klant over de offerte"
            labelSize={6}
            rows={5}
            placeholder="Schrijf een persoonlijke tekst voor de klant op de offerte"
            defaultValue={quote.text || ''}
          />
          {(quote.text?.includes('<<<') || quote.text?.includes('>>>')) && (
            <Red>
              <Small>
                <strong>Let op:</strong> Er staan nog placeholders in de tekst (tussen de{' '}
                <strong>{'<<< ... >>>'}</strong> tekens)
              </Small>
            </Red>
          )}
          {showAdviceGenerator || isEditingTexts ? (
            <GeneratedAdviceTexts
              separatorText="Auto-advies tekst"
              sortedParts={sortedParts}
              setSortedParts={setSortedParts}
              adviceComposerLoading={loading}
              editingTexts={isEditingTexts}
              formatAsMarkdownInCopy={false}
            />
          ) : (
            <></>
          )}
        </Flex>
      </Box>

      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 3]} px={1}>
          <QuoteUploader
            bgColor="orange"
            borderColor="gray"
            quoteId={quote.id}
            multiple={true}
            tags={['quote', quote.id, quote.solution]}
            text="Sleep of klik om een bestanden toe te voegen aan de offerte"
          />
        </Box>
        <Box width={[1, 1, 1 / 3]} px={1}>
          <CopyFileToQuoteModal
            houseId={houseId}
            quoteId={quote.id}
            tags={['quote', quote.id, quote.solution]}
          />
        </Box>
        <Box width={[1, 1, 1 / 3]} px={1}>
          <QuoteGallery quoteId={quote.id} tags={[quote.id]} />
        </Box>
      </Flex>
    </Flex>
  );
}
