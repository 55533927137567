import { useMutation } from '@apollo/client';
import { Solution, SolutionDomain } from '@energiebespaarders/constants';
import { Box, Flex, Modal, Radio, RadioGroup, Toast } from '@energiebespaarders/symbols';
import { Heading, Medium } from '@energiebespaarders/symbols/helpers';
import { ArrowLeft, ArrowRight, PageAdd, Reply } from '@energiebespaarders/symbols/icons/solid';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import React, { useEffect, useMemo, useState } from 'react';
import { useFlag } from 'react-unleash-flags';
import styled from 'styled-components';
import { FeatureFlag } from '../../../config';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import { useMe } from '../../../hooks/useMe';
import useStepper, { IStep } from '../../../hooks/useStepper';
import { checkTeamMember } from '../../../lib/permissions';
import { DEALS_BY_SOLUTION_DOMAIN, GENERATE_QUOTE } from '../../../queries/installatron';
import {
  dealsByHouseSolutionDomain,
  dealsByHouseSolutionDomainVariables,
  dealsByHouseSolutionDomain_dealsByHouseSolutionDomain,
} from '../../../types/generated/dealsByHouseSolutionDomain';
import {
  generateQuoteFromInstallation,
  generateQuoteFromInstallationVariables,
} from '../../../types/generated/generateQuoteFromInstallation';
import { installationByHouseSolution_installationByHouseSolution as t_installation } from '../../../types/generated/installationByHouseSolution';
import { DealStatus, QuoteExperiment } from '../../../types/graphql-global-types';
import Alerts from '../../Alerts';
import { OperatorTeam } from '../../operatorDirectory/OperatorProfile';
import PvQuoteInfo from '../PvQuoteInfo';
import D2CQuoteConfigurator from './D2CQuoteConfigurator';
import DealSelector, { SelectedDeal } from './DealSelector';
import InstallationItemBreakdown from './InstallationItemBreakdown';
import OutOfDatePriceChecker, { PricingStatus } from './OutOfDatePriceChecker';

const QUATT_SUPPLIER_ID = '66f404805ef89a45984384ff';
const EVERS_SUPPLIER_ID = '6172c9c51e2ac328e5eb276a';

type CustomTabProps = {
  $isActive: boolean;
  $isClickable: boolean;
};

const CustomTab = styled(Box)<CustomTabProps>`
  text-align: center;
  color: ${x => (x.$isActive ? undefined : themify(x.$isClickable ? 'darkGray' : 'gray'))};
  cursor: ${x => (x.$isClickable ? 'pointer' : '')};
  border-bottom: 2px solid ${x => (x.$isActive ? themify('green') : 'transparant')};
`;

const usePurchasePriceCheck = ({
  installation,
  selectedDeal,
  pricingStatus,
}: {
  installation: t_installation;
  selectedDeal?: dealsByHouseSolutionDomain_dealsByHouseSolutionDomain;
  pricingStatus: PricingStatus | undefined;
}) => {
  const { me } = useMe();
  const isPlanning =
    checkTeamMember(me, OperatorTeam.Planning) || checkTeamMember(me, OperatorTeam.Development);

  const hasQuoteForSameInstallerOnDeal = selectedDeal?.quotes.find(
    // should really be checking the main item here to match the supplier id, but, meh
    q => q.acceptedOn && q.items[0]?.supplier?.id === installation.items[0]?.supplier?.id,
  );

  // Note: This causes Planning (and dev) to see extra UI based on permissions.
  // Do we want that, or always show the same to everyone, but just make it read-only if permission is denied?
  const confirmPurchasePricesBeforeExport =
    isPlanning && hasQuoteForSameInstallerOnDeal && pricingStatus?.purchasePrice !== 'up-to-date';

  return { confirmPurchasePricesBeforeExport };
};

const PurchasePriceCheckStep: React.FC<{
  ensureValidPurchasePrices?: boolean;
  setEnsureValidPurchasePrices: (val: boolean) => void;
}> = ({ ensureValidPurchasePrices, setEnsureValidPurchasePrices }) => {
  return (
    <Box width={1} px={1}>
      <p>
        Bij deze producten in Installatron staan <strong>inkoopprijzen</strong> opgeslagen die{' '}
        <strong>afwijken van het huidige aanbod</strong>.
        <br />
        Wil je de prijzen in Installatron behouden of verhogen naar het huidige aanbod?
      </p>
      <ul>
        <li>
          Kies <strong>Behouden</strong> alleen wanneer je dit zeker weet, bijvoorbeeld als je een
          aanpassing aan een oude offerte doorvoert na een schouw.
        </li>
        <li>
          Kies <strong>Verhogen</strong> in standaardgevallen om ons te houden aan de nieuwe
          afgesproken prijzen met de installateur
        </li>
      </ul>
      <RadioGroup
        label="Wil je de inkoopprijzen behouden of verhogen?"
        onChange={value => setEnsureValidPurchasePrices(value)}
      >
        <Radio
          id={'pp-false'}
          checked={ensureValidPurchasePrices === false}
          label="Opgeslagen inkoopprijzen uit installatron behouden"
          value={false}
        />
        <Radio
          id={'pp-true'}
          checked={ensureValidPurchasePrices === true}
          label="Inkoopprijzen verhogen naar het huidige aanbod"
          value={true}
        />
      </RadioGroup>
    </Box>
  );
};

const OperationalPilotStep: React.FC = () => (
  <>
    <Heading heading="h2">D2C pilot</Heading>
    <p>
      <strong>LET OP:</strong> Deze offerte maakt gebruik van een ander model, waarbij
      Energiebespaarders na akkoord niet meer het aanspreekpunt is. Bij twijfel of vragen, informeer
      bij Laurens, Jack of Hannelore.
    </p>
  </>
);

interface QuoteExportModalProps {
  isOpen: boolean;
  mobile: boolean;
  onClose: () => void;
  onCompletePvInfo: (args?: any) => void;
  installation: t_installation;
  deals: readonly dealsByHouseSolutionDomain_dealsByHouseSolutionDomain[];
  solutionDomain: SolutionDomain;
  onExported: () => void;
  pricingStatus: PricingStatus | undefined;
  pvInfoComplete: boolean;
}

const QuoteExportModal: React.FC<QuoteExportModalProps> = ({
  isOpen,
  onClose,
  mobile,
  onCompletePvInfo,
  installation,
  solutionDomain,
  deals,
  onExported,
  pricingStatus,
  pvInfoComplete,
}) => {
  const { me } = useMe();
  const { activeHouseId } = useActiveHouseId();
  const [selectedDeal, setSelectedDeal] = useState<SelectedDeal | undefined>();
  const [cardinalDirection, setCardinalDirection] = useState<string>('');
  const [multipleRoofSurfaces, setMultipleRoofSurfaces] = useState<boolean>();
  const [installationPlanProperties, setInstallationPlanProperties] = useState<string>('');
  const [neighbourDiscount, setNeighbourDiscount] = useState<boolean>();
  const [ensureValidPurchasePrices, setEnsureValidPurchasePrices] = useState<boolean>();

  const [isD2CSmokeScreen, setIsD2CSmokeScreen] = useState(false);
  const [hasBeenConvertedToD2CSmokeScreen, setHasBeenConvertedToD2CSmokeScreen] = useState(false);
  const [isD2CSmokeScreenStepValid, setIsD2CSmokeScreenStepValid] = useState(true);
  const [handleD2CSmokeScreenSubmit, setHandleD2CSmokeScreenSubmit] = useState<
    () => Promise<void>
  >();

  const solution = installation.solution;

  const d2cSmokeScreenFlag = useFlag(FeatureFlag.D2CQuoteSmokeScreenExperiment);
  const d2cOperationalQuattFlag = useFlag(FeatureFlag.D2CQuoteOperationalPilotQuatt);
  const d2cOperationalEversFlag = useFlag(FeatureFlag.D2CQuoteOperationalPilotEvers);

  const [isCheckCompleted, setIsCheckCompleted] = useState(false);

  const deal = useMemo(() => deals.find(d => d.id === selectedDeal?.dealId), [
    deals,
    selectedDeal?.dealId,
  ]);

  const { confirmPurchasePricesBeforeExport } = usePurchasePriceCheck({
    installation,
    selectedDeal: deal,
    pricingStatus,
  });
  const isQuattQuote = installation.items.some(item => item.supplier.id === QUATT_SUPPLIER_ID);
  const isEversQuote = installation.items.some(item => item.supplier.id === EVERS_SUPPLIER_ID);
  const isOperationalPilot =
    (d2cOperationalQuattFlag?.enabled && isQuattQuote) ||
    (d2cOperationalEversFlag?.enabled && isEversQuote);

  const steps = useMemo<IStep[]>(() => {
    const hadPreviousD2CQuote = deal?.quotes.some(q =>
      q.experiments?.includes(QuoteExperiment.d2cSmokeScreen),
    );

    const canBeD2CSmokeScreenExperiment =
      d2cSmokeScreenFlag?.enabled &&
      // Only inside-sales (and DEV for testing) can see this step
      ([OperatorTeam.InsideSales, OperatorTeam.Development].includes(me.team as OperatorTeam) ||
        // and for Planning if there was a previous D2C quote on this specific deal
        (me.team === OperatorTeam.Planning && hadPreviousD2CQuote)) &&
      // Can't be part of both experiments simultaneously
      !isOperationalPilot;

    return [
      {
        // For Planning,
        // if there is an accepted quote for the same installer,
        // and the purchase prices of this installation aren't up to date,
        // provide an explicit option to skip the purchase price update
        id: 'purchasePriceCheck',
        title: 'Inkoopprijzen controleren',
        isIrrelevant: !confirmPurchasePricesBeforeExport,
        isInputValid: ensureValidPurchasePrices !== undefined,
        content: (
          <>
            <OutOfDatePriceChecker
              installation={installation}
              pricingStatus={pricingStatus}
              defaultPriceType="purchasePrice"
              defaultOpen
            />
            <PurchasePriceCheckStep
              ensureValidPurchasePrices={ensureValidPurchasePrices}
              setEnsureValidPurchasePrices={setEnsureValidPurchasePrices}
            />
          </>
        ),
      },
      {
        id: 'd2cSmokeScreen',
        title: 'Type offerte',
        isIrrelevant: !canBeD2CSmokeScreenExperiment,
        isInputValid: isD2CSmokeScreenStepValid,
        content: (
          <D2CQuoteConfigurator
            installationId={installation.id}
            solution={solution}
            items={installation.items}
            setIsValid={setIsD2CSmokeScreenStepValid}
            setSubmissionHandler={setHandleD2CSmokeScreenSubmit}
            isD2CSmokeScreen={isD2CSmokeScreen}
            setIsD2CSmokeScreen={setIsD2CSmokeScreen}
            hasBeenConvertedToD2CSmokeScreen={hasBeenConvertedToD2CSmokeScreen}
            setHasBeenConvertedToD2CSmokeScreen={setHasBeenConvertedToD2CSmokeScreen}
          />
        ),
        onSubmit: isD2CSmokeScreen ? handleD2CSmokeScreenSubmit : undefined,
      },
      {
        id: 'd2cOperational',
        title: 'Directe verkoop via Quatt',
        isIrrelevant: !isOperationalPilot,
        isInputValid: true,
        content: <OperationalPilotStep />,
      },
      {
        id: 'retailPriceCheck',
        isInputValid: true,
        title: 'Verkoopprijzen controleren',
        content: (
          <>
            {!pvInfoComplete && solution === Solution.PvSystem && (
              <Toast
                type="alert"
                message={'Let op, "zonnepanelen extra offerte informatie" is niet ingevuld'}
                width="100%"
                toastId={991}
              />
            )}
            <p>
              <Medium>Kloppen deze verkoopprijzen?</Medium>
            </p>
            <InstallationItemBreakdown
              installation={installation}
              houseId={activeHouseId}
              solution={solution}
              solutionDomain={solutionDomain}
              mobile={mobile}
              readOnly
            />
            {solution === Solution.PvSystem && (
              <PvQuoteInfo
                cardinalDirection={cardinalDirection}
                setCardinalDirection={setCardinalDirection}
                multipleRoofSurfaces={multipleRoofSurfaces}
                setMultipleRoofSurfaces={setMultipleRoofSurfaces}
                installationPlanProperties={installationPlanProperties}
                setInstallationPlanProperties={setInstallationPlanProperties}
                neighbourDiscount={neighbourDiscount}
                setNeighbourDiscount={setNeighbourDiscount}
                pvInfoComplete={pvInfoComplete}
              />
            )}
          </>
        ),
      },
      {
        id: 'pickDeal',
        title: 'Deal selecteren',
        content: (
          <DealSelector selectDeal={setSelectedDeal} deals={deals} selectedDeal={selectedDeal} />
        ),
        isInputValid: !!selectedDeal?.dealId,
      },
    ];
  }, [
    deal?.quotes,
    d2cSmokeScreenFlag?.enabled,
    me.team,
    isOperationalPilot,
    confirmPurchasePricesBeforeExport,
    ensureValidPurchasePrices,
    installation,
    pricingStatus,
    isD2CSmokeScreenStepValid,
    solution,
    isD2CSmokeScreen,
    hasBeenConvertedToD2CSmokeScreen,
    handleD2CSmokeScreenSubmit,
    pvInfoComplete,
    activeHouseId,
    solutionDomain,
    mobile,
    cardinalDirection,
    multipleRoofSurfaces,
    installationPlanProperties,
    neighbourDiscount,
    deals,
    selectedDeal,
  ]);

  const { step, relevantSteps, resetProgress, handleNextStep, handlePrevStep } = useStepper(
    steps,
    setIsCheckCompleted,
  );

  useEffect(() => {
    onCompletePvInfo(
      solution !== Solution.PvSystem ||
        !!(
          cardinalDirection &&
          installationPlanProperties &&
          multipleRoofSurfaces !== undefined &&
          neighbourDiscount !== undefined
        ),
    );
  }, [
    cardinalDirection,
    solution,
    installationPlanProperties,
    multipleRoofSurfaces,
    neighbourDiscount,
    onCompletePvInfo,
  ]);

  const [generateQuote, { loading: generateQuoteLoading, error: generateQuoteError }] = useMutation<
    generateQuoteFromInstallation,
    generateQuoteFromInstallationVariables
  >(GENERATE_QUOTE, {
    variables: {
      installationId: installation.id,
      operatorId: me.id,
      pvSystemInfo: {
        cardinalDirection,
        multipleRoofSurfaces,
        installationPlanProperties,
        neighbourDiscount,
      },
      dealReference: selectedDeal?.dealId === 'new' ? null : deal?.reference,
      quoteToCopyId: selectedDeal?.quoteToCopyId,
      ensureValidPurchasePrices: !!ensureValidPurchasePrices,
      experiments: [
        ...(isD2CSmokeScreen ? [QuoteExperiment.d2cSmokeScreen] : []),
        ...(isOperationalPilot ? [QuoteExperiment.d2cOperational] : []),
      ],
    },
    update: (store, { data }) => {
      if (data?.generateQuoteFromInstallation) {
        const newQuote = { ...data.generateQuoteFromInstallation, jobs: [] };

        // UPDATE DEALS BY SOLUTION DOMAIN
        const dealsByHouseSolutionDomainData = store.readQuery<
          dealsByHouseSolutionDomain,
          dealsByHouseSolutionDomainVariables
        >({
          query: DEALS_BY_SOLUTION_DOMAIN,
          variables: { houseId: activeHouseId, solutionDomain },
        });

        if (dealsByHouseSolutionDomainData?.dealsByHouseSolutionDomain) {
          const { dealsByHouseSolutionDomain } = dealsByHouseSolutionDomainData;

          const updatedDeals: dealsByHouseSolutionDomain_dealsByHouseSolutionDomain[] = [];
          if (selectedDeal?.dealId !== 'new') {
            updatedDeals.push(
              ...dealsByHouseSolutionDomain.map(_deal => {
                if (_deal.id === newQuote?.deal?.id) {
                  const updatedQuotes = [..._deal.quotes, newQuote];
                  return { ..._deal, quotes: updatedQuotes };
                } else {
                  return _deal;
                }
              }),
            );
          } else {
            updatedDeals.push(...dealsByHouseSolutionDomain, {
              items: [],
              noLongerActive: null,
              status: newQuote.deal?.status || DealStatus.open,
              pipedriveDealId: newQuote.deal?.pipedriveDealId || null,
              id: newQuote.deal?.id || '',
              onHold: newQuote.deal.onHold,
              reference: newQuote.deal?.reference || '',
              created: new Date().getTime(),
              solution: newQuote.solution,
              quotes: [newQuote],
              isInstalled: false,
              activeQuote: null,
              pipedriveDealUrl: null,
              salesManagerId: newQuote.deal.salesManagerId,
              __typename: 'Deal',
              title: null,
              description: null,
              icon: null,
              priority: 0,
            });
          }

          store.writeQuery<dealsByHouseSolutionDomain, dealsByHouseSolutionDomainVariables>({
            query: DEALS_BY_SOLUTION_DOMAIN,
            data: {
              dealsByHouseSolutionDomain: updatedDeals,
            },
            variables: { houseId: activeHouseId, solutionDomain },
          });
        }
      }
    },
    onCompleted: onExported,
  });

  useEffect(() => {
    if (!isOpen) {
      // Reset the state when the modal closes, so it must be explicitly picked again after re-opening
      setEnsureValidPurchasePrices(undefined);
      resetProgress();
      setIsCheckCompleted(false);
      setIsD2CSmokeScreenStepValid(true);
      setIsD2CSmokeScreen(false);
      setHasBeenConvertedToD2CSmokeScreen(false);
    }
  }, [deals, isOpen, resetProgress]);

  return (
    <Modal
      onRequestClose={onClose}
      isOpen={isOpen}
      mobile={mobile}
      size="xl"
      title="Offerte exporteren"
      minHeight={mobile ? undefined : '30em'}
      buttons={[
        step === relevantSteps[0]
          ? {
              bgColor: 'orange',
              inverse: true,
              iconStart: Reply,
              label: 'Terug naar overzicht',
              onClick: onClose,
              fontSize: 6,
            }
          : {
              bgColor: 'orange',
              inverse: true,
              iconStart: ArrowLeft,
              label: 'Vorige',
              onClick: handlePrevStep,
              fontSize: 6,
              disabled: step?.id === relevantSteps[0]?.id,
            },
        isCheckCompleted
          ? {
              bgColor: 'green',
              disabled:
                !pvInfoComplete ||
                generateQuoteLoading ||
                !selectedDeal?.dealId ||
                !isCheckCompleted,
              label: generateQuoteLoading
                ? 'Bezig...'
                : !selectedDeal?.dealId
                ? 'Deal selecteren'
                : 'Offerte exporteren',
              onClick: generateQuote as () => void,
              error: generateQuoteError?.message,
              fontSize: 6,
              iconStart: PageAdd,
            }
          : {
              bgColor: 'green',
              iconEnd: ArrowRight,
              label:
                relevantSteps[relevantSteps.findIndex(s => s === step) + 1]?.title || 'Volgende',
              onClick: handleNextStep,
              fontSize: 6,
              disabled: !step?.isInputValid,
            },
      ]}
    >
      <Alerts
        warnings={installation.exportConstraintMessages.warnings[solution]}
        errors={installation.exportConstraintMessages.errors[solution]}
      />

      <Flex justifyContent="space-around">
        {relevantSteps.map((s, i) => {
          return (
            <CustomTab key={s.id} p={1} $isActive={s === step}>
              <strong>
                {i + 1}. {s.title}
              </strong>
            </CustomTab>
          );
        })}
      </Flex>

      {step?.content}
    </Modal>
  );
};

export default QuoteExportModal;
