import { useMutation, useQuery } from '@apollo/client';
import { Gallery, Placeholder } from '@energiebespaarders/symbols';
import React, { useCallback } from 'react';
import { fileUrl } from '../config';
import { DELETE_QUOTE_FILE, GET_QUOTE_FILES } from '../queries/files';
import { deleteQuoteFile, deleteQuoteFileVariables } from '../types/generated/deleteQuoteFile';
import { getQuoteFiles, getQuoteFilesVariables } from '../types/generated/getQuoteFiles';

interface QuoteGalleryProps {
  exclusiveMatch?: boolean;
  quoteId: string;
  readOnly?: boolean;
  tags: string[];
  onDelete?: () => void;
}

const QuoteGallery: React.FC<QuoteGalleryProps> = ({
  exclusiveMatch = true,
  quoteId,
  readOnly,
  tags,
  onDelete,
}) => {
  const { data, loading, error } = useQuery<getQuoteFiles, getQuoteFilesVariables>(
    GET_QUOTE_FILES,
    { variables: { quoteId } },
  );
  const [deleteQuoteFileMutation] = useMutation<deleteQuoteFile, deleteQuoteFileVariables>(
    DELETE_QUOTE_FILE,
  );
  const deleteFile = useCallback(
    fileId => {
      deleteQuoteFileMutation({
        variables: {
          quoteId,
          fileId,
        },
        update: store => {
          const queryData = store.readQuery<getQuoteFiles, getQuoteFilesVariables>({
            query: GET_QUOTE_FILES,
            variables: { quoteId },
          });
          if (queryData?.quoteById?.files) {
            const newFiles = queryData.quoteById.files.filter(file => file.id !== fileId);
            store.writeQuery({
              query: GET_QUOTE_FILES,
              data: {
                quoteById: { ...queryData.quoteById, files: newFiles },
              },
              variables: { quoteId },
            });
          }
          onDelete?.();
        },
      });
    },
    [deleteQuoteFileMutation, quoteId, onDelete],
  );
  if (loading || error) return <Placeholder error={error} />;
  return (
    <Gallery
      deleteFile={readOnly ? undefined : deleteFile}
      exclusiveMatch={exclusiveMatch}
      files={data?.quoteById?.files ?? []}
      fileUrl={fileUrl}
      gutter={1}
      size="ss"
      tags={tags}
      thumbSize={100}
    />
  );
};

export default QuoteGallery;
