import { gql } from '@apollo/client';
import OperatorFragment from '../fragments/Operator';

export const GET_OPERATORS = gql`
  query getOperators($includeInactive: Boolean) {
    operators(includeInactive: $includeInactive) {
      id
      firstName
      lastName
      jobTitle
      role
      profilePhotoUrl
      isActive
      team
      isFreelancer
    }
  }
`;

export const GET_OPERATOR = gql`
  ${OperatorFragment}
  query operatorById($id: ID!) {
    operatorById(id: $id) {
      ...Operator
      salesManager {
        id
        firstName
        lastName
      }
      created
      modified
      lastLogin
    }
  }
`;

export const ADD_OPERATOR = gql`
  mutation registerOperator($operator: OperatorInput) {
    registerOperator(operator: $operator) {
      id
      firstName
      lastName
      jobTitle
      profilePhotoUrl
      isActive
      role
      team
      isFreelancer
    }
  }
`;

export const UPDATE_OPERATOR = gql`
  ${OperatorFragment}
  mutation updateOperator($operator: OperatorInput, $id: ID!) {
    updateOperator(operator: $operator, id: $id) {
      ...Operator
      salesManager {
        id
        firstName
        lastName
      }
      created
      modified
    }
  }
`;

export const SET_OPERATOR_PROFILE_PHOTO = gql`
  mutation setOperatorProfilePhoto($id: ID!, $file: Upload) {
    setOperatorProfilePhoto(id: $id, file: $file) {
      id
      extension
    }
  }
`;
