import { ApolloClient, ApolloLink, split } from '@apollo/client';
import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client/cache';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { CachePersistor } from 'apollo-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import localforage from 'localforage';
import cleanTypenameFieldLink from './cleanTypenameFieldLink';
import { graphqlUrl, isDeveloping, version } from './config';
import possibleTypes from './possibleTypes.json';
import typePolicies from './typePolicies.json';

export async function setupClient() {
  localforage.config({
    storeName: 'Energiebeheerders',
  });

  // Define the cache and persist it immediately
  const cache = new InMemoryCache({
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'Progress':
          return object.houseId;
        default:
          return defaultDataIdFromObject(object) as any;
      }
    },
    // Generated using the chili:utilities/getPossibleTypes.js
    possibleTypes,
    // Custom cache merging:
    // https://www.apollographql.com/docs/react/caching/cache-field-behavior
    // Generated using chili:utilities/getMergeTypePolicies.js
    typePolicies: {
      ...typePolicies,
      Query: {
        fields: {
          ...typePolicies.Query.fields,
          dealsByHouseSolutionDomain: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      Installation: {
        fields: {
          ...typePolicies.Installation.fields,
          items: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      Intake: {
        fields: {
          ...typePolicies.Intake.fields,
          incorrectMap: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      Deal: {
        fields: {
          ...typePolicies.Deal.fields,
          quotes: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      Price: {
        fields: {
          revisions: {
            // needed for fetchMore https://www.apollographql.com/docs/react/pagination/core-api/
            keyArgs: [],
            merge(existing = [], incoming, { args }) {
              const typedArgs = args as { offset?: number };
              // Slicing is necessary because the existing data is
              // immutable, and frozen in development.
              const merged = existing ? existing.slice(0) : [];
              for (let i = 0; i < incoming.length; ++i) {
                merged[(typedArgs.offset || 0) + i] = incoming[i];
              }
              return merged;
            },
          },
        },
      },
    },
  });

  const persistor = new CachePersistor({ cache, storage: localforage as any });

  try {
    await persistor.restore();
  } catch (error) {
    console.error('Error restoring Apollo cache', error);
  }

  // TODO: Custom auth:: https://www.apollographql.com/docs/link/links/batch-http.html#custom-auth

  // const httpLink = new HttpLink({ uri: graphqlUrl, credentials: 'include' });
  // Batch graphql requests per 10 every 20ms
  const httpLink = new BatchHttpLink({
    uri: graphqlUrl,
    credentials: 'include',
    batchMax: 10,
    batchInterval: 20,
  });

  const uploadLink = createUploadLink({
    uri: graphqlUrl,
    credentials: 'include',
    headers: { 'Apollo-Require-Preflight': 'true' }, // required for CSRF prevention & graphql-upload, see https://www.apollographql.com/docs/apollo-server/security/cors/#graphql-upload
  });

  const resetToken = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { pathname, search, origin } = window.location;
        switch (err?.extensions?.code) {
          case 'FORBIDDEN':
          case 'UNAUTHENTICATED':
            if (pathname !== '/login') {
              window.location.replace(`${origin}/login?from=${pathname.slice(1)}${search}`);
            }
            break;
          default:
            break;
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  // Avoiding conflict with upload link: only use it for uploading
  // Source: https://github.com/jaydenseric/apollo-upload-client/issues/63
  const isFile = (value: any) =>
    (typeof FileList !== 'undefined' && value instanceof FileList) ||
    (typeof File !== 'undefined' && value instanceof File) ||
    (typeof Blob !== 'undefined' && value instanceof Blob) ||
    (typeof File !== 'undefined' &&
      typeof Blob !== 'undefined' &&
      Array.isArray(value) &&
      value.some(v => v instanceof File || v instanceof Blob));

  const containsUpload: (value: any) => boolean = (value: any) => {
    if (isFile(value)) {
      return true;
    }
    if (Array.isArray(value)) {
      return value.some(containsUpload);
    }
    if (typeof value === 'object' && value !== null) {
      return Object.values(value).some(containsUpload);
    }
    return false;
  };

  const isUpload = ({ variables }: { variables: Record<string, unknown> }) => {
    return Object.values(variables).some(containsUpload);
  };

  const isNotUpload = (input: any) => !isUpload(input);

  // The type for uploads is needed to determine the split in the terminal link
  const filteredCleanTypenameFieldLink = split(isNotUpload, cleanTypenameFieldLink);
  const terminalLink = split(isUpload, uploadLink, httpLink);

  const link = ApolloLink.from([
    filteredCleanTypenameFieldLink, // removes __typename from variables, see cleanTypenameFieldLink.js
    resetToken,
    setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          'apollographql-client-name': 'energiebeheerders',
          'apollographql-client-version': version,
        },
      };
    }),
    terminalLink,
  ]);

  const client = new ApolloClient({
    link,
    cache,
    name: 'energiebeheerders',
    version,
    // queryDeduplication: false,
    connectToDevTools: isDeveloping,
  });

  client.onResetStore(() => cache.reset());

  return client;
}
