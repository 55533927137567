import { Solution } from '@energiebespaarders/constants';
import { InstallerStatus } from '../../types/graphql-global-types';
import { ToastType } from './../../hooks/useToaster';
import { getInstallationPartners_installersBySolution } from './../../types/generated/getInstallationPartners';
import { getPrice_getPrice } from './../../types/generated/getPrice';

type GenerationErrorCode =
  | 'missingAnswers'
  | 'answersPreventGeneration'
  | 'failedToFindProduct'
  | 'no viable installer found'
  | 'productDbProblem'
  | 'unknown';

type GenerationError = {
  errorCode: GenerationErrorCode;
  message: string;
  details: string;
};

export const generatableSolutions: Solution[] = [
  Solution.WallInsulation,
  Solution.FloorInsulation,
  Solution.CrawlspaceInsulation,
  Solution.AtticFloorInsulation,
  Solution.InnerRoofInsulation,
  Solution.WindowGlazing,
];

export const parseInstallationGenerationError = (errorCode: string): GenerationError => {
  switch (true) {
    case errorCode.includes('missingAnswers'):
      return {
        errorCode: 'missingAnswers',
        details:
          'The answers that are required to generate a pre-filled installation were not all present',
        message: 'Er ontbreekt nog informatie uit de opname om een installatie te genereren',
      };
    case errorCode.includes('answersPreventGeneration'):
      return {
        errorCode: 'answersPreventGeneration',
        details: 'The answers given in the intake prevent the installation from being pre-filled',
        message:
          'Met de huidige opname antwoorden kan de installatie niet gegenereerd worden, vul hem handmatig in',
      };
    case errorCode.includes('failedToFindProduct'):
      return {
        errorCode: 'failedToFindProduct',
        details:
          'The products required to pre-fill the installation could not be found in the database',
        message: 'De benodigde producten voor deze installatie zijn niet gevonden in onze database',
      };
    case errorCode.includes('no viable installer found'):
      return {
        errorCode: 'no viable installer found',
        details: 'No viable installer was found',
        message: 'Er kon geen geschikte installateur gevonden worden',
      };
    case errorCode.includes('productDbProblem'):
      return {
        errorCode: 'productDbProblem',
        details: 'A problem was encountered while gathering the corresponding products',
        message: 'Er is iets mis gegaan met de juiste producten bij elkaar zoeken, vraag aan DEV',
      };
    default:
      return {
        errorCode: 'unknown',
        details: 'Something went wrong when generating the installation',
        message: 'Er is iets onverwachts misgegaan',
      };
  }
};

export const getInstallationConfiguratorNotifications = (
  solution: Solution,
  tooExpensive: boolean,
  price: getPrice_getPrice,
  selectedInstallationPartner?: getInstallationPartners_installersBySolution,
) => {
  const notifications: { key: string; type: ToastType; message: string }[] = [];

  if (selectedInstallationPartner && !selectedInstallationPartner.capableOfInstallingAtHouse) {
    notifications.push({
      key: 'outsideOfInstallerRegion',
      type: 'error',
      message: `Het adres van deze woning zit buiten het werkgebied van ${selectedInstallationPartner.name}! Dit kan niet (voor deze prijs) aangeboden worden.`,
    });
  }
  if (selectedInstallationPartner && selectedInstallationPartner.installationRegions.length === 0) {
    notifications.push({
      key: 'noInstallerRegion',
      type: 'error',
      message: `De werkgebieden van ${selectedInstallationPartner.name} zijn onbekend! Deze moeten eerst toegevoegd worden.`,
    });
  }

  if (selectedInstallationPartner?.status.value === InstallerStatus.inactive) {
    notifications.push({
      key: 'installerInactive',
      type: 'error',
      message: `${selectedInstallationPartner.name} staat op inactief, ze nemen geen opdrachten meer aan.`,
    });
  } else if (selectedInstallationPartner?.status.value === InstallerStatus.paused) {
    notifications.push({
      key: 'installerPaused',
      type: 'warning',
      message: `${selectedInstallationPartner.name} staat op pauze, ze nemen geen nieuwe opdrachten meer aan.`,
    });
  } else if (selectedInstallationPartner?.status.value === InstallerStatus.setup) {
    notifications.push({
      key: 'installerPaused',
      type: 'warning',
      message: `${selectedInstallationPartner.name} wordt nog klaar gezet.`,
    });
  }

  return notifications;
};
