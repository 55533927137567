import { gql, useMutation, useQuery } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { SOLUTION_TOPICS, Solution, Solutions } from '@energiebespaarders/constants';
import { Box, Flex, Icon, Placeholder, Spinner } from '@energiebespaarders/symbols';
import { Medium, Red, Small } from '@energiebespaarders/symbols/helpers';
import dayjs from 'dayjs';
import { rgba } from 'polished';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useMe } from '../../../hooks/useMe';
import useToaster from '../../../hooks/useToaster';
import { checkTeamMember } from '../../../lib/permissions';
import {
  GET_ADVICE_ID,
  TOGGLE_ADVISED_QUOTE,
  updateToggleAdvisedQuoteCache,
} from '../../../queries/advice';
import { GET_CONSUMPTION } from '../../../queries/calculations';
import { SEND_JOB_FOLLOW_UP_EMAIL } from '../../../queries/job';
import { margin, padding, shade, themify } from '../../../styles/mixins';
import {
  adviceIdByHouse,
  adviceIdByHouseVariables,
  adviceIdByHouse_adviceByHouse,
} from '../../../types/generated/adviceIdByHouse';
import { getConsumption, getConsumptionVariables } from '../../../types/generated/getConsumption';
import { quoteModalQuote_quoteById } from '../../../types/generated/quoteModalQuote';
import {
  sendJobCompletedFollowUp,
  sendJobCompletedFollowUpVariables,
} from '../../../types/generated/sendJobCompletedFollowUp';
import {
  sendQuoteBlocksByHouseId,
  sendQuoteBlocksByHouseIdVariables,
  sendQuoteBlocksByHouseId_house_intake_progress,
} from '../../../types/generated/sendQuoteBlocksByHouseId';
import {
  toggleAdvisedQuote,
  toggleAdvisedQuoteVariables,
} from '../../../types/generated/toggleAdvisedQuote';
import { DealStatus, JobFollowUpType } from '../../../types/graphql-global-types';
import AcceptQuoteModal from '../../modals/AcceptQuoteModal';
import { OperatorTeam } from '../../operatorDirectory/OperatorProfile';
import CancelQuoteModal, { cancellationReasons } from './CancelQuoteModal';
import FinalizationModal from './FinalizationModal';
import { getQuoteActions, QuoteStatus, StatusIcon } from './QuoteStatusIcons';
import UpdateCalculationsModal from './UpdateCalculationsModal';
import { IntakeProgressFragment } from '../../../fragments/Intake';

export const SEND_QUOTE_BLOCKS = gql`
  ${IntakeProgressFragment}

  query sendQuoteBlocksByHouseId($houseId: ID!) {
    house(id: $houseId) {
      id
      isGVAHouse
      intake {
        id
        isStarted
        startedOn

        ...Progress
      }
    }
  }
`;

export const INTAKE_COMPLETION_DEPLOY_DATE = dayjs('2022-09-11');

type StyledLabelProps = { mobile: boolean };
const StatusLabel = styled.div<StyledLabelProps>`
  ${margin(1, 'top')};
  font-size: ${x => x.theme.type.scale[x.mobile ? 8 : 7]};
  font-weight: bold;
`;

type StatusButtonProps = {
  mobile: boolean;
  isActive: boolean;
  noPointerEvents: boolean;
  disabled: boolean;
  color: string;
};

const StatusButton = styled.div<StatusButtonProps>`
  ${padding(2)};
  ${x => margin(x.mobile ? 1 : 0, 'bottom')};
  background: ${x => (x.isActive ? rgba(x.color, 0.1) : 'none')};
  border-radius: 3px;
  pointer-events: ${x => (x.noPointerEvents ? 'none' : 'all')};
  text-align: center;
  color: ${x => (x.disabled ? themify('gray') : x.color)};
  cursor: ${x => (x.disabled ? 'no-drop' : 'pointer')};
  transition: all 0.2s ${x => x.theme.curves.standard};

  &:hover {
    background: ${x => (x.isActive ? rgba(x.color, 0.15) : 'none')};
    color: ${x => shade(0.95, x.color)};

    ${StatusIcon} {
      background: ${x => shade(0.95, x.color)};
    }

    ${StatusLabel} {
      color: ${x => shade(0.95, x.color)};
    }
  }

  &:active {
    color: ${x => shade(0.85, x.color)};
  }
`;

type Props = {
  mobile: boolean;
  houseId: string;
  quote: quoteModalQuote_quoteById;
  updateQuote: () => void;
  isOnlyVisibleQuoteOnDeal?: boolean;
  isReadOnly?: boolean;
};

export type CancellationInformation = { reason: string; note?: string; party: string };
export type FinalizationInformation = { sendCustomerEmail: boolean };

const notSuppliableMessage = 'Deze offerte heeft artikelen die niet meer leverbaar zijn.';
const notSentMessage = 'Je kunt een conceptofferte niet accorderen: maak zichtbaar voor de klant.';
const alreadyWonMessage =
  'Deze deal is al gewonnen. Is deze offerte onafhankelijk realiseerbaar van de uitgevoerde offerte? Dan hoort deze in een nieuwe deal. Anders zal de eerdere offerte geannuleerd moeten worden.';
const intakeNotFinishedMessage = 'De opname voor deze offerte is nog niet voltooid.';
const isMissingSavingsMessage = 'Er is geen besparing aangegeven of berekend voor deze offerte.';
const isMissingConsumptionMessage = 'Er is nog geen energieverbruik ingevuld. Toch doorgaan?';
const unCancellationMessage =
  'Weet je zeker dat je een geannuleerde offerte weer actief wilt maken? Dit zou niet moeten gebeuren.';
const confirmUnfinalizeMessage = 'Weet je zeker dat je de offerte van definitief wilt afhalen?';
const notUnfinalizableMessage =
  'Je kunt een definitieve offerte met akkoord niet meer terugzetten naar voorlopig. Maak indien nodig een nieuwe offerte.';
const advisedStatusNotNeededMessage =
  'Voor geannuleerde offertes maakt het niet uit of deze op geadviseerd staat, deze worden niet meer getoond aan de klant. Toch doorgaan?';
const noUnacceptingMsg = (
  quoteReference: string,
) => `Je hoort een akkoord op een offerte niet te verwijderen.

Als er een nieuwe offerte met een akkoord is, dan is het genoeg om gewoon deze offerte te annuleren - dan gaat de akkoord-informatie niet verloren.

Als je écht het akkoord wilt weghalen, laat dit dan Dev even weten met de referentie (${quoteReference}) met de reden dat waarom het nodig is.`;
const installerInactiveMessage =
  'Deze offerte mag niet aangepast worden vanwege de status van de installateur.';

const QuoteStatusButtons: React.FC<Props> = ({
  mobile,
  houseId,
  quote,
  updateQuote,
  isOnlyVisibleQuoteOnDeal,
  isReadOnly,
}) => {
  const [acceptQuoteModal, setAcceptQuoteModal] = useState(false);
  const toast = useToaster();
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [cancelQuoteModal, setCancelQuoteModal] = useState(false);
  const [finalizationModal, setFinalizationModal] = useState(false);
  const [updateCalculationsModal, setUpdateCalculationsModal] = useState(false);
  const quoteActions = getQuoteActions(true, quote);
  const activeStatuses = quote && getQuoteActions(false, quote);
  const { me } = useMe();
  const isPlanning =
    checkTeamMember(me, OperatorTeam.Planning) || checkTeamMember(me, OperatorTeam.Development);

  const { data: adviceIdData, loading: adviceIdLoading, error: adviceIdError } = useQuery<
    adviceIdByHouse,
    adviceIdByHouseVariables
  >(GET_ADVICE_ID, { variables: { houseId } });

  const { data: consumptionData, loading: consumptionLoading, error: consumptionError } = useQuery<
    getConsumption,
    getConsumptionVariables
  >(GET_CONSUMPTION, {
    variables: {
      houseId,
    },
  });

  const { data: sendQuoteBlocks } = useQuery<
    sendQuoteBlocksByHouseId,
    sendQuoteBlocksByHouseIdVariables
  >(SEND_QUOTE_BLOCKS, { variables: { houseId } });

  const intakeStarted = sendQuoteBlocks?.house.intake.startedOn || false;
  const isAfterIntakeCompletion = dayjs(intakeStarted).isAfter(INTAKE_COMPLETION_DEPLOY_DATE);
  const isPTCInsufficientAfterInsideSalesIntake =
    sendQuoteBlocks?.house.intake.progress[
      SOLUTION_TOPICS[quote.solution as keyof typeof SOLUTION_TOPICS] as Exclude<
        keyof sendQuoteBlocksByHouseId_house_intake_progress,
        '__typename'
      >
    ]?.reasonNotCompleted?.category;

  const isMaxThreeMinsOld = dayjs(quote.created).diff(dayjs(), 'minutes') <= 3;
  const isManualCalculation = useMemo(
    () =>
      [
        Solution.PvSystem,
        Solution.HybridHeatPump,
        Solution.ElectricHeatPump,
        Solution.UnderfloorHeating,
        Solution.Miscellaneous,
        Solution.ChargingBox,
        Solution.Sedum,
        Solution.HomeBattery,
      ].some(s => quote.solution === s),
    [quote.solution],
  );

  const [sendEmail] = useMutation<sendJobCompletedFollowUp, sendJobCompletedFollowUpVariables>(
    SEND_JOB_FOLLOW_UP_EMAIL,
    {
      onCompleted: () =>
        toast({ message: 'E-mailbevestiging verstuurd voor definitief maken', type: 'success' }),
      onError: e =>
        toast({
          message: `E-mailbevestiging kon niet worden verstuurd: ${e.message}`,
          type: 'error',
        }),
    },
  );

  /** The actual click handler after passing validation */
  const handleClick = (
    mutation: (v: any) => void,
    quote: quoteModalQuote_quoteById,
    advice: adviceIdByHouse_adviceByHouse | null,
    status: QuoteStatus,
    cancellationInformation?: CancellationInformation,
    updateCalculations?: boolean,
    finalizationInformation?: FinalizationInformation,
  ) => {
    setSubmissionLoading(true);
    let variables: any = { id: quote.id };
    switch (status) {
      case QuoteStatus.Final:
        variables = {
          ...variables,
          quote: { final: quote.final ? null : new Date() },
        };
        break;
      case QuoteStatus.Sent:
        variables = {
          ...variables,
          updateCalculations,
        };
        break;
      case QuoteStatus.Advised:
        variables = {
          adviceId: advice?.id,
          quoteId: quote.id,
          solution: quote.solution,
        };
        break;
      case QuoteStatus.AcceptedSubjectToFunding: // This means the quote is being unaccepted (accepted was pressed when quote was accepted)
        variables = {
          ...variables,
          quote: { acceptedSubjectToFunding: null },
        };
        break;
      case QuoteStatus.Cancelled:
        variables = {
          ...variables,
          quote: {
            cancellationInformation,
            cancelled: quote.isCancelled ? null : new Date(),
          },
        };
        break;
      default:
        break;
    }
    mutation({ variables });

    // E-mail updates
    const ptcJob = quote.deal.ptcJob;
    if (ptcJob && finalizationInformation?.sendCustomerEmail) {
      const jobFollowUpType: JobFollowUpType = JobFollowUpType.ptcNoChanges;
      sendEmail({ variables: { jobFollowUpType, jobId: ptcJob.id } });
    }
  };

  const [toggleAdvisedQuote] = useMutation<toggleAdvisedQuote, toggleAdvisedQuoteVariables>(
    TOGGLE_ADVISED_QUOTE,
    {
      onCompleted: () => {
        setAcceptQuoteModal(false);
        setSubmissionLoading(false);
        setCancelQuoteModal(false);
        setUpdateCalculationsModal(false);
        setFinalizationModal(false);
      },
      update: (cache, res) => updateToggleAdvisedQuoteCache(cache, res, quote),
    },
  );

  /** Determines whether to block sending a quote */
  const isMissingSavings = useMemo(() => {
    if (!consumptionData) return false;
    if (Solutions.nonEnergySaving.includes(quote.solution)) return false;
    if (quote.solution === Solution.PvSystem) {
      return quote.energyDelta.electricityProduction === 0;
    }
    if (consumptionData.house.situation.consumption.gas === 0) return false;
    return quote.energyDelta.gasConsumption === 0;
  }, [
    consumptionData,
    quote.energyDelta.electricityProduction,
    quote.energyDelta.gasConsumption,
    quote.solution,
  ]);

  const isMissingConsumption = useMemo(() => {
    if (
      quote.solution === Solution.HybridHeatPump ||
      quote.solution === Solution.ElectricHeatPump
    ) {
      return quote.energyDelta.electricityConsumption === 0;
    }
    return false;
  }, [quote.energyDelta.electricityConsumption, quote.solution]);

  if (adviceIdError || consumptionError)
    return <Placeholder error={adviceIdError || consumptionError} />;
  if (adviceIdLoading || !adviceIdData || consumptionLoading || !consumptionData)
    return <Spinner />;
  const { adviceByHouse: advice } = adviceIdData;
  if (!advice) return <Placeholder error="No advice found!" />;

  const renderButton = (
    isActive: boolean,
    quoteButton: any,
    advice: adviceIdByHouse_adviceByHouse,
    mutation: (v: any) => void,
  ) => {
    // Validation
    // ------------------------------------
    // Every button has its own validation depending on the status of the quote and the user's permissions:
    //  when the disabled message is set, the action is not allowed
    let disabledMessage = '';
    //  when the confirmation message is set, the action requires an explicit confirmation
    let confirmationMessage = '';

    switch (quoteButton.status) {
      case QuoteStatus.Final:
        if (!quote.final && !quote.hasCompletedIntake && isAfterIntakeCompletion) {
          if (!isPTCInsufficientAfterInsideSalesIntake) {
            // ✨ New exception ✨ After a inside-sales intake, and the PTC doesn't include all info to complete the intake, we allow finalizing the quote
            disabledMessage = intakeNotFinishedMessage;
          }
        } else if (quote.isAccepted && quote.final) {
          // When the quote is already accepted and set to final, only Planning may un-finalize it
          if (isPlanning) {
            confirmationMessage = confirmUnfinalizeMessage;
          } else {
            disabledMessage = notUnfinalizableMessage;
          }
        }
        break;
      case QuoteStatus.Sent:
        // Sending a quote with un-suppliable products should be blocking for Sales, confirmation for Planning
        if (!quote.isSuppliable) {
          if (isPlanning) {
            confirmationMessage = notSuppliableMessage + ' Doorgaan?';
          } else {
            disabledMessage = notSuppliableMessage;
          }
        }
        // Energy savings must be always specified before sending (except for non-energy-saving solutions, built into isMissingSavings)
        if (isMissingSavings) disabledMessage = isMissingSavingsMessage;
        else if (isMissingConsumption) confirmationMessage = isMissingConsumptionMessage;
        break;
      case QuoteStatus.Advised:
        // (Un)advising that doesn't matter for cancelled quotes
        if (quote.isCancelled) {
          confirmationMessage = advisedStatusNotNeededMessage;
        }
        break;
      case QuoteStatus.Accepted:
      case QuoteStatus.AcceptedSubjectToFunding:
        // Quotes may never be un-accepted
        if (quote.isAccepted) disabledMessage = noUnacceptingMsg(quote.reference || 'onbekend');
        // Quotes must be sent before they can be accepted
        if (!quote.sentOn) disabledMessage = notSentMessage;
        // Quotes may not be accepted if the deal is already won
        if (quote.deal.isInstalled && quote.deal.status === DealStatus.accepted)
          disabledMessage = alreadyWonMessage;
        break;
      case QuoteStatus.Cancelled:
        // Un-cancelling: shouldn't be needed in a perfect world. For now, allow with a confirmation
        if (quote.isCancelled) confirmationMessage = unCancellationMessage;
        if (quote.jobs.find(job => job.type === 'INSTALLATION')?.completed) {
          disabledMessage =
            'Deze offerte heeft al een uitgevoerde installatieopdracht. Je mag hem niet annuleren.';
        }
        break;
      default:
        break;
    }

    const disabled = !!disabledMessage;

    // Also validate based on the status of the installer (the readOnly prop will be passed in if)
    // If the quote is read-only because of the status of the installer, some operations are still allowed
    const isAllowedReadOnlyOp =
      quoteButton.status === QuoteStatus.Cancelled || quoteButton.status === QuoteStatus.Advised;

    const handleButtonClick = disabled
      ? () => window.alert(disabledMessage)
      : () => {
          // Handle quote status validation
          if (confirmationMessage && !window.confirm(confirmationMessage)) {
            return;
          }

          // Handle installer status validation
          if (isReadOnly && !isAllowedReadOnlyOp) {
            if (!isPlanning) {
              return window.alert(installerInactiveMessage + ' Vraag eventueel Planning');
            } else if (!window.confirm(installerInactiveMessage + ' Doorgaan?')) {
              return;
            }
          }

          // Now that we've passed validation, ask for confirmation (if needed):
          // Confirmation can be skipped if the action is not immediately applied, e.g. through a custom modal:
          // - Accepting:  For accepting a quote, a custom modal is shown. Un-accepting a quote is blocked
          // - Cancelling: Cancelling is also handled with a custom modal too
          // - Finalizing: For toggling it on, a custom modal is shown. Un-finalizing is already validated up top
          const skipConfirmation =
            quoteButton.status === QuoteStatus.Accepted ||
            quoteButton.status === QuoteStatus.Cancelled ||
            quoteButton.status === QuoteStatus.Final;

          const handleConfirm = () => {
            const emptyCancellation = { note: '', reason: '', party: '' };
            switch (quoteButton.status) {
              case QuoteStatus.Sent:
                // Don't ask for recalculation if you're sending the quote directly after exporting it (±3 mins)
                if (isManualCalculation || isMaxThreeMinsOld) {
                  handleClick(mutation, quote, null, quoteButton.status, undefined, false);
                } else setUpdateCalculationsModal(true);
                break;

              case QuoteStatus.Cancelled:
                if (!quote.isCancelled) setCancelQuoteModal(true);
                else handleClick(mutation, quote, null, quoteButton.status, emptyCancellation);
                break;

              case QuoteStatus.Final:
                if (!quote.final) setFinalizationModal(true);
                else handleClick(mutation, quote, advice, quoteButton.status);
                break;

              case QuoteStatus.Advised:
                handleClick(mutation, quote, advice, quoteButton.status);
                break;

              case QuoteStatus.Accepted:
                setAcceptQuoteModal(true);
                break;

              case QuoteStatus.AcceptedSubjectToFunding:
                if (!quote.acceptedSubjectToFunding) setAcceptQuoteModal(true);
                else handleClick(updateQuote, quote, null, quoteButton.status);
                break;

              default:
                // This is the "default" case, all cases should've been handled already
                alert(`Actie "${quoteButton.status}" niet ondersteund, neem contact op met DEV!`);
            }
          };

          if (
            skipConfirmation ||
            window.confirm(`Weet je zeker dat je ${quoteButton.label} wilt aanpassen?`)
          ) {
            handleConfirm();
          }
        };

    return (
      <>
        <StatusButton
          disabled={disabled}
          title={
            isMissingSavings
              ? isMissingSavingsMessage
              : !quote.isSuppliable
              ? notSuppliableMessage
              : quoteButton.label
          }
          color={themify(isActive ? quoteButton.color : 'gray')}
          isActive={isActive}
          mobile={mobile}
          noPointerEvents={quote.isSent && quoteButton.status === 'sent'}
          onClick={handleButtonClick}
        >
          <StatusIcon
            iconColor={themify(isActive ? quoteButton.color : 'gray')}
            style={{ margin: 0 }}
          >
            <Icon icon={quoteButton.icon} solid fill="white" strokeWidth={2} />
          </StatusIcon>
          <StatusLabel mobile={mobile}>{quoteButton.label}</StatusLabel>
        </StatusButton>
        {quoteButton.status === QuoteStatus.Accepted && (
          <AcceptQuoteModal
            quote={quote}
            isOpen={acceptQuoteModal}
            closeModal={() => setAcceptQuoteModal(false)}
            mobile={mobile}
            isGVAHouse={!!sendQuoteBlocks?.house.isGVAHouse}
          />
        )}
        {quoteButton.status === QuoteStatus.Cancelled && (
          <CancelQuoteModal
            isOpen={cancelQuoteModal}
            closeModal={() => setCancelQuoteModal(false)}
            mobile={mobile}
            loading={submissionLoading}
            submit={(cancellationInformation: CancellationInformation) =>
              handleClick(mutation, quote, null, quoteButton.status, cancellationInformation)
            }
            isOnlyVisibleQuoteOnDeal={isOnlyVisibleQuoteOnDeal}
          />
        )}
        {quoteButton.status === QuoteStatus.Final && (
          <FinalizationModal
            isOpen={finalizationModal}
            closeModal={() => setFinalizationModal(false)}
            mobile={mobile}
            loading={submissionLoading}
            quoteId={quote.id}
            submit={finalizationInfo =>
              handleClick(
                mutation,
                quote,
                null,
                quoteButton.status,
                undefined,
                undefined,
                finalizationInfo,
              )
            }
          />
        )}
        {quoteButton.status === 'sent' && !isManualCalculation && (
          <UpdateCalculationsModal
            isOpen={updateCalculationsModal}
            closeModal={() => setUpdateCalculationsModal(false)}
            mobile={mobile}
            submit={
              (updateCalculations: boolean) =>
                handleClick(
                  mutation,
                  quote,
                  null,
                  quoteButton.status,
                  undefined,
                  updateCalculations,
                ) // This is a shitshow, needs refactor. +1
            }
          />
        )}
      </>
    );
  };

  return (
    <>
      <Flex
        flexWrap={mobile ? 'wrap' : 'nowrap'}
        justifyContent="space-between"
        alignItems="center"
        mx={-1}
      >
        {quoteActions.map((quoteButton, index) => {
          let isActive = false;
          activeStatuses.forEach(activeStatus => {
            if (quoteButton.label === activeStatus.label) isActive = true;
          });
          return (
            quoteButton.status !== 'expired' && (
              <Box key={`status-${index}`} width={1} px={1}>
                {/*
                  This is so old now it really needs updating. Accept quote is
                  no longer triggered via this spaghettiness, it's triggered in
                  the AcceptQuoteModal
                 */}
                {quoteButton.mutation ? (
                  <Mutation
                    mutation={quoteButton.mutation}
                    onCompleted={() => {
                      setAcceptQuoteModal(false);
                      setSubmissionLoading(false);
                      setCancelQuoteModal(false);
                      setUpdateCalculationsModal(false);
                      setFinalizationModal(false);
                    }}
                  >
                    {(mutation: any) => renderButton(isActive, quoteButton, advice, mutation)}
                  </Mutation>
                ) : (
                  renderButton(isActive, quoteButton, advice, toggleAdvisedQuote)
                )}
              </Box>
            )
          );
        })}
      </Flex>
      {quote.cancellationInformation && (
        <Red>
          <Flex alignItems="flex-end" flexDirection="column" pt={2}>
            <Box>
              <Medium>
                {
                  cancellationReasons.find(r => r.value === quote.cancellationInformation?.reason)
                    ?.label
                }
              </Medium>
            </Box>
            <Box>
              <Small>{quote.cancellationInformation.note}</Small>
            </Box>
          </Flex>
        </Red>
      )}
    </>
  );
};

export default QuoteStatusButtons;
