/* eslint-disable prettier/prettier */
import { Gray, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table<{ $whiteSpace?: string }>`
  border-collapse: collapse;
  margin-bottom: 1em;
  white-space: ${x => x.$whiteSpace || 'nowrap'};

  th {
    background-color: ${x => x.theme.colors.green};
    color: white;
    font-weight: bold;
    font-size: ${x => x.theme.type.scale[5]};
    padding: 0 2px;
  }

  th,
  td {
    width: 60px;
    height: 30px;
    border: 1px solid #ccc;
  }
`;

export const intakeGuide = (
  <>
    <Smaller>
      <Gray>Laatst gewijzigd: 22-09-2021</Gray>
    </Smaller>
    <h3>Opnamegids</h3>
    <p>
      De opnametool is onze manier om een woning volledig in kaart te brengen, om gepaste offertes
      en advies op te stellen en realistische berekeningen te maken. Je moet in eerste instantie een
      opname starten voor een woning door te een adviseur te kiezen die de verantwoordelijkheid
      draagt voor de informatie die eruit komt. Zodra je een opname start voor een woning, kan de
      klant via zijn/haar account geen aanpassingen aan de woning meer maken. Op de overzichtspagina
      zie je links relevante informatie en een overzicht van de notities en foto’s die tijdens de
      opname zijn gemaakt. Rechts zie je de huidige voortgang per onderdeel. Elk onderdeel bevat een
      set vragen over de eigenschappen van de woning en een set vragen over de huidige situatie rond
      dat onderdeel van de woning. De woningeigenschappen zijn vrijwel onveranderbaar en hebben
      mogelijk een invloed op berekeningen, de situatie helpt bij het adviseren en bepaalt of iets
      wel of niet van toepassing is, of misschien meerwerk vraagt.
    </p>
    <p>
      Per opname-‘hoofdstuk’ kan de opnamespecialist ervoor kiezen alleen de basisset vragen te
      beantwoorden, of de detailvragen mee te nemen, door het schuifje bovenin om te zetten. Op dit
      moment is er vrijwel geen onderscheid tussen de basisset en detailset, dus zul je weinig
      merken. Het idee is dat er bij elke woningopname de basisset altijd voltooid wordt, maar de
      detailset alleen bij verwachting dat dat specifiek onderdeel verduurzaamd gaat worden.
    </p>
    <p>
      De volgorde van hoofdstukken en vragen is ingericht om zowel de opname logisch in te richten,
      maar ook om consequenties van een antwoord direct door te voeren op vervolgvragen. De
      individuele vragen zijn namelijk conditioneel. Als je bijvoorbeeld aangeeft dat de woning geen
      onderdeel is van een VVE, hoef je ook niet te vragen of er toestemming is om iets te
      installeren. Of als je aangeeft dat er geen kruipruimte is, hoef je ook niet te beantwoorden
      of deze vochtig is. Scheelt tijd en verwarring, mits het in de juiste volgorde wordt ingevuld.
    </p>
    <h4 id="rc">RC-waardes</h4>
    <p>
      Een RC-waarde geeft het isolerend vermogen van een materiaal aan. Hoger is beter.
      <br />
      Het wordt gemeten in m<sup>2</sup>K/W. Ofwel, de warmtetransmissie in Watt per vierkante
      meter, voor het temperatuurverschil in graden Kelvin tussen binnen en buiten de schil.
      <br />
      Ter referentie zijn hieronder veelvoorkomende gevallen te vinden.
    </p>
    {/* Source: https://docs.google.com/spreadsheets/d/1KK46uKp3fJUOJv6Sy3BwOEZrDOhiIzLTbugZbXKd6LA/edit#gid=0 */}
    {/* converted with https://tableizer.journalistopia.com/tableizer.php */}
    <h5>Standaard RC-waarden</h5>
    <StyledTable>
      <tbody>
        <tr>
          <td>Spouwmuur</td>
          <td>0,36</td>
        </tr>
        <tr>
          <td>Steensmuur</td>
          <td>0,43</td>
        </tr>
        <tr>
          <td>Houtenvloer</td>
          <td>0,22</td>
        </tr>
        <tr>
          <td>Betonvloer</td>
          <td>0,15</td>
        </tr>
        <tr>
          <td>Schuin dak </td>
          <td>0,22</td>
        </tr>
        <tr>
          <td>Platdak</td>
          <td>0,15</td>
        </tr>
      </tbody>
    </StyledTable>

    <h5>Tempex</h5>
    <StyledTable>
      <thead>
        <tr>
          <th>Dikte (mm)</th>
          <th>RC-waarde</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>20</td>
          <td>0,55</td>
        </tr>
        <tr>
          <td>40</td>
          <td>1,1</td>
        </tr>
        <tr>
          <td>50</td>
          <td>1,35</td>
        </tr>
        <tr>
          <td>60</td>
          <td>1,65</td>
        </tr>
        <tr>
          <td>80</td>
          <td>2,2</td>
        </tr>
        <tr>
          <td>100</td>
          <td>2,75</td>
        </tr>
      </tbody>
    </StyledTable>

    <h5>Glaswol</h5>
    <StyledTable>
      <thead>
        <tr>
          <th>Dikte (mm) </th>
          <th>Gevel</th>
          <th>Dak</th>
          <th>Vloer</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>40</td>
          <td>1,34</td>
          <td>1,2</td>
          <td>1,13</td>
        </tr>
        <tr>
          <td>50</td>
          <td>1,58</td>
          <td>1,44</td>
          <td>1,37</td>
        </tr>
        <tr>
          <td>60</td>
          <td>1,82</td>
          <td>1,68</td>
          <td>1,61</td>
        </tr>
        <tr>
          <td>70</td>
          <td>2,07</td>
          <td>1,93</td>
          <td>1,86</td>
        </tr>
        <tr>
          <td>80</td>
          <td>2,31</td>
          <td>2,17</td>
          <td>2,1</td>
        </tr>
        <tr>
          <td>90</td>
          <td>2,56</td>
          <td>2,42</td>
          <td>2,35</td>
        </tr>
        <tr>
          <td>100</td>
          <td>2,8</td>
          <td>2,66</td>
          <td>2,59</td>
        </tr>
        <tr>
          <td>110</td>
          <td>3,04</td>
          <td>2,9</td>
          <td>2,83</td>
        </tr>
        <tr>
          <td>120</td>
          <td>3,29</td>
          <td>3,15</td>
          <td>3,08</td>
        </tr>
        <tr>
          <td>130</td>
          <td>3,53</td>
          <td>3,39</td>
          <td>3,32</td>
        </tr>
        <tr>
          <td>140</td>
          <td>3,77</td>
          <td>3,63</td>
          <td>3,56</td>
        </tr>
        <tr>
          <td>150</td>
          <td>4,02</td>
          <td>3,88</td>
          <td>3,81</td>
        </tr>
        <tr>
          <td>160</td>
          <td>4,26</td>
          <td>4,12</td>
          <td>4,05</td>
        </tr>
        <tr>
          <td>170</td>
          <td>4,51</td>
          <td>4,37</td>
          <td>4,3</td>
        </tr>
        <tr>
          <td>180</td>
          <td>4,75</td>
          <td>4,61</td>
          <td>4,54</td>
        </tr>
        <tr>
          <td>190</td>
          <td>4,99</td>
          <td>4,85</td>
          <td>4,78</td>
        </tr>
        <tr>
          <td>200</td>
          <td>5,24</td>
          <td>5,1</td>
          <td>5,03</td>
        </tr>
        <tr>
          <td>210</td>
          <td>5,48</td>
          <td>5,34</td>
          <td>5,27</td>
        </tr>
        <tr>
          <td>220</td>
          <td>5,73</td>
          <td>5,59</td>
          <td>5,52</td>
        </tr>
        <tr>
          <td>230</td>
          <td>5,97</td>
          <td>5,83</td>
          <td>5,76</td>
        </tr>
      </tbody>
    </StyledTable>

    <h5>Steenwol inblaaswol</h5>
    <StyledTable>
      <thead>
        <tr>
          <th>Dikte (mm) </th>
          <th>RC-waarde</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>10</td>
          <td>0,58</td>
        </tr>
        <tr>
          <td>20</td>
          <td>0,8</td>
        </tr>
        <tr>
          <td>30</td>
          <td>1,03</td>
        </tr>
        <tr>
          <td>40</td>
          <td>1,25</td>
        </tr>
        <tr>
          <td>50</td>
          <td>1,47</td>
        </tr>
        <tr>
          <td>60</td>
          <td>1,69</td>
        </tr>
        <tr>
          <td>70</td>
          <td>1,92</td>
        </tr>
        <tr>
          <td>80</td>
          <td>2,14</td>
        </tr>
        <tr>
          <td>90</td>
          <td>2,36</td>
        </tr>
        <tr>
          <td>100</td>
          <td>2,58</td>
        </tr>
        <tr>
          <td>110</td>
          <td>2,8</td>
        </tr>
        <tr>
          <td>120</td>
          <td>3,03</td>
        </tr>
        <tr>
          <td>130</td>
          <td>3,25</td>
        </tr>
        <tr>
          <td>140</td>
          <td>3,47</td>
        </tr>
        <tr>
          <td>150</td>
          <td>3,69</td>
        </tr>
        <tr>
          <td>160</td>
          <td>3,92</td>
        </tr>
        <tr>
          <td>170</td>
          <td>4,14</td>
        </tr>
        <tr>
          <td>180</td>
          <td>4,36</td>
        </tr>
        <tr>
          <td>190</td>
          <td>4,58</td>
        </tr>
        <tr>
          <td>200</td>
          <td>4,8</td>
        </tr>
        <tr>
          <td>210</td>
          <td>5,03</td>
        </tr>
        <tr>
          <td>220</td>
          <td>5,25</td>
        </tr>
        <tr>
          <td>230</td>
          <td>5,47</td>
        </tr>
      </tbody>
    </StyledTable>

    <h5>Steenwolplaat</h5>
    <StyledTable>
      <thead>
        <tr>
          <th>Dikte (mm) </th>
          <th>RC (m2K/W)</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>&nbsp;</td>
          <td>Gevel</td>
          <td>Vloer</td>
          <td>Plat/hellend dak</td>
        </tr>
        <tr>
          <td>10</td>
          <td>0,64</td>
          <td>0,43</td>
          <td>0,46</td>
        </tr>
        <tr>
          <td>20</td>
          <td>0,92</td>
          <td>0,71</td>
          <td>0,69</td>
        </tr>
        <tr>
          <td>30</td>
          <td>1,19</td>
          <td>0,98</td>
          <td>0,93</td>
        </tr>
        <tr>
          <td>40</td>
          <td>1,47</td>
          <td>1,26</td>
          <td>1,17</td>
        </tr>
        <tr>
          <td>50</td>
          <td>1,75</td>
          <td>1,54</td>
          <td>1,41</td>
        </tr>
        <tr>
          <td>60</td>
          <td>2,03</td>
          <td>1,82</td>
          <td>1,64</td>
        </tr>
        <tr>
          <td>70</td>
          <td>2,3</td>
          <td>2,09</td>
          <td>1,88</td>
        </tr>
        <tr>
          <td>80</td>
          <td>2,58</td>
          <td>2,37</td>
          <td>2,12</td>
        </tr>
        <tr>
          <td>90</td>
          <td>2,86</td>
          <td>2,65</td>
          <td>2,36</td>
        </tr>
        <tr>
          <td>100</td>
          <td>3,14</td>
          <td>2,93</td>
          <td>2,59</td>
        </tr>
        <tr>
          <td>110</td>
          <td>3,42</td>
          <td>3,21</td>
          <td>2,83</td>
        </tr>
        <tr>
          <td>120</td>
          <td>3,69</td>
          <td>3,48</td>
          <td>3,07</td>
        </tr>
        <tr>
          <td>130</td>
          <td>3,97</td>
          <td>3,76</td>
          <td>3,31</td>
        </tr>
        <tr>
          <td>140</td>
          <td>4,25</td>
          <td>4,04</td>
          <td>3,54</td>
        </tr>
        <tr>
          <td>150</td>
          <td>4,53</td>
          <td>4,32</td>
          <td>3,78</td>
        </tr>
        <tr>
          <td>160</td>
          <td>4,8</td>
          <td>4,59</td>
          <td>4,02</td>
        </tr>
        <tr>
          <td>170</td>
          <td>5,08</td>
          <td>4,87</td>
          <td>4,26</td>
        </tr>
        <tr>
          <td>180</td>
          <td>5,36</td>
          <td>5,15</td>
          <td>4,49</td>
        </tr>
        <tr>
          <td>190</td>
          <td>5,64</td>
          <td>5,43</td>
          <td>4,73</td>
        </tr>
        <tr>
          <td>200</td>
          <td>5,92</td>
          <td>5,71</td>
          <td>4,97</td>
        </tr>
        <tr>
          <td>210</td>
          <td>6,19</td>
          <td>5,98</td>
          <td>5,21</td>
        </tr>
        <tr>
          <td>220</td>
          <td>6,47</td>
          <td>6,26</td>
          <td>5,44</td>
        </tr>
        <tr>
          <td>230</td>
          <td>6,75</td>
          <td>6,54</td>
          <td>5,68</td>
        </tr>
      </tbody>
    </StyledTable>
    <h5>HR++ Parels</h5>
    <StyledTable>
      <thead>
        <tr>
          <th>Isolatiedikte (mm) </th>
          <th>RC (m2K/W)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>&nbsp;</td>
          <td>Gevel</td>
        </tr>
        <tr>
          <td>40</td>
          <td>1,34</td>
        </tr>
        <tr>
          <td>50</td>
          <td>1,58</td>
        </tr>
        <tr>
          <td>60</td>
          <td>1,82</td>
        </tr>
        <tr>
          <td>70</td>
          <td>2,07</td>
        </tr>
        <tr>
          <td>80</td>
          <td>2,31</td>
        </tr>
        <tr>
          <td>90</td>
          <td>2,56</td>
        </tr>
        <tr>
          <td>100</td>
          <td>2,8</td>
        </tr>
        <tr>
          <td>110</td>
          <td>3,04</td>
        </tr>
        <tr>
          <td>120</td>
          <td>3,29</td>
        </tr>
        <tr>
          <td>130</td>
          <td>3,53</td>
        </tr>
        <tr>
          <td>140</td>
          <td>3,77</td>
        </tr>
        <tr>
          <td>150</td>
          <td>4,02</td>
        </tr>
        <tr>
          <td>160</td>
          <td>4,26</td>
        </tr>
        <tr>
          <td>170</td>
          <td>4,51</td>
        </tr>
        <tr>
          <td>180</td>
          <td>4,75</td>
        </tr>
        <tr>
          <td>190</td>
          <td>4,99</td>
        </tr>
        <tr>
          <td>200</td>
          <td>5,24</td>
        </tr>
        <tr>
          <td>210</td>
          <td>5,48</td>
        </tr>
        <tr>
          <td>220</td>
          <td>5,73</td>
        </tr>
        <tr>
          <td>230</td>
          <td>5,97</td>
        </tr>
      </tbody>
    </StyledTable>
    <h5>Minimale RC-waarden</h5>
    {/* // maybe flip it? */}
    {/* <StyledTable>
      <thead><tr><th></th><th>&nbsp;</th><th>1965</th><th>1975</th><th>1979</th><th>1982</th><th>1987</th><th>1990</th><th>1992</th><th>2012</th><th>2015</th><th>2018</th><th>Passiefwon.</th><th>&nbsp;</th><th>&nbsp;</th></tr></thead>
      <tbody>
        <tr><td>wet</td><td>&nbsp;</td><td>voorschriften</td><td>&nbsp;</td><td>modelbouwverordering</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>bouwbesluit</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>ingrijpende verbouwing bouwbesluit</td><td>gewone verbouwing bouwbesluit</td></tr>
        <tr><td>RC Vloer</td><td>&nbsp;</td><td>0,17</td><td>0,26</td><td>0,52</td><td>1,3</td><td>1,3</td><td>1,3</td><td>2,5</td><td>3,5</td><td>3,5</td><td>&nbsp;</td><td>5</td><td>3,5</td><td>2,5</td></tr>
        <tr><td>RC Gevel</td><td>&nbsp;</td><td>0,43</td><td>0,69</td><td>1,29</td><td>1,3</td><td>2</td><td>2,5</td><td>2,5</td><td>3,5</td><td>4,5</td><td>&nbsp;</td><td>8</td><td>4,5</td><td>1,3</td></tr>
        <tr><td>RC Dak</td><td>&nbsp;</td><td>0,86</td><td>1,03</td><td>1,29</td><td>1,3</td><td>2</td><td>2,5</td><td>2,5</td><td>3,5</td><td>6</td><td>&nbsp;</td><td>10</td><td>6</td><td>2</td></tr>
        <tr><td>U Ramen</td><td>&nbsp;</td><td>5,8</td><td>5,8</td><td>2,8</td><td>2,8</td><td>2,8</td><td>2,8</td><td>2,8</td><td>&nbsp;</td><td>1,65</td><td>&nbsp;</td><td>0,8</td><td>1,65</td><td>&nbsp;</td></tr>
        <tr><td>RC Bergingscheiding</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>4,5</td><td>&nbsp;</td><td>&nbsp;</td><td>4,5</td><td></td></tr>
      </tbody>
    </StyledTable> */}
    <StyledTable $whiteSpace="normal">
      <thead>
        <tr>
          <th></th>
          <th>Wet</th>
          <th>Vloer</th>
          <th>Gevel</th>
          <th>Dak</th>
          <th>Ramen (U)</th>
          <th>Berging scheiding</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>1965</td>
          <td>
            <Small>Voorschriften</Small>
          </td>
          <td>0,17</td>
          <td>0,43</td>
          <td>0,86</td>
          <td>5,8</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>1975</td>
          <td>&nbsp;</td>
          <td>0,26</td>
          <td>0,69</td>
          <td>1,03</td>
          <td>5,8</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>1979</td>
          <td>
            <Small>Modelbouw verordering</Small>
          </td>
          <td>0,52</td>
          <td>1,29</td>
          <td>1,29</td>
          <td>2,8</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>1982</td>
          <td>&nbsp;</td>
          <td>1,3</td>
          <td>1,3</td>
          <td>1,3</td>
          <td>2,8</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>1987</td>
          <td>&nbsp;</td>
          <td>1,3</td>
          <td>2</td>
          <td>2</td>
          <td>2,8</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>1990</td>
          <td>&nbsp;</td>
          <td>1,3</td>
          <td>2,5</td>
          <td>2,5</td>
          <td>2,8</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>1992</td>
          <td>
            <Small>Bouwbesluit</Small>
          </td>
          <td>2,5</td>
          <td>2,5</td>
          <td>2,5</td>
          <td>2,8</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>2012</td>
          <td>&nbsp;</td>
          <td>3,5</td>
          <td>3,5</td>
          <td>3,5</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>2015</td>
          <td>&nbsp;</td>
          <td>3,5</td>
          <td>4,5</td>
          <td>6</td>
          <td>1,65</td>
          <td>4,5</td>
        </tr>
        <tr>
          <td>2018</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>
            <Small>Passiefwon.</Small>
          </td>
          <td>&nbsp;</td>
          <td>5</td>
          <td>8</td>
          <td>10</td>
          <td>0,8</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>
            <Small>Ingrijpende verbouwing bouwbesluit</Small>
          </td>
          <td>3,5</td>
          <td>4,5</td>
          <td>6</td>
          <td>1,65</td>
          <td>4,5</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>
            <Small>Gewone verbouwing bouwbesluit</Small>
          </td>
          <td>2,5</td>
          <td>1,3</td>
          <td>2</td>
          <td>&nbsp;</td>
          <td></td>
        </tr>
      </tbody>
    </StyledTable>
  </>
);
