import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useIsInitializing } from '../../hooks/useIsInitializing';
import { useMe } from '../../hooks/useMe';
import PageLoading from '../PageLoading';
import AppliedRoute, { AppliedRouteProps } from './AppliedRoute';

export const TaskRoute: React.FC<AppliedRouteProps> = ({ component: Component, exact, path }) => {
  const { isInitializing } = useIsInitializing();
  const { me } = useMe();
  document.body.classList.add('with-scrollbar');
  return (
    <Route
      exact={exact}
      path={path}
      render={renderProps => {
        return isInitializing ? (
          <PageLoading />
        ) : me ? (
          <AppliedRoute
            component={Component}
            {...renderProps}
            location={{ ...renderProps.location, state: { from: renderProps.location } }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: renderProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default TaskRoute;
