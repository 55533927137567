import { useLazyQuery } from '@apollo/client';
import { Icon, Image, Placeholder } from '@energiebespaarders/symbols';
import { Center, Color, Medium } from '@energiebespaarders/symbols/helpers';
import { Alert } from '@energiebespaarders/symbols/icons/solid';
import React, { useEffect } from 'react';
import { fileUrl } from '../config';
import { GET_INSTALLER_FILES, GET_SUPPLIER_FILES } from '../queries/files';
import {
  getInstallerFiles,
  getInstallerFilesVariables,
} from '../types/generated/getInstallerFiles';
import { getSupplierFiles, getSupplierFilesVariables } from '../types/generated/getSupplierFiles';

interface CompanyLogoProps {
  companyId: string;
  companyType: 'supplier' | 'installer';
  hideEmpty?: boolean;
  size?: 'xs' | 'ss' | 'sm' | 'md' | 'lg' | 'xl';
  maxHeight?: string | number;
  maxWidth?: string | number;
}

const CompanyLogo: React.FC<CompanyLogoProps> = ({
  companyId,
  companyType,
  hideEmpty,
  maxHeight = 90,
  maxWidth = 90,
}) => {
  const [fetchSupplierFiles, { loading: sLoading, data: sData, error: sError }] = useLazyQuery<
    getSupplierFiles,
    getSupplierFilesVariables
  >(GET_SUPPLIER_FILES, {
    variables: { id: companyId },
  });

  const [fetchInstallerFiles, { loading: iLoading, data: iData, error: iError }] = useLazyQuery<
    getInstallerFiles,
    getInstallerFilesVariables
  >(GET_INSTALLER_FILES, {
    variables: { id: companyId },
  });

  useEffect(() => {
    if (companyType === 'supplier') {
      fetchSupplierFiles();
    } else {
      fetchInstallerFiles();
    }
  }, [companyType, fetchSupplierFiles, fetchInstallerFiles]);

  const loading = companyType === 'supplier' ? sLoading : iLoading;
  const data = companyType === 'supplier' ? sData : iData;
  const error = companyType === 'supplier' ? sError : iError;
  const companyName =
    companyType === 'supplier' ? sData?.supplierById?.name : iData?.installerById?.name;
  const files =
    companyType === 'supplier'
      ? (data as getSupplierFiles)?.supplierById?.files || []
      : (data as getInstallerFiles)?.installerById?.files || [];

  if (loading || error || !data) return <Placeholder error={error} />;

  const logoFile =
    files.length > 0
      ? files.find(file => [companyId, 'logo'].every(tag => file.metadata.tags.indexOf(tag) > -1))
      : undefined;
  const logoSrc = logoFile ? `${fileUrl}/${logoFile?.id}.${logoFile?.extension}` : '';
  return logoSrc ? (
    <Image src={logoSrc} alt={companyName || ''} style={{ width: 'auto', maxHeight, maxWidth }} />
  ) : !hideEmpty ? (
    <Center block>
      <Icon icon={Alert} solid fill="gray" width="16px" />
      <br />{' '}
      <Medium>
        <Color c="gray">GEEN LOGO</Color>
      </Medium>
    </Center>
  ) : null;
};

export default CompanyLogo;
