import { Box, Card, Flex, Icon, Separator, Tooltip } from '@energiebespaarders/symbols';
import { Center, Medium, Smaller } from '@energiebespaarders/symbols/helpers';
import { Alert, Time } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { migrationDate } from '../../config';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import { withSpacing } from '../../lib/hoc/withSpacing';
import { search_search } from '../../types/generated/search';
import { Ribbon } from '../HouseCardRibbons';
import { getResultStyle } from './SearchResults';

interface StyledResultProps {
  $barColor: string;
}

export const StyledResult = styled(Card)<StyledResultProps>`
  position: relative;
  line-height: 1.2;
  cursor: pointer;
  transition: all 0.1s ${x => x.theme.curves.sharp};

  &:hover {
    background: ${x => x.theme.colors.grayLighter};
    color: ${x => x.theme.colors.grayBlack};
    transform: translateY(-2px);
  }

  &:active {
    background: ${x => x.theme.colors.grayLight};
    color: ${x => x.theme.colors.grayBlack};
    transform: translateY(0);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${x => x.theme.spacer};
    background: ${x => x.$barColor};
    border-radius: 3px 0 0 3px;
  }
`;

const ResultSubtitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  font-size: ${x => x.theme.type.scale[5]};
`;

const ResultId = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: ${x => x.theme.type.scale[7]};
`;

const StyledLink = styled(Link)`
  color: ${x => x.theme.colors.grayBlack};
`;

const StyledTimestamp = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: ${x => x.theme.type.scale[7]};
`;

interface SearchResultProps {
  className?: string;
  showIds?: boolean;
  index?: number;
  resultData: search_search;
  setActiveHouseId: Dispatch<SetStateAction<string>>;
  toggleSearch: () => void;
}

const SearchResult: React.FC<SearchResultProps> = ({
  className,
  showIds,
  resultData,
  toggleSearch,
}) => {
  const { setActiveHouseId } = useActiveHouseId();
  const houseId = resultData.id;
  let link = `/house/${houseId}`;
  const {
    __typename,
    id,
    searchResult: { title, subtitle, created, extraInfo },
  } = resultData;
  // NOTE: Anything created before May 6th 2019 is assumed to be migrated!
  const legacy = created > 0 && dayjs(created).isBefore(dayjs(migrationDate));

  // Render search result card
  const renderResult = useCallback(
    (link: string, houseId?: string) => (
      <StyledLink
        to={link}
        onClick={() => {
          if (houseId) setActiveHouseId(houseId);
          toggleSearch();
        }}
      >
        <StyledResult $barColor={getResultStyle(__typename).color} className={className} p={2}>
          {Boolean(legacy) && (
            <Ribbon bgColor="orange" right={5} width="24px">
              <Icon icon={Time} solid fill="white" width="0.75em" />
            </Ribbon>
          )}
          <Flex alignItems="center" mx={-1}>
            <Box width={1 / 10} mx={1}>
              <Center block>
                <Icon
                  fill={getResultStyle(__typename).color}
                  height="24px"
                  icon={getResultStyle(__typename).icon}
                  inline
                  solid
                  width="24px"
                />
              </Center>
            </Box>
            <Box width={8 / 10} mx={1}>
              {showIds && <ResultId>{id}</ResultId>}
              <Medium>{title}</Medium>
              {!houseId && __typename === 'Customer' && (
                <Tooltip content="Geen gekoppelde woning gevonden" bgColor="red">
                  <Icon icon={Alert} solid fill="red" verticalAlign="baseline" mx={1} />
                </Tooltip>
              )}
              <ResultSubtitle>{subtitle}</ResultSubtitle>
              {created && (
                <StyledTimestamp>
                  Aangemaakt op {dayjs(created).format('DD MMMM YYYY').toLowerCase()}
                </StyledTimestamp>
              )}
              <Separator mt={1} />
              <Smaller>{extraInfo || '-'}</Smaller>
            </Box>
          </Flex>
        </StyledResult>
      </StyledLink>
    ),
    [
      __typename,
      className,
      created,
      extraInfo,
      id,
      legacy,
      setActiveHouseId,
      showIds,
      subtitle,
      title,
      toggleSearch,
    ],
  );

  // Find link parameters for search card
  if (__typename === 'Customer') {
    const houseId = resultData.searchResult.houseId || '';
    link = `/house/${houseId}/customer`;
    return renderResult(link, houseId);
  } else if (__typename === 'Operator') {
    const operatorId = resultData.id;
    link = `/users/operators/${operatorId}`;
    return renderResult(link);
  } else if (__typename === 'Employee') {
    const employeeId = resultData.id;
    link = `/contact/${employeeId}`;
    return renderResult(link);
  } else if (__typename === 'Supplier') {
    const supplierId = resultData.id;
    link = `/supplier/${supplierId}`;
    return renderResult(link);
  } else if (__typename === 'Installer') {
    const installerId = resultData.id;
    link = `/installer/${installerId}`;
    return renderResult(link);
  } else if (__typename === 'PartnerAgent') {
    const PartnerAgentId = resultData.id;
    link = `/partner-agent/${PartnerAgentId}`;
    return renderResult(link);
  } else {
    return renderResult(link, houseId);
  }
};

export default withSpacing(SearchResult);
