import { gql } from '@apollo/client';
import { Icon, Table, Tooltip } from '@energiebespaarders/symbols';
import {
  Block,
  BlockAlt,
  CheckCircle,
  Mailbox,
  Warning,
} from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { getEmailsEventsByEmail_getEmailsEventsByEmail } from '../../types/generated/getEmailsEventsByEmail';
import { MailjetTemplateDescriptor } from '../../types/graphql-global-types';

export const EMAIL_HISTORY_BY_EMAIL = gql`
  query getEmailsEventsByEmail($email: String!) {
    getEmailsEventsByEmail(email: $email) {
      id
      messageId
      template
      sent
      open
      click
      bounce
      spam
      blocked
      unsub
    }
  }
`;

// Hello. You might be here because someone added a new email template,
// which then got added to the schema, which then needs to be added here
// because of typegenpsrc/components/installatron/Deal/SetOnHoldModal.tsx
// If you don't feel like it, feel free to make this a Partial<>
// This only concerns customer targeted emails anyways, didn't bother with translating the rest properly
const TemplateTranslations: Record<MailjetTemplateDescriptor, string> = {
  AfterSustainabilized: 'Na verduurzaming',
  AfterVisitAdvice: 'Na huisbezoek: advies',
  AfterVisitNoSolutions: 'Na huisbezoek: advies',
  AfterVisitQuotes: 'Na huisbezoek: offertes opmaken',
  AppointmentConfirmationInstallationFloorInsulation:
    'Afspraakbevestiging installatie vloerisolatie',
  AppointmentConfirmationInstallationGlazing: 'Afspraakbevestiging installatie isolatieglas',
  AppointmentConfirmationInstallationHeatpump: 'Afspraakbevestiging installatie warmtepomp',
  AppointmentConfirmationInstallationOtherProducts:
    'Afspraakbevestiging installatie overige producten',
  AppointmentConfirmationInstallationPv: 'Afspraakbevestiging installatie zonnepanelen',
  AppointmentConfirmationInstallationWallInsulation: 'Afspraakbevestiging installatie spouwmuur',
  AppointmentConfirmationPtc: 'Afspraakbevestiging schouw',
  AppointmentReminderInstallation: 'Afspraakherinnering installatie',
  AppointmentReminderPtc: 'Afspraakherinnering schouw',
  AppointmentReminderPtcCustomerPrep: 'Afspraakherinnering schouw prep',
  BespaarbuddiesCongratulations: 'Bespaarbuddies felicitatie',
  BespaarbuddiesCongratulationsNoKickBack: 'Bespaarbuddies felicitatie no kick back',
  BespaarbuddiesIntro: 'Bespaarbuddies intro',
  BespaarbuddiesIntroNoKickback: 'Bespaarbuddies intro no kickback',
  BespaarbuddiesReminder: 'Bespaarbuddies herinnering',
  BespaarbuddiesReminderNoKickback: 'Bespaarbuddies herinnering no kickback',
  BespaarcheckResults: 'Bespaarcheck results',
  CentralHeatingAfterCare: 'Verwarming after care',
  CheckIfFundingAvailable: 'Check of financiering rond is',
  CongratulationsOnFirstInstallation: 'Felicitatie bij eerste installatie',
  CongratulationsOnMoreInstallations: 'Felicitatie bij meerdere installaties',
  ContactFormCustomer: 'Contact form',
  CustomerHouseVisitReminder: 'Huisbezoek herinnering',
  CustomerMagicLink: 'Magic login link aangevraagd',
  CustomerVirtualHouseVisitReminder: 'Virtueel huisbezoek herinnering',
  DateSelectInstallation: 'Datum selectie installatie',
  DateSelectPtc: 'Datum selectie schouw',
  DealLostDueToAdviser: 'Deal verloren door adviseur',
  EnergyPassport: 'Energiepaspoort klaar',
  FailedToSendEmailAlert: 'Failed to send email alert',
  Feedback: 'Feedback',
  FloorInsulationAftercare: 'Vloerisolatie aftercare',
  GeneralCallConfirmation: 'Bel afspraak bevestiging',
  GeneralCallReminder: 'Bel afspraak herinnering',
  GeneralCheckup: 'Algemene checkup',
  GeneralCloser: 'Checkup na installatie is afgerond',
  GlazingAftercare: 'Isolatieglas aftercare',
  HeatpumpAftercare: 'Warmtepomp aftercare',
  HouseVisitCancellation: 'Huisbezoek geannuleerd',
  HouseVisitConfirmation: 'Huisbezoek bevestiging',
  InsideSalesAppointmentConfirmation: 'BiDS afspraakbevestiging',
  InsideSalesAppointmentReminder: 'BiDS afspraakherinnering',
  InstallerAgentMagicLink: 'Magic login link aangevraagd (IA)',
  InstallerInvoiceReminder: 'Factuur reminder naar installateur',
  InstallerJobPlanningConfirmedWithCustomer: 'Installateur opdracht planning confirmed with',
  InstallerPlanning20PercReminder: 'Installateur planning20 perc herinnering',
  InstallerPlanning50PercReminder: 'Installateur planning50 perc herinnering',
  InstallerPlanningConfirmation: 'Installateur planning bevestiging',
  InstallerPlanningConfirmationToInstaller: 'Installateur planning bevestiging naar installateur',
  InstallerPlanningReminder: 'Installateur planning herinnering',
  InstallerSelected: 'Installateur gekozen (opdracht verstuurd)',
  InternalAnnouncement: 'Interne aankondiging',
  Invoice: 'Factuur verwachtingen algemeen',
  InvoiceBtw: 'Factuur verwachtingen met btw teruggave info',
  InvoiceISDE: 'Factuur verwachtingen met ISDE info',
  JobQuestion: 'Opdracht question',
  JobQuestionToInstaller: 'Opdracht question naar installateur',
  JobSuspendedToDEB: 'Opdracht uitgesteld',
  JobSuspendedByInstallerToInstaller: 'Opdracht uitgesteld bevestiging naar installateur',
  JobSuspendedByDEBToInstaller: 'Opdracht uitgesteld bevestiging naar installateur',
  JobSuspendedToCustomer: 'Opdracht uitgesteld bevestiging naar klant',
  MultiplePlanningProposals: 'Multiple planning proposals',
  NewJobInstallerAccount: 'New opdracht installateur account',
  NoProposalOptionSuitableToCustomer: 'Geen plannings voorstel geschikt',
  NoProposalOptionSuitableToInstaller: 'Geen plannings voorstel geschikt voor installateur',
  PartnerDossierTransferred: 'Partner dossier transferred',
  PartnerInstallationDoneNotification: 'Partner installatie done notification',
  PartnerLabelRegistrationNotification: 'Partner label registration notification',
  PartnerPortalResetPassword: 'Partner portal reset password',
  PartnerPreDossierFinished: 'Partner pre dossier finished',
  PartnerQuoteAcceptedNotification: 'Partner offerte akkoord notification',
  PartnerWelcomeActivate: 'Partner welcome activate',
  PlanningConfirmationReminderToInstaller: 'Planning bevestiging herinnering naar installateur',
  PlanningUpdateRequest: 'Planning update request',
  PlanningUpdateRequestToInstaller: 'Planning update request naar installateur',
  PostPTCExpectations: 'Na schouw verwachtingen',
  PostPtcNoChanges: 'Na schouw geen veranderingen nodig',
  ProposalOptionConfirmedToCustomer: 'Plannings voorstel bevestiging',
  ProposalOptionConfirmedToInstaller: 'Proposal option confirmed naar installateur',
  PTCResultsReminder: 'Schouw results herinnering',
  PvAftercare: 'Zonnepanelen aftercare',
  PvCheckup: 'Zonnepanelen checkup',
  PvSignup: 'Zonnepanelen aanmelden',
  QuoteAccepted: 'Offerte akkoord bevestiging',
  QuoteAcceptedSubjectToFunding: 'Offerte akkoord ovvf bevestiging',
  QuoteExpirationReminder: 'Offerte bijna verlopen',
  QuotePdfs: 'Offerte pdfs',
  QuotesReady: 'Offertes staan klaar',
  RatingsOverviewToInstaller: 'Beoordeling overzicht naar installateur',
  RequestConfirmationGeneral: 'Advies aanvraag bevestiging algemeen',
  RequestConfirmationPartner: 'Advies aanvraag door klant via partner',
  RequestConfirmationPartnerAgent: 'Advies aanvraag partner agent',
  RequestConfirmationSelfServicePlanning: 'Advies aanvraag zelf service',
  RequestConfirmationWithPaymentUrl: 'Advies aanvraag met betaal link',
  RequestQuotesConfirmation: 'Offerte aanvraag bevestiging',
  RequestedSupplementalPackage: 'Extra diensten aanvraag bevestiging',
  RequestEnergyLabelOnly: 'Energie label aanvraag bevestiging',
  RequestLabelConfirmationPartnerAgent: 'Request label bevestiging partner agent',
  RoofInsulationAftercare: 'Dak isolatie aftercare',
  SamenResetPassword: 'Samen reset password',
  ScoreNotificationCSAT: 'Score notification csat',
  SendWoningKeurenLead: 'Send woning keuren lead',
  SimpelSubsidieInfo: 'Simpel subsidie info',
  SimpelSubsidieInstructions: 'Simpel subsidie instructies',
  SinglePlanningProposal: 'Enkele plannings voorstel',
  SustainablyFinanced: 'Duurzaam gefinancierd',
  Unknown: 'Onbekend',
  UpgradeElectricityConnection: 'Aansluiting verzwaren',
  UpgradeElectricityConnectionCheckUp: 'Aansluiting verzwaren check up',
  UpgradeElectricityConnectionModification: 'Aansluiting verzwaren aanpassing',
  VirtualHouseVisitConfirmation: 'Virtueel huisbezoek bevestiging',
  VisitPlanningCSAT: 'Intake gesprek evaluatie',
  WallInsulationAftercare: 'Spouwmuur aftercare',
  [MailjetTemplateDescriptor.PreVisitCallConfirmation]: 'Belafspraak voor huisbezoek bevestiging',
  [MailjetTemplateDescriptor.SelfServiceIntakeReminder]: 'Zelf-service intake herinnering',
  [MailjetTemplateDescriptor.PaymentConfirmation]: 'Betaalbevestiging',
};

export const translateEmailTemplate = (template: MailjetTemplateDescriptor) =>
  TemplateTranslations[template] || _.startCase(template);

export const emailStatusIndicator = (email: getEmailsEventsByEmail_getEmailsEventsByEmail) => {
  if (email.blocked) {
    return {
      icon: Block,
      label: 'Blocked',
      description: 'Geblokkeerd door de e-mail provider',
      color: 'red',
    };
  } else if (email.bounce) {
    return {
      icon: BlockAlt,
      label: 'Bounced',
      description: 'Kon niet worden afgeleverd',
      color: 'red',
    };
  } else if (email.spam) {
    return {
      icon: Mailbox,
      label: 'Spam',
      description: 'Is gemarkeerd als spam',
      color: 'orange',
    };
  } else if (email.open || email.click) {
    // TODO: do we want to give them this information?
    // With great power comes great responsibility
    // maybe phrase it as "received" instead of "seen"?
    return {
      label: 'Verstuurd',
      description: 'Verstuurd en waarschijnlijk gezien',
      color: 'green',
      icon: CheckCircle,
    };
  } else if (email.sent) {
    return {
      label: 'Verstuurd',
      description: 'Verstuurd, nog niet zeker ontvangen',
      color: 'blue',
      icon: CheckCircle,
    };
  } else {
    // Every email must be sent, so there's something terribly wrong if it is not sent
    return {
      icon: Warning,
      label: 'Niet verstuurd',
      description: 'Niet verzonden?!',
      color: 'red',
    };
  }
};

const format = 'DD/MM/YYYY [om] HH:mm';

export const formatRelativeTime = (date: Date, maxDaysAgo = 2) => {
  return dayjs().subtract(maxDaysAgo, 'day').isBefore(date)
    ? `${dayjs(date).fromNow()} (${dayjs(date).format('HH:mm')})`
    : dayjs(date).format(format);
};

const columns = [
  {
    id: 'type',
    header: 'E-mail type',
    Cell: function Subject({ email }: { email: getEmailsEventsByEmail_getEmailsEventsByEmail }) {
      return <span>{translateEmailTemplate(email.template)}</span>;
    },
  },
  {
    id: 'status',
    header: 'Status',
    Cell: function Status({ email }: { email: getEmailsEventsByEmail_getEmailsEventsByEmail }) {
      const sortedDateEntries = Object.entries(email)
        .filter(e => e[1] instanceof Date || typeof e[1] === 'number')
        .sort((a, b) => a[1] - b[1]);
      const latestEntry = sortedDateEntries.at(-1);
      const latestIndicator = emailStatusIndicator(email);
      if (latestEntry) {
        return (
          <Tooltip content={latestIndicator.description} bgColor={latestIndicator.color}>
            <>
              <Icon icon={latestIndicator.icon} fill={latestIndicator.color} />{' '}
              {latestIndicator.label}
            </>
          </Tooltip>
        );
      }
      return <span>Geen gebeurtenis</span>;
    },
  },
  {
    id: 'sent',
    header: 'Verzonden',
    Cell: function Sent({ email }: { email: getEmailsEventsByEmail_getEmailsEventsByEmail }) {
      return <span>{formatRelativeTime(email.sent)}</span>;
    },
  },
];

const EmailHistoryTable: React.FC<{
  emailHistory: readonly getEmailsEventsByEmail_getEmailsEventsByEmail[];
}> = ({ emailHistory }) => {
  const mobile = useIsMobile();

  return (
    <Table
      fontSize={mobile ? 7 : 6}
      mobile={mobile}
      defaultPadding={mobile ? 1 : 2}
      defaultOverflow="ellipsis"
      layout={columns.map(() => 1 / columns.length)}
    >
      <Table.Row isHeader>
        {columns.map(({ id, header }) => (
          <Table.Cell key={id}>{header}</Table.Cell>
        ))}
      </Table.Row>
      {emailHistory.length === 0 && (
        <Table.Row>
          <Table.Cell>Geen informatie bekend</Table.Cell>
          <Table.Cell />
          <Table.Cell />
        </Table.Row>
      )}
      {[...emailHistory].reverse().map(email => (
        <Table.Row key={email.id}>
          {columns.map(({ id, Cell }) => (
            <Table.Cell key={email.id + id}>
              <Cell email={email} />
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table>
  );
};

export default EmailHistoryTable;
