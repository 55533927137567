import { DocumentNode } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Box, Flex, Icon, Input, Spinner } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import { Cancel, Edit, FloppyDisk } from '@energiebespaarders/symbols/icons/line';
import { Alert } from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useState } from 'react';
import { operatorById_operatorById } from '../../types/generated/operatorById';

interface EditableFieldProps {
  mutation: DocumentNode;
  operator: operatorById_operatorById;
  field: string;
  value: string | number | null;
  name: string;
  justifyContent?: string;
  alignItems?: string;
  type?: string;
  id: string;
}

// TODO: This is a mess.
// update: still is
const EditableField: React.FC<EditableFieldProps> = ({
  mutation,
  operator: {
    id: removedIdFromInput,
    lastLogin: removedLastLoginFromInput,
    created,
    modified,
    profilePhotoUrl,
    salesManager,
    lastLogin: _,
    ...operatorInput
  },
  field,
  value,
  id,
  name,
  justifyContent,
  alignItems,
  type,
}) => {
  const [editing, setEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);

  const toggle = useCallback(() => setEditing(!editing), [editing]);

  if (!mutation || !field) {
    return (
      <Flex justifyContent={justifyContent || 'space-between'} alignItems={alignItems || 'center'}>
        <div>
          {editing ? (
            `Unable to edit, no ${!mutation ? 'mutation' : 'field'} provided!`
          ) : (
            <p>
              {name}:<br />
              <Medium>{value}</Medium>
            </p>
          )}
        </div>
        <Icon icon={Alert} onClick={toggle} ml={2} stroke="grayBlack" />
      </Flex>
    );
  }

  return (
    <Mutation
      mutation={mutation}
      variables={{
        operator: Object.assign({}, operatorInput, {
          [field]: type === 'number' ? Number(newValue) : newValue,
        }),
        id: id,
      }}
      onCompleted={() => toggle()}
      onError={() => toggle()}
    >
      {(mutation: any, { loading }: { loading: boolean }) => {
        if (loading) return <Spinner />;
        return (
          <Flex
            justifyContent={justifyContent || 'space-between'}
            alignItems={alignItems || 'center'}
          >
            {editing ? (
              <Flex alignItems="center" width={1}>
                <Box width={3 / 4}>
                  <Input
                    value={newValue || ''}
                    label={name}
                    onChange={e => setNewValue(e.target.value)}
                    placeholder={name}
                  />
                </Box>
              </Flex>
            ) : (
              <p>
                {name}:
                <br />
                <Medium>{value}</Medium>
              </p>
            )}
            {editing ? (
              <>
                <Icon
                  icon={FloppyDisk}
                  onClick={typeof newValue === 'string' && newValue.trim() ? mutation : toggle}
                  ml={2}
                  stroke="green"
                />
                <Icon icon={Cancel} stroke="red" onClick={toggle} ml={2} />
              </>
            ) : (
              <Icon icon={Edit} onClick={toggle} ml={2} stroke="grayBlack" />
            )}
          </Flex>
        );
      }}
    </Mutation>
  );
};

export default EditableField;
