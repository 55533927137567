import React from 'react';
import styled from 'styled-components';
import { Box, Icon, Spacer } from '@energiebespaarders/symbols';
import { ArrowLeft } from '@energiebespaarders/symbols/icons/line';
import { Info } from '@energiebespaarders/symbols/icons/solid';
import mediaMin from '../../styles/breakpoints';
import { margin, padding, themify } from '../../styles/mixins';

interface StyledSearchContainerProps {
  $bgColor: string;
  $offsetTop: string;
  $offsetLeft: string;
  $visible: boolean;
  $translateX: string;
}

const StyledSearchContainer = styled.main<StyledSearchContainerProps>`
  ${padding(2)};
  position: fixed;
  top: ${x => x.$offsetTop};
  left: ${x => x.$offsetLeft};
  width: calc(100vw - ${x => x.$offsetLeft});
  height: calc(100vh - ${x => x.$offsetTop});
  overflow-x: hidden;
  background: ${x => x.$bgColor};
  opacity: ${x => (x.$visible ? 1 : 0)};
  z-index: ${x => (x.$visible ? x.theme.z.base : 0)};
  transform: ${x => `translateX(${x.$translateX})`};
  transition: all 0.2s ${x => x.theme.curves.standard};

  ${mediaMin.md} {
    ${padding(4)};
  }
`;

const ReturnIcon = styled(Icon)`
  ${margin(2, 'right')};
  transition: transform 0.2s ${x => x.theme.curves.standard};
  cursor: pointer;
`;

const SearchTitle = styled.div<{ $narrowScreen: boolean }>`
  display: inline-block;
  color: white;
  font-size: ${x => x.theme.type.scale[5]};
  letter-spacing: ${x => (x.$narrowScreen ? '0.025em' : '0.075em')};
  word-break: break-word;
  cursor: pointer;

  &:hover {
    ${ReturnIcon} {
      transform: translateX(-25%);
    }
  }
`;

const NoResults = styled.div`
  color: white;
`;

function getSearchTitle(searchString: string, loading?: boolean) {
  switch (true) {
    case searchString && loading:
      return (
        <span>
          Zoeken naar <strong>{searchString}</strong>...
        </span>
      );
    case searchString && !loading:
      return (
        <span>
          Zoekresultaten voor <strong>{searchString}</strong>
        </span>
      );
    default:
      return <span>Type een zoekopdracht</span>;
  }
}

interface SearchContainerProps {
  bgColor?: string;
  narrowScreen: boolean;
  offsetLeft?: string;
  offsetTop?: string;
  searchString: string;
  toggleSearch: (state?: boolean) => void;
  translateX?: string;
  visible?: boolean;
}

const SearchContainer: React.FC<SearchContainerProps> = ({
  bgColor = 'grayDarker',
  children,
  narrowScreen,
  offsetLeft = '0',
  offsetTop = '0',
  searchString,
  toggleSearch,
  translateX = '0',
  visible = false,
}) => (
  <StyledSearchContainer
    $bgColor={themify(bgColor)}
    $offsetLeft={offsetLeft}
    $offsetTop={offsetTop}
    $visible={visible}
    $translateX={translateX}
  >
    <Box onClick={() => toggleSearch()}>
      <SearchTitle $narrowScreen={narrowScreen}>
        {!narrowScreen && (
          <ReturnIcon
            icon={ArrowLeft}
            inline
            width="24px"
            stroke="white"
            onClick={() => toggleSearch()}
          />
        )}
        {getSearchTitle(searchString)}
      </SearchTitle>
    </Box>
    <Spacer vertical amount={6} />
    {searchString ? (
      children
    ) : (
      <NoResults>
        <Icon icon={Info} fill="white" solid height="1rem" width="1rem" /> Je kunt zoeken naar
        klanten, woningen, medewerkers, partners en contactpersonen
      </NoResults>
    )}
  </StyledSearchContainer>
);

export default SearchContainer;
