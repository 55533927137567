import { gql } from '@apollo/client';

export const CustomerAddressFragment = gql`
  fragment CustomerAddress on Customer {
    address {
      street
      number
      suffix
      zip
      city
    }
  }
`;

export const UTMFragment = gql`
  fragment UTM on Customer {
    utm {
      source
      medium
      campaign
      term
      content
    }
  }
`;

export const CustomerNameFragment = gql`
  fragment CustomerName on Customer {
    firstName
    lastName
  }
`;

export const PersonaliaFragment = gql`
  ${CustomerNameFragment}
  fragment Personalia on Customer {
    ...CustomerName
    gender
    email
    phone
    lastLogin
  }
`;

export const CustomerFragment = gql`
  ${PersonaliaFragment}
  ${CustomerAddressFragment}
  ${UTMFragment}
  fragment Customer on Customer {
    id
    ...Personalia
    ...CustomerAddress
    ...UTM
    # motivation TODO: Uncomment once user-service understands enum Motivation
  }
`;

export const BusinessFragment = gql`
  fragment Business on Business {
    name
    coc
    vat
    iban
    address {
      zip
      number
      suffix
      street
      city
    }
  }
`;

export const BusinessCustomerFragment = gql`
  ${CustomerFragment}
  fragment BusinessCustomer on Customer {
    ...Customer
    business {
      ...Business
    }
  }
`;

export default CustomerFragment;
