import { useMutation, useQuery } from '@apollo/client';
import { Solution, delimit } from '@energiebespaarders/constants';
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Separator,
  TextLink,
  Toast,
  Tooltip,
} from '@energiebespaarders/symbols';
import { Small } from '@energiebespaarders/symbols/helpers';
import { Cancel, Check, Edit, Warning } from '@energiebespaarders/symbols/icons/solid';
import React, { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
import useToaster from '../../hooks/useToaster';
import { CALCULATIONS_TABLE, GET_CONSUMPTION } from '../../queries/calculations';
import { UPDATE_QUOTE } from '../../queries/installatron';
import { Writable } from '../../typeHelpers';
import { getConsumption, getConsumptionVariables } from '../../types/generated/getConsumption';
import { quoteModalQuote_quoteById } from '../../types/generated/quoteModalQuote';
import { updateQuote, updateQuoteVariables } from '../../types/generated/updateQuote';
import { EnergyDeltaInput, ProductCategory } from '../../types/graphql-global-types';

type CalculationInputProps = {
  houseId: string;
  quote: quoteModalQuote_quoteById;
  manualElectricity: number;
  setManualElectricity: (value: number) => void;
  manualGas: number;
  setManualGas: (value: number) => void;
};

export const CalculationInput: React.FC<CalculationInputProps> = ({
  houseId,
  quote,
  manualElectricity,
  setManualElectricity,
  manualGas,
  setManualGas,
}) => {
  const [editing, setEditing] = useState(false);

  const { data } = useQuery<getConsumption, getConsumptionVariables>(GET_CONSUMPTION, {
    variables: { houseId },
  });

  const toast = useToaster();

  const [updateQuoteMutation] = useMutation<updateQuote, updateQuoteVariables>(UPDATE_QUOTE, {
    refetchQueries: [{ query: CALCULATIONS_TABLE, variables: { houseId } }],
    onCompleted: () => {
      setEditing(false);
      toast({
        type: 'success',
        message: 'Berekeningen zijn aangepast',
      });
    },
    onError: e => {
      toast({
        type: 'error',
        message: 'Berekeningen konden niet worden aangepast: ' + e.message,
      });
    },
  });

  const handleUpdateGas = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setManualGas(parseInt(e.target.value));
    },
    [setManualGas],
  );
  const handleUpdateElectricity = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setManualElectricity(parseInt(e.target.value));
    },
    [setManualElectricity],
  );
  const handleSetEditingTrue = useCallback(() => () => setEditing(true), [setEditing]);

  const consumption = useMemo(() => {
    if (!data) return { gas: 0, electricity: 0 };
    const {
      house: {
        energy,
        situation: { consumption: sitCons },
      },
    } = data;
    return {
      gas: sitCons.gas ?? energy.gasConsumption,
      electricity:
        sitCons.electricity ?? energy.electricityConsumption - energy.electricityProduction,
    };
  }, [data]);

  const { energyDelta } = quote;
  const quoteContainsHeatPump = quote.items.some(
    item => item.product.category === ProductCategory.HeatPump,
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const gas = Number(manualGas);
    const electricity = Number(manualElectricity);
    const delta: Writable<EnergyDeltaInput> = {
      electricityConsumption: energyDelta.electricityConsumption,
      electricityProduction: energyDelta.electricityProduction,
      gasFactor: energyDelta.gasFactor,
      energyIndex: energyDelta.energyIndex,
    };
    if (quoteContainsHeatPump) {
      delta.electricityConsumption = -electricity;
      delta.gasFactor = gas / consumption.gas;
    } else if (quote.solution === Solution.PvSystem || consumption.gas === 0) {
      delta.electricityProduction = electricity;
    } else {
      delta.gasFactor = gas / consumption.gas;
    }
    await updateQuoteMutation({
      variables: {
        id: quote!.id!,
        quote: {
          energyDelta: delta,
        },
      },
    });
  };

  const heatPumpProduct = quote.items.find(
    item => item.product.category === ProductCategory.HeatPump,
  )?.product;
  const scop35 = heatPumpProduct?.__typename === 'HeatPump' ? heatPumpProduct.scop35 : 1;
  const scop55 = heatPumpProduct?.__typename === 'HeatPump' ? heatPumpProduct.scop55 : 1;
  const estimatedHeatPumpGasSavings = quoteContainsHeatPump ? consumption.gas * 0.6 : 0;
  const estimatedElectricityConsumptionSCOP35 = scop35
    ? quoteContainsHeatPump
      ? ((manualGas ?? estimatedHeatPumpGasSavings) * 9.3) / scop35
      : 0
    : 0;
  const estimatedElectricityConsumptionSCOP55 = scop55
    ? quoteContainsHeatPump
      ? ((manualGas ?? estimatedHeatPumpGasSavings) * 9.3) / scop55
      : 0
    : 0;

  return (
    <>
      {quote.solution !== Solution.PvSystem && !quote.isSent && (
        <div>
          <Small>
            <Icon icon={Warning} solid fill="orange" /> De berekeningen voor deze offerte zullen
            worden geüpdatet met de nieuwe info uit de opname wanneer deze wordt verzonden naar de
            klant
          </Small>
        </div>
      )}

      {editing ? (
        <Flex alignItems="flex-start" mx={-1}>
          {quote.isSent ? (
            <Box width={2 / 5} px={1}>
              <Toast
                type="warning"
                message="Deze offerte is al verzonden! Weet je zeker dat je de berekeningen wilt aanpassen?"
                width="100%"
              />
            </Box>
          ) : (
            <></>
          )}

          <Box width={3 / 5} px={1}>
            <form onSubmit={handleSubmit}>
              {consumption.gas === 0 &&
                quote.solution !== Solution.PvSystem &&
                !quoteContainsHeatPump && (
                  <Toast
                    type="alert"
                    message="Volgens de opname heeft is er een gasverbruik van 0m³, dus kan er geen gasbesparing zijn."
                    width="100%"
                  />
                )}
              <Flex alignItems="flex-start">
                {(quote.solution === Solution.PvSystem ||
                  quoteContainsHeatPump ||
                  consumption.gas === 0) && (
                  <Box pr={1}>
                    <Input
                      type="number"
                      label={
                        quoteContainsHeatPump
                          ? 'Stroomverbruik handmatig invullen'
                          : 'Opwek handmatig invullen'
                      }
                      addonSide="end"
                      addonContent="kWh"
                      value={manualElectricity}
                      onClick={handleSetEditingTrue}
                      onChange={handleUpdateElectricity}
                    />
                    {quoteContainsHeatPump && (
                      <Tooltip
                        placement="bottom"
                        fontSize={5}
                        content={
                          <div>
                            <b>Vuistregel</b>:
                            <br />
                            Gasbesparing in kWh (<b>
                              {manualGas || estimatedHeatPumpGasSavings}m³
                            </b>{' '}
                            × 9.3)
                            <br />÷ SCOP 35°C (<b>{scop35}</b>), of SCOP 55°C (<b>{scop55}</b>)
                          </div>
                        }
                      >
                        <div>
                          <Small>
                            <TextLink
                              onClick={() =>
                                setManualElectricity(
                                  Math.round(estimatedElectricityConsumptionSCOP35),
                                )
                              }
                            >
                              → Gebruik SCOP 35°C (
                              {delimit(estimatedElectricityConsumptionSCOP35, 0)} kWh)
                            </TextLink>
                          </Small>
                          <br />
                          <Small>
                            <TextLink
                              onClick={() =>
                                setManualElectricity(
                                  Math.round(estimatedElectricityConsumptionSCOP55),
                                )
                              }
                            >
                              → Gebruik SCOP 55°C (
                              {delimit(estimatedElectricityConsumptionSCOP55, 0)} kWh)
                            </TextLink>
                          </Small>
                        </div>
                      </Tooltip>
                    )}
                  </Box>
                )}

                {quote.solution !== Solution.PvSystem &&
                  quote.solution !== Solution.Miscellaneous &&
                  consumption.gas !== 0 && (
                    <Box pr={1}>
                      <Input
                        type="number"
                        label="Gasbesparing handmatig aanpassen"
                        addonSide="end"
                        addonContent="m³"
                        value={manualGas}
                        onClick={handleSetEditingTrue}
                        onChange={handleUpdateGas}
                      />
                      {quoteContainsHeatPump && (
                        <Small>
                          <TextLink
                            onClick={() => setManualGas(Math.round(estimatedHeatPumpGasSavings))}
                          >
                            → Gebruik 60% van gasverbruik ({Math.round(estimatedHeatPumpGasSavings)}
                            m³)
                          </TextLink>
                        </Small>
                      )}
                    </Box>
                  )}

                <Box>
                  <Button
                    type="button"
                    bgColor="red"
                    onClick={() => setEditing(false)}
                    minimal
                    mt={1}
                    mb={0}
                    mr={0}
                  >
                    <Icon icon={Cancel} solid fill="red" />
                  </Button>
                </Box>
                <Box>
                  <Button type="submit" minimal mt={1} mb={0} mr={0}>
                    <Icon icon={Check} solid fill="green" />
                  </Button>
                </Box>
              </Flex>
            </form>
          </Box>
        </Flex>
      ) : (
        <Flex justifyContent="flex-end">
          <Tooltip content="Besparing handmatig aanpassen" bgColor="orange">
            <Icon icon={Edit} solid hoverColor="orange" onClick={() => setEditing(true)} />
          </Tooltip>
        </Flex>
      )}
      <Separator my={2} color="grayLight" />
    </>
  );
};

export default CalculationInput;
