import React from 'react';
import styled, { css } from 'styled-components';
import { themify } from '../../styles/mixins';

interface StyledNavBarProps {
  $offsetLeft: string;
  $offsetRight: string;
  $height: string;
  $bgColor: string;
  $borderColor: string;
  $color: string;
  $transform: string;
  $narrowScreen: boolean;
}

export const StyledNavBar = styled.nav<StyledNavBarProps>`
  position: fixed;
  top: 0;
  left: ${x => x.$offsetLeft};
  width: ${x => `calc(100% - ${x.$offsetLeft} - ${x.$offsetRight})`};
  text-align: right;
  white-space: nowrap;
  height: ${x => x.$height};
  line-height: ${x => x.$height};
  background: ${x => x.$bgColor};
  border-bottom: 1px solid ${x => x.$borderColor};
  color: ${x => x.$color};
  z-index: ${x => x.theme.z.maskedNav};
  transform: ${x => `translateX(${x.$transform})`};
  transition: left, width, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;

  ${x =>
    x.$narrowScreen
      ? css`
          overflow-x: hidden;
        `
      : css`
          overflow: hidden;
        `}
`;

interface NavBarProps {
  bgColor?: string;
  borderColor?: string;
  color?: string;
  height: string;
  narrowScreen: boolean;
  offsetLeft: string;
  offsetRight: string;
  transform: string;
}

const NavBar: React.FC<NavBarProps> = ({
  bgColor = 'white',
  borderColor = 'grayLight',
  children,
  color = 'grayDarker',
  height,
  narrowScreen,
  offsetLeft,
  offsetRight,
  transform,
}) => (
  <StyledNavBar
    $bgColor={themify(bgColor)}
    $borderColor={themify(borderColor)}
    $color={themify(color)}
    $height={height}
    $offsetLeft={offsetLeft}
    $offsetRight={offsetRight}
    $narrowScreen={narrowScreen}
    $transform={transform}
    role="navigation"
  >
    {children}
  </StyledNavBar>
);

export default NavBar;
