import React from 'react';
import { Flex, Spinner } from '@energiebespaarders/symbols';
import { Helmet } from 'react-helmet-async';

const PageLoading: React.FC<{
  isLoading?: boolean;
  error?: Error;
}> = ({ isLoading = true, error }) => {
  if (error) console.error(error);
  return (
    <Flex alignItems="center" justifyContent="center" mt={10}>
      <Helmet title="Laden..." />
      {isLoading && <Spinner />}
      {error && <div>{error.message}</div>}
    </Flex>
  );
};

export default PageLoading;
