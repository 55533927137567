import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Accordion,
  Box,
  Card,
  Counter,
  Flex,
  Input,
  Range,
  Separator,
  Switch,
} from '@energiebespaarders/symbols';
import { Center, Medium, Small } from '@energiebespaarders/symbols/helpers';
import { Calculator } from '@energiebespaarders/symbols/icons/solid';
import { delimit } from '../../lib/utils';

const TaxRatioTool: React.FC<{ isNarrow: boolean }> = ({ isNarrow }) => {
  const [includeTax, setIncludeTax] = useState(false);
  const [newProductPrice, setNewProductPrice] = useState(0);
  const [newLaborPrice, setNewLaborPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(100);
  const [ratio, setRatio] = useState(0.4);

  const calculateNewAmounts = () => {
    setNewProductPrice((originalPrice * ratio) / 1.21);
    setNewLaborPrice(((1 - ratio) * originalPrice) / 1.09);
  };
  const handleToggle = () => {
    setIncludeTax(!includeTax);
  };

  useEffect(() => {
    calculateNewAmounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratio, includeTax, originalPrice, newProductPrice, newLaborPrice]);

  return (
    <Card mt={4} p={0} style={{ overflowY: 'hidden' }}>
      <Accordion
        baseBgColor="white"
        bgColor="grayLighter"
        contentBorderColor="grayLight"
        defaultOpen={false}
        icon={Calculator}
        iconColor="purple"
        title="BTW Calculator"
        titleSize={5}
      >
        <Flex flexWrap="wrap" alignItems="center" p={2}>
          <Box width={1} pb={2}>
            <Box width={isNarrow ? 1 : 1 / 3} mx="auto">
              <Input
                name="originalPrice"
                label="Oorspronkelijke totaalprijs incl. BTW"
                addonContent="€"
                addonSide="start"
                value={originalPrice}
                onChange={e => setOriginalPrice(Number(e.target.value.replace(/,/g, '.')))}
                iconColor="purple"
              />
            </Box>
          </Box>
          <Box width={1}>
            <Separator text="Gewenste verhouding 9% : 21%" fontSize={6} />
          </Box>
          <Box width={isNarrow ? 1 : 1 / 3} mt={2}>
            <Center block>
              <Medium>{Math.round((1 - ratio) * 100)}%</Medium>
              <Small> LAAG (9%)</Small>
            </Center>
          </Box>
          <Box width={isNarrow ? 1 : 1 / 3} mt={2}>
            <Center block>
              <Range
                step={0.05}
                min={0}
                max={1}
                value={ratio}
                showValue={false}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setRatio(Number(e.target.value))}
              />
            </Center>
          </Box>
          <Box width={isNarrow ? 1 : 1 / 3} mt={2}>
            <Center block>
              <Medium>{Math.round(ratio * 100)}%</Medium>
              <Small> HOOG (21%)</Small>
            </Center>
          </Box>
          <Box width={1} my={2}>
            <Separator text="Nieuwe prijzen" fontSize={6} />
          </Box>
          <Box width={isNarrow ? 1 : 1 / 3}>
            <Center block>
              <Small>Arbeid ({includeTax ? 'incl' : 'excl'}. 9% BTW)</Small>
              <br />
              <Medium>
                €{' '}
                <Counter
                  value={includeTax ? newLaborPrice * 1.09 : newLaborPrice}
                  formatValue={(n: number) => delimit(n)}
                  duration={300}
                />
              </Medium>
            </Center>
          </Box>
          <Box width={isNarrow ? 1 : 1 / 3}>
            <Switch
              bgColorOff="purple"
              bgColorOn="orange"
              isOn={includeTax}
              offLabel="Excl"
              onLabel="Incl"
              toggleSwitch={handleToggle}
            />
          </Box>
          <Box width={isNarrow ? 1 : 1 / 3}>
            <Center block>
              <Small>Product ({includeTax ? 'incl' : 'excl'}. 21% BTW)</Small>
              <br />
              <Medium>
                €{' '}
                <Counter
                  value={includeTax ? newProductPrice * 1.21 : newProductPrice}
                  formatValue={(n: number) => delimit(n)}
                  duration={300}
                />
              </Medium>
            </Center>
          </Box>
        </Flex>
      </Accordion>
    </Card>
  );
};

export default TaxRatioTool;
