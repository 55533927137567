import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { Flex, Box, Icon, Spacer, Input, Button } from '@energiebespaarders/symbols';
import { Medium, Small } from '@energiebespaarders/symbols/helpers';
import { SpecialCheck, Edit, Check, Cancel } from '@energiebespaarders/symbols/icons/solid';
import { UPDATE_QUOTE } from '../../../queries/installatron';
import { quoteModalQuote_quoteById as Quote } from '../../../types/generated/quoteModalQuote';
import {
  updateQuote as QuoteMutation,
  updateQuoteVariables,
} from '../../../types/generated/updateQuote';
import styled from 'styled-components';

const AcceptIcon = styled(Icon)`
  display: inline-block;
`;

const UpdateIcon = styled(Icon)`
  display: none;
`;

const StyledExpirationDate = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    ${AcceptIcon} {
      display: none;
    }

    ${UpdateIcon} {
      display: inline-block;
    }
  }
`;

const CancelIcon = styled(Icon)``;
const CancelButton = styled(Button)`
  &:hover {
    ${CancelIcon} {
      & * {
        fill: white !important;
      }
    }
  }
`;

function AcceptedField({ quote }: { quote: Quote }) {
  const [newAcceptedDate, setNewAcceptedDate] = useState(
    dayjs(new Date(quote.acceptedOn)).format('YYYY-MM-DD'),
  );
  const [isEditing, setIsEditing] = useState(false);

  const [updateQuote, { loading }] = useMutation<QuoteMutation, updateQuoteVariables>(
    UPDATE_QUOTE,
    {
      variables: {
        id: quote.id!,
        quote: {
          accepted: dayjs(newAcceptedDate).toDate(),
        },
      },
      onCompleted: () => setIsEditing(false),
    },
  );

  return (
    <>
      <StyledExpirationDate>
        {isEditing ? (
          <Flex alignItems="flex-end" mx={-1}>
            <Box width={3 / 4} px={1}>
              <Input
                disabled={loading}
                label="Akkoorddatum"
                type="date"
                value={newAcceptedDate}
                onChange={(e: any) => setNewAcceptedDate(e.target.value)}
              />
            </Box>
            <Box width={1 / 4} px={1}>
              <Button disabled={loading} onClick={() => updateQuote()}>
                <Icon icon={Check} solid fill="white" />
              </Button>
              <CancelButton
                disabled={loading}
                bgColor="red"
                inverse
                onClick={() => setIsEditing(false)}
              >
                <CancelIcon icon={Cancel} solid fill="red" />
              </CancelButton>
            </Box>
          </Flex>
        ) : (
          <div onClick={() => setIsEditing(!isEditing)}>
            <AcceptIcon icon={SpecialCheck} solid inline fill="green" width="1.25rem" />
            <UpdateIcon icon={Edit} solid inline fill="green" width="1.25rem" />
            <Spacer amount={2} />
            <Small>
              Klant heeft akkoord gegeven op{' '}
              <Medium>
                {dayjs(quote!.acceptedOn).format('dddd D MMMM YYYY (HH:mm)').toLowerCase()}
              </Medium>
            </Small>
          </div>
        )}
      </StyledExpirationDate>
    </>
  );
}

export default AcceptedField;
