import { gql } from '@apollo/client';
import { BasicAddressFragment } from '../../fragments';

export const GET_EMAIL_HTML = gql`
  query getEmailHTML($emailName: String!, $domain: String!, $body: EmailBodyInput!) {
    getEmailHTML(emailName: $emailName, domain: $domain, body: $body) {
      emailHTML
    }
  }
`;

export const SEND_TEST_EMAIL = gql`
  mutation sendTestEmail($emailName: String!, $variables: TestEmailVariables!) {
    sendTestEmail(emailName: $emailName, variables: $variables) {
      success
      error
    }
  }
`;

export const QUOTES_READY_EMAIL = gql`
  mutation quotesReadyEmail(
    $houseId: ID!
    $adviserId: ID!
    $solutions: [Solution!]!
    $quotesReadyText: String!
    $appointment: AppointmentInput
    $dealIds: [ID!]
  ) {
    quotesReady(
      houseId: $houseId
      adviserId: $adviserId
      solutions: $solutions
      quotesReadyText: $quotesReadyText
      appointment: $appointment
      dealIds: $dealIds
    ) {
      error
      success
    }
  }
`;

export const EMAILER = gql`
  ${BasicAddressFragment}
  query emailer($houseId: ID!) {
    house(id: $houseId) {
      id
      isGVAHouse
      isHabitataHouse
      ...BasicAddress
      orders {
        id
        orderLines {
          orderItem {
            id
            key
            isForLeadGenerator
          }
        }
        duties {
          name
        }
        status
      }
      customer {
        id
        firstName
        lastName
        email
        gender
        pipedriveId
      }
      advice {
        id
        isPaid
        isRequested
        isSent
        paidOn
        author {
          id
          firstName
          lastName
          email
        }
      }
      intake {
        id
        isStarted
      }
    }
  }
`;

export const SEND_INTERNAL_ANNOUNCEMENT = gql`
  mutation sendInternalAnnouncement($to: String!, $subject: String!, $body: String!) {
    sendInternalAnnouncement(to: $to, subject: $subject, body: $body)
  }
`;
