import { IntakeTopic } from '@energiebespaarders/constants';
import { DataPointObj } from '@energiebespaarders/data-maps';
import { Box, Flex, Input, RadioGroup, Select } from '@energiebespaarders/symbols';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { submitOnEnter } from '../../domains/Intake/utils';
import { fixUnit } from '../../lib/utils';

type Material = 'tempex' | 'glassWool' | 'stoneWool' | 'stoneWoolPlate' | 'pearls';

const MATERIAL_NL: Record<Material, string> = {
  tempex: 'Tempex',
  glassWool: 'Glaswol',
  stoneWool: 'Ingeblazen steenwol',
  stoneWoolPlate: 'Steenwolplaten',
  pearls: 'HR++ Parels',
};

// https://docs.google.com/spreadsheets/d/1KK46uKp3fJUOJv6Sy3BwOEZrDOhiIzLTbugZbXKd6LA/edit#gid=0
const TopicMaterials: Partial<
  Record<IntakeTopic, Partial<Record<Material, [number, number][]>>>
> = {
  [IntakeTopic.Floor]: {
    tempex: [
      [20, 0.55],
      [40, 1.1],
      [50, 1.35],
      [60, 1.65],
      [80, 2.2],
      [100, 2.75],
    ],
    glassWool: [
      [40, 1.13],
      [50, 1.37],
      [60, 1.61],
      [70, 1.86],
      [80, 2.1],
      [90, 2.35],
      [100, 2.59],
      [110, 2.83],
      [120, 3.08],
      [130, 3.32],
      [140, 3.56],
      [150, 3.81],
      [160, 4.05],
      [170, 4.3],
      [180, 4.54],
      [190, 4.78],
      [200, 5.03],
      [210, 5.27],
      [220, 5.52],
      [230, 5.76],
    ],
    stoneWoolPlate: [
      [10, 0.43],
      [20, 0.71],
      [30, 0.98],
      [40, 1.26],
      [50, 1.54],
      [60, 1.82],
      [70, 2.09],
      [80, 2.37],
      [90, 2.65],
      [100, 2.93],
      [110, 3.21],
      [120, 3.48],
      [130, 3.76],
      [140, 4.04],
      [150, 4.32],
      [160, 4.59],
      [170, 4.87],
      [180, 5.15],
      [190, 5.43],
      [200, 5.71],
      [210, 5.98],
      [220, 6.26],
      [230, 6.54],
    ],
  },
  [IntakeTopic.Walls]: {
    glassWool: [
      [40, 1.34],
      [50, 1.58],
      [60, 1.82],
      [70, 2.07],
      [80, 2.31],
      [90, 2.56],
      [100, 2.8],
      [110, 3.04],
      [120, 3.29],
      [130, 3.53],
      [140, 3.77],
      [150, 4.02],
      [160, 4.26],
      [170, 4.51],
      [180, 4.75],
      [190, 4.99],
      [200, 5.24],
      [210, 5.48],
      [220, 5.73],
      [230, 5.97],
    ],
    stoneWool: [
      [10, 0.58],
      [20, 0.8],
      [30, 1.03],
      [40, 1.25],
      [50, 1.47],
      [60, 1.69],
      [70, 1.92],
      [80, 2.14],
      [90, 2.36],
      [100, 2.58],
      [110, 2.8],
      [120, 3.03],
      [130, 3.25],
      [140, 3.47],
      [150, 3.69],
      [160, 3.92],
      [170, 4.14],
      [180, 4.36],
      [190, 4.58],
      [200, 4.8],
      [210, 5.03],
      [220, 5.25],
      [230, 5.47],
    ],
    stoneWoolPlate: [
      [10, 0.64],
      [20, 0.92],
      [30, 1.19],
      [40, 1.47],
      [50, 1.75],
      [60, 2.03],
      [70, 2.3],
      [80, 2.58],
      [90, 2.86],
      [100, 3.14],
      [110, 3.42],
      [120, 3.69],
      [130, 3.97],
      [140, 4.25],
      [150, 4.53],
      [160, 4.8],
      [170, 5.08],
      [180, 5.36],
      [190, 5.64],
      [200, 5.92],
      [210, 6.19],
      [220, 6.47],
      [230, 6.75],
    ],
    pearls: [
      [40, 1.34],
      [50, 1.58],
      [60, 1.82],
      [70, 2.07],
      [80, 2.31],
      [90, 2.56],
      [100, 2.8],
      [110, 3.04],
      [120, 3.29],
      [130, 3.53],
      [140, 3.77],
      [150, 4.02],
      [160, 4.26],
      [170, 4.51],
      [180, 4.75],
      [190, 4.99],
      [200, 5.24],
      [210, 5.48],
      [220, 5.73],
      [230, 5.97],
    ],
  },
  [IntakeTopic.Roof]: {
    glassWool: [
      [40, 1.2],
      [50, 1.44],
      [60, 1.68],
      [70, 1.93],
      [80, 2.17],
      [90, 2.42],
      [100, 2.66],
      [110, 2.9],
      [120, 3.15],
      [130, 3.39],
      [140, 3.63],
      [150, 3.88],
      [160, 4.12],
      [170, 4.37],
      [180, 4.61],
      [190, 4.85],
      [200, 5.1],
      [210, 5.34],
      [220, 5.59],
      [230, 5.83],
    ],
    stoneWoolPlate: [
      [10, 0.46],
      [20, 0.69],
      [30, 0.93],
      [40, 1.17],
      [50, 1.41],
      [60, 1.64],
      [70, 1.88],
      [80, 2.12],
      [90, 2.36],
      [100, 2.59],
      [110, 2.83],
      [120, 3.07],
      [130, 3.31],
      [140, 3.54],
      [150, 3.78],
      [160, 4.02],
      [170, 4.26],
      [180, 4.49],
      [190, 4.73],
      [200, 4.97],
      [210, 5.21],
      [220, 5.44],
      [230, 5.68],
    ],
  },
};

const manualOption: DropdownOption = {
  label: 'Vrije invoer',
  value: 'manual',
};

const RCInputHelper = ({
  question,
  onBlur,
  onChange,
  value,
  renderLabel,
}: {
  question: DataPointObj;
  onBlur: (value: number) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: number;
  renderLabel: boolean;
}) => {
  const topic: IntakeTopic = question.key.split('.')[1] as IntakeTopic;

  const [inputOption, setInputOption] = useState<'manual' | Material>('manual');
  const [thickness, setThickness] = useState(0);

  const MaterialOptions = TopicMaterials[topic];
  const thicknessForInput = MaterialOptions?.[inputOption as Material];
  // const rcValue = thicknessForInput?.find(o => o[0] === thickness)?.[1];

  const handlePickInputOption = useCallback((o: DropdownOption<string>) => {
    const val = o.value as Material;
    setInputOption(val);
    setThickness(-1);
  }, []);

  const handleSetThickness = useCallback(
    (o: DropdownOption<number>) => {
      setThickness(o.value);

      const rc = MaterialOptions?.[inputOption as Material]?.find(
        entry => entry[0] === o.value,
      )?.[1];
      if (rc) onBlur(rc);
    },
    [MaterialOptions, inputOption, onBlur],
  );

  const inputOptions = useMemo(
    () => [
      manualOption,
      ...Object.keys(MaterialOptions || {})?.map(mat => ({
        value: mat,
        label: MATERIAL_NL[mat as Material],
      })),
    ],
    [MaterialOptions],
  );

  const thicknessOptions = useMemo(
    () => [
      { value: -1, label: 'Kies een dikte', disabled: true },
      ...(thicknessForInput?.map(([thickness, rc]) => ({
        label: `${thickness.toString()} mm (RC ${rc})`,
        value: thickness,
      })) || []),
    ],
    [thicknessForInput],
  );

  // TODO: show minimum and average value as hint, in small text (see sheet)
  return (
    <>
      <Flex flexWrap="wrap">
        {renderLabel && (
          <Box width={1}>
            <RadioGroup.Label $labelColor="grayBlack">{question.question}</RadioGroup.Label>
          </Box>
        )}
        <Box width={1 / 2}>
          <Select
            options={inputOptions}
            value={inputOptions.find(o => o.value === inputOption)}
            onChange={handlePickInputOption}
            pr={1}
            useNativeSelect
          />
        </Box>
        <Box width={1 / 2}>
          {inputOption === 'manual' ? (
            <Input
              type="number"
              onBlur={e => onBlur(e.target.valueAsNumber)}
              labelColor="grayBlack"
              labelSize={6}
              min={question.minValue}
              max={question.maxValue}
              name={question.key}
              onChange={onChange}
              placeholder={question.label}
              addonSide={question.unit ? (question.unit === '€' ? 'start' : 'end') : undefined}
              // maybe only if screen is wide enough?
              addonContent={fixUnit(question.unit)}
              onKeyDown={submitOnEnter}
              value={value || ''}
            />
          ) : (
            <>
              <Select
                options={thicknessOptions}
                onChange={handleSetThickness}
                value={thicknessOptions.find(o => o.value === thickness)}
                // native select is nicer on mobile than React select for intake
                useNativeSelect
              />
            </>
          )}
        </Box>
      </Flex>

      {/* {inputOption !== 'manual' && (
        <Smaller>
          {rcValue} m<sup>2</sup>K/W &nbsp;&nbsp;&nbsp;
        </Smaller>
      )} */}
    </>
  );
};

export default RCInputHelper;
