import { gql } from '@apollo/client';

export const OperatorFragment = gql`
  fragment Operator on Operator {
    id
    firstName
    lastName
    email
    phone
    jobTitle
    role
    pipedriveId
    isActive
    team
    isFreelancer
    role
    address {
      street
      number
      suffix
      zip
      city
    }
    workRegions {
      to
      from
    }
    profilePhotoUrl
  }
`;

export default OperatorFragment;
