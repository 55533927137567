import React from 'react';
import { Modal, Separator, Textarea } from '@energiebespaarders/symbols';
import {
  jobDomainQuote_quoteById_jobs,
  jobDomainQuote_quoteById,
} from '../../../types/generated/jobDomainQuote';
import { gql, useMutation } from '@apollo/client';
import {
  uncompleteJob as uncompleteJobMutation,
  uncompleteJobVariables,
} from '../../../types/generated/uncompleteJob';
import useToaster from '../../../hooks/useToaster';

const UNCOMPLETE_JOB = gql`
  mutation uncompleteJob($id: ID!, $reason: String!) {
    uncompleteJob(id: $id, reason: $reason) {
      id
      completed
    }
  }
`;

const UncompletionModal: React.FC<
  UncompletionControlsProps & { isOpen: boolean; setIsOpen: (isOpen: boolean) => void }
> = ({ job, isOpen, setIsOpen }) => {
  const toast = useToaster();

  const [uncompleteJob, { loading, error }] = useMutation<
    uncompleteJobMutation,
    uncompleteJobVariables
  >(UNCOMPLETE_JOB, {
    onCompleted: ({ uncompleteJob }) => {
      if (uncompleteJob.completed === false) {
        toast({
          type: 'success',
          message: 'Opdracht on-voltooid',
        });
        setIsOpen(false);
      }
    },
  });

  const [reason, setReason] = React.useState('');

  return (
    <Modal
      title="Opdrachtvoltooiing ongedaan maken"
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      buttons={[
        {
          label: 'Annuleren',
          onClick: () => setIsOpen(false),
          bgColor: 'red',
        },
        {
          label: 'Voltooien ongedaan maken',
          bgColor: 'orange',
          type: 'submit',
          onClick: () => uncompleteJob({ variables: { id: job.id, reason } }),
          loading,
          error: error?.toString(),
          disabled: reason.length === 0,
        },
      ]}
    >
      <p>
        Weet je zeker dat je de opdracht wilt on-voltooien? <br />
        Gebruik dit alleen wanneer de opdracht in de werkelijkheid nog niet is afgerond. De
        voltooiingsdatum en beoordeling indien aanwezig zal worden verwijderd. Deze worden in een
        Pipedrive notitie gezet om later terug gevonden te kunnen worden.
      </p>
      <Textarea
        label="Reden"
        value={reason}
        onChange={e => setReason(e.target.value)}
        minHeight="60"
        placeholder="Bijvoorbeeld: Na het voltooien bleek er een nieuwe afspraak nodig voor het vervangen van een defect paneel"
      />
    </Modal>
  );
};

interface UncompletionControlsProps {
  job: jobDomainQuote_quoteById_jobs;
  quote: jobDomainQuote_quoteById;
}

const UncompletionControls: React.FC<UncompletionControlsProps> = ({ job, quote }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleUncomplete = () => {
    // TODO: be clearer about when/why to use this, and the consequences
    // - From perspective of installer in IA
    // - Perspective of customer on dashboard
    // - Gripp/finance
    // -
    setIsOpen(true);
  };

  return (
    <>
      <Separator my={1} />
      <small>
        Opdracht toch niet voltooid?{' '}
        <a href="#" onClick={handleUncomplete}>
          Opdracht on-voltooien
        </a>
      </small>
      <UncompletionModal job={job} quote={quote} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default UncompletionControls;
