import { getLabelInfo_house_labelRegistration } from './../../types/generated/getLabelInfo';
import { constants } from '@energiebespaarders/constants';

// TODO: Shouldn't this include A+ and A++ now? The 2021 EnergyLabel even goes up to A++++ :O
export type EnergyLabelType = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

export const averageEnergyIndexes: { [x: string]: number } = {
  A: 1.2,
  B: 1.3,
  C: 1.6,
  D: 1.95,
  E: 2.25,
  F: 2.55,
  G: 2.75,
};

export function getPreliminaryEnergyLabel(type: number, year: number): EnergyLabelType {
  let labels: EnergyLabelType[] = ['G', 'F', 'D', 'C', 'C', 'C', 'B', 'A', 'A'];
  // Vrijstaande woning
  if (type === 1) labels = ['G', 'F', 'D', 'C', 'C', 'B', 'B', 'A', 'A'];
  // Twee-onder-één-kapwoning
  if (type === 2) labels = ['G', 'F', 'D', 'C', 'C', 'B', 'B', 'B', 'A'];
  // Hoekwoning
  if (type === 3) labels = ['G', 'F', 'D', 'C', 'C', 'C', 'B', 'A', 'A'];
  // Tussenwoning
  if (type === 4) labels = ['F', 'E', 'C', 'C', 'C', 'C', 'B', 'A', 'A'];
  // Generiek appartement
  if (type === 5) labels = ['G', 'E', 'F', 'C', 'C', 'C', 'B', 'B', 'A'];
  // Maisonette hoek dak vloer
  if (type === 6) labels = ['G', 'E', 'D', 'C', 'C', 'B', 'A', 'A', 'A'];
  // Maisonette hoek dak
  if (type === 7) labels = ['G', 'E', 'C', 'B', 'C', 'B', 'A', 'A', 'A'];
  // Maisonette hoek vloer
  if (type === 8) labels = ['E', 'D', 'C', 'C', 'C', 'C', 'A', 'A', 'A'];
  // Maisonette hoek midden
  if (type === 9) labels = ['E', 'D', 'C', 'B', 'C', 'C', 'A', 'A', 'A'];
  // Maisonette tussen dak vloer
  if (type === 10) labels = ['F', 'E', 'C', 'B', 'C', 'B', 'A', 'A', 'A'];
  // Maisonette tussen dak
  if (type === 11) labels = ['F', 'E', 'C', 'B', 'C', 'B', 'A', 'A', 'A'];
  // Maisonette tussen vloer
  if (type === 12) labels = ['D', 'D', 'C', 'B', 'C', 'C', 'A', 'A', 'A'];
  // Maisonette tussen midden
  if (type === 13) labels = ['D', 'D', 'C', 'B', 'C', 'C', 'A', 'A', 'A'];
  // Appartment hoek dak vloer
  if (type === 14) labels = ['G', 'G', 'F', 'C', 'C', 'C', 'B', 'B', 'A'];
  // Appartment hoek dak
  if (type === 15) labels = ['G', 'G', 'F', 'C', 'C', 'C', 'B', 'B', 'A'];
  // Appartment hoek vloer
  if (type === 16) labels = ['G', 'E', 'F', 'C', 'C', 'C', 'B', 'B', 'A'];
  // Appartment hoek midden
  if (type === 17) labels = ['G', 'E', 'F', 'C', 'C', 'C', 'B', 'B', 'A'];
  // Appartment tussen dak vloer
  if (type === 18) labels = ['G', 'F', 'F', 'C', 'C', 'C', 'B', 'A', 'A'];
  // Appartment tussen dak
  if (type === 19) labels = ['G', 'G', 'F', 'C', 'C', 'C', 'B', 'B', 'A'];
  // Appartment tussen vloer
  if (type === 20) labels = ['G', 'E', 'E', 'B', 'C', 'C', 'B', 'B', 'A'];
  // Appartment tussen midden
  if (type === 21) labels = ['G', 'E', 'F', 'C', 'C', 'C', 'B', 'B', 'A'];
  // Schakelwoning
  if (type === 22) labels = ['G', 'F', 'D', 'C', 'C', 'B', 'B', 'B', 'A'];

  if (year <= 1945) return labels[0];
  if (year > 1945 && year <= 1964) return labels[1];
  if (year > 1964 && year <= 1974) return labels[2];
  if (year > 1974 && year <= 1982) return labels[3];
  if (year > 1982 && year <= 1987) return labels[4];
  if (year > 1987 && year <= 1991) return labels[5];
  if (year > 1991 && year <= 1999) return labels[6];
  if (year > 1999 && year <= 2005) return labels[7];
  if (year > 2005) return labels[8];
  return 'G';
}

export function getEnergyLabel(
  energyIndex: number,
  energyLabels = constants.energyLabels,
): EnergyLabelType {
  switch (true) {
    case energyIndex <= energyLabels.A:
      return 'A';
    case energyIndex <= energyLabels.B:
      return 'B';
    case energyIndex <= energyLabels.C:
      return 'C';
    case energyIndex <= energyLabels.D:
      return 'D';
    case energyIndex <= energyLabels.E:
      return 'E';
    case energyIndex <= energyLabels.F:
      return 'F';
    default:
      return 'G';
  }
}

export function getCurrentLabelInfo(
  estimatedEnergyIndex: number,
  labelRegistration: getLabelInfo_house_labelRegistration,
): { currentLabel: EnergyLabelType; currentEnergyIndex: number } {
  // Start with the ECM calculations
  let currentLabel = getEnergyLabel(estimatedEnergyIndex);
  let currentEnergyIndex = estimatedEnergyIndex;

  // Check if RVO has a registered label
  if (labelRegistration.registered) {
    if (labelRegistration.energyIndex) {
      // Check if the registration contains an energy index – if so, use that
      currentEnergyIndex = labelRegistration.energyIndex;
      currentLabel = getEnergyLabel(currentEnergyIndex);
    } else if (
      labelRegistration.energyLabel &&
      Object.keys(averageEnergyIndexes).includes(labelRegistration.energyLabel)
    ) {
      // Otherwise use the average for the registered label to base our calculated improvement on
      // In the outlier case that RVO does return registered, but no label, use our estimate as fallback
      currentEnergyIndex = averageEnergyIndexes[labelRegistration.energyLabel];
      currentLabel = labelRegistration.energyLabel as EnergyLabelType;
    }
  }
  return {
    currentLabel,
    currentEnergyIndex,
  };
}
