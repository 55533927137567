import { ApolloCache, FetchResult, gql } from '@apollo/client';
import { quoteById as quoteByIdQuery } from '../types/generated/quoteById';
import { AdviceTextsFragment, AdvisedQuotesFragment, SolutionAdviceFragment } from '../fragments';
import { quoteByIdVariables } from '../types/generated/quoteById';
import { QUOTE_BY_ID } from './quote';
import { toggleAdvisedQuote } from '../types/generated/toggleAdvisedQuote';

export const updateToggleAdvisedQuoteCache = (
  cache: ApolloCache<toggleAdvisedQuote>,
  { data }: FetchResult<toggleAdvisedQuote, Record<string, any>, Record<string, any>>,
  quote: { id: string },
) => {
  // Update installation data with new info about quote to show in installatron overview
  if (data) {
    const quoteData = cache.readQuery<quoteByIdQuery, quoteByIdVariables>({
      query: QUOTE_BY_ID,
      variables: { quoteId: quote.id },
    });
    if (quoteData) {
      cache.writeQuery<quoteByIdQuery, quoteByIdVariables>({
        query: QUOTE_BY_ID,
        variables: { quoteId: quote.id },
        data: {
          quoteById: {
            ...quoteData.quoteById!,
            isAdvised: !quoteData.quoteById?.isAdvised,
          },
        },
      });
    }
  }
};

export const TOGGLE_ADVISED_QUOTE = gql`
  ${AdvisedQuotesFragment}
  mutation toggleAdvisedQuote($quoteId: ID!) {
    toggleAdvisedQuote(quoteId: $quoteId) {
      id
      ...AdvisedQuotes
    }
  }
`;

export const GET_ADVICE_ID = gql`
  query adviceIdByHouse($houseId: ID!) {
    adviceByHouse(houseId: $houseId) {
      id
    }
  }
`;

export const UPDATE_ADVICE = gql`
  mutation updateAdvice($id: ID!, $advice: AdviceInput!) {
    updateAdvice(id: $id, advice: $advice) {
      id
    }
  }
`;

export const ADVISED_QUOTES = gql`
  ${AdvisedQuotesFragment}
  query advicedQuotesByHouse($houseId: ID!) {
    adviceByHouse(houseId: $houseId) {
      id
      ...AdvisedQuotes
    }
  }
`;

export const UPSERT_ADVICE_AUTHOR = gql`
  mutation upsertAdviceAuthor($adviceId: ID!, $operatorId: ID!) {
    upsertAdviceAuthor(adviceId: $adviceId, operatorId: $operatorId) {
      id
      author {
        id
        firstName
        lastName
      }
    }
  }
`;

export const UPSERT_ADVICE_BUDDY = gql`
  mutation upsertAdviceBuddy($adviceId: ID!, $operatorId: ID!) {
    upsertAdviceBuddy(adviceId: $adviceId, operatorId: $operatorId) {
      id
      buddy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_ADVICE_TEXTS = gql`
  ${AdviceTextsFragment}
  query getAdviceTexts($houseId: ID!) {
    adviceByHouse(houseId: $houseId) {
      id
      ...AdviceTexts
    }
  }
`;

export const SAVE_EDITOR = gql`
  ${AdviceTextsFragment}
  mutation saveAdviceEditor($content: String, $topic: String, $houseId: ID) {
    saveAdviceEditor(content: $content, topic: $topic, houseId: $houseId) {
      id
      ...AdviceTexts
      sendingConstraintMessages {
        errors {
          general
          roof
          walls
          floor
          heating
          pvSystem
        }
        warnings {
          general
          roof
          walls
          floor
          heating
          pvSystem
        }
      }
    }
  }
`;

export const ADVICE_CHECK_REMINDER = gql`
  mutation adviceCheckActivity($houseId: ID!) {
    adviceCheckActivity(houseId: $houseId)
  }
`;

export const UPDATE_APPENDICES = gql`
  mutation updateAppendices($appendices: [Appendix!]!, $houseId: ID!) {
    updateAppendices(appendices: $appendices, houseId: $houseId) {
      id
      appendices
    }
  }
`;

export const UPDATE_SOLUTION_ORDER = gql`
  mutation updatesSolutionDomainOrder($solutionDomainOrder: [String!]!, $houseId: ID!) {
    updateSolutionDomainOrder(solutionDomainOrder: $solutionDomainOrder, houseId: $houseId) {
      id
      solutionDomainOrder
    }
  }
`;

export const SEND_PASSPORT_EMAIL = gql`
  mutation passportReady(
    $operator: OperatorEmailInput!
    $customer: CustomerEmailInput!
    $orderId: ID!
    $houseId: ID!
    $adviserId: ID!
    $appointment: AppointmentInput
    $opts: OptionsInput
  ) {
    passportReady(
      operator: $operator
      customer: $customer
      orderId: $orderId
      houseId: $houseId
      adviserId: $adviserId
      appointment: $appointment
      opts: $opts
    ) {
      error
      success
    }
  }
`;

export const SEND_GVA_PASSPORT_EMAIL = gql`
  mutation gvaPassportReady($opts: OptionsInput!, $houseId: ID!) {
    gvaPassportReady(houseId: $houseId, opts: $opts) {
      error
      success
    }
  }
`;

export const SEND_ADVICE = gql`
  mutation sendAdvice($adviceId: ID!) {
    sendAdvice(adviceId: $adviceId) {
      id
      isRequested
      requestedOn
      isPaid
      paidOn
      isSent
      sentOn
    }
  }
`;

export const GET_OPERATOR_NAMES = gql`
  query getOperatorNames {
    operators {
      id
      firstName
      lastName
      jobTitle
      isActive
      role
      phone
    }
  }
`;

export const RESET_ADVICE_OPENED = gql`
  mutation resetToUnopened($adviceId: ID!, $openDate: Date) {
    setAdviceOpened(adviceId: $adviceId, openDate: $openDate) {
      id
      isOpened
      openedOn
    }
  }
`;

export const ADVICE_OVERVIEW = gql`
  ${SolutionAdviceFragment}
  query adviceOverview($houseId: ID!) {
    house(id: $houseId) {
      id
      isGVAHouse
      isHabitataHouse
      requestedDuties {
        name
      }
      customer {
        id
        firstName
        lastName
        email
        gender
        utm {
          campaign
        }
        referrer
      }
      quotes {
        id
        isAdvised
        isSent
        isCancelled
      }
      advice {
        id
        wallInsulation {
          ...SolutionAdvice
        }
        floorInsulation {
          ...SolutionAdvice
        }
        roofInsulation {
          ...SolutionAdvice
        }
        insulationGlazing {
          ...SolutionAdvice
        }
        pvSystem {
          ...SolutionAdvice
        }
        heating {
          ...SolutionAdvice
        }
        miscellaneous {
          ...SolutionAdvice
        }
        sendingConstraintMessages {
          errors {
            general
            roof
            walls
            floor
            heating
            pvSystem
            windows
            other
          }
          warnings {
            general
            roof
            walls
            floor
            heating
            pvSystem
            windows
            other
          }
        }
        author {
          id
          firstName
          lastName
          email
        }
        buddy {
          id
        }
        summary
        solutionDomainOrder
        isRequested
        requestedOn
        isSent
        sentOn
        isPaid
        paidOn
        isOpened
        openedOn
        cancellation {
          reason
          date
        }
      }
    }
  }
`;

export const ADVICE_AUDIT_BY_ADVICE = gql`
  query adviceAuditByAdvice($adviceId: ID!) {
    adviceAuditByAdvice(adviceId: $adviceId) {
      id
      buddy {
        id
        firstName
        lastName
      }
      timeSpentOnPassport
      stoppedOn
    }
  }
`;

export const ADVICE_AUDIT_BY_HOUSE = gql`
  query adviceAuditByHouse($houseId: ID!) {
    house(id: $houseId) {
      id
      advice {
        id
        audit {
          id
          startedOn
          buddy {
            id
            firstName
            lastName
          }
          timeSpentOnPassport
        }
      }
    }
  }
`;

export const START_WORKING_ON_PASSPORT = gql`
  mutation startWorkingOnPassport($adviceId: ID!, $operatorId: ID!) {
    startWorkingOnPassport(adviceId: $adviceId, operatorId: $operatorId) {
      id
    }
  }
`;

export const STOP_WORKING_ON_PASSPORT = gql`
  mutation stopWorkingOnPassport($adviceId: ID!, $overwritePreviousStop: Boolean, $operatorId: ID) {
    stopWorkingOnPassport(
      adviceId: $adviceId
      overwritePreviousStop: $overwritePreviousStop
      operatorId: $operatorId
    ) {
      id
      timeSpentOnPassport
      buddy {
        id
        firstName
      }
    }
  }
`;
