import { ReactNode } from 'react';
import {
  BagEuro,
  BanknoteEuro,
  ChairEasy,
  EnergyCircle,
  Fire,
  LeafAngle,
  Percentage,
  PiggyBank,
  Tag,
  Time,
} from '@energiebespaarders/symbols/icons/solid';

type CalcDataObj = {
  key: string;
  color: string;
  icon: ReactNode;
  label: string;
  prefix?: string;
  suffix?: string;
  decimals?: number;
};

export const calculationData: CalcDataObj[] = [
  {
    key: 'money',
    color: 'green',
    icon: PiggyBank,
    label: 'Besparing',
    prefix: '€ ',
    suffix: ' / jaar',
    decimals: 0,
  },
  {
    key: 'gas',
    color: 'red',
    icon: Fire,
    label: 'Gasbesparing',
    suffix: ' m3 / jaar',
    decimals: 0,
  },
  {
    key: 'electricity',
    color: 'orange',
    icon: EnergyCircle,
    label: 'Elektriciteitsbesparing',
    suffix: ' kWh / jaar',
    decimals: 0,
  },
  {
    key: 'emission',
    color: 'green',
    icon: LeafAngle,
    label: 'Uitstootreductie',
    suffix: ' kg CO2 / jaar',
    decimals: 0,
  },
  {
    key: 'comfort',
    color: 'orange',
    icon: ChairEasy,
    label: 'Comfortverbetering',
    decimals: 0,
  },
  {
    key: 'investment',
    color: 'purple',
    icon: BagEuro,
    label: 'Investering',
    prefix: '€ ',
    decimals: 2,
  },
  {
    key: 'efficiency',
    color: 'purple',
    icon: Percentage,
    label: 'Rendement',
    suffix: '%',
    decimals: 2,
  },
  {
    key: 'paybackTime',
    color: 'purple',
    icon: Time,
    label: 'Terugverdientijd',
    suffix: ' jaar',
    decimals: 1,
  },
  {
    key: 'subsidy',
    color: 'purple',
    icon: BanknoteEuro,
    label: 'Subsidie',
    prefix: '€ ',
    decimals: 0,
  },
  {
    key: 'energyLabel',
    color: 'purple',
    icon: Tag,
    label: 'Indicatie energielabel',
  },
];

export default calculationData;
