import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from '@energiebespaarders/symbols';

const StyledEmailContainer = styled.div`
  margin: 0 auto;
`;

const EmailModal = ({ body, closeModal, isOpen, mobile, title }) => (
  <Modal
    closeTimeoutMS={mobile ? 250 : undefined}
    contentPadding={0}
    height="70vh"
    isOpen={isOpen}
    mobile={mobile}
    onRequestClose={closeModal}
    role="dialog"
    title={`${title} (voorbeeld)`}
  >
    <StyledEmailContainer>
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </StyledEmailContainer>
  </Modal>
);

EmailModal.propTypes = {
  body: PropTypes.node,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  mobile: PropTypes.bool,
  title: PropTypes.string,
};

export default EmailModal;
