import { Toast } from '@energiebespaarders/symbols';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import React from 'react';
import styled from 'styled-components';
import { IToast } from '../hooks/useToaster';

const StyledToaster = styled.div`
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: ${x => x.theme.z.toaster};
  pointer-events: none;

  ${mediaMin.sm} {
    top: 60px;
    right: 30px;
    left: auto;
    width: calc(100% - 60px);
  }

  ${mediaMin.md} {
    width: ${x => x.theme.containers.sm};
  }
`;

interface ToasterProps {
  deleteToast: (uid: number) => void;
  toasts: IToast[];
}

const Toaster: React.FC<ToasterProps> = ({ deleteToast, toasts }) => (
  <StyledToaster>
    {toasts.map(toast => (
      <Toast
        width="100%"
        key={`toast-${toast.uid}`}
        handleClose={() => toast.uid && deleteToast(toast.uid)}
        {...toast}
      >
        {toast.message}
      </Toast>
    ))}
  </StyledToaster>
);

export default Toaster;
