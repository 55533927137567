import { gql } from '@apollo/client';

import {
  WallsFragment,
  FloorFragment,
  CrawlspaceFragment,
  RoofFragment,
  HeatingFragment,
  AppliancesFragment,
} from './House';

export const GeneralIntakeFragment = gql`
  fragment GeneralIntake on Intake {
    houseId
    area
    conservatory
    constructionYear
    hasAnnex
    isStarted
    monument
    pvSystemPower
    residents
    startedOn
    stories
    type
    method
    vve
    woz
    hasBasement
    incorrectMap {
      key
      incorrect
    }
  }
`;

export const IntakeNotesFragment = gql`
  fragment IntakeNotes on Intake {
    notes {
      general
      pvSystem
      walls
      floor
      roof
      windows
      heating
      other
    }
  }
`;

export const IntakeWindowsFragment = gql`
  fragment IntakeWindows on IntakeWindows {
    sleepingZTA
    sleepingU
    livingZTA
    livingU
    sleepingArea
    livingArea
    totalSingleArea
    totalDoubleArea
    totalHRArea
  }
`;

export const IntakeTopicProgressFragment = gql`
  fragment IntakeTopicProgress on IntakeTopicProgress {
    value
    completedOn
    reasonNotCompleted {
      category
      description
      plannedCompletionDate
    }
  }
`;

export const IntakeProgressFragment = gql`
  ${IntakeTopicProgressFragment}

  fragment Progress on Intake {
    progress {
      general {
        ...IntakeTopicProgress
      }
      pvSystem {
        ...IntakeTopicProgress
      }
      walls {
        ...IntakeTopicProgress
      }
      floor {
        ...IntakeTopicProgress
      }
      roof {
        ...IntakeTopicProgress
      }
      windows {
        ...IntakeTopicProgress
      }
      heating {
        ...IntakeTopicProgress
      }
      other {
        ...IntakeTopicProgress
      }
    }
  }
`;

export const IntakeSurfacesFragment = gql`
  ${WallsFragment}
  ${FloorFragment}
  ${CrawlspaceFragment}
  ${RoofFragment}
  ${IntakeWindowsFragment}
  ${HeatingFragment}
  ${AppliancesFragment}
  fragment IntakeSurfaces on Intake {
    walls {
      ...Walls
    }
    floor {
      ...Floor
    }
    crawlspace {
      ...Crawlspace
    }
    roof {
      ...Roof
    }
    glazingTypes {
      living
      sleeping
    }
    windows {
      ...IntakeWindows
    }
    heating {
      ...Heating
    }
    appliances {
      ...Appliances
    }
  }
`;

export const IntakeFragment = gql`
  ${GeneralIntakeFragment}
  ${IntakeNotesFragment}
  ${IntakeSurfacesFragment}
  ${IntakeProgressFragment}
  fragment Intake on Intake {
    id
    operator {
      id
      firstName
      lastName
    }
    ...GeneralIntake
    ...IntakeNotes
    ...IntakeSurfaces
    ...Progress
  }
`;
