import { gql, useQuery } from '@apollo/client';
import { IDiscount } from '@energiebespaarders/constants';
import { useForm } from '@energiebespaarders/hooks';
import { Box, Button, Flex, Icon, Input, Select, Switch } from '@energiebespaarders/symbols';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { Medium } from '@energiebespaarders/symbols/helpers';
import { Add, Alert, Cancel, Check } from '@energiebespaarders/symbols/icons/solid';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { getQuoteDiscountNames } from '../../../types/generated/getQuoteDiscountNames';
import { DiscountInput, DiscountType } from '../../../types/graphql-global-types';

const GET_DISCOUNT_NAMES = gql`
  query getQuoteDiscountNames {
    getQuoteDiscountNames
  }
`;

interface DiscountFormProps {
  initialValue?: DiscountInput;
  onCancel: () => void;
  /** Called when the discount is deleted, only visible when an initialValue is provided */
  onClear: () => void;
  onSubmit: (discount: DiscountInput) => void;
  /** Transform a fixed amount to a percentage value or vice versa */
  transformDiscount?: (discount: DiscountInput, newType: DiscountType) => number;
}

type FormState = IDiscount;

const discountTypes: { value: DiscountType; label: string }[] = [
  {
    value: DiscountType.amountInclTax,
    label: 'Netto bedrag (na BTW)',
  },
  {
    value: DiscountType.percentageInclTax,
    label: 'Netto percentage (na BTW)',
  },
];

const DiscountForm: React.FC<DiscountFormProps> = ({
  initialValue,
  onCancel,
  onClear,
  onSubmit,
  transformDiscount,
}) => {
  const [isAddingNewDiscount, setAddingNewDiscount] = useState(false);

  const { formState, submitForm, handleChange } = useForm<FormState>({
    initialValues: {
      amount: initialValue?.amount || 0,
      text: initialValue?.text || '',
      type: initialValue?.type || 'amountInclTax',
    },
    handleSubmit(values) {
      onSubmit({
        amount: values.amount,
        text: values.text,
        type: values.type as DiscountType,
      });
    },
    validate(values, errors) {
      if (values.type === 'percentageInclTax' && values.amount > 100) {
        errors.amount = 'Korting percentage mag niet hoger zijn dan 100%';
      }
      return errors;
    },
  });

  const { amount, text, type } = formState;

  const { data: discountNameData, error } = useQuery<getQuoteDiscountNames>(GET_DISCOUNT_NAMES, {
    fetchPolicy: 'network-only',
  });
  const existingDiscounts = discountNameData?.getQuoteDiscountNames;

  const handleUpdateType = useCallback(
    (e: DropdownOption<DiscountType>) => {
      const newType = e.value as DiscountType;
      const newAmount =
        transformDiscount?.(
          {
            amount: amount.value,
            text: text.value,
            type: type.value as DiscountType,
          },
          newType,
        ) ?? 0;

      handleChange({ type: newType });
      handleChange({ amount: newAmount });
    },
    [amount.value, handleChange, text.value, transformDiscount, type.value],
  );

  return (
    <form onSubmit={submitForm}>
      {type.value === 'percentageInclTax' && (
        <p>
          <Icon icon={Alert} fill="orange" mr={1} />
          <Medium>
            Let op: percentages worden omgerekend naar euro's en als bedrag opgeslagen.
          </Medium>
        </p>
      )}
      <Flex mx={-1} flexWrap="wrap">
        <Box width={[1, 6 / 12, 3 / 12]} px={1}>
          <Select<DiscountType>
            value={discountTypes.find(type => formState.type.value === type.value)}
            onChange={handleUpdateType}
            options={discountTypes}
            clearable={false}
            placeholder="Kies een type korting"
            searchable={false}
            label="Type korting"
            zIndex={5}
          />
        </Box>
        <Box width={[1, 1 / 2, 3 / 12]} px={1}>
          {type.value === 'amountInclTax' ? (
            <Input
              type="number"
              placeholder="0"
              min="0"
              step="0.01"
              {...amount}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange({ amount: Number(e.target.value) })
              }
              name="discountAmount"
              label="Bedrag"
              addonContent="€"
              addonSide="start"
            />
          ) : (
            <Input
              type="number"
              placeholder="0"
              min="0"
              max="100"
              step="0.01"
              {...amount}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange({ amount: Number(e.target.value) })
              }
              label="Percentage"
              name="discountPercentage"
              addonContent="%"
              addonSide="end"
            />
          )}
        </Box>
        <Box width={[1, 1, 6 / 12]} px={1} style={{ position: 'relative' }}>
          {existingDiscounts ? (
            isAddingNewDiscount ? (
              <Input
                type="text"
                label="Omschrijving"
                {...text}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange({ text: e.target.value })
                }
                placeholder="Type een omschrijving"
              />
            ) : (
              <Select<string>
                {...text}
                value={{ label: text.value, value: text.value }}
                options={existingDiscounts.map(discount => ({ label: discount, value: discount }))}
                label="Omschrijving"
                onChange={option => handleChange({ text: option.value })}
              />
            )
          ) : error ? (
            <p>{error.message}</p>
          ) : (
            <p>Laden...</p>
          )}

          <Box style={{ position: 'absolute', top: 0, right: 0, whiteSpace: 'nowrap' }} mr={1}>
            <Switch
              bgColorOff="gray"
              bgColorOn="blue"
              isOn={isAddingNewDiscount}
              onLabel="Nieuwe korting"
              width="1em"
              toggleSwitch={() => setAddingNewDiscount(!isAddingNewDiscount)}
            />
          </Box>
        </Box>
      </Flex>

      <Flex justifyItems="stretch" mx="-3px">
        <Box width={1} px="3px">
          <Button
            fluid
            type="button"
            bgColor="red"
            fontSize={6}
            minIconSize="1em"
            inverse
            onClick={onCancel}
            label="Annuleren"
            mt={1}
          />
        </Box>

        {initialValue?.amount && initialValue.amount > 0 ? (
          <Box width={1} px="3px">
            <Button
              fluid
              type="button"
              bgColor="red"
              fontSize={6}
              iconStart={Cancel}
              minIconSize="1em"
              inverse
              label="Korting verwijderen"
              mt={1}
              onClick={onClear}
            />
          </Box>
        ) : (
          <></>
        )}
        <Box width={1} px="3px">
          <Button
            fluid
            type="submit"
            disabled={!amount.value || !text.value}
            fontSize={6}
            iconStart={initialValue && initialValue?.amount > 0 ? Check : Add}
            inverse
            label={
              initialValue && initialValue.amount > 0 ? 'Korting aanpassen' : 'Korting toevoegen'
            }
            minIconSize="1em"
            mt={1}
          />
        </Box>
      </Flex>
    </form>
  );
};

export default DiscountForm;
