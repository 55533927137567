import { Box, Card, Container, Flex, Select, Wrapper } from '@energiebespaarders/symbols';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';

export const IMAGE_UPLOAD_SCALE_PREFERENCE_KEY = 'IMAGE_UPLOAD_SCALE_PREFERENCE';
export type ImageUploadScalePreset = 'original' | 'small' | 'medium' | 'large';
export const ImageUploadScaleSizes: Record<ImageUploadScalePreset, number> = {
  small: 800,
  medium: 1600,
  large: 2400,
  original: 99999,
};
export const ImageUploadScaleNL: Record<ImageUploadScalePreset, string> = {
  small: `Klein (max. ${ImageUploadScaleSizes.small} pixels groot)`,
  medium: `Gemiddeld (max. ${ImageUploadScaleSizes.medium} pixels groot)`,
  large: `Groot (max. ${ImageUploadScaleSizes.large} pixels groot)`,
  original: 'Originele resolutie behouden',
};
const imageRescaleOptions = Object.keys(ImageUploadScaleSizes).map(key => ({
  label: ImageUploadScaleNL[key as ImageUploadScalePreset],
  value: key,
}));

const Config = () => {
  const mobile = useIsMobile();

  const [imageScale, setImageScale] = useState<ImageUploadScalePreset>(
    (localStorage.getItem(IMAGE_UPLOAD_SCALE_PREFERENCE_KEY) as ImageUploadScalePreset) || 'large',
  );

  const handleImageScaleChange = useCallback((e: { value: string }) => {
    setImageScale(e.value as ImageUploadScalePreset);
    localStorage.setItem(IMAGE_UPLOAD_SCALE_PREFERENCE_KEY, e.value);
  }, []);

  return (
    <Wrapper mobile={mobile} p={mobile ? 2 : 4} pb={mobile ? 10 : 5}>
      <Helmet title="Instellingen" />

      <Container textAlign="center">
        <h1>Instellingen</h1>

        <Flex>
          <Box width={[1, 1, 1 / 2]}>
            <Card>
              <Box width={1} px={1}>
                <Select
                  name="Fotoresolutie voor uploaden*"
                  label="Fotoresolutie voor uploaden*"
                  onChange={handleImageScaleChange}
                  options={imageRescaleOptions}
                  value={imageRescaleOptions.find(opt => opt.value === imageScale)}
                />
              </Box>

              <Box textAlign="left">
                * Deze instellingen worden op dit apparaat opgeslagen, ze zijn niet aan je account
                gekoppeld
              </Box>
            </Card>
          </Box>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default Config;
