import { gql } from '@apollo/client';

export const InstallationCapacityFragment = gql`
  fragment WeeklyJobCapacity on WeeklyInstallationCapacity {
    solution
    weeks {
      date
      year
      week
      capacity
    }
  }
`;
