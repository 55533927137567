import { Box, Card, Gallery, Spacer, Tooltip } from '@energiebespaarders/symbols';
import { Medium, Small } from '@energiebespaarders/symbols/helpers';
import dayjs from 'dayjs';
import React from 'react';
import { fileUrl } from '../../../../config';
import SimpleBanner from '../../../../domains/TaskFlow/SimpleBanner';
import { File_metadata } from '../../../../types/generated/File';

export type InstallerSignoff = {
  jobId: string;
  signoffPoints: readonly {
    comment: string;
    key: string;
    files: readonly {
      id: string;
      extension: string;
      metadata: File_metadata;
    }[];
  }[];
  markedAsComplete: {
    date: Date;
    user: {
      firstName: string;
      lastName: string;
      email: string;
    };
  } | null;
};

const PtcSignoffOverview: React.FC<{ signoff: InstallerSignoff }> = ({ signoff }) => {
  const completion = signoff.markedAsComplete;

  if (!completion) return <SimpleBanner type="error" message="Geen schouwresultaten geüpload" />;

  // TODO: Apparently there already was a component for this: SignoffPoints (components/signoff/Points.tsx)
  return (
    <>
      <Card animation="none" shadow="none" borderColor="grayLight" bgColor="grayLighter" mb={1}>
        <div>
          Schouwresultaten aangeleverd door{' '}
          <Medium>
            <Tooltip content={completion.user.email}>
              <u>
                {completion.user.firstName} {completion.user.lastName}
              </u>
            </Tooltip>
          </Medium>{' '}
          op <Medium>{dayjs(completion.date).format('dddd D MMMM YYYY [om] HH:mm')}</Medium>
        </div>
      </Card>

      {signoff.signoffPoints.map((p, i) => (
        <Box key={`${p.key}-${i}`} mt={2}>
          <Gallery
            files={signoff.signoffPoints[0]?.files || []}
            fileUrl={fileUrl}
            size="sm"
            tags={['signoff']}
            placeholderText="Er zijn geen bestanden geüpload"
          />

          <Spacer vertical amount={2} />

          <p>Opmerkingen bij de schouw</p>
          <Card shadow="none" animation="none" borderColor="grayLight" bgColor="floral">
            <Box style={{ fontFamily: 'monospace' }}>
              <Small>{signoff.signoffPoints[0]?.comment}</Small>
            </Box>
          </Card>
        </Box>
      ))}
    </>
  );
};

export default PtcSignoffOverview;
