import {
  SOLUTIONS,
  SOLUTIONS_NL,
  SOLUTION_PREFIXES,
  Solution,
} from '@energiebespaarders/constants';
import { Box, Button, Flex, Tooltip } from '@energiebespaarders/symbols';
import { Center, Heading, Right } from '@energiebespaarders/symbols/helpers';
import { CaretLeft, CaretRight, FloppyDisk, Pencil } from '@energiebespaarders/symbols/icons/solid';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { sum } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Cell, Column, FooterProps, useBlockLayout, useTable } from 'react-table';
import styled from 'styled-components';
import dayjs from '../../dayjs';
import { TableWrapper } from '../../domains/Products/productTableHelpers';
import { getInstallationCapacity_installerById_installationCapacity } from '../../types/generated/getInstallationCapacity';
import { WeeklyInstallationCapacityInput } from '../../types/graphql-global-types';

const CellInput = styled.input<{ $isZero?: boolean }>`
  width: 100%;
  text-align: right;
  border: none;

  &:disabled {
    background-color: transparent;
    color: ${x => (x.$isZero ? themify('grayDark') : 'inherit')};
  }

  color: ${x => (x.$isZero ? themify('grayDark') : undefined)};
`;

type Row = { week: number } & Partial<Record<Solution, number>>;
type CustomColumn = Column<Row>;
type CustomCell = Cell<Row> & {
  isEditing: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
type CustomFooter = FooterProps<Row>;

/**
 * Converts data from the GraphQL format to a table format:
 * - { week: 1, pvSystem: 0, hybridHeatPump: 1 }
 * - { week: 2, pvSystem: 0, hybridHeatPump: 2 }
 * @param year
 * @param capacity
 * @returns
 */
const convertToTableData = (
  year: number,
  capacity: ReadonlyArray<getInstallationCapacity_installerById_installationCapacity>,
): Row[] => {
  const amountWeeks = dayjs().year(year).isoWeeksInYear();
  const weekNumbers = Array.from({ length: amountWeeks }, (_, i) => i + 1);

  return weekNumbers.map(week => {
    const row: Row = { week };
    for (const { solution, weeks } of capacity) {
      // Can't simply check week and year number because the first week of the year might start the year before
      const weekStartDate = dayjs().year(year).week(week).startOf('week').toDate();
      row[solution] = weeks.find(
        w => new Date(w.date).getTime() === weekStartDate.getTime(),
      )?.capacity;
    }
    return row;
  });
};

const convertToGraphQLFormat = (
  year: number,
  tableData: Row[],
): WeeklyInstallationCapacityInput[] => {
  return SOLUTIONS.flatMap(solution => {
    const weeks = tableData.flatMap(row => {
      const capacity = row[solution];
      if (capacity === undefined) return [];
      return [{ week: row.week, capacity }];
    });
    if (!weeks.length) return [];
    return {
      solution,
      weeks: weeks.map(week => ({
        date: dayjs().year(year).week(week.week).startOf('week').toDate(),
        capacity: week.capacity,
      })),
    };
  });
};

const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const solution = e.currentTarget.dataset.solution as Solution;
  const week = Number(e.currentTarget.dataset.week);
  // Focus the input element on the next/previous row with Enter
  if (e.key === 'Enter') {
    const targetIndex = week + (e.shiftKey ? -1 : 1);
    const nextCellId = `${solution}-${targetIndex}`;
    const nextCell = document.querySelector<HTMLInputElement>(
      `[data-capacity-input-id="${nextCellId}"]`,
    );
    nextCell?.focus();
    nextCell?.select();
  }
};

type CapacityTableProps = {
  /** Which solutions the installer performs installations for */
  solutions: ReadonlyArray<Solution>;
  capacity: ReadonlyArray<getInstallationCapacity_installerById_installationCapacity>;
  onSave: (capacity: WeeklyInstallationCapacityInput[]) => void;
  isSubmitting?: boolean;
  submitError?: string;
};

const CapacityTable: React.FC<CapacityTableProps> = ({
  capacity,
  solutions,
  onSave,
  submitError,
  isSubmitting,
}) => {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const columns = useMemo((): CustomColumn[] => {
    return [
      {
        id: 'week',
        accessor: 'week',
        Header: `Week (${year})`,
        Cell: function Week({ value }: CustomCell) {
          const isCurrentWeek = dayjs().week() === value;
          const isInPast = year <= dayjs().year() && value < dayjs().week();
          return (
            <pre
              style={{
                background: themify(isCurrentWeek ? 'yellow' : isInPast ? 'lightGray' : undefined),
              }}
              data-week={value}
            >
              {dayjs().set('y', year).week(value).startOf('week').format('D MMMM')} - {value}
            </pre>
          );
        },
        Footer: function TotalsFooter() {
          return 'Totaal';
        },
      },
      ...SOLUTIONS.map(solution => ({
        accessor: solution,
        width: 50,
        Header: (
          <Center block>
            <Tooltip content={SOLUTIONS_NL[solution]}>
              <>{SOLUTION_PREFIXES[solution]}</>
            </Tooltip>
          </Center>
        ),
        Cell: function Capacity({ row, value, isEditing, handleChange }: CustomCell) {
          return (
            <CellInput
              type="number"
              step="1"
              min="0"
              value={value}
              // Used in event handlers to identify the solution and week without having to pass these as arguments
              data-solution={solution}
              data-week={row.original.week}
              // Used for focusing the next/previous cell
              data-capacity-input-id={`${solution}-${row.original.week}`}
              onChange={handleChange}
              disabled={!isEditing}
              onKeyDown={handleKeyDown}
              $isZero={!value}
            />
          );
        },
        Footer: function TotalPerSolution({ rows }: CustomFooter) {
          const total = sum(rows.map(row => row.values[solution]) || []);
          return <pre>{total}</pre>;
        },
      })),
      {
        id: 'total',
        Header: <Right block>Totaal</Right>,
        width: 50,
        Cell: function Total({ row }: CustomCell) {
          const total = sum(solutions.map(solution => row.values[solution] || 0));
          return <pre>{total}</pre>;
        },

        Footer: function TotalPerSolution({ rows }: CustomFooter) {
          const total = sum(rows.flatMap(row => solutions.map(sol => row.values[sol])) || []);
          return <pre>{total}</pre>;
        },
      },
    ];
  }, [solutions, year]);

  const [tableData, setTableData] = useState(() => convertToTableData(year, capacity));

  const setCapacity = useCallback((week: number, solution: Solution, value: number) => {
    setTableData(prev => {
      const row = prev.find(r => r.week === week);
      if (!row) return prev;

      const newRow = { ...row, [solution]: value };
      return prev.map(r => (r.week === week ? newRow : r));
    });
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const week = Number(event.currentTarget.dataset.week);
      const solution = event.currentTarget.dataset.solution as Solution;
      const value = Number(event.currentTarget.value);

      setCapacity(week, solution, value);
    },
    [setCapacity],
  );

  // IDEA: a footer/header row to do a "fill-all" or "clear" for the column

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    columns: columnGroups,
    rows,
    allColumns,
    prepareRow,
    setHiddenColumns,
  } = useTable(
    {
      columns: columns,
      data: tableData,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetHiddenColumns: false,
      autoResetExpanded: false,
      isEditing,
      handleChange,
    },
    useBlockLayout,
  );

  useEffect(() => {
    setHiddenColumns(
      SOLUTIONS.filter(solution => !solutions.includes(solution)).map(solution => solution),
    );
  }, [setHiddenColumns, solutions]);

  const onSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onSave(convertToGraphQLFormat(year, tableData));
      setIsEditing(false);
    },
    [onSave, year, tableData],
  );

  const onCancel = useCallback(() => {
    setTableData(convertToTableData(year, capacity));
    setIsEditing(false);
  }, [capacity, year]);

  const onYearChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const offset = Number(event.currentTarget.dataset.yearOffset);
      setYear(year + offset);
      setTableData(convertToTableData(year + offset, capacity));
    },
    [year, capacity],
  );

  useLayoutEffect(() => {
    // On mount, scroll to the cell of the current week
    document.querySelector(`[data-week="${dayjs().week()}"]`)?.scrollIntoView({ block: 'center' });
  }, []);

  return (
    <>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Button
          disabled={isEditing}
          onClick={onYearChange}
          data-year-offset={-1}
          inverse
          minimal
          iconStart={CaretLeft}
        >
          Vorig jaar ({year - 1})
        </Button>
        <Box>
          <Heading h={4} m="auto">
            Capaciteit {year}
          </Heading>
        </Box>
        <Button
          disabled={isEditing}
          onClick={onYearChange}
          data-year-offset={1}
          inverse
          minimal
          iconEnd={CaretRight}
        >
          Volgend jaar ({year + 1})
        </Button>
      </Flex>
      <Box mb={1}>
        <TableWrapper $height="500px">
          <div {...getTableProps()} className="table sticky">
            <div className="header">
              {headerGroups.map(headerGroup => (
                // react-table already adds keys, no need to do it ourselves
                // eslint-disable-next-line react/jsx-key
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map(column => (
                    // eslint-disable-next-line react/jsx-key
                    <div {...column.getHeaderProps()} className={'th'}>
                      {column.render('Header')}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {rows.map(row => {
                prepareRow(row);

                // find the first column in each column group (allColumns is ordered)
                const groupStartIDs = columnGroups.map(
                  parent => allColumns.find(col => col.parent === parent)?.id,
                );

                return (
                  // eslint-disable-next-line react/jsx-key
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map(cell => (
                      // eslint-disable-next-line react/jsx-key
                      <div
                        {...cell.getCellProps()}
                        className={`td ${
                          groupStartIDs.includes(cell.column.id) ? 'group-start' : ''
                        }`}
                      >
                        {cell.render('Cell')}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
            <div className="footer">
              {footerGroups.map(footerGroup => (
                // eslint-disable-next-line react/jsx-key
                <div {...footerGroup.getHeaderGroupProps()} className="tr">
                  {footerGroup.headers.map(column => (
                    // eslint-disable-next-line react/jsx-key
                    <div className="td" {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </TableWrapper>
      </Box>

      <Flex flexWrap="wrap" justifyContent="end">
        {isEditing && (
          <Box width={1 / 4} ml={1}>
            <Button bgColor="red" onClick={onCancel} fluid>
              Annuleren
            </Button>
          </Box>
        )}
        <Box width={1 / 4} ml={1}>
          <Button
            iconStart={isEditing ? FloppyDisk : Pencil}
            onClick={isEditing ? onSubmit : () => setIsEditing(true)}
            loading={isSubmitting}
            error={submitError}
            fluid
          >
            {isEditing ? 'Opslaan' : 'Aanpassen'}
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default CapacityTable;
