import { useMutation } from '@apollo/client';
import { Textarea } from '@energiebespaarders/symbols';
import { Heading } from '@energiebespaarders/symbols/helpers';
import React from 'react';
import useToaster from '../../../hooks/useToaster';
import { SAVE_COMMENT } from '../../../queries/installatron';
import { jobDomainQuote_quoteById_jobs } from '../../../types/generated/jobDomainQuote';

interface PricePdfRemarksProps {
  job: jobDomainQuote_quoteById_jobs;
}

const PricePdfRemarks: React.FC<PricePdfRemarksProps> = ({ job }) => {
  const toast = useToaster();
  const [saveComment] = useMutation(SAVE_COMMENT, {
    onCompleted: () => {
      toast({
        type: 'success',
        message: 'Opmerking is opgeslagen',
      });
    },
  });
  return (
    <>
      <Heading>Prijs PDF opmerkingen</Heading>
      <Textarea
        width="100%"
        placeholder="Schrijf hier opmerkingen voor op prijs PDF"
        minHeight="7em"
        onBlur={e =>
          saveComment({
            variables: {
              jobId: job?.id,
              content: e.target.value,
            },
          })
        }
        defaultValue={job?.comment ?? ''}
      />
    </>
  );
};

export default PricePdfRemarks;
