import React from 'react';
import { ScrollLink as ScrollHoc } from 'react-scroll';

interface ScrollLinkWrapperProps {
  bgColor?: string;
  borderColor?: string;
  color?: string;
  height?: string;
  indicatorOffset?: string;
}

const ScrollLinkWrapper: React.FC<ScrollLinkWrapperProps> = ({
  bgColor,
  borderColor,
  color,
  height,
  indicatorOffset,
  children,
  ...domProps
}) => <div {...domProps}>{children}</div>;

export default ScrollHoc(ScrollLinkWrapper);
