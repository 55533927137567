import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useMe } from '../../hooks/useMe';
import AppliedRoute, { AppliedRouteProps } from './AppliedRoute';

export const UnauthenticatedRoute: React.FC<AppliedRouteProps> = ({
  component: Component,
  exact,
  path,
}) => {
  const { me } = useMe();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const fromRedirect = params.get('from') || '';
  return (
    <Route
      exact={exact}
      path={path}
      render={renderProps =>
        !me ? (
          <AppliedRoute component={Component} {...renderProps} />
        ) : (
          <Redirect
            to={{
              pathname: renderProps.location.state
                ? (renderProps.location.state as any).from.pathname
                : `/${fromRedirect}`,
              state: { from: renderProps.location },
            }}
          />
        )
      }
    />
  );
};

export default UnauthenticatedRoute;
