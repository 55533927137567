import { constants, getYearlyFinancialSavings, Solution } from '@energiebespaarders/constants';
import { QuoteCalculationObj } from '../../components/advice/CalculationsTable';
import { EnergyPrices } from '../../components/modals/EnergyPricesModal';
const { emissionFactors } = constants;

export function calculateTotalInvestment(calculations: QuoteCalculationObj[]) {
  if (!calculations) return 0;
  let totalInvestment = 0;
  calculations.forEach(solution => {
    totalInvestment += solution.investment;
  });
  return totalInvestment;
}

export function getEnergyLabel(energyIndex: number, labels = constants.energyLabels) {
  if (!energyIndex || typeof energyIndex !== 'number') return '?';
  switch (true) {
    case energyIndex <= (labels || constants.energyLabels).A:
      return 'A';
    case energyIndex <= (labels || constants.energyLabels).B:
      return 'B';
    case energyIndex <= (labels || constants.energyLabels).C:
      return 'C';
    case energyIndex <= (labels || constants.energyLabels).D:
      return 'D';
    case energyIndex <= (labels || constants.energyLabels).E:
      return 'E';
    case energyIndex <= (labels || constants.energyLabels).F:
      return 'F';
    default:
      return 'G';
  }
}

export interface RelativeSavingTotals {
  gasConsumption: number;
  electricityProduction: number;
  electricityConsumption: number;
  electricitySavings: number;
  money: number;
  emission: number;
  energyIndex: number;
}

export function calculateRelativeTotals(
  quotes: QuoteCalculationObj[],
  consumption: { gas: number; electricity: number },
  energyPrices: EnergyPrices,
): RelativeSavingTotals {
  let totalEnergyIndex = 0;
  let newGasConsumption = consumption.gas;
  let totalElectricityProduction = 0;
  let totalElectricityConsumption = 0;

  quotes.forEach(quote => {
    totalEnergyIndex += quote.energyDelta.energyIndex;
    newGasConsumption -= newGasConsumption * quote.energyDelta.gasFactor;
    totalElectricityProduction += quote.energyDelta.electricityProduction;
    totalElectricityConsumption += quote.energyDelta.electricityConsumption;
  });

  const isHeatPumpQuote = (q: QuoteCalculationObj) =>
    ([Solution.HybridHeatPump, Solution.ElectricHeatPump] as Solution[]).includes(q.solution);
  const hasHeatPump = quotes.some(quote => isHeatPumpQuote(quote));

  let insulationGasFactor = 1;

  if (hasHeatPump) {
    const heatPumpQuote = quotes.find(quote => isHeatPumpQuote(quote))!;
    quotes.forEach(quote => {
      if (!isHeatPumpQuote(quote)) {
        insulationGasFactor = insulationGasFactor * (1 - quote.energyDelta.gasFactor);
      }
    });
    totalElectricityConsumption =
      totalElectricityConsumption -
      heatPumpQuote.energyDelta.electricityConsumption -
      200 +
      (heatPumpQuote.energyDelta.electricityConsumption + 200) * insulationGasFactor;
  }

  const totalGasSavings = consumption.gas - newGasConsumption;
  const totalElectricitySavings = totalElectricityConsumption + totalElectricityProduction;

  const money = getYearlyFinancialSavings(
    consumption,
    {
      gasConsumption: totalGasSavings,
      electricityConsumption: totalElectricityConsumption,
      electricityProduction: totalElectricityProduction,
    },
    energyPrices,
  ).total;

  const relativeTotals = {
    gasConsumption: totalGasSavings,
    electricitySavings: totalElectricitySavings,
    electricityConsumption: totalElectricityConsumption,
    electricityProduction: totalElectricityProduction,
    money,
    emission:
      totalGasSavings * emissionFactors.gas + totalElectricitySavings * emissionFactors.electricity,
    energyIndex: totalEnergyIndex,
  };
  return relativeTotals;
}
