import React from 'react';
import { Gray, Smaller } from '@energiebespaarders/symbols/helpers';

export const generalGuide = (
  <>
    <Smaller>
      <Gray>Laatst gewijzigd: 09-05-2019</Gray>
    </Smaller>
    <h3>Energiebeheerders</h3>
    <ul>
      <li>
        Alle tools voor het helpen van een klant staan in de linker zijbalk en worden pas actief als
        je een klant of woning geselecteerd hebt vanuit de zoekbalk.
      </li>
      <li>
        Alle algemene admin tools staan in de rechter zijbalk (zichtbaar als je op icoontje
        rechtsboven drukt).
      </li>
      <li>De zoekbalk is universeel voor klanten, woningen, bedrijven, contacten en operators.</li>
      <li>
        De laatst bezochte klant en/of woning wordt lokaal opgeslagen, zodat je de volgende keer
        daar weer mee verder kunt.
      </li>
      <li>Op OBAMA na, werken alle tools op mobiel, tablet en desktop.</li>
      <li>Pro tip: je kunt de zijbalk inklappen om meer ruimte te maken op je scherm ;)</li>
    </ul>
  </>
);
