import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useRouteMatch } from 'react-router-dom';

type ContextValue = [string, Dispatch<SetStateAction<string>> | null];

const initialActiveHouseId = window.localStorage.getItem('activeHouseId') || '';
export const ActiveHouseIdContext = React.createContext<ContextValue>([initialActiveHouseId, null]);

export const ActiveHouseIdProvider: React.FC = ({ children }) => {
  const [activeHouseId, setActiveHouseId] = useState(initialActiveHouseId);
  const contextValue: ContextValue = [activeHouseId, setActiveHouseId];
  return (
    <ActiveHouseIdContext.Provider value={contextValue}>{children}</ActiveHouseIdContext.Provider>
  );
};

export function useActiveHouseId() {
  const [activeHouseId, setActiveHouseId] = useContext(ActiveHouseIdContext);

  const match = useRouteMatch<{ houseId?: string }>();
  const routeHouseId = match.params.houseId || '';

  const handleSetActiveHouseId = useCallback(
    (id: string) => {
      setActiveHouseId?.(id);
      // Also store activeHouseId in localStorage so we can reinitialize correctly on reload, see Initialize component
      window.localStorage.setItem('activeHouseId', id);
    },
    [setActiveHouseId],
  );

  // Set the active house ID in the context and local storage, when the ID in the route changes
  useEffect(() => {
    if (routeHouseId) handleSetActiveHouseId(routeHouseId);
  }, [handleSetActiveHouseId, routeHouseId]);

  return {
    activeHouseId: activeHouseId,
    setActiveHouseId: handleSetActiveHouseId,
  };
}
