import { OperatorTeam } from '../components/operatorDirectory/OperatorProfile';
import { me_me_Operator } from './../types/generated/me';

export const checkTeamMember = (me: me_me_Operator, team: OperatorTeam): boolean => {
  return me.team === team;
};

/** Returns true if your role is Admin or Manager.
 * If passed a targetOperator, it checks whether you're _their_ manager.
 */
export const checkManagerRole = (me: me_me_Operator, targetOperator?: me_me_Operator): boolean => {
  if (!targetOperator) return false;

  const myTeam = me.team;
  const isTeamMember = targetOperator && targetOperator.team === myTeam;
  const myRole = me.role?.toLowerCase() || '';

  // BiDS needs to manage freelance sales operators (in order to set the sales manager)
  if (
    myRole === 'manager' &&
    checkTeamMember(me, OperatorTeam.InsideSales) &&
    targetOperator.isFreelancer
  ) {
    return true;
  }

  // TODO: Make these an enum in the schema?
  return myRole === 'admin' || (myRole === 'manager' && isTeamMember);
};

export const checkIfManagerOrAdmin = (me: me_me_Operator): boolean => {
  const myRole = me.role?.toLowerCase() || '';
  return myRole === 'admin' || myRole === 'manager';
};
