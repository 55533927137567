import { gql, useQuery } from '@apollo/client';
import { SOLUTIONS_NL } from '@energiebespaarders/constants';
import { Placeholder } from '@energiebespaarders/symbols';
import { Small } from '@energiebespaarders/symbols/helpers';
import React, { ReactNode, useState } from 'react';
import { FileFragment } from '../../fragments';
import {
  installerRevisions,
  installerRevisionsVariables,
  installerRevisions_installerById_revisions_diff,
} from '../../types/generated/installerRevisions';
import Changelog from '../Changelog';
import { installerStatusReasonNL } from './InstallerStatusConfirmationModal';
import InstallerStatusIndicators from './InstallerStatusIndicator';

const INSTALLER_REVISIONS = gql`
  ${FileFragment}

  query installerRevisions($installerId: ID!, $offset: Int, $limit: Int) {
    installerById(id: $installerId) {
      id
      revisions(offset: $offset, limit: $limit) {
        id
        modifiedBy {
          ... on AuthenticatedUser {
            id
            firstName
            lastName
          }
        }
        timestamp
        revision
        diff {
          id
          address {
            zip
            number
            suffix
            street
            city
          }
          certifications
          coc
          email
          contactEmail
          files {
            ...File
          }
          iban
          name
          phone
          size
          solutions
          status {
            value
            reason
            comment
            date
          }
          vat
          website
          workRadius
          installationRegions {
            to
            from
          }
          inspectionEmail
          installationEmail
          planningPeriod
          customerNotificationPeriod
          extraJobInformation
          grippId
          pipedriveId
          legalName
          rating
          minimumRate
          hasInstallerAccount
          accountManager {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const InstallerFieldLabels: Record<
  keyof installerRevisions_installerById_revisions_diff,
  string
> = {
  __typename: '',
  id: '',
  address: 'Adres',
  certifications: 'Certificering',
  coc: 'COC',
  email: 'Contact e-mailadres',
  contactEmail: 'Contact e-mailadres',
  files: 'Bestanden',
  iban: 'IBAN',
  name: 'Naam',
  phone: 'Telefoonnummer',
  size: 'Grootte',
  solutions: 'Oplossingen',
  status: 'Status',
  vat: 'VAT',
  website: 'Website',
  workRadius: 'Werkradius',
  installationRegions: 'Werkgebieden',
  inspectionEmail: 'Schouw e-mailadres',
  installationEmail: 'Installatie e-mailadres',
  planningPeriod: 'Planning periode (WERKDAGEN)',
  customerNotificationPeriod: 'Klant notificatie periode (WEKEN)',
  extraJobInformation: 'Extra opdracht info',
  grippId: 'Gripp ID',
  pipedriveId: 'Pipedrive ID',
  legalName: 'Wettelijke naam',
  rating: 'Beoordeling',
  minimumRate: 'Minimumtarief',
  hasInstallerAccount: 'Heeft installer account',
  accountManager: 'Accountmanager',
};

const InstallerFieldFormatter: Partial<
  Record<
    keyof installerRevisions_installerById_revisions_diff,
    (value: installerRevisions_installerById_revisions_diff) => ReactNode
  >
> = {
  address: ({ address: a }) => `${a?.street} ${a?.number}${a?.suffix || ''}, ${a?.zip} ${a?.city}`,
  certifications: ({ certifications }) => certifications?.join(', '),
  files: ({ files }) => files?.map(f => f.metadata.title),
  solutions: function Solutions({ solutions }) {
    return <Small>{solutions?.map(s => SOLUTIONS_NL[s]).join(', ')}</Small>;
  },
  status: function Status({ status }) {
    return (
      <>
        {InstallerStatusIndicators[status!.value].label}.{' '}
        <Small>
          Vanwege <i>{installerStatusReasonNL[status!.reason!] || 'Onbekend'}</i>. Toelichting:{' '}
          <i>{status?.comment || 'Geen toelichting'}</i>
        </Small>
      </>
    );
  },
  installationRegions: function InstallationRegions({ installationRegions }) {
    return <Small>{installationRegions?.map(reg => `${reg.from}-${reg.to}`).join(', ')}</Small>;
  },
  accountManager: ({ accountManager }) =>
    accountManager ? `${accountManager.firstName} ${accountManager?.lastName}` : 'geen',
};

interface InstallerChangelogProps {
  installerId: string;
}

const defaultLimit = 10;

const InstallerChangelog: React.FC<InstallerChangelogProps> = ({ installerId }) => {
  const { data, loading, error, refetch } = useQuery<
    installerRevisions,
    installerRevisionsVariables
  >(INSTALLER_REVISIONS, {
    variables: { installerId, limit: defaultLimit },
    fetchPolicy: 'cache-and-network',
  });

  // Temporary pagination alternative, a single "load more" button
  const [hasLoadedMore, setHasLoadedMore] = useState(false);

  if (error) return <Placeholder error={error} />;

  const revisions = data?.installerById?.revisions || [];

  return (
    <Changelog
      fetchMore={
        // TODO: add real pagination https://www.apollographql.com/docs/react/pagination/core-api/
        revisions.length === defaultLimit && !hasLoadedMore
          ? () => {
              setHasLoadedMore(true);
              refetch({ installerId, limit: 50 });
            }
          : undefined
      }
      loading={loading}
      error={error}
      changes={
        revisions.flatMap(({ modifiedBy, timestamp, diff, revision }) => {
          const user =
            modifiedBy && 'firstName' in modifiedBy
              ? {
                  name: `${modifiedBy.firstName} ${modifiedBy.lastName}`,
                  link: `/users/operators/${modifiedBy.id}`,
                }
              : { name: 'Onbekend' };

          // TODO: this filters out fields intentionally changed to null... no way around it through graphql
          // Would have to send over the entire doc instead of diffs. Might be better: also allows showing from->to changes
          // TODO: that's supported now too: query the `document` instead of `diff`. Leaving for later
          const changedKeys = Object.keys(diff).filter(
            k => !['id', '__typename'].includes(k) && diff[k as keyof typeof diff],
          );

          const isMultiChange = changedKeys.length > 1;

          return changedKeys.map((key, index) => {
            const typedKey = key as keyof typeof diff;
            return {
              revision: isMultiChange
                ? `${revision}-${String.fromCharCode(index + 'A'.charCodeAt(0))}`
                : revision.toString(),
              modifiedBy: user,
              timestamp,
              field: InstallerFieldLabels[typedKey] || key,
              change: InstallerFieldFormatter[typedKey]?.(diff) || JSON.stringify(diff[typedKey]),
            };
          });
        }) || []
      }
    />
  );
};

export default InstallerChangelog;
