import { gql } from '@apollo/client';
import { QuoteFragment, QuoteItemFragment } from '../fragments';

export const QUOTE_BY_ID = gql`
  ${QuoteFragment}
  query quoteById($quoteId: ID!) {
    quoteById(quoteId: $quoteId) {
      ...Quote
      isSuppliable
    }
  }
`;

export const QUOTE_MODAL_QUOTE = gql`
  ${QuoteFragment}
  ${QuoteItemFragment}
  query quoteModalQuote($quoteId: ID!) {
    quoteById(quoteId: $quoteId) {
      ...Quote
      isSuppliable
      hasCompletedIntake
      acceptedSubjectToFunding {
        date
      }
      cancellationInformation {
        reason
        note
      }
      jobs {
        id
        type
        completed
      }
      house {
        id
        isHabitataHouse
        intake {
          startedOn
        }
      }
      updatedPurchasePrices {
        retailPrice
        purchasePrice
        product {
          id
          title
        }
      }
      updatedRetailPrices {
        retailPrice
        purchasePrice
        product {
          id
          title
        }
      }
      pvSystemInfo {
        cardinalDirection
        installationPlanProperties
        multipleRoofSurfaces
        neighbourDiscount
      }
      installer {
        id
        name
        status {
          value
          reason
          comment
          date
        }
      }
      deal {
        id
        status
        isInstalled
        hasHadAPTC
        ptcJob {
          id
        }
        history {
          timestamp
          linkId
          linkType
          items {
            amount
            retailPrice
            purchasePrice
            product {
              id
              title
              priceUnit
              solution
              category
              tax
            }
            supplier {
              id
            }
          }
        }
        onHold {
          since
        }
      }
      acceptedSiblingInDeal {
        id
        installer {
          id
        }
      }
    }
  }
`;

export const SEND_ADVISED_QUOTES = gql`
  ${QuoteFragment}
  mutation sendAdvisedQuotes($houseId: ID!) {
    sendAdvisedQuotes(houseId: $houseId) {
      ...Quote
    }
  }
`;
