import React from 'react';
import styled from 'styled-components';
import { Icon, theme } from '@energiebespaarders/symbols';
import mediaMin from '../../styles/breakpoints';
import { margin, themify } from '../../styles/mixins';

interface StyledNavBarIconProps {
  $border: string;
  $borderColor: string;
  $hoverColor: string;
  $zIndex: number;
}

const StyledNavBarIcon = styled(Icon)<StyledNavBarIconProps>`
  ${margin(2, 'h')};
  display: inline-block;
  height: 100%;
  border-left: ${x => x.$border} ${x => x.$borderColor};
  cursor: pointer;
  z-index: ${x => x.$zIndex};

  ${mediaMin.md} {
    ${margin(4, 'h')};
  }

  &:hover {
    & * {
      fill: ${x => x.$hoverColor} !important;
    }
  }
`;

interface NavBarIconProps {
  border?: string;
  borderColor?: string;
  fill?: string;
  height?: string;
  hoverColor?: string;
  icon: JSX.Element;
  leftNavWidth?: string;
  onClick: any;
  zIndex?: number;
  'data-tour'?: string;
}

const NavBarIcon: React.FC<NavBarIconProps> = ({
  border = '0',
  borderColor = 'grayLight',
  fill,
  hoverColor = 'green',
  icon,
  onClick,
  zIndex = theme.z.maskedNav,
  ...rest
}) => (
  <StyledNavBarIcon
    $border={border}
    $borderColor={themify(borderColor)}
    $zIndex={zIndex}
    fill={fill}
    height="24px"
    $hoverColor={themify(hoverColor)}
    icon={icon}
    inline
    onClick={onClick}
    solid
    width="24px"
    {...rest}
  />
);

export default NavBarIcon;
