import React from 'react';
import { Icon } from '@energiebespaarders/symbols';
import { Gray, Smaller } from '@energiebespaarders/symbols/helpers';
import {
  Block,
  Flag,
  Heart,
  PaperAirplane,
  Pencil,
  Photo,
  ThumbsUp,
  Time,
  Wrench,
} from '@energiebespaarders/symbols/icons/line';
import { themify } from '../../../styles/mixins';
import { StatusIcon } from '../../installatron/Quote/QuoteStatusIcons';

export const installatronGuide = (
  <>
    <Smaller>
      <Gray>Laatst gewijzigd: 09-05-2019</Gray>
    </Smaller>
    <h3>Installatron</h3>
    <p>
      Dit is de tool waarmee we aanpassingen aan het huis (installaties in onze termen) kunnen
      aanmaken en doorrekenen, om er vervolgens offertes en opdrachten van te exporteren.
    </p>
    <p>
      Hier is nu ook een overzichtspagina als startpunt, zodat je voor elke oplossing de huidige
      status kunt zien:{' '}
    </p>
    <ul>
      <li>is de oplossing volgens ons systeem mogelijk bij deze woning?</li>
      <li>is er al een installatie aangemaakt?</li>
      <li> zijn er al offertes en zoja, wat is de status?</li>
      <li> zijn er al opdrachten verzonden?</li>
    </ul>
    <p>
      {' '}
      Een groot verschil hier is dat zelfs oplossingen waarvan het systeem denkt dat het niet
      mogelijk is – niet fysiek installeerbaar – theoretisch doorgerekend zullen worden. Er is dus
      geen beperking meer dat een oplossing volgens ons systeem mogelijk moet zijn om er offertes
      voor te maken. Het is wel cruciaal dat je in dat geval scherp blijft op de berekeningen en
      geen onzinnige dingen naar de klant stuurt.
    </p>
    <p>
      Per oplossing krijg je vervolgens een overzicht dat je deels zult herkennen. De linkerkant
      bevat alle nodige informatie om een passende offerte te kunnen maken – de input. Relevante
      informatie over de woning, klant, notities en foto’s. Volledig nieuw is de checklist die toont
      welke stappen allemaal al genomen zijn – hoe meer, hoe beter. Deze zijn voornamelijk gericht
      op het proces rond een Energiepaspoort, zodat er niets vergeten wordt. Verder zijn er de twee
      calculators: een om PV-systemen te extrapoleren en een voor BTW-berekeningen.
    </p>
    <p>
      Aan de rechterkant wordt de input verwerkt tot output. Daar creëer je in eerste instantie een
      installatie voor een oplossing. Er is maar 1 installatie per oplossing, die je als
      configurator kunt zien voor de gewenste offertes en opdrachten. Een offerte is eigenlijk niets
      meer dan een momentopname van de samenstelling in de installatie. Je kunt vanuit die
      momentopname de samenstelling weer terugzetten om een kleine aanpassing te maken of een nieuwe
      offerte te exporteren. Nadat je een installatie hebt gemaakt, krijg je de configurator te
      zien. De configurator bestaat uit 3 stappen:
    </p>
    <ol>
      <li>
        <h4>Installatiebedrijf kiezen</h4>
        <p>
          Deze stap is er om onze onafhankelijkheid te waarborgen. Je kunt op basis van een product
          vinden welk installatiebedrijf deze kan installeren en daarmee aan de slag gaan om de
          offerte op te stellen. In theorie kun je nu ook beginnen door direct een
          installatiebedrijf te kiezen en vervolgens alleen hun producten te zien. Op termijn moet
          hier ook de selectiemechanisme verder worden geïmplementeerd met o.a. locatie en
          beoordelingen.
        </p>
      </li>
      <li>
        <h4>Installatie samenstellen</h4>
        <p>
          Dit is waar de producten uit de productdatabase voor de huidige oplossing ingeladen worden
          om aan een installatie toe te voegen. Zodra je een product selecteert, worden de bij ons
          bekende leveranciers getoond om een inkoopprijs van te kiezen. De leveranciers zijn
          voorlopig nog identiek aan de installatiebedrijven, maar in de toekomst kunnen we van de
          ene partij inkopen (e.g. groothandel) en een andere partij (e.g. installateur) laten
          installeren.
        </p>
        <p>
          Zodra je een hoeveelheid van een product toevoegt aan de installatie, verschijnt de
          samenstellingstabel met alle producten in de installatie en straks de offerte. Als dit een
          hoofdproduct is, wordt er direct doorgerekend wat de besparingen worden a.d.h.v. de
          producteigenschappen (i.e. WP in geval van zonnepanelen, Rd in geval van isolatie, etc.).
          Verder kun je in deze stap ook de korting toevoegen. Bruto of netto, percentage of exact
          bedrag. Deze wordt meegenomen in de berekeningen voor terugverdientijd en rendement.
        </p>
        <p>
          <strong>
            LET OP: Je kunt geen producten meer gebruiken die niet in de productdatabase staan of
            waarvan we geen inkoopprijs weten. Wil je een nieuw product offreren, dan zul je deze
            eerst volledig moeten toevoegen in het systeem.
          </strong>
        </p>
      </li>
      <li>
        <h4>Installatie exporteren</h4>
        <p>
          Tot slot hoef je alleen nog maar te exporteren naar een offerte. De concept offerte
          verschijnt direct in het blokje Offertes.
        </p>
        <p>
          Hier zie je in een oogopslag welke offertes er voor deze oplossing zijn. De icoontjes
          eronder tonen de eigenschappen van de offerte:
        </p>
        <ul>
          <li>
            <StatusIcon iconColor={themify('purple')}>
              <Icon icon={Flag} stroke="white" strokeWidth={2} width="16px" />
            </StatusIcon>{' '}
            geeft aan dat de offerte definitief is: er is geen schouw meer nodig en we verwachten
            geen prijsmutaties meer. Deze is dus optioneel.
          </li>
          <li>
            <StatusIcon iconColor={themify('orange')}>
              <Icon icon={PaperAirplane} stroke="white" strokeWidth={2} width="16px" />
            </StatusIcon>{' '}
            geeft aan dat de offerte verzonden is. Dit kan niet ongedaan gemaakt worden en bepaalt
            of de klant de offerte kan zien in zijn/haar account en Energiepaspoort. Er wordt nog
            geen mail verstuurd, dat kan handmatig vanuit het E-MAILS tabje, voor het geval je
            meerdere offertes wilt bundelen in een bericht.{' '}
            <strong>
              LET OP: Bij het verzenden krijgt een offerte een referentienummer en vanaf dat moment
              kan de offerte niet meer verwijderd worden.
            </strong>
          </li>
          <li>
            <StatusIcon iconColor={themify('blue')}>
              <Icon icon={Heart} stroke="white" strokeWidth={2} width="16px" />
            </StatusIcon>{' '}
            geeft aan dat deze offerte geadviseerd is door ons. Er kan maar 1 offerte geadviseerd
            zijn. Voor een Energiepaspoort is het de bedoeling dat er altijd een geadviseerde
            offerte is: hierop worden dan de initieel getoonde berekeningen in het Energiepaspoort
            gebaseerd.
          </li>
          <li>
            <StatusIcon iconColor={themify('green')}>
              <Icon icon={ThumbsUp} stroke="white" strokeWidth={2} width="16px" />
            </StatusIcon>{' '}
            geeft aan dat de klant een akkoord heeft gegeven op de offerte.
          </li>
          <li>
            <StatusIcon iconColor={themify('red')}>
              <Icon icon={Block} stroke="white" strokeWidth={2} width="16px" />
            </StatusIcon>{' '}
            geeft aan dat de offerte geannuleerd is. Dit is de enige manier om een verzonden offerte
            te verbergen voor een klant. Verder kun je hiermee aangeven dat er niets meer mee moet
            gebeuren.
          </li>
          <li>
            <StatusIcon iconColor={themify('grayDark')}>
              <Icon icon={Wrench} stroke="white" strokeWidth={2} width="16px" />
            </StatusIcon>{' '}
            geeft aan dat er een opdracht is gecreëerd voor deze offerte. Je ziet dan ook rechts een
            preview van het logo van het installatiebedrijf (in dit geval heeft die er geen).
          </li>
          <li>
            <StatusIcon iconColor={themify('red')}>
              <Icon icon={Time} stroke="white" strokeWidth={2} width="16px" />
            </StatusIcon>{' '}
            geeft aan dat de offerte verlopen is.
          </li>
          <li>
            <StatusIcon iconColor={themify('blue')}>
              <Icon icon={Photo} stroke="white" strokeWidth={2} width="16px" />
            </StatusIcon>{' '}
            geeft aan dat er foto's zijn geupload bij de offerte. Dit zal voornamelijk bij
            legplannen voor zonnepanelen het geval zijn.
          </li>
          <li>
            <StatusIcon iconColor={themify('blue')}>
              <Icon icon={Pencil} stroke="white" strokeWidth={2} width="16px" />
            </StatusIcon>{' '}
            geeft aan dat er een offertetekst geschreven is
          </li>
        </ul>
        <p>
          Als je op de offerte klikt, krijg je het detailscherm te zien van de offerte. Hier kun je
          metadata zien (wie, wanneer), de geldigheid aanpassen, tekst en bestanden toevoegen voor
          de klant, de opdracht aanmaken en verzenden en tot slot de eigenschappen van de offerte
          aanpassen. Bij elke offerte kun je de bijbehorende installatieopdracht als PDF exporteren.
          Deze exports zijn installateurspecifiek. Mocht je een opdracht opnieuw willen exporteren
          voor een andere installateur, is dat voor nu dus geen probleem – je krijgt simpelweg een
          nieuwe PDF. Op deze manier kunnen we voorlopig informatie op de opdracht op maat
          aanleveren.
        </p>
      </li>
    </ol>
  </>
);
